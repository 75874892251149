import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetBackofficeTeamMenuPermissionDataInterface,
  BackofficeGetBackofficeTeamMenuPermissionInputInterface,
} from './interfaces'

const backofficeGetBackofficeTeamMenuPermission: TypedDocumentNode<
  BackofficeGetBackofficeTeamMenuPermissionDataInterface,
  BackofficeGetBackofficeTeamMenuPermissionInputInterface
> = gql`
  query backofficeGetBackofficeTeamMenuPermission($input: GetBackofficeTeamMenuPermissionInput!) {
    backofficeGetBackofficeTeamMenuPermission(input: $input) {
      id
      name
      code
    }
  }
`

export default backofficeGetBackofficeTeamMenuPermission
