import React, { ReactElement } from 'react'
import { useRecoilValue } from 'recoil'
import { Navigate, useLocation } from 'react-router-dom'

import CONSTANT from './constant'
import userAtom from './recoil/user'

function RequireAuth({ children, pageCode }: { children: ReactElement; pageCode?: Array<string> }) {
  const user = useRecoilValue(userAtom)
  const location = useLocation()
  const currentLocation = location.pathname
  const hasPage = user.pages.some((page) => pageCode?.includes(page))

  if (!user.isAuth) {
    return (
      <Navigate
        to={{
          pathname: CONSTANT.ROUTES.LOGIN_PAGE,
        }}
        state={{ referrer: currentLocation }}
      />
    )
  }

  if (user.isAuth && !hasPage && pageCode !== undefined) {
    return (
      <Navigate
        to={{
          pathname: CONSTANT.ROUTES.UNAUTHORIZED_PAGE,
        }}
      />
    )
  }

  return children
}

export default RequireAuth
