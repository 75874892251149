const SEARCH_USER_INPUT_TYPE_NAME = 'NAME'
const SEARCH_USER_INPUT_TYPE_POLICY_NUMBER = 'POLICY_NUMBER'
const SEARCH_USER_INPUT_TYPE_IDENTITY_ID = 'IDENTITY_ID'
const SEARCH_USER_INPUT_TYPE_MOBILE_NUMBER = 'MOBILE_NUMBER'

export {
  SEARCH_USER_INPUT_TYPE_NAME,
  SEARCH_USER_INPUT_TYPE_POLICY_NUMBER,
  SEARCH_USER_INPUT_TYPE_IDENTITY_ID,
  SEARCH_USER_INPUT_TYPE_MOBILE_NUMBER,
}
