import styled from '@emotion/styled'
import { Card as AntdCard, Image, Skeleton as AntdSkeleton } from 'antd'
import { css } from '@emotion/css'

export const Container = styled('div')``

export const Content = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const Block = styled('div')``

export const Card = styled(AntdCard)`
  margin-bottom: 16px;
  border-radius: 8px;
`

export const PointIcon = styled('img')`
  width: 18px;
  height: 18px;
  margin-right: 16px;
`

export const Title = styled('div')`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`

export const Point = styled('div')`
  font-weight: 500;
  font-size: 16px;
  color: #009640;
`

export const InformationTitle = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const Information = styled('div')`
  font-weight: 500;
  font-size: 16px;
  color: #000000;
`

export const BpmTitle = styled('span')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const Bpm = styled('span')`
  color: #009640;
`

export const ActiveBonusIcon = styled('img')`
  width: 18px;
  height: 18px;
  margin-right: 16px;
`

export const PartnerLogo = styled('img')`
  width: 40px;
  height: 40px;
  margin-right: 16px;
`

export const EditTitle = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-bottom: 24px;
`

export const EditBy = styled('span')`
  color: #000000;
`

export const Thumbnail = styled(Image)`
  max-width: 56px;
  margin: 8px;
`

export const TitleBlockStyle = css`
  display: flex;
  align-items: center;
`

export const ActivityContentStyle = css`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 16px;
`

export const Skeleton = styled(AntdSkeleton)`
  margin: 4px 0;
`
