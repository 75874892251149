import axios from 'axios'

export const httpClientPrivate = axios.create({
  validateStatus(status) {
    return status >= 200 && status < 400
  },
})

httpClientPrivate.interceptors.request.use((config) => {
  if (config.headers && !config.headers.Authorization) {
    config.headers.setAuthorization(`Bearer ${localStorage.getItem('accessToken')}`)
  }

  return config
})

export default httpClientPrivate
