import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeListBackofficeRoleDataInterface } from './types'

export const backofficeListBackofficeRole: TypedDocumentNode<BackofficeListBackofficeRoleDataInterface> = gql`
  query backofficeListBackofficeRole {
    backofficeListBackofficeRole {
      id
      title
      isDefault
    }
  }
`

export default backofficeListBackofficeRole
