import { Container, Title, Subtitle, List, PasswordInput, Button, Form, FormItem } from './styles'
import ResetPasswordFormHook from './ResetPasswordForm.hook'

function ResetPasswordForm() {
  const { onValidatePassword, form, resetBackofficeUserPassword } = ResetPasswordFormHook()

  return (
    <Container visible={!resetBackofficeUserPassword.isSuccessful}>
      <Title>เปลี่ยนรหัสผ่าน</Title>
      <Subtitle>รหัสผ่านต้องประกอบด้วย:</Subtitle>
      <List>
        <li>ตัวอักษรและตัวเลข</li>
        <li>ตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัว</li>
        <li>ความยาวตั้งแต่ 8 ตัวขึ้นไป</li>
      </List>
      <Form form={form} onFinish={onValidatePassword}>
        <FormItem name="password">
          <PasswordInput fieldName="password" placeholder="New password" />
        </FormItem>
        <FormItem name="confirmPassword">
          <PasswordInput fieldName="confirmPassword" placeholder="Confirm password" />
        </FormItem>
        <Button type="primary" htmlType="submit">
          ยืนยัน
        </Button>
      </Form>
    </Container>
  )
}

export default ResetPasswordForm
