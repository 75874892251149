import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetEditWellBeingDataDailySummaryActivityLogDataInterface,
  BackofficeGetEditWellBeingDataDailySummaryActivityLogInputInterface,
} from './interfaces'

const backOfficeGetEditWellBeingDataDailySummaryActivityLog: TypedDocumentNode<
  BackofficeGetEditWellBeingDataDailySummaryActivityLogDataInterface,
  BackofficeGetEditWellBeingDataDailySummaryActivityLogInputInterface
> = gql`
  query BackOfficeGetEditWellBeingDataDailySummaryActivityLog(
    $activeHealthDailySummaryId: String!
  ) {
    backOfficeGetEditWellBeingDataDailySummaryActivityLog(
      activeHealthDailySummaryId: $activeHealthDailySummaryId
    ) {
      id
      action
      module
      actionData
      refId
      actedById
      actedBy {
        firstname
        lastname
      }
      actedAt
    }
  }
`

export default backOfficeGetEditWellBeingDataDailySummaryActivityLog
