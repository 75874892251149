import dayjs, { Dayjs } from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import {
  AddressPolicyPrintTypeEnum,
  ApplicationFormStatusEnum,
  ORDER_TAB,
  OrderTabEnum,
  PAYMENT_TYPE,
} from '@/constant/TA_TRANSACTION'
import { OrderManagementContext } from '@/contexts'
import message from '@/libs/message'
import { validatePerm } from '@/libs/validatePermission'
import { DateRange } from '@/libs/x-date-pickers-pro'
import userAtom from '@/recoil/user'
import { useExportOrder } from '@/services/order/useExportOrder'
import {
  AddressPolicyType,
  ApplicationFormType,
  OrderItemType,
  Payment,
} from '@/services/order/useExportOrder/types'
import { findOrderTab } from '@/libs/utils/find-order-tab'
import { isTimesEqual } from '@/libs/utils/is-times-equal'

const MAXIMUM_DATE_RANGE = 90

const TaTransactionExporterHook = () => {
  const { orderTab } = useContext(OrderManagementContext)
  const user = useRecoilValue(userAtom)
  const [dateValue, setDateValue] = useState<DateRange<Dayjs>>([null, null])
  const isEnabled = validatePerm([BACKOFFICE_USER_PERMISSION.TA010104], user.permissions)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const handleCloseModal = () => {
    setDateValue([null, null])
    setIsModalVisible(false)
  }

  const handleDateValue = (dateRangeValues: DateRange<Dayjs>) => {
    setDateValue(dateRangeValues)
  }

  const onClickCsvLink = (
    event: React.MouseEventHandler<HTMLAnchorElement>,
    done: (proceed?: boolean) => void,
  ) => {
    if (fetchExportOrder.data && fetchExportOrder.data.length <= 0) return done(false)

    handleCloseModal()
  }

  const getPolicyPrintType = (
    isTabForPolicyPrintType: boolean,
    addressPolicy: AddressPolicyType,
  ): string | undefined => {
    if (!isTabForPolicyPrintType) return undefined

    switch (addressPolicy.policyPrintType) {
      case AddressPolicyPrintTypeEnum.E_POLICY:
        return 'อีเมล'
      case AddressPolicyPrintTypeEnum.POLICY:
        return 'ไปรษณีย์'
      default:
        return '-'
    }
  }

  const transformToCsvData = (orderItems: Array<OrderItemType>) => {
    if (!orderItems) return []

    // result must be like [[header1, header2, header3], [data1, data2, data3]]
    const isTabForPolicyNumber = [OrderTabEnum.PAYMENT_SUCCESS, OrderTabEnum.ALL].includes(orderTab)
    const isTabForTotalOrderItem = [
      OrderTabEnum.DRAFT,
      OrderTabEnum.WAITING_PAYMENT,
      OrderTabEnum.PAYMENT_FAILED,
      OrderTabEnum.PAYMENT_SUCCESS,
      OrderTabEnum.CANCELLED,
      OrderTabEnum.ALL,
    ].includes(orderTab)
    const isTabForPaymentType = [
      OrderTabEnum.WAITING_PAYMENT,
      OrderTabEnum.PAYMENT_FAILED,
      OrderTabEnum.PAYMENT_SUCCESS,
      OrderTabEnum.ALL,
    ].includes(orderTab)
    const isTabForPaymentRemark = [OrderTabEnum.PAYMENT_SUCCESS, OrderTabEnum.ALL].includes(
      orderTab,
    )
    const isTabForPromotion = [
      OrderTabEnum.DRAFT,
      OrderTabEnum.WAITING_PAYMENT,
      OrderTabEnum.PAYMENT_FAILED,
      OrderTabEnum.PAYMENT_SUCCESS,
      OrderTabEnum.ALL,
    ].includes(orderTab)
    const isTabForStatus = [OrderTabEnum.ALL].includes(orderTab)
    const isTabForFailVerified = [OrderTabEnum.FAIL_VERIFIED, OrderTabEnum.ALL].includes(orderTab)
    const isTabForPolicyPrintType = [OrderTabEnum.PAYMENT_SUCCESS, OrderTabEnum.ALL].includes(
      orderTab,
    )
    const header = [
      'ลำดับ',
      'เลขที่คำสั่งซื้อ',
      'วันที่สั่งซื้อ',
      isTabForPolicyNumber ? 'เลขที่กรมธรรม์' : undefined,
      isTabForTotalOrderItem ? 'จำนวนผู้เดินทาง' : undefined,
      'ชื่อผู้ติดต่อ',
      'เบอร์โทรผู้ติดต่อ',
      'อีเมลผู้ติดต่อ',
      'ชื่อผู้เดินทาง',
      'เบอร์โทรผู้เดินทาง',
      'ID/Passport No.',
      'แผนประกัน',
      'ประเภทประกันภัย',
      'ค่าเบี้ย/ผู้เดินทาง',
      'ยอดค่าเบี้ยชำระรวม',
      'วันเริ่มความคุ้มครอง',
      'วันสิ้นสุดความคุ้มครอง',
      isTabForPaymentType ? 'ประเภทการชำระ' : undefined,
      isTabForPaymentRemark ? 'หมายเหตุการชำระ' : undefined,
      isTabForPromotion ? 'โปรโมชัน' : undefined,
      isTabForPromotion ? 'โปรโมชั่นหมดอายุ' : undefined,
      isTabForPromotion ? 'PROMO CODE' : undefined,
      isTabForStatus ? 'สถานะ' : undefined,
      isTabForFailVerified ? 'เหตุผลไม่ผ่านการตรวจสอบ' : undefined,
      isTabForPolicyPrintType ? 'รูปแบบจัดส่ง กธ.' : undefined,
      'ซื้อผ่านช่องทาง',
    ].filter((column) => column !== undefined)
    const reasonText = (applicationForm: ApplicationFormType) =>
      applicationForm.status === ApplicationFormStatusEnum.FAIL_VERIFIED
        ? 'ไม่ผ่าน AMLO (ไม่แจ้งลูกค้าโดยตรง ปรึกษา Innovation Tech Support)'
        : '-'

    const statusText = (orderTab: OrderTabEnum, orderItem: OrderItemType) => {
      const tab = findOrderTab(
        orderItem?.order?.status,
        orderItem?.applicationForm?.status,
        orderItem?.order?.effectiveDate,
      )

      return tab ? ORDER_TAB[tab] : '-'
    }

    const paymentTypeText = (payment: Payment) => {
      if (!payment) return '-'

      const paymentTypeId: keyof typeof PAYMENT_TYPE =
        payment?.paymentType?.id.toString() as keyof typeof PAYMENT_TYPE
      const paymentTypeName = PAYMENT_TYPE[paymentTypeId] ?? '-'

      return paymentTypeName === PAYMENT_TYPE[4]
        ? `${paymentTypeName} ${payment?.omiseMobileBanking?.abbreviation || ''}`
        : paymentTypeName
    }

    const result = orderItems.map((orderItem, index) => {
      const {
        order,
        order: { contactPerson, plan },
        applicationForm,
        addressPolicy,
      } = orderItem
      const contactPersonName = `${contactPerson.title.titleEn} ${contactPerson.firstname} ${contactPerson.lastname}`
      const applicationFormName = `${applicationForm.title.titleEn} ${applicationForm.firstname} ${applicationForm.lastname}`
      const identity = applicationForm.identityId || applicationForm.passportId
      const planName = `ประกันภัยการเดินทาง ${plan?.product?.nameTh} ${plan.code} ${plan.nameTh}`
      const expiryDate = isTimesEqual(order.expiryDate, '23:59')
        ? dayjs(order.expiryDate).format('DD-MM-YYYY 24:00')
        : dayjs(order.expiryDate).format('DD-MM-YYYY HH:mm')
      const paymentType = isTabForPaymentType ? paymentTypeText(order.payment) : undefined
      const paymentRemark = isTabForPaymentRemark ? order.payment?.status ?? '-' : undefined
      const promotion = isTabForPromotion ? order.promotionDetail ?? '-' : undefined
      const promotionExpire = isTabForPromotion
        ? order.promotionExpire
          ? dayjs(order.promotionExpire).format('DD-MM-YYYY')
          : '-'
        : undefined
      const promotionCode = isTabForPromotion ? order.promotionCode ?? '-' : undefined
      const status = isTabForStatus ? statusText(orderTab, orderItem) : undefined
      const failVerifiedReason = isTabForFailVerified ? reasonText(applicationForm) : undefined
      const policyPrintType = getPolicyPrintType(isTabForPolicyPrintType, addressPolicy)
      const purchaseChannel = 'แอปพลิเคชัน'
      const totalOrderItemText = isTabForTotalOrderItem
        ? order.totalOrderItem > 1
          ? `${applicationForm?.insuredNo + 1}/${order?.totalOrderItem}`
          : order?.totalOrderItem
        : undefined

      return [
        index + 1,
        order.orderNumber,
        dayjs(order.orderDate).format('DD-MM-YYYY HH:mm:ss'),
        isTabForPolicyNumber ? orderItem.policyNumber : undefined,
        totalOrderItemText,
        contactPersonName,
        `${contactPerson.mobileNumber}`,
        contactPerson.email,
        applicationFormName,
        `${applicationForm.mobileNumber}`,
        identity,
        planName,
        plan.product.type.nameTh,
        orderItem.amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        order.premiumAmount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        dayjs(order.effectiveDate).format('DD-MM-YYYY HH:mm'),
        expiryDate,
        paymentType,
        paymentRemark,
        promotion,
        promotionExpire,
        promotionCode,
        status,
        failVerifiedReason,
        policyPrintType,
        purchaseChannel,
      ].filter((data) => data !== undefined)
    })

    return [header, ...result]
  }

  const fetchExportOrder = useExportOrder({
    tab: orderTab,
    from: dateValue[0] ? dayjs(dateValue[0]).format('YYYY-MM-DD') : '',
    to: dateValue[1] ? dayjs(dateValue[1]).format('YYYY-MM-DD') : '',
  })

  useEffect(() => {
    const startDate = dateValue[0]
    const endDate = dateValue[1]

    if (startDate && endDate && dayjs(endDate).diff(startDate, 'days') > MAXIMUM_DATE_RANGE) {
      message.error('ไม่สามารถดึงข้อมูลเกิน 90 วัน')
      setDateValue([startDate, null])
    }
  }, [dateValue])

  useEffect(() => {
    if (fetchExportOrder.isFetched && !fetchExportOrder.data?.length) {
      message.warning('ไม่พบข้อมูลที่ต้องการดึงในช่วงวันที่เลือก')
    }
  }, [fetchExportOrder.data, fetchExportOrder.isFetched])

  return {
    isEnabled,
    isModalVisible,
    setIsModalVisible,
    handleCloseModal,
    dateValue,
    setDateValue,
    onClickCsvLink,
    handleDateValue,
    fetchExportOrder,
    transformToCsvData,
  }
}

export default TaTransactionExporterHook
