import FrequencyQuestionsPage from '@/components/feature/FrequencyQuestionsPage/FrequencyQuestions'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import UnauthorizedPage from '@/pages/Unauthorized'

const FrequencyQuestions = () => {
  const user = useRecoilValue(userAtom)

  return (
    <>
      {validatePerm([BACKOFFICE_USER_PERMISSION.FAQ010101], user.permissions) ? (
        <FrequencyQuestionsPage />
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default FrequencyQuestions
