import { gql } from '@apollo/client'
import { TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetChargingLocationsByPolicyNumberDataInterface,
  BackofficeGetChargingLocationByPolicyNumberInputInterface,
} from './interface'

const backofficeGetChargingLocationsByPolicyNumber: TypedDocumentNode<
  BackofficeGetChargingLocationsByPolicyNumberDataInterface,
  BackofficeGetChargingLocationByPolicyNumberInputInterface
> = gql`
  query ($input: BackofficeGetChargingLocationByPolicyNumberInput!) {
    backofficeGetChargingLocationsByPolicyNumber(input: $input) {
      id
      name
      address
      lat
      lng
    }
  }
`

export default backofficeGetChargingLocationsByPolicyNumber
