import dayjs from 'dayjs'

export const isTimesEqual = (time1: dayjs.ConfigType, time2: string): boolean => {
  const formatTime = (time: dayjs.ConfigType) => {
    return dayjs(time).format('HH:mm')
  }

  const time2Formatted = dayjs(time2, 'HH:mm').format('HH:mm')

  return formatTime(time1) === time2Formatted
}
