import styled from '@emotion/styled'
import { Input as AntInput, Form as AntForm } from 'antd'
import CommonButton from '@/components/common/Button'

import { PasswordInputProps, ContainerProps } from './types'

export const Container = styled('div')<ContainerProps>`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 72px 173px;
  width: 730px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`
export const Title = styled('div')`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  margin-bottom: 16px;
`
export const Subtitle = styled('div')`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
`

export const List = styled('ol')`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 16px;
`

export const Form = styled(AntForm)``

export const FormItem = styled(AntForm.Item)`
  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
`

export const PasswordInput = styled(AntInput.Password)<PasswordInputProps>`
  border-radius: 4px;
`

export const Button = styled(CommonButton)`
  width: 100%;
`
