import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { Radio as AntdRadio } from 'antd'

export const ActionText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #009640;
  cursor: pointer;
`

export const EyeIcon = styled('img')`
  cursor: pointer;
  font-size: 20px;
  color: #666666;
`

export const SelectedRow = css`
  background-color: #e6f6ea;
`

export const RadioGroup = styled(AntdRadio.Group)`
  margin-top: 8px;
  margin-bottom: 24px;

  .ant-radio-button-wrapper {
    font-size: 16px !important;

    ::before {
      :hover {
        background-color: #d9d9d9 !important;
      }
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: #f6f6f6 !important;
    color: #000000 !important;
    border-color: #d9d9d9 !important;

    ::before {
      background-color: #d9d9d9 !important;

      :hover {
        background-color: #d9d9d9 !important;
      }
    }
  }
`

export const TotalRecordText = styled('span')`
  font-size: 14px;
  color: #666666;
  margin-left: 16px;
`

export const UserSelectStyle = css`
  user-select: text;
`
