import { Container, ContainerTitle, SearchIcon, SearchInput } from './styles'
import TaTransactionResendPolicyModal from '../TaTransactionResendMailModal'
import TaTransactionTableHook from './TaTransactionTable.hook'
import TaTransactionExporter from '../TaTransactionExporter'
import Table from '@/components/common/Table'

const TaTransactionTable = () => {
  const {
    fetchOrderPagination,
    placeholder,
    columns,
    handleOnChangeSearch,
    handleOnChangeTable,
    currentSearch,
    currentPage,
    currentPageSize,
    selectedSendMailPolicy,
    isModalOpen,
    cancelModal,
  } = TaTransactionTableHook()

  return (
    <Container>
      <ContainerTitle>
        <SearchInput
          id="ta-transaction-search"
          placeholder={placeholder}
          prefix={<SearchIcon />}
          data-testid="ta-transaction-search-input"
          onChange={handleOnChangeSearch}
          value={currentSearch}
        />
        <TaTransactionExporter />
      </ContainerTitle>
      <Table
        data-testid="ta-transaction-table"
        rowKey="id"
        columns={columns.filter((column) => column.width !== 0)}
        loading={fetchOrderPagination.isFetching}
        dataSource={fetchOrderPagination.data?.data}
        scroll={{
          x: 'max-content',
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: fetchOrderPagination.data?.meta?.totalItems,
          defaultCurrent: 1,
          current: currentPage,
          defaultPageSize: 10,
          pageSize: currentPageSize,
        }}
        onChange={handleOnChangeTable}
      />
      {selectedSendMailPolicy && (
        <TaTransactionResendPolicyModal
          policyMail={selectedSendMailPolicy.email}
          applicationFormId={selectedSendMailPolicy.applicationFormId}
          open={isModalOpen}
          onCancel={cancelModal}
        />
      )}
    </Container>
  )
}

export default TaTransactionTable
