import styled from '@emotion/styled'
import { Skeleton as AntdSkeleton } from 'antd'

export const Container = styled('div')``

export const LeftSubContainer = styled('div')``

export const RightSubContainer = styled('div')``

export const Card = styled('div')<{ displayBlock?: boolean }>`
  display: ${(props) => (props.displayBlock ? 'block' : 'grid')};
  grid-template-columns: auto 1fr;
  padding: 32px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;
`

export const Header = styled('div')`
  margin-bottom: 24px;
  display: flex;
  justify-content: right;
`

export const Body = styled('div')`
  margin-bottom: 40px;
`

export const Footer = styled('div')`
  display: flex;
  justify-content: center;
`

export const SkeletonContainer = styled('div')`
  display: grid;
  width: 234px;
  padding-right: 24px;
`

export const Skeleton = styled(AntdSkeleton)`
  margin: 4px 0;
`

export const SkeletonButton = styled(AntdSkeleton.Button)`
  width: 150px !important;
`
