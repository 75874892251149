import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ColumnsType } from 'antd/lib/table'
import resetWatchDeviceModalAtom from '@/recoil/resetWatchDeviceModal'
import { ActionText } from './styles'
import { BackofficeListActiveHealthPolicyIncludeWatchBranchType } from '@/gql/activeHealthPolicy/backofficeListActiveHealthPolicyIncludeWatchBranch/type'
import dayjs from 'dayjs'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import hardResetWatchDeviceModalAtom from '@/recoil/hardResetWatchDeviceModal/atom'

export default function WatchDeviceTableHook() {
  const setResetWatchDeviceModal = useSetRecoilState(resetWatchDeviceModalAtom)
  const setHardResetWatchDeviceModal = useSetRecoilState(hardResetWatchDeviceModalAtom)
  const user = useRecoilValue(userAtom)

  const columns: ColumnsType<BackofficeListActiveHealthPolicyIncludeWatchBranchType | {}> = [
    {
      title: 'นาฬิกา',
      key: 'brand',
      render: (data) => data.watchBrand.name,
    },
    {
      title: 'รุ่น',
      dataIndex: 'watchModel',
      key: 'watchModel',
      render: (watchModel) => watchModel || '-',
    },
    {
      title: 'S/N',
      dataIndex: 'watchSerialNumber',
      key: 'watchSerialNumber',
      render: (watchSerialNumber) => watchSerialNumber || '-',
    },
    {
      title: 'ดึงข้อมูลล่าสุด',
      dataIndex: 'watchLastSyncedAt',
      key: 'watchLastSyncedAt',
      render: (watchLastSyncedAt) =>
        watchLastSyncedAt ? (
          <div>{dayjs(watchLastSyncedAt).format('DD-MM-YYYY HH:mm:ss')}</div>
        ) : (
          '-'
        ),
    },
    validatePerm([BACKOFFICE_USER_PERMISSION.HLT010110], user.permissions)
      ? {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 150,
          render: (
            _value,
            record: BackofficeListActiveHealthPolicyIncludeWatchBranchType | {},
            index,
          ) => {
            const deviceId = 'id' in record ? record.id : undefined
            return (
              <ActionText
                onClick={() => (deviceId ? handleOpenHardResetModal(deviceId) : undefined)}
                data-testid={`policy-detail-device-tab-smartwatches-table-reset-device-button-${index}`}
              >
                ฮาร์ดรีเซ็ตอุปกรณ์
              </ActionText>
            )
          },
        }
      : {
          width: 0,
        },
    validatePerm([BACKOFFICE_USER_PERMISSION.HLT010109], user.permissions)
      ? {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          render: (
            _value,
            record: BackofficeListActiveHealthPolicyIncludeWatchBranchType | {},
            index,
          ) => {
            const deviceId = 'id' in record ? record.id : undefined
            return (
              <ActionText
                onClick={() => (deviceId ? handleOpenModal(deviceId) : undefined)}
                data-testid={`policy-detail-device-tab-smartwatches-table-reset-device-button-${index}`}
              >
                รีเซ็ตอุปกรณ์
              </ActionText>
            )
          },
        }
      : {
          width: 0,
        },
  ]

  const handleOpenModal = (deviceId?: string) => {
    if (deviceId)
      return setResetWatchDeviceModal({ open: true, id: deviceId, isRefetchWatchDevice: false })
  }


  const handleOpenHardResetModal = (deviceId?: string) => {
    if (deviceId)
      return setHardResetWatchDeviceModal({ open: true, id: deviceId, isRefetchWatchDevice: false })
  }

  return { columns }
}
