import styled from '@emotion/styled'

export const Notification = styled('div')`
  background: #e2f2ff;
  border-radius: 8px;
  padding: 8px;
  margin-left: 10px;
  display: flex;
  align-items: center;
`

export const FlashIcon = styled('img')`
  margin-right: 8px;
`

export const Text = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #276ef1;
  line-height: 1.5;
`

export const TextBlock = styled('div')``
