import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'
import { useQuery } from '@tanstack/react-query'
import { ReturnTimeReasonType } from '@/services/returnTimeReason/useReturnTimeReason/types'

const fetchReturnTimeReason = async (ppuMotorReturnTimeId: string) => {
  const response = await httpClientPrivate.get<ReturnTimeReasonType[]>(
    `${config.restUrl}/v1/backoffice-ppu-motor/returns-time/reasons/${ppuMotorReturnTimeId}`,
  )
  return response.data
}

const useReturnTimeReason = (query: { ppuMotorReturnTimeId: string }) => {
  const queryKey = ['useReturnTimeReason', query]
  const queryFn = async () => fetchReturnTimeReason(query.ppuMotorReturnTimeId)
  const queryResult = useQuery({ queryKey, queryFn })
  return { ...queryResult }
}

export { fetchReturnTimeReason, useReturnTimeReason }
