import { activeHealthPartnerGql } from '@/gql'
import { useLazyQuery } from '@apollo/client'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

function WellBeingPartnerInfoHook() {
  const [
    backofficeGetActiveHealthPartnerById,
    { data: activeHealthPartnerData, loading: activeHealthPartnerLoading },
  ] = useLazyQuery(activeHealthPartnerGql.query.backofficeGetActiveHealthPartnerById, {
    fetchPolicy: 'no-cache',
  })
  const { partnerId } = useParams<{ partnerId: string }>()
  const getActiveHealthPartnerById = useCallback(
    (id: string) => {
      backofficeGetActiveHealthPartnerById({
        variables: {
          input: {
            id: id,
            relations: ['activeHealthPartnerCategory'],
          },
        },
      })
    },
    [backofficeGetActiveHealthPartnerById],
  )

  useEffect(() => {
    if (partnerId) {
      getActiveHealthPartnerById(partnerId)
    }
  }, [partnerId])

  return {
    activeHealthPartnerData,
    activeHealthPartnerLoading,
  }
}

export default WellBeingPartnerInfoHook
