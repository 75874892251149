import dayjs from 'dayjs'
import {
  AllTypeFilterButton,
  Block,
  CalledFilterButton,
  CallTypeFilterButtonBlock,
  Container,
  CountBlock,
  DateFilterButton,
  DateFilterButtonBlock,
  FilterButtonBlock,
  Header,
  HeaderBlock,
  ImportButton,
  ImportIcon,
  LastSyncText,
  LeftArrowButton,
  NotCallFilterButton,
  RightArrowButton,
  SearchIcon,
  SearchInput,
  Success,
  Title,
  Total,
  SelectedRow,
  SkeletonInput,
} from './styles'
import WelcomeCallTableHook from './WelcomeCallTable.hook'
import { CALL_TYPE, CallTypeEnum, CallTypeFilterEnum } from '@/constant/CALL_TYPE'
import { toThaiLocaleDateString } from '@/libs/date'
import { IMAGE_URL } from '@/constant/IMAGE'
import Table from '@/components/common/Table'
import { WelcomeCallTableProps } from './types'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'

function WelcomeCallTable({ openWelcomeCallImporter }: WelcomeCallTableProps) {
  const {
    columns,
    filters,
    selectIndex,
    selectCallType,
    setSelectCallType,
    transitionFilterDateButton,
    welcomeCalls,
    welcomeCallPagination,
    handleOnChangeTable,
    handleOnClickDateFilter,
    currentPage,
    currentPageSize,
    handleOnSearchInputChange,
    selectedRowKeys,
    user,
    isTableLoading,
    dateFilterWithCountCallStatusLoading,
  } = WelcomeCallTableHook()

  return (
    <Container>
      <Header>
        <HeaderBlock>
          <Title>รายชื่อลูกค้าซื้อประกัน</Title>
        </HeaderBlock>
        <HeaderBlock>
          <LastSyncText>ดึงข้อมูลล่าสุด {dayjs().format('DD-MM-YYYY HH:mm:ss')}</LastSyncText>
          {validatePerm([BACKOFFICE_USER_PERMISSION.WEL010204], user.permissions) ? (
            <ImportButton
              onClick={openWelcomeCallImporter}
              data-testid="welcome-call-import-file-button"
            >
              <ImportIcon src={IMAGE_URL.importIcon} /> Import ข้อมูลการจัดส่ง
            </ImportButton>
          ) : undefined}
        </HeaderBlock>
        <SearchInput
          id="welcome-call-search"
          data-testid="welcome-call-search-input"
          prefix={<SearchIcon />}
          placeholder="ค้นหาชื่อผู้เอาประกัน, เบอร์โทรศัพท์, เลขอ้างอิง"
          onChange={() => handleOnSearchInputChange(true)}
        />
      </Header>
      <Block>
        <LeftArrowButton
          onClick={() =>
            transitionFilterDateButton({ direction: 'previous', index: selectIndex - 1 })
          }
          disabled={selectIndex === 0}
          data-testid="welcome-call-previous-arrow-button"
        />
        <FilterButtonBlock id="filter-button-block">
          {dateFilterWithCountCallStatusLoading ? (
            <>
              <SkeletonInput active size="large" />
              <SkeletonInput active size="large" />
              <SkeletonInput active size="large" />
              <SkeletonInput active size="large" />
              <SkeletonInput active size="large" />
              <SkeletonInput active size="large" />
            </>
          ) : (
            filters.length > 0 &&
            filters.map((filter, index) => (
              <DateFilterButton
                id={index.toString()}
                select={selectIndex === index}
                onClick={() => handleOnClickDateFilter(index, filter.date)}
              >
                <DateFilterButtonBlock>
                  {toThaiLocaleDateString({ date: filter.date, isUtc: true })}
                  <CountBlock>
                    <Success>{filter.complete}</Success>
                    <Total>/{filter.total}</Total>
                  </CountBlock>
                </DateFilterButtonBlock>
              </DateFilterButton>
            ))
          )}
        </FilterButtonBlock>
        <RightArrowButton
          onClick={() => transitionFilterDateButton({ direction: 'next', index: selectIndex + 1 })}
          disabled={selectIndex + 1 === filters.length}
          data-testid="welcome-call-next-arrow-button"
        />
      </Block>
      <CallTypeFilterButtonBlock>
        <AllTypeFilterButton
          select={selectCallType === CallTypeFilterEnum.ALL}
          onClick={() => setSelectCallType(CallTypeFilterEnum.ALL)}
          data-testid="welcome-call-filter-all-button"
        >
          {CALL_TYPE[CallTypeEnum.ALL]}
        </AllTypeFilterButton>
        <NotCallFilterButton
          select={selectCallType === CallTypeFilterEnum.PENDING}
          onClick={() => setSelectCallType(CallTypeFilterEnum.PENDING)}
          data-testid="welcome-call-filter-pending-button"
        >
          {CALL_TYPE[CallTypeEnum.PENDING]}
        </NotCallFilterButton>
        <NotCallFilterButton
          select={selectCallType === CallTypeFilterEnum.NOT_RECEIVE}
          onClick={() => setSelectCallType(CallTypeFilterEnum.NOT_RECEIVE)}
          data-testid="welcome-call-filter-not-receive-button"
        >
          {CALL_TYPE[CallTypeEnum.NOT_RECEIVE]}
        </NotCallFilterButton>
        <NotCallFilterButton
          select={selectCallType === CallTypeFilterEnum.BUSY}
          onClick={() => setSelectCallType(CallTypeFilterEnum.BUSY)}
          data-testid="welcome-call-filter-busy-button"
        >
          {CALL_TYPE[CallTypeEnum.BUSY]}
        </NotCallFilterButton>
        <NotCallFilterButton
          select={selectCallType === CallTypeFilterEnum.CALLED}
          onClick={() => setSelectCallType(CallTypeFilterEnum.CALLED)}
          data-testid="welcome-call-filter-call-button"
        >
          {CALL_TYPE[CallTypeEnum.CALLED]}
        </NotCallFilterButton>
        <CalledFilterButton
          select={selectCallType === CallTypeFilterEnum.NOT_AVAILABLE}
          onClick={() => setSelectCallType(CallTypeFilterEnum.NOT_AVAILABLE)}
          data-testid="welcome-call-filter-cancel-button"
        >
          {CALL_TYPE[CallTypeEnum.NOT_AVAILABLE]}
        </CalledFilterButton>
      </CallTypeFilterButtonBlock>
      <Table
        data-testid="welcome-call-table"
        loading={isTableLoading}
        rowClassName={(_value, index) => (index === selectedRowKeys[0] ? SelectedRow : '')}
        dataSource={welcomeCalls || []}
        columns={columns}
        scroll={{
          x: '230vw',
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: welcomeCallPagination?.totalItems,
          current: currentPage,
          defaultCurrent: 1,
          pageSize: currentPageSize,
          defaultPageSize: 10,
        }}
        onChange={(pagination, filters, sorter) => {
          handleOnChangeTable({ pagination, sorter, filters })
        }}
        totalRecord={welcomeCallPagination?.totalItems}
      />
    </Container>
  )
}

export default WelcomeCallTable
