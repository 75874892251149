import { useRecoilState, useResetRecoilState } from 'recoil'

import sideBarInformationAtom from '@/recoil/sideBarInformation'
import delay from '@/libs/delay'

function SideBarInformationHook() {
  const [sideBarInformation, setSideBarInformation] = useRecoilState(sideBarInformationAtom)
  const resetSideBarInformation = useResetRecoilState(sideBarInformationAtom)
  const onCloseSideBar = async () => {
    await setSideBarInformation({
      visible: false,
      title: sideBarInformation.title,
      children: sideBarInformation.children,
      dataTestId: sideBarInformation.dataTestId,
    })

    await delay(500)

    await resetSideBarInformation()
  }

  const onCloseSideBarChildren = async () => {
    await setSideBarInformation({
      visible: sideBarInformation.visible,
      title: sideBarInformation.title,
      children: sideBarInformation.children,
      dataTestId: sideBarInformation.dataTestId,
      sideBarChildrenVisible: false,
      sideBarChildrenTitle: sideBarInformation.sideBarChildrenTitle,
      sideBarChildren: sideBarInformation.sideBarChildren,
      childrenDataTestId: sideBarInformation.childrenDataTestId,
    })

    await delay(500)

    await setSideBarInformation({
      visible: sideBarInformation.visible,
      title: sideBarInformation.title,
      children: sideBarInformation.children,
      dataTestId: sideBarInformation.dataTestId,
      sideBarChildrenVisible: false,
      sideBarChildrenTitle: null,
      sideBarChildren: null,
      childrenDataTestId: undefined,
    })
  }

  return { sideBarInformation, resetSideBarInformation, onCloseSideBarChildren, onCloseSideBar }
}

export default SideBarInformationHook
