import { Title, Container, SubContainer } from './styles'

function UnauthorizedPage() {
  return (
    <Container>
      <SubContainer>
        <Title>คุณไม่มีสิทธิเข้าถึงหน้านี้</Title>
      </SubContainer>
    </Container>
  )
}

export default UnauthorizedPage
