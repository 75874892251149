import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthPolicyInputInterface,
  BackofficeGetActiveHealthPolicyInterface,
} from './interfaces'

const backofficeGetActiveHealthPolicyByUserId: TypedDocumentNode<
  BackofficeGetActiveHealthPolicyInterface,
  BackofficeGetActiveHealthPolicyInputInterface
> = gql`
  query BackofficeGetActiveHealthPolicy($userId: ID!, $policyNumber: String!) {
    backofficeGetActiveHealthPolicy(userId: $userId, policyNumber: $policyNumber) {
      watchLastSyncedAt
    }
  }
`

export default backofficeGetActiveHealthPolicyByUserId
