import { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import backofficeTeam from '@/gql/backofficeTeam'
import { useRecoilState, useRecoilValue } from 'recoil'
import selectedTeamPermissionAtom from '@/recoil/selectedTeamPermission/atom'
import addTeamMemberModalAtom from '@/recoil/addTeamMemberModal/atom'
import { ModeUserEnum } from '@/recoil/userModal/types'
import ModalAddUserHook from '../ModalAddUser/ModalAddUser.hook'
import { BackofficeListBackofficeRoleDataInterface } from '@/gql/backofficeTeam/backofficeListBackofficeRole/types'
import message from '@/libs/message'
import { UserValueType } from './types'

const ModalAddTeamMemberHook = () => {
  const { setUserModal } = ModalAddUserHook()
  const selectedTeam = useRecoilValue(selectedTeamPermissionAtom)
  const [userValue, setUserValue] = useState<UserValueType[]>([])
  const [roleId, setRoleId] = useState<string | undefined>()
  const [disabledSave, setDisabledSave] = useState<boolean>(true)
  const [addTeamMemberModal, setAddTeamMemberModal] = useRecoilState(addTeamMemberModalAtom)
  const {
    isVisible,
    onOkFunction,
    okButtonProps,
    onCancelFunction: cancelFunction,
  } = addTeamMemberModal

  const [getBackofficeListBackofficeRoleQuery, { loading: loadBackofficeListBackofficeRoleQuery }] =
    useLazyQuery(backofficeTeam.query.backofficeListBackofficeRole, {
      fetchPolicy: 'no-cache',
      onCompleted: (data: BackofficeListBackofficeRoleDataInterface) => {
        if (data.backofficeListBackofficeRole.length > 0) {
          const role = data.backofficeListBackofficeRole.find((data) => data.isDefault)

          if (role) setRoleId(role.id)
        }
      },
    })

  const [createBackofficeBulkAssignUsersToTeam, { loading: loadBackofficeBulkAssignUsersToTeam }] =
    useMutation(backofficeTeam.mutation.backofficeBulkAssignUsersToTeam)

  const onOpenAddUserTeamMember = () => {
    setUserModal({
      title: 'สร้างผู้ใช้งาน',
      onOkFunction: () => {
        setUserModal({
          isVisible: false,
          onOkFunction: Function,
          mode: ModeUserEnum.CREATE,
          okButtonProps: { type: 'primary', danger: false },
        })
      },
      isVisible: true,
      okButtonProps: { type: 'primary', danger: false },
      mode: ModeUserEnum.CREATE,
    })
  }

  const onCancelFunction = () => {
    if (cancelFunction) {
      cancelFunction()
    }
    setUserValue([])
    setAddTeamMemberModal({
      isVisible: false,
    })
  }

  const onSubmit = async () => {
    if (userValue.length > 0 && selectedTeam.id && roleId) {
      const users = userValue.map((user) => user.value)

      await createBackofficeBulkAssignUsersToTeam({
        variables: {
          input: {
            userIds: users,
            teamId: selectedTeam.id,
            roleId: roleId,
          },
        },
      })
        .then(() => {
          if (onOkFunction) {
            onOkFunction()
          }
          setUserValue([])
          setAddTeamMemberModal({
            isVisible: false,
          })
          message.success({
            content: 'เพิ่มผู้ใช้งานสำเร็จ',
            duration: 10,
          })
        })
        .catch((e) => {
          console.log('e ===>', e)
        })
    }
  }

  const isLoadButton = loadBackofficeListBackofficeRoleQuery || loadBackofficeBulkAssignUsersToTeam

  const buttonProps = { ...okButtonProps, disabled: disabledSave, loading: isLoadButton }

  useEffect(() => {
    if (userValue.length > 0) {
      setDisabledSave(false)
    } else {
      setDisabledSave(true)
    }
  }, [userValue.length])

  useEffect(() => {
    if (isVisible) getBackofficeListBackofficeRoleQuery()
  }, [isVisible])

  return {
    isVisible,
    buttonProps,
    onSubmit,
    onCancelFunction,
    userValue,
    setUserValue,
    onOpenAddUserTeamMember,
  }
}

export default ModalAddTeamMemberHook
