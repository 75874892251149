import { ActivityFilterEnum } from '@/constant/ACTIVITY_FILTER'
import { ActivityTextCustomStyle, Container, StepTextCustomStyle, Text } from './styles'
import ActivityFilterHook from './ActivityFilter.hook'
import { ActivityFilterProps } from './types'

function ActivityFilter({ handleSelectedActivityFilter }: ActivityFilterProps) {
  const { filterSelected, handleSetFilter } = ActivityFilterHook(handleSelectedActivityFilter)

  return (
    <Container>
      <Text
        select={filterSelected === ActivityFilterEnum.STEPS}
        onClick={() => handleSetFilter(ActivityFilterEnum.STEPS)}
        className={StepTextCustomStyle}
        data-testid="policy-detail-exercise-tab-activity-filter-step"
      >
        จำนวนก้าว
      </Text>
      <Text
        select={filterSelected === ActivityFilterEnum.ACTIVITY_DURATION}
        onClick={() => handleSetFilter(ActivityFilterEnum.ACTIVITY_DURATION)}
        className={ActivityTextCustomStyle}
        data-testid="policy-detail-exercise-tab-activity-filter-activity-duration"
      >
        นาทีออกกำลังกาย
      </Text>
    </Container>
  )
}

export default ActivityFilter
