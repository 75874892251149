import { atom } from 'recoil'

import { ResetBackofficeUserPasswordProps } from './types'

const initResetBackofficeUserPassword = {
  firstname: null,
  lastname: null,
  email: null,
  staffId: null,
  updatedAt: null,
  isSuccessful: false,
} as ResetBackofficeUserPasswordProps

const resetBackofficeUserPasswordAtom = atom({
  key: 'resetBackofficeUserPasswordAtom',
  default: initResetBackofficeUserPassword,
})

export default resetBackofficeUserPasswordAtom
