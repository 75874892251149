import { Container, IconLoading, SpinAntd, Title } from './styles'

function LoadingBootstrap() {
  return (
    <Container>
      <SpinAntd indicator={<IconLoading style={{ fontSize: 55 }} spin />} />
      <Title>โปรดรอสักครู่... เรากำลังพาคุณเข้าสู่ระบบ</Title>
    </Container>
  )
}

export default LoadingBootstrap
