import { CSVLink } from 'react-csv'
import { DownloadOutlined } from '@ant-design/icons'
import Table from '@/components/common/Table'
import { Container, Button, TableHeader, Title, TableClassName } from './styles'
import DeviceImportFailHook from './DeviceImportFailureTable.hook'

function DeviceImportFailureTable() {
  const { failResults, columns } = DeviceImportFailHook()

  return (
    <Container visible={failResults.length > 0}>
      <TableHeader>
        <Title>รายการที่ไม่สำเร็จ</Title>
        <CSVLink data={failResults} target="_blank">
          <Button>
            <DownloadOutlined />
            Export
          </Button>
        </CSVLink>
      </TableHeader>
      <Table dataSource={failResults} columns={columns} className={TableClassName} />
    </Container>
  )
}

export default DeviceImportFailureTable
