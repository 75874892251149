import { gql, TypedDocumentNode } from '@apollo/client'
import {
  UpdateOrderBackofficeFAQInfoDataInterface,
  UpdateOrderBackofficeFAQInfoInputInterface,
} from './interfaces'

const updateOrderBackofficeFAQInfo: TypedDocumentNode<
  UpdateOrderBackofficeFAQInfoDataInterface,
  UpdateOrderBackofficeFAQInfoInputInterface
> = gql`
  mutation UpdateOrderBackofficeFAQInfo($input: UpdateOrderBackofficeFAQInfoActiveHealthInput!) {
    updateOrderBackofficeFAQInfo(input: $input) {
      id
      faqCategoryId
      title
      description
      status
      createBy {
        id
      }
      createAt
      order
    }
  }
`

export default updateOrderBackofficeFAQInfo
