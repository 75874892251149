import changeCreditCardModalAtom from '@/recoil/changeCreditCardModal'
import { useRecoilState } from 'recoil'

function ChangeCreditCardModalHook() {
  const [changeCreditCardModal, setChangeCreditCardModal] =
    useRecoilState(changeCreditCardModalAtom)

  const handleCloseChangeCreditCardModal = () => {
    setChangeCreditCardModal({
      open: false,
    })
  }
  return { handleCloseChangeCreditCardModal, changeCreditCardModal }
}

export default ChangeCreditCardModalHook
