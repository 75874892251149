import styled from '@emotion/styled'

export const Container = styled('div')`
  width: 100%;
  padding: 32px;
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 24px;
  padding: 10px 0;
`
