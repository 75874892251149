import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeActiveHealthSpecialPointHighlightInterface,
  BackofficeActiveHealthSpecialPointHighlightInputInterface,
} from './interfaces'

export const backofficeGetActiveHealthSpecialPointHighlight: TypedDocumentNode<
  BackofficeActiveHealthSpecialPointHighlightInterface,
  BackofficeActiveHealthSpecialPointHighlightInputInterface
> = gql`
  query backofficeGetActiveHealthSpecialPointHighlight(
    $input: GetActiveHealthSpecialPointHighlightInput!
  ) {
    backofficeGetActiveHealthSpecialPointHighlight(input: $input) {
      descriptionTh
    }
  }
`

export default backofficeGetActiveHealthSpecialPointHighlight
