import { atom } from 'recoil'

import { DiseaseModalProps } from './types'

const initDiseaseModal: DiseaseModalProps = {
  open: false,
  diseases: null,
}

const diseaseModalAtom = atom({
  key: 'diseaseModalAtom',
  default: initDiseaseModal,
})

export default diseaseModalAtom
