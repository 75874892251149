// Ref: https://ant.design/components/message
import { message as antdMessage } from 'antd'
import { ArgsProps, MessageType, JointContent, TypeOpen } from 'antd/lib/message/interface'

interface MessageMethods {
  info: TypeOpen
  success: TypeOpen
  error: TypeOpen
  warning: TypeOpen
  loading: TypeOpen
}

const defaultContent = {
  style: {
    fontFamily: 'Prompt',
    fontSize: 14,
  },
  duration: 3,
} as ArgsProps

const getContent = (currentContent: JointContent): JointContent => {
  const content = {} as ArgsProps
  if ((currentContent as ArgsProps).content) {
    const {
      content: ct,
      className,
      duration,
      onClose,
      style,
      type,
      key,
      onClick,
      icon,
    } = currentContent as ArgsProps

    content.content = ct
    content.className = className
    content.duration = duration || defaultContent.duration
    content.onClose = onClose
    content.style = {
      ...style,
      ...defaultContent.style,
    }
    content.type = type
    content.key = key
    content.onClick = onClick
    content.icon = icon

    return content
  }

  return currentContent
}

const message: MessageMethods = {
  /**
   * Wrap component from ant design message. Used to display info message.
   * @param content
   * @param duration
   * @param onClose
   * @returns MessageType
   */
  info: (
    content: JointContent,
    duration?: number | VoidFunction,
    onClose?: VoidFunction,
  ): MessageType => {
    const ct = getContent(content)
    return antdMessage.info(ct, duration, onClose)
  },
  /**
   * Wrap component from ant design message. Used to display success message.
   * @param content
   * @param duration
   * @param onClose
   * @returns MessageType
   */
  success: (
    content: JointContent,
    duration?: number | VoidFunction,
    onClose?: VoidFunction,
  ): MessageType => {
    const ct = getContent(content)
    return antdMessage.success(ct, duration, onClose)
  },
  /**
   * Wrap component from ant design message. Used to display warning message.
   * @param content
   * @param duration
   * @param onClose
   * @returns MessageType
   */
  warning: (
    content: JointContent,
    duration?: number | VoidFunction,
    onClose?: VoidFunction,
  ): MessageType => {
    const ct = getContent(content)
    return antdMessage.warning(ct, duration, onClose)
  },
  /**
   * Wrap component from ant design message. Used to display loading message.
   * @param content
   * @param duration
   * @param onClose
   * @returns MessageType
   */
  loading: (
    content: JointContent,
    duration?: number | VoidFunction,
    onClose?: VoidFunction,
  ): MessageType => {
    const ct = getContent(content)
    return antdMessage.loading(ct, duration, onClose)
  },
  /**
   * Wrap component from ant design message. Used to display error message.
   * @param content
   * @param duration
   * @param onClose
   * @returns MessageType
   */
  error: (
    content: JointContent,
    duration?: number | VoidFunction,
    onClose?: VoidFunction,
  ): MessageType => {
    const ct = getContent(content)
    return antdMessage.error(ct, duration, onClose)
  },
}

export default message
