import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'

import policyDetailAtom from '@/recoil/policyDetail'
import policyAtom from '@/recoil/policy'
import deviceDerailAtom from '@/recoil/deviceDetail'
import userAtom from '@/recoil/user'

function PolicyDetailHeader() {
  const [policyDetail] = useRecoilState(policyDetailAtom)
  const [policy] = useRecoilState(policyAtom)
  const resetPolicyDetail = useResetRecoilState(policyDetailAtom)
  const resetPolicy = useResetRecoilState(policyAtom)
  const resetDeviceDerail = useResetRecoilState(deviceDerailAtom)
  const user = useRecoilValue(userAtom)

  const handleResetAllState = () => {
    resetPolicy()
    resetPolicyDetail()
    resetDeviceDerail()
  }

  const handleResetPolicyStateAndDeviceDetailState = () => {
    resetPolicy()
    resetDeviceDerail()
  }

  return {
    policyDetail,
    policy,
    handleResetAllState,
    handleResetPolicyStateAndDeviceDetailState,
    user,
  }
}

export default PolicyDetailHeader
