import { Card, SearchIcon, SearchInput, Topic } from './styles'
import HelpTableHook from './HelpTable.hook'
import { SorterResult } from 'antd/es/table/interface'
import { UserHelpInterface } from '@/gql/user/backofficeGetUserHelpPagination/interfaces'
import Table from '@/components/common/Table'

export default function HelpTable() {
  const {
    columns,
    pagination,
    userHelps,
    currentPage,
    currentPageSize,
    handlePaginate,
    handleSearchInputChange,
    handleDisplayHighlightRow,
    handleTableChange,
    isTableLoading,
  } = HelpTableHook()

  return (
    <Card>
      <Topic>รายการที่ขอความช่วยเหลือ</Topic>
      <SearchInput
        id="user-help-search"
        data-testid="user-help-search-input"
        prefix={<SearchIcon />}
        placeholder="ค้นหาชื่อผู้เอาประกัน, เบอร์โทรศัพท์"
        onChange={() => handleSearchInputChange(true)}
      />
      <Table
        data-testid="user-help-table"
        loading={isTableLoading}
        columns={columns}
        dataSource={userHelps}
        scroll={{
          x: '200vw',
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: pagination?.totalItems,
          current: currentPage,
          defaultCurrent: 1,
          pageSize: currentPageSize,
          defaultPageSize: 10,
          onChange: (page, pageSize) => handlePaginate(page, pageSize),
        }}
        rowClassName={handleDisplayHighlightRow}
        onChange={(
          _pagination,
          _filters,
          sorter:
            | SorterResult<UserHelpInterface | {}>
            | Array<SorterResult<UserHelpInterface | {}>>,
        ) => handleTableChange(sorter)}
        totalRecord={pagination?.totalItems}
      />
    </Card>
  )
}
