import { useEffect, useState } from 'react'
import teamModalAtom from '@/recoil/teamModal/atom'
import { Form } from 'antd'
import { useRecoilState } from 'recoil'
import message from '@/libs/message'
import { useMutation } from '@apollo/client'
import backofficeTeam from '@/gql/backofficeTeam'
import { ModeTeamEnum } from '@/recoil/teamModal/types'
import { CreateTeamFormType } from './types'

const TeamModalHook = () => {
  const [form] = Form.useForm<CreateTeamFormType>()
  const [teamModal, setTeamModal] = useRecoilState(teamModalAtom)
  const [disabledSave, setDisabledSave] = useState<boolean>(true)

  const { mode, onOkFunction, dataTeam } = teamModal

  const onModalInvisible = () => {
    setTeamModal({
      isVisible: false,
      onOkFunction: Function,
    })
  }

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length)
    setDisabledSave(hasErrors)
  }

  const handleDisabledSave = () => {
    setDisabledSave(true)
  }

  const [createBackofficeTeamMutation] = useMutation(backofficeTeam.mutation.createBackofficeTeam, {
    fetchPolicy: 'no-cache',
  })

  const [updateBackofficeTeamMutation] = useMutation(
    backofficeTeam.mutation.backofficeUpdateBackofficeTeam,
    {
      fetchPolicy: 'no-cache',
    },
  )

  useEffect(() => {
    if (mode === ModeTeamEnum.UPDATE && dataTeam?.name) {
      form.setFieldsValue({
        name: dataTeam.name,
      })
    }
  }, [dataTeam, form, mode])

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (values) {
          if (mode === ModeTeamEnum.UPDATE && teamModal.dataTeam?.id) {
            const { data } = await updateBackofficeTeamMutation({
              variables: {
                input: {
                  id: teamModal.dataTeam.id,
                  name: values.name,
                },
              },
            })
            if (data) {
              handleDisabledSave()
              form.resetFields()
              onOkFunction()
              message.success({
                content: 'เปลี่ยนชื่อทีมสำเร็จ',
                duration: 10,
              })
            }
          }

          if (mode === ModeTeamEnum.CREATE) {
            const { data } = await createBackofficeTeamMutation({
              variables: {
                input: {
                  name: values.name,
                },
              },
            })

            if (data?.backofficeCreateBackofficeTeam) {
              onOkFunction()
              handleDisabledSave()
              form.resetFields()
              message.success({
                content: 'สร้างทีมสำเร็จ',
                duration: 10,
              })
            }
          }
        }
      })
      .catch((errorKey) => {
        const messageError: { [key: string]: string } = {
          'Error: BIZBOTEAM1001': 'ชื่อทีมซ้ำในระบบสร้างข้อมูลไม่สำเร็จ',
        }
        const error = messageError[errorKey]

        onOkFunction()
        handleDisabledSave()
        form.resetFields()

        message.error({
          content: error,
          duration: 10,
        })
      })
  }

  const onCancel = () => {
    form.resetFields()
    onModalInvisible()
  }

  return {
    teamModal,
    disabledSave,
    onModalInvisible,
    handleFormChange,
    handleDisabledSave,
    onSubmit,
    form,
    onCancel,
    setTeamModal,
  }
}

export default TeamModalHook
