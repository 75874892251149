import { ColumnsType } from 'antd/lib/table'
import { WelcomeCallCsvInterface } from '@/components/feature/WelcomeCallPage/WelcomeCallImporter/interfaces'

function WelcomeCallUploadFailedTableHook() {
  const columns: ColumnsType<WelcomeCallCsvInterface | {}> = [
    {
      title: 'NO',
      dataIndex: 'NO',
      align: 'center',
      width: 90,
    },
    {
      title: 'ERROR',
      dataIndex: 'error',
      width: 300,
    },
    {
      title: 'RECIPIENT NAME',
      dataIndex: 'Recipient Name',
      width: 200,
    },
    {
      title: 'MOBILE NO.',
      dataIndex: 'Mobile No.',
      width: 180,
    },
    {
      title: 'POLICY NO.',
      dataIndex: 'Policy No.',
      width: 220,
    },
    {
      title: 'ADDRESS#1',
      dataIndex: 'Address#1',
      width: 300,
      render: (value) => {
        return value || '-'
      },
    },
    {
      title: 'ADDRESS#2',
      dataIndex: 'Address#2',
      width: 300,
      render: (value) => {
        return value || '-'
      },
    },
    {
      title: 'KERRY',
      dataIndex: 'Kerry',
      width: 220,
      render: (value) => {
        return value || '-'
      },
    },
    {
      title: 'MESSENGER',
      dataIndex: 'Messenger',
      width: 220,
      render: (value) => {
        return value || '-'
      },
    },
    {
      title: 'POST OFFICE',
      dataIndex: 'Post Office',
      width: 220,
      render: (value) => {
        return value || '-'
      },
    },
    {
      title: 'SEND DATE (DD/MM/YYYY)',
      dataIndex: 'Sent Date (DD/MM/YYYY)',
      width: 150,
    },
  ]

  return { columns }
}

export default WelcomeCallUploadFailedTableHook
