import { useRecoilState } from 'recoil'

import forgotBackofficeUserPasswordAtom from '@/recoil/forgotBackofficeUserPassword'

function SuccessFullyForgotPasswordCardHook() {
  const [forgotBackofficeUserPassword] = useRecoilState(forgotBackofficeUserPasswordAtom)

  return {
    forgotBackofficeUserPassword,
  }
}

export default SuccessFullyForgotPasswordCardHook
