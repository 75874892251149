import styled from '@emotion/styled'
import { Form, Input } from 'antd'

export const FormAntd = styled(Form)``

export const FormItem = styled(Form.Item)``

export const EmailInput = styled(Input)`
  border: 1px solid #e5e5e5;
`
