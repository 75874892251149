import { BubbleDataPoint, ChartData, ChartOptions, ScatterDataPoint } from 'chart.js'
import { ActivityStatsDataType } from './types'

function ActivityInformationBarChartHook() {
  const setBarChartOptions = (options?: ChartOptions<'bar'>) => ({
    responsive: true,
    scales: {
      x: {
        ticks: {
          font: {
            family: 'Prompt',
          },
        },
      },
      y: {
        ticks: {
          font: {
            family: 'Prompt',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    ...options,
  })

  const getData = (stats: ActivityStatsDataType) => {
    const labels = []
    const dataSet = []

    for (const stat of stats) {
      labels.push(stat.date)
      dataSet.push(stat.value)
    }

    const data: ChartData<
      'bar',
      (number | [number, number] | ScatterDataPoint | BubbleDataPoint | null)[],
      unknown
    > = {
      labels,
      datasets: [
        {
          data: dataSet,
          backgroundColor: '#009640',
        },
      ],
    }

    return data
  }

  return { setBarChartOptions, getData }
}

export default ActivityInformationBarChartHook
