import { atom } from 'recoil'

import { DeviceImportProps } from './types'

const initDeviceImport = {
  successful: 0,
  failed: 0,
  devices: [],
  isUpload: false,
} as DeviceImportProps

const deviceImportAtom = atom({
  key: 'deviceImportAtom',
  default: initDeviceImport,
})

export default deviceImportAtom
