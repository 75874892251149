import { withTheme } from '@emotion/react'
import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import { Button, Container, ExportIcon, ButtonContainer, OkButton } from './styles'
import ActiveHealthPolicyExporterHook from './ActiveHealthPolicyExporter.hook'
import DateRangePicker from '@/components/common/DateRangePicker'
import Modal from '@/components/common/Modal'
import { IMAGE_URL } from '@/constant/IMAGE'

function ActiveHealthPolicyExporter() {
  const {
    isModalVisible,
    setIsModalVisible,
    dateValue,
    setDateValue,
    policies,
    handleCloseModal,
    activeHealthPolicyLoading,
    onClickCsvLink,
  } = ActiveHealthPolicyExporterHook()

  return (
    <Container>
      <Button
        onClick={() => setIsModalVisible(true)}
        icon={<ExportIcon src={IMAGE_URL.exportIcon} />}
        data-testid="policy-management-export-button"
      >
        Export
      </Button>
      <Modal
        title="ช่วงการดึงข้อมูล"
        open={isModalVisible}
        onCancel={handleCloseModal}
        width="670px"
        footer={null}
        data-testid="export-policy-modal"
      >
        <DateRangePicker
          dateRangeValues={dateValue}
          onChangeValue={setDateValue}
          displayHeader={true}
        />

        <ButtonContainer>
          <Button onClick={handleCloseModal} data-testid="export-policy-modal-close-button">
            ยกเลิก
          </Button>
          {/* API always return header of csv at 0 index (length === 1), we allow to download csv file when found length of data more than 1 */}
          <OkButton disabled={policies.length <= 1 && !activeHealthPolicyLoading}>
            <CSVLink
              data={policies}
              filename={`active-health-policy-${dayjs().toISOString()}.csv`}
              asyncOnClick={true}
              onClick={(event, done) => onClickCsvLink(event, done)}
              data-testid="export-policy-export-button"
            >
              ดึงข้อมูล
            </CSVLink>
          </OkButton>
        </ButtonContainer>
      </Modal>
    </Container>
  )
}

export default withTheme(ActiveHealthPolicyExporter)
