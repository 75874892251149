import { Badge } from 'antd'
import {
  Block,
  Container,
  PvStatusTitle,
  RefTitle,
  StatusBlockStyle,
  StatusText,
  SubContainer,
  Title,
} from './styles'
import ManualDiscountInformationHeaderHook from './ManualDiscountInformationHeader.hook'
import { ManualDiscountInformationHeaderProps } from './types'
import { BadgeStatusEnum } from '@/constant/BADGE_STATUS'

function ManualDiscountInformationHeader({
  activeHealthManualDiscount,
}: ManualDiscountInformationHeaderProps) {
  const { handleDisplayStatus, checkPvDiscountSuccessIsBoolean } =
    ManualDiscountInformationHeaderHook()
  const { badgeStatus, status } = handleDisplayStatus(activeHealthManualDiscount.approveStatus)
  const isPvDiscountBoolean = checkPvDiscountSuccessIsBoolean(
    activeHealthManualDiscount?.activeHealthPolicyBilling?.isPvDiscountSuccess,
  )

  return (
    <Container>
      <RefTitle>เลขที่รายการ {activeHealthManualDiscount.id}</RefTitle>
      <Title>รายละเอียดขออนุโลมส่วนลด</Title>
      <Container>
        <SubContainer>
          <Block className={badgeStatus !== BadgeStatusEnum.PROCESSING ? StatusBlockStyle : ''}>
            <Badge status={badgeStatus} /> <StatusText>{status}</StatusText>
          </Block>
          {isPvDiscountBoolean ? (
            <>
              <Block>
                <PvStatusTitle>สถานะ PV</PvStatusTitle>
              </Block>
              <Block>
                <Badge
                  status={
                    activeHealthManualDiscount.activeHealthPolicyBilling.isPvDiscountSuccess
                      ? BadgeStatusEnum.SUCCESS
                      : BadgeStatusEnum.ERROR
                  }
                />{' '}
                <StatusText>
                  {activeHealthManualDiscount.activeHealthPolicyBilling.isPvDiscountSuccess
                    ? 'สำเร็จ'
                    : 'ไม่สำเร็จ'}
                </StatusText>
              </Block>
            </>
          ) : undefined}
        </SubContainer>
      </Container>
    </Container>
  )
}

export default ManualDiscountInformationHeader
