import { useMutation } from '@tanstack/react-query'
import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'

const postResendPolicy = async (email: string, applicationFormId: string) => {
  const response = await httpClientPrivate.post(
    `${config.restUrl}/v1/backoffice/commerce/resend-policy`,
    { email, applicationFormId },
  )

  return response.data
}

const useResendPolicy = (email: string, applicationFormId: string) => {
  const mutation = useMutation({
    mutationKey: ['useResendPolicy', email, applicationFormId],
    mutationFn: async () => postResendPolicy(email, applicationFormId),
  })

  return mutation
}

export { useResendPolicy, postResendPolicy }
