import { Col, Row, Tooltip } from 'antd'
import styled from '@emotion/styled'

export const Container = styled('div')`
  padding: 24px 32px 24px 32px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
`

export const Header = styled('div')`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 18px;
`

export const Title = styled('div')`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
`
export const Value = styled('div')`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
`

export const RowAntd = styled(Row)`
  margin: 10px 0px;
`

export const ColAntd = styled(Col)`
  padding-right: 15px;
  padding-bottom: 20px;
`

export const Box = styled('div')``

export const Line = styled('div')`
  background: #e5e5e5;
  margin-bottom: 25px;
  height: 1px;
`

export const ActionBlock = styled('div')`
  display: flex;
  justify-content: center;
`

export const ButtonIcon = styled('img')`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0px 2px;
`

export const InformationContainer = styled(Tooltip)``
