import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Badge, BadgeProps, MenuProps } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { fromEvent, of, tap } from 'rxjs'
import _ from 'lodash'
import { useLazyQuery, useMutation } from '@apollo/client'
import { userGql } from '@/gql'
import userAtom from '@/recoil/user'
import {
  UserHelpInterface,
  UserHelpMetaInterface,
} from '@/gql/user/backofficeGetUserHelpPagination/interfaces'
import {
  SORTER,
  SorterEnum,
  USER_HELP_STATUS,
  USER_HELP_TYPE,
  UserHelpSearchableFieldEnum,
  UserHelpSortableFieldEnum,
  UserHelpStatusEnum,
  UserHelpTypeEnum,
} from '@/constant/USER'
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { Dropdown, Link, RowHighlightClassName } from './styles'
import { UpdateUserHelpInterface } from '@/gql/user/backofficeUpdateUserHelp/interfaces'
import { SorterResult } from 'antd/lib/table/interface'
import { SortByEnum } from '@/constant/SORT_BY'
import notificationAtom from '@/recoil/notification'
import { BadgeStatusEnum } from '@/constant/BADGE_STATUS'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { UserSelectStyle } from '@/components/common/Table/styles'

export default function HelpTableHook() {
  const searchElement = document.getElementById('user-help-search')
  const user = useRecoilValue(userAtom)
  const [notification, setNotification] = useRecoilState(notificationAtom)
  const [getUserHelp, { data: userHelpData, loading: userHelpLoading }] = useLazyQuery(
    userGql.query.backofficeGetUserHelpPagination,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const [
    getSuccessfulUserHelp,
    { loading: successfulUserHelpLoading, data: successfulUserHelpData },
  ] = useLazyQuery(userGql.query.backofficeGetUserHelpPagination, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        searchBy: [UserHelpSearchableFieldEnum.STATUS],
        search: UserHelpStatusEnum.SUCCESSFUL,
      },
    },
  })

  const [updateUserHelp, { data: updateUserHelpData, loading: updateUserHelpLoading }] =
    useMutation(userGql.mutation.backofficeUpdateUserHelp, {
      fetchPolicy: 'no-cache',
    })
  const isTableLoading = userHelpLoading || successfulUserHelpLoading || updateUserHelpLoading
  const [pagination, setPagination] = useState<UserHelpMetaInterface | null>(null)
  const [userHelps, setUserHelps] = useState<Array<UserHelpInterface>>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentPageSize, setCurrentPageSize] = useState<number>(10)
  const [search, setSearch] = useState<string>('')
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [sorter, setSorter] = useState<[UserHelpSortableFieldEnum, SortByEnum | '']>([
    UserHelpSortableFieldEnum.CREATED_AT,
    SortByEnum.DESC,
  ])
  const badgeStatus: {
    [UserHelpStatusEnum.SUCCESSFUL]: BadgeProps['status']
    [UserHelpStatusEnum.WAITING]: BadgeProps['status']
    [UserHelpStatusEnum.IN_PROGRESS]: BadgeProps['status']
  } = {
    [UserHelpStatusEnum.SUCCESSFUL]: BadgeStatusEnum.SUCCESS,
    [UserHelpStatusEnum.WAITING]: BadgeStatusEnum.WARNING,
    [UserHelpStatusEnum.IN_PROGRESS]: BadgeStatusEnum.PROCESSING,
  }

  const menuItems = (userHelp: UserHelpInterface): MenuProps['items'] => {
    return [
      {
        label: USER_HELP_STATUS[UserHelpStatusEnum.WAITING],
        key: UserHelpStatusEnum.WAITING,
        onClick: () => handleUpdateUserHelp(userHelp, UserHelpStatusEnum.WAITING),
      },
      {
        label: USER_HELP_STATUS[UserHelpStatusEnum.IN_PROGRESS],
        key: UserHelpStatusEnum.IN_PROGRESS,
        onClick: () => handleUpdateUserHelp(userHelp, UserHelpStatusEnum.IN_PROGRESS),
      },
      {
        label: USER_HELP_STATUS[UserHelpStatusEnum.SUCCESSFUL],
        key: UserHelpStatusEnum.SUCCESSFUL,
        onClick: () => handleUpdateUserHelp(userHelp, UserHelpStatusEnum.SUCCESSFUL),
      },
    ]
  }

  const columns: ColumnsType<UserHelpInterface | {}> = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      align: 'center',
      render: (_, __, index) => {
        return <div>{index + 1}</div>
      },
    },
    {
      title: 'วันที่แจ้งปัญหา',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (createdAt) => {
        return <div>{dayjs(createdAt).format('DD-MM-YYYY HH:mm:ss')}</div>
      },
    },
    {
      title: 'ประเภท',
      key: 'type',
      dataIndex: 'type',
      className: UserSelectStyle,
      render: (type: UserHelpTypeEnum) => {
        return <div>{USER_HELP_TYPE[type]}</div>
      },
    },
    {
      title: 'เรื่อง',
      key: 'remark',
      dataIndex: 'remark',
      className: UserSelectStyle,
      render: (value) => {
        return value || '-'
      },
    },
    {
      title: 'ชื่อผู้เอาประกัน',
      className: UserSelectStyle,
      render: (_, record) => {
        const row = record as UserHelpInterface
        if (row?.user) {
          return (
            <div>
              {row.user.firstname} {row.user.lastname}
            </div>
          )
        }

        return '-'
      },
    },
    {
      title: 'เบอร์โทรศัพท์ติดต่อกลับ',
      key: 'phone',
      dataIndex: 'phone',
      className: UserSelectStyle,
    },
    {
      title: 'สถานะ',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (status: UserHelpStatusEnum, record, index) => {
        const userHelp = record as UserHelpInterface
        const canUpdateStatus = validatePerm(
          [BACKOFFICE_USER_PERMISSION.HEL010102],
          user.permissions,
        )

        return (
          <div data-testid={`user-help-table-update-status-dropdown-container-${index}`}>
            <Dropdown menu={{ items: menuItems(userHelp) }} disabled={!canUpdateStatus}>
              <Link data-testid={`user-help-table-update-status-${index}`}>
                <Badge status={badgeStatus[status]} /> <span>{USER_HELP_STATUS[status]}</span>{' '}
                {canUpdateStatus ? <CaretDownOutlined /> : undefined}
              </Link>
            </Dropdown>
          </div>
        )
      },
    },
    {
      title: 'ผู้รับเรื่อง',
      key: 'receivedBy',
      dataIndex: 'receivedBy',
      render: (_, record) => {
        const row = record as UserHelpInterface
        if (row?.receivedBy) {
          return (
            <div>
              {row.receivedBy.firstname} {row.receivedBy.lastname}
            </div>
          )
        }

        return '-'
      },
    },
    {
      title: 'ผู้แก้ไขสำเร็จ',
      key: 'successfulBy',
      dataIndex: 'successfulBy',
      render: (_, record) => {
        const row = record as UserHelpInterface
        if (row?.successfulBy) {
          return (
            <div>
              {row.successfulBy.firstname} {row.successfulBy.lastname}
            </div>
          )
        }

        return '-'
      },
    },
    {
      title: 'วันที่รับเรื่อง',
      key: 'receivedAt',
      dataIndex: 'receivedAt',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      render: (receivedAt) => {
        return <div>{receivedAt ? dayjs(receivedAt).format('DD-MM-YYYY HH:mm:ss') : '-'}</div>
      },
    },
    {
      title: 'วันที่แก้ไขสำเร็จ',
      key: 'successfulAt',
      dataIndex: 'successfulAt',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      render: (successfulAt) => {
        return <div>{successfulAt ? dayjs(successfulAt).format('DD-MM-YYYY HH:mm:ss') : '-'}</div>
      },
    },
  ]

  const handlePaginate = (page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  const handleSetSearch = (input: string) =>
    of(setSearch(input)).pipe(
      tap(() => {
        setCurrentPage(1)
        setCurrentPageSize(10)
      }),
    )

  // implement for support table sorter change
  const handleTableChange = (
    sorter: SorterResult<UserHelpInterface | {}> | Array<SorterResult<UserHelpInterface | {}>>,
  ) => {
    if (!_.isEmpty(sorter)) {
      const sortData = sorter as SorterResult<UserHelpInterface | {}>
      const sortBy = sortData.columnKey as UserHelpSortableFieldEnum
      const sort = sortData.order as SorterEnum

      setSorter([sortBy, SORTER[sort] || ''])
    }
  }

  const handleSearchInputChange = (isTyping: boolean) => setIsTyping(isTyping)

  const handleDisplayHighlightRow = (record: UserHelpInterface | {}) => {
    const data = record as UserHelpInterface

    return data.status !== UserHelpStatusEnum.SUCCESSFUL ? RowHighlightClassName : ''
  }

  const handleUpdateUserHelp = (userHelp: UserHelpInterface, status: UserHelpStatusEnum) => {
    const input: UpdateUserHelpInterface = {
      id: userHelp.id,
      status,
      receivedBy: '',
      successfulBy: '',
    }

    if (status === UserHelpStatusEnum.SUCCESSFUL) {
      if (!userHelp?.receivedBy?.firstname || !userHelp?.receivedBy?.lastname) {
        input.receivedBy = user.id
      } else {
        input.receivedBy = undefined
      }

      input.successfulBy = user.id
    } else if (status === UserHelpStatusEnum.IN_PROGRESS || status === UserHelpStatusEnum.WAITING) {
      input.receivedBy = user.id
      input.successfulBy = null
    }

    updateUserHelp({
      variables: {
        input,
      },
    })
  }

  useEffect(() => {
    getUserHelp({
      variables: {
        input: {
          limit: currentPageSize,
          page: currentPage,
          searchBy: [
            UserHelpSearchableFieldEnum.FIRSTNAME,
            UserHelpSearchableFieldEnum.LASTNAME,
            UserHelpSearchableFieldEnum.PHONE,
          ],
          search,
          sortBy: [sorter],
        },
      },
    })
    getSuccessfulUserHelp()
  }, [currentPage, currentPageSize, search, updateUserHelpData, sorter])

  useEffect(() => {
    if (
      userHelpData?.backofficeGetUserHelpPagination &&
      successfulUserHelpData?.backofficeGetUserHelpPagination
    ) {
      const { data, meta } = userHelpData.backofficeGetUserHelpPagination
      const totalSuccessful = successfulUserHelpData.backofficeGetUserHelpPagination.meta.totalItems
      const totalItems = meta.totalItems
      const count = totalItems - totalSuccessful

      setUserHelps(data)
      setPagination(meta)
      setNotification({
        ...notification,
        helpPage: {
          count,
        },
      })
    }
  }, [userHelpData, successfulUserHelpData])

  useEffect(() => {
    if (isTyping && searchElement) {
      fromEvent(searchElement, 'keyup')
        .pipe(
          debounceTime(1000),
          map((e: Event) => {
            const target = e.target as HTMLInputElement

            return target.value || ''
          }),
          distinctUntilChanged(),
          switchMap(handleSetSearch),
          tap(() => setIsTyping(false)),
        )
        .subscribe()
    }
  }, [isTyping, searchElement])

  return {
    columns,
    pagination,
    userHelps,
    currentPage,
    currentPageSize,
    handlePaginate,
    handleSearchInputChange,
    handleDisplayHighlightRow,
    handleTableChange,
    isTableLoading,
  }
}
