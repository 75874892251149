import { Card, Topic, SearchInput, SearchIcon } from './styles'
import DiscountIssueTableHook from './DiscountIssueTable.hook'
import Table from '@/components/common/Table'

export default function DiscountIssueTable() {
  const {
    columns,
    handleSearchInputChange,
    pagination,
    activeHealthBillingDiscountProblemData,
    isTableLoading,
    handleDisplayHighlightRow,
    handleOnChangeTable,
  } = DiscountIssueTableHook()

  return (
    <Card>
      <Topic>รายการลูกค้าที่มีปัญหา</Topic>
      <SearchInput
        id="active-health-billing-discount-problem-search"
        data-testid="billing-discount-issue-search-input"
        prefix={<SearchIcon />}
        placeholder="ค้นหาชื่อผู้เอาประกัน..."
        onChange={() => handleSearchInputChange(true)}
      />
      <Table
        data-testid="billing-discount-issue-table"
        loading={isTableLoading}
        columns={columns.filter((column) => column.width !== 0)}
        dataSource={
          activeHealthBillingDiscountProblemData
            ?.backofficeGetActiveHealthBillingDiscountProblemPagination?.data
        }
        scroll={{
          x: '200vw',
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: pagination?.totalItems,
          current: pagination.page,
          defaultCurrent: 1,
          pageSize: pagination.limit,
        }}
        onChange={(pagination, filters) => handleOnChangeTable({ pagination, filters })}
        rowClassName={handleDisplayHighlightRow}
        totalRecord={pagination?.totalItems}
      />
    </Card>
  )
}
