import { useQuery } from '@tanstack/react-query'
import { httpClientPrivate } from '@/libs/httpClient'
import { ApplicationFormResponse } from './types'
import config from '@/config'

const fetchOrderApplicationForm = async (orderId: string) => {
  const response = await httpClientPrivate.get<ApplicationFormResponse[]>(
    `${config.restUrl}/v1/backoffice-commerce/order/${orderId}/application-form`,
  )

  return response.data
}

const useOrderApplicationForm = (orderId: string) => {
  const query = useQuery({
    queryKey: ['useOrderApplicationForm', orderId],
    queryFn: async () => fetchOrderApplicationForm(orderId),
  })

  return { ...query }
}

export { useOrderApplicationForm, fetchOrderApplicationForm }
