import { toThaiLocaleDateAndMonthRangeWithDash, toThaiLocaleDateAndTimeWithDash } from '@/libs/date'
import { Container, Title, Information, Icon, Block } from './styles'
import { ActivityInformationProps } from './types'
import { IMAGE_URL } from '@/constant/IMAGE'

function ActivityInformation({
  startDate,
  endDate,
  lastUpdate,
  totalStep,
  totalMinuteTime,
  totalPoint,
  totalBonusPoint,
}: ActivityInformationProps) {
  return (
    <Container data-testid="policy-detail-exercise-tab-activity-information">
      <Block>
        <Title>ข้อมูลวันที่</Title>
        <Information>{toThaiLocaleDateAndMonthRangeWithDash(startDate, endDate)}</Information>
        <Title>ดึงข้อมูลล่าสุด {toThaiLocaleDateAndTimeWithDash(lastUpdate)}</Title>
      </Block>

      <Block>
        <Icon src={IMAGE_URL.stepIcon} /> <Title>ก้าว</Title>
        <Information>{totalStep.toLocaleString()}</Information>
      </Block>

      <Block>
        <Icon src={IMAGE_URL.clokcwatchIcon} /> <Title>นาที</Title>
        <Information>{totalMinuteTime.toLocaleString()}</Information>
      </Block>

      <Block>
        <Icon src={IMAGE_URL.pointIcon} /> <Title>คะแนน</Title>
        <Information>{totalPoint.toLocaleString()}</Information>
      </Block>

      {totalBonusPoint != null ? (
        <Block>
          <Icon src={IMAGE_URL.wellBeingIcon} /> <Title>Well Point</Title>
          <Information>{totalBonusPoint?.toLocaleString()}</Information>
        </Block>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default ActivityInformation
