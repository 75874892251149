import styled from '@emotion/styled'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import { Menu as AntMenu, Badge as AntdBadge } from 'antd'

export const Container = styled('div')`
  height: 100%;
  background-color: #ffffff;
`

export const Menu = styled(AntMenu)<{ inlineCollapsed: boolean }>`
  height: 100%;
  padding: 0 6px;
  width: ${(props) => (props.inlineCollapsed ? '80px' : '284px')};

  .ant-menu-item-selected {
    background-color: unset !important;
  }

  .ant-menu-item::after {
    border-right: unset;
  }

  .ant-menu-item-selected {
    color: #009640 !important;

    img {
      filter: brightness(0) saturate(100%) invert(52%) sepia(68%) saturate(5761%) hue-rotate(126deg)
        brightness(86%) contrast(101%);
    }
  }
`
export const MenuItem = styled(AntMenu.Item)`
  font-size: 14px;

  :hover {
    color: #009640 !important;

    img {
      filter: brightness(0) saturate(100%) invert(52%) sepia(68%) saturate(5761%) hue-rotate(126deg)
        brightness(86%) contrast(101%);
    }
  }
`

export const MenuHeader = styled('div')`
  margin: 16px;
  font-size: 12px;
  color: #666666;
`

export const BottomLine = styled('div')`
  border-bottom: 1px solid #e5e5e5;
  margin: 16px 0 24px 18px;
  max-width: 234px;
`

export const Link = styled(ReactRouterDomLink)``

export const Icon = styled('img')`
  filter: unset;
  width: 20px;
  height: auto;
`

export const TitleCollapse = styled('p')`
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
`

export const Badge = styled(AntdBadge)`
  position: absolute;
  right: 24px;
  top: 9px;
`
