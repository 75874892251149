import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetMenuPermissionByMenuTypeIdDataInterface,
  BackofficeGetMenuPermissionByMenuTypeIdInputInterface,
} from './interfaces'

const backofficeGetMenuPermissionByMenuTypeId: TypedDocumentNode<
  BackofficeGetMenuPermissionByMenuTypeIdDataInterface,
  BackofficeGetMenuPermissionByMenuTypeIdInputInterface
> = gql`
  query backofficeGetMenuPermissionByMenuTypeId($id: ID!) {
    backofficeGetMenuPermissionByMenuTypeId(id: $id) {
      id
      name
      order
      menu {
        id
        name
        code
        order
        page {
          id
          name
          code
          order
          permission {
            id
            name
            code
            order
          }
        }
      }
    }
  }
`

export default backofficeGetMenuPermissionByMenuTypeId
