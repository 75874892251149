import dayjs, { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/es/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import th from 'dayjs/locale/th'
import { noteOnce } from 'rc-util/es/warning'
import { GenerateConfig } from 'rc-picker/es/generate'

// REMIND: reference from https://stackblitz.com/edit/react-e2yyn2-m57kbm?file=demo.tsx
dayjs.locale(
  {
    ...th,
    formats: {
      LT: 'H:mm',
      LTS: 'H:mm:ss',
      L: 'DD/MM/BBBB',
      LL: 'D MMMM BBBB',
      LLL: 'D MMMM BBBB เวลา H:mm',
      LLLL: 'วันddddที่ D MMMM BBBB เวลา H:mm',
    },
  },
  undefined,
  true,
)

dayjs.extend(buddhistEra)

const parseLocale = (locale: string) => {
  return 'th'
}

const parseNoMatchNotice = () => {
  /* istanbul ignore next */
  noteOnce(false, 'Not match any format. Please help to fire a issue about this.')
}

const config: GenerateConfig<dayjs.Dayjs> = {
  ...dayjsGenerateConfig,
  getFixedDate: (string) => dayjs(string, ['BBBB-M-DD', 'BBBB-MM-DD']),
  setYear: (date, year) => {
    return date.year(year - 543)
  },
  getYear: (date) => Number(date.format('BBBB')),
  locale: {
    getWeekFirstDay: (locale) => dayjs().locale(parseLocale(locale)).localeData().firstDayOfWeek(),
    getWeekFirstDate: (locale, date) => date.locale(parseLocale(locale)).weekday(0),
    getWeek: (locale, date) => date.locale(parseLocale(locale)).weekday(),
    getShortWeekDays: (locale) => dayjs().locale(parseLocale(locale)).localeData().weekdaysMin(),
    getShortMonths: (locale) => dayjs().locale(parseLocale(locale)).localeData().monthsShort(),
    format: (locale, date, format) => {
      const convertFormat = format.replace('YYYY', 'BBBB')
      return date.locale(parseLocale(locale)).format(convertFormat)
    },
    parse: (locale, text, formats) => {
      const localeStr = parseLocale(locale)
      for (let i = 0; i < formats.length; i += 1) {
        const format = formats[i]
        const formatText = text
        if (format.includes('wo') || format.includes('Wo')) {
          // parse Wo
          const year = formatText.split('-')[0]
          const weekStr = formatText.split('-')[1]
          const firstWeek = dayjs(year, 'BBBB').startOf('year').locale(localeStr)
          for (let j = 0; j <= 52; j += 1) {
            const nextWeek = firstWeek.add(j, 'week')
            if (nextWeek.format('Wo') === weekStr) {
              return nextWeek
            }
          }
          parseNoMatchNotice()
          return null
        }
        const date = dayjs(formatText, format, true).locale(localeStr)
        if (date.isValid()) {
          return date
        }
      }

      if (text) {
        parseNoMatchNotice()
      }
      return null
    },
  },
}

const BuddhistDatePicker = generatePicker<Dayjs>(config)

export default BuddhistDatePicker
