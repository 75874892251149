import { useState } from 'react'
import { ActiveHealthPolicyStatusEnum } from '@/constant/ACTIVE_HEALTH_POLICY'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'

export default function PolicyManagementHook() {
  const [policyStatus, setPolicyStatus] = useState(ActiveHealthPolicyStatusEnum.ALL)
  const user = useRecoilValue(userAtom)

  const handleSetPolicyStatus = (status: ActiveHealthPolicyStatusEnum) => setPolicyStatus(status)

  return { policyStatus, handleSetPolicyStatus, user }
}
