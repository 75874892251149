import { Container, SearchInput, SearchIcon, Title } from './styles'
import WatchManagementTableHook from './WatchManagementTable.hook'
import Table from '@/components/common/Table'

function WatchManagementTable() {
  const { columns, setIsTyping, filterWatches, watchBrandsLoading } = WatchManagementTableHook()

  return (
    <Container>
      <Title>รายการยี่ห้อนาฬิกา</Title>
      <SearchInput
        placeholder="ค้นหาชื่อนาฬิกา..."
        prefix={<SearchIcon />}
        id="watch-search"
        data-testid="watch-managment-search-input"
        onChange={() => setIsTyping(true)}
      />
      <Table
        data-testid="watch-managment-table"
        loading={watchBrandsLoading}
        dataSource={filterWatches}
        columns={columns}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
        }}
        totalRecord={filterWatches.length}
      />
    </Container>
  )
}

export default WatchManagementTable
