import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import th from 'dayjs/locale/th'
import utc from 'dayjs/plugin/utc'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(utc)
dayjs.locale(th)
dayjs.extend(buddhistEra)
dayjs.extend(isSameOrAfter)

export function toThaiLocaleDateString({
  date,
  isUtc = true,
  isFullMonth = false,
}: {
  date: Date | string
  isUtc?: boolean
  isFullMonth?: boolean
}): string {
  return dayjs(date)
    .utc(isUtc)
    .format(isFullMonth ? 'DD MMMM BBBB' : 'DD MMM BBBB')
}

export function toThaiLocaleDateRangeWithDash(startDate: Date, endDate: Date): string {
  return `${dayjs(startDate).format('DD')} - ${dayjs(endDate).format('DD MMM BBBB')}`
}

export function toThaiLocaleDateAndMonthRangeWithDash(startDate: Date, endDate: Date): string {
  return `${dayjs(startDate).format('D MMM')} - ${dayjs(endDate).format('D MMM BBBB')}`
}

export function toThaiLocaleDayAndDateWithDash(startDate: Date): string {
  return `${dayjs(startDate).format('dd, D MMM')}`
}

export function toThaiLocaleDateAndTimeWithDash(date: string): string {
  if (date !== '') {
    return `${dayjs(date).format('D MMM, H:mm')}`
  }

  return ''
}

export function toThaiLocaleMonthAndYearWithDash(date: Date): string {
  return `${dayjs(date).format('MMM BB')}`
}

export function toThaiLocaleDateAndTime(date: string): string {
  if (date !== '') {
    return `${dayjs(date).format('DD MMM YYYY, HH:mm')}`
  }
  return ''
}

export function toThaiLocaleDateAndMonth(date: string): string {
  if (date !== '') {
    return `${dayjs(date).format('dddd DD MMMM')}`
  }
  return ''
}

export function toThaiDayName(date?: Date): string {
  if (date) {
    return dayjs(date).format('dddd')
  }

  return ''
}

export const isCurrentMonthlyBilling = (startDate: Date, billDate: Date): boolean => {
  const today = dayjs().startOf('d')
  const start = dayjs(startDate)
  const end = dayjs(billDate)
  const isAfterOrEqualStartDate = today.isSameOrAfter(start)
  const isBeforeBillDate = today.isBefore(end)

  return isAfterOrEqualStartDate && isBeforeBillDate
}

export const toThaiLocaleSlashDate = (date?: Date): string =>
  date ? dayjs(date).format('DD/MM/BBBB') : ''

export const toThaiLocaleShortDate = (date?: Date): string =>
  date ? dayjs(date).format('DD MMM BB') : ''

// Check number of the day of the week.
// Start from 1 (Monday),..., 7 (Sunday)
export const isCurrentDay = (day: number) => {
  dayjs.locale({
    ...th,
    weekStart: 1,
  })
  const current = dayjs().day()

  return day ? day === current : false
}

export const formatThaiLocaleDateRange = (from: Date, to?: Date) => {
  // check is the same day
  if (!to || dayjs(from).isSame(to, 'day')) {
    return dayjs(from).format('D MMM BB')
  }

  return `${dayjs(from).format('D MMM BB')} - ${dayjs(to).format('D MMM BB')}`
}
