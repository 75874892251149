import { Tabs } from 'antd'
import { useContext, useEffect } from 'react'
import ChangeCreditCardModal from '@/components/feature/PolicyDetailPage/ChangeCreditCardModal'
import DeferredDiscountModal from '@/components/feature/PolicyDetailPage/DeferredDiscountModal'
import { PolicyDetailContext } from '@/contexts'
import PremiumTabHook from './PremiumTab.hook'
import { Container } from './styles'

function PremiumTab() {
  const { tabItems, tabActiveKey } = PremiumTabHook()
  const { handleSetTabActiveKey } = useContext(PolicyDetailContext)

  useEffect(() => {
    handleSetTabActiveKey(undefined)
  }, [])

  return (
    <Container>
      <Tabs items={tabItems} activeKey={tabActiveKey} />
      <ChangeCreditCardModal />
      <DeferredDiscountModal />
    </Container>
  )
}

export default PremiumTab
