import styled from '@emotion/styled'
import { Switch } from 'antd'

export const Information = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
`

export const OnOffSwitchContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
`
export const OnOffSwitch = styled(Switch)``

export const InformationContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 32px;
`
