import PasswordValidator from 'password-validator'

export const isMatchPassword = (password: string) => {
  const schema = new PasswordValidator()

  schema
    .is()
    .min(8) // Minimum length 8
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 1 digit
    .has()
    .not()
    .spaces() // Should not have spaces

  return schema.validate(password)
}
