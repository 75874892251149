import styled from '@emotion/styled'
import { Input as AntdInput, Dropdown as AntdDropdown, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { css } from '@emotion/css'

export const Card = styled('div')`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 32px;
  width: 100%;
  position: relative;
  user-select: none;
  margin: 24px auto;
`

export const Topic = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
`

export const ActionText = styled('span')`
  color: #009640;
  cursor: pointer;
`

export const SearchInput = styled(AntdInput)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 239px;
  position: absolute;
  right: 0;
  top: -60px;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`

export const RowHighlightClassName = css`
  background-color: #fffde5;

  .ant-table-cell-fix-right {
    background-color: #fffde5 !important;
  }
`

export const Dropdown = styled(AntdDropdown)``

export const Link = styled(Typography.Link)`
  color: #000000 !important;
`
