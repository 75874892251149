import { IMAGE_URL } from '@/constant/IMAGE'
import { toThaiLocaleDateAndTime } from '@/libs/date'
import CategoryList from './components/CategoryList/CategoryList'
import QuestionForm from './components/QuestionForm/QuestionForm'
import ReadQuestion from './components/ReadQuestion/ReadQuestion'
import SubCategoryList from './components/SubCategoryList/SubCategoryList'
import FrequencyQuestionsHook from './FrequencyQuestions.hook'
import MoveCategoryModal from './MoveCategoryModal'
import {
  BodyBlock,
  ButtonDefault,
  Container,
  CreateCategoryInput,
  HeaderBlock,
  IconSvg,
  PlusButton,
  SearchIcon,
  SearchInput,
  Title,
  TitleWrapper,
  Topic,
  CategoryWrapper,
  TopicWrapper,
  AllCheckbox,
  CategoryListWrapper,
  Skeleton,
  SkeletonContainer,
  SkeletonButton,
} from './styles'
import { FAQCategoryType, statusQuestionEnum, SubCategoryMoveEnum } from './types'

const FrequencyQuestions = () => {
  const {
    initialFaqCategory,
    faqCategory,
    setFaqCategory,
    handleSelectedFaqCategory,
    handleCreateBackofficeFAQCategory,
    isCreateCategory,
    setCreateCategory,
    handleUpdateBackofficeFAQCategory,
    handleRemoveBackofficeFAQCategory,
    handleEditCategory,
    handleChangeCategory,
    categoryOptions,
    faqInfoesByFAQCategoryId,
    setFaqInfoesByFAQCategoryId,
    statusQuestion,
    setStatusQuestion,
    selectedFaqCategory,
    handleRemoveBackofficeFAQInfo,
    subCategory,
    setSubCategory,
    handleQuestion,
    handleMoveCategoryMultiple,
    isSomeSubCategorySelected,
    isEverySubCategorySelected,
    handleMoveCategoryQuestion,
    isMoveCategory,
    setMoveCategory,
    handleCategoryMove,
    handleSubCategoryMove,
    debouncedSearch,
    getTitle,
    canSearch,
    canDelete,
    canMove,
    canEdit,
    canCreate,
    isCateLoading,
    isFaqInfoLoading,
  } = FrequencyQuestionsHook()

  return (
    <Container>
      <HeaderBlock>
        <Title>คำถามที่พบบ่อย</Title>
        {canSearch ? (
          <SearchInput
            placeholder="ค้นหาคำถาม..."
            prefix={<SearchIcon />}
            onChange={debouncedSearch}
            data-testid="faq-search-input"
          />
        ) : undefined}
        <CategoryWrapper>
          <TopicWrapper>
            <Topic>หมวดหมู่คำถาม</Topic>
            {canCreate ? (
              <PlusButton
                onClick={() => setCreateCategory(!isCreateCategory)}
                data-testid="create-faq-cate-button"
              />
            ) : undefined}
          </TopicWrapper>
          <CategoryListWrapper>
            {isCreateCategory && (
              <CreateCategoryInput
                placeholder="ระบุ"
                onKeyDown={(events) => handleCreateBackofficeFAQCategory(events)}
                data-testid="assign-faq-cate-input"
              />
            )}
            {isCateLoading ? (
              <>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </>
            ) : (
              faqCategory.length > 0 &&
              faqCategory.map((cate: FAQCategoryType, index) => {
                return (
                  <CategoryList
                    key={cate.id}
                    value={cate.title}
                    action={cate.action}
                    isSelect={selectedFaqCategory?.id === cate.id}
                    onSelected={() => handleSelectedFaqCategory(cate)}
                    onEditCategory={() => handleEditCategory(cate, index)}
                    onChange={(text: string) => handleChangeCategory(cate, index, text)}
                    onKeyDown={(e) => handleUpdateBackofficeFAQCategory(e, cate)}
                    onMouseLeave={() => setFaqCategory(initialFaqCategory)}
                    onDelete={() => handleRemoveBackofficeFAQCategory(cate)}
                    onMoveUp={() =>
                      handleCategoryMove(SubCategoryMoveEnum.MOVE_UP, cate.id, cate.order)
                    }
                    onMoveDown={() =>
                      handleCategoryMove(SubCategoryMoveEnum.MOVE_DOWN, cate.id, cate.order)
                    }
                    canDelete={canDelete}
                    canEdit={canEdit}
                    index={index}
                  />
                )
              })
            )}
          </CategoryListWrapper>
        </CategoryWrapper>
      </HeaderBlock>
      <BodyBlock>
        {statusQuestion !== statusQuestionEnum.READ_QUESTION && (
          <TitleWrapper>
            <Title>{getTitle()}</Title>
            {!isMoveCategory &&
              statusQuestion !== statusQuestionEnum.EDIT_QUESTION &&
              statusQuestion !== statusQuestionEnum.CREATE_QUESTION && (
                <>
                  {isFaqInfoLoading ? (
                    <SkeletonButton active size="large" />
                  ) : faqInfoesByFAQCategoryId.length > 0 && canMove ? (
                    <ButtonDefault
                      onClick={() => setMoveCategory((bool) => !bool)}
                      icon={<IconSvg src={IMAGE_URL.shareIcon} />}
                      type="default"
                      data-testid="faq-move-cate-button"
                    >
                      ย้ายหมวดคำถาม
                    </ButtonDefault>
                  ) : null}

                  {canCreate ? (
                    <ButtonDefault
                      onClick={() => setStatusQuestion(statusQuestionEnum.CREATE_QUESTION)}
                      icon={<IconSvg src={IMAGE_URL.plusIcon} />}
                      type="primary"
                      data-testid="faq-add-question-button"
                    >
                      เพิ่มคำถาม
                    </ButtonDefault>
                  ) : undefined}
                </>
              )}
            {isMoveCategory && statusQuestion !== statusQuestionEnum.CREATE_QUESTION && (
              <>
                <ButtonDefault
                  onClick={() => setMoveCategory((bool) => !bool)}
                  type="default"
                  data-testid="faq-cancel-button"
                >
                  ยกเลิก
                </ButtonDefault>
                <ButtonDefault
                  type="primary"
                  onClick={() => handleMoveCategoryMultiple()}
                  data-testid="faq-move-to-button"
                >
                  ย้ายไปยัง
                </ButtonDefault>
              </>
            )}
          </TitleWrapper>
        )}
        {(statusQuestion === statusQuestionEnum.QUESTION ||
          statusQuestion === statusQuestionEnum.SEARCH_FAQ) &&
        faqInfoesByFAQCategoryId.length > 0 ? (
          <div>
            {isMoveCategory && (
              <AllCheckbox
                indeterminate={isSomeSubCategorySelected && !isEverySubCategorySelected}
                checked={isEverySubCategorySelected}
                onChange={() => {
                  setFaqInfoesByFAQCategoryId((prevState) =>
                    prevState.map((data) => ({
                      ...data,
                      selectedSubCategory: !isEverySubCategorySelected,
                    })),
                  )
                }}
                data-testid="faq-move-cate-check-all-checkbox"
              >
                เลือกทั้งหมด
              </AllCheckbox>
            )}
            {isFaqInfoLoading ? (
              <SkeletonContainer>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </SkeletonContainer>
            ) : faqInfoesByFAQCategoryId ? (
              faqInfoesByFAQCategoryId.map((cate, index) => {
                return (
                  <SubCategoryList
                    index={index}
                    key={cate.id}
                    title={cate.title}
                    description={cate.description}
                    isMoveCategory={isMoveCategory}
                    selected={cate.selectedSubCategory}
                    onSelected={() => {
                      if (faqInfoesByFAQCategoryId[index]) {
                        let newArray = faqInfoesByFAQCategoryId

                        newArray[index] = {
                          ...cate,
                          selectedSubCategory: !cate.selectedSubCategory,
                        }

                        setFaqInfoesByFAQCategoryId([...newArray])
                      }
                    }}
                    onClickSubCategory={() => {
                      setSubCategory(cate)
                      setStatusQuestion(statusQuestionEnum.READ_QUESTION)
                    }}
                    onMoveUp={() =>
                      handleSubCategoryMove(SubCategoryMoveEnum.MOVE_UP, cate.id, cate.order)
                    }
                    onMoveDown={() =>
                      handleSubCategoryMove(SubCategoryMoveEnum.MOVE_DOWN, cate.id, cate.order)
                    }
                  />
                )
              })
            ) : null}
          </div>
        ) : null}
        {statusQuestion === statusQuestionEnum.CREATE_QUESTION ||
        statusQuestion === statusQuestionEnum.EDIT_QUESTION ? (
          <QuestionForm
            mode={statusQuestion}
            subCategory={subCategory}
            selectedFaqCategory={selectedFaqCategory}
            onCancel={() => setStatusQuestion(statusQuestionEnum.QUESTION)}
            onSuccess={() => handleQuestion()}
            categoryOptions={categoryOptions}
          />
        ) : null}
        {statusQuestion === statusQuestionEnum.READ_QUESTION && subCategory ? (
          <ReadQuestion
            onEdit={() => {
              setStatusQuestion(statusQuestionEnum.EDIT_QUESTION)
            }}
            categoryTitle={selectedFaqCategory?.title || ''}
            onMoveQuestion={() => handleMoveCategoryQuestion(subCategory?.id)}
            onDelete={() => handleRemoveBackofficeFAQInfo(subCategory)}
            title={subCategory?.title || ''}
            createdBy={`สร้างโดย ${subCategory.createBy.firstname} ${
              subCategory.createBy.lastname
            } ${toThaiLocaleDateAndTime(subCategory.createAt)}`}
            description={subCategory?.description || ''}
            canDelete={canDelete}
            canEdit={canEdit}
            canMove={canMove}
          />
        ) : null}
        <MoveCategoryModal />
      </BodyBlock>
    </Container>
  )
}

export default FrequencyQuestions
