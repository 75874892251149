import { useState } from 'react'
import { DisplayModeEnum } from '@/components/feature/WellBeingPage/WellBeingPartnerStatus/types'

function WellBeingPartnerStatusHook() {
  const [displayMode, setDisplayMode] = useState<DisplayModeEnum>(DisplayModeEnum.VIEW)

  return { displayMode }
}

export default WellBeingPartnerStatusHook
