import backofficeGetActiveHealthPartnerAll from './backofficeGetActiveHealthPartnerAll/backofficeGetActiveHealthPartnerAll.gql'
import backofficeGetActiveHealthPartnerPagination from './backofficeGetActiveHealthPartnerPagination/backofficeGetActiveHealthPartnerPagination.gql'
import backofficeGetActiveHealthPartnerById from './backofficeGetActiveHealthPartnerById/backofficeGetActiveHealthPartnerById.gql'
import backofficeGetRecentActiveHealthBonusCriteria from './backofficeGetRecentActiveHealthBonusCriteria/backofficeGetRecentActiveHealthBonusCriteria.gql'
import backofficeGetActiveHealthPartnerBranchPaginationWithScanCount from './backofficeGetActiveHealthPartnerBranchPaginationWithScanCount/backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.gql'
import backofficeGetQrByActiveHealthPartnerBranchId from './backofficeGetQrByActiveHealthPartnerBranchId/backofficeGetQrByActiveHealthPartnerBranchId.gql'
const activeHealthPartner = {
  query: {
    backofficeGetActiveHealthPartnerAll,
    backofficeGetActiveHealthPartnerPagination,
    backofficeGetActiveHealthPartnerById,
    backofficeGetRecentActiveHealthBonusCriteria,
    backofficeGetActiveHealthPartnerBranchPaginationWithScanCount,
    backofficeGetQrByActiveHealthPartnerBranchId,
  },
  mutation: {},
}

export default activeHealthPartner
