import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetBonusPointPartnerBranchDataInterface,
  BackofficeGetBonusPointPartnerBranchInputInterface,
} from './interface'

export const backofficeGetBonusPointPartnerBranch: TypedDocumentNode<
  BackofficeGetBonusPointPartnerBranchDataInterface,
  BackofficeGetBonusPointPartnerBranchInputInterface
> = gql`
  query BackofficeGetBonusPointPartnerBranch($input: BackofficeGetBonusPointPartnerInput!) {
    backofficeGetBonusPointPartnerBranch(input: $input) {
      bonusPoint
      activityBonusPoint {
        point
        activityType
        activeHealthPartnerBranch {
          id
          displayNameTh
          displayNameEn
          activeHealthPartner {
            id
            displayNameTh
            displayNameEn
            logoImageUrl
          }
        }
      }
    }
  }
`

export default backofficeGetBonusPointPartnerBranch
