// See react-leaflet's documents at: https://react-leaflet.js.org/
import React, { ReactElement, useEffect, useState } from 'react'
import * as leaflet from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import defaultIconMarker from 'leaflet/dist/images/marker-icon.png'
import defaultIconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import defaultIconShadow from 'leaflet/dist/images/marker-shadow.png'
import { Container, IconLoading, LoadingContainer, SpinAntd } from './styles'

import 'leaflet/dist/leaflet.css'
import { IMAGE_URL } from '@/constant/IMAGE'
import Loading from '@/components/common/Loading'

leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: defaultIconRetina,
  iconUrl: defaultIconMarker,
  shadowUrl: defaultIconShadow,
})

export interface MapLocationProps {
  iconUrl?: string
  position: leaflet.LatLngExpression
  popupChildren?: React.ReactNode
}

/**
 * React-leaflet map common component.
 * @function
 * @param {MapLocationProps} props - An object containing location information.
 * @param {Array<{ iconUrl?: string, position: leaflet.LatLngExpression, popupChildren?: React.ReactNode }>} props.locations - An array of location objects, iconUrl require a PNG, SVG.
 */
function Map({
  children = undefined,
  locations,
  currentPosition,
  loading,
}: {
  children?: ReactElement
  locations: Array<MapLocationProps>
  currentPosition?: leaflet.LatLngExpression
  loading?: boolean
}) {
  const defaultPosition: leaflet.LatLngExpression = [13.7640412549892, 100.54677577546956] // Thaivivat's HQ location
  const [currentViewPosition, setCurrentViewPosition] = useState<
    leaflet.LatLngExpression | undefined
  >(undefined)

  useEffect(() => {
    setCurrentViewPosition(currentPosition)
  }, [currentPosition])

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <SpinAntd indicator={<IconLoading style={{ fontSize: 55 }} spin />} />
        </LoadingContainer>
      )}

      <MapContainer
        center={defaultPosition}
        zoom={15}
        scrollWheelZoom={true}
        style={{ height: '100vh', width: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {children}
        {locations && locations.length > 0
          ? locations.map((loc) => {
              let leafletIcon = undefined
              let isSelected = false

              // Workaround.
              if (currentViewPosition) {
                // @ts-ignore
                const currentLat: number = currentViewPosition[0]
                // @ts-ignore
                const currentLng: number = currentViewPosition[1]
                // @ts-ignore
                const locLat: number = loc.position[0]
                // @ts-ignore
                const locLng: number = loc.position[1]

                if (currentLat === locLat && currentLng === locLng) {
                  isSelected = true
                }
              }

              if (loc.iconUrl) {
                leafletIcon = new leaflet.Icon({
                  iconUrl: loc.iconUrl,
                  iconAnchor: [47, 100],
                  popupAnchor: [0, -45],
                })
              }

              // Workaround.
              const selectedIcon = new leaflet.Icon({
                iconUrl: IMAGE_URL.evChargingStationSelectedMarker,
                iconAnchor: [47, 100],
                popupAnchor: [0, -45],
              })

              return (
                <Marker
                  position={loc.position}
                  icon={isSelected ? selectedIcon : leafletIcon}
                  eventHandlers={{
                    click: () => {
                      setCurrentViewPosition(loc.position)
                    },
                  }}
                >
                  {loc.popupChildren ? <Popup>{loc.popupChildren}</Popup> : undefined}
                </Marker>
              )
            })
          : undefined}
      </MapContainer>
    </Container>
  )
}

export default Map
