import { BadgeProps } from 'antd'
import _ from 'lodash'
import { ActiveHealthManualDiscountFilterApproveStatusEnum } from '@/gql/activeHealthPolicy/backofficeGetActiveHealthManualDiscountPagination/interfaces'
import { BadgeStatusEnum } from '@/constant/BADGE_STATUS'

function ManualDiscountInformationHeaderHook() {
  const handleDisplayStatus = (
    approveStatus: string,
  ): { badgeStatus: BadgeProps['status']; status: string } => {
    switch (approveStatus) {
      case ActiveHealthManualDiscountFilterApproveStatusEnum.APPROVE.toLocaleUpperCase():
        return { badgeStatus: BadgeStatusEnum.SUCCESS, status: 'อนุมัติ' }
      case ActiveHealthManualDiscountFilterApproveStatusEnum.REJECT.toLocaleUpperCase():
        return { badgeStatus: BadgeStatusEnum.ERROR, status: 'ไม่อนุมัติ' }
      case ActiveHealthManualDiscountFilterApproveStatusEnum.PENDING.toLocaleUpperCase():
        return { badgeStatus: BadgeStatusEnum.PROCESSING, status: 'รออนุมัติ' }
      default:
        return { badgeStatus: BadgeStatusEnum.DEFAULT, status: '-' }
    }
  }

  const checkPvDiscountSuccessIsBoolean = (isPvDiscountSuccess: boolean | null) =>
    _.isBoolean(isPvDiscountSuccess)

  return { handleDisplayStatus, checkPvDiscountSuccessIsBoolean }
}

export default ManualDiscountInformationHeaderHook
