// @ts-nocheck

import * as React from 'react'
import {
  useUtils,
  WrapperVariantContext,
  MobileKeyboardInputView,
  defaultReduceAnimations,
  ExportedCalendarPickerProps,
  useCalendarState,
  PickerStatePickerProps,
  DayPickerProps,
} from '@mui/x-date-pickers/internals'
import PropTypes from 'prop-types'
import { DateRange, CurrentlySelectingRangeEndProps } from '../internal/models/dateRange'
import { isRangeValid } from '../internal/utils/date-utils'
import { calculateRangeChange } from './date-range-manager'
import { DateRangePickerInput, DateRangeInputProps } from './DateRangePickerInput'
import { DateRangePickerToolbar } from './DateRangePickerToolbar'
import {
  DateRangePickerViewDesktop,
  ExportedDesktopDateRangeCalendarProps,
} from './DateRangePickerViewDesktop'
import {
  DateRangePickerViewMobile,
  DateRangePickerViewMobileSlotsComponent,
  DateRangePickerViewMobileSlotsComponentsProps,
} from './DateRangePickerViewMobile'

export type DateRangePickerViewSlotsComponent = DateRangePickerViewMobileSlotsComponent

export type DateRangePickerViewSlotsComponentsProps = DateRangePickerViewMobileSlotsComponentsProps

export interface ExportedDateRangePickerViewProps<TDate>
  extends ExportedDesktopDateRangeCalendarProps<TDate>,
    Omit<ExportedCalendarPickerProps<TDate>, 'onYearChange' | 'renderDay'> {
  /**
   * Overrideable components.
   * @default {}
   */
  components?: Partial<DateRangePickerViewSlotsComponent>
  /**
   * The props used for each component slot.
   * @default {}
   */
  componentsProps?: Partial<DateRangePickerViewSlotsComponentsProps>
  /**
   * If `true`, after selecting `start` date calendar will not automatically switch to the month of `end` date.
   * @default false
   */
  disableAutoMonthSwitching?: boolean
  /**
   * Mobile picker title, displaying in the toolbar.
   * @default 'Select date range'
   */
  toolbarTitle?: React.ReactNode
  /**
   * Date format, that is displaying in toolbar.
   */
  toolbarFormat?: string
  /**
   * If `true`, show the toolbar even in desktop mode.
   */
  showToolbar?: boolean
  /**
   * className applied to the root component.
   */
  className?: string
}

interface DateRangePickerViewProps<TInputDate, TDate>
  extends CurrentlySelectingRangeEndProps,
    ExportedDateRangePickerViewProps<TDate>,
    PickerStatePickerProps<DateRange<TDate>> {
  calendars: 1 | 2 | 3
  open: boolean
  startText: React.ReactNode
  endText: React.ReactNode
  DateInputProps: DateRangeInputProps<TInputDate, TDate>
}

type DateRangePickerViewComponent = (<TInputDate, TDate = TInputDate>(
  props: DateRangePickerViewProps<TInputDate, TDate>,
) => JSX.Element) & { propTypes?: any }

/**
 * @ignore - internal component.
 */
function DateRangePickerViewRaw<TInputDate, TDate>(
  props: DateRangePickerViewProps<TInputDate, TDate>,
) {
  const {
    calendars,
    className,
    currentlySelectingRangeEnd,
    parsedValue,
    DateInputProps,
    defaultCalendarMonth,
    disableAutoMonthSwitching = false,
    disableFuture,
    disableHighlightToday,
    disablePast,
    endText,
    isMobileKeyboardViewOpen,
    maxDate,
    minDate,
    onDateChange,
    onMonthChange,
    open,
    reduceAnimations = defaultReduceAnimations,
    setCurrentlySelectingRangeEnd,
    shouldDisableDate,
    showToolbar,
    startText,
    toggleMobileKeyboardView,
    toolbarFormat,
    toolbarTitle,
    ...other
  } = props

  const utils = useUtils<TDate>()
  const wrapperVariant = React.useContext(WrapperVariantContext)

  const [start, end] = parsedValue
  const {
    changeMonth,
    calendarState,
    isDateDisabled,
    onMonthSwitchingAnimationEnd,
    changeFocusedDay,
  } = useCalendarState({
    date: start || end,
    defaultCalendarMonth,
    disableFuture,
    disablePast,
    disableSwitchToMonthOnDayFocus: true,
    maxDate,
    minDate,
    onMonthChange,
    reduceAnimations,
    shouldDisableDate,
  })

  const toShowToolbar = showToolbar ?? wrapperVariant !== 'desktop'

  const scrollToDayIfNeeded = (day: TDate | null) => {
    if (!day || !utils.isValid(day) || isDateDisabled(day)) {
      return
    }

    const currentlySelectedDate = currentlySelectingRangeEnd === 'start' ? start : end

    if (currentlySelectedDate === null) {
      // do not scroll if one of ages is not selected
      return
    }

    const displayingMonthRange = wrapperVariant === 'mobile' ? 0 : calendars - 1
    const currentMonthNumber = utils.getMonth(calendarState.currentMonth)
    const requestedMonthNumber = utils.getMonth(day)

    if (
      !utils.isSameYear(calendarState.currentMonth, day) ||
      requestedMonthNumber < currentMonthNumber ||
      requestedMonthNumber > currentMonthNumber + displayingMonthRange
    ) {
      const newMonth =
        currentlySelectingRangeEnd === 'start'
          ? currentlySelectedDate
          : // If need to focus end, scroll to the state when "end" is displaying in the last calendar
            utils.addMonths(currentlySelectedDate, -displayingMonthRange)

      changeMonth(newMonth)
    }
  }

  React.useEffect(() => {
    if (disableAutoMonthSwitching || !open) {
      return
    }

    scrollToDayIfNeeded(currentlySelectingRangeEnd === 'start' ? start : end)
  }, [currentlySelectingRangeEnd, parsedValue]) // eslint-disable-line

  const handleSelectedDayChange = React.useCallback<DayPickerProps<TDate>['onSelectedDaysChange']>(
    (newDate) => {
      const { nextSelection, newRange } = calculateRangeChange({
        newDate,
        utils,
        range: parsedValue,
        currentlySelectingRangeEnd,
      })

      setCurrentlySelectingRangeEnd(nextSelection)

      const isFullRangeSelected =
        currentlySelectingRangeEnd === 'end' && isRangeValid(utils, newRange)

      onDateChange(
        newRange as DateRange<TDate>,
        wrapperVariant,
        isFullRangeSelected ? 'finish' : 'partial',
      )
    },
    [
      currentlySelectingRangeEnd,
      parsedValue,
      onDateChange,
      setCurrentlySelectingRangeEnd,
      utils,
      wrapperVariant,
    ],
  )

  const renderView = () => {
    const sharedCalendarProps = {
      parsedValue,
      changeFocusedDay,
      onSelectedDaysChange: handleSelectedDayChange,
      reduceAnimations,
      disableHighlightToday,
      onMonthSwitchingAnimationEnd,
      changeMonth,
      currentlySelectingRangeEnd,
      disableFuture,
      disablePast,
      minDate,
      maxDate,
      shouldDisableDate,
      ...calendarState,
      ...other,
    }

    switch (wrapperVariant) {
      case 'desktop': {
        return <DateRangePickerViewDesktop calendars={calendars} {...sharedCalendarProps} />
      }

      default: {
        return <DateRangePickerViewMobile {...sharedCalendarProps} />
      }
    }
  }

  return (
    <div className={className}>
      {toShowToolbar && (
        <DateRangePickerToolbar
          parsedValue={parsedValue}
          isMobileKeyboardViewOpen={isMobileKeyboardViewOpen}
          toggleMobileKeyboardView={toggleMobileKeyboardView}
          currentlySelectingRangeEnd={currentlySelectingRangeEnd}
          setCurrentlySelectingRangeEnd={setCurrentlySelectingRangeEnd}
          startText={startText}
          endText={endText}
          toolbarTitle={toolbarTitle}
          toolbarFormat={toolbarFormat}
        />
      )}

      {isMobileKeyboardViewOpen ? (
        <MobileKeyboardInputView>
          <DateRangePickerInput disableOpenPicker ignoreInvalidInputs {...DateInputProps} />
        </MobileKeyboardInputView>
      ) : (
        renderView()
      )}
    </div>
  )
}

export const DateRangePickerView = DateRangePickerViewRaw as DateRangePickerViewComponent

DateRangePickerViewRaw.propTypes = {
  calendars: PropTypes.oneOf([1, 2, 3]),
  disableAutoMonthSwitching: PropTypes.bool,
}
