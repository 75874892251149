import { gql, TypedDocumentNode } from '@apollo/client'
import {
  UpdateFAQCategoryBackofficeFAQInfoDataInterface,
  UpdateFAQCategoryBackofficeFAQInfoInputInterface,
} from './interfaces'

const updateFAQCategoryBackofficeFAQInfo: TypedDocumentNode<
  UpdateFAQCategoryBackofficeFAQInfoDataInterface,
  UpdateFAQCategoryBackofficeFAQInfoInputInterface
> = gql`
  mutation UpdateFAQCategoryBackofficeFAQInfo(
    $input: UpdateFAQCategoryBackofficeFAQInfoActiveHealthInput!
  ) {
    UpdateFAQCategoryBackofficeFAQInfo(input: $input) {
      code
      message
      data
    }
  }
`

export default updateFAQCategoryBackofficeFAQInfo
