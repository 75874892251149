import { IMAGE_URL } from '@/constant/IMAGE'
import {
  BlockWrapper,
  CategoryMoveButton,
  ContainerWrapper,
  Description,
  DescriptionWrapper,
  DownArrow,
  SvgIcon,
  Title,
  UpArrow,
  Checkbox,
  CategoryMoveWrapper,
} from './styles'
import { CLASS_CATEGORY_MOVE, SubCategoryListProps } from './types'

const SubCategoryList = ({
  title,
  description,
  isMoveCategory,
  selected,
  onSelected,
  onClickSubCategory,
  onMoveUp,
  onMoveDown,
  index,
}: SubCategoryListProps) => {
  return (
    <ContainerWrapper
      isMoveCategory={isMoveCategory}
      selected={selected}
      data-testid={`faq-cate-wrapper-${index}`}
    >
      {isMoveCategory && (
        <Checkbox checked={selected} onChange={onSelected} data-testid={`faq-checkbox-${index}`} />
      )}
      {!isMoveCategory && (
        <CategoryMoveWrapper>
          <CategoryMoveButton
            className={CLASS_CATEGORY_MOVE}
            onClick={onMoveDown}
            data-testid={`faq-move-down-cate-${index}`}
          >
            <UpArrow src={IMAGE_URL.chevronUpIcon} />
          </CategoryMoveButton>
          <CategoryMoveButton
            className={CLASS_CATEGORY_MOVE}
            onClick={onMoveUp}
            data-testid={`faq-move-up-cate-${index}`}
          >
            <DownArrow src={IMAGE_URL.chevronDownIcon} />
          </CategoryMoveButton>
        </CategoryMoveWrapper>
      )}
      <BlockWrapper onClick={() => onClickSubCategory()} data-testid={`faq-cate-block-${index}`}>
        <Title>{title}</Title>
        <DescriptionWrapper>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </DescriptionWrapper>
        <SvgIcon src={IMAGE_URL.chevronRightIcon} />
      </BlockWrapper>
    </ContainerWrapper>
  )
}

export default SubCategoryList
