import styled from '@emotion/styled'
import { Alert as AntdAlert } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { DropzoneEventProps } from '@/components/feature/WelcomeCallPage/WelcomeCallImporter/types'
import Button from '@/components/common/Button'

const getDropzoneContainerBorderColor = (props: DropzoneEventProps) => {
  if (props.isDragActive) {
    return '#009640'
  }

  return '#cccccc'
}

export const Container = styled('div')``

export const HeaderContainer = styled('div')``

export const BackTitle = styled('div')`
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  cursor: pointer;
`

export const ImportContainer = styled('div')`
  padding: 24px 32px;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin: 24px 0;
`

export const ImportTitle = styled('p')`
  font-weight: 500;
  font-size: 18px;
  color: #000000;
`

export const Alert = styled(AntdAlert)`
  margin-bottom: 24px;
  border: 0;
`

export const DropzoneContainer = styled('div')<DropzoneEventProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  border-color: ${(props) => getDropzoneContainerBorderColor(props)};
  background: #ffffff;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  padding: 32px;
  cursor: pointer;
  margin-bottom: 24px;
`

export const UploadFileIcon = styled('img')`
  color: #83d4a0;
  font-size: 20px;
  margin-bottom: 20px;
`

export const UploadFileTitleContainer = styled('div')``

export const UploadFileClickTitle = styled('span')`
  font-weight: 500;
  font-size: 14px;
  color: #009640;
`

export const UploadFileDragTitle = styled('span')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const UploadFileSubtitle = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #666666;
`

export const ImportButtonContainer = styled('div')`
  text-align: right;
`

export const ImportButton = styled(Button)`
  border: 0;
`

export const FileInfoContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`

export const DeleteIcon = styled('img')`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const ImportResultContainer = styled('div')`
  margin: 24px 0;
`

export const ImportResultAlert = styled(AntdAlert)`
  border: 0;
`

export const ImportResultTitle = styled('div')`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`

export const ImportResultDetails = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
`

export const LeftArrow = styled(ArrowLeftOutlined)`
  font-size: 18px;
`
