import backofficeGetMe from './backofficeGetMe/BackofficeGetMe.gql'
import backofficeLogin from './backofficeLogin/BackofficeLogin.gql'
import createBackofficeUser from './createBackofficeUser/CreateBackofficeUser.gql'
import updateBackofficeUser from './updateBackofficeUser/UpdateBackofficeUser.gql'
import forgotBackofficeUserPassword from './forgotBackofficeUserPassword/ForgotBackofficeUserPassword.gql'
import resetBackofficeUserPassword from './resetBackofficeUserPassword/ResetBackofficeUserPassword.gql'
import getBackofficeUsers from './getBackofficeUsers/GetBackofficeUsers.gql'

const backofficeUser = {
  query: {
    backofficeGetMe,
    getBackofficeUsers,
  },
  mutation: {
    backofficeLogin,
    createBackofficeUser,
    updateBackofficeUser,
    forgotBackofficeUserPassword,
    resetBackofficeUserPassword,
  },
}

export default backofficeUser
