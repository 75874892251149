import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetEditExerciseDataDailySummaryActivityLogDataInterface,
  BackofficeGetEditExerciseDataDailySummaryActivityLogInputInterface,
} from './interfaces'

const backOfficeGetEditExerciseDataDailySummaryActivityLog: TypedDocumentNode<
  BackofficeGetEditExerciseDataDailySummaryActivityLogDataInterface,
  BackofficeGetEditExerciseDataDailySummaryActivityLogInputInterface
> = gql`
  query BackOfficeGetEditExerciseDataDailySummaryActivityLog($activeHealthDailySummaryId: String!) {
    backOfficeGetEditExerciseDataDailySummaryActivityLog(
      activeHealthDailySummaryId: $activeHealthDailySummaryId
    ) {
      id
      action
      module
      actionData
      refId
      actedById
      actedBy {
        firstname
        lastname
      }
      actedAt
    }
  }
`

export default backOfficeGetEditExerciseDataDailySummaryActivityLog
