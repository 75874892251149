import { useDropzone } from 'react-dropzone'
import { useEffect, useState } from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useRecoilValue } from 'recoil'
import message from '@/libs/message'
import { FileProps } from '@/components/feature/TviConnectPage/DeviceImportCard/types'
import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import config from '@/config'
import userAtom from '@/recoil/user'
import { ImportWelcomeCallCsvResponseInterface } from '@/components/feature/WelcomeCallPage/WelcomeCallImporter/interfaces'

// TODO: refactor
const ERROR_MESSAGE = {
  INVALID_HEADER: 'INVALID_HEADER',
}

const ERROR_TITLE = {
  INVALID_HEADER: 'ชื่อหัวข้อคอลัมน์ใน CSV ไม่ถูกต้อง',
}

function WelcomeCallImporterHook() {
  const user = useRecoilValue(userAtom)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [result, setResult] = useState<ImportWelcomeCallCsvResponseInterface | null>(null)
  const [file, setFile] = useState<FileProps>({
    name: null,
    source: null,
  })
  const { setConfirmModal } = ConfirmModalHook()
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
    },
  })

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('file', acceptedFiles[0])
    setIsLoading(true)

    const options: AxiosRequestConfig = {
      method: 'POST',
      url: `${config.graphqlRestEndpoint}/backoffice-welcome-call/update`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.accessToken}`,
      },
    }

    await axios(options)
      .then((response: AxiosResponse<{ data: ImportWelcomeCallCsvResponseInterface }>) => {
        if (response.data) {
          setResult(response.data.data)
          message.success({
            content: 'อัปโหลดข้อมูลการจัดส่งสำเร็จ',
            duration: 10,
          })
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message === ERROR_MESSAGE.INVALID_HEADER) {
          message.error({
            content: ERROR_TITLE.INVALID_HEADER,
            duration: 10,
          })
        } else {
          message.error({
            content: err.response.data.message,
            duration: 10,
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        handleRemoveFile()
        setConfirmModal({
          isVisible: false,
          onOkFunction: Function,
          dataTestId: undefined,
        })
      })
  }

  const handleRemoveFile = () => setFile({ name: null, source: null } as FileProps)

  const onModalVisible = () => {
    setConfirmModal({
      isVisible: true,
      onOkFunction: handleSubmit,
      title: 'คุณต้องการ Import ไฟล์นี้?',
      dataTestId: 'import-welcome-call-modal',
    })
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      // Set device import state to default, to prevent re-upload file.
      setFile({
        name: acceptedFiles[0].name,
        source: acceptedFiles[0],
      })
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (fileRejections.length > 0) {
      message.warning({
        content: 'ไม่รองรับไฟล์ประเภทนี้',
      })
      setFile({
        name: null,
        source: null,
      })
    }
  }, [fileRejections])

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    onModalVisible,
    handleRemoveFile,
    file,
    result,
    isLoading,
  }
}

export default WelcomeCallImporterHook
