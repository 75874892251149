import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeAdjustBonusPointDataInterface,
  BackofficeAdjustBonusPointInputInterface,
} from './interface'

export const backofficeAdjustBonusPoint: TypedDocumentNode<
  BackofficeAdjustBonusPointDataInterface,
  BackofficeAdjustBonusPointInputInterface
> = gql`
  mutation BackofficeAdjustBonusPoint($input: AdjustBonusPointInput!) {
    backofficeAdjustBonusPoint(input: $input) {
      id
      bonusPoint
    }
  }
`

export default backofficeAdjustBonusPoint
