import { gql, TypedDocumentNode } from '@apollo/client'
import {
  UpdateActiveHealthSpecialPointDataInterface,
  UpdateActiveHealthSpecialPointInput,
} from './interfaces'

export const backofficeUpdateActiveHealthSpecialPoint: TypedDocumentNode<
  UpdateActiveHealthSpecialPointDataInterface,
  UpdateActiveHealthSpecialPointInput
> = gql`
  mutation BackofficeUpdateActiveHealthSpecialPoint($input: UpdateActiveHealthSpecialPointInput!) {
    backofficeUpdateActiveHealthSpecialPoint(input: $input) {
      code
      message
      data
    }
  }
`

export default backofficeUpdateActiveHealthSpecialPoint
