import styled from '@emotion/styled'
import { Form, Input as AntdInput, Select as AntdSelect } from 'antd'

export const Container = styled('div')``

export const Select = styled(AntdSelect)`
  width: 288px;

  .ant-select-selector {
    height: 40px !important;
    padding: 10px 16px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
`

export const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
  width: 288px;

  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0 0 4px;
    white-space: initial;
    text-align: start;
  }
`

export const Input = styled(AntdInput)`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
`

export const Title = styled('div')`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 16px;
`

export const OrderNumber = styled('div')`
  position: absolute;
  top: 16px;
  left: 20px;
`
