import { IMAGE_URL } from '@/constant/IMAGE'

export enum ActiveHealthPolicyBillingSearchableFieldEnum {
  POLICY_NUMBER = 'policyNumber',
}

export enum CreditCardTypeEnum {
  AMEX = 'amex',
  DINNERS = 'dinners',
  JCB = 'jcb',
  MASTER_CARD = 'mastercard',
  VISA = 'visa',
}

export const CREDIT_CARD_TYPE_IMAGE = {
  [CreditCardTypeEnum.AMEX]: IMAGE_URL.amexIcon,
  [CreditCardTypeEnum.DINNERS]: IMAGE_URL.dinerIcon,
  [CreditCardTypeEnum.JCB]: IMAGE_URL.jcbIcon,
  [CreditCardTypeEnum.MASTER_CARD]: IMAGE_URL.mastercardIcon,
  [CreditCardTypeEnum.VISA]: IMAGE_URL.visaIcon,
}
