import { Block, Container, FilterButton, LeftArrow, RightArrow } from './styles'
import ActivityPeriodDateFilterHook from './ActivityPeriodDateFilter.hook'
import { ActivityPeriodDateFilterProps } from './types'

function ActivityPeriodDateFilter({
  handleNextRangeDateChart,
  handlePrevRangeDateChart,
  handlePeriodChange,
}: ActivityPeriodDateFilterProps) {
  const { filterList, filter, handleSetFilter } = ActivityPeriodDateFilterHook()

  return (
    <Container>
      <Block>
        <LeftArrow
          onClick={() => handlePrevRangeDateChart()}
          data-testid="policy-detail-exercise-tab-activity-previous-filter-button"
        />
        <RightArrow
          onClick={() => handleNextRangeDateChart()}
          data-testid="policy-detail-exercise-tab-activity-next-filter-button"
        />
      </Block>

      <Block>
        {filterList.map((filterItem, index) => (
          <>
            <FilterButton
              select={filter === filterItem.filter}
              onClick={() => {
                handleSetFilter(filterItem.filter)
                handlePeriodChange(filterItem.filter)
              }}
              start={index === 0}
              end={index + 1 === filterList.length}
              data-testid={`policy-detail-exercise-tab-activity-filter-${filterItem.dataTestId}`}
            >
              {filterItem.label}
            </FilterButton>
          </>
        ))}
      </Block>
    </Container>
  )
}

export default ActivityPeriodDateFilter
