import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeGetActiveHealthPartnerAllDataInterface } from './interface'

const backofficeGetActiveHealthPartnerAll: TypedDocumentNode<BackofficeGetActiveHealthPartnerAllDataInterface> = gql`
  query BackofficeGetActiveHealthPartnerAll {
    backofficeGetActiveHealthPartnerAll {
      id
      displayNameTh
      displayNameEn
      activeHealthPartnerBranch {
        id
        displayNameTh
        displayNameEn
      }
    }
  }
`

export default backofficeGetActiveHealthPartnerAll
