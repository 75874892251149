import { atom } from 'recoil'

import { HardResetWatchDeviceModalProps } from './types'

const initHardResetWatchDeviceModal: HardResetWatchDeviceModalProps = {
  id: undefined,
  open: false,
  isRefetchWatchDevice: false,
}

const hardResetWatchDeviceModalAtom = atom({
  key: 'hardResetWatchDeviceModalAtom',
  default: initHardResetWatchDeviceModal,
})

export default hardResetWatchDeviceModalAtom
