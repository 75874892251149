import { useRecoilState } from 'recoil'
import activeHealthPolicy from '@/gql/activeHealthPolicy'
import { useMutation } from '@apollo/client'
import message from '@/libs/message'
import hardResetWatchDeviceModalAtom from '@/recoil/hardResetWatchDeviceModal'

export default function HardResetWatchDeviceModalHook() {
  const [hardResetWatchDeviceModal, setHardResetWatchDeviceModal] =
    useRecoilState(hardResetWatchDeviceModalAtom)

  const [postBackofficeHardResetActiveHealthWearableMutation, { error }] = useMutation(
    activeHealthPolicy.mutation.backofficeHardResetActiveHealthWearable,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const handleSetHardResetWatchDeviceModal = () => {
    if (hardResetWatchDeviceModal.id)
      postBackofficeHardResetActiveHealthWearableMutation({
        variables: {
          id: hardResetWatchDeviceModal.id,
        },
      })
        .finally(() => {
          setHardResetWatchDeviceModal({ id: undefined, open: false, isRefetchWatchDevice: true })
        })
        .catch(() => {
          message.error({
            content: `ปัญหา: ${error?.message}`,
            duration: 10,
          })
        })
  }

  const onCancelModal = () => {
    setHardResetWatchDeviceModal({ id: undefined, open: false, isRefetchWatchDevice: false })
  }

  return { hardResetWatchDeviceModal, handleSetHardResetWatchDeviceModal, onCancelModal }
}
