import VerticalBarChart from '@/components/common/VerticalBarChart/VerticalBarChart'
import ActivityInformationBarChartHook from './ActivityInformationBarChart.hook'
import { Container } from './styles'
import { ActivityInformationBarChartProps } from './types'

function ActivityInformationBarChart({ stats, options }: ActivityInformationBarChartProps) {
  const { getData, setBarChartOptions } = ActivityInformationBarChartHook()
  const data = getData(stats)
  const barChartOptions = setBarChartOptions(options)

  return (
    <Container>
      <VerticalBarChart data={data} options={barChartOptions} />
    </Container>
  )
}

export default ActivityInformationBarChart
