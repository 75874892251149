import { useState } from 'react'
import { ActivityPeriodDateFilterEnum } from '@/constant/ACTIVITY_PERIOD_DATE_FILTER'

function ActivityPeriodDateFilterHook() {
  const [filter, setFilter] = useState<ActivityPeriodDateFilterEnum>(
    ActivityPeriodDateFilterEnum.WEEK,
  )
  const filterList: Array<{
    filter: ActivityPeriodDateFilterEnum
    label: string
    dataTestId: string
  }> = [
    { filter: ActivityPeriodDateFilterEnum.WEEK, label: 'สัปดาห์', dataTestId: 'week' },
    { filter: ActivityPeriodDateFilterEnum.MONTH, label: 'เดือน', dataTestId: 'month' },
    { filter: ActivityPeriodDateFilterEnum.YEAR, label: 'ปี', dataTestId: 'year' },
  ]

  const handleSetFilter = (filter: ActivityPeriodDateFilterEnum) => setFilter(filter)

  return { filter, filterList, handleSetFilter }
}

export default ActivityPeriodDateFilterHook
