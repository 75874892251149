import styled from '@emotion/styled'
import { Dropdown } from 'antd'

import { BarProps } from './types'

export const Bar = styled('header')<BarProps>`
  height: 72px;
  background-color: ${(props) => props.bgColor};
  width: 100%;
  display: flex;
  min-width: 0;
  flex: 0 0 auto;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`
export const Header = styled('div')`
  height: 26px;
  padding: 4px 40px;
  color: white;
  background-color: #006029;
`

export const Logo = styled('img')`
  height: 100%;
  padding: 14px 40px;
  padding-left: 0;
`

export const UserContainer = styled('div')`
  width: 100%;
  padding-right: 46px;
  display: flex;
  justify-content: flex-end;
`

export const UserDropdown = styled(Dropdown)`
  color: white;
  font-weight: 500;
`

export const UserText = styled('div')`
  display: flex;
  align-items: center;

  cursor: pointer;
  color: white;
  font-weight: 500;

  // Suffix Icon styled
  span {
    margin-left: 10px;
  }
`

export const HamburgerIcon = styled('img')`
  cursor: pointer;
  margin: 0 27px;
`
