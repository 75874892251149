import backofficeListWatchBrand from './backofficeListWatchBrand/BackofficeListWatchBrand.gql'

const watchBrand = {
  query: {
    backofficeListWatchBrand,
  },
  mutation: {},
}

export default watchBrand
