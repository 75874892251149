export type SubCategoryListProps = {
  title: string
  description: string
  isMoveCategory: boolean
  selected: boolean
  onSelected: () => void
  onClickSubCategory: () => void
  onMoveUp: () => void
  onMoveDown: () => void
  index: number
}

export const CLASS_CATEGORY_MOVE = 'classCategoryMove'
