import { atom } from 'recoil'

import { ForgotBackofficeUserPasswordProps } from './types'

const initForgotBackofficeUserPassword = {
  firstname: null,
  lastname: null,
  email: null,
  staffId: null,
  updatedAt: null,
  isSuccessful: false,
} as ForgotBackofficeUserPasswordProps

const forgotBackofficeUserPasswordAtom = atom({
  key: 'forgotBackofficeUserPasswordAtom',
  default: initForgotBackofficeUserPassword,
})

export default forgotBackofficeUserPasswordAtom
