import dayjs from 'dayjs'

export const diffDayRounded = (firstDate: Date, secondDate: Date) => {
  const firstDay = dayjs(firstDate)
  const secondDay = dayjs(secondDate)
  const diffDay = secondDay.diff(firstDay, 'day')
  const isExact = secondDay.isSame(firstDay.add(diffDay, 'day'))

  if (secondDay.isSameOrAfter(firstDay)) {
    return isExact ? diffDay : diffDay + 1
  }
  return isExact ? diffDay : diffDay - 1
}
