import backofficeGetWelcomeCallPagination from './backofficeGetWelcomeCallPagination/BackofficeGetWelcomeCallPagination.gql'
import backofficeGetWelcomeCallFilterDateWithCountCallStatus from './backofficeGetWelcomeCallFilterDateWIthCountCallStatus/BackofficeGetWelcomeCallFilterDateWithCountCallStatus.gql'
import backofficeUpdateWelcomeCall from './backofficeUpdateWelcomeCall/BackofficeUpdateWelcomeCall.gql'
import backofficeListWelcomeCallPurchaseChannel from './backofficeListWelcomeCallPurchaseChannel/backofficeListWelcomeCallPurchaseChannel.gql'
import backofficeListWelcomeCallPaymentMethod from './backofficeListWelcomeCallPaymentMethod/backofficeListWelcomeCallPaymentMethod.gql'

const welcomeCall = {
  query: {
    backofficeGetWelcomeCallPagination,
    backofficeGetWelcomeCallFilterDateWithCountCallStatus,
    backofficeListWelcomeCallPurchaseChannel,
    backofficeListWelcomeCallPaymentMethod,
  },
  mutation: {
    backofficeUpdateWelcomeCall,
  },
}

export default welcomeCall
