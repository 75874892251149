import { IMAGE_URL } from '@/constant/IMAGE'
import {
  Breadcrumb,
  BreadcrumbItem,
  ButtonDefault,
  ButtonWrapper,
  Container,
  DescriptionWrapper,
  Detail,
  HeaderWrapper,
  IconSvg,
  Title,
  TitleWrapper,
} from './styles'
import { ReadQuestionProps } from './types'

const ReadQuestion = ({
  onEdit,
  onMoveQuestion,
  onDelete,
  title,
  createdBy,
  description,
  categoryTitle,
  canDelete,
  canEdit,
  canMove,
}: ReadQuestionProps) => {
  return (
    <Container>
      <Breadcrumb>
        <BreadcrumbItem>{categoryTitle}</BreadcrumbItem>
        <BreadcrumbItem>
          <span>{title}</span>
        </BreadcrumbItem>
      </Breadcrumb>
      <HeaderWrapper>
        <TitleWrapper>
          <Title>{title}</Title>
          <Detail>{createdBy}</Detail>
        </TitleWrapper>
        <ButtonWrapper>
          {canEdit ? (
            <ButtonDefault
              onClick={() => onEdit()}
              icon={<IconSvg src={IMAGE_URL.editIcon} />}
              type="default"
              data-testid="faq-read-question-edit-button"
            >
              แก้ไข
            </ButtonDefault>
          ) : undefined}
          {canMove ? (
            <ButtonDefault
              onClick={() => onMoveQuestion()}
              icon={<IconSvg src={IMAGE_URL.shareIcon} />}
              type="default"
              data-testid="faq-read-question-move-cate-button"
            >
              ย้ายหมวดคำถาม
            </ButtonDefault>
          ) : undefined}
          {canDelete ? (
            <ButtonDefault
              onClick={() => onDelete()}
              icon={<IconSvg src={IMAGE_URL.deleteIcon} />}
              type="default"
              data-testid="faq-read-question-delete-button"
            >
              ลบคำถาม
            </ButtonDefault>
          ) : undefined}
        </ButtonWrapper>
      </HeaderWrapper>
      <DescriptionWrapper dangerouslySetInnerHTML={{ __html: description }} />
    </Container>
  )
}

export default ReadQuestion
