import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthManualDiscountFileDataInterface,
  BackofficeGetActiveHealthManualDiscountFileInputInterface,
} from './interfaces'

const backofficeGetActiveHealthManualDiscountFile: TypedDocumentNode<
  BackofficeGetActiveHealthManualDiscountFileDataInterface,
  BackofficeGetActiveHealthManualDiscountFileInputInterface
> = gql`
  query BackofficeGetActiveHealthManualDiscountFile($fileId: String!) {
    backofficeGetActiveHealthManualDiscountFile(fileId: $fileId) {
      fileUrl
    }
  }
`

export default backofficeGetActiveHealthManualDiscountFile
