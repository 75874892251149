import { LoadingOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Spin } from 'antd'

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
  background: rgba(255, 255, 255, 1);
`

export const SpinAntd = styled(Spin)``

export const IconLoading = styled(LoadingOutlined)``

export const Title = styled('div')`
  margin-top: 20px;
  color: rgba(102, 102, 102, 1);
`
