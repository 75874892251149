import { useRecoilValue } from 'recoil'
import { validatePerm } from '@/libs/validatePermission'
import { Container } from './styles'
import WellBeingPage from '@/components/feature/WellBeingPage/WellBeingPage'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import userAtom from '@/recoil/user/atom'
import UnauthorizedPage from '../Unauthorized/Unauthorized'

function WellBeing() {
  const user = useRecoilValue(userAtom)

  return (
    <>
      {validatePerm([BACKOFFICE_USER_PERMISSION.WBE010101], user.permissions) ? (
        <Container>
          <WellBeingPage />
        </Container>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default WellBeing
