import { Container, Title, Tab } from './styles'
import ActiveHealthPolicyFilterTabHook from './ActiveHealthPolicyFilterTab.hook'
import {
  ACTIVE_HEALTH_POLICY_STATUS,
  ActiveHealthPolicyStatusEnum,
} from '@/constant/ACTIVE_HEALTH_POLICY'

function ActiveHealthPolicyFilterTab() {
  const { policyStatus, setPolicyStatus } = ActiveHealthPolicyFilterTabHook()
  return (
    <Container data-testid="policy-management-tab">
      <Tab>
        <Title
          select={policyStatus === ActiveHealthPolicyStatusEnum.CANCELLED_WITH_REASON}
          onClick={() => setPolicyStatus(ActiveHealthPolicyStatusEnum.CANCELLED_WITH_REASON)}
          data-testid="policy-management-tab-filter-cancelled-with-reason"
        >
          {ACTIVE_HEALTH_POLICY_STATUS[ActiveHealthPolicyStatusEnum.CANCELLED]}
        </Title>
      </Tab>
      <Tab>
        <Title
          select={policyStatus === ActiveHealthPolicyStatusEnum.EXPIRED}
          onClick={() => setPolicyStatus(ActiveHealthPolicyStatusEnum.EXPIRED)}
          data-testid="policy-management-tab-filter-expired"
        >
          {ACTIVE_HEALTH_POLICY_STATUS[ActiveHealthPolicyStatusEnum.EXPIRED]}
        </Title>
      </Tab>
      <Tab>
        <Title
          select={policyStatus === ActiveHealthPolicyStatusEnum.ACTIVE}
          onClick={() => setPolicyStatus(ActiveHealthPolicyStatusEnum.ACTIVE)}
          data-testid="policy-management-tab-filter-active"
        >
          {ACTIVE_HEALTH_POLICY_STATUS[ActiveHealthPolicyStatusEnum.ACTIVE]}
        </Title>
      </Tab>
      <Tab>
        <Title
          select={policyStatus === ActiveHealthPolicyStatusEnum.NOT_YET}
          onClick={() => setPolicyStatus(ActiveHealthPolicyStatusEnum.NOT_YET)}
          data-testid="policy-management-tab-filter-not-yet"
        >
          {ACTIVE_HEALTH_POLICY_STATUS[ActiveHealthPolicyStatusEnum.NOT_YET]}
        </Title>
      </Tab>
      <Tab>
        <Title
          select={policyStatus === ActiveHealthPolicyStatusEnum.ALL}
          onClick={() => setPolicyStatus(ActiveHealthPolicyStatusEnum.ALL)}
          data-testid="policy-management-tab-filter-all"
        >
          {ACTIVE_HEALTH_POLICY_STATUS[ActiveHealthPolicyStatusEnum.ALL]}
        </Title>
      </Tab>
    </Container>
  )
}

export default ActiveHealthPolicyFilterTab
