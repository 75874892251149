import { gql, TypedDocumentNode } from '@apollo/client'
import {
  RemoveBackofficeFAQCategoryDataInterface,
  RemoveBackofficeFAQCategoryInput,
} from './interfaces'

export const removeBackofficeFAQCategory: TypedDocumentNode<
  RemoveBackofficeFAQCategoryDataInterface,
  RemoveBackofficeFAQCategoryInput
> = gql`
  mutation RemoveBackofficeFAQCategory($input: RemoveBackofficeFAQCategoryActiveHealthInput!) {
    removeBackofficeFAQCategory(input: $input) {
      id
      title
      order
    }
  }
`

export default removeBackofficeFAQCategory
