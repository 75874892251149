import { useState } from 'react'
import { useParams } from 'react-router-dom'

function WellBeingPageHook() {
  const [isPartnerActive, setIsPartnerActive] = useState<boolean>(false)
  const { partnerId } = useParams<{ partnerId: string }>()

  const handleIsPartnerActive = (isActive: boolean) => setIsPartnerActive(isActive)

  return {
    handleIsPartnerActive,
    isPartnerActive,
    partnerId,
  }
}

export default WellBeingPageHook
