import React from 'react'
import { Route, Routes as ReactRouterDomRoutes } from 'react-router-dom'
import RoutesHook from './Routes.hook'
import { Unauthorized } from './pages'
import CONSTANT from '@/constant'

function Routes() {
  const { routeComponents } = RoutesHook()

  return (
    <ReactRouterDomRoutes>
      <Route path={CONSTANT.ROUTES.UNAUTHORIZED_PAGE} element={<Unauthorized />} />
      {routeComponents}
    </ReactRouterDomRoutes>
  )
}

export default Routes
