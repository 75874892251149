import { IMAGE_URL } from '@/constant/IMAGE'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import {
  AddMemberIcon,
  Button,
  CardWrapper,
  DescriptionNoTeamMember,
  NoTeamMemberWrapper,
  SearchInput,
  SearchWrapper,
  Title,
  TitleNoTeamMember,
} from './styles'
import TabUserHook from './TabUser.hook'
import ModalAddUser from '../components/ModalAddUser/ModalAddUser'
import Table from '@/components/common/Table'

const TabUser = () => {
  const {
    backofficeUserTeamRole,
    columns,
    handleAddUserModal,
    pagination,
    debouncedSearch,
    onHandlePageChange,
    loadingGetBackofficeUserTeamRolePaginatedQuery,
  } = TabUserHook()

  return (
    <CardWrapper data-testid="user-permission-user-tab">
      <Title>รายชื่อผู้ใช้งาน</Title>
      <SearchWrapper>
        <SearchInput
          placeholder="ค้นหา..."
          prefix={<SearchOutlined style={{ color: '#ADADAD' }} />}
          onChange={debouncedSearch}
          data-testid="user-permission-user-tab-search-user-input"
        />
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={handleAddUserModal}
          data-testid="user-permission-user-tab-add-user-button"
        >
          เพิ่มผู้ใช้งาน
        </Button>
      </SearchWrapper>
      {backofficeUserTeamRole && backofficeUserTeamRole?.length > 0 ? (
        <Table
          loading={loadingGetBackofficeUserTeamRolePaginatedQuery}
          columns={columns}
          dataSource={backofficeUserTeamRole}
          pagination={{
            pageSizeOptions: [10, 25, 50, 100],
            showSizeChanger: true,
            total: pagination?.totalItems,
            current: pagination.page,
            defaultCurrent: 1,
            pageSize: pagination.limit,
            onChange: (page, pageSize) => onHandlePageChange(page, pageSize),
          }}
          totalRecord={pagination?.totalItems}
          data-testid="user-permission-user-tab-user-list-table"
        />
      ) : (
        <NoTeamMemberWrapper>
          <AddMemberIcon src={IMAGE_URL.userIcon} />
          <TitleNoTeamMember>ไม่มีผู้ใช้งาน</TitleNoTeamMember>
          <DescriptionNoTeamMember>คุณสามารถสร้างบัญชีผู้ใช้งานระบบนี้</DescriptionNoTeamMember>
          <DescriptionNoTeamMember>เพียงกดปุ่ม “เพิ่มผู้ใช้งาน”</DescriptionNoTeamMember>
        </NoTeamMemberWrapper>
      )}
      <ModalAddUser />
    </CardWrapper>
  )
}

export default TabUser
