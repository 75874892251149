import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeLoginDataInterface, BackofficeLoginInputInterface } from './interfaces'

export const backofficeLogin: TypedDocumentNode<
  BackofficeLoginDataInterface,
  BackofficeLoginInputInterface
> = gql`
  mutation BackofficeLogin($input: BackofficeLoginAuthInput!) {
    backofficeLogin(input: $input) {
      accessToken
      refreshToken
    }
  }
`
export default backofficeLogin
