import { atom } from 'recoil'

import { DeferredDiscountModalProps } from './types'

const initDeferredDiscountModal: DeferredDiscountModalProps = {
  open: false,
  activeHealthPolicyBilling: undefined,
}

const deferredDiscountModalAtom = atom({
  key: 'deferredDiscountModalAtom',
  default: initDeferredDiscountModal,
})

export default deferredDiscountModalAtom
