import { useCallback, useState } from 'react'
import { activeHealthPolicyGql } from '@/gql'
import { useLazyQuery } from '@apollo/client'
import {
  ActiveHealthManualDiscountFilterApproveStatusEnum,
  ActiveHealthManualDiscountPaginationInterface,
} from '@/gql/activeHealthPolicy/backofficeGetActiveHealthManualDiscountPagination/interfaces'

function ManualDiscountInformationHook() {
  const [fileUrl, setFileUrl] = useState<string>('')
  const [backofficeGetActiveHealthManualDiscountFile, { loading: getManualDiscountFileLoading }] =
    useLazyQuery(activeHealthPolicyGql.query.backofficeGetActiveHealthManualDiscountFile, {
      fetchPolicy: 'no-cache',
    })

  const getActiveHealthManualDiscountFile = useCallback(
    (fileId: string) => {
      backofficeGetActiveHealthManualDiscountFile({
        variables: {
          fileId,
        },
      }).then(({ data }) => {
        if (data?.backofficeGetActiveHealthManualDiscountFile?.fileUrl) {
          setFileUrl(data.backofficeGetActiveHealthManualDiscountFile.fileUrl)
        }
      })
    },
    [backofficeGetActiveHealthManualDiscountFile],
  )

  const getFileExtension = (fileKey: string) => (fileKey ? fileKey.split('.')[1] : '')

  const handleDisplayPvStatus = (
    activeHealthManualDiscount: ActiveHealthManualDiscountPaginationInterface,
  ) => {
    if (
      activeHealthManualDiscount.approveStatus ===
      ActiveHealthManualDiscountFilterApproveStatusEnum.REJECT.toLocaleUpperCase()
    ) {
      return 'ไม่จัดส่ง'
    }

    if (
      activeHealthManualDiscount.approveStatus ===
      ActiveHealthManualDiscountFilterApproveStatusEnum.PENDING.toLocaleUpperCase()
    ) {
      return '-'
    }

    if (
      activeHealthManualDiscount.approveStatus ===
      ActiveHealthManualDiscountFilterApproveStatusEnum.APPROVE.toLocaleUpperCase()
    ) {
      if (activeHealthManualDiscount.activeHealthPolicyBilling.isPvDiscountSuccess === true) {
        return 'สำเร็จ'
      } else if (
        activeHealthManualDiscount.activeHealthPolicyBilling.isPvDiscountSuccess === false
      ) {
        return 'ไม่สำเร็จ'
      } else {
        return 'รอผลการจัดส่ง'
      }
    }
  }

  return {
    getActiveHealthManualDiscountFile,
    fileUrl,
    getFileExtension,
    handleDisplayPvStatus,
    getManualDiscountFileLoading,
  }
}

export default ManualDiscountInformationHook
