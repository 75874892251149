import DetailCard from '../DetailCard'
import ChargePointCardHook from './ChargePointCard.hook'
import {
  TitleContainer,
  ChargeNumber,
  ListAntd,
  ListItemAntd,
  ListItemTextAntd,
  LocationContainer,
  LocationIcon,
  LocaitonTitle,
  ChargeTitle,
  Skeleton,
} from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'

function ChargePointCard() {
  const {
    policy,
    chargingLocationsByPolicyNumber,
    backofficeGetChargingLocationsByPolicyNumberLoading,
  } = ChargePointCardHook()

  return (
    <DetailCard
      visible={!!policy.effectiveDate}
      title={
        <TitleContainer>
          <ChargeTitle>จุดชาร์จประจำ&nbsp;</ChargeTitle>
          <ChargeNumber>
            {chargingLocationsByPolicyNumber?.backofficeGetChargingLocationsByPolicyNumber
              ? `${chargingLocationsByPolicyNumber?.backofficeGetChargingLocationsByPolicyNumber.length}/10`
              : '0/10'}
          </ChargeNumber>
        </TitleContainer>
      }
      dataTestId="policy-detail-charge-point-card"
      rightItem={<></>}
    >
      {backofficeGetChargingLocationsByPolicyNumberLoading ? (
        <Skeleton active />
      ) : chargingLocationsByPolicyNumber?.backofficeGetChargingLocationsByPolicyNumber &&
        chargingLocationsByPolicyNumber?.backofficeGetChargingLocationsByPolicyNumber?.length >
          0 ? (
        <ListAntd>
          {chargingLocationsByPolicyNumber?.backofficeGetChargingLocationsByPolicyNumber?.map(
            (location, index) => (
              <ListItemAntd key={index}>
                <ListItemTextAntd>{index + 1}</ListItemTextAntd>
                &nbsp;&nbsp;{location.address}
              </ListItemAntd>
            ),
          )}
        </ListAntd>
      ) : (
        <LocationContainer>
          <LocationIcon src={IMAGE_URL.locationIcon} />
          <LocaitonTitle>ไม่มีข้อมูล</LocaitonTitle>
        </LocationContainer>
      )}
    </DetailCard>
  )
}

export default ChargePointCard
