import { CSVLink } from 'react-csv'
import {
  Container,
  Header,
  Title,
  ExportButton,
  TableContainer,
  ExportIcon,
  HeaderContainer,
} from './styles'
import WelcomeCallUploadFailedTableHook from './WelcomeCallUploadFailedTable.hook'
import { IMAGE_URL } from '@/constant/IMAGE'
import dayjs from 'dayjs'
import { WelcomeCallUploadFailedTableProps } from './types'
import Table from '@/components/common/Table'

function WelcomeCallUploadFailedTable({ failedRecord }: WelcomeCallUploadFailedTableProps) {
  const { columns } = WelcomeCallUploadFailedTableHook()

  return (
    <Container>
      <Header>
        <TableContainer>
          <HeaderContainer>
            <Title>รายการที่ไม่สำเร็จ</Title>
            <ExportButton data-testid="welcome-call-export-file-button">
              <CSVLink
                data={failedRecord && failedRecord.length > 0 ? failedRecord : []}
                filename={`welcome-call-import-failed-${dayjs().toISOString()}.csv`}
              >
                <ExportIcon src={IMAGE_URL.exportIcon} /> Export
              </CSVLink>
            </ExportButton>
          </HeaderContainer>
          <Table
            columns={columns}
            scroll={{
              x: '200vw',
            }}
            pagination={{
              pageSizeOptions: [10, 25, 50, 100],
              showSizeChanger: true,
            }}
            dataSource={failedRecord}
            data-testid="welcome-call-import-result-table"
          />
        </TableContainer>
      </Header>
    </Container>
  )
}

export default WelcomeCallUploadFailedTable
