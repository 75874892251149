import {
  CardContainer,
  Container,
  Information,
  InformationContainer,
  InformationTitle,
  InsureIcon,
  PolicyStatusContainer,
  Title,
  SkeletonContainer,
  Skeleton,
  SkeletonAvatar,
} from './styles'
import PolicyStatusTag from '../PolicyStatusTag'
import PolicyCardHook from './PolicyCard.hook'
import { SearchStateEnum } from '@/components/feature/PolicyDetailPage/SearchPolicyBox/SearchPolicyBox.hook'

function PolicyCard({
  isComponentLoading,
  searchState,
}: {
  isComponentLoading: boolean
  searchState: SearchStateEnum
}) {
  const { policies, handleDisplayPolicy, policyIcons } = PolicyCardHook()

  return (
    <Container
      visible={searchState === SearchStateEnum.SEARCHING || searchState === SearchStateEnum.FINISH}
    >
      <Title>กรมธรรม์ประกันภัย</Title>
      {isComponentLoading ? (
        <SkeletonContainer>
          <SkeletonAvatar size="large" active />
          <Skeleton paragraph={{ rows: 1 }} active />
        </SkeletonContainer>
      ) : (
        policies.map((policy, index) => (
          <CardContainer
            onClick={() => handleDisplayPolicy(policy.policyNumber)}
            key={`policy-card-${index}`}
            data-testid={`policy-detail-policy-card-${policy.policyNumber}`}
          >
            <InformationContainer>
              {policyIcons
                .filter((icon) => icon.policyGroup === policy.policyGroup)
                .map((filteredIcon, index) => (
                  <div key={index}>
                    <InsureIcon src={filteredIcon.src} />
                  </div>
                ))}

              <div>
                <InformationTitle>แผนประกันภัย</InformationTitle>
                <Information data-testid="policy-detail-policy-card-planName">
                  {policy.planName || policy.campaignName
                    ? policy.planName || policy.campaignName
                    : '-'}
                </Information>
              </div>
              <div>
                <InformationTitle>เลขกรมธรรม์</InformationTitle>
                <Information data-testid="policy-detail-policy-card-policyNumber">
                  {policy.policyNumber}
                </Information>
              </div>
              {policy.vehicleNumber ? (
                <div>
                  <InformationTitle>ทะเบียนรถยนต์</InformationTitle>
                  <Information data-testid="policy-detail-policy-card-vehicleNumber">
                    {policy.vehicleNumber}
                  </Information>
                </div>
              ) : (
                <div />
              )}
              <div />
              <PolicyStatusContainer>
                <PolicyStatusTag
                  data-testid="policy-detail-policy-card-policySuperAppStatus"
                  policySuperAppStatus={policy.policySuperAppStatus}
                />
              </PolicyStatusContainer>
            </InformationContainer>
          </CardContainer>
        ))
      )}
    </Container>
  )
}

export default PolicyCard
