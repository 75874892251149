import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { fromEvent, of } from 'rxjs'
import { debounceTime, map, distinctUntilChanged, switchMap, tap } from 'rxjs/operators'
import { useLazyQuery } from '@apollo/client'
import { watchBrandGql } from '@/gql'
import { WatchBrandType } from './types'
import dayjs from 'dayjs'

function WatchManagementTableHook() {
  const searchElement = document.getElementById('watch-search')
  const [isTyping, setIsTyping] = useState(false)
  const [watches, setWatches] = useState<Array<WatchBrandType>>([])
  const [filterWatches, setFilterWatches] = useState<Array<WatchBrandType>>([])

  const [getWatchBrands, { loading: watchBrandsLoading }] = useLazyQuery(
    watchBrandGql.query.backofficeListWatchBrand,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (watchBrandsData) => {
        const watchBrands = watchBrandsData?.backofficeListWatchBrand ?? []
        const watches: Array<WatchBrandType> = []
        let watchIndex = 0

        watchBrands.forEach((watchBrand) => {
          if (watchBrand.watchBrandSeries.length === 0) {
            watches.push({
              watchBrand: watchBrand.name,
              order: watchBrand.order,
              watchModel: '-',
              createdAt: dayjs(watchBrand.createdAt).format('DD-MM-YYYY HH:mm:ss'),
              updatedAt: dayjs(watchBrand.updatedAt).format('DD-MM-YYYY HH:mm:ss'),
            })
          } else {
            const watchSeries = watchBrand.watchBrandSeries.map((series) => ({
              watchBrand: watchBrand.name,
              order: watchBrand.order,
              watchModel: series.name,
              createdAt: dayjs(series.createdAt).format('DD-MM-YYYY HH:mm:ss'),
              updatedAt: dayjs(series.updatedAt).format('DD-MM-YYYY HH:mm:ss'),
              id: ++watchIndex,
            }))

            watches.push(...watchSeries)
          }
        })

        return setWatches(watches)
      },
    },
  )

  const columns: ColumnsType<WatchBrandType | {}> = [
    {
      title: 'ลำดับ',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (_value, _record, number) => {
        return number + 1
      },
    },
    {
      title: 'ชื่อนาฬิกา',
      dataIndex: 'watchBrand',
      key: 'watchBrand',
    },
    {
      title: 'ลำดับการแสดงผลหน้าเลือกนาฬิกา',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'รุ่นนาฬิกา',
      dataIndex: 'watchModel',
      key: 'watchModel',
    },
    {
      title: 'วันที่สร้างรายการ',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'วันที่อัปเดทล่าสุด',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
  ]

  useEffect(() => {
    if (isTyping && searchElement) {
      const getWatches = (key: string) =>
        of(watches.filter((e) => e.watchBrand.toLowerCase().indexOf(key.toLowerCase()) > -1))

      fromEvent(searchElement, 'keyup')
        .pipe(
          debounceTime(1000),
          map((e: Event) => {
            const target = e.target as HTMLInputElement

            return target.value || ''
          }),
          distinctUntilChanged(),
          switchMap(getWatches),
          tap((w) => setFilterWatches(w)),
        )
        .subscribe()
    }
  }, [isTyping, searchElement, watches])

  // fetch first time rendering.
  useEffect(() => {
    getWatchBrands()
  }, [getWatchBrands])

  // for reference data only from watches
  useEffect(() => {
    if (watches) {
      setFilterWatches(watches)
    }
  }, [watches])

  return { columns, setIsTyping, filterWatches, watchBrandsLoading }
}

export default WatchManagementTableHook
