import styled from '@emotion/styled'
import { Input as AntInput } from 'antd'
import { CLASS_NAME_EDIT, CLASS_NAME_MOVE } from './types'

export const CategoryTextWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-gap: 8px;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;

  .${CLASS_NAME_EDIT} {
    display: none;
  }

  .${CLASS_NAME_MOVE} {
    display: none;
  }

  :hover {
    background-color: #f6f6f6;

    .${CLASS_NAME_EDIT} {
      display: block;
    }

    .${CLASS_NAME_MOVE} {
      display: block;
    }
  }
`

export const CategoryEditButton = styled('div')``

export const CategoryMoveButton = styled('div')`
  cursor: pointer;
`

export const CategoryText = styled('span')<{ isColor: boolean }>`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.isColor ? '#009640' : '#000000')};
`

export const CategoryEditWrapper = styled('div')`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr auto;
  align-items: center;
`

export const DeleteIcon = styled('img')`
  cursor: pointer;
  margin: 12px;
  height: 20px;
  width: 20px;
`

export const Input = styled(AntInput)`
  caret-color: #009640;
`

export const ImageIcon = styled('img')`
  cursor: pointer;
  height: 16px;
  width: 16px;
`

export const MoveIcon = styled('img')`
  cursor: grab;
  height: 16px;
  width: 16px;

  :active {
    cursor: grabbing;
  }
`

export const DownArrow = styled('img')``

export const UpArrow = styled('img')``
