import { IMAGE_URL } from '@/constant/IMAGE'
import { UploadOutlined } from '@ant-design/icons'
import { Form, Radio, Upload } from 'antd'
import {
  AntdTextArea,
  Container,
  FormItem,
  ImgWrapper,
  Input,
  Span,
  Image,
  PreviewImage,
  BoostPointIcon,
  PreviewText,
  ButtonPopUp,
  BuddhistDatePickerStyled,
  DviWrapper,
} from './styles'
import { CampaignModalProps } from './types'
import { ActiveHealthSpecialPointEnum } from '@/gql/activeHealthSpecialPointCampaign/types'
import CampaignModalHook from './CampaignModal.hook'
import Modal from '@/components/common/Modal'

const CampaignModal = ({
  isModalOpen,
  title,
  handleOk,
  handleCancel,
  dataCampaign,
}: CampaignModalProps) => {
  const {
    form,
    descriptionThCampaign,
    handleUploadProps,
    onSubmit,
    disabledSave,
    handleFormChange,
    handleDisabledSave,
    disabledStatusCampaign,
    isLoading,
    files,
    setFiles,
    setDefaultFiles,
    isDisabledPickerRangePicker,
    disabledDatePickerRangePicker,
  } = CampaignModalHook({
    handleOk,
    dataCampaign,
  })

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={onSubmit}
      onCancel={() => {
        handleDisabledSave()
        setFiles([])
        setDefaultFiles([])
        form.resetFields()
        handleCancel()
      }}
      width={900}
      okButtonProps={{ disabled: disabledSave, loading: isLoading }}
      data-testid="campaign-modal"
    >
      <Container>
        <Form
          layout="vertical"
          form={form}
          initialValues={{ type: ActiveHealthSpecialPointEnum.MULTIPLIER }}
          onFieldsChange={handleFormChange}
          data-testid="campaign-modal-form"
        >
          <FormItem label="ชื่อ Campaign" name="title">
            <Input
              placeholder="ระบุ"
              disabled={disabledStatusCampaign()}
              data-testid="campaign-modal-name-input"
            />
          </FormItem>
          <FormItem label="ตัวเลือกเปลี่ยนเงื่อนไข" name="type">
            <Radio.Group
              defaultValue={ActiveHealthSpecialPointEnum.MULTIPLIER}
              disabled={disabledStatusCampaign()}
              data-testid="campaign-modal-point-type-selected"
            >
              <Radio
                value={ActiveHealthSpecialPointEnum.MULTIPLIER}
                data-testid="campaign-modal-point-type-multiplier"
              >
                x 2 คะแนน
              </Radio>
              <Radio
                value={ActiveHealthSpecialPointEnum.TARGET}
                data-testid="campaign-modal-point-type-target"
              >
                2 คะแนนได้ส่วนลด
              </Radio>
            </Radio.Group>
          </FormItem>
          <FormItem label="ระยะเวลา Campaign" name="dateRang">
            <BuddhistDatePickerStyled
              format="DD/MM/BBBB"
              disabledDate={disabledDatePickerRangePicker}
              disabled={isDisabledPickerRangePicker()}
              data-testid="campaign-modal-datepicker"
            />
          </FormItem>
          <FormItem label="คำอธิบายภาษาไทย" name="descriptionTh">
            <AntdTextArea
              rows={4}
              placeholder="เขียนคำอธิบายภาษาไทย"
              showCount
              maxLength={55}
              disabled={disabledStatusCampaign()}
              data-testid="campaign-modal-thai-desc"
            />
          </FormItem>
          <FormItem label="คำอธิบายภาษาอังกฤษ" name="descriptionEn">
            <AntdTextArea
              rows={4}
              placeholder="เขียนคำอธิบายภาษาอังกฤษ"
              showCount
              maxLength={55}
              disabled={disabledStatusCampaign()}
              data-testid="campaign-modal-eng-desc"
            />
          </FormItem>
          <FormItem label="รูปภาพ pop-up" name="imageUrl">
            <Upload {...handleUploadProps} maxCount={1}>
              <ButtonPopUp
                type="default"
                icon={<UploadOutlined />}
                data-testid="campaign-modal-choose-button"
              >
                เลือก
              </ButtonPopUp>
            </Upload>
          </FormItem>
          <Span>jpg,png ขนาด 500 x 500px</Span>
        </Form>
        <ImgWrapper>
          <DviWrapper>
            <Image src={IMAGE_URL.AHExercisePoint} />
            {files && files?.length > 0 && (
              <PreviewImage
                src={files[0].response?.url}
                data-testid="campaign-modal-preview-image"
              />
            )}
          </DviWrapper>
          <DviWrapper>
            <Image src={IMAGE_URL.AHExercisePoint1} />
            <BoostPointIcon src={IMAGE_URL.boostPointIcon} />
            <PreviewText>{descriptionThCampaign}</PreviewText>
          </DviWrapper>
        </ImgWrapper>
      </Container>
    </Modal>
  )
}

export default CampaignModal
