import { gql, TypedDocumentNode } from '@apollo/client'
import { CreateBackofficeTeamDataInterface, CreateBackofficeTeamInputInterface } from './interfaces'

export const createBackofficeTeam: TypedDocumentNode<
  CreateBackofficeTeamDataInterface,
  CreateBackofficeTeamInputInterface
> = gql`
  mutation BackofficeCreateBackofficeTeam($input: BackofficeCreateBackofficeTeamInput!) {
    backofficeCreateBackofficeTeam(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`

export default createBackofficeTeam
