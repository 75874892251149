import { ThemeConfig } from 'antd/es/config-provider/context'

// Ref: //https://ant.design/theme-editor https://ant.design/docs/react/customize-theme

const AntdTheme: ThemeConfig = {
  token: {
    colorPrimaryBg: '#c1d6c6',
    colorPrimaryBgHover: '#81c997',
    colorPrimaryBorder: '#5bbd7b',
    colorPrimaryBorderHover: '#38b064',
    colorPrimaryHover: '#1aa351',
    colorPrimary: '#009640',
    colorPrimaryActive: '#007034',
    colorPrimaryTextHover: '#1aa351',
    colorPrimaryText: '#009640',
    colorPrimaryTextActive: '#007034',
    fontSize: 16,
    fontFamily: 'Prompt, Sarabun',
  },
  components: {
    Radio: {
      colorPrimary: '#009640',
    },
    Menu: {
      colorPrimary: '#009640',
    },
    Button: {
      fontFamily: 'Prompt',
      fontSize: 14,
      controlHeight: 40,
    },
    Form: {
      size: 14,
      fontFamily: 'Prompt',
    },
    Modal: {
      borderRadiusLG: 12,
      fontFamily: 'Prompt',
      fontSize: 14,
      fontSizeHeading5: 18,
      paddingContentHorizontalLG: 24,
    },
    Input: {
      controlHeight: 40,
      paddingSM: 16,
    },
    DatePicker: {
      paddingSM: 16,
      controlHeight: 40,
    },
    Badge: {
      colorPrimary: '#276ef1',
      colorSuccess: '#009640',
      colorWarning: '#ffa100',
    },
  },
}

export default AntdTheme
