import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetUserHelpPaginationDataInterface,
  BackofficeGetUserHelpPaginationInputInterface,
} from './interfaces'

const backofficeGetUserHelpPagination: TypedDocumentNode<
  BackofficeGetUserHelpPaginationDataInterface,
  BackofficeGetUserHelpPaginationInputInterface
> = gql`
  query BackofficeGetUserHelpPagination($input: RequestPaginationInput!) {
    backofficeGetUserHelpPagination(input: $input) {
      data {
        id
        createdAt
        type
        phone
        remark
        user {
          firstname
          lastname
        }
        status
        receivedBy {
          firstname
          lastname
        }
        receivedAt
        successfulBy {
          firstname
          lastname
        }
        successfulAt
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
        sortBy
        searchBy
        search
      }
    }
  }
`

export default backofficeGetUserHelpPagination
