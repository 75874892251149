export enum BillingFilterEnum {
  ALL = '',
  PENDING = 'pending',
  PAID = 'paid',
}

export enum BillingTabEnum {
  SUMMARY = 'summary',
  MANUAL_DISCOUNT = 'manual-discount',
}
