import { List, Typography, Skeleton as AntdSkeleton } from 'antd'
import styled from '@emotion/styled'

export const TitleContainer = styled('div')`
  display: flex;
`

export const ChargeTitle = styled('div')``

export const ChargeNumber = styled('div')`
  color: rgba(173, 173, 173, 1);
  font-weight: 500;
  font-size: 16px;
`

export const Skeleton = styled(AntdSkeleton)`
  margin-top: 32px;
`

export const ListAntd = styled(List)`
  margin-top: 32px;
  overflow: auto;
  height: 350px;
`

export const ListItemAntd = styled(List.Item)``

export const ListItemTextAntd = styled(Typography.Text)`
  color: rgba(102, 102, 102, 1);
`

export const LocationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  height: 100%;
`

export const LocaitonTitle = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
`

export const LocationIcon = styled('img')`
  margin: 10px;
  height: 48px;
  width: 48px;
`
