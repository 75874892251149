import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeCountPendingActiveHealthBillingDiscountProblemRecordDataInterface } from './interfaces'

const backofficeCountPendingActiveHealthBillingDiscountProblemRecord: TypedDocumentNode<BackofficeCountPendingActiveHealthBillingDiscountProblemRecordDataInterface> = gql`
  query BackofficerCountPendingActiveHealthBillingDiscountProblemRecord {
    backofficerCountPendingActiveHealthBillingDiscountProblemRecord
  }
`

export default backofficeCountPendingActiveHealthBillingDiscountProblemRecord
