import { UserSession } from '@/gql/activeHealthPolicy/backofficeListUserSession/types'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'

export default function MobileDeviceTableHook() {
  const columns: ColumnsType<UserSession | {}> = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      align: 'center',
      render: (_, __, index) => {
        return <div>{index + 1}</div>
      },
    },
    {
      title: 'ยี่ห้อ - รุ่น',
      dataIndex: 'deviceVendor',
      key: 'deviceVendor',
    },
    {
      title: 'ระบบปฏิบัติการ',
      dataIndex: 'osName',
      key: 'osName',
    },
    {
      title: 'เวอร์ชั่นแอป',
      dataIndex: 'osVersion',
      key: 'osVersion',
    },
    {
      title: 'เข้าใช้งานล่าสุดเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (data) => {
        return <div>{dayjs(data).format('DD-MM-YYYY HH:mm:ss')}</div>
      },
    },
  ]

  return {
    columns,
  }
}
