import { Container, Title, WatchTitle } from './styles'
import { toThaiDayName, toThaiLocaleDateString } from '@/libs/date'
import { RecentActivityInformationHeaderProp } from './types'

function RecentActivityInformationHeader({ exerciseHistory }: RecentActivityInformationHeaderProp) {
  return (
    <Container>
      <Title>{toThaiDayName(exerciseHistory?.date)}</Title>
      <Title>{toThaiLocaleDateString({ date: exerciseHistory?.date, isFullMonth: true })}</Title>
      <WatchTitle>{exerciseHistory?.watchBrand?.name || '-'}</WatchTitle>
    </Container>
  )
}

export default RecentActivityInformationHeader
