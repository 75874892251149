import { ModalProps as AntdModalProps } from 'antd'
import { Theme } from 'antd/lib/config-provider/context'
import { CSSObject } from '@emotion/styled/dist/emotion-styled.cjs'
import { ModalAntd } from './styles'

export interface ModalProps extends AntdModalProps {
  theme?: Theme
  css?: CSSObject
}

function Modal(props: ModalProps) {
  return <ModalAntd {...props} />
}

export default Modal
