import React, { useMemo, useRef, useState } from 'react'
import { Space, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import { useLazyQuery } from '@apollo/client'
import backofficeTeam from '@/gql/backofficeTeam'
import { AutoCompleteSearchUserProps, UserValue } from './types'
import { AntdDebounceSelect, SelectStyled } from './styles'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  debounceTimeout?: number
}

export function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Space direction="vertical">
      <SelectStyled
        labelInValue
        filterOption={false}
        maxTagCount="responsive"
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
        placeholder="ค้นหาชื่อผู้ใช้งาน"
        allowClear
      />
    </Space>
  )
}

const AutoCompleteSearchUser = ({ userValue, onChangeUser }: AutoCompleteSearchUserProps) => {
  const [getBackofficeSearchBackOfficeUser, { loading: loadingGetbackofficeSearchBackOfficeUser }] =
    useLazyQuery(backofficeTeam.query.backofficeSearchBackOfficeUser, {
      variables: {
        input: {
          q: '',
          columns: ['firstname', 'lastname'],
        },
      },
      fetchPolicy: 'no-cache',
    })

  async function fetchUserList(word: string): Promise<UserValue[]> {
    const { data } = await getBackofficeSearchBackOfficeUser({
      variables: {
        input: {
          q: word.toLocaleLowerCase(),
          columns: ['firstname', 'lastname'],
        },
      },
      fetchPolicy: 'no-cache',
    })

    if (data && data.backofficeSearchBackOfficeUser) {
      const result = data.backofficeSearchBackOfficeUser.map((user) => ({
        label: `${user.firstname} ${user.lastname}`,
        value: user.id,
        title: `${user.firstname} ${user.lastname}`,
      }))

      return result
    }

    return [] // Return an empty array if there's no data or an error occurred
  }

  return (
    <AntdDebounceSelect
      loading={loadingGetbackofficeSearchBackOfficeUser}
      mode="multiple"
      value={userValue}
      placeholder="Select users"
      fetchOptions={fetchUserList}
      onChange={onChangeUser}
    />
  )
}

export default AutoCompleteSearchUser
