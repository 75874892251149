import { useEffect } from 'react'

import TaTransactionResendPolicyModalHook from './TaTransactionResendPolicyModal.hook'
import { EmailInput, FormAntd, FormItem } from './styles'
import Modal from '@/components/common/Modal'
import message from '@/libs/message'

const TaTransactionResendPolicyModal = ({
  policyMail,
  applicationFormId,
  open,
  onCancel,
}: {
  policyMail: string
  applicationFormId: string
  open: boolean
  onCancel: () => void
}) => {
  const { form, validateEmail, useResendEmail, handleEmailChange } =
    TaTransactionResendPolicyModalHook({
      policyMail,
      applicationFormId,
    })

  const mutation = useResendEmail()

  useEffect(() => {
    if (mutation.isSuccess) {
      message.success('ส่งข้อมูลสำเร็จ')
      onCancel()
    }

    if (mutation.isError) {
      message.error('ส่งข้อมูลไม่สำเร็จ')
    }
  }, [mutation.isSuccess, mutation.isError])

  return (
    <Modal
      forceRender
      title="ส่งอีเมล"
      centered
      closable={false}
      maskClosable={false}
      open={open}
      onCancel={onCancel}
      onOk={() => mutation.mutate()}
    >
      <FormAntd layout="vertical" form={form}>
        <FormItem name="email" label="อีเมล" rules={[{ validator: validateEmail }]}>
          <EmailInput id="ta-transaction-resend-policy-modal-mail" onChange={handleEmailChange} />
        </FormItem>
      </FormAntd>
    </Modal>
  )
}

export default TaTransactionResendPolicyModal
