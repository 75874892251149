import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeUpdateActiveHealthBillingDiscountProblemStatusDataInterface,
  BackofficeUpdateActiveHealthBillingDiscountProblemStastusInputInterface,
} from './interfaces'

const backofficeUpdateActiveHealthBillingDiscountProblemStatus: TypedDocumentNode<
  BackofficeUpdateActiveHealthBillingDiscountProblemStatusDataInterface,
  BackofficeUpdateActiveHealthBillingDiscountProblemStastusInputInterface
> = gql`
  mutation BackofficeUpdateActiveHealthBillingDiscountProblemStatus(
    $input: UpdateActiveHealthBillingDiscountProblem!
  ) {
    backofficeUpdateActiveHealthBillingDiscountProblemStatus(input: $input) {
      id
      status
      resolvedAt
      resolvedBy {
        firstname
        lastname
      }
    }
  }
`

export default backofficeUpdateActiveHealthBillingDiscountProblemStatus
