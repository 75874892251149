import styled from '@emotion/styled'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

export const Container = styled('div')`
  display: flex;
`

export const FilterButton = styled('button')<{ select: boolean; start: boolean; end: boolean }>`
  padding: 10px 16px;
  height: 40px;
  background: ${(props) => (props.select ? '#f6f6f6' : '#ffffff')};
  border: 1px solid #e5e5e5;
  border-radius: ${(props) => (props.start ? '8px 0 0 8px' : props.end ? '0 8px 8px 0' : 'unset')};
  cursor: pointer;
`

export const Block = styled('div')`
  margin-left: 10px;
`

export const LeftArrow = styled(LeftOutlined)`
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 12px;
`

export const RightArrow = styled(RightOutlined)`
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 12px;
  margin-left: 8px;
`
