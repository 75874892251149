import styled from '@emotion/styled'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'

export const Container = styled('div')`
  margin: 24px auto;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 32px;
  width: 100%;
  position: relative;
  user-select: none;
`

export const SearchInput = styled(Input)`
  width: 239px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 24px;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`

export const LastSyncText = styled('span')`
  font-size: 14px;
  color: #666666;
  position: absolute;
  top: 36px;
  right: 32px;
`

export const EyeIcon = styled('img')`
  cursor: pointer;
  font-size: 20px;
  color: #666666;
`
