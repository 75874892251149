import styled from '@emotion/styled'

export const Container = styled('div')`
  width: 100%;
  padding: 40px;
  overflow: hidden;
`

export const Title = styled('p')`
  font-weight: 400;
  font-size: 24px;
`
