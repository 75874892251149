import {
  CardWrapper,
  HeaderWrapper,
  MenuWrapper,
  PageWrapper,
  PermissionWrapper,
  SubTitle,
  Switch,
  SwitchPermissionWrapper,
  Title,
} from './styles'
import { CardMemberPermissionProps } from './types'

const CardMemberPermission = ({
  onChangeHeaderSwitch,
  onChangePageSwitch,
  onChangePermissionSwitch,
  menu,
  menuPermission,
}: CardMemberPermissionProps) => {
  return (
    <CardWrapper>
      <HeaderWrapper>
        <Title>{menu.name}</Title>
        <Switch
          defaultChecked
          onChange={() => onChangeHeaderSwitch(menu)}
          checked={menu.page.every((page) =>
            page.permission.every((permission) => menuPermission.has(permission.code)),
          )}
          data-testid={`user-role-manage-tab-permission-menu-${menu.code}`}
        />
      </HeaderWrapper>
      {menu.page
        .sort((a, b) => a.order - b.order)
        .map((page) => {
          return (
            <MenuWrapper key={page.id}>
              <PageWrapper>
                <SubTitle>{page.name}</SubTitle>
                <Switch
                  defaultChecked
                  onChange={() => onChangePageSwitch(page)}
                  checked={page.permission.every((permission) =>
                    menuPermission.has(permission.code),
                  )}
                  data-testid={`user-role-manage-tab-permission-page-${page.code}`}
                />
              </PageWrapper>
              <PermissionWrapper>
                {page.permission
                  .sort((a, b) => a.order - b.order)
                  .map((permission) => {
                    return (
                      <SwitchPermissionWrapper key={permission.id}>
                        <SubTitle>{permission.name}</SubTitle>
                        <Switch
                          onChange={() => onChangePermissionSwitch(permission)}
                          checked={menuPermission.has(permission.code)}
                          data-testid={`user-role-manage-tab-permission-${permission.code}`}
                        />
                      </SwitchPermissionWrapper>
                    )
                  })}
              </PermissionWrapper>
            </MenuWrapper>
          )
        })}
    </CardWrapper>
  )
}

export default CardMemberPermission
