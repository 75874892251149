import { gql, TypedDocumentNode } from '@apollo/client'
import { CreateBackofficeUserDataInterface, CreateBackofficeUserInputInterface } from './interfaces'

export const createBackofficeUser: TypedDocumentNode<
  CreateBackofficeUserDataInterface,
  CreateBackofficeUserInputInterface
> = gql`
  mutation createBackofficeUser($input: CreateBackofficeUserInput!) {
    createBackofficeUser(input: $input) {
      code
      message
      data
    }
  }
`

export default createBackofficeUser
