import { gql, TypedDocumentNode } from '@apollo/client'

export const backofficeDeleteActiveHealthSpecialPoint: TypedDocumentNode<{}, { id: string }> = gql`
  mutation BackofficeDeleteActiveHealthSpecialPoint($id: ID!) {
    backofficeDeleteActiveHealthSpecialPoint(id: $id) {
      code
      message
      data
    }
  }
`

export default backofficeDeleteActiveHealthSpecialPoint
