export enum ModeUserEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

export type DataUserType = {
  userId: string
  roleId: string
  roleName: string
  teamId: string
  teamName: string
  name: string
  lastName: string
  phone: string
  email: string
}

export type UserModalProps = {
  title?: string
  dataUser?: DataUserType
  isVisible: boolean
  onOkFunction: Function
  onCancelFunction?: Function
  okText?: string
  okButtonProps?: {
    type: 'default' | 'link' | 'text' | 'ghost' | 'primary' | 'dashed'
    danger: boolean
  }
  mode?: ModeUserEnum
}
