import BillingTableHook from './BillingTable.hook'
import Table from '@/components/common/Table'
import { SelectedRow, RadioGroup, TotalRecordText } from './styles'
import { BillingFilterEnum } from '@/constant/BILLING_FILTER'

function BillingTable() {
  const {
    billings,
    columns,
    selectedRowKeys,
    activeHealthBillingLoading,
    radioOptions,
    activeHealthBillingData,
    getActiveHealthBilling,
  } = BillingTableHook()

  return (
    <>
      <RadioGroup
        options={radioOptions}
        optionType="button"
        buttonStyle="solid"
        size="large"
        defaultValue={BillingFilterEnum.ALL}
        onChange={({ target: { value } }) => getActiveHealthBilling(value as BillingFilterEnum)}
        data-testid="policy-detail-billing-tab-summary-radio-group"
      />{' '}
      <TotalRecordText>
        {`ทั้งหมด ${
          activeHealthBillingData?.backofficeListActiveHealthBillingByUserId?.data?.length || 0
        } รายการ`}
      </TotalRecordText>
      <Table
        loading={activeHealthBillingLoading}
        rowClassName={(_value, index) => (index === selectedRowKeys[0] ? SelectedRow : '')}
        columns={columns.filter((column) => column.width !== 0)}
        scroll={{
          x: '180vw',
        }}
        pagination={false}
        dataSource={billings}
        data-testid="policy-detail-billing-tab-summary-table"
      />
    </>
  )
}

export default BillingTable
