import backofficeFAQCategoriesByModuleName from './backofficeFAQCategoriesByModuleName/backofficeFAQCategoriesByModuleName.gql'
import createBackofficeFAQCategory from './createBackofficeFAQCategory/createBackofficeFAQCategory.gql'
import removeBackofficeFAQCategory from './removeBackofficeFAQCategory/removeBackofficeFAQCategory.gql'
import updateBackofficeFAQCategory from './updateBackofficeFAQCategory/updateBackofficeFAQCategory.gql'
import updateOrderBackofficeFAQCategory from './updateOrderBackofficeFAQCategory/updateOrderBackofficeFAQCategory.gql'

const backofficeFAQCategory = {
  query: {
    backofficeFAQCategoriesByModuleName,
  },
  mutation: {
    createBackofficeFAQCategory,
    updateBackofficeFAQCategory,
    removeBackofficeFAQCategory,
    updateOrderBackofficeFAQCategory,
  },
}

export default backofficeFAQCategory
