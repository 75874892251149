import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthPartnerBranchByIdDataInterface,
  BackofficeGetActiveHealthPartnerBranchByIdInputInterface,
} from './interface'

const backofficeGetActiveHealthPartnerBranchById: TypedDocumentNode<
  BackofficeGetActiveHealthPartnerBranchByIdDataInterface,
  BackofficeGetActiveHealthPartnerBranchByIdInputInterface
> = gql`
  query BackofficeGetActiveHealthPartnerBranchById(
    $input: BackofficeGetActiveHealthPartnerBranchByIdInput!
  ) {
    backofficeGetActiveHealthPartnerBranchById(input: $input) {
      id
      displayNameTh
      displayNameEn
      activeHealthPartner {
        id
        displayNameTh
        displayNameEn
        logoImageUrl
        isActive
      }
    }
  }
`

export default backofficeGetActiveHealthPartnerBranchById
