import ActivityInformationBarChart from '@/components/feature/PolicyDetailPage/ActivityInformationBarChart'
import ActivityInformation from '@/components/feature/PolicyDetailPage/ActivityInformation'
import ActivityPeriodDateFilter from '@/components/feature/PolicyDetailPage/ActivityPeriodDateFilter'
import ActivityBonusNotification from '@/components/feature/PolicyDetailPage/ActivityBonusNotification'
import ActivityFilter from '@/components/feature/PolicyDetailPage/ActivityFilter'
import RecentActivityTable from '@/components/feature/PolicyDetailPage/RecentActivityTable/RecentActivityTable'
import EditActivityDurationModal from '@/components/feature/PolicyDetailPage/EditActivityDurationModal'
import EditActiveBonusModal from '@/components/feature/PolicyDetailPage/EditActiveBonusModal'
import {
  Body,
  Card,
  Container,
  Footer,
  Header,
  LeftSubContainer,
  RightSubContainer,
  Skeleton,
  SkeletonButton,
  SkeletonContainer,
} from './styles'
import ActivityTabHook from './ActivityTab.hook'

function ActivityTab() {
  const {
    exerciseBarChart,
    startDateStat,
    endDateStat,
    totalStep,
    totalMinute,
    totalPoint,
    totalBonusPoint,
    specialHighlights,
    lastSync,
    handleNextRangeDateChart,
    handlePrevRangeDateChart,
    handleSelectedActivityFilter,
    handlePeriodChange,
    handleRefetchBackofficeGetUserExerciseStat,
    isComponentLoading,
  } = ActivityTabHook()

  return (
    <>
      <Container>
        <Card>
          <LeftSubContainer>
            <Body>
              {isComponentLoading ? (
                <SkeletonContainer>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </SkeletonContainer>
              ) : (
                <ActivityInformation
                  startDate={startDateStat}
                  endDate={endDateStat}
                  lastUpdate={lastSync}
                  totalStep={totalStep}
                  totalMinuteTime={totalMinute}
                  totalPoint={totalPoint}
                  totalBonusPoint={totalBonusPoint}
                />
              )}
            </Body>
          </LeftSubContainer>
          <RightSubContainer>
            <Header>
              {isComponentLoading ? (
                <SkeletonButton size="large" active />
              ) : (
                <ActivityBonusNotification specialHighlights={specialHighlights} />
              )}
              <ActivityPeriodDateFilter
                handleNextRangeDateChart={handleNextRangeDateChart}
                handlePrevRangeDateChart={handlePrevRangeDateChart}
                handlePeriodChange={handlePeriodChange}
              />
            </Header>
            <Body>
              <ActivityInformationBarChart stats={exerciseBarChart} />
            </Body>
            <Footer>
              <ActivityFilter handleSelectedActivityFilter={handleSelectedActivityFilter} />
            </Footer>
          </RightSubContainer>
        </Card>
        <Card displayBlock={true}>
          <RecentActivityTable />
        </Card>
      </Container>
      <EditActivityDurationModal
        handleRefetchBackofficeGetUserExerciseStat={handleRefetchBackofficeGetUserExerciseStat}
      />
      <EditActiveBonusModal />
    </>
  )
}

export default ActivityTab
