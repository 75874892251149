import styled from '@emotion/styled'
import { Checkbox as AntdCheckbox } from 'antd'
import { CLASS_CATEGORY_MOVE } from './types'

export const ContainerWrapper = styled('div')<{ isMoveCategory: boolean; selected: boolean }>`
  display: grid;
  padding: 16px 40px 16px 16px;
  border-bottom: 1px solid #cccccc;
  align-items: center;
  background-color: ${(props) => (props.selected ? '#e6f6ea' : '#ffffff')};
  grid-template-columns: ${(props) => (props.isMoveCategory ? 'auto 1fr' : 'auto')};

  .${CLASS_CATEGORY_MOVE} {
    display: none;
  }

  :hover {
    background-color: #f6f6f6;
    grid-template-columns: ${(props) => (props.isMoveCategory ? 'auto 1fr' : 'auto 1fr')};
    padding: ${(props) => (props.isMoveCategory ? '16px 40px 16px 16px' : '16px 40px 16px 0px')};
    .${CLASS_CATEGORY_MOVE} {
      display: block;
    }
  }
`

export const Checkbox = styled(AntdCheckbox)`
  margin-right: 12px;
`

export const CategoryMoveButton = styled('div')`
  cursor: pointer;
  margin-right: 12px;
`

export const DownArrow = styled('img')``

export const UpArrow = styled('img')``

export const BlockWrapper = styled('div')`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Title = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`

export const Description = styled('div')`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
`

export const DescriptionWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  height: 32px;
`

export const SvgIcon = styled('img')`
  position: absolute;
  top: 48%;
  right: 0;
  height: 12px;
  width: 12px;
`

export const CategoryMoveWrapper = styled('div')`
  display: grid;
  grid-gap: 8px;
  /* margin: 0px 12px; */
  margin: 0px 0px 0px 16px;
`
