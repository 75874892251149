import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetCreateActiveHealthManualDiscountDataInterface,
  BackofficeGetCreateActiveHealthManualDiscountInputInterface,
} from '@/gql/activeHealthPolicy/backofficeCreateActiveHealthManualDiscount/interfaces'

const backofficeCreateActiveHealthManualDiscount: TypedDocumentNode<
  BackofficeGetCreateActiveHealthManualDiscountDataInterface,
  BackofficeGetCreateActiveHealthManualDiscountInputInterface
> = gql`
  mutation BackofficeCreateActiveHealthManualDiscount(
    $input: BackofficeCreateActiveHealthManualDiscountInput!
  ) {
    backofficeCreateActiveHealthManualDiscount(input: $input) {
      id
      policyNumber
      discount
      approveStatus
      createdAt
    }
  }
`

export default backofficeCreateActiveHealthManualDiscount
