import { Container, Title, Subtitle, SuccessIcon, FailIcon, TitleContainer } from './styles'
import ResultImportCardHook from './ResultImportCard.hook'
import { DeviceImportProps } from '@/recoil/deviceImport/types'

function ResultImportCard() {
  const { deviceImport } = ResultImportCardHook()
  const { successful, failed } = deviceImport as DeviceImportProps

  return (
    <Container result={failed ? 'failed' : 'success'}>
      <TitleContainer>
        {failed ? <FailIcon /> : <SuccessIcon />}
        <Title>ผลการ Import</Title>
      </TitleContainer>
      <Subtitle>สำเร็จ: {successful}</Subtitle>
      <Subtitle>ไม่สำเร็จ: {failed}</Subtitle>
    </Container>
  )
}

export default ResultImportCard
