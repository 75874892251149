import { ReactElement } from 'react'
import { MsalProvider as Provider } from '@azure/msal-react'
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser'
import config from '@/config'

interface ProviderInterface {
  children: ReactElement
}

export const msalInstance = new PublicClientApplication(config.msalConfig)

export const MsalProvider = ({ children }: ProviderInterface) => {
  // Extracted function to set the active account
  const setActiveAccount = (msalInstance: PublicClientApplication, accounts: AccountInfo[]) => {
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0])
    }
  }

  // Extracted event callback function
  const eventCallback = (msalInstance: PublicClientApplication) => (event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const { account } = payload

      msalInstance.setActiveAccount(account)
    }
  }

  const accounts = msalInstance.getAllAccounts()
  setActiveAccount(msalInstance, accounts)
  msalInstance.addEventCallback(eventCallback(msalInstance))

  return <Provider instance={msalInstance}>{children}</Provider>
}
