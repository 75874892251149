import { atom } from 'recoil'
import { SelectedTeamPermissionType } from './types'

const initSelectedTeamPermission: SelectedTeamPermissionType = {
  id: undefined,
  name: undefined,
  createdAt: undefined,
  updatedAt: undefined,
}

const selectedTeamPermissionAtom = atom({
  key: 'selectedTeamPermissionAtom',
  default: initSelectedTeamPermission,
})

export default selectedTeamPermissionAtom
