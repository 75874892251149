import styled from '@emotion/styled'
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons'
import { FontColorProp } from './types'
import { Tag } from 'antd';

export const Container = styled('div')<{ marginBottomUnset?: boolean }>`
  margin-bottom: ${(props) => (props.marginBottomUnset ? 'unset' : '32px')};
  padding-right: 32px;
`

export const Block = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InformationTitle = styled('div')`
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  margin-bottom: 4px;
`

export const Information = styled('div')<{ fontColor?: FontColorProp }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.fontColor || '#000000'};
`

export const MoreInfoIcon = styled(InfoCircleFilled)`
  color: #c4c4c4;
`

export const MoreInfoOutlineIcon = styled(InfoCircleOutlined)`
  color: #adadad;
  width: 13px;
  height: 13px;
`

export const EvBadge = styled(Tag)`
  margin-left: 4px
`
