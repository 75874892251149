import React from 'react'
import _ from 'lodash'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import { TableProps as AntdTableProps } from './interfaces'
import { AntdTable, TotalRecordContainer, TotalRecord } from './styles'

interface TableProps extends AntdTableProps {
  totalRecord?: number
}

function Table(props: TableProps) {
  const pagination: false | TablePaginationConfig = _.isBoolean(props.pagination)
    ? props.pagination
    : {
        ...props.pagination,
        locale: { items_per_page: '' },
      }

  return (
    <>
      <AntdTable
        {...props}
        pagination={pagination}
        locale={{ filterReset: 'ล้าง', filterConfirm: 'ตกลง' }}
      />
      {!props.loading && props.totalRecord ? (
        <TotalRecordContainer>
          <TotalRecord>ทั้งหมด {props.totalRecord.toLocaleString()} รายการ</TotalRecord>
        </TotalRecordContainer>
      ) : undefined}
    </>
  )
}

export default Table
