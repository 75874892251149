import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { useLazyQuery, useMutation } from '@apollo/client'
import message from '@/libs/message'
import deviceDetailAtom from '@/recoil/deviceDetail'
import tviConnectMainSimPackageAtom from '@/recoil/tviConnectMainSimPackage'
import { ppuMotorGql } from '@/gql'
import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import { MainPackageNameNbEnum } from '@/constant/MAIN_PACKAGE_NAME'
import { TviConnectSimMainPackageInterface } from '@/gql/ppuMotor/backofficeGetTviConnectCurrentSimMainPackage/interfaces'
import userAtom from '@/recoil/user'
import {
  SimStatusEnum,
  TrueJasperSimStatusEnum,
  TviConnectPartnerEnum,
} from '@/constant/SIM_STATUS'

function TviConnectCardHook() {
  const [visible, setVisible] = useState<boolean>(false)
  const [isOpenModalSimStatusInfo, setIsOpenModalSimStatusInfo] = useState<boolean>(false)
  const { setConfirmModal } = ConfirmModalHook()
  const user = useRecoilValue(userAtom)
  const [deviceDetail, setDeviceDetail] = useRecoilState(deviceDetailAtom)
  const resetDeviceDetail = useResetRecoilState(deviceDetailAtom)
  const [tviConnectMainPackage, setTviConnectMainPackage] =
    useRecoilState<TviConnectSimMainPackageInterface>(tviConnectMainSimPackageAtom)
  const [
    unboundPpuMotorUserPolicyMotorMutation,
    { loading: unboundPpuMotorUserPolicyMotorLoading },
  ] = useMutation(ppuMotorGql.mutation.backofficeUnboundPpuMotorUserPolicyMotor, {
    fetchPolicy: 'no-cache',
    onError: () => {
      message.error({
        content: 'ยกเลิกการผูกอุปกรณ์ไม่สำเร็จ',
      })
    },
  })
  const [resetDeviceIdMutation, { loading: resetDeviceIdLoading }] = useMutation(
    ppuMotorGql.mutation.backofficeResetPpuMotorUserPolicyMotorDeviceId,
    {
      fetchPolicy: 'no-cache',
      onError: () => {
        message.error({
          content: 'รีเซ็ตอุปกรณ์ไม่สำเร็จ',
        })
      },
    },
  )
  const [
    getTviConnectMainPackageQuery,
    { data: tviConnectMainPackageData, loading: tviConnectMainPackageLoading },
  ] = useLazyQuery(ppuMotorGql.query.backofficeGetTviConnectCurrentSimMainPackage, {
    fetchPolicy: 'no-cache',
    onError: () => {
      message.error({
        content: 'เรียกดูข้อมูล SIM ไม่สำเร็จ กรุณาติดต่อผู้ดูแล',
      })
    },
  })
  const [changeTviConnectMainPackageMutation, { loading: changeTviConnectMainPackageLoading }] =
    useMutation(ppuMotorGql.mutation.backofficeChangeTviConnectSimMainPackage, {
      fetchPolicy: 'no-cache',
      onError: () => {
        message.error({
          content: 'เปิดใช้งาน SIM ไม่สำเร็จ',
        })
      },
    })

  const handleDisplaySimStatusModal = (isOpen: boolean) => setIsOpenModalSimStatusInfo(isOpen)

  useEffect(() => {
    if (deviceDetail.id && deviceDetail.imei) {
      getTviConnectMainPackageQuery({
        variables: {
          id: deviceDetail.id,
        },
      })
    }
  }, [deviceDetail.id, deviceDetail.imei])

  useEffect(() => {
    if (
      (deviceDetail.vendor === TviConnectPartnerEnum.AIS && deviceDetail.deviceId) ||
      (deviceDetail.vendor === TviConnectPartnerEnum.TRUE && deviceDetail.serialNumber)
    ) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [deviceDetail])

  useEffect(() => {
    if (tviConnectMainPackageData) {
      setTviConnectMainPackage(
        tviConnectMainPackageData.backofficeGetTviConnectCurrentSimMainPackage,
      )
    }
  }, [tviConnectMainPackageData])

  const onModalVisible = () => {
    setConfirmModal({
      isVisible: true,
      onOkFunction: onUnboundPpuMotorUserPolicyMotor,
      title: 'คุณต้องการยกเลิกการผูกอุปกรณ์?',
      dataTestId: 'unbound-device-modal',
    })
  }

  const onModalResetDeviceIdVisible = () => {
    setConfirmModal({
      isVisible: true,
      onOkFunction: onResetDeviceId,
      title: 'คุณต้องการรีเซ็ตอุปกรณ์?',
      dataTestId: 'reset-device-modal',
    })
  }

  const onModalChangeTviConnectMainPackageVisible = () => {
    setConfirmModal({
      isVisible: true,
      onOkFunction: onChangeMainPackage,
      title: 'คุณต้องการเปิดใช้งาน SIM?',
      dataTestId: 'change-tvi-connect-main-package-modal',
    })
  }

  const onUnboundPpuMotorUserPolicyMotor = async () => {
    const result = await unboundPpuMotorUserPolicyMotorMutation({
      variables: {
        input: {
          id: deviceDetail.id,
        },
      },
    })

    const { id, chassisNumber, deviceId } = result.data.backofficeUnboundPpuMotorUserPolicyMotor

    if (id && !chassisNumber && !deviceId) {
      resetDeviceDetail()

      setConfirmModal({
        isVisible: false,
        onOkFunction: Function,
        dataTestId: undefined,
      })

      message.success({ content: 'ยกเลิกการผูกอุปกรณ์สำเร็จ' })
    }
  }

  const onResetDeviceId = async () => {
    if (deviceDetail.imei && deviceDetail.id) {
      const { data } = await resetDeviceIdMutation({
        variables: {
          input: {
            imei: deviceDetail.imei,
            id: deviceDetail.id,
          },
        },
      })

      if (data && data.backofficeResetPpuMotorUserPolicyMotorDeviceId) {
        const { deviceId } = data.backofficeResetPpuMotorUserPolicyMotorDeviceId

        if (deviceId) {
          setDeviceDetail({
            ...deviceDetail,
            deviceId,
          })

          setConfirmModal({
            isVisible: false,
            onOkFunction: Function,
            dataTestId: undefined,
          })
        } else {
          message.error({
            content:
              'ข้อมูลอุปกรณ์ไม่ถูกต้อง กรุณาตรวจสอบว่าอุปกรณ์ดังกล่าวถูกต้องหรือไม่ หรือมีการผูกกับกรมธรรม์อื่นไปแล้วหรือไม่ หรือติดต่อเจ้าหน้าที่ที่ดูแล',
          })
        }
      }
    }
  }

  const onChangeMainPackage = async () => {
    if (deviceDetail.id) {
      const { data } = await changeTviConnectMainPackageMutation({
        variables: {
          id: deviceDetail.id,
        },
      })

      if (data && data.backofficeChangeTviConnectSimMainPackage) {
        const result = data.backofficeChangeTviConnectSimMainPackage

        if (result.isSuccessful) {
          message.success({
            content:
              'ส่งคำร้องขอเปิดใช้งาน SIM สำเร็จ (กรุณารอประมาณ 3 นาที เพื่อรอกระบวนการเปิดใช้งาน SIM สำเร็จ)',
            duration: 10,
          })

          setConfirmModal({
            isVisible: false,
            onOkFunction: Function,
            dataTestId: undefined,
          })
        } else {
          message.error({
            content: `ปัญหา: ${result.message} เหตุผล: ${result.reason}`,
            duration: 10,
          })
        }
      }
    }
  }

  const displaySimStatus = (tviConnectMainPackage: TviConnectSimMainPackageInterface) => {
    // (hack process) The current status response from the AIS partner is inadequate to sufficiently convey the suspension of the SIM card.
    if (tviConnectMainPackage.packageName === MainPackageNameNbEnum.SUSPEND) {
      return 'SIM ถูกระงับการใช้งาน'
    }

    // normally process.
    switch (tviConnectMainPackage.status) {
      case SimStatusEnum.SIM_READY:
      case TrueJasperSimStatusEnum.ACTIVATION_READY:
      case TrueJasperSimStatusEnum.TEST_READY:
      case TrueJasperSimStatusEnum.INVENTORY:
        return 'ยังไม่ถูกลงทะเบียน'
      case SimStatusEnum.COMMERCIAL:
      case TrueJasperSimStatusEnum.ACTIVATED:
      case SimStatusEnum.TEST:
        return 'SIM เปิดใช้งานแล้ว'
      case SimStatusEnum.SUSPEND:
      case TrueJasperSimStatusEnum.DEACTIVATED:
        return 'SIM ถูกระงับการใช้งาน'
      case TrueJasperSimStatusEnum.RETIRED:
        return 'SIM ถูกยกเลิกการใช้งาน'
      case TrueJasperSimStatusEnum.PURGED:
      case TrueJasperSimStatusEnum.REPLACED:
        return tviConnectMainPackage.status
      default:
        return 'ไม่พบ SIM'
    }
  }

  return {
    deviceDetail,
    visible,
    onModalVisible,
    onModalResetDeviceIdVisible,
    tviConnectMainPackage,
    onModalChangeTviConnectMainPackageVisible,
    user,
    unboundPpuMotorUserPolicyMotorLoading,
    resetDeviceIdLoading,
    changeTviConnectMainPackageLoading,
    tviConnectMainPackageLoading,
    displaySimStatus,
    handleDisplaySimStatusModal,
    isOpenModalSimStatusInfo,
  }
}

export default TviConnectCardHook
