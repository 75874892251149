import { useRecoilState } from 'recoil'
import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { ppuMotorGql } from '@/gql'
import policyAtom from '@/recoil/policy'

function InsuredCardHook() {
  const [isMotorOnOffTopUp, setIsMotorOnOffTopUp] = useState<boolean>(false)
  const [isAutoTopUp, setIsAutoTopUp] = useState<boolean>(false)
  const [
    backofficeGetPpuMotorAutoTopUpByPolicyNumberQuery,
    { data: autoTopUpData, error: autoTopUpError },
  ] = useLazyQuery<{ policyNumber: string; recurringType: string }>(
    ppuMotorGql.query.backofficeGetPpuMotorAutoTopUpByPolicyNumber,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const [policy] = useRecoilState(policyAtom)

  useEffect(() => {
    if (policy.policyNumber && policy.policyGroup === 'Motor On Off (Top-up)') {
      setIsMotorOnOffTopUp(true)
      backofficeGetPpuMotorAutoTopUpByPolicyNumberQuery({
        variables: {
          policyNumber: policy.policyNumber,
        },
      })
    } else {
      setIsMotorOnOffTopUp(false)
    }
  }, [policy])

  useEffect(() => {
    if (autoTopUpData) {
      setIsAutoTopUp(true)
    }
  }, [autoTopUpData])

  useEffect(() => {
    if (autoTopUpError) {
      setIsAutoTopUp(false)
    }
  }, [autoTopUpError])

  return {
    policy,
    isAutoTopUp,
    isMotorOnOffTopUp,
  }
}

export default InsuredCardHook
