import { UploadProps } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import config from '@/config/config'
import backofficeAdjustDailyExercise from '@/gql/backofficeAdjustDailyExercise'
import editActivityDurationModalAtom from '@/recoil/editActivityDurationModal'
import message from '@/libs/message'
import userAtom from '@/recoil/user/atom'
import { useMutation } from '@apollo/client'
import { faker } from '@faker-js/faker'
import _ from 'lodash'

function EditActivityDurationModalHook() {
  const [editActivityDurationModal, setEditActivityDurationModal] = useRecoilState(
    editActivityDurationModalAtom,
  )
  const [imgActivityDuration, setImgActivityDuration] = useState<string>('')
  const user = useRecoilValue(userAtom)
  const [isFormValidate, setIsFormValidate] = useState<boolean>(false)
  const [fileList, setFileList] = useState<Array<UploadFile>>([])
  const [step, setStep] = useState<string>('')
  const [activityDuration, setActivityDuration] = useState<string>('')

  const [
    updateBackofficeAdjustDailyExerciseDataMutation,
    { loading: loadingBackofficeAdjustDailyExerciseDataMutation },
  ] = useMutation(
    backofficeAdjustDailyExercise.mutation.backofficeAdjustDailyExerciseData,

    {
      fetchPolicy: 'no-cache',
    },
  )

  const handleUploadProps: UploadProps = {
    fileList,
    name: 'file',
    action: `${config.graphqlRestEndpoint}/backoffice-active-health/daily-summary/exercise/remark`,
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
    data: {
      reference: faker.datatype.uuid(),
    },
    beforeUpload: (file) => {
      const isJpgOrPngOrPDF =
        file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
      if (!isJpgOrPngOrPDF) {
        message.error('You can only upload JPG/PNG/PDF file!')
        return false
      }

      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        message.error('File must smaller than 10MB!')
        return false
      }

      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        // Check image dimensions
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onload = () => {
            // Check width and height
            if (img.width <= 500 && img.height <= 500) {
              resolve(true)
            } else {
              message.error('Image dimensions must be within 500x500 pixels!')
              reject()
            }
          }
          img.src = URL.createObjectURL(file)
        })
      } else {
        return true
      }
    },
    onChange(info) {
      let newFileList = [...info.fileList]

      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      newFileList = newFileList.slice(-2)

      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url
        }
        return file
      })

      setFileList(newFileList)

      if (newFileList[0].status === 'done') {
        setImgActivityDuration(newFileList[0].response.url)
        message.success(`${newFileList[0].name} file upload success.`)
      } else if (newFileList[0].status === 'error') {
        message.error(`${newFileList[0].name} file upload failed.`)
      }
    },
  }

  const handleRemoveFileList = () => {
    setFileList([])
  }

  const handleRemoveFileProps = () => {
    setImgActivityDuration('')
    handleRemoveFileList()
  }

  const onCloseModal = ({ isEditSuccess }: { isEditSuccess?: boolean }) => {
    setStep('')
    setActivityDuration('')
    setImgActivityDuration('')
    handleRemoveFileList()
    setEditActivityDurationModal({ open: false, isEditSuccess })
  }

  const onChangeStep = (value: string) => {
    setStep(value)
  }

  const onChangeActivityDuration = (value: string) => {
    setActivityDuration(value)
  }

  const onHandlerDefaultValue = () => {
    if (editActivityDurationModal) {
      setStep(editActivityDurationModal.step ? `${editActivityDurationModal.step}` : '0')
      setActivityDuration(
        editActivityDurationModal.activityDuration
          ? `${editActivityDurationModal.activityDuration}`
          : '0',
      )
    }
  }

  const validateEditActivityDurationModal = (step: string, activityDuration: string) => {
    if (!editActivityDurationModal) {
      return false
    }

    if (editActivityDurationModal.step) {
      if (parseInt(step) < editActivityDurationModal.step) {
        message.error('ระบุ จำนวนก้าว ให้มากกว่าหรือเท่ากับ ข้อมูลเดิม')
        return false
      }
    }
    if (editActivityDurationModal.activityDuration) {
      if (parseInt(activityDuration) < editActivityDurationModal.activityDuration) {
        message.error('ระบุ จำนวนนาที ให้มากกว่าหรือเท่ากับ ข้อมูลเดิม')
        return false
      }
    }

    return true
  }

  const onHandlerSaveActivityDuration = async () => {
    const isValidate = await validateEditActivityDurationModal(step, activityDuration)
    if (!isValidate) return
    try {
      const { data } = await updateBackofficeAdjustDailyExerciseDataMutation({
        variables: {
          input: {
            dailySummaryId: editActivityDurationModal.id ? editActivityDurationModal.id : '',
            step: parseInt(step),
            activityDuration: parseInt(activityDuration),
            remarkImageUrl: imgActivityDuration,
          },
        },
      })
      if (data) {
        message.success('แก้ไขสำเร็จ')
        onCloseModal({ isEditSuccess: true })
      }
    } catch (err) {
      console.error(err)
    }
  }

  const haveSomeValueFrom = (list: any[]) => _.some(list)

  useEffect(() => {
    if (haveSomeValueFrom([step, activityDuration])) {
      setIsFormValidate(true)
    } else {
      setIsFormValidate(false)
    }
  }, [step, activityDuration])

  return {
    editActivityDurationModal,
    step,
    imgActivityDuration,
    activityDuration,
    isFormValidate,
    handleUploadProps,
    handleRemoveFileProps,
    onHandlerSaveActivityDuration,
    onCloseModal,
    onChangeStep,
    onChangeActivityDuration,
    onHandlerDefaultValue,
    loadingBackofficeAdjustDailyExerciseDataMutation,
  }
}

export default EditActivityDurationModalHook
