import styled from '@emotion/styled'
import { Tooltip } from 'antd'

export const Container = styled('div')`
  padding: 24px 32px 24px 32px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
`

export const Header = styled('div')`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 18px;
`

export const ActionBlock = styled('div')`
  display: flex;
  justify-content: center;
`

export const ButtonIcon = styled('img')`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0px 2px;
`

export const InformationContainer = styled(Tooltip)``
