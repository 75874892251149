import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const Container = styled('div')``

export const Block = styled('div')`
  margin-right: 8px;
`

export const SubContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const RefTitle = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-bottom: 4px;
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 18px;
  color: #000000;
`

export const PvStatusTitle = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const StatusText = styled('span')`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 2.5;
`

export const StatusBlockStyle = css`
  border-right: 1px solid #e5e5e5;
  padding-right: 8px;
`
