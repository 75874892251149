import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetUserExerciseHistoryPaginationInterface,
  BackofficeGetUserExerciseHistoryPaginationInputInterface,
} from './interfaces'

const backofficeGetUserExerciseHistoryPagination: TypedDocumentNode<
  BackofficeGetUserExerciseHistoryPaginationInterface,
  BackofficeGetUserExerciseHistoryPaginationInputInterface
> = gql`
  query BackofficeGetUserExerciseHistoryPagination(
    $userId: ID!
    $policyNumber: String!
    $input: RequestPaginationInput!
  ) {
    backofficeGetUserExerciseHistoryPagination(
      userId: $userId
      policyNumber: $policyNumber
      input: $input
    ) {
      data {
        isEditWellPoint
        dailySummary {
          id
          date
          watchBrand {
            name
          }
          activityDuration
          step
          exercisePoint
          bonusPoint
          updatedAt
        }
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
      }
    }
  }
`

export default backofficeGetUserExerciseHistoryPagination
