import { Image } from 'antd'

import dayjs from 'dayjs'
import { useEffect } from 'react'
import {
  Block,
  Container,
  InformationTitle,
  Information,
  Title,
  Thumbnail,
  DetailBlockStyle,
  DetailInformationStyle,
  PdfIcon,
  SkeletonImage,
} from './styles'
import { ActiveHealthManualDiscountFilterApproveStatusEnum } from '@/gql/activeHealthPolicy/backofficeGetActiveHealthManualDiscountPagination/interfaces'
import { toThaiLocaleDateAndMonthRangeWithDash } from '@/libs/date'
import ManualDiscountInformationHeaderHook from '@/components/feature/PolicyDetailPage/ManualDiscountInformationHeader/ManualDiscountInformationHeader.hook'
import { ManualDiscountInformationProps } from './types'
import ManualDiscountInformationHook from './ManualDiscountInformation.hook'
import { IMAGE_URL } from '@/constant/IMAGE'

function ManualDiscountInformation({ activeHealthManualDiscount }: ManualDiscountInformationProps) {
  const { handleDisplayStatus } = ManualDiscountInformationHeaderHook()
  const {
    getFileExtension,
    getActiveHealthManualDiscountFile,
    fileUrl,
    handleDisplayPvStatus,
    getManualDiscountFileLoading,
  } = ManualDiscountInformationHook()
  const { status } = handleDisplayStatus(activeHealthManualDiscount.approveStatus)
  const extension = getFileExtension(activeHealthManualDiscount.fileKey)

  useEffect(() => {
    getActiveHealthManualDiscountFile(activeHealthManualDiscount.fileId)
  }, [activeHealthManualDiscount.fileId])

  return (
    <Container>
      <Block>
        <InformationTitle>ชื่อผู้เอาประกัน</InformationTitle>
        <Information>{activeHealthManualDiscount.activeHealthPolicy.insuredName}</Information>
      </Block>
      <Block>
        <InformationTitle>รอบบิลวันที่</InformationTitle>
        <Information>
          {toThaiLocaleDateAndMonthRangeWithDash(
            activeHealthManualDiscount.refActiveHealthPolicyBilling.startDate,
            activeHealthManualDiscount.refActiveHealthPolicyBilling.billDate,
          )}
        </Information>
      </Block>
      <Block>
        <InformationTitle>แผนประกัน</InformationTitle>
        <Information>{activeHealthManualDiscount.activeHealthPolicy.plan}</Information>
      </Block>
      <Block>
        <InformationTitle>วันที่ครบกำหนดชำระ</InformationTitle>
        <Information>
          {activeHealthManualDiscount.refActiveHealthPolicyBilling.billDate
            ? dayjs(activeHealthManualDiscount.refActiveHealthPolicyBilling.billDate).format(
                'DD-MM-YYYY',
              )
            : '-'}
        </Information>
      </Block>
      <Block>
        <InformationTitle>วันที่ขออนุโลมส่วนลด</InformationTitle>
        <Information>
          {dayjs(activeHealthManualDiscount.createdAt).format('DD-MM-YYYY')}
        </Information>
      </Block>
      <Block>
        <InformationTitle>ทำรายการโดย</InformationTitle>
        <Information>
          {activeHealthManualDiscount?.createdBy?.firstname}{' '}
          {activeHealthManualDiscount?.createdBy?.lastname}
        </Information>
      </Block>
      <Block>
        <InformationTitle>ส่วนลดค่าเบี้ย (บาท)</InformationTitle>
        <Information>{activeHealthManualDiscount.discount.toLocaleString()}</Information>
      </Block>
      <Block className={DetailBlockStyle}>
        <InformationTitle>รายละเอียด</InformationTitle>
        <Information className={DetailInformationStyle}>
          {activeHealthManualDiscount.reason || '-'}
        </Information>
        {getManualDiscountFileLoading ? (
          <Image.PreviewGroup>
            <SkeletonImage active />
          </Image.PreviewGroup>
        ) : fileUrl && (extension === 'jpeg' || extension === 'png') ? (
          <Image.PreviewGroup>
            <Thumbnail src={fileUrl} />
          </Image.PreviewGroup>
        ) : fileUrl && extension === 'pdf' ? (
          <a href={fileUrl} target="_blank" rel="noreferrer" type="application/pdf" download>
            <PdfIcon src={IMAGE_URL.pdfIcon} />
          </a>
        ) : undefined}
      </Block>
      <Title>รายละเอียดการอนุมัติ</Title>
      <Block>
        <InformationTitle>อนุมัติโดย</InformationTitle>
        <Block>{activeHealthManualDiscount?.activeHealthManualDiscountApprover?.name || '-'}</Block>
      </Block>
      <Block>
        <InformationTitle>วันที่อนุมัติ</InformationTitle>
        <Block>
          {activeHealthManualDiscount.approveStatus !==
          ActiveHealthManualDiscountFilterApproveStatusEnum.PENDING.toLocaleUpperCase()
            ? dayjs(activeHealthManualDiscount?.updatedAt).format('DD-MM-YYYY HH:mm:ss')
            : '-'}
        </Block>
      </Block>
      <Block>
        <InformationTitle>หมายเหตุ</InformationTitle>
        <Block>-</Block>
      </Block>
      <Block>
        <InformationTitle>สถานะ</InformationTitle>
        <Block>{status}</Block>
      </Block>
      <Block>
        <InformationTitle>สถานะ PV</InformationTitle>
        <Block>{handleDisplayPvStatus(activeHealthManualDiscount)}</Block>
      </Block>
    </Container>
  )
}

export default ManualDiscountInformation
