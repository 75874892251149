import backofficeTeam from '@/gql/backofficeTeam'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { BackofficeListBackofficeRoleDataInterface } from '@/gql/backofficeTeam/backofficeListBackofficeRole/types'
import backofficeMenu from '@/gql/backofficeMenu'
import {
  BackofficeGetAllBackofficeMenuType,
  BackofficeGetAllBackofficeMenuTypeDataInterface,
} from '@/gql/backofficeMenu/backofficeGetAllBackofficeMenuType/interfaces'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import selectedTeamPermissionAtom from '@/recoil/selectedTeamPermission/atom'
import {
  BackofficeGetMenuPermissionByMenuTypeIdType,
  MenuType,
  PageType,
  PermissionType,
} from '@/gql/backofficeMenu/backofficeGetMenuPermissionByMenuTypeId/interfaces'
import debounce from 'lodash/debounce'
import message from '@/libs/message'
import confirmModal from '@/recoil/confirmModal'

const TabTeamMemberPermissionHook = () => {
  const [roleOptions, setRoleOptions] = useState<Array<{ value: string; label: string }>>([])
  const [roleId, setRoleId] = useState<string>('')
  const [menu, setMenu] = useState<Array<BackofficeGetAllBackofficeMenuType>>([])

  const [initialMenuPermissionByMenuTypeId, setInitialMenuPermissionByMenuTypeId] =
    useState<BackofficeGetMenuPermissionByMenuTypeIdType>()
  const [menuPermissionByMenuTypeId, setMenuPermissionByMenuTypeId] =
    useState<BackofficeGetMenuPermissionByMenuTypeIdType>()
  const setConfirmModal = useSetRecoilState(confirmModal)

  const [teamMenuPermission, setTeamMenuPermission] = useState<
    Map<
      string,
      {
        id: string
        name: string
        code: string
      }
    >
  >(new Map())

  const [permissionSelected, setPermissionSelected] = useState<
    Map<
      string,
      {
        id: string
        name: string
        code: string
      }
    >
  >(new Map())

  const selectedTeam = useRecoilValue(selectedTeamPermissionAtom)

  const [getBackofficeListBackofficeRoleQuery, { loading: loadBackofficeListBackofficeRoleQuery }] =
    useLazyQuery(backofficeTeam.query.backofficeListBackofficeRole, {
      fetchPolicy: 'no-cache',
      onCompleted: (data: BackofficeListBackofficeRoleDataInterface) => {
        if (data.backofficeListBackofficeRole.length > 0) {
          const role = data.backofficeListBackofficeRole.map((role) => {
            return {
              value: role.id,
              label: role.title,
            }
          })

          setRoleId(role[1].value)
          setRoleOptions(role)
        }
      },
    })

  const [
    getBackofficeGetMenuPermissionByMenuTypeIdQuery,
    {
      refetch: refetchBackofficeGetMenuPermissionByMenuTypeId,
      loading: loadingGetMenuPermissionByMenuTypeId,
    },
  ] = useLazyQuery(backofficeMenu.query.backofficeGetMenuPermissionByMenuTypeId, {
    fetchPolicy: 'no-cache',
  })

  const [
    getBackofficeGetBackofficeTeamMenuPermissionQuery,
    {
      loading: loadBackofficeGetBackofficeTeamMenuPermission,
      refetch: refetchBackofficeGetBackofficeTeamMenuPermission,
    },
  ] = useLazyQuery(backofficeMenu.query.backofficeGetBackofficeTeamMenuPermission, {
    fetchPolicy: 'no-cache',
  })

  const [
    createBackofficeUpdateBackofficeTeamMenuPermission,
    { loading: loadingBackofficeUpdateBackofficeTeamMenuPermission },
  ] = useMutation(backofficeTeam.mutation.backofficeUpdateBackofficeTeamMenuPermission, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.backofficeUpdateBackofficeTeamMenuPermission.code === 200) {
        refetchBackofficeGetBackofficeTeamMenuPermission()
        refetchBackofficeGetMenuPermissionByMenuTypeId()
        message.success('แก้ไขสิทธิ์ในการใช้งานสำเร็จ')
      }
    },
    onError: (e) => {
      console.log('e ===>', e)
    },
  })

  const handleMenu = (menu: BackofficeGetAllBackofficeMenuType) => {
    if (menu) {
      getBackofficeGetMenuPermissionByMenuTypeIdQuery({
        variables: {
          id: menu.id,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          setInitialMenuPermissionByMenuTypeId(data.backofficeGetMenuPermissionByMenuTypeId)
          setMenuPermissionByMenuTypeId(data.backofficeGetMenuPermissionByMenuTypeId)

          if (selectedTeam.id)
            getBackofficeGetBackofficeTeamMenuPermissionQuery({
              variables: {
                input: {
                  backofficeTeamId: selectedTeam.id,
                  backofficeRoleId: roleId,
                },
              },
              fetchPolicy: 'no-cache',
              onCompleted: (data) => {
                if (data.backofficeGetBackofficeTeamMenuPermission.length > 0) {
                  const permission = new Map<
                    string,
                    {
                      id: string
                      name: string
                      code: string
                    }
                  >()

                  for (const perm of data.backofficeGetBackofficeTeamMenuPermission) {
                    permission.set(perm.code, perm)
                  }

                  setTeamMenuPermission(permission)
                  setPermissionSelected(new Map())
                } else {
                  setTeamMenuPermission(new Map())
                  setPermissionSelected(new Map())
                }
              },
            })
        },
      })
    }
  }

  const searchMenuByName = (
    menuObj: BackofficeGetMenuPermissionByMenuTypeIdType,
    keyword: string,
  ): BackofficeGetMenuPermissionByMenuTypeIdType => {
    const searchResult: BackofficeGetMenuPermissionByMenuTypeIdType = {
      ...menuObj,
      menu: [],
    }

    if (menuObj && menuObj.menu) {
      searchResult.menu = menuObj.menu.filter((menu) => {
        const nameMatch = menu.name.toLowerCase().includes(keyword.toLowerCase())
        const pageMatch = menu.page.some((page) =>
          page.name.toLowerCase().includes(keyword.toLowerCase()),
        )
        const permissionMatch = menu.page.some((page) =>
          page.permission.some((permission) =>
            permission.name.toLowerCase().includes(keyword.toLowerCase()),
          ),
        )
        return nameMatch || pageMatch || permissionMatch
      })
    }

    return searchResult
  }

  const handleSubmitPermission = () => {
    setConfirmModal({
      isVisible: true,
      title: 'มีการเปลี่ยนแปลงการตั้งค่า',
      bodyText: 'คุณต้องการบันทึกสิ่งที่ตั้งค่าทั้งหมดหรือไม่',
      okText: 'บันทึก',
      onOkFunction: () => handleCreatePermission(),
      dataTestId: 'submit-permission-modal',
    })
  }

  const handleSetSearch = useCallback(
    (word?: string) => {
      if (word && initialMenuPermissionByMenuTypeId) {
        const search = searchMenuByName(initialMenuPermissionByMenuTypeId, word)

        return setMenuPermissionByMenuTypeId(search)
      } else {
        return setMenuPermissionByMenuTypeId(initialMenuPermissionByMenuTypeId)
      }
    },
    [initialMenuPermissionByMenuTypeId],
  )

  const handleSearchTextChange = debounce((event?: ChangeEvent<HTMLInputElement>) => {
    const query = event?.target.value
    const value = query !== '' ? query : undefined
    return handleSetSearch(value)
  }, 500)

  const onHandleHeaderSwitch = (menuSwitch: MenuType) => {
    if (
      menuSwitch.page.every((page) =>
        page.permission.every((permission) => teamMenuPermission.has(permission.code)),
      )
    ) {
      for (const page of menuSwitch.page) {
        for (const permission of page.permission) {
          teamMenuPermission.delete(permission.code)
        }
      }
    } else {
      for (const page of menuSwitch.page) {
        for (const permission of page.permission) {
          teamMenuPermission.set(permission.code, permission)
        }
      }
    }

    if (
      menuSwitch.page.every((page) =>
        page.permission.every((permission) => permissionSelected.has(permission.code)),
      )
    ) {
      for (const page of menuSwitch.page) {
        for (const permission of page.permission) {
          permissionSelected.delete(permission.code)
        }
      }
    } else {
      for (const page of menuSwitch.page) {
        for (const permission of page.permission) {
          permissionSelected.set(permission.code, permission)
        }
      }
    }

    setTeamMenuPermission(new Map(teamMenuPermission))
  }

  const onHandlePageSwitch = (pageSwitch: PageType) => {
    if (pageSwitch.permission.every((permission) => teamMenuPermission.has(permission.code))) {
      for (const permission of pageSwitch.permission) {
        teamMenuPermission.delete(permission.code)
      }
    } else {
      for (const permission of pageSwitch.permission) {
        teamMenuPermission.set(permission.code, permission)
      }
    }

    if (pageSwitch.permission.every((permission) => permissionSelected.has(permission.code))) {
      for (const permission of pageSwitch.permission) {
        permissionSelected.delete(permission.code)
      }
    } else {
      for (const permission of pageSwitch.permission) {
        permissionSelected.set(permission.code, permission)
      }
    }

    setTeamMenuPermission(new Map(teamMenuPermission))
  }

  const onHandlePermissionSwitch = (permissionSwitch: PermissionType) => {
    if (teamMenuPermission.has(permissionSwitch.code)) {
      teamMenuPermission.delete(permissionSwitch.code)
    } else {
      teamMenuPermission.set(permissionSwitch.code, permissionSwitch)
    }

    if (permissionSelected.has(permissionSwitch.code)) {
      permissionSelected.delete(permissionSwitch.code)
    } else {
      permissionSelected.set(permissionSwitch.code, permissionSwitch)
    }

    setTeamMenuPermission(new Map(teamMenuPermission))
  }

  const [
    getBackofficeGetAllBackofficeMenuTypeQuery,
    { loading: loadBackofficeGetAllBackofficeMenuTypeQuery },
  ] = useLazyQuery(backofficeMenu.query.backofficeGetAllBackofficeMenuType, {
    fetchPolicy: 'no-cache',
  })

  const handleCreatePermission = async () => {
    if (
      initialMenuPermissionByMenuTypeId?.id &&
      roleId &&
      selectedTeam.id &&
      permissionSelected.size > 0
    ) {
      const teamMenuPermissionId = Array.from(teamMenuPermission.values()).map((perm) => perm.id)

      let resultPermissionId = []

      // REMIND: Find the licensed information of the teamMenuPermissionId.
      for (const menuItem of initialMenuPermissionByMenuTypeId.menu) {
        for (const pageItem of menuItem.page) {
          for (const permissionItem of pageItem.permission) {
            if (teamMenuPermissionId.includes(permissionItem.id)) {
              resultPermissionId.push(permissionItem.id)
            }
          }
        }
      }

      const data = {
        backofficeTeamId: selectedTeam.id,
        backofficeRoleId: roleId,
        backofficeMenuTypeId: initialMenuPermissionByMenuTypeId?.id,
        permissionIds: teamMenuPermissionId,
      }

      await createBackofficeUpdateBackofficeTeamMenuPermission({
        variables: {
          input: { ...data },
        },
      }).finally(() => {
        setConfirmModal({
          isVisible: false,
          onOkFunction: Function,
          dataTestId: undefined,
        })
        setPermissionSelected(new Map())
      })
    }
  }

  useEffect(() => {
    if (selectedTeam.id && roleId)
      getBackofficeGetBackofficeTeamMenuPermissionQuery({
        variables: {
          input: {
            backofficeTeamId: selectedTeam.id,
            backofficeRoleId: roleId,
          },
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          if (data.backofficeGetBackofficeTeamMenuPermission.length > 0) {
            const permission = new Map<
              string,
              {
                id: string
                name: string
                code: string
              }
            >()

            for (const perm of data.backofficeGetBackofficeTeamMenuPermission) {
              permission.set(perm.code, perm)
            }

            setTeamMenuPermission(permission)
            setPermissionSelected(new Map())
          } else {
            setTeamMenuPermission(new Map())
            setPermissionSelected(new Map())
          }
        },
      })
  }, [getBackofficeGetBackofficeTeamMenuPermissionQuery, roleId, selectedTeam.id])

  useEffect(() => {
    getBackofficeGetAllBackofficeMenuTypeQuery({
      fetchPolicy: 'no-cache',
      onCompleted: (data: BackofficeGetAllBackofficeMenuTypeDataInterface) => {
        if (data.backofficeGetAllBackofficeMenuType.length > 0) {
          const menu = data.backofficeGetAllBackofficeMenuType.sort((a, b) => a.order - b.order)
          setMenu(menu)

          getBackofficeGetMenuPermissionByMenuTypeIdQuery({
            variables: {
              id: menu[0].id,
            },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
              if (data.backofficeGetMenuPermissionByMenuTypeId) {
                setInitialMenuPermissionByMenuTypeId(data.backofficeGetMenuPermissionByMenuTypeId)
                setMenuPermissionByMenuTypeId(data.backofficeGetMenuPermissionByMenuTypeId)
              }
            },
          })
        }
      },
    })
  }, [getBackofficeGetAllBackofficeMenuTypeQuery, getBackofficeGetMenuPermissionByMenuTypeIdQuery])

  useEffect(() => {
    getBackofficeListBackofficeRoleQuery()
  }, [])

  const isLoading =
    loadBackofficeListBackofficeRoleQuery ||
    loadBackofficeGetAllBackofficeMenuTypeQuery ||
    loadBackofficeGetBackofficeTeamMenuPermission

  const disabledSaveButton =
    permissionSelected.size === 0 || loadBackofficeGetBackofficeTeamMenuPermission

  return {
    roleOptions,
    roleId,
    isLoading,
    setRoleId,
    menu,
    menuPermissionByMenuTypeId,
    handleMenu,
    handleSearchTextChange,
    onHandleHeaderSwitch,
    onHandlePageSwitch,
    onHandlePermissionSwitch,
    teamMenuPermission,
    handleCreatePermission,
    loadingBackofficeUpdateBackofficeTeamMenuPermission,
    handleSubmitPermission,
    disabledSaveButton,
    loadBackofficeGetAllBackofficeMenuTypeQuery,
    loadingGetMenuPermissionByMenuTypeId,
  }
}

export default TabTeamMemberPermissionHook
