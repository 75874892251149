import { useEffect } from 'react'
import BillingInformationHook from './BillingInformation.hook'
import {
  ActivityBonusStyle,
  BillingCard,
  BillingIcon,
  ActiveHealthScoreBlock,
  BlockSeparate,
  Body,
  Card,
  CardSubtitle,
  CardTitle,
  Container,
  CreditCardTypeIcon,
  DiscountBadge,
  DiscountBlock,
  DiscountPrice,
  DiscountText,
  FooterBlock,
  FooterInformation,
  FooterNotation,
  FooterPaymentInformation,
  FooterPaymentText,
  FooterText,
  FooterTitle,
  Header,
  HeaderBlock,
  PaymentStatus,
  PointBlock,
  PointIcon,
  PointText,
  ScoreText,
  SubBlock,
  Title,
  WeeklyBlock,
  Skeleton,
  WellPointSubBlock,
  ExercisePointSubBlock,
} from './styles'
import PointWeeklyInformation from '@/components/feature/PolicyDetailPage/PointWeeklyInformation'
import { toThaiLocaleDateAndMonthRangeWithDash, toThaiLocaleDateString } from '@/libs/date'
import ActivityBonusNotification from '@/components/feature/PolicyDetailPage/ActivityBonusNotification'
import { BillingStatusEnum } from '@/constant/BILLING_STATUS'
import { IMAGE_URL } from '@/constant/IMAGE'
import { BillingInformationProps } from './types'
import { ActiveHealthWeeklySummaryStatusEnum } from '@/gql/activeHealthPolicy/backofficeListActiveHealthByBillId/interfaces'
import { CREDIT_CARD_TYPE_IMAGE } from '@/constant/ACTIVE_HEALTH_POLICY_BILLING'

function BillingInformation({
  activeHealthPolicyBilling,
  activeHealthPolicyWeeklySummaries,
  loading,
}: BillingInformationProps) {
  const {
    openSideBarChildren,
    specialPointHighlights,
    getSpecialPointHighlight,
    displayLastDigits,
    getCreditCardType,
    visibleTitleCurrentBilling,
    specialPointHighlightLoading,
  } = BillingInformationHook()
  const creditCardType = getCreditCardType(activeHealthPolicyBilling)
  const visibleTitle = visibleTitleCurrentBilling(activeHealthPolicyBilling)

  // REMIND: We must call in tsx cause activeHealthPolicyBilling will pass when click on billing table
  useEffect(() => {
    if (activeHealthPolicyBilling.startDate) {
      getSpecialPointHighlight(activeHealthPolicyBilling.startDate)
    }
  }, [activeHealthPolicyBilling])

  return (
    <Container>
      {loading || specialPointHighlightLoading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <>
          <Header>
            <HeaderBlock>
              {visibleTitle ? (
                <>
                  <Title>ระยะเวลาสะสม</Title>
                  <Title>คะแนนรอบบิลปัจจุบัน</Title>
                </>
              ) : (
                <>
                  <Title>บิลค่าเบี้ย</Title>
                  <Title>ที่ต้องชำระตั้งแต่</Title>
                </>
              )}
              <Title>
                {toThaiLocaleDateAndMonthRangeWithDash(
                  activeHealthPolicyBilling.startDate,
                  activeHealthPolicyBilling.billDate,
                )}
              </Title>
              <PaymentStatus>
                {activeHealthPolicyBilling.paymentStatus === BillingStatusEnum.PAID
                  ? 'ชำระแล้ว'
                  : 'ยังไม่ชำระ'}
              </PaymentStatus>
            </HeaderBlock>
            <HeaderBlock>
              <BillingIcon src={IMAGE_URL.billingSummaryIcon} />
            </HeaderBlock>
          </Header>
          <Body>
            {specialPointHighlights.length > 0 && (
              <ActivityBonusNotification
                specialHighlights={specialPointHighlights}
                className={ActivityBonusStyle}
                displayInsideBadge={true}
              />
            )}

            {activeHealthPolicyWeeklySummaries.length > 0 &&
              activeHealthPolicyWeeklySummaries
                .filter((dt) => dt.status !== ActiveHealthWeeklySummaryStatusEnum.PENDING)
                .map((weeklySummary, index) => (
                  <Card
                    key={index}
                    special={specialPointHighlights.length > 0 && index === 0}
                    onClick={() =>
                      openSideBarChildren({
                        sideBarChildrenVisible: true,
                        sideBarChildren: <PointWeeklyInformation weeklySummary={weeklySummary} />,
                        sideBarChildrenTitle: 'ข้อมูลส่วนลดรายสัปดาห์',
                      })
                    }
                    data-testid={`policy-detail-billing-information-card-${index}`}
                  >
                    <BillingCard>
                      <WeeklyBlock>
                        <div>
                          <CardTitle>สัปดาห์ที่ {weeklySummary.billingWeekNo}</CardTitle>
                          <CardSubtitle>
                            ตั้งแต่ {toThaiLocaleDateString({ date: weeklySummary.startDate })} -{' '}
                            {toThaiLocaleDateString({ date: weeklySummary.endDate })}
                          </CardSubtitle>
                        </div>
                        <DiscountBadge status={weeklySummary.status}>
                          {weeklySummary.status === ActiveHealthWeeklySummaryStatusEnum.ACTIVE
                            ? 'อยู่ในระหว่างสะสม'
                            : weeklySummary.status ===
                              ActiveHealthWeeklySummaryStatusEnum.GOTDISCOUNT
                            ? 'ได้รับส่วนลด'
                            : 'ไม่ได้รับส่วนลด'}
                        </DiscountBadge>
                      </WeeklyBlock>

                      <ActiveHealthScoreBlock>
                      <ExercisePointSubBlock>
                        <PointBlock>
                          <PointIcon src={IMAGE_URL.pointIcon} />
                          <PointText>คะแนนออกกำลังกาย</PointText>
                        </PointBlock>
                        <ScoreText>{weeklySummary.summaryExercisePoint} คะแนน</ScoreText>
                        </ExercisePointSubBlock>
                        {
                        weeklySummary.summaryBonusPoint !== null ?
                        <WellPointSubBlock>
                          <>
                          <PointBlock>
                              <PointIcon src={IMAGE_URL.wellBeingIcon} />
                              <PointText>คะแนน Well Point</PointText>
                            </PointBlock>
                            <ScoreText>{weeklySummary.summaryBonusPoint} คะแนน</ScoreText></>
                        </WellPointSubBlock>
                          : undefined
                        }
                      </ActiveHealthScoreBlock>
                      <DiscountBlock>
                        <DiscountText>ส่วนลดค่าเบี้ยที่ได้รับ</DiscountText>
                        <DiscountPrice>
                          {weeklySummary.discountAmount.toLocaleString()} บาท
                        </DiscountPrice>
                      </DiscountBlock>
                    </BillingCard>
                  </Card>
                ))}
          </Body>

          <FooterBlock>
            <FooterTitle>ชำระเงินผ่าน</FooterTitle>
            <SubBlock>
              <FooterText>บัตรเครดิต</FooterText>
              <FooterInformation>
                {creditCardType ? (
                  <CreditCardTypeIcon src={CREDIT_CARD_TYPE_IMAGE[creditCardType]} />
                ) : undefined}
                {activeHealthPolicyBilling.paymentStatus === BillingStatusEnum.PAID
                  ? displayLastDigits(activeHealthPolicyBilling.creditCardNumber)
                  : displayLastDigits(
                      activeHealthPolicyBilling.activeHealthPolicy.creditCardNumber,
                    ) || '-'}
              </FooterInformation>
            </SubBlock>
            <SubBlock>
              <FooterText>วันที่ต้องชำระ</FooterText>
              <FooterInformation>
                {activeHealthPolicyBilling.billDate
                  ? toThaiLocaleDateString({ date: activeHealthPolicyBilling.billDate })
                  : '-'}
              </FooterInformation>
            </SubBlock>
            <BlockSeparate />
            <FooterTitle>สรุปเบี้ยประกัน</FooterTitle>
            <SubBlock>
              <FooterText>ค่าเบี้ยประกัน</FooterText>
              <FooterInformation>
                {activeHealthPolicyBilling.billingAmount.toLocaleString()} บาท
              </FooterInformation>
            </SubBlock>
            <SubBlock>
              <FooterText>ส่วนลดการออกกำลังกาย</FooterText>
              <FooterInformation>
                {`-${
                  (
                    activeHealthPolicyBilling.billingDiscount -
                    activeHealthPolicyBilling.totalManualDiscount
                  ).toLocaleString() || 0
                } บาท`}
              </FooterInformation>
            </SubBlock>
            {activeHealthPolicyBilling.totalManualDiscount > 0 ? (
              <SubBlock>
                <FooterText>ส่วนลดการขออนุโลม</FooterText>
                <FooterInformation>
                  {`-${activeHealthPolicyBilling.totalManualDiscount.toLocaleString()} บาท`}
                </FooterInformation>
              </SubBlock>
            ) : undefined}
            <SubBlock>
              <FooterPaymentText>ค่าเบี้ยที่ต้องชำระ</FooterPaymentText>
              <FooterPaymentInformation>
                {`${(
                  activeHealthPolicyBilling.billingAmount -
                  activeHealthPolicyBilling.billingDiscount
                ).toLocaleString()} บาท`}
              </FooterPaymentInformation>
            </SubBlock>
            <FooterNotation>
              กรุณาตรวจสอบการดึงข้อมูลการออกกำลังกายเพื่อรับส่วนลดค่าเบี้ย
              หากเช็กแล้วปรากฏว่าสัปดาห์ที่ผ่านมาของรอบบิล นี้ไม่ได้รับส่วนลด
              คุณสามารถดึงข้อมูลย้อนหลังได้ และ ระบบจะทำการคำนวณให้ใหม่ จนกว่าจะถึงวันที่สรุปยอดของ
              รอบบิลนี้
            </FooterNotation>
          </FooterBlock>
        </>
      )}
    </Container>
  )
}

export default BillingInformation
