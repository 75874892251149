import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ColumnsType } from 'antd/lib/table'
import React, { Key, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useLazyQuery } from '@apollo/client'
import { Badge, TablePaginationConfig } from 'antd'
import { fromEvent, of, tap } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import _ from 'lodash'
import { CALL_TYPE, CallTypeEnum, CallTypeFilterEnum } from '@/constant/CALL_TYPE'
import welcomeCallInformationAtom from '@/recoil/welcomeCallInformation'
import sideBarInformationAtom from '@/recoil/sideBarInformation'
import { welcomeCallGql } from '@/gql'
import { WelcomeCallFilterDateWIthCountCallStatusInterface } from '@/gql/welcomeCall/backofficeGetWelcomeCallFilterDateWIthCountCallStatus/interfaces'
import {
  WelcomeCallInputFilterInterface,
  WelcomeCallInputInterface,
  WelcomeCallInterface,
  WelcomeCallMetaInterface,
  WelcomeCallPaginationInterface,
} from '@/gql/welcomeCall/backofficeGetWelcomeCallPagination/interfaces'
import { EyeIcon } from '@/components/feature/WelcomeCallPage/WelcomeCallTable/styles'
import WelcomeCallInformation from '@/components/feature/WelcomeCallPage/WelcomeCallInformation'
import WelcomeCallInformationHeader from '@/components/feature/WelcomeCallPage/WelcomeCallInformationHeader'
import { BadgeStatusEnum } from '@/constant/BADGE_STATUS'
import { SORTER, SorterEnum } from '@/constant/USER'
import { ColumnFilterItem, FilterValue, SorterResult } from 'antd/es/table/interface'
import { WelcomeCallSortableFieldEnum } from '@/constant/WELCOME_CALL'
import { SortByEnum } from '@/constant/SORT_BY'
import userAtom from '@/recoil/user'
import { IMAGE_URL } from '@/constant/IMAGE'
import { UserSelectStyle } from '@/components/common/Table/styles'

function WelcomeCallTableHook() {
  const searchInputElement = document.getElementById('welcome-call-search')
  const [welcomeCallInformation, setWelcomeCallInformation] = useRecoilState(
    welcomeCallInformationAtom,
  )
  const [sideBarInformation, setSideBarInformation] = useRecoilState(sideBarInformationAtom)
  const user = useRecoilValue(userAtom)
  const [
    getFilterDateWithCountCallStatus,
    { data: dateFilterWithCountCallStatusData, loading: dateFilterWithCountCallStatusLoading },
  ] = useLazyQuery(welcomeCallGql.query.backofficeGetWelcomeCallFilterDateWithCountCallStatus, {
    fetchPolicy: 'no-cache',
  })
  const [
    getWelcomeCallPagination,
    { data: welcomeCallPaginationData, loading: welcomeCallPaginationLoading },
  ] = useLazyQuery(welcomeCallGql.query.backofficeGetWelcomeCallPagination, {
    fetchPolicy: 'no-cache',
  })
  const [listWelcomeCallPaymentMethod, { loading: listWelcomeCallPaymentMethodLoading }] =
    useLazyQuery(welcomeCallGql.query.backofficeListWelcomeCallPaymentMethod, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.backofficeListWelcomeCallPaymentMethod.length > 0) {
          setPaymentMethodFilter(
            data.backofficeListWelcomeCallPaymentMethod.map((value) => ({ text: value, value })),
          )
        } else {
          setPaymentMethodFilter([])
        }
      },
    })
  const [listWelcomeCallPurchaseChannel, { loading: listWelcomeCallPurchaseChannelLoading }] =
    useLazyQuery(welcomeCallGql.query.backofficeListWelcomeCallPurchaseChannel, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.backofficeListWelcomeCallPurchaseChannel.length > 0) {
          setPurchaseChannelFilter(
            data.backofficeListWelcomeCallPurchaseChannel.map((value) => ({
              text: value,
              value,
            })),
          )
        } else {
          setPaymentMethodFilter([])
        }
      },
    })

  const [paymentMethodFilter, setPaymentMethodFilter] = useState<Array<ColumnFilterItem>>([])
  const [purchaseChannelFilter, setPurchaseChannelFilter] = useState<Array<ColumnFilterItem>>([])
  const [selectIndex, setSelectIndex] = useState(0)
  const [selectCallType, setSelectCallType] = useState<CallTypeFilterEnum>(CallTypeFilterEnum.ALL)
  const [selectDate, setSelectDate] = useState<string>('')
  const [filters, setFilters] = useState<Array<WelcomeCallFilterDateWIthCountCallStatusInterface>>(
    [],
  )
  const [sorter, setSorter] = useState<[WelcomeCallSortableFieldEnum, SortByEnum | '']>([
    WelcomeCallSortableFieldEnum.CREATED_AT,
    SortByEnum.DESC,
  ])
  const [welcomeCalls, setWelcomeCalls] = useState<Array<WelcomeCallInterface> | null>(null)
  const [welcomeCallPagination, setWelcomeCallPagination] =
    useState<WelcomeCallMetaInterface | null>(null)
  const [currentPageSize, setCurrentPageSize] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [selectPaymentMethodFilters, setSelectPaymentMethodFilters] = useState<
    Array<boolean | Key>
  >([])
  const [selectPurchaseChannelFilters, setSelectPurchaseChannelFilters] = useState<
    Array<boolean | Key>
  >([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([])
  const isTableLoading =
    dateFilterWithCountCallStatusLoading ||
    welcomeCallPaginationLoading ||
    listWelcomeCallPaymentMethodLoading ||
    listWelcomeCallPurchaseChannelLoading
  const columns: ColumnsType<WelcomeCallInterface | {}> = [
    {
      title: 'ลำดับ',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '100px',
      render: (_, __, index) => {
        return <div>{index + 1}</div>
      },
    },
    {
      title: 'เลขอ้างอิง',
      dataIndex: 'referenceNumber',
      key: 'referenceNumber',
      className: UserSelectStyle,
      width: '150px',
    },
    {
      title: 'ชื่อผู้เอาประกัน',
      dataIndex: 'insuredName',
      key: 'insuredName',
      className: UserSelectStyle,
    },
    {
      title: 'เลขที่กรมธรรม์',
      dataIndex: 'policyNumber',
      key: 'policyNumber',
      className: UserSelectStyle,
      width: '200px',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      className: UserSelectStyle,
      width: '150px',
    },
    {
      title: 'แผนประกัน',
      dataIndex: 'planCode',
      key: 'planCode',
      className: UserSelectStyle,
      width: '150px',
    },
    {
      title: 'ที่อยู่จัดส่งนาฬิกา',
      dataIndex: 'address',
      key: 'address',
      className: UserSelectStyle,
    },
    {
      title: 'หมายเหตุจาก PV',
      dataIndex: 'remarkPolicy',
      key: 'remarkPolicy',
      className: UserSelectStyle,
    },
    {
      title: 'วันเริ่มคุ้มครอง',
      dataIndex: 'effectiveDate',
      key: 'effectiveDate',
      width: '150px',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (effectiveDate) => {
        return <div>{dayjs(effectiveDate).format('DD-MM-YYYY')}</div>
      },
    },
    {
      title: 'ช่องทางการซื้อ',
      filters: purchaseChannelFilter,
      filteredValue: selectPurchaseChannelFilters.length > 0 ? selectPurchaseChannelFilters : null,
      dataIndex: 'purchaseChannel',
      key: 'purchaseChannel',
    },
    {
      title: 'ช่องทางการจ่ายเงิน',
      filters: paymentMethodFilter,
      filteredValue: selectPaymentMethodFilters.length > 0 ? selectPaymentMethodFilters : null,
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: 'วันที่จัดส่งอุปกรณ์',
      dataIndex: 'watchDeliveredDate',
      key: 'watchDeliveredDate',
      width: '150px',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (watchDeliveredDate) => {
        return <div>{watchDeliveredDate ? dayjs(watchDeliveredDate).format('DD-MM-YYYY') : ''}</div>
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'callStatus',
      key: 'callStatus',
      width: '100px',
      render: (callStatus: CallTypeEnum) => {
        return (
          <div>
            <Badge
              status={
                callStatus === CallTypeEnum.CALLED
                  ? BadgeStatusEnum.SUCCESS
                  : BadgeStatusEnum.WARNING
              }
            />{' '}
            {CALL_TYPE[callStatus]}
          </div>
        )
      },
    },
    {
      title: 'แจ้งลูกค้าโดย',
      dataIndex: 'calledBy',
      key: 'calledBy',
      render: (_, record) => {
        const { calledBy } = record as WelcomeCallInterface

        if (calledBy?.firstname && calledBy?.lastname) {
          return (
            <div>
              {calledBy.firstname} {calledBy.lastname}
            </div>
          )
        }

        return ''
      },
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: '',
      width: 50,
      fixed: 'right',
      render: (_value, record, index) => (
        <EyeIcon
          src={IMAGE_URL.eyeIcon}
          onClick={() => {
            handleSetWelcomeCallInformation(
              record as WelcomeCallInterface,
              <WelcomeCallInformation />,
              <WelcomeCallInformationHeader />,
            )
            handleSelectKeyRows([index])
          }}
          data-testid={`welcome-call-table-view-${index}`}
        />
      ),
    },
  ]

  const handleSetWelcomeCallInformation = (
    data: WelcomeCallInterface,
    children: React.ReactNode,
    titleElement: React.ReactNode,
  ) => {
    setWelcomeCallInformation({
      welcomeCall: {
        ...data,
        watchDeliveredDate: data.watchDeliveredDate ? dayjs(data.watchDeliveredDate) : null,
      },
    })
    setSideBarInformation({
      children: children,
      title: titleElement,
      visible: true,
      dataTestId: 'welcome-call-sidebar',
    })
  }

  const handleSetTableState = ({
    selectDate,
    currentPage,
    currentPageSize,
  }: {
    selectDate: string
    currentPage: number
    currentPageSize: number
  }) => {
    setSelectDate(selectDate)
    setCurrentPage(currentPage)
    setCurrentPageSize(currentPageSize)
  }

  const transitionFilterDateButton = ({
    direction,
    index,
  }: {
    direction: 'next' | 'previous'
    index: number
  }) => {
    const blockElement = document.getElementById('filter-button-block')
    const blockRect = blockElement?.getBoundingClientRect()

    if (index >= 0 && index < filters.length && direction === 'next') {
      const directionElement = document.getElementById((selectIndex + 1).toString())
      const directionRect = directionElement?.getBoundingClientRect()

      setSelectIndex(selectIndex + 1)
      handleSetTableState({ selectDate: filters[index].date, currentPage: 1, currentPageSize: 10 })
      resetPaymentMethodAndPurchaseChannelFilter()

      if (directionRect && blockRect) {
        if (directionRect.x > blockRect.width + directionRect.width) {
          return blockElement && (blockElement.scrollLeft += directionRect.width + 8)
        }
      }
    } else if (index >= 0 && direction === 'previous') {
      const directionElement = document.getElementById((selectIndex - 1).toString())
      const directionRect = directionElement?.getBoundingClientRect()

      setSelectIndex(selectIndex - 1)
      handleSetTableState({ selectDate: filters[index].date, currentPage: 1, currentPageSize: 10 })
      resetPaymentMethodAndPurchaseChannelFilter()

      if (directionRect && blockRect) {
        if (directionRect.x < blockRect.x) {
          return blockElement && (blockElement.scrollLeft -= directionRect.width + 8)
        }
      }
    }
  }

  const handleGetWelcomeCallWithPagination = (input: WelcomeCallInputInterface) => {
    getWelcomeCallPagination({ variables: { input } })
  }

  const handleOnChangeTable = ({
    pagination,
    sorter,
    filters,
  }: {
    pagination: TablePaginationConfig
    sorter: SorterResult<WelcomeCallInterface | {}> | Array<SorterResult<WelcomeCallInterface | {}>>
    filters: Record<string, FilterValue | null>
  }) => {
    if (filters.purchaseChannel && filters.purchaseChannel.length > 0) {
      setSelectPurchaseChannelFilters(filters.purchaseChannel)
    } else {
      setSelectPurchaseChannelFilters([])
    }

    if (filters.paymentMethod && filters?.paymentMethod?.length > 0) {
      setSelectPaymentMethodFilters(filters.paymentMethod)
    } else {
      setSelectPaymentMethodFilters([])
    }

    if (pagination.current && pagination.pageSize) {
      setCurrentPage(pagination.current)
      setCurrentPageSize(pagination.pageSize)
    }

    if (!_.isEmpty(sorter)) {
      const sortData = sorter as SorterResult<WelcomeCallInterface | {}>
      const sortBy = sortData.columnKey as WelcomeCallSortableFieldEnum
      const sort = sortData.order as SorterEnum

      setSorter([sortBy, SORTER[sort] || ''])
    }
  }

  const handleOnClickDateFilter = (index: number, date: string) => {
    setSelectIndex(index)
    handleSetTableState({ selectDate: date, currentPage: 1, currentPageSize: 10 })
    resetPaymentMethodAndPurchaseChannelFilter()
  }

  const handleOnSearchInputChange = (isTyping: boolean) => {
    setIsTyping(isTyping)
    resetPaymentMethodAndPurchaseChannelFilter()
  }

  const handleSetSearch = (key: string) =>
    of(setSearch(key)).pipe(
      tap(() => {
        setCurrentPage(1)
        setCurrentPageSize(10)
      }),
    )

  const resetPaymentMethodAndPurchaseChannelFilter = () => {
    if (selectPurchaseChannelFilters.length > 0) {
      setSelectPurchaseChannelFilters([])
    }

    if (selectPaymentMethodFilters.length > 0) {
      setSelectPaymentMethodFilters([])
    }
  }

  const handleSelectKeyRows = (data: Array<number>) => setSelectedRowKeys(data)

  useEffect(() => {
    if (!dateFilterWithCountCallStatusData || welcomeCallInformation.isCallStatusChange) {
      getFilterDateWithCountCallStatus()
      setWelcomeCallInformation({ ...welcomeCallInformation, isCallStatusChange: false })
    } else if (
      dateFilterWithCountCallStatusData?.backofficeGetWelcomeCallFilterDateWithCountCallStatus
    ) {
      setFilters(
        dateFilterWithCountCallStatusData.backofficeGetWelcomeCallFilterDateWithCountCallStatus,
      )
    }
  }, [dateFilterWithCountCallStatusData, welcomeCallInformation.isCallStatusChange])

  useEffect(() => {
    if (welcomeCallPaginationData?.backofficeGetWelcomeCallPagination) {
      const { data, meta }: WelcomeCallPaginationInterface =
        welcomeCallPaginationData.backofficeGetWelcomeCallPagination

      setWelcomeCalls(data)
      setWelcomeCallPagination(meta)
    }
  }, [welcomeCallPaginationData])

  useEffect(() => {
    if (filters.length > 0 && !selectDate) {
      setSelectDate(filters[0].date)
    }
  }, [filters, selectDate])

  useEffect(() => {
    if (selectDate) {
      dayjs.extend(utc)
      const from = dayjs(selectDate).startOf('d').utc(false).toISOString()
      const to = dayjs(selectDate).startOf('d').utc(false).add(1, 'd').toISOString()
      const filter: WelcomeCallInputFilterInterface = {
        createdAt: `$btw:${from},${to}`,
        callStatus: selectCallType,
        paymentMethod:
          selectPaymentMethodFilters.length > 0
            ? `$in:${selectPaymentMethodFilters.join()}`
            : undefined,
        purchaseChannel:
          selectPurchaseChannelFilters.length > 0
            ? `$in:${selectPurchaseChannelFilters.join()}`
            : undefined,
      }

      if (!filter.callStatus) {
        filter.callStatus = undefined
      }

      if (!filter.purchaseChannel) {
        filter.purchaseChannel = undefined
      }

      if (!filter.paymentMethod) {
        filter.paymentMethod = undefined
      }

      handleGetWelcomeCallWithPagination({
        page: currentPage,
        limit: currentPageSize,
        filter,
        search: search,
        searchBy: ['insuredName', 'phoneNumber', 'referenceNumber'],
        sortBy: [sorter],
      })

      listWelcomeCallPaymentMethod({
        variables: {
          date: dayjs(selectDate).toDate(),
        },
      })
      listWelcomeCallPurchaseChannel({
        variables: {
          date: dayjs(selectDate).toDate(),
        },
      })
    }
  }, [
    selectCallType,
    selectDate,
    currentPage,
    currentPageSize,
    search,
    welcomeCallInformation,
    sorter,
    selectPaymentMethodFilters,
    selectPurchaseChannelFilters,
  ])

  useEffect(() => {
    if (isTyping && searchInputElement) {
      fromEvent(searchInputElement, 'keyup')
        .pipe(
          debounceTime(1000),
          map((e: any) => e.target.value),
          distinctUntilChanged(),
          switchMap(handleSetSearch),
          tap(() => setIsTyping(false)),
        )
        .subscribe()
    }
  }, [isTyping, searchInputElement])

  useEffect(() => {
    if (!sideBarInformation.visible) {
      handleSelectKeyRows([])
    }
  }, [sideBarInformation.visible])

  return {
    columns,
    filters,
    selectIndex,
    selectCallType,
    setSelectCallType,
    transitionFilterDateButton,
    dateFilterWithCountCallStatusData,
    welcomeCalls,
    welcomeCallPagination,
    handleOnChangeTable,
    handleOnClickDateFilter,
    currentPage,
    currentPageSize,
    handleOnSearchInputChange,
    selectedRowKeys,
    user,
    isTableLoading,
    dateFilterWithCountCallStatusLoading,
  }
}

export default WelcomeCallTableHook
