import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackOfficeGetDiscountConditionByPolicyNumberDataInterface,
  BackOfficeGetDiscountConditionByPolicyNumberInputInterface,
} from './interfaces'

const backofficeGetDiscountConditionByPolicyNumber: TypedDocumentNode<
  BackOfficeGetDiscountConditionByPolicyNumberDataInterface,
  BackOfficeGetDiscountConditionByPolicyNumberInputInterface
> = gql`
  query BackOfficeGetDiscountConditionByPolicyNumber($input: String!) {
    backOfficeGetDiscountConditionByPolicyNumber(policyNumber: $input)
  }
`

export default backofficeGetDiscountConditionByPolicyNumber
