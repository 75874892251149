import { atom } from 'recoil'
import { UserPolicyInterface } from '@/gql/ppuMotor/getAllUserPoliciesByIdentityId/interfaces'

const initPolicy: UserPolicyInterface = {
  policyNumber: null,
  insuredName: null,
  identityId: null,
  insuredDateOfBirth: null,
  insuredAddress: null,
  insuredDistrict: {
    displayNameTh: null,
  },
  insuredProvince: {
    displayNameTh: null,
  },
  insuredPostCode: null,
  deliveryAddress: null,
  beneficiary: null,
  policySuperAppStatus: null,
  sumInsured: null,
  vehicleNumber: null,
  effectiveDate: null,
  expiryDate: null,
  garageGrade: null,
  compulsoryMotorInsurancePolicyNumber: null,
  ppuTimeUsed: null,
  ppuTimeLeft: null,
  ppuPlanType: null,
  mtPolicyType: null,
  planName: null,
  campaignName: null,
  policyGroup: null,
  ipdSumInsured: null,
  opdSumInsured: null,
  paMedicalExpenseLimit: null,
  opdTotal: null,
  opdLeft: null,
  roomLimit: null,
  paAccidentalDeath: null,
  taOnOffCoverDaysTotal: null,
  taOnOffCoverDaysLeft: null,
  diseases: [],
  carModelName: null,
  carYear: null,
  pvVehicleType: null,
}

const policyAtom = atom({
  key: 'policyAtom',
  default: initPolicy,
})

export default policyAtom
