import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeUpdateBackofficeTeamMenuPermissionDataInterface,
  BackofficeUpdateBackofficeTeamMenuPermissionInputInterface,
} from './interfaces'

export const backofficeUpdateBackofficeTeamMenuPermission: TypedDocumentNode<
  BackofficeUpdateBackofficeTeamMenuPermissionDataInterface,
  BackofficeUpdateBackofficeTeamMenuPermissionInputInterface
> = gql`
  mutation ($input: BackofficeUpdateBackofficeTeamMenuPermissionInput!) {
    backofficeUpdateBackofficeTeamMenuPermission(input: $input) {
      code
      message
      data
    }
  }
`

export default backofficeUpdateBackofficeTeamMenuPermission
