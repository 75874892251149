import { useRecoilValue } from 'recoil'

import AppHook from './App.hook'

import MainApp from './layouts'
import Routes from './Routes'
import userAtom from './recoil/user'
import ConfirmModal from './components/common/ConfirmModal'
import LoadingBootstrap from './components/common/LoadingBootstrap'
import Loading from './components/common/Loading'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(localeData)
dayjs.extend(weekday)

function App() {
  const { isBootstrapping } = AppHook()
  const user = useRecoilValue(userAtom)

  return (
    <>
      {isBootstrapping ? (
        <LoadingBootstrap />
      ) : (
        <MainApp hasSideBar={user.isAuth} user={user}>
          <Loading />
          <Routes />
          <ConfirmModal />
        </MainApp>
      )}
    </>
  )
}

export default App
