import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthPartnerByIdDataInterface,
  BackofficeGetActiveHealthPartnerByIdInputInterface,
} from './interface'

const backofficeGetActiveHealthPartnerById: TypedDocumentNode<
  BackofficeGetActiveHealthPartnerByIdDataInterface,
  BackofficeGetActiveHealthPartnerByIdInputInterface
> = gql`
  query backofficeGetActiveHealthPartnerById($input: GetActiveHealthPartnerInput!) {
    backofficeGetActiveHealthPartnerById(input: $input) {
      id
      displayNameEn
      displayNameTh
      isActive
      logoImageUrl
      activeHealthPartnerCategory {
        id
        displayNameEn
      }
      createdByText
      updatedByText
      updatedAt
    }
  }
`

export default backofficeGetActiveHealthPartnerById
