import styled from '@emotion/styled'

export const Container = styled('div')`
  margin: 24px auto;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 32px;
  width: 100%;
  position: relative;
  user-select: none;
`
