import { Modal } from 'antd'
import styled from '@emotion/styled'

export const ModalAntd = styled(Modal)`
  .ant-btn-default {
    box-shadow: none;
    height: auto;
    padding: 10px 16px;
    border-radius: 8px;
    font-weight: 600;
  }

  .ant-btn-primary {
    box-shadow: none;
    height: auto;
    padding: 10px 16px;
    border-radius: 8px;
    font-weight: 600;
  }

  .ant-btn-primary:disabled {
    background-color: #ededf0;
    color: #ffffff;
    border: unset;
  }

  .ant-modal-content {
    padding: 0 !important;
  }

  .ant-modal-header {
    padding: 20px 24px;
    border-bottom: 1px solid #e5e5e5;
  }

  .ant-modal-title {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  .ant-modal-close {
    top: 20px !important;
    right: 26px !important;

    .ant-modal-close-x {
      color: #adadad !important;
    }
  }

  .ant-modal-body {
    padding: 24px;
  }

  .ant-modal-footer {
    margin-top: 0px;
    padding: 16px 24px;
    border-top: 1px solid #e5e5e5;
  }
`
