import { Button } from 'antd'
import styled from '@emotion/styled'

const getDropzoneContainerBorderColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676'
  }

  if (props.isDragReject) {
    return '#ff1744'
  }

  if (props.isDragActive) {
    return '#009641'
  }

  return '#cccccc'
}

const getDropzoneContainerBackgroundColor = (props: any) => {
  if (props.isDragReject) {
    return '#fcb6c4'
  }

  if (props.isDragActive) {
    return '#e5f4eb'
  }

  return '#fafafa'
}

export const Container = styled('div')`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 100%;
  max-width: 776px;
  padding: 24px;
  margin: auto;
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 20px;
`

export const DropzoneContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: ${(props) => getDropzoneContainerBorderColor(props)};
  border-style: dashed;
  background-color: ${(props) => getDropzoneContainerBackgroundColor(props)};
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  padding: 32px;
  cursor: pointer;
`

export const UploadFileIcon = styled('img')`
  color: #83d4a0;
  font-size: 20px;
`
export const UploadFileTitle = styled('div')`
  color: #000000;
  font-size: 14px;
  line-height: 16px;
  margin-top: 22px;
`
export const UploadFileSubtitle = styled('div')`
  color: #999999;
  font-size: 14px;
  line-height: 16px;
  margin-top: 4px;
`

export const ProgressContainer = styled('div')`
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
`

export const ProgressInfoBlock = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 10px 24px;
`

export const FileName = styled('div')`
  font-size: 16px;
  line-height: 18px;
  color: #000000;
`

export const DeleteIcon = styled('img')`
  height: 20px;
  width: 20px;
`

export const ImportButtonContainer = styled('div')`
  padding-top: 24px;
  text-align: right;
`

export const ImportButton = styled(Button)``

export const FailIcon = styled('img')`
  margin-right: 5px;
  width: 16px;
`

export const UploadFailContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`
