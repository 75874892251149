import _ from 'lodash'
import Table from '@/components/common/Table'
import RecentActivityTableHook from './RecentActivityTable.hook'
import {
  Container,
  Title,
  Block,
  ChooseDateButton,
  CalendarIcon,
  DatePickerStyles,
  ButtonContainer,
  DatePickerExtraFooter,
  Circle,
  FooterContainer,
  FooterText,
  ActiveBonusIcon,
  DatePickerCellInner,
  highlightColor,
  ActiveBonusIconDateCellStyles,
  FooterBlock,
  DatePicker,
} from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'

function RecentActivityTable() {
  const {
    vt,
    isDatePickerOpen,
    handleDatePicker,
    highlightDays,
    datePickerGetPopupContainer,
    datePickerRef,
    columns,
    dataUserExerciseHistory,
    loadingBackofficeGetUserExerciseHistoryQuery,
    handlePanelChange,
    selectedDate,
    handleSelectDate,
    isIconWellPoint,
    isRenderPagination,
    tablePaginationConfig,
  } = RecentActivityTableHook()

  return (
    <Container>
      <Block>
        <Title>การออกกำลังกายล่าสุด</Title>
        <ButtonContainer
          id="date-picker-container"
          data-testid="policy-detail-exercise-tab-activity-datepicker"
          ref={datePickerRef}
        >
          <ChooseDateButton
            onClick={() => handleDatePicker(!isDatePickerOpen)}
            data-testid="policy-detail-exercise-tab-activity-choose-date-button"
          >
            <CalendarIcon src={IMAGE_URL.calendarIcon} /> เลือกวัน
          </ChooseDateButton>
          <DatePicker
            mode="date"
            className={DatePickerStyles}
            open={isDatePickerOpen}
            getPopupContainer={datePickerGetPopupContainer}
            onPanelChange={handlePanelChange}
            value={selectedDate}
            onChange={(value) => {
              if (value) {
                // REMIND: add @is-ignore because Ant Design still used day.js dependency of themself
                // day.js version ant design version may not compatible with day.js version in my project
                // when project running will found error TS2345: 'Dayjs' is missing the following properties from type 'Dayjs': utc, local, isUTC, weekday, localeData
                // @ts-ignore
                handleSelectDate(value)
              }
            }}
            cellRender={(current) => {
              const day = _.find(highlightDays, {
                date: current.date(),
                month: current.month(),
                year: current.year(),
              })

              if (day) {
                let backgroundColor = '#ffffff'

                if (day.point === 1) {
                  backgroundColor = highlightColor.one.backgroundColor || '#ffffff'
                }

                if (day.point === 2) {
                  backgroundColor = highlightColor.two.backgroundColor || '#ffffff'
                }

                return (
                  <DatePickerCellInner
                    className="ant-picker-cell-inner"
                    isHighlight={true}
                    backgroundColor={backgroundColor}
                    data-testid={`policy-detail-exercise-tab-activity-datepicker-date-cell-${current.date()}`}
                  >
                    {day.activeBonus && day.activeBonus > 0 ? (
                      <ActiveBonusIcon
                        src={IMAGE_URL.wellBeingIcon}
                        className={ActiveBonusIconDateCellStyles}
                      />
                    ) : null}
                    {current.date()}
                  </DatePickerCellInner>
                )
              }

              // non exercise date
              return (
                <DatePickerCellInner
                  className="ant-picker-cell-inner"
                  data-testid={`policy-detail-exercise-tab-activity-datepicker-date-cell-${current.date()}`}
                >
                  {current.date()}
                </DatePickerCellInner>
              )
            }}
            renderExtraFooter={() => (
              <DatePickerExtraFooter>
                <FooterContainer isIconWellPoint={isIconWellPoint}>
                  <FooterBlock>
                    <Circle backgroundColor={highlightColor.two.backgroundColor} />
                    <FooterText>2 คะแนน</FooterText>
                  </FooterBlock>
                  <FooterBlock>
                    <Circle backgroundColor={highlightColor.one.backgroundColor} />
                    <FooterText>1 คะแนน</FooterText>
                  </FooterBlock>
                  {isIconWellPoint ? (
                    <FooterBlock>
                      <ActiveBonusIcon src={IMAGE_URL.wellBeingIcon} />
                      <FooterText>1 คะแนน</FooterText>
                    </FooterBlock>
                  ) : null}
                </FooterContainer>
              </DatePickerExtraFooter>
            )}
            showToday={false}
          />
        </ButtonContainer>
      </Block>
      <Table
        components={isRenderPagination ? undefined : vt}
        columns={columns.filter((column) => column.width !== 0)}
        dataSource={dataUserExerciseHistory}
        scroll={{
          x: '100vw',
          y: 500,
        }}
        loading={loadingBackofficeGetUserExerciseHistoryQuery}
        pagination={isRenderPagination ? tablePaginationConfig : false}
        data-testid="policy-detail-exercise-tab-activity-table"
      />
    </Container>
  )
}

export default RecentActivityTable
