import { useNavigate } from 'react-router-dom'

import { Title, Container, Button, SubContainer } from './styles'
import CONSTANT from '@/constant'

function NotFoundPage() {
  const navigate = useNavigate()

  return (
    <Container>
      <SubContainer>
        <Title>404 - Page Not Found</Title>
        <Button onClick={() => navigate(CONSTANT.PATHNAME.POLICY_DETAIL)}>กลับสู่หน้าหลัก</Button>
      </SubContainer>
    </Container>
  )
}

export default NotFoundPage
