import dayjs from 'dayjs'
import 'dayjs/locale/th'
import { Form } from 'antd'
import {
  BlackSubtitle,
  BottomLine,
  Button,
  Container,
  Information,
  Input,
  SubmitContainer,
  Subtitle,
  TextArea,
  Title,
  DatePicker,
  FormItem,
  Hint,
  ErrorMessage,
  FieldTitle,
} from './styles'
import WelcomeCallInformationHook from './WelcomeCallInformation.hook'
import { datePickerTh } from '@/components/common/BuddhistDatePicker/config'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'

function WelcomeCallInformation() {
  const {
    welcomeCall,
    disableEditButton,
    onFinish,
    onFieldsChange,
    user,
    updateWelcomeCallLoading,
  } = WelcomeCallInformationHook()

  return (
    <Form
      initialValues={{
        ...welcomeCall,
        watchDeliveredDate: welcomeCall.watchDeliveredDate
          ? dayjs(welcomeCall.watchDeliveredDate)
          : '',
      }}
      onFieldsChange={(_, allFields) => onFieldsChange(allFields)}
      onFinish={(values) => onFinish(values)}
      data-testid="welcome-call-sidebar-information-form"
    >
      <Container>
        <Title>ข้อมูลผู้เอาประกัน</Title>
        <Subtitle>เลขที่กรมธรรม์</Subtitle>
        <Information data-testid="welcome-call-sidebar-policyNumber">
          {welcomeCall.policyNumber}
        </Information>
        <Subtitle>ชื่อผู้เอาประกัน</Subtitle>
        <Information data-testid="welcome-call-sidebar-insuredName">
          {welcomeCall.insuredName}
        </Information>
        <Subtitle>แผนประกัน</Subtitle>
        <Information data-testid="welcome-call-sidebar-planCode">
          {welcomeCall.planCode}
        </Information>
        <Subtitle>วันเริ่มคุ้มครอง</Subtitle>
        <Information data-testid="welcome-call-sidebar-effectiveDate">
          {dayjs(welcomeCall.effectiveDate).format('DD-MM-YYYY')}
        </Information>
        <Subtitle>หมายเหตุจาก PV</Subtitle>
        <Information data-testid="welcome-call-sidebar-remarkPolicy">
          {welcomeCall.remarkPolicy}
        </Information>
        <BottomLine />

        <Title>ข้อมูลการติดต่อ</Title>
        <FieldTitle>เบอร์โทรศัพท์</FieldTitle>
        <FormItem
          name="phoneNumber"
          rules={[
            {
              required: !!welcomeCall.phoneNumber,
              pattern: /^[0]\d{8,9}$/g,
              message: <ErrorMessage>กรุณาระบุเบอร์โทร</ErrorMessage>,
            },
          ]}
        >
          <Input data-testid="welcome-call-sidebar-phoneNumber" />
        </FormItem>
        <FieldTitle>ที่อยู่จัดส่งนาฬิกา</FieldTitle>
        <FormItem
          name="address"
          rules={[
            {
              required: !!welcomeCall.address,
              message: <ErrorMessage>กรุณาระบุที่อยู่จัดส่ง</ErrorMessage>,
            },
          ]}
        >
          <TextArea data-testid="welcome-call-sidebar-address" />
        </FormItem>
        <BottomLine />

        <Title>ข้อมูลการซื้อประกัน</Title>
        <Subtitle>ช่องทางการซื้อ</Subtitle>
        <Information data-testid="welcome-call-sidebar-purchaseChannel">
          {welcomeCall.purchaseChannel}
        </Information>
        <Subtitle>ช่องทางการจ่ายเงิน</Subtitle>
        <Information data-testid="welcome-call-sidebar-paymentMethod">
          {welcomeCall.paymentMethod}
        </Information>
        <BottomLine />

        <Title>ข้อมูลนาฬิกา</Title>
        <FormItem name="watchSerialNumber">
          <Input placeholder="S/N นาฬิกา" data-testid="welcome-call-sidebar-watchSerialNumber" />
        </FormItem>
        <BottomLine />

        <Title>ข้อมูลการจัดส่ง</Title>
        <BlackSubtitle>จัดส่งผ่าน</BlackSubtitle>
        <FormItem
          name="watchDeliveredBy"
          rules={[
            {
              required: !!welcomeCall.watchDeliveredBy,
              message: <ErrorMessage>กรุณาระบุผู้ให้บริการจัดส่ง</ErrorMessage>,
            },
          ]}
        >
          <Input data-testid="welcome-call-sidebar-watchDeliveryBy" />
        </FormItem>
        <BlackSubtitle>เลขติดตามสถานะพัสดุ</BlackSubtitle>
        <FormItem
          name="watchDeliveredTrackingNo"
          rules={[
            {
              required: !!welcomeCall.watchDeliveredTrackingNo,
              message: <ErrorMessage>กรุณาระบุเลขติดตามสถานะพัสดุ</ErrorMessage>,
            },
          ]}
        >
          <Input data-testid="welcome-call-sidebar-watchDeliveredTrackingNo" />
        </FormItem>
        <BlackSubtitle>วันที่จัดส่ง</BlackSubtitle>
        <FormItem
          name="watchDeliveredDate"
          sixMarginBottom={true}
          rules={[
            {
              required: !!welcomeCall.watchDeliveredDate,
              message: <ErrorMessage>กรุณาระบุวันที่จัดส่ง</ErrorMessage>,
            },
          ]}
        >
          <DatePicker
            format="DD/MM/BBBB"
            locale={datePickerTh}
            data-testid="welcome-call-sidebar-watchDeliveredDate"
          />
        </FormItem>
        <Hint>วัน/เดือน/ปี พ.ศ. เช่น 12/09/2566</Hint>

        <BlackSubtitle>หมายเหตุ</BlackSubtitle>
        <FormItem name="remark">
          <TextArea data-testid="welcome-call-sidebar-remark" />
        </FormItem>
      </Container>
      {validatePerm([BACKOFFICE_USER_PERMISSION.WEL010203], user.permissions) ? (
        <SubmitContainer>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={disableEditButton}
              loading={updateWelcomeCallLoading}
              data-testid="welcome-call-sidebar-submit-button"
            >
              บันทึก
            </Button>
          </FormItem>
        </SubmitContainer>
      ) : undefined}
    </Form>
  )
}

export default WelcomeCallInformation
