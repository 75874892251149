import styled from '@emotion/styled'
import CommonButton from '@/components/common/Button'
import { ContainerProps } from './types'

export const Container = styled('div')<ContainerProps>`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 730px;
  padding: 60px 120px;
  text-align: center;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export const ForgotPasswordIcon = styled('img')`
  margin-bottom: 16px;
  margin-left: 15px;
  width: 96px;
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  margin-bottom: 8px;
`
export const Subtitle = styled('div')`
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #666666;
`

export const Button = styled(CommonButton)`
  width: 156px;
  margin-top: 32px;
`
