import styled from '@emotion/styled'
import { Input as AntInput, Form as AntForm, Alert as AntAlert } from 'antd'
import CommonButton from '@/components/common/Button'

import { ContainerProps } from './types'

export const Container = styled('div')<ContainerProps>`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 72px 120px;
  width: 720px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`
export const Title = styled('div')`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  margin-bottom: 8px;
`

export const Subtitle = styled('div')`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #666666;
  margin-bottom: 16px;
`

export const Input = styled(AntInput)`
  border-radius: 4px;

  .ant-form-item {
    margin-bottom: 0px;
  }
`

export const Button = styled(CommonButton)`
  width: 100%;
`

export const Form = styled(AntForm)``

export const FormItem = styled(AntForm.Item)`
  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
`

export const Alert = styled(AntAlert)`
  margin-top: 16px;
  border: 0px;
  border-radius: 4px;
`
