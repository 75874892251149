import { atom } from 'recoil'

import { DeviceDetailProps } from './types'

const initDeviceDetail = {
  id: null,
  deviceId: null,
  imei: null,
  currentMode: null,
  currentStatus: null,
  policyNumber: null,
  createdAt: null,
  updatedAt: null,
  unboundAt: null,
  vendor: null,
  eSimNumber: null,
  broker: null,
} as DeviceDetailProps

const deviceDetailAtom = atom({
  key: 'deviceDetailAtom',
  default: initDeviceDetail,
})

export default deviceDetailAtom
