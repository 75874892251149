import { Radio, Form } from 'antd'
import ModalAddUserHook from './ModalAddUser.hook'
import { FormItem, FormItemWrapper, Input, InputEmail, Select } from './styles'
import { ModeUserEnum } from '@/recoil/userModal/types'
import Modal from '@/components/common/Modal'

const ModalAddUser = () => {
  const {
    form,
    isVisible,
    onCancelFunction,
    title,
    teamOptions,
    loadGetAllBackofficeTeamQuery,
    mode,
    roleList,
    handleOnSubmit,
    disabledSave,
    handleFormChange,
    okButtonProps,
    isDisabledTeamOptions,
  } = ModalAddUserHook()

  return (
    <Modal
      title={title}
      open={isVisible}
      closable={false}
      cancelText="ยกเลิก"
      okText="บันทึก"
      okButtonProps={{ ...okButtonProps, disabled: disabledSave }}
      onOk={handleOnSubmit}
      onCancel={onCancelFunction}
      width={640}
      data-testid="user-info-modal"
    >
      <Form layout="vertical" form={form} onFieldsChange={handleFormChange}>
        <FormItem label="บทบาท" name="roleId">
          <Radio.Group
            defaultValue={roleList && roleList[0].id}
            data-testid="user-info-modal-user-role-radio-group"
          >
            {roleList && roleList?.length > 0
              ? roleList?.map((role, index) => {
                  return (
                    <Radio
                      key={role.id}
                      value={role.id}
                      data-testid={`user-info-modal-user-role-radio-${index}`}
                    >
                      {role.title}
                    </Radio>
                  )
                })
              : null}
          </Radio.Group>
        </FormItem>
        <FormItem label="ทีม" name="teamId">
          <Select
            disabled={isDisabledTeamOptions}
            defaultValue={'เลือก'}
            options={teamOptions}
            loading={loadGetAllBackofficeTeamQuery}
            data-testid="user-info-modal-user-team-select"
          />
        </FormItem>
        <FormItemWrapper>
          <FormItem label="ชื่อ" name="name">
            <Input placeholder="ระบุชื่อ" data-testid="user-info-modal-user-name-input" />
          </FormItem>
          <FormItem label="นามสกุล" name="lastName">
            <Input placeholder="ระบุนามสกุล" data-testid="user-info-modal-user-surname-input" />
          </FormItem>

          {mode === ModeUserEnum.CREATE ? (
            <>
              <FormItem
                label="อีเมล"
                name="email"
                help="ระบบจะทำการส่งข้อมูลเปิดใช้งานไปยังอีเมลที่คุณระบุ"
              >
                <InputEmail
                  placeholder="email"
                  addonAfter="@thaivivat.co.th"
                  data-testid="user-info-modal-user-email-input"
                />
              </FormItem>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  return (
                    <FormItem
                      label="ยืนยันอีเมล"
                      name="confirmEmail"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณากรอกอีเมลอีกครั้ง เพื่อยืนยันอีเมล',
                        },
                        () => ({
                          validator(_, value) {
                            const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
                            const emailValidatePass = regexEmail.test(value)

                            if (emailValidatePass && getFieldValue('email') !== value) {
                              return Promise.reject(
                                new Error('อีเมลไม่ตรงกัน กรุณาตรวจสอบอีกครั้ง'),
                              )
                            }

                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <InputEmail
                        placeholder="ระบุยืนยันอีเมล "
                        addonAfter="@thaivivat.co.th"
                        data-testid="user-info-modal-user-confirm-email-input"
                      />
                    </FormItem>
                  )
                }}
              </Form.Item>
              <FormItem label="เบอร์โทรศัพท์" name="phone">
                <Input
                  placeholder="ระบุเบอร์โทรศัพท์"
                  type="tel"
                  maxLength={10}
                  data-testid="user-info-modal-user-mobile-input"
                />
              </FormItem>
            </>
          ) : null}

          {mode === ModeUserEnum.UPDATE ? (
            <>
              <FormItem label="เบอร์โทรศัพท์" name="phone">
                <Input
                  placeholder="ระบุเบอร์โทรศัพท์"
                  type="tel"
                  maxLength={10}
                  data-testid="user-info-modal-user-mobile-input"
                />
              </FormItem>
              <FormItem label="อีเมล" name="email">
                <Input
                  placeholder="email"
                  disabled
                  data-testid="user-info-modal-user-email-input"
                />
              </FormItem>
            </>
          ) : null}
        </FormItemWrapper>
      </Form>
    </Modal>
  )
}

export default ModalAddUser
