import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeGetWelcomeCallFilterDateWithCountCallStatusDataInterface } from './interfaces'

const backofficeGetWelcomeCallFilterDateWithCountCallStatus: TypedDocumentNode<BackofficeGetWelcomeCallFilterDateWithCountCallStatusDataInterface> = gql`
  query BackofficeGetWelcomeCallFilterDateWithCountCallStatus {
    backofficeGetWelcomeCallFilterDateWithCountCallStatus {
      date
      complete
      total
    }
  }
`

export default backofficeGetWelcomeCallFilterDateWithCountCallStatus
