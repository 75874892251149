import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeUpdateUserDataInterface, BackofficeUpdateUserInputInterface } from './interfaces'

export const backofficeUpdateUser: TypedDocumentNode<
  BackofficeUpdateUserDataInterface,
  BackofficeUpdateUserInputInterface
> = gql`
  mutation BackofficeUpdateUser($input: BackofficeUpdateUserInput!) {
    backofficeUpdateUser(input: $input) {
      id
      thaiCitizen {
        identityId
      }
      foreignCitizen {
        passportId
      }
      firstname
      lastname
      email
      isConfirmEmail
      mobileNumber
      createdAt
      deleteRequestedAt
    }
  }
`
export default backofficeUpdateUser
