import {
  Button,
  Icon,
  InformationContainer,
  Skeleton,
  SkeletonButton,
  Info,
  Title,
  TitleBlock,
  InfoTitle,
  RightItemContainer,
} from './styles'
import DetailCard from '../DetailCard'
import InformationGroup from '../InformationGroup'
import TviConnectCardHook from './TviConnectCard.hook'
import CONSTANT from '@/constant'
import {
  SimStatusEnum,
  TrueJasperSimStatusEnum,
  TviConnectPartnerEnum,
} from '@/constant/SIM_STATUS'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { IMAGE_URL } from '@/constant/IMAGE'
import Modal from '@/components/common/Modal'
import { CloseOutlined } from '@ant-design/icons'

function TviConnectCard({ isEv }: { isEv?: boolean }) {
  const {
    deviceDetail,
    onModalVisible,
    onModalResetDeviceIdVisible,
    tviConnectMainPackage,
    onModalChangeTviConnectMainPackageVisible,
    user,
    unboundPpuMotorUserPolicyMotorLoading,
    resetDeviceIdLoading,
    changeTviConnectMainPackageLoading,
    tviConnectMainPackageLoading,
    visible,
    displaySimStatus,
    handleDisplaySimStatusModal,
    isOpenModalSimStatusInfo,
  } = TviConnectCardHook()
  const { FontColorEnum } = CONSTANT
  const simStatus = displaySimStatus(tviConnectMainPackage)
  const isSimActive = simStatus === 'SIM เปิดใช้งานแล้ว'

  return (
    <DetailCard
      title="TVI-Connect"
      visible={visible}
      dataTestId="policy-detail-tvi-connect-card"
      rightItem={
        <RightItemContainer>
          {tviConnectMainPackageLoading ? (
            <>
              <SkeletonButton active />
              <SkeletonButton active />
              <SkeletonButton active />
            </>
          ) : (
            deviceDetail.id && (
              <>
                {!isSimActive &&
                validatePerm([BACKOFFICE_USER_PERMISSION.MTP010102], user.permissions) ? (
                  <Button
                    color={FontColorEnum.GREEN}
                    onClick={onModalChangeTviConnectMainPackageVisible}
                    loading={changeTviConnectMainPackageLoading}
                    data-testid="policy-detail-tvi-connect-card-change-main-package-button"
                  >
                    <Icon src={IMAGE_URL.simIcon} />
                    เปิดใช้งาน SIM
                  </Button>
                ) : undefined}
                {deviceDetail.vendor === TviConnectPartnerEnum.AIS &&
                validatePerm([BACKOFFICE_USER_PERMISSION.MTP010103], user.permissions) ? (
                  <Button
                    color={FontColorEnum.RED}
                    onClick={onModalResetDeviceIdVisible}
                    loading={resetDeviceIdLoading}
                    data-testid="policy-detail-tvi-connect-card-reset-device-button"
                  >
                    <Icon src={IMAGE_URL.resetDeviceIcon} />
                    รีเซ็ตอุปกรณ์
                  </Button>
                ) : undefined}
                {validatePerm([BACKOFFICE_USER_PERMISSION.MTP010104], user.permissions) ? (
                  <Button
                    color={FontColorEnum.RED}
                    onClick={onModalVisible}
                    loading={unboundPpuMotorUserPolicyMotorLoading}
                    data-testid="policy-detail-tvi-connect-card-unbound-device-button"
                  >
                    <Icon src={IMAGE_URL.unboundDeviceIcon} />
                    ยกเลิกการผูก
                  </Button>
                ) : undefined}
              </>
            )
          )}
        </RightItemContainer>
      }
    >
      {tviConnectMainPackageLoading ? (
        <Skeleton active />
      ) : (
        <>
          <InformationContainer marginTop={'32px'} isEv={isEv}>
            <InformationGroup
              information={{
                title: 'อุปกรณ์ค่าย',
                detail: deviceDetail.vendor || '-',
              }}
            />
            <InformationGroup
              information={{
                title: 'ชื่อแพ็กเกจ',
                detail: tviConnectMainPackage.packageName || '-',
              }}
            />
          </InformationContainer>
          <InformationContainer marginTop={'unset'} isEv={isEv}>
            <InformationGroup
              information={{
                title: 'Serial no.',
                detail: deviceDetail.serialNumber || '-',
              }}
              tooltip={!!tviConnectMainPackage.checksumDigit}
              tooltipMessage={tviConnectMainPackage.checksumDigit || undefined}
            />
            <InformationGroup
              information={{
                title: 'eSim no.',
                detail: deviceDetail.eSimNumber ?? '-',
              }}
            />
          </InformationContainer>
          <InformationContainer marginTop={'unset'} isEv={isEv}>
            <InformationGroup
              information={{
                title: 'IMEI',
                detail: deviceDetail.imei || '-',
              }}
            />
            <InformationGroup
              information={{
                title: 'Device id',
                detail: deviceDetail.deviceId || '-',
              }}
            />
          </InformationContainer>
          <InformationContainer marginTop={'unset'} isEv={isEv}>
            <InformationGroup
              information={{
                title: 'วันที่สร้าง',
                detail: deviceDetail?.createdAt?.toString() || '-',
              }}
            />
            <InformationGroup
              information={{
                title: 'แก้ไขล่าสุด',
                detail: deviceDetail?.updatedAt?.toString() || '-',
              }}
            />
          </InformationContainer>

          <InformationContainer marginTop={'unset'} isEv={isEv}>
            <InformationGroup
              information={{
                title: 'สถานะ SIM',
                detail: simStatus,
              }}
              fontColor={isSimActive ? FontColorEnum.GREEN : FontColorEnum.RED}
              infoIcon={true}
              infoIconFunction={() => handleDisplaySimStatusModal(true)}
            />
            <InformationGroup
              information={{
                title: 'Device Type',
                detail: tviConnectMainPackage.deviceType || 'ไม่พบชนิดอุปกรณ์',
              }}
            />
          </InformationContainer>

          <InformationContainer marginTop={'unset'} isEv={isEv}>
            <InformationGroup
              information={{
                title: 'Broker',
                detail: deviceDetail.broker || '-',
              }}
              marginBottomUnset={true}
            />
          </InformationContainer>
        </>
      )}
      <Modal
        title="รายละเอียดสถานะ SIM"
        open={isOpenModalSimStatusInfo}
        closeIcon={<CloseOutlined />}
        onCancel={() => handleDisplaySimStatusModal(false)}
        footer={null}
      >
        <TitleBlock>
          <Title>
            <InfoTitle>อุปกรณ์ค่าย:</InfoTitle> {tviConnectMainPackage.partner}
          </Title>
          <Title>
            <InfoTitle>สถานะ SIM จากผู้ให้บริการ:</InfoTitle> {tviConnectMainPackage.status}
          </Title>
          <Title>
            <InfoTitle>ชื่อแพ็กเกจ:</InfoTitle> {tviConnectMainPackage.packageName}
          </Title>
        </TitleBlock>

        <Info>
          <div>รายการสถานะ SIM จากค่ายผู้ให้บริการ</div>
          <div>ค่าย AIS</div>
          <div>
            <span style={{ color: 'red' }}>**</span>
            {SimStatusEnum.TEST}: SIM เปิดใช้งานแล้ว
          </div>
          <div>{SimStatusEnum.SIM_READY}: ยังไม่ถูกลงทะเบียน</div>
          <div>
            <span style={{ color: 'red' }}>**</span>
            {SimStatusEnum.COMMERCIAL}: SIM เปิดใช้งานแล้ว
          </div>
          <div>{SimStatusEnum.SUSPEND}: SIM ถูกระงับการใช้งาน</div>
          <div>{SimStatusEnum.NOT_FOUND}: ไม่พบ SIM</div>
          <div style={{ marginBottom: '16px' }}>
            {SimStatusEnum.TERMINATE}: SIM ถูกยุติการใช้งาน
          </div>

          <div>ค่าย TRUE</div>
          <div>{TrueJasperSimStatusEnum.ACTIVATED}: SIM เปิดใช้งานแล้ว</div>
          <div>{TrueJasperSimStatusEnum.ACTIVATION_READY}: ยังไม่ถูกลงทะเบียน</div>
          <div>{TrueJasperSimStatusEnum.TEST_READY}: ยังไม่ถูกลงทะเบียน</div>
          <div>{TrueJasperSimStatusEnum.INVENTORY}: ยังไม่ถูกลงทะเบียน</div>
          <div>{TrueJasperSimStatusEnum.DEACTIVATED}: ยังไม่ถูกลงทะเบียน</div>
          <div>{TrueJasperSimStatusEnum.RETIRED}: SIM ถูกยกเลิกการใช้งาน</div>
          <div>{TrueJasperSimStatusEnum.PURGED}: PURGED</div>
          <div>{TrueJasperSimStatusEnum.REPLACED}: REPLACED</div>
          <div style={{ color: 'red' }}>
            **สถานะ SIM อาจจะแสดงไม่ถูกต้อง ขึ้นอยู่กับแพ็กเกจของอุปกรณ์
          </div>
        </Info>
      </Modal>
    </DetailCard>
  )
}

export default TviConnectCard
