import { useEffect, useState } from 'react'
import { Form } from 'antd'

import { useResendPolicy } from '@/services/order/useResendPolicy'

const TaTransactionResendPolicyModalHook = ({
  policyMail,
  applicationFormId,
}: {
  policyMail: string
  applicationFormId: string
}) => {
  const [form] = Form.useForm<{ email: string }>()
  const [email, setEmail] = useState<string>(policyMail)

  const validateEmail = (_: any, value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!value || emailRegex.test(value)) {
      return Promise.resolve()
    }
    return Promise.reject('ข้อมูลไม่ถูกต้อง')
  }

  const useResendEmail = () => {
    const mutation = useResendPolicy(email, applicationFormId)

    return mutation
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  useEffect(() => {
    form.setFieldValue('email', policyMail)
    setEmail(policyMail)
  }, [form, policyMail])

  return { form, validateEmail, useResendEmail, handleEmailChange }
}

export default TaTransactionResendPolicyModalHook
