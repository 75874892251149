import { atom } from 'recoil'

import { ResetWatchDeviceModalProps } from './types'

const initResetWatchDeviceModal: ResetWatchDeviceModalProps = {
  id: undefined,
  open: false,
  isRefetchWatchDevice: false,
}

const resetWatchDeviceModalAtom = atom({
  key: 'resetWatchDeviceModalAtom',
  default: initResetWatchDeviceModal,
})

export default resetWatchDeviceModalAtom
