import styled from '@emotion/styled'
import { Form as AntForm, Input as AntInput, Checkbox as AntCheckbox } from 'antd'
import { Link } from 'react-router-dom'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')`
  height: 446px;
  width: 920px;
  margin: auto;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  box-shadow: 0px 5px 20px #bbbbbb;
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  margin-bottom: 24px;
`

export const Form = styled(AntForm)`
  width: 556px;
  padding: 0px 107px;
`

export const FormItem = styled(AntForm.Item)`
  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
`

export const Input = styled(AntInput)`
  border-radius: 4px;
`

export const InputPassword = styled(AntInput.Password)`
  border-radius: 4px;
`

export const Checkbox = styled(AntCheckbox)``

export const ForgotPasswordLink = styled(Link)`
  float: right;
  font-size: 16px;

  :hover {
    color: #009640;
  }
`

export const Button = styled(CommonButton)`
  width: 100%;
`

export const FormRight = styled('div')<{ backgroundImage: string }>`
  height: 446px;
  width: 364px;

  display: flex;
  position: relative;

  border-radius: 0 6px 6px 0px;
  background-image: url('${(props) => props.backgroundImage}');
`

export const FormRightLoginLogo = styled('img')`
  height: 104px;
  width: 104px;
  margin: auto;
`

export const AppVersionContainer = styled('div')`
  text-align: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

export const AppVersion = styled('div')`
  font-size: 12px;
  color: #5b5b5b;
`

export const KeyIcon = styled('img')`
  height: 16px;
  width: 16px;
`
