import { gql, TypedDocumentNode } from '@apollo/client'
import {
  CreateBackofficeFAQCategoryDataInterface,
  CreateBackofficeFAQCategoryInputInterface,
} from './interfaces'

export const createBackofficeFAQCategory: TypedDocumentNode<
  CreateBackofficeFAQCategoryDataInterface,
  CreateBackofficeFAQCategoryInputInterface
> = gql`
  mutation CreateBackofficeFAQCategory($input: CreateBackofficeFAQCategoryActiveHealthInput!) {
    createBackofficeFAQCategory(input: $input) {
      id
      title
      faqModuleId
    }
  }
`

export default createBackofficeFAQCategory
