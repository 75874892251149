import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthDailySummaryByWeekIdInputDataInterface,
  BackofficeGetActiveHealthDailySummaryByWeekIdInputInputInterface,
} from './interfaces'

const backofficeGetActiveHealthDailySummaryByWeekIdInput: TypedDocumentNode<
  BackofficeGetActiveHealthDailySummaryByWeekIdInputDataInterface,
  BackofficeGetActiveHealthDailySummaryByWeekIdInputInputInterface
> = gql`
  query BackofficeGetActiveHealthDailySummaryByWeekId(
    $input: BackofficeGetActiveHealthDailySummaryByWeekIdInput!
  ) {
    backofficeGetActiveHealthDailySummaryByWeekId(input: $input) {
      id
      policyNumber
      date
      step
      activityDuration
      exercisePoint
      bonusPoint
      state
      createdAt
      updatedAt
    }
  }
`

export default backofficeGetActiveHealthDailySummaryByWeekIdInput
