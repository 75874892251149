import { useCallback, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useLazyQuery } from '@apollo/client'
import policyAtom from '@/recoil/policy'
import policyDetailAtom from '@/recoil/policyDetail/atom'
import activeHealthPolicy from '@/gql/activeHealthPolicy'
import resetWatchDeviceModalAtom from '@/recoil/resetWatchDeviceModal/atom'
import hardResetWatchDeviceModalAtom from '@/recoil/hardResetWatchDeviceModal/atom'

const DeviceTabHook = () => {
  const policy = useRecoilValue(policyAtom)
  const policyDetail = useRecoilValue(policyDetailAtom)
  const [resetWatch, setResetWatchDeviceModal] = useRecoilState(resetWatchDeviceModalAtom)
  const [hardResetWatch, setHardResetWatchDeviceModal] = useRecoilState(hardResetWatchDeviceModalAtom)

  const [
    getBackOfficeListUserSessionQuery,
    { loading: loadingBackOfficeListUserSessionQuery, data: dataBackOfficeListUserSessionQuery },
  ] = useLazyQuery(activeHealthPolicy.query.backofficeListUserSession, {
    fetchPolicy: 'no-cache',
  })

  const [
    getBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery,
    {
      loading: loadBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery,
      data: dataBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery,
      refetch: refetchBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery,
    },
  ] = useLazyQuery(activeHealthPolicy.query.backofficeListActiveHealthPolicyIncludeWatchBranch, {
    fetchPolicy: 'no-cache',
  })

  const getBackOfficeListDeviceTab = useCallback(
    (id: string, policyNumber: string) => {
      getBackOfficeListUserSessionQuery({
        variables: {
          id,
        },
      })

      getBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery({
        variables: {
          userId: id,
          policyNumber,
        },
      })
    },
    [getBackOfficeListUserSessionQuery, getBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery],
  )

  const getRefetchBackOfficeListDeviceTab = useCallback(() => {
    refetchBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery()
    setResetWatchDeviceModal({ isRefetchWatchDevice: false })
    setHardResetWatchDeviceModal({ isRefetchWatchDevice: false })
  }, [refetchBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery, setResetWatchDeviceModal, setHardResetWatchDeviceModal])

  useEffect(() => {
    if (policyDetail.id && policy.policyNumber) {
      getBackOfficeListDeviceTab(policyDetail.id, policy.policyNumber)
    }
  }, [getBackOfficeListDeviceTab, policyDetail.id, policy.policyNumber])

  useEffect(() => {
    if (resetWatch.isRefetchWatchDevice) {
      getRefetchBackOfficeListDeviceTab()
    }
  }, [getRefetchBackOfficeListDeviceTab, resetWatch.isRefetchWatchDevice])

  useEffect(() => {
    if (hardResetWatch.isRefetchWatchDevice) {
      getRefetchBackOfficeListDeviceTab()
    }
  }, [getRefetchBackOfficeListDeviceTab, hardResetWatch.isRefetchWatchDevice])

  return {
    dataBackOfficeListUserSessionQuery,
    loadingBackOfficeListUserSessionQuery,
    dataBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery,
    loadBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery,
  }
}

export default DeviceTabHook
