import React from 'react'

import { Container, Title, HeaderContainer, LeftItem, RightItem } from './styles'
import { DetailCardInterface } from './interfaces'

function DetailCard({ visible, title, children, rightItem, dataTestId }: DetailCardInterface) {
  return (
    <Container visible={visible} data-testid={dataTestId}>
      <HeaderContainer isFlex={!!rightItem}>
        <LeftItem>
          <Title>{title}</Title>
        </LeftItem>
        <RightItem>{rightItem}</RightItem>
      </HeaderContainer>
      {children}
    </Container>
  )
}

export default DetailCard
