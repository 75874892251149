import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetWelcomeCallPaginationDataInterface,
  BackofficeGetWelcomeCallPaginationInputInterface,
} from './interfaces'

const backofficeGetWelcomeCallPagination: TypedDocumentNode<
  BackofficeGetWelcomeCallPaginationDataInterface,
  BackofficeGetWelcomeCallPaginationInputInterface
> = gql`
  query BackofficeGetWelcomeCallPagination($input: RequestPaginationInput!) {
    backofficeGetWelcomeCallPagination(input: $input) {
      data {
        policyNumber
        referenceNumber
        insuredName
        phoneNumber
        planCode
        address
        remarkPolicy
        effectiveDate
        purchaseChannel
        paymentMethod
        watchDeliveredDate
        watchDeliveredBy
        watchDeliveredTrackingNo
        watchSerialNumber
        callStatus
        calledBy {
          firstname
          lastname
        }
        remark
        createdAt
        updatedAt
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
        sortBy
        searchBy
        search
      }
    }
  }
`

export default backofficeGetWelcomeCallPagination
