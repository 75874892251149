import styled from '@emotion/styled'
import { Skeleton as AntdSkeleton } from 'antd'
import { ContainerProps } from './types'

export const Container = styled('div')<ContainerProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export const CardContainer = styled('div')`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  cursor: pointer;
`
export const Title = styled('div')`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
`

export const InformationContainer = styled('div')`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
`

export const InformationTitle = styled('div')`
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  margin-bottom: 4px;
`

export const Information = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
`
export const InsureIcon = styled('img')`
  width: 56px;
  height: 56px;
`

export const PolicyStatusContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const SkeletonContainer = styled('div')`
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  max-height: 109px;
  align-items: center;
  justify-items: center;
`
export const Skeleton = styled(AntdSkeleton)``

export const SkeletonAvatar = styled(AntdSkeleton.Avatar)``
