import backofficeSearchActiveHealthPolicy from './backofficeSearchActiveHealthPolicy/BackofficeSearchActiveHealthPolicy.gql'
import backofficeExportSearchActiveHealthPolicy from './backofficeExportSearchActiveHealthPolicy/BackofficeExportSearchActiveHealthPolicy.gql'
import backofficeListUserSession from '@/gql/activeHealthPolicy/backofficeListUserSession/backofficeListUserSession.gql'
import backofficeListActiveHealthPolicyIncludeWatchBranch from '@/gql/activeHealthPolicy/backofficeListActiveHealthPolicyIncludeWatchBranch/backofficeListActiveHealthPolicyIncludeWatchBranch.gql'
import backofficeResetActiveHealthWearable from './backofficeResetActiveHealthWearable/backofficeResetActiveHealthWearable.gql'
import backofficeListActiveHealthBillingByUserId from './backofficeListActiveHealthBillingByUserId/backofficeListActiveHealthBillingByUserId.gql'
import backofficeListActiveHealthByBillId from './backofficeListActiveHealthByBillId/backofficeListActiveHealthByBillId.gql'
import backofficeGetActiveHealthManualDiscountPagination from './backofficeGetActiveHealthManualDiscountPagination/backofficeGetActiveHealthManualDiscountPagination.gql'
import backofficeGetActiveHealthDailySummaryByWeekIdInput from './backofficeGetActiveHealthDailySummaryByWeekId/backofficeGetActiveHealthDailySummaryByWeekId.gql'
import backofficeGetDiscountConditionByPolicyNumber from './backofficeGetDiscountConditionByPolicyNumber/backofficeGetDiscountConditionByPolicyNumber.gql'
import backofficeGetActiveHealthBillingDiscountProblemPagination from './backofficeGetActiveHealthBillingDiscountProblemPagination/backofficeGetActiveHealthBillingDiscountProblemPagination.gql'
import backofficeCountPendingActiveHealthBillingDiscountProblemRecord from './backofficeGetActiveHealthBillingDiscountProblemPagination/backofficerCountPendingActiveHealthBillingDiscountProblemRecord.gql'
import backofficeUpdateActiveHealthBillingDiscountProblemStatus from './backofficeUpdateActiveHealthBillingDiscountProblemStatus/backofficeUpdateActiveHealthBillingDiscountProblemStatus.gql'
import backofficeCreateActiveHealthManualDiscount from './backofficeCreateActiveHealthManualDiscount/backofficeCreateActiveHealthManualDiscount.gql'
import backofficeGetActiveHealthManualDiscountFile from './backofficeGetActiveHealthManualDiscountFile/backofficeGetActiveHealthManualDiscountFile.gql'
import backofficeHardResetActiveHealthWearable from './backofficeHardResetActiveHealthWearable/backofficeHardResetActiveHealthWearable.gql'

const activeHealthPolicy = {
  query: {
    backofficeSearchActiveHealthPolicy,
    backofficeExportSearchActiveHealthPolicy,
    backofficeListUserSession,
    backofficeListActiveHealthPolicyIncludeWatchBranch,
    backofficeListActiveHealthBillingByUserId,
    backofficeListActiveHealthByBillId,
    backofficeGetActiveHealthManualDiscountPagination,
    backofficeGetActiveHealthDailySummaryByWeekIdInput,
    backofficeGetDiscountConditionByPolicyNumber,
    backofficeGetActiveHealthBillingDiscountProblemPagination,
    backofficeCountPendingActiveHealthBillingDiscountProblemRecord,
    backofficeGetActiveHealthManualDiscountFile,
  },
  mutation: {
    backofficeResetActiveHealthWearable,
    backofficeUpdateActiveHealthBillingDiscountProblemStatus,
    backofficeCreateActiveHealthManualDiscount,
    backofficeHardResetActiveHealthWearable,
  },
}

export default activeHealthPolicy
