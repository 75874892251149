import { TypedDocumentNode, gql } from '@apollo/client'
import {
  BackofficeGetActiveHealthPartnerBranchPaginationWithScanCountInputInterface,
  BackofficeGetActiveHealthPartnerBranchPaginationWithScanCountDataInterface,
} from './interface'

const backofficeGetActiveHealthPartnerBranchPaginationWithScanCount: TypedDocumentNode<
  BackofficeGetActiveHealthPartnerBranchPaginationWithScanCountDataInterface,
  BackofficeGetActiveHealthPartnerBranchPaginationWithScanCountInputInterface
> = gql`
  query BackofficeGetActiveHealthPartnerBranchPaginationWithScanCount(
    $activeHealthPartnerId: ID!
    $input: RequestPaginationInput!
  ) {
    backofficeGetActiveHealthPartnerBranchPaginationWithScanCount(
      activeHealthPartnerId: $activeHealthPartnerId
      input: $input
    ) {
      data {
        activeHealthPartnerBranch {
          id
          displayNameEn
          displayNameTh
          startDate
          endDate
          createdBy {
            firstname
            lastname
          }
          createdByText
          updatedBy {
            firstname
            lastname
          }
          updatedByText
          qr {
            isActive
            imageUrl
          }
          updatedAt
          addressTh
          addressEn
          subDistrict {
            displayNameTh
            postCode
            subDistrictCode
          }
          lat
          long
          activeHealthPartnerBranchContacts {
            phoneNumber
            extensionNumber
          }
        }
        qrScanCount
        csAdjustCount
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
      }
    }
  }
`

export default backofficeGetActiveHealthPartnerBranchPaginationWithScanCount
