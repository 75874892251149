import { useMutation } from '@tanstack/react-query'
import config from '@/config'
import { httpClientPrivate } from '@/libs/httpClient'
import { UpdateApplicationFormInputType } from './types'

const updateApplicationForm = async (
  orderId: string,
  applicationFormId: string,
  data: UpdateApplicationFormInputType,
) => {
  const response = await httpClientPrivate.put(
    `${config.restUrl}/v1/backoffice-commerce/order/${orderId}/application-form/${applicationFormId}`,
    data,
  )

  return response.data
}

const useUpdateApplicationForm = (
  orderId: string,
  applicationFormId: string,
  data: UpdateApplicationFormInputType,
) => {
  const mutation = useMutation({
    mutationKey: ['useUpdateApplicationForm', orderId, applicationFormId],
    mutationFn: async () => updateApplicationForm(orderId, applicationFormId, data),
  })

  return mutation
}

export { useUpdateApplicationForm, updateApplicationForm }
