import styled from '@emotion/styled'
import { Skeleton as AntdSkeleton } from 'antd'
import { FontColorProp } from './types'
import CommonButton from '@/components/common/Button'

export const InformationContainer = styled('div')<{ marginTop?: string; isEv?: boolean }>`
  display: grid;
  grid-template-columns: repeat(${(props) => (props.isEv ? 2 : 3)}, 1fr);
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '32px')};
`

export const RightItemContainer = styled('div')`
  display: flex;
  flex-flow: wrap;
  justify-content: end;
`

export const Button = styled(CommonButton)<{ color: FontColorProp }>`
  display: flex;
  align-items: center;

  border: 1px solid #e5e5e5;
  box-sizing: border-box;

  color: ${(props) => props.color || '#000000'};
  margin-left: 8px;

  :hover {
    color: ${(props) => props.color || '#000000'};
    border-color: ${(props) => props.color || '#000000'};
  }

  :focus {
    color: ${(props) => props.color || '#000000'};
    border-color: ${(props) => props.color || '#000000'};
  }
`

export const Icon = styled('img')`
  margin-right: 9px;
`

export const Skeleton = styled(AntdSkeleton)`
  margin-top: 32px;
`

export const SkeletonButton = styled(AntdSkeleton.Button)`
  margin-left: 8px;
  width: 135px !important;
`

export const Info = styled('div')`
  font-size: 12px;
  color: #777777;
`

export const InfoTitle = styled('span')`
  color: #666666;
  font-size: 14px;
`

export const Title = styled('div')`
  font-size: 16px;
`

export const TitleBlock = styled('div')`
  margin-bottom: 16px;
`
