import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeGetAllMotorEvChargingTypesInterface } from './interfaces'

const backofficeGetAllMotorEvChargingTypes: TypedDocumentNode<BackofficeGetAllMotorEvChargingTypesInterface> = gql`
  query BackofficeGetAllMotorEvChargingTypes {
    backofficeGetAllMotorEvChargingTypes {
      id
      connectorType
      currentType
    }
  }
`

export default backofficeGetAllMotorEvChargingTypes
