import styled from '@emotion/styled'
import { Image, Skeleton as AntdSkeleton } from 'antd'
import { css } from '@emotion/css'

export const Container = styled('div')``

export const Block = styled('div')`
  margin-bottom: 16px;
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 16px;
  color: #000000;
`

export const InformationTitle = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #666666;
`

export const Information = styled('div')`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`

export const Thumbnail = styled(Image)`
  max-width: 56px;
  margin: 8px;
`

export const PdfIcon = styled('img')`
  width: 50px;
  margin-top: 8px;
`

export const DetailBlockStyle = css`
  border-bottom: 1px solid #c6c6c8;
  padding-bottom: 24px;
`

export const DetailInformationStyle = css`
  margin-bottom: 16px;
`

export const SkeletonImage = styled(AntdSkeleton.Image)`
  margin: 4px 0;
  max-width: 56px;
`
