import { atom } from 'recoil'

import { ConfirmModalProps } from './types'

const initConfirmModal = {
  isVisible: false,
  onOkFunction: Function,
  title: '',
  okText: 'ตกลง',
  bodyText: '',
  okButtonProps: { type: 'primary', danger: false },
  dataTestId: undefined,
} as ConfirmModalProps

const confirmModalAtom = atom({
  key: 'confirmModalAtom',
  default: initConfirmModal,
})

export default confirmModalAtom
