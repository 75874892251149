import styled from '@emotion/styled'
import { Input as AntInput, AutoComplete } from 'antd'
import CommonModal from '@/components/common/Modal'

export const Modal = styled(CommonModal)<any>`
  .ant-modal-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.004em;
    color: #000000;
    height: 50%;
  }

  .ant-btn-default {
    box-shadow: none;
    height: auto;
    padding: 10px 16px;
    border-radius: 8px;
  }

  .ant-btn-primary {
    box-shadow: none;
    height: auto;
    padding: 10px 16px;
    border-radius: 8px;
  }

  .ant-modal-header {
    padding: 24px;
    margin-bottom: 4px;
  }

  .ant-modal-body {
    padding: 20px 0px;
  }

  .ant-modal-content {
    padding: 0px;
  }
`

export const SearchWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 128px;
`

export const SearchInput = styled(AntInput)`
  width: 100%;
  height: 44px;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #adadad;

  .ant-input-prefix {
    margin-inline-end: 13px;
  }
`

export const AddUserButton = styled('div')`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  gap: 4px;
`

export const TextButton = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.004em;
  color: #009640;

  :hover {
    color: #1aa351;
  }

  :active {
    color: #007034;
  }
`

export const ImageIcon = styled('img')`
  width: 16px;
  height: 16px;
`

export const ListWrapper = styled('div')`
  overflow-x: hidden;
`

export const HeaderList = styled('div')`
  display: flex;

  justify-content: space-between;
`

export const TitleList = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #666666;
`

export const RemoveAllButton = styled('div')`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.004em;
  color: #adadad;
`

export const FormWrapper = styled('div')`
  padding: 16px 24px;
`

export const BodyWrapper = styled('div')`
  height: 390px;
  overflow: auto;

  /* Hide the scrollbar */

  ::-webkit-scrollbar {
    width: 0.5em;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 0.25em;
  }
`

export const SearchAutoComplete = styled(AutoComplete)`
  width: 100%;
  height: 44px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #adadad;

  .ant-input-prefix {
    margin-inline-end: 13px;
  }
`
