import { ORDER_TAB, OrderTabEnum } from '@/constant/TA_TRANSACTION'
import { Container, Title, Tab } from './styles'
import TaTransactionFilterTabHook from './TaTransactionFilterTab.hook'

function TaTransactionFilterTab() {
  const { orderTab, setOrderTab } = TaTransactionFilterTabHook()
  const tabs = [
    {
      status: OrderTabEnum.DRAFT,
      testId: 'ta-transaction-tab-draft',
    },
    {
      status: OrderTabEnum.WAITING_PAYMENT,
      testId: 'ta-transaction-tab-waiting-payment',
    },
    {
      status: OrderTabEnum.PAYMENT_FAILED,
      testId: 'ta-transaction-tab-payment-failed',
    },
    {
      status: OrderTabEnum.PAYMENT_SUCCESS,
      testId: 'ta-transaction-tab-payment-success',
    },
    {
      status: OrderTabEnum.FAIL_VERIFIED,
      testId: 'ta-transaction-tab-fail-verified',
    },
    {
      status: OrderTabEnum.CANCELLED,
      testId: 'ta-transaction-tab-cancelled',
    },
    {
      status: OrderTabEnum.ALL,
      testId: 'ta-transaction-tab-all',
    },
  ]

  return (
    <Container data-testid="ta-transaction-tab">
      {tabs.map((tab) => {
        return (
          <Tab key={tab.testId}>
            <Title
              select={orderTab === tab.status}
              onClick={() => setOrderTab(tab.status)}
              data-testid={tab.testId}
            >
              {ORDER_TAB[tab.status]}
            </Title>
          </Tab>
        )
      })}
    </Container>
  )
}

export default TaTransactionFilterTab
