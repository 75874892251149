import { useContext } from 'react'
import { OrderManagementContext } from '@/contexts'

const TaTransactionFilterTabHook = () => {
  const { orderTab, setOrderTab } = useContext(OrderManagementContext)

  return { orderTab, setOrderTab }
}

export default TaTransactionFilterTabHook
