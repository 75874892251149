import { Link } from 'react-router-dom'

import { Container, Title, ForgotPasswordIcon, Subtitle, Button } from './styles'
import CONSTANT from '@/constant'
import SuccessfullyForgotPasswordHook from './SuccessfullyForgotPasswordCard.hook'
import { IMAGE_URL } from '@/constant/IMAGE'

function SuccessfullyForgotPasswordCard() {
  const { forgotBackofficeUserPassword } = SuccessfullyForgotPasswordHook()
  return (
    <Container visible={forgotBackofficeUserPassword.isSuccessful}>
      <ForgotPasswordIcon src={IMAGE_URL.forgotPasswordIcon} />
      <Title>กรุณาตรวจสอบอีเมล</Title>
      <Subtitle>ลิงค์สำหรับเปลี่ยนรหัสผ่านได้ถูกส่งไปยัง</Subtitle>
      <Subtitle>อีเมลของคุณเรียบร้อยแล้ว</Subtitle>
      <Link to={CONSTANT.ROUTES.LOGIN_PAGE}>
        <Button type="primary">กลับหน้าหลัก</Button>
      </Link>
    </Container>
  )
}

export default SuccessfullyForgotPasswordCard
