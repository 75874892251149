import ResetWatchDeviceModal from '@/components/feature/PolicyDetailPage/ResetWatchDeviceModal'
import Table from '@/components/common/Table'
import WatchDeviceTableHook from './WatchDeviceTable.hook'
import { TableStyles } from './styles'
import { WatchDeviceTableProps } from './types'
import HardResetWatchDeviceModal from '../HardResetWatchDeviceModal'

export default function WatchDeviceTable({ dataSource, isLoading }: WatchDeviceTableProps) {
  const { columns } = WatchDeviceTableHook()

  return (
    <>
      <Table
        loading={isLoading}
        className={TableStyles}
        columns={columns.filter((column) => column.width !== 0)}
        pagination={false}
        dataSource={dataSource}
        data-testid="policy-detail-device-tab-smartwatches-table"
      />
      <ResetWatchDeviceModal />
      <HardResetWatchDeviceModal />
    </>
  )
}
