import TaTransactionOrderCardPolicyHook from './TaTransactionOrderCardPolicy.hook'
import { Box, ColAntd, Container, Header, Line, RowAntd, Title, Value } from './styles'
import { ContactPersonResponse, OrderResponse } from '@/services/order/type'
import Table from '@/components/common/Table'
import TaContactPersonModal from '@/components/feature/TaTransactionOrderPage/TaContactPersonModal'

const TaTransactionOrderCardPolicy = ({ data }: { data: OrderResponse | undefined }) => {
  const {
    policyDetails,
    isContactPerson,
    contactPersonColumns,
    selectedContactPerson,
    closeModal,
  } = TaTransactionOrderCardPolicyHook({ data })

  return (
    <Container>
      <Header>ข้อมูลการขอประกันภัย</Header>
      <RowAntd>
        {policyDetails.map((detail, index) => (
          <ColAntd key={index} span={6}>
            <Title>{detail.title}</Title>
            <Value>{detail.value}</Value>
          </ColAntd>
        ))}
      </RowAntd>
      {isContactPerson ? (
        <Box>
          <Line />
          <Header>ชื่อผู้ติดต่อ</Header>
          <Table
            data-testid="ta-transaction-contact-person-table"
            rowKey="id"
            columns={contactPersonColumns.filter((column) => column.width !== 0)}
            dataSource={data?.contactPerson ? [{ ...data?.contactPerson }] : undefined}
            pagination={false}
            scroll={{
              x: '77vw',
            }}
          />
        </Box>
      ) : undefined}
      {selectedContactPerson && (
        <TaContactPersonModal
          open={!!selectedContactPerson}
          orderId={data?.id || ''}
          contactPerson={data?.contactPerson || ({} as ContactPersonResponse)}
          closeModal={closeModal}
        />
      )}
    </Container>
  )
}

export default TaTransactionOrderCardPolicy
