import { SearchOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Select as AntSelect, Switch as AntSwitch, Input, Skeleton as AntSkeleton } from 'antd'
import CommonButton from '@/components/common/Button'

export const ContainerWrapper = styled('div')`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
`

export const HeaderWrapper = styled('div')`
  padding: 24px;
`

export const Header = styled('div')`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`

export const Description = styled('div')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

export const BodyWrapper = styled('div')`
  display: grid;
  grid-template-columns: 320px 1fr;
`

export const Block = styled('div')`
  padding-bottom: 16px;
`

export const MemberRoleWrapper = styled('div')`
  display: grid;
  grid-gap: 16px;
  padding: 24px;
  border-top: 1px solid #e5e5e5;
`

export const RoleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
`

export const BlockWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Button = styled(CommonButton)`
  line-height: 20px;
  padding: 10px 16px;
`

export const Select = styled(AntSelect)`
  .ant-select-selector {
    height: 40px !important;
    padding: 10px 16px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
`

export const SwitchWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  padding: 16px;
  border-bottom: 0.5px solid #e5e5e5;
`

export type LabelType = {
  selected: boolean
}

export const Label = styled('span')<LabelType>`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.004em;
  color: #000000;
  color: ${(props) => (props.selected ? '#009640' : '#000000')};

  :hover {
    color: #1aa351;
  }

  :active {
    color: #007034;
  }
`

export const Switch = styled(AntSwitch)``

export const SearchInput = styled(Input)`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 10px 16px;

  .ant-input-prefix {
    margin-inline-end: 12px;
  }
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`

export const SkeletonContainer = styled('div')`
  display: grid;
  padding: 0 24px;
`
export const Skeleton = styled(AntSkeleton)`
  margin: 4px 0;
`
