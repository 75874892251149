import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeGetAllBackofficeMenuTypeDataInterface } from './interfaces'

const backofficeGetAllBackofficeMenuType: TypedDocumentNode<BackofficeGetAllBackofficeMenuTypeDataInterface> = gql`
  query backofficeGetAllBackofficeMenuType {
    backofficeGetAllBackofficeMenuType {
      id
      name
      order
    }
  }
`

export default backofficeGetAllBackofficeMenuType
