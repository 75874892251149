import styled from '@emotion/styled'
import { Input as AntdInput } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export const Container = styled('div')`
  position: relative;
`

export const FilterContainer = styled('div')`
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 401;
`

export const Search = styled(AntdInput.Search)`
  position: relative;
  width: 400px;
  margin-bottom: 8px;

  .ant-input {
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 0 4px 4px 0 !important;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  }

  .ant-input-group-addon {
    background-color: #ffffff;

    .ant-input-search-button {
      display: none;
    }
  }
`

export const CloseContainer = styled('div')`
  padding: 4px;
  display: flex;
  flex-direction: row-reverse;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`

export const SearchResultContainer = styled('div')`
  font-family: 'Prompt';
  width: 400px;
  background-color: #ffffff;
  border-radius: 8px;
`

export const SearchResultSubContainer = styled('div')`
  height: 400px;
  overflow-y: scroll;
`

export const SearchResultBlock = styled('div')`
  padding: 16px;
  border-bottom: 1px solid #cccccc;
`

export const SearchResultTitle = styled('div')`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const SearchResultAddress = styled('div')`
  font-size: 14px;
  color: #666666;
  margin-bottom: 8px;
`

export const SearchResultAvailableTime = styled('div')`
  font-size: 14px;
  color: #009400;
  padding-bottom: 16px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 16px;
`

export const SearchResultChargerType = styled('div')`
  font-size: 14px;
  margin-bottom: 8px;
`

export const PlugIcon = styled('img')``

export const StationDetailContainer = styled('div')`
  font-family: 'Prompt';
`

export const StationDetailBlock = styled('div')<{
  disableBorderBottom?: boolean
  disableMarginBottom?: boolean
}>`
  padding-bottom: 16px;
  margin-bottom: ${(props) => (props.disableMarginBottom ? 'unset' : '16px')};
  border-bottom: ${(props) => (props.disableBorderBottom ? 'unset' : '1px solid #cccccc')};
`

export const ChargeTypeBlock = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const StationDetailTitle = styled('div')`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  cursor: pointer;
`

export const StationDetailAddress = styled('div')`
  font-size: 14px;
  color: #666666;
  margin-bottom: 16px;
`

export const StationDetailSubtitle = styled('div')`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const StationDetailInfo = styled('div')`
  font-size: 14px;
`

export const TimedPeriodBlock = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #cccccc;
`

export const TimePeriodInfo = styled('div')<{ currentDay?: boolean }>`
  font-size: 14px;
  font-weight: ${(props) => (props.currentDay ? '700' : 'unset')};
`

export const ChargeRateBlock = styled('div')`
  margin-bottom: 16px;
`
