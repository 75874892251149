import React, { useCallback, useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useVT } from 'virtualizedtableforantd4'
import type { DatePickerProps, MenuProps, TablePaginationConfig } from 'antd'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { ColumnsType } from 'antd/lib/table'
import sideBarInformationAtom from '@/recoil/sideBarInformation'
import editActivityDurationModalAtom from '@/recoil/editActivityDurationModal'
import editActiveBonusModalAtom from '@/recoil/editActiveBonusModal'
import RecentActivityInformation from '@/components/feature/PolicyDetailPage/RecentActivityInformation'
import RecentActivityInformationHeader from '@/components/feature/PolicyDetailPage/RecentActivityInformationHeader'
import {
  Dropdown,
  EditIcon,
  EyeIcon,
  FlashIconStyles,
  NotificationContainerStyles,
  NotificationStyles,
} from './styles'
import { HighlightDayTypes, RecentActivityDataTables } from './types'
import {
  BackofficeGetUserExerciseHistoryData,
  BackofficeGetUserExerciseHistoryPaginationData,
  BackofficeGetUserExerciseHistoryPaginationMeta,
} from '@/gql/backofficeActiveHealthExercise/backofficeGetUserExerciseHistoryPagination/interfaces'
import backofficeActiveHealthExercise from '@/gql/backofficeActiveHealthExercise'
import { useLazyQuery } from '@apollo/client'
import policyAtom from '@/recoil/policy'
import policyDetailAtom from '@/recoil/policyDetail/atom'
import { BackofficeActiveHealthSpecialPointHighlightPeriodEnum } from '@/gql/backofficeActiveHealthExercise/backofficeGetActiveHealthSpecialPointHighlight/interfaces'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { PolicySuperAppStatusEnum } from '@/constant/POLICY_SUPER_APP_STATUS'
import { IMAGE_URL } from '@/constant/IMAGE'
import { isWindowsOs } from '@/libs/os'

function RecentActivityTableHook() {
  const [highlightDays, setHighlightDays] = useState<Array<HighlightDayTypes> | []>([])
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const [isIconWellPoint, setIsIconWellPoint] = useState<boolean>(false)
  const [isRenderPagination, setIsRenderPagination] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentPageSize, setCurrentPageSize] = useState<number>(10)
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null)
  const [datePickerOpenDate, setDatePickerOpenDate] = useState<Dayjs>(dayjs())
  const datePickerRef = useDetectClickOutside({ onTriggered: () => handleDatePicker(false) })
  const setSideBarInformation = useSetRecoilState(sideBarInformationAtom)
  const [editActivityDurationModal, setEditActivityDurationModal] = useRecoilState(
    editActivityDurationModalAtom,
  )
  const [editActiveBonusModal, setEditActiveBonusModal] = useRecoilState(editActiveBonusModalAtom)
  const policyDetail = useRecoilValue(policyDetailAtom)
  const policy = useRecoilValue(policyAtom)
  const user = useRecoilValue(userAtom)
  const isWindows = isWindowsOs()

  const [dataUserExerciseHistory, setUserExerciseHistory] =
    useState<Array<BackofficeGetUserExerciseHistoryPaginationData>>()

  const [useExerciseHistoryPagination, setUseExerciseHistoryPagination] =
    useState<BackofficeGetUserExerciseHistoryPaginationMeta | null>(null)
  const activeBonusNotificationContainerId = 'active-bonus-notification-container'
  const activeBonusNotificationContainer = document.getElementById(
    activeBonusNotificationContainerId,
  )

  const tablePaginationConfig: TablePaginationConfig = {
    pageSizeOptions: [10, 25, 50, 100],
    showSizeChanger: true,
    total: useExerciseHistoryPagination?.totalItems,
    current: currentPage,
    defaultCurrent: 1,
    pageSize: currentPageSize,
    defaultPageSize: 10,
    onChange: (page, pageSize) => handlePaginate(page, pageSize),
  }

  const menuItems = (data: RecentActivityDataTables): MenuProps['items'] => {
    const { isEditWellPoint, dailySummary } = data

    return [
      validatePerm([BACKOFFICE_USER_PERMISSION.HLT010106], user.permissions)
        ? {
            label: 'แก้ไขข้อมูลออกกำลังกาย',
            key: '0',
            onClick: () =>
              setEditActivityDurationModal({
                open: true,
                id: dailySummary.id,
                step: dailySummary.step,
                activityDuration: dailySummary.activityDuration,
              }),
          }
        : null,
      validatePerm([BACKOFFICE_USER_PERMISSION.HLT010107], user.permissions) && isEditWellPoint
        ? {
            label: 'แก้ไขคะแนน Well Point',
            key: '1',
            onClick: () => setEditActiveBonusModal({ open: true, id: dailySummary.id }),
          }
        : null,
    ]
  }

  const columns: ColumnsType<BackofficeGetUserExerciseHistoryPaginationData | {}> = [
    {
      title: 'ลำดับ',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 80,
      render: (_, __, index) => {
        return <div>{index + 1}</div>
      },
    },
    {
      title: 'วันที่ออกกำลังกาย',
      dataIndex: ['dailySummary', 'date'],
      key: 'date',
      width: 140,
      render: (date) => {
        return <div>{dayjs(date).format('DD-MM-YYYY')}</div>
      },
    },
    {
      title: 'นาฬิกา',
      width: 140,
      render: (_value, record) => {
        const dataTable = record as RecentActivityDataTables
        const data = dataTable.dailySummary as BackofficeGetUserExerciseHistoryData

        return <div>{data?.watchBrand?.name || '-'}</div>
      },
    },
    {
      title: 'อัพเดทข้อมูลล่าสุด',
      dataIndex: ['dailySummary', 'updatedAt'],
      key: 'updatedAt',
      width: 160,
      render: (updatedAt) => {
        return <div>{dayjs(updatedAt).format('DD-MM-YYYY HH:mm:ss')}</div>
      },
    },
    {
      title: 'ก้าวเดิน',
      dataIndex: ['dailySummary', 'step'],
      key: 'step',
      width: 100,
    },
    {
      title: 'นาทีออกกำลังกาย',
      dataIndex: ['dailySummary', 'activityDuration'],
      key: 'activityDuration',
      width: 100,
    },
    {
      title: 'คะแนนออกกำลังกาย',
      dataIndex: ['dailySummary', 'exercisePoint'],
      key: 'exercisePoint',
      width: 100,
    },
    {
      title: 'คะแนน Well Point',
      dataIndex: ['dailySummary', 'bonusPoint'],
      key: 'bonusPoint',
      width: 100,
      render: (bonusPoint) => {
        return <div>{bonusPoint !== null ? bonusPoint : '-'}</div>
      },
    },
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (_value, record, index) => {
        const dataTable = record as RecentActivityDataTables
        const data = dataTable.dailySummary as BackofficeGetUserExerciseHistoryData

        return (
          <EyeIcon
            src={IMAGE_URL.eyeIcon}
            onClick={() =>
              openSideBar({
                children: <RecentActivityInformation exerciseHistory={data} />,
                title: <RecentActivityInformationHeader exerciseHistory={data} />,
              })
            }
            data-testid={`policy-detail-exercise-tab-activity-table-view-button-${index}`}
          />
        )
      },
    },
    validatePerm(
      [BACKOFFICE_USER_PERMISSION.HLT010106, BACKOFFICE_USER_PERMISSION.HLT010107],
      user.permissions,
    )
      ? {
          title: '',
          key: 'edit',
          width: 50,
          align: 'center',
          fixed: 'right',
          render: (_, record, index) => {
            const row = record as RecentActivityDataTables
            return (
              <div
                data-testid={`policy-detail-exercise-tab-activity-table-edit-dropdown-container-${index}`}
              >
                <Dropdown
                  menu={{ items: menuItems(row) }}
                  trigger={['click']}
                  disabled={
                    !policy.policySuperAppStatus ||
                    ![
                      PolicySuperAppStatusEnum.A,
                      PolicySuperAppStatusEnum.F,
                      PolicySuperAppStatusEnum.C,
                    ].includes(policy.policySuperAppStatus)
                  }
                >
                  <EditIcon
                    src={IMAGE_URL.editIcon}
                    data-testid={`policy-detail-exercise-tab-activity-table-edit-dropdown-${index}`}
                  />
                </Dropdown>
              </div>
            )
          },
        }
      : { width: 0 },
  ]

  const handleHighlightDays = useCallback(
    (data: Array<BackofficeGetUserExerciseHistoryPaginationData>) => {
      let highlightDays: Array<HighlightDayTypes> = []

      for (const activity of data) {
        highlightDays.push({
          date: dayjs(activity.dailySummary.date).get('D'),
          month: dayjs(activity.dailySummary.date).get('M'),
          year: dayjs(activity.dailySummary.date).get('y'),
          point: activity.dailySummary.exercisePoint,
          activeBonus: activity.dailySummary.bonusPoint,
        })
      }

      return setHighlightDays(highlightDays)
    },
    [],
  )

  const [
    getBackofficeGetUserExerciseHistory,
    { loading: loadingBackofficeGetUserExerciseHistoryQuery },
  ] = useLazyQuery(
    backofficeActiveHealthExercise.query.backofficeGetUserExerciseHistoryPagination,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.backofficeGetUserExerciseHistoryPagination) {
          setUserExerciseHistory(data.backofficeGetUserExerciseHistoryPagination.data)
          setUseExerciseHistoryPagination(data.backofficeGetUserExerciseHistoryPagination.meta)

          handleHighlightDays(data.backofficeGetUserExerciseHistoryPagination.data)
        }
      },
    },
  )

  const [getBackofficeGetUserExerciseHistoryByDate] = useLazyQuery(
    backofficeActiveHealthExercise.query.backofficeGetUserExerciseHistoryPagination,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const [getActiveHealthSpecialPointHighlight] = useLazyQuery(
    backofficeActiveHealthExercise.query.backofficeGetActiveHealthSpecialPointHighlight,
    { fetchPolicy: 'no-cache' },
  )

  const handleOpenSidebar = useCallback(
    (selectDate: Date) => {
      const tomorrow = dayjs().startOf('day').utc(false)
      const searchDate = dayjs(selectDate).startOf('day').utc(false)

      if (policyDetail.id && policy.policyNumber && searchDate.diff(tomorrow) <= 0) {
        getBackofficeGetUserExerciseHistoryByDate({
          variables: {
            userId: policyDetail.id,
            policyNumber: policy.policyNumber,
            input: {
              searchBy: ['date'],
              search: searchDate.format('YYYY-MM-DD'),
            },
          },
          onCompleted: (data) => {
            if (data.backofficeGetUserExerciseHistoryPagination?.data?.length > 0) {
              const exerciseHistory =
                data?.backofficeGetUserExerciseHistoryPagination.data[0].dailySummary

              openSideBar({
                children: <RecentActivityInformation exerciseHistory={exerciseHistory} />,
                title: <RecentActivityInformationHeader exerciseHistory={exerciseHistory} />,
              })
            }
          },
        })
      }
    },
    [getBackofficeGetUserExerciseHistoryByDate, policyDetail.id, policy.policyNumber],
  )
  const getSpecialPointHighlight = useCallback(
    (date: dayjs.Dayjs) => {
      const formatDay = date.format('YYYY-MM')

      getActiveHealthSpecialPointHighlight({
        variables: {
          input: {
            period: BackofficeActiveHealthSpecialPointHighlightPeriodEnum.MONTHLY,
            date: formatDay,
          },
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          if (data?.backofficeGetActiveHealthSpecialPointHighlight) {
            const mapData = data?.backofficeGetActiveHealthSpecialPointHighlight.map(
              (data) => data.descriptionTh,
            )

            createActiveBonusNotificationElement(mapData)
          }
        },
      })
    },
    [getActiveHealthSpecialPointHighlight],
  )
  const handlePanelChange: DatePickerProps['onPanelChange'] = (date) => {
    removeActiveBonusNotificationElement()
    // @ts-ignore
    setDatePickerOpenDate(date)
    // @ts-ignore
    getSpecialPointHighlight(date)
    handleIconWellPoint(date)
  }

  const handleIconWellPoint = (dayjs: dayjs.Dayjs) => {
    const summaryBonusPointInMonth = highlightDays
      .filter((day) => {
        return dayjs.month() === day.month && dayjs.year() === day.year
      })
      .reduce((sum, highlight) => sum + highlight.activeBonus, 0)
    setIsIconWellPoint(summaryBonusPointInMonth > 0)
  }

  const [vt] = useVT(
    () => ({
      onScroll: async ({ top, isEnd }) => {
        const isValidatePagination =
          useExerciseHistoryPagination?.totalItems === Number(dataUserExerciseHistory?.length)

        if (policyDetail.id && policy.policyNumber && isEnd && !isValidatePagination) {
          getBackofficeGetUserExerciseHistory({
            variables: {
              userId: policyDetail.id,
              policyNumber: policy.policyNumber,
              input: {
                page: Number(useExerciseHistoryPagination?.currentPage) + 1,
                limit: 100,
              },
            },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
              if (data?.backofficeGetUserExerciseHistoryPagination.data) {
                setUserExerciseHistory((state = []) => [
                  ...state,
                  ...data.backofficeGetUserExerciseHistoryPagination.data,
                ])

                setUseExerciseHistoryPagination(
                  data.backofficeGetUserExerciseHistoryPagination.meta,
                )

                if (dataUserExerciseHistory) {
                  handleHighlightDays([
                    ...dataUserExerciseHistory,
                    ...data.backofficeGetUserExerciseHistoryPagination.data,
                  ])
                }
              }
            },
          })
        }
      },
      scroll: {
        y: 500,
      },
    }),
    [useExerciseHistoryPagination, dataUserExerciseHistory],
  )

  const openSideBar = ({
    children,
    title,
  }: {
    children: React.ReactNode
    title: React.ReactNode
  }) => {
    setSideBarInformation({
      visible: true,
      children: children,
      title: title,
      dataTestId: 'recent-activity-sidebar',
    })
  }

  const handleDatePicker = (isOpen: boolean) => {
    if (isOpen && datePickerOpenDate) {
      getSpecialPointHighlight(datePickerOpenDate)
      handleIconWellPoint(datePickerOpenDate)
    }

    setIsDatePickerOpen(isOpen)
  }

  const handleSelectDate = (date: Dayjs | null) => setSelectedDate(date)

  const datePickerGetPopupContainer = () => {
    return document.getElementById('date-picker-container') || document.body
  }

  const createActiveBonusNotificationElement = (items: Array<string>) => {
    const datepickerBody = document.getElementsByClassName('ant-picker-body')[0]
    const parentContainer = document.createElement('div')

    // create container
    parentContainer.id = activeBonusNotificationContainerId

    for (const item of items) {
      const notificationContainer = document.createElement('div')
      const content = document.createElement('div')
      const img = document.createElement('img')

      // create notification container
      notificationContainer.id = 'active-bonus-notification'
      notificationContainer.classList.add(NotificationContainerStyles)

      // add property content element
      content.classList.add(NotificationStyles)
      content.textContent = item

      // add property flash image
      img.classList.add(FlashIconStyles)
      img.src = '/assets/icons/flash-icon.svg'

      // append flash image and content to container element
      notificationContainer.appendChild(img)
      notificationContainer.appendChild(content)

      // prepend notification to parent container
      parentContainer.prepend(notificationContainer)

      // prepend content to ant design datepicker body
      datepickerBody.prepend(parentContainer)
    }
  }

  const removeActiveBonusNotificationElement = () => activeBonusNotificationContainer?.remove()

  const handlePaginate = (page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  useEffect(() => {
    if (!isDatePickerOpen) {
      // remove after close date picker
      setTimeout(() => {
        removeActiveBonusNotificationElement()
      }, 500)
    }
  }, [isDatePickerOpen])

  useEffect(() => {
    if (policyDetail.id && policy.policyNumber) {
      let page: number | undefined = useExerciseHistoryPagination?.currentPage
      let limit: number = 100

      if (isWindows) {
        page = currentPage
        limit = currentPageSize
      }

      getBackofficeGetUserExerciseHistory({
        variables: {
          userId: policyDetail.id,
          policyNumber: policy.policyNumber,
          input: {
            page: page || 1,
            limit: limit,
          },
        },
      })
    }
  }, [
    getBackofficeGetUserExerciseHistory,
    editActivityDurationModal.isEditSuccess,
    editActiveBonusModal.isEditSuccess,
    window,
    currentPage,
    currentPageSize,
  ])

  useEffect(() => {
    if (selectedDate) {
      handleOpenSidebar(dayjs(selectedDate).toDate())
      handleSelectDate(null)
    }
  }, [selectedDate, handleOpenSidebar])

  useEffect(() => {
    if (window.navigator && isWindows) {
      setIsRenderPagination(true)
    } else {
      setIsRenderPagination(false)
    }
  }, [window])

  return {
    vt,
    isDatePickerOpen,
    handleDatePicker,
    highlightDays,
    datePickerGetPopupContainer,
    datePickerRef,
    columns,
    dataUserExerciseHistory,
    loadingBackofficeGetUserExerciseHistoryQuery,
    handlePanelChange,
    selectedDate,
    handleSelectDate,
    isIconWellPoint,
    tablePaginationConfig,
    isRenderPagination,
  }
}

export default RecentActivityTableHook
