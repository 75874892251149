import { gql, TypedDocumentNode } from '@apollo/client'
import {
  UpdateOrderBackofficeFAQCategoryDataInterface,
  UpdateOrderBackofficeFAQCategoryInput,
} from './types'

export const updateOrderBackofficeFAQCategory: TypedDocumentNode<
  UpdateOrderBackofficeFAQCategoryDataInterface,
  UpdateOrderBackofficeFAQCategoryInput
> = gql`
  mutation UpdateOrderBackofficeFAQCategory(
    $input: UpdateOrderBackofficeFAQCategoryActiveHealthInput!
  ) {
    updateOrderBackofficeFAQCategory(input: $input) {
      id
      title
      faqModuleId
      order
    }
  }
`

export default updateOrderBackofficeFAQCategory
