import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeGetAllMotorEvChargingProvidersInterface } from './interfaces'

const backofficeGetAllMotorEvChargingProviders: TypedDocumentNode<BackofficeGetAllMotorEvChargingProvidersInterface> = gql`
  query BackofficeGetAllMotorEvChargingProviders {
    backofficeGetAllMotorEvChargingProviders {
      id
      name
    }
  }
`

export default backofficeGetAllMotorEvChargingProviders
