import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetAdjustBonusPointAndPromotionActivityDataInterface,
  BackofficeGetAdjustBonusPointAndPromotionActivityInputInterface,
} from './interface'

export const backofficeGetAdjustBonusPointAndPromotionActivity: TypedDocumentNode<
  BackofficeGetAdjustBonusPointAndPromotionActivityDataInterface,
  BackofficeGetAdjustBonusPointAndPromotionActivityInputInterface
> = gql`
  query BackofficeGetAdjustBonusPointAndPromotionActivity(
    $input: BackofficeGetAdjustBonusPointAndPromotionActivityInput!
  ) {
    backofficeGetAdjustBonusPointAndPromotionActivity(input: $input) {
      point
      activeHealthPromotionActivity {
        activityType
        createdByText
        createdAt
        actionData
      }
    }
  }
`

export default backofficeGetAdjustBonusPointAndPromotionActivity
