import { Form, Input } from 'antd'
import {
  Cancel,
  Create,
  Container,
  FormFooter,
  FormItem,
  FormWrapper,
  Select,
  TextEditor,
} from './styles'
import 'react-quill/dist/quill.snow.css'
import { QuestionFormProps } from './types'
import QuestionFormHook from './QuestionForm.hook'
import { statusQuestionEnum } from '../../types'

const QuestionForm = ({
  onCancel,
  onSuccess,
  categoryOptions,
  mode,
  subCategory,
  selectedFaqCategory,
}: QuestionFormProps) => {
  const { form, onSubmit, modules, isLoading } = QuestionFormHook({
    onSuccess,
    mode,
    subCategory,
    selectedFaqCategory,
  })

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Container>
        <FormWrapper>
          <FormItem label="หมวดหมู่คำถาม" name="faqCategoryId">
            <Select
              defaultValue={'เลือก'}
              options={categoryOptions}
              data-testid="faq-select-cate-input"
            />
          </FormItem>
          <FormItem label="คำถาม" name="title">
            <Input placeholder="ระบุคำถาม" data-testid="faq-question-name-input" />
          </FormItem>
          <FormItem name="description">
            <TextEditor theme="snow" modules={modules} data-testid="faq-text-editor" />
          </FormItem>
        </FormWrapper>
        <FormFooter>
          <Cancel
            onClick={() => onCancel()}
            type="default"
            loading={isLoading}
            data-testid="faq-question-cancel-button"
          >
            ยกเลิก
          </Cancel>
          <Create
            type="primary"
            htmlType="submit"
            loading={isLoading}
            data-testid="faq-submit-button"
          >
            {mode === statusQuestionEnum.EDIT_QUESTION ? 'แก้ไขคำถาม' : 'เพิ่มคำถาม'}
          </Create>
        </FormFooter>
      </Container>
    </Form>
  )
}

export default QuestionForm
