import { MainAppProps } from './types'
import { Container, Header, Content, Footer } from './styles'

import AppBar from '@/components/common/AppBar'
import SideBar from '@/components/common/SideBar'
import MainAppHook from './MainApp.hook'
import SideBarInformation from '@/components/common/SideBarInformation'

function MainApp({ hasSideBar = false, user, children }: MainAppProps) {
  const { isSideBarCollapse, setSideBar } = MainAppHook()

  return (
    <Container>
      <Header>
        <AppBar user={user} isSideBarCollapse={isSideBarCollapse} setSideBar={setSideBar} />
      </Header>
      <Content>
        {hasSideBar && <SideBar isSideBarCollapse={isSideBarCollapse} />}
        {children}
        {hasSideBar && <SideBarInformation />}
      </Content>
      <Footer />
    </Container>
  )
}

export default MainApp
