import dayjs, { Dayjs } from 'dayjs'
import { DateRange, StaticDateRangePicker } from '@/libs/x-date-pickers-pro'
import { Box as MuiBox, TextField as MuiTextField } from '@mui/material'
import {
  DateRangePickerStyled,
  SelectedDateBlock,
  SelectedDateContainer,
  SelectedDateSubtitle,
  SelectedDateValue,
} from './styles'

export default function DateRangePicker({
  dateRangeValues,
  onChangeValue,
  displayHeader = false,
}: {
  dateRangeValues: DateRange<Dayjs>
  onChangeValue: (dateRangeValues: DateRange<Dayjs>) => void
  displayHeader?: boolean
}) {
  return (
    <>
      {displayHeader ? (
        <SelectedDateContainer>
          <SelectedDateBlock>
            <SelectedDateSubtitle>ตั้งแต่</SelectedDateSubtitle>
            <SelectedDateValue>
              {dateRangeValues[0]
                ? dayjs(dateRangeValues[0]).toDate().toLocaleDateString('th-TH', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })
                : 'กรุณาระบุ'}
            </SelectedDateValue>
          </SelectedDateBlock>
          <SelectedDateBlock>
            <SelectedDateSubtitle>จนถึง</SelectedDateSubtitle>
            <SelectedDateValue>
              {dateRangeValues[1]
                ? dayjs(dateRangeValues[1]).toDate().toLocaleDateString('th-TH', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })
                : 'กรุณาระบุ'}
            </SelectedDateValue>
          </SelectedDateBlock>
        </SelectedDateContainer>
      ) : undefined}

      <StaticDateRangePicker
        className={DateRangePickerStyled}
        displayStaticWrapperAs="desktop"
        value={dateRangeValues}
        onChange={(newDateValue: DateRange<Dayjs>) => onChangeValue(newDateValue)}
        disableFuture={true}
        renderInput={(startProps, endProps) => (
          <>
            <MuiTextField {...startProps} />
            <MuiBox sx={{ mx: 2 }}> to </MuiBox>
            <MuiTextField {...endProps} />
          </>
        )}
      />
    </>
  )
}
