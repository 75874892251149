export enum BackofficeActiveHealthSpecialPointHighlightPeriodEnum {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export interface BackofficeActiveHealthSpecialPointHighlightDataInterface {
  descriptionTh: string
}

export interface BackofficeActiveHealthSpecialPointHighlightInterface {
  backofficeGetActiveHealthSpecialPointHighlight: Array<BackofficeActiveHealthSpecialPointHighlightDataInterface>
}

export interface BackofficeActiveHealthSpecialPointHighlightInputInterface {
  input: {
    period: BackofficeActiveHealthSpecialPointHighlightPeriodEnum
    date?: string
  }
}
