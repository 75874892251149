import { withTheme } from '@emotion/react'
import Modal from '@/components/common/Modal'
import { Button, Container, ExportIcon, ButtonContainer, OkButton } from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import DateRangePicker from '@/components/common/DateRangePicker'
import TaTransactionExporterHook from './TaTransactionExporter.hook'
import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'

const TaTransactionExporter = () => {
  const {
    isEnabled,
    isModalVisible,
    setIsModalVisible,
    dateValue,
    handleCloseModal,
    onClickCsvLink,
    handleDateValue,
    fetchExportOrder,
    transformToCsvData,
  } = TaTransactionExporterHook()

  if (isEnabled) {
    return (
      <Container>
        <Button
          onClick={() => setIsModalVisible(true)}
          icon={<ExportIcon src={IMAGE_URL.exportIcon} />}
          data-testid="ta-transaction-export-button"
        >
          Export
        </Button>
        <Modal
          title="ช่วงการดึงข้อมูล (ตามวันที่สั่งซื้อ)"
          open={isModalVisible}
          onCancel={handleCloseModal}
          width="670px"
          footer={null}
          data-testid="export-ta-transaction-modal"
        >
          <DateRangePicker
            dateRangeValues={dateValue}
            onChangeValue={handleDateValue}
            displayHeader={true}
          />

          <ButtonContainer>
            <Button
              onClick={handleCloseModal}
              data-testid="export-ta-transaction-modal-close-button"
            >
              Cancel
            </Button>
            <OkButton disabled={!fetchExportOrder.data || fetchExportOrder.data.length <= 0}>
              <CSVLink
                data={transformToCsvData(fetchExportOrder.data || [])}
                filename={`ta-transaction-${dayjs().toISOString()}.csv`}
                asyncOnClick={true}
                onClick={(event, done) => onClickCsvLink(event, done)}
                data-testid="export-ta-transaction-export-button"
              >
                Export
              </CSVLink>
            </OkButton>
          </ButtonContainer>
        </Modal>
      </Container>
    )
  }
}

export default withTheme(TaTransactionExporter)
