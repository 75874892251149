import { Input as AntdInput } from 'antd'
import styled from '@emotion/styled'

export const Subtitle = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const Label = styled('p')`
  font-weight: 500;
  font-size: 14px;
  color: #000000;
`

export const Input = styled(AntdInput)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 16px;
`

export const TextBox = styled('div')`
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
`

export const SmsText = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
`
