import { ButtonProps as AntdButtonProps } from 'antd'
import { Theme } from 'antd/lib/config-provider/context'
import { CSSObject } from '@emotion/styled/dist/emotion-styled.cjs'
import { ButtonAntd } from './styles'

interface ButtonProps extends AntdButtonProps {
  theme?: Theme
  css?: CSSObject
}

function Button(props: ButtonProps) {
  return <ButtonAntd {...props}>{props.children}</ButtonAntd>
}

export default Button
