import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeSearchActiveHealthPolicyDataInterface,
  BackofficeSearchActiveHealthPolicyInputInterface,
} from './interfaces'

const backofficeSearchActiveHealthPolicy: TypedDocumentNode<
  BackofficeSearchActiveHealthPolicyDataInterface,
  BackofficeSearchActiveHealthPolicyInputInterface
> = gql`
  query BackofficeSearchActiveHealthPolicy($input: RequestPaginationInput!) {
    backofficeSearchActiveHealthPolicy(input: $input) {
      data {
        referenceNumber
        policyNumber
        insuredName
        identityId
        phoneNumber
        plan
        user {
          id
        }
        startAt
        endAt
        cancelledAt
        status
        remark
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
        sortBy
        searchBy
        search
      }
    }
  }
`

export default backofficeSearchActiveHealthPolicy
