import styled from '@emotion/styled'
import { Form, Input as FormInput, Select as AntSelect, Button } from 'antd'
import ReactQuill from 'react-quill'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 24px 40px 24px 40px;
`

export const FormWrapper = styled('div')``

export const FormFooter = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 12px;
`

export const Cancel = styled(Button)`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px;
  box-shadow: none;
  height: auto;
  padding: 10px 16px;
  border-radius: 8px;
`

export const SaveDraft = styled(Button)`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px;
  box-shadow: none;
  height: auto;
  padding: 10px 16px;
  border-radius: 8px;
`
export const Create = styled(Button)`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px;
  box-shadow: none;
  height: auto;
  padding: 10px 16px;
  border-radius: 8px;
`

export const FormItem = styled(Form.Item)`
  margin-bottom: 16px;

  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #000000;
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0 0 4px;
    white-space: initial;
    text-align: start;
  }

  .ant-form-item-explain {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.004em;
    color: #666666;
    margin-top: 4px;
    margin-bottom: 16px;
  }
`

export const Select = styled(AntSelect)`
  .ant-select-selector {
    height: 40px !important;
    padding: 10px 16px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
`

export const Input = styled(FormInput)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
`

export const TextEditor = styled(ReactQuill)`
  height: 553px;
  margin-bottom: 32px;

  .ql-toolbar {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .ql-container {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`
