import { gql, TypedDocumentNode } from '@apollo/client'
import {
  ResetBackofficeUserPasswordDataInterface,
  ResetBackofficeUserPasswordInputInterface,
} from './interfaces'

export const resetBackofficeUserPassword: TypedDocumentNode<
  ResetBackofficeUserPasswordDataInterface,
  ResetBackofficeUserPasswordInputInterface
> = gql`
  mutation ResetBackofficeUserPassword($input: ResetBackofficeUserPasswordInput!) {
    resetBackofficeUserPassword(input: $input) {
      id
      email
      firstname
      lastname
      staffId
      updatedAt
      createdAt
    }
  }
`

export default resetBackofficeUserPassword
