import { IMAGE_URL } from '@/constant/IMAGE'
import {
  CategoryEditButton,
  CategoryEditWrapper,
  CategoryMoveButton,
  CategoryText,
  CategoryTextWrapper,
  DeleteIcon,
  DownArrow,
  ImageIcon,
  Input,
  UpArrow,
} from './styles'
import { CategoryActionEnum, CategoryProps, CLASS_NAME_EDIT, CLASS_NAME_MOVE } from './types'

const CategoryList = ({
  value,
  action,
  isSelect,
  onChange,
  onKeyDown,
  onEditCategory,
  onSelected,
  onDelete,
  onMouseLeave,
  onMoveUp,
  onMoveDown,
  canDelete,
  canEdit,
  index,
}: CategoryProps) => {
  if (action === CategoryActionEnum.EDIT && onChange) {
    return (
      <CategoryEditWrapper>
        <Input
          allowClear
          value={value}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={(event) => onKeyDown(event)}
          onMouseLeave={onMouseLeave}
          data-testid={`faq-edit-cate-input-${index}`}
        />
        {canDelete ? (
          <DeleteIcon
            onClick={onDelete}
            src={IMAGE_URL.deleteGreyIcon}
            data-testid={`faq-delete-cate-button-${index}`}
          />
        ) : undefined}
      </CategoryEditWrapper>
    )
  }

  return (
    <CategoryTextWrapper>
      <CategoryText onClick={onSelected} isColor={isSelect || false}>
        {value}
      </CategoryText>
      <CategoryMoveButton
        className={CLASS_NAME_MOVE}
        onClick={onMoveUp}
        data-testid={`faq-move-down-button-${index}`}
      >
        <DownArrow src={IMAGE_URL.chevronDownIcon} />
      </CategoryMoveButton>
      <CategoryMoveButton
        className={CLASS_NAME_MOVE}
        onClick={onMoveDown}
        data-testid={`faq-move-up-button-${index}`}
      >
        <UpArrow src={IMAGE_URL.chevronUpIcon} />
      </CategoryMoveButton>
      {canEdit ? (
        <CategoryEditButton
          className={CLASS_NAME_EDIT}
          onClick={onEditCategory}
          data-testid={`faq-edit-cate-button-${index}`}
        >
          <ImageIcon src={IMAGE_URL.editGreyIcon} />
        </CategoryEditButton>
      ) : undefined}
    </CategoryTextWrapper>
  )
}

export default CategoryList
