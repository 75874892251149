import React from 'react'
import { IMAGE_URL } from '@/constant/IMAGE'
import { Drawer, CloseIcon, BackIcon } from './styles'
import SideBarInformationHook from './SideBarInformation.hook'

function SideBarInformation() {
  const { sideBarInformation, onCloseSideBarChildren, onCloseSideBar } = SideBarInformationHook()
  const {
    visible,
    title,
    children,
    sideBarChildrenTitle,
    sideBarChildrenVisible,
    sideBarChildren,
    dataTestId,
    childrenDataTestId,
  } = sideBarInformation

  return (
    <>
      <Drawer
        open={visible}
        onClose={onCloseSideBar}
        closeIcon={<CloseIcon src={IMAGE_URL.closeBlackIcon} />}
        placement="right"
        title={title}
        width={400}
        zIndex={1050}
        data-testid={dataTestId}
      >
        {children}
      </Drawer>
      <Drawer
        open={sideBarChildrenVisible}
        onClose={onCloseSideBarChildren}
        placement="right"
        title={sideBarChildrenTitle}
        width={400}
        closeIcon={<BackIcon />}
        zIndex={1051}
        childrenVisible={sideBarChildrenVisible}
        data-testid={childrenDataTestId}
      >
        {sideBarChildren}
      </Drawer>
    </>
  )
}

export default SideBarInformation
