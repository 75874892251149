import { useRecoilState, useRecoilValue } from 'recoil'
import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { Form, UploadProps } from 'antd'
import message from '@/libs/message'
import deferredDiscountModalAtom from '@/recoil/deferredDiscountModal'
import { activeHealthPolicyGql } from '@/gql'
import { BackofficeGetCreateActiveHealthManualDiscountInputInterface } from '@/gql/activeHealthPolicy/backofficeCreateActiveHealthManualDiscount/interfaces'
import config from '@/config'
import userAtom from '@/recoil/user'
import policyDetailAtom from '@/recoil/policyDetail'
import { CreateManualDiscountTypes } from './types'

function DeferredDiscountModalHook() {
  const user = useRecoilValue(userAtom)
  const policyDetail = useRecoilValue(policyDetailAtom)
  const [disabledSave, setDisabledSave] = useState(true)
  const [form] = Form.useForm<CreateManualDiscountTypes>()
  const [deferredDiscountModal, setDeferredDiscountModal] =
    useRecoilState(deferredDiscountModalAtom)
  const [backofficeCreateActiveHealthManualDiscount] = useMutation(
    activeHealthPolicyGql.mutation.backofficeCreateActiveHealthManualDiscount,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const handleUploadProps: UploadProps = {
    name: 'file',
    action: `${config.graphqlRestEndpoint}/backoffice-active-health/manual-discount/file/upload`,
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
    beforeUpload: (file) => {
      const isAccept =
        file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
      if (!isAccept) {
        message.error('You can only upload JPG/PNG/PDF file!')
        return false
      }

      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isLt10M) {
        message.error('Image must smaller than 10MB!')
        return false
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file upload success.`)
        form.setFieldValue('uploadFile', info)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  const handleFormChange = () => {
    const { discount, reason } = form.getFieldsValue()
    const hasErrors = !reason || !discount

    setDisabledSave(hasErrors)
  }

  const handleDisabledSave = () => {
    setDisabledSave(true)
  }

  const onCloseModal = () => {
    setDeferredDiscountModal({ open: false, activeHealthPolicyBilling: undefined })
    handleDisabledSave()
    form.resetFields()
  }

  const onSubmit = () => {
    form
      .validateFields()
      .then((value) => {
        if (value.discount && value.reason) {
          let fileId = undefined
          let fileKey = undefined

          if (value?.uploadFile?.fileList?.length > 0) {
            fileId = value.uploadFile?.file?.response?.fileId
            fileKey = value.uploadFile?.file?.response?.fileKey
          }

          const payload: BackofficeGetCreateActiveHealthManualDiscountInputInterface = {
            input: {
              userId: policyDetail.id || '',
              refActiveHealthPolicyBillingId:
                deferredDiscountModal?.activeHealthPolicyBilling?.id || '',
              policyNumber: deferredDiscountModal?.activeHealthPolicyBilling?.policyNumber || '',
              discount: value.discount,
              reason: value.reason,
              fileId,
              fileKey,
            },
          }

          backofficeCreateActiveHealthManualDiscount({
            variables: payload,
          })
            .then(({ data }) => {
              if (data?.backofficeCreateActiveHealthManualDiscount) {
                message.success({
                  content: `ส่งคำขออนุโลมส่วนลดของกรมธรรม์ ${data?.backofficeCreateActiveHealthManualDiscount?.policyNumber} สำเร็จ`,
                  duration: 10,
                })
              }
            })
            .catch((error) => {
              if (error instanceof Error) {
                message.error({
                  content: `Error: ${error.message}`,
                  duration: 20,
                })
              }
            })
            .finally(() => {
              onCloseModal()
            })
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  return {
    deferredDiscountModal,
    onCloseModal,
    form,
    handleUploadProps,
    handleFormChange,
    disabledSave,
    onSubmit,
  }
}

export default DeferredDiscountModalHook
