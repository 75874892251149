import { atom } from 'recoil'
import { ModeTeamEnum, TeamModalProps } from './types'

const initTeamModal = {
  title: undefined,
  dataTeam: undefined,
  isVisible: false,
  onOkFunction: Function,
  okText: 'ตกลง',
  okButtonProps: { type: 'primary', danger: false },
  mode: ModeTeamEnum.CREATE,
} as TeamModalProps

const teamModalAtom = atom({
  key: 'teamModalAtom',
  default: initTeamModal,
})

export default teamModalAtom
