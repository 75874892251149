export enum OrderTabEnum {
  DRAFT = 'draft',
  WAITING_PAYMENT = 'waiting-payment',
  PAYMENT_FAILED = 'payment-failed',
  PAYMENT_SUCCESS = 'payment-success',
  FAIL_VERIFIED = 'fail-verified',
  CANCELLED = 'cancelled',
  ALL = 'all',
}

export enum OrderStatusEnum {
  DRAFTED = 'drafted',
  SUBMITTED = 'submitted',
  PAID = 'paid',
  PAYMENT_CONFIRMED = 'payment_confirmed',
  PAYMENT_FAILED = 'payment_failed',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export const ORDER_TAB = {
  [OrderTabEnum.DRAFT]: 'กำลังทำรายการ',
  [OrderTabEnum.WAITING_PAYMENT]: 'รอการชำระเงิน',
  [OrderTabEnum.PAYMENT_FAILED]: 'ตัดบัตรไม่ผ่าน',
  [OrderTabEnum.PAYMENT_SUCCESS]: 'ชำระเงินสำเร็จ',
  [OrderTabEnum.FAIL_VERIFIED]: 'ไม่ผ่านการตรวจสอบ',
  [OrderTabEnum.CANCELLED]: 'ยกเลิก/ใบสั่งซื้อหมดอายุ',
  [OrderTabEnum.ALL]: 'ทั้งหมด',
}

export enum AddressPolicyPrintTypeEnum {
  POLICY = 'POLICY',
  E_POLICY = 'E-POLICY',
}

export enum ApplicationFormStatusEnum {
  VERIFIED = 'verified',
  FAIL_VERIFIED = 'fail_verified',
  SUBMITTED = 'submitted',
  POLICY_ISSUED = 'policy_issued',
  RECEIVED = 'received',
  CANCELLED = 'cancelled',
}

export enum ProductTypeEnum {
  TRAVEL_TRIP = 'TravelTrip',
  TRAVEL_ANNUAL = 'TravelAnnual',
}

export const PRODUCT_TYPE = {
  [ProductTypeEnum.TRAVEL_TRIP]: 'รายเที่ยว',
  [ProductTypeEnum.TRAVEL_ANNUAL]: 'รายปี',
}

export const ORDER_STATUS = {
  [OrderTabEnum.DRAFT]: {
    orderStatus: [OrderStatusEnum.DRAFTED],
    appFormStatus: [ApplicationFormStatusEnum.VERIFIED],
  },
  [OrderTabEnum.WAITING_PAYMENT]: {
    orderStatus: [OrderStatusEnum.SUBMITTED],
    appFormStatus: null,
  },
  [OrderTabEnum.PAYMENT_FAILED]: {
    orderStatus: [OrderStatusEnum.PAYMENT_FAILED],
    appFormStatus: null,
  },
  [OrderTabEnum.PAYMENT_SUCCESS]: {
    orderStatus: [OrderStatusEnum.PAID, OrderStatusEnum.PAYMENT_CONFIRMED],
    appFormStatus: null,
  },
  [OrderTabEnum.FAIL_VERIFIED]: {
    orderStatus: null,
    appFormStatus: [ApplicationFormStatusEnum.FAIL_VERIFIED],
  },
  [OrderTabEnum.CANCELLED]: {
    orderStatus: [OrderStatusEnum.CANCELLED, OrderStatusEnum.EXPIRED],
    appFormStatus: null,
  },
  [OrderTabEnum.ALL]: {
    orderStatus: null,
    appFormStatus: null,
  },
}

export enum TitleTypeEnum {
  PERSON = 'person',
  CORPORATION = 'corporation',
}

export const TITLE_TYPE = {
  [TitleTypeEnum.PERSON]: 'บุคคลธรรมดา',
  [TitleTypeEnum.CORPORATION]: 'นิติบุคคล',
}

export enum BeneficiaryTypeEnum {
  LAW = 'law',
  OTHER = 'other',
}

export const BENEFICIARY_TYPE_LAW = 'ทายาทตามกฏหมาย'

export const PAYMENT_TYPE = {
  '1': 'Credit/Debit',
  '2': 'Promptpay',
  '3': 'CounterService',
  '4': 'MBanking',
}
