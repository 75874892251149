import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Badge, CheckboxOptionType } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import ManualDiscountInformation from '@/components/feature/PolicyDetailPage/ManualDiscountInformation'
import ManualDiscountInformationHeader from '@/components/feature/PolicyDetailPage/ManualDiscountInformationHeader'
import sideBarInformationAtom from '@/recoil/sideBarInformation'
import { EyeIcon } from './styles'
import policyAtom from '@/recoil/policy/atom'
import { useLazyQuery } from '@apollo/client'
import { activeHealthPolicyGql } from '@/gql'
import {
  ActiveHealthManualDiscountEnum,
  ActiveHealthManualDiscountFilterApproveStatusEnum,
  ActiveHealthManualDiscountPaginationInterface,
} from '@/gql/activeHealthPolicy/backofficeGetActiveHealthManualDiscountPagination/interfaces'
import { toThaiLocaleDateAndMonthRangeWithDash } from '@/libs/date'
import { BadgeStatusEnum } from '@/constant/BADGE_STATUS'
import { IMAGE_URL } from '@/constant/IMAGE'

function ManualDiscountTableHook() {
  const policy = useRecoilValue(policyAtom)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([])
  const [sideBarInformation, setSideBarInformation] = useRecoilState(sideBarInformationAtom)
  const radioOptions: Array<CheckboxOptionType> = [
    {
      label: 'ทั้งหมด',
      value: '',
    },
    {
      label: 'รออนุมัติ',
      value: ActiveHealthManualDiscountFilterApproveStatusEnum.PENDING,
    },
    {
      label: 'อนุมัติ',
      value: ActiveHealthManualDiscountFilterApproveStatusEnum.APPROVE,
    },
  ]
  const [
    getBackofficeGetActiveHealthManualDiscountPagination,
    {
      data: dataBackofficeGetActiveHealthManualDiscountPagination,
      loading: loadingBackofficeGetActiveHealthManualDiscountPagination,
    },
  ] = useLazyQuery(activeHealthPolicyGql.query.backofficeGetActiveHealthManualDiscountPagination, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        searchBy: [ActiveHealthManualDiscountEnum.POLICY_NUMBER],
        search: policy.policyNumber || '',
      },
    },
  })

  const getActiveHealthManualDiscount = useCallback(
    (filter?: ActiveHealthManualDiscountFilterApproveStatusEnum) => {
      getBackofficeGetActiveHealthManualDiscountPagination({
        variables: {
          input: {
            searchBy: [ActiveHealthManualDiscountEnum.POLICY_NUMBER],
            search: policy.policyNumber || '',
            filter: {
              approveStatus: filter || undefined,
            },
          },
        },
      })
    },
    [getBackofficeGetActiveHealthManualDiscountPagination],
  )

  const columns: ColumnsType<ActiveHealthManualDiscountPaginationInterface | {}> = [
    {
      title: 'ลำดับ',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (_, __, index) => <div>{index + 1}</div>,
    },
    {
      title: 'แผนประกัน',
      key: 'planCode',
      render: (data) => data.activeHealthPolicy.plan,
    },
    {
      title: 'รอบบิลวันที่',
      key: 'billingDate',
      render: (data) =>
        data?.refActiveHealthPolicyBilling?.billDate &&
        data?.refActiveHealthPolicyBilling?.startDate
          ? toThaiLocaleDateAndMonthRangeWithDash(
              data.refActiveHealthPolicyBilling.startDate,
              data.refActiveHealthPolicyBilling.billDate,
            )
          : '-',
    },
    {
      title: 'วันที่ครบกำหนดชำระ',
      key: 'dueDate',
      render: (data) =>
        data?.refActiveHealthPolicyBilling?.billDate
          ? dayjs(data.refActiveHealthPolicyBilling.billDate).format('DD-MM-YYYY')
          : '-',
    },
    {
      title: 'วันที่ขออนุโลมส่วนลด',
      key: 'createdAt',
      render: (data) => dayjs(data.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'ทำรายการโดย',
      key: 'issuedBy',
      render: (data) =>
        data.createdBy && data.createdBy.firstname && data.createdBy.lastname ? (
          <div>
            {data.createdBy.firstname} {data.createdBy.lastname}
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: 'สถานะ',
      key: 'approveStatus',
      render: (data) => {
        if (
          data.approveStatus ===
          ActiveHealthManualDiscountFilterApproveStatusEnum.APPROVE.toLocaleUpperCase()
        )
          return (
            <>
              <Badge status={BadgeStatusEnum.SUCCESS} /> อนุมัติ
            </>
          )

        if (
          data.approveStatus ===
          ActiveHealthManualDiscountFilterApproveStatusEnum.PENDING.toLocaleUpperCase()
        )
          return (
            <>
              <Badge status={BadgeStatusEnum.PROCESSING} /> รออนุมัติ
            </>
          )

        return (
          <>
            <Badge status={BadgeStatusEnum.ERROR} /> ไม่อนุมัติ
          </>
        )
      },
    },
    {
      title: 'วันที่อนุมัติ',
      key: 'approvedDate',
      render: (data) =>
        data.approveStatus !==
        ActiveHealthManualDiscountFilterApproveStatusEnum.PENDING.toLocaleUpperCase()
          ? dayjs(data.updatedAt).format('DD-MM-YYYY HH:mm:ss')
          : '-',
    },
    {
      title: 'อนุมัติโดย',
      key: 'approvedBy',
      render: (data) =>
        data.activeHealthManualDiscountApprover
          ? data.activeHealthManualDiscountApprover.name
          : '-',
    },
    {
      title: 'สถานะ PV',
      key: 'pvStatus',
      render: (data) => {
        if (
          data?.approveStatus ===
          ActiveHealthManualDiscountFilterApproveStatusEnum.REJECT.toLocaleUpperCase()
        ) {
          return (
            <>
              <Badge status={BadgeStatusEnum.ERROR} /> ไม่จัดส่ง
            </>
          )
        }

        if (
          data?.approveStatus ===
          ActiveHealthManualDiscountFilterApproveStatusEnum.PENDING.toLocaleUpperCase()
        ) {
          return '-'
        }

        if (
          data?.approveStatus ===
          ActiveHealthManualDiscountFilterApproveStatusEnum.APPROVE.toLocaleUpperCase()
        ) {
          if (data?.activeHealthPolicyBilling?.isPvDiscountSuccess === true) {
            return (
              <>
                <Badge status={BadgeStatusEnum.SUCCESS} /> สำเร็จ
              </>
            )
          }

          if (data?.activeHealthPolicyBilling?.isPvDiscountSuccess === false) {
            return (
              <>
                <Badge status={BadgeStatusEnum.ERROR} /> ไม่สำเร็จ
              </>
            )
          }

          return (
            <>
              <Badge status={BadgeStatusEnum.PROCESSING} /> รอผลการจัดส่ง
            </>
          )
        }
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
      fixed: 'right',
      render: (_value, record, index) => {
        const data = record as ActiveHealthManualDiscountPaginationInterface

        return (
          <EyeIcon
            src={IMAGE_URL.eyeIcon}
            onClick={() => {
              handleSelectKeyRows([index])
              handleSetManualDiscountInformation(
                <ManualDiscountInformation activeHealthManualDiscount={data} />,
                <ManualDiscountInformationHeader activeHealthManualDiscount={data} />,
              )
            }}
            data-testid={`policy-detail-billing-tab-manual-discount-table-view-button-${index}`}
          />
        )
      },
    },
  ]

  const handleSetManualDiscountInformation = (
    children: React.ReactNode,
    title: React.ReactNode,
  ) => {
    setSideBarInformation({
      title: title,
      children: children,
      visible: true,
      dataTestId: 'manual-discount-sidebar',
    })
  }

  const handleSelectKeyRows = (data: Array<number>) => setSelectedRowKeys(data)

  useEffect(() => {
    getActiveHealthManualDiscount()
  }, [])

  useEffect(() => {
    if (!sideBarInformation.visible) {
      handleSelectKeyRows([])
    }
  }, [sideBarInformation])

  return {
    dataBackofficeGetActiveHealthManualDiscountPagination,
    loadingBackofficeGetActiveHealthManualDiscountPagination,
    columns,
    selectedRowKeys,
    radioOptions,
    getActiveHealthManualDiscount,
  }
}

export default ManualDiscountTableHook
