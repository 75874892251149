import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeListWatchBrandDataInterface } from './interfaces'

const backofficeListWatchBrand: TypedDocumentNode<BackofficeListWatchBrandDataInterface> = gql`
  query BackofficeListWatchBrand {
    backofficeListWatchBrand {
      id
      name
      logoUrl
      authUrl
      order
      watchBrandSeries {
        id
        name
        isSuspend
        createdAt
        updatedAt
      }
      updatedAt
      createdAt
    }
  }
`

export default backofficeListWatchBrand
