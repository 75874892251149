import { useNavigate } from 'react-router-dom'
import {
  Title,
  Container,
  Button,
  SubContainer,
  Subtitle,
  Wrapper,
  NotFoundUserIcon,
} from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import CONSTANT from '@/constant'

function UserNotFound() {
  const navigate = useNavigate()

  return (
    <Container>
      <SubContainer>
        <NotFoundUserIcon src={IMAGE_URL.userNotFoundIcon}></NotFoundUserIcon>
        <Wrapper>
          <Title>ไม่พบข้อมูล</Title>
          <Subtitle>ไม่พบข้อมูลผู้ใช้งานในระบบ</Subtitle>
          <Subtitle>กรุณาติดต่อผู้ดูแลระบบ หรือ หัวหน้าฝ่ายของท่าน</Subtitle>
        </Wrapper>
        <Button type="primary" onClick={() => navigate(CONSTANT.ROUTES.LOGIN_PAGE)}>
          กลับไปหน้า เข้าสู่ระบบ
        </Button>
      </SubContainer>
    </Container>
  )
}

export default UserNotFound
