import { useRecoilState } from 'recoil'
import resetWatchDeviceModalAtom from '@/recoil/resetWatchDeviceModal'
import activeHealthPolicy from '@/gql/activeHealthPolicy'
import { useMutation } from '@apollo/client'
import message from '@/libs/message'

export default function ResetWatchDeviceModalHook() {
  const [resetWatchDeviceModal, setResetWatchDeviceModal] =
    useRecoilState(resetWatchDeviceModalAtom)

  const [postBackofficeResetActiveHealthWearableMutation, { error }] = useMutation(
    activeHealthPolicy.mutation.backofficeResetActiveHealthWearable,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const handleSetResetWatchDeviceModal = () => {
    if (resetWatchDeviceModal.id)
      postBackofficeResetActiveHealthWearableMutation({
        variables: {
          id: resetWatchDeviceModal.id,
        },
      })
        .finally(() => {
          setResetWatchDeviceModal({ id: undefined, open: false, isRefetchWatchDevice: true })
        })
        .catch(() => {
          message.error({
            content: `ปัญหา: ${error?.message}`,
            duration: 10,
          })
        })
  }

  const onCancelModal = () => {
    setResetWatchDeviceModal({ id: undefined, open: false, isRefetchWatchDevice: false })
  }

  return { resetWatchDeviceModal, handleSetResetWatchDeviceModal, onCancelModal }
}
