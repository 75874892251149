import styled from '@emotion/styled'
import { Input as AntdInput, Form as AntdForm } from 'antd'
import BuddhistDatePicker from '@/components/common/BuddhistDatePicker/BuddhistDatePicker'
import CommonButton from '@/components/common/Button'

const { TextArea: AntdTextArea } = AntdInput

export const Container = styled('div')`
  margin-bottom: 77px;
`

export const Title = styled('p')`
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 16px;
`

export const Subtitle = styled('p')`
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-bottom: 4px;
`

export const FieldTitle = styled('div')`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
`

export const BlackSubtitle = styled('p')`
  font-weight: 500;
  font-size: 14px;
  color: #000000;
`

export const Information = styled('p')`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`

export const Input = styled(AntdInput)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 100%;
`

export const TextArea = styled(AntdTextArea)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 100%;
  min-height: 104px !important;
`

export const BottomLine = styled('div')`
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 24px;
`

export const SubmitContainer = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding: 16px;
`

export const Button = styled(CommonButton)`
  position: absolute;
  right: 0;
  top: 8px;
`

export const DatePicker = styled(BuddhistDatePicker)`
  width: 100%;
  height: 42px;
  border-radius: 8px;
`

export const FormItem = styled(AntdForm.Item)<{ sixMarginBottom?: boolean }>`
  margin-bottom: ${(props) => (props.sixMarginBottom ? '6px' : '24px')};

  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
`

export const Hint = styled('div')`
  font-size: 12px;
  color: #666666;
  margin-bottom: 24px;
`

export const ErrorMessage = styled('span')`
  font-size: 12px;
`
