import { Container, Title } from './styles'
import ReturnTimePage from '@/components/feature/ReturnTimePage/ReturnTimePage'

function ReturnTime() {
  return (
    <Container>
      <Title>คืนชั่วโมงความคุ้มครอง</Title>
      <ReturnTimePage />
    </Container>
  )
}

export default ReturnTime
