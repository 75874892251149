import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeListActiveHealthBillingByUserIdInputInterface,
  BackofficeListActiveHealthBillingByUserIdDataInterface,
} from './interfaces'

const backofficeListActiveHealthBillingByUserId: TypedDocumentNode<
  BackofficeListActiveHealthBillingByUserIdDataInterface,
  BackofficeListActiveHealthBillingByUserIdInputInterface
> = gql`
  query BackofficeListActiveHealthBillingByUserId($userId: ID!, $input: RequestPaginationInput!) {
    backofficeListActiveHealthBillingByUserId(userId: $userId, input: $input) {
      data {
        id
        activeHealthPolicy {
          insuredName
          plan
          creditCardType
          creditCardNumber
          identityId
        }
        policyNumber
        totalPendingBill
        startDate
        billDate
        actualDiscount
        totalManualDiscount
        billingDiscount
        billingAmount
        payDate
        paymentStatus
        premium
        creditCardType
        creditCardNumber
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
        sortBy
        searchBy
        search
      }
    }
  }
`

export default backofficeListActiveHealthBillingByUserId
