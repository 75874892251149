import CONSTANT from '@/constant'
import {
  Container,
  Menu,
  MenuItem,
  MenuHeader,
  BottomLine,
  Link,
  Icon,
  TitleCollapse,
  Badge,
} from './styles'
import SideBarHook from './SideBar.hook'
import { SideBarInterface } from './interfaces'
import { Fragment } from 'react'

function SideBar({ isSideBarCollapse }: SideBarInterface) {
  const { selectedKeys, menus, setSelectedKeys } = SideBarHook()

  return (
    <Container>
      <Menu
        mode="inline"
        selectedKeys={selectedKeys}
        defaultOpenKeys={[CONSTANT.ROUTES.POLICY_DETAIL_PAGE]}
        inlineCollapsed={isSideBarCollapse}
        onSelect={({ key }) => setSelectedKeys([key])}
      >
        {menus.map(
          (item, index) =>
            item.display && (
              <Fragment key={index}>
                {!isSideBarCollapse && <MenuHeader>{item.title}</MenuHeader>}
                {item.subMenus.map(
                  (subMenu, index) =>
                    subMenu.display && (
                      <Fragment key={index}>
                        <MenuItem
                          key={subMenu.key}
                          icon={<Icon src={subMenu.iconSource} />}
                          disabled={!subMenu.display}
                          data-testid={subMenu.id}
                        >
                          {subMenu.toExternal ? (
                            <a href={subMenu.to} target="_blank" rel="noreferrer">
                              {subMenu.title}
                            </a>
                          ) : (
                            <>
                              <Link to={subMenu.to}>{subMenu.title}</Link>
                              {!isSideBarCollapse && subMenu.notification?.notify ? (
                                <Badge count={subMenu.notification?.count} />
                              ) : undefined}
                            </>
                          )}
                        </MenuItem>
                        {isSideBarCollapse ? (
                          <TitleCollapse>{subMenu.title}</TitleCollapse>
                        ) : undefined}
                      </Fragment>
                    ),
                )}
                {item.hasBottomLine && !isSideBarCollapse ? <BottomLine /> : undefined}
              </Fragment>
            ),
        )}
      </Menu>
    </Container>
  )
}

export default SideBar
