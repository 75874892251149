import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useSetRecoilState } from 'recoil'

import { ppuMotorGql } from '@/gql'
import { HandleFetchDeviceDetailProps } from './types'
import { DeviceDetailProps } from '@/recoil/deviceDetail/types'
import deviceDetailAtom from '@/recoil/deviceDetail'
import { toThaiLocaleDateString } from '@/libs/date'

function DeviceDetailHook() {
  const setDeviceDetail = useSetRecoilState(deviceDetailAtom)
  const [deviceDetailQuery, { data: deviceDetailQueryData }] = useLazyQuery(
    ppuMotorGql.query.backofficeGetPpuMotorUserPolicyMotors,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const handleFetchDeviceDetail = ({ userId, policyNumber }: HandleFetchDeviceDetailProps) => {
    if (userId && policyNumber) {
      deviceDetailQuery({
        variables: {
          input: {
            userId,
            policyNumber,
            isUnbound: false,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (deviceDetailQueryData?.backofficeGetPpuMotorUserPolicyMotors[0]) {
      const {
        id,
        deviceId,
        imei,
        ppuMotorUserPolicy: { currentMode, currentStatus, policyNumber },
        createdAt,
        updatedAt,
        unboundAt,
        vendor,
        eSimNumber,
        serialNumber,
        broker,
      } = deviceDetailQueryData.backofficeGetPpuMotorUserPolicyMotors[0]

      const deviceDetail: DeviceDetailProps = {
        id: id,
        deviceId: deviceId,
        imei: imei,
        currentMode: currentMode,
        currentStatus: currentStatus,
        policyNumber: policyNumber,
        createdAt: toThaiLocaleDateString({ date: createdAt }),
        updatedAt: toThaiLocaleDateString({ date: updatedAt }),
        unboundAt: unboundAt ? toThaiLocaleDateString({ date: unboundAt }) : null,
        vendor,
        eSimNumber,
        serialNumber,
        broker,
      }

      setDeviceDetail(deviceDetail)
    }
  }, [deviceDetailQueryData])

  return {
    handleFetchDeviceDetail,
  }
}

export default DeviceDetailHook
