import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeListWelcomeCallPurchaseChannelDataInterface,
  BackofficeListWelcomeCallPurchaseChannelInputInterface,
} from './interfaces'

const backofficeListWelcomeCallPurchaseChannel: TypedDocumentNode<
  BackofficeListWelcomeCallPurchaseChannelDataInterface,
  BackofficeListWelcomeCallPurchaseChannelInputInterface
> = gql`
  query BackofficeListWelcomeCallPurchaseChannel($date: DateTime!) {
    backofficeListWelcomeCallPurchaseChannel(date: $date)
  }
`

export default backofficeListWelcomeCallPurchaseChannel
