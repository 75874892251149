import { useMutation } from '@apollo/client'
import { useRecoilState } from 'recoil'

import { backofficeUserGql } from '@/gql'
import { OnSubmitProps } from './types'
import forgotBackofficeUserPasswordAtom from '@/recoil/forgotBackofficeUserPassword'
import { ForgotBackofficeUserPasswordProps } from '@/recoil/forgotBackofficeUserPassword/types'

function ForgotPasswordFormHook() {
  const [forgotBackofficeUserPassword, setForgotBackofficeUserPassword] = useRecoilState(
    forgotBackofficeUserPasswordAtom,
  )
  const [forgotBackofficeUserPasswordMutation, { error: forgotBackofficeUserPasswordError }] =
    useMutation(backofficeUserGql.mutation.forgotBackofficeUserPassword, {
      fetchPolicy: 'no-cache',
    })

  const onSubmit = async (values: unknown | OnSubmitProps) => {
    if (values) {
      const { email } = values as OnSubmitProps

      const { data } = await forgotBackofficeUserPasswordMutation({
        variables: {
          input: {
            email,
          },
        },
      })

      if (data) {
        setForgotBackofficeUserPassword({
          ...data.forgotBackofficeUserPassword,
          isSuccessful: true,
        } as ForgotBackofficeUserPasswordProps)
      }
    }
  }

  return {
    onSubmit,
    forgotBackofficeUserPassword,
    forgotBackofficeUserPasswordError,
  }
}

export default ForgotPasswordFormHook
