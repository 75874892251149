import { Button } from 'antd'
import styled from '@emotion/styled'

export const ButtonAntd = styled(Button)`
  box-shadow: unset;
  border-radius: 8px;
  font-weight: 600 !important;
  font-size: 14px;

  :disabled {
    background-color: #ededf0;
    color: #ffffff;
    border: unset;
  }
`
