import styled from '@emotion/styled'

import { ContainerProps } from './types'
import { css } from '@emotion/css'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')<ContainerProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: 100%;
  padding: 24px 0px;
  margin: auto;
`

export const TableClassName = css`
  margin-top: 20px;

  .ant-table-thead > tr > th {
    background: #ebeff2;
  }
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #000000;
`

export const TableHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Button = styled(CommonButton)`
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  color: #000000;
`
