import { createContext } from 'react'
import { ContextTypes } from './types'
import { ActiveHealthPolicyStatusEnum } from '@/constant/ACTIVE_HEALTH_POLICY'

const defaultValue: ContextTypes = {
  policyStatus: ActiveHealthPolicyStatusEnum.ALL,
  setPolicyStatus: Function,
}

const PolicyManagementContext = createContext(defaultValue)

export default PolicyManagementContext
