import backofficeGetAllBackofficeMenuType from './backofficeGetAllBackofficeMenuType/backofficeGetAllBackofficeMenuType.gql'
import backofficeGetBackofficeTeamMenuPermission from './backofficeGetBackofficeTeamMenuPermission/backofficeGetBackofficeTeamMenuPermission.gql'
import backofficeGetMenuPermissionByMenuTypeId from './backofficeGetMenuPermissionByMenuTypeId/backofficeGetMenuPermissionByMenuTypeId.gql'

const backofficeMenu = {
  query: {
    backofficeGetAllBackofficeMenuType,
    backofficeGetMenuPermissionByMenuTypeId,
    backofficeGetBackofficeTeamMenuPermission,
  },
  mutation: {},
}

export default backofficeMenu
