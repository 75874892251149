export enum ActiveHealthManualDiscountEnum {
  POLICY_NUMBER = 'policyNumber',
}

export enum ActiveHealthManualDiscountFilterApproveStatusEnum {
  REJECT = 'reject',
  PENDING = 'pending',
  APPROVE = 'approve',
}

export interface ActiveHealthManualDiscountPaginationInterface {
  id: string
  policyNumber: string
  approveStatus: string
  user: {
    firstname: string
    lastname: string
  }
  refActiveHealthPolicyBilling: {
    id: string
    billDate: Date
    billingDiscount: number
    startDate: Date
    payDate: Date
  }
  activeHealthPolicyBilling: {
    id: string
    billDate: string
    billingDiscount: number
    isPvDiscountSuccess: boolean
  }
  activeHealthManualDiscountApprover: {
    name: string
    email: string
  }
  activeHealthPolicy: {
    plan: string
    insuredName: string
  }
  discount: number
  reason: string
  fileId: string
  fileKey: string
  createdAt: Date
  updatedAt: Date
  createdBy: {
    firstname: string
    lastname: string
  }
}

export interface BackofficeGetActiveHealthManualDiscountPaginationDataInterface {
  backofficeGetActiveHealthManualDiscountPagination: {
    data: Array<ActiveHealthManualDiscountPaginationInterface>
    meta: {
      totalItems: number
    }
  }
}

export interface BackofficeGetActiveHealthManualDiscountPaginationInputInterface {
  input: {
    searchBy: [ActiveHealthManualDiscountEnum.POLICY_NUMBER]
    search: string
    filter?: {
      approveStatus?: ActiveHealthManualDiscountFilterApproveStatusEnum
    }
  }
}
