import { Key, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge, MenuProps, TablePaginationConfig } from 'antd'
import { generatePath } from 'react-router'
import { ColumnsType } from 'antd/es/table'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { fromEvent, of, tap } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import dayjs from 'dayjs'
import { ColumnFilterItem, FilterValue } from 'antd/es/table/interface'
import { toThaiLocaleDateAndMonthRangeWithDash } from '@/libs/date'
import CONSTANT from '@/constant'
import { activeHealthPolicyGql } from '@/gql'
import {
  ActiveHealthBillingDiscountProblemSearchableEnum,
  ActiveHealthBillingDiscountProblemStatusEnum,
  ActiveHealthBillingDiscountProblemStatusFilterEnum,
  ActiveHealthBillingDiscountProblemType,
} from '@/gql/activeHealthPolicy/backofficeGetActiveHealthBillingDiscountProblemPagination/interfaces'
import { SortByEnum } from '@/constant/SORT_BY'
import { ActionText, Dropdown, Link, RowHighlightClassName } from './styles'
import { PaginationTypes } from './types'
import notificationAtom from '@/recoil/notification'
import { CaretDownOutlined } from '@ant-design/icons'
import { BadgeStatusEnum } from '@/constant/BADGE_STATUS'
import { ACTIVE_HEALTH_BILLING_DISCOUNT_PROBLEM_STATUS_TITLE } from '@/constant/DISCOUNT_ISSUE_PAGE'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { UserSelectStyle } from '@/components/common/Table/styles'

export default function DiscountIssueTableHook() {
  const [notification, setNotification] = useRecoilState(notificationAtom)
  const user = useRecoilValue(userAtom)
  const [
    backofficeGetActiveHealthBillingDiscountProblem,
    {
      data: activeHealthBillingDiscountProblemData,
      loading: activeHealthBillingDiscountProblemLoading,
    },
  ] = useLazyQuery(
    activeHealthPolicyGql.query.backofficeGetActiveHealthBillingDiscountProblemPagination,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const [pagination, setPagination] = useState<PaginationTypes>({
    page: 1,
    limit: 10,
    totalItems: 0,
  })
  const [
    countPendingActiveHealthBillingDiscountProblem,
    { loading: countPendingActiveHealthBillingDiscountProblemLoading },
  ] = useLazyQuery(
    activeHealthPolicyGql.query.backofficeCountPendingActiveHealthBillingDiscountProblemRecord,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const count = data.backofficerCountPendingActiveHealthBillingDiscountProblemRecord

        setNotification({
          ...notification,
          discountIssuePage: {
            count,
          },
        })
      },
    },
  )
  const [
    backofficeUpdateActiveHealthBillingDiscountProblemStatus,
    {
      data: updateActiveHealthBillingDiscountProblemStatusData,
      loading: updateActiveHealthBillingDiscountProblemStatusLoading,
    },
  ] = useMutation(
    activeHealthPolicyGql.mutation.backofficeUpdateActiveHealthBillingDiscountProblemStatus,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const isTableLoading =
    activeHealthBillingDiscountProblemLoading ||
    updateActiveHealthBillingDiscountProblemStatusLoading ||
    countPendingActiveHealthBillingDiscountProblemLoading
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const [isTyping, setIsTyping] = useState(false)
  const searchElement = document.getElementById('active-health-billing-discount-problem-search')
  const [selectStatusFilters, setSelectStatusFilters] = useState<Array<boolean | Key>>([])
  const statusColumnsFilters: Array<ColumnFilterItem> = [
    {
      text: ACTIVE_HEALTH_BILLING_DISCOUNT_PROBLEM_STATUS_TITLE.PENDING,
      value: ActiveHealthBillingDiscountProblemStatusFilterEnum.PENDING,
    },
    {
      text: ACTIVE_HEALTH_BILLING_DISCOUNT_PROBLEM_STATUS_TITLE.RESOLVED,
      value: ActiveHealthBillingDiscountProblemStatusFilterEnum.RESOLVED,
    },
  ]
  const menuItems = (
    activeHealthBillingDiscountProblem: ActiveHealthBillingDiscountProblemType,
  ): MenuProps['items'] => {
    return [
      {
        label: ACTIVE_HEALTH_BILLING_DISCOUNT_PROBLEM_STATUS_TITLE.PENDING,
        key: ActiveHealthBillingDiscountProblemStatusFilterEnum.PENDING,
        onClick: () =>
          updateActiveHealthBillingDiscountProblemStatus(
            activeHealthBillingDiscountProblem.id,
            ActiveHealthBillingDiscountProblemStatusFilterEnum.PENDING,
          ),
      },
      {
        label: ACTIVE_HEALTH_BILLING_DISCOUNT_PROBLEM_STATUS_TITLE.RESOLVED,
        key: ActiveHealthBillingDiscountProblemStatusFilterEnum.RESOLVED,
        onClick: () =>
          updateActiveHealthBillingDiscountProblemStatus(
            activeHealthBillingDiscountProblem.id,
            ActiveHealthBillingDiscountProblemStatusFilterEnum.RESOLVED,
          ),
      },
    ]
  }
  const columns: ColumnsType<ActiveHealthBillingDiscountProblemType | {}> = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      align: 'center',
      render: (_value, _record, number) => {
        return number + 1
      },
    },
    {
      title: 'ชื่อผู้เอาประกัน',
      width: 220,
      dataIndex: ['activeHealthPolicyBilling', 'activeHealthPolicy', 'insuredName'],
      className: UserSelectStyle,
    },
    {
      title: 'เลขที่กรมธรรม์',
      dataIndex: ['activeHealthPolicyBilling', 'activeHealthPolicy', 'policyNumber'],
      width: 220,
      className: UserSelectStyle,
    },
    {
      title: 'แผนประกัน',
      dataIndex: ['activeHealthPolicyBilling', 'activeHealthPolicy', 'plan'],
      className: UserSelectStyle,
    },
    {
      title: 'รอบบิลวันที่',
      width: 220,
      className: UserSelectStyle,
      render: (data) => {
        return toThaiLocaleDateAndMonthRangeWithDash(
          data?.activeHealthPolicyBilling?.startDate,
          data?.activeHealthPolicyBilling?.billDate,
        )
      },
    },
    {
      title: 'ค่าเบี้ย/รอบบิล',
      dataIndex: ['activeHealthPolicyBilling', 'billingAmount'],
      render: (billingAmount) => {
        return billingAmount.toLocaleString() || 0
      },
    },
    {
      title: 'รอบบิลที่ค้างชำระ',
      dataIndex: ['unpaidBillNumber'],
      render: (unpaidBillNumber) => {
        return unpaidBillNumber.toLocaleString() || 0
      },
    },
    {
      title: 'ส่วนลดส่งไป PV',
      dataIndex: ['activeHealthPolicyBilling', 'billingDiscount'],
      render: (billingDiscount) => {
        return billingDiscount.toLocaleString() || 0
      },
    },
    {
      title: 'ส่วนลดการขออนุโลม',
      dataIndex: ['activeHealthPolicyBilling', 'totalManualDiscount'],
      render: (totalManualDiscount) => {
        return totalManualDiscount.toLocaleString() || 0
      },
    },
    {
      title: 'รวมส่วนลดค่าเบี้ย',
      dataIndex: '',
      render: (_value, record) => {
        const {
          activeHealthPolicyBilling: { actualDiscount, totalManualDiscount },
        } = record as ActiveHealthBillingDiscountProblemType

        return (actualDiscount + totalManualDiscount).toLocaleString() || 0
      },
    },
    {
      title: 'ยอดค่าเบี้ยที่ชำระแล้ว',
      dataIndex: ['activeHealthPolicyBilling', 'premium'],
      render: (premium) => {
        return premium.toLocaleString() || 0
      },
    },
    {
      title: 'วันที่ชำระ',
      dataIndex: ['activeHealthPolicyBilling', 'payDate'],
      render: (payDate) => {
        return payDate || '-'
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      filters: statusColumnsFilters,
      filteredValue: selectStatusFilters,
      render: (status, record, index) => {
        const activeHealthBillingDiscountProblem = record as ActiveHealthBillingDiscountProblemType
        const isResolve = status === ActiveHealthBillingDiscountProblemStatusEnum.RESOLVED
        const canUpdateStatus = validatePerm(
          [BACKOFFICE_USER_PERMISSION.DIS010103],
          user.permissions,
        )

        return (
          <div data-testid={`billing-discount-issue-table-update-status-dropdown-${index}`}>
            <Dropdown
              menu={{ items: menuItems(activeHealthBillingDiscountProblem) }}
              disabled={!canUpdateStatus}
            >
              <Link>
                <Badge status={isResolve ? BadgeStatusEnum.SUCCESS : BadgeStatusEnum.PROCESSING} />{' '}
                <span>
                  {isResolve
                    ? ACTIVE_HEALTH_BILLING_DISCOUNT_PROBLEM_STATUS_TITLE.RESOLVED
                    : ACTIVE_HEALTH_BILLING_DISCOUNT_PROBLEM_STATUS_TITLE.PENDING}
                </span>{' '}
                {canUpdateStatus ? <CaretDownOutlined /> : undefined}
              </Link>
            </Dropdown>
          </div>
        )
      },
    },
    {
      title: 'วันที่แก้ไข',
      dataIndex: 'resolvedAt',
      width: 220,
      render: (resolvedAt) => {
        return resolvedAt ? dayjs(resolvedAt).format('DD-MM-YYYY HH:mm:ss') : '-'
      },
    },
    {
      title: 'แก้ไขโดย',
      dataIndex: '',
      width: 220,
      render: (_value, record) => {
        const { resolvedBy } = record as ActiveHealthBillingDiscountProblemType

        if (resolvedBy?.firstname && resolvedBy?.lastname) {
          return `${resolvedBy.firstname} ${resolvedBy.lastname}`
        }

        return '-'
      },
    },
    validatePerm([BACKOFFICE_USER_PERMISSION.DIS010102], user.permissions)
      ? {
          title: '',
          key: 'action',
          dataIndex: 'action',
          width: 120,
          fixed: 'right',
          align: 'center',
          render: (_value, _record, index) => {
            return (
              <ActionText data-testid={`billing-discount-issue-redirect-button-${index}`}>
                จัดการค่าเบี้ย
              </ActionText>
            )
          },
          onCell: (data) => {
            const {
              activeHealthPolicyBilling: {
                activeHealthPolicy: { policyNumber },
              },
            } = data as ActiveHealthBillingDiscountProblemType

            return {
              onClick: () => {
                redirectToPolicyDetailPage(policyNumber)
              },
            }
          },
        }
      : {
          width: 0,
        },
  ]

  const getActiveHealthBillingDiscountProblem = useCallback(
    ({
      page,
      limit,
      search,
      filters,
    }: {
      page: number
      limit: number
      search: string
      filters?: { status: Array<boolean | Key> }
    }) => {
      backofficeGetActiveHealthBillingDiscountProblem({
        variables: {
          input: {
            page,
            limit,
            search,
            searchBy: [ActiveHealthBillingDiscountProblemSearchableEnum.INSURANCE_NAME],
            sortBy: [['createdAt', SortByEnum.DESC]],
            filter: {
              status:
                filters && filters.status && filters?.status?.length > 0
                  ? `$in:${filters.status.join()}`
                  : undefined,
            },
          },
        },
      })
    },
    [backofficeGetActiveHealthBillingDiscountProblem],
  )

  const handleSetSearch = (input: string) =>
    of(setSearch(input)).pipe(
      tap(() => {
        setPagination({
          page: 1,
          limit: 10,
          totalItems: pagination.totalItems,
        })
      }),
    )

  const redirectToPolicyDetailPage = (policyNumber: string) => {
    navigate(generatePath(CONSTANT.ROUTES.POLICY_DETAIL_PAGE, { searchInput: policyNumber }), {
      state: { referrer: CONSTANT.ROUTES.DISCOUNT_ISSUE_PAGE, policyNumber },
    })
  }

  const handleOnChangeTable = ({
    pagination,
    filters,
  }: {
    pagination: TablePaginationConfig
    filters: Record<string, FilterValue | null>
  }) => {
    if (pagination.current && pagination.pageSize) {
      setPagination({
        page: pagination.current,
        limit: pagination.pageSize,
      })
    }

    if (filters.status && filters.status.length > 0) {
      setSelectStatusFilters(filters.status)
    } else {
      setSelectStatusFilters([])
    }
  }

  const handleSearchInputChange = (isTyping: boolean) => setIsTyping(isTyping)

  const updateActiveHealthBillingDiscountProblemStatus = useCallback(
    (id: string, status: ActiveHealthBillingDiscountProblemStatusFilterEnum) => {
      backofficeUpdateActiveHealthBillingDiscountProblemStatus({
        variables: {
          input: {
            activeHealthBillingDiscountProblemId: id,
            status,
          },
        },
      })
    },
    [backofficeUpdateActiveHealthBillingDiscountProblemStatus],
  )

  const handleDisplayHighlightRow = (record: ActiveHealthBillingDiscountProblemType | {}) => {
    const data = record as ActiveHealthBillingDiscountProblemType

    return data.status !== ActiveHealthBillingDiscountProblemStatusEnum.RESOLVED
      ? RowHighlightClassName
      : ''
  }

  useEffect(() => {
    getActiveHealthBillingDiscountProblem({
      page: pagination.page,
      limit: pagination.limit,
      search,
      filters: { status: selectStatusFilters },
    })
  }, [
    search,
    pagination.page,
    pagination.limit,
    updateActiveHealthBillingDiscountProblemStatusData?.backofficeUpdateActiveHealthBillingDiscountProblemStatus,
    selectStatusFilters,
  ])

  useEffect(() => {
    if (
      activeHealthBillingDiscountProblemData
        ?.backofficeGetActiveHealthBillingDiscountProblemPagination?.meta
    ) {
      const meta =
        activeHealthBillingDiscountProblemData
          .backofficeGetActiveHealthBillingDiscountProblemPagination.meta
      setPagination({
        page: meta.currentPage,
        limit: meta.itemsPerPage,
        totalItems: meta.totalItems,
      })
    }
    countPendingActiveHealthBillingDiscountProblem()
  }, [activeHealthBillingDiscountProblemData])

  useEffect(() => {
    if (isTyping && searchElement) {
      fromEvent(searchElement, 'keyup')
        .pipe(
          debounceTime(1000),
          map((e: Event) => {
            const target = e.target as HTMLInputElement

            return target.value || ''
          }),
          distinctUntilChanged(),
          switchMap(handleSetSearch),
          tap(() => setIsTyping(false)),
        )
        .subscribe()
    }
  }, [isTyping, searchElement])

  useEffect(() => {
    getActiveHealthBillingDiscountProblem({
      page: pagination.page,
      limit: pagination.limit,
      search: '',
    })
    countPendingActiveHealthBillingDiscountProblem()
  }, [])

  return {
    columns,
    pagination,
    handleSearchInputChange,
    activeHealthBillingDiscountProblemData,
    isTableLoading,
    handleDisplayHighlightRow,
    handleOnChangeTable,
  }
}
