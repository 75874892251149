import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import { IMAGE_URL } from '@/constant/IMAGE'
import { Space } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { ButtonIcon } from './styles'
import { PaginationTypes } from './types'
import message from '@/libs/message'
import { BackofficeGetBackofficeUserTeamRoleType } from '@/gql/backofficeTeam/backofficeGetBackofficeUserTeamRolePaginated/interfaces'
import { useLazyQuery, useMutation } from '@apollo/client'
import backofficeTeam from '@/gql/backofficeTeam'
import debounce from 'lodash/debounce'
import ModalAddUserHook from '../components/ModalAddUser/ModalAddUser.hook'
import { ModeUserEnum } from '@/recoil/userModal/types'

const TabUserHook = () => {
  const { setConfirmModal } = ConfirmModalHook()
  const { setUserModal } = ModalAddUserHook()

  const [backofficeUserTeamRole, setBackofficeUserTeamRole] =
    useState<Array<BackofficeGetBackofficeUserTeamRoleType>>()

  const [searchValue, setSearchValue] = useState<string>('')
  const [pagination, setPagination] = useState<PaginationTypes>({
    page: 1,
    limit: 10,
    totalItems: 0,
  })

  const [
    postBackofficeDeleteBackofficeTeamFromBackofficeUserMutation,
    { error: errorBackofficeDeleteBackofficeTeamFromBackofficeUser },
  ] = useMutation(backofficeTeam.mutation.backofficeDeleteBackofficeTeamFromBackofficeUser, {
    fetchPolicy: 'no-cache',
  })

  const [
    getBackofficeUserTeamRolePaginatedQuery,
    {
      loading: loadingGetBackofficeUserTeamRolePaginatedQuery,
      refetch: refetchGetBackofficeUserTeamRolePaginatedQuery,
    },
  ] = useLazyQuery(backofficeTeam.query.backofficeGetBackofficeUserTeamRolePaginated, {
    variables: {
      input: {
        page: 1,
        limit: 10,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { meta, data: dataUserTeamRole } = data.backofficeGetBackofficeUserTeamRolePaginated

      setBackofficeUserTeamRole(dataUserTeamRole)

      setPagination({
        page: meta.currentPage,
        limit: meta.itemsPerPage,
        totalItems: meta.totalItems,
      })
    },
  })

  const columns: ColumnsType<BackofficeGetBackofficeUserTeamRoleType | {}> = [
    {
      title: 'ลำดับ',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '5%',
      render: (_value, _record, number) => {
        return number + 1
      },
    },
    {
      title: 'ชื่อ-นามสกุล',
      align: 'left',
      width: '20%',
      render: (_data, record) => {
        const { backofficeUser } = record as BackofficeGetBackofficeUserTeamRoleType

        return `${backofficeUser?.firstname || ''} ${backofficeUser?.lastname || ''}`
      },
    },
    {
      title: 'เบอร์โทรศัพท์',
      key: 'phone',
      width: '10%',
      render: (_data, record) => {
        const { backofficeUser } = record as BackofficeGetBackofficeUserTeamRoleType

        return `${backofficeUser?.mobileNumber || ''}`
      },
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      render: (_data, record) => {
        const { backofficeUser } = record as BackofficeGetBackofficeUserTeamRoleType

        return `${backofficeUser.email || ''}`
      },
    },
    {
      title: 'ทีม',
      dataIndex: 'team',
      key: 'team',
      width: '10%',
      render: (_data, record) => {
        const { backofficeTeam } = record as BackofficeGetBackofficeUserTeamRoleType
        return `${backofficeTeam.name || ''}`
      },
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'position',
      key: 'position',
      align: 'center',
      width: '10%',
      render: (_data, record) => {
        const { backofficeRole } = record as BackofficeGetBackofficeUserTeamRoleType
        return `${backofficeRole.title || ''}`
      },
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: (_data, record, index) => {
        const { backofficeRole, backofficeTeam, backofficeUser } =
          record as BackofficeGetBackofficeUserTeamRoleType

        return (
          <Space size="middle">
            <ButtonIcon
              data-testid={`user-permission-user-tab-user-list-table-edit-user-${index}`}
              src={IMAGE_URL.editGreyIcon}
              onClick={() => {
                const dataUser = {
                  userId: backofficeUser.id,
                  roleId: backofficeRole.id,
                  roleName: backofficeRole.title,
                  teamId: backofficeTeam.id,
                  teamName: backofficeTeam.name,
                  name: backofficeUser.firstname,
                  lastName: backofficeUser.lastname,
                  phone: String(backofficeUser.mobileNumber),
                  email: backofficeUser.email,
                }

                if (dataUser)
                  setUserModal({
                    title: 'แก้ไขข้อมูลผู้ใช้งาน',
                    onOkFunction: () => {
                      refetchGetBackofficeUserTeamRolePaginatedQuery()
                      setUserModal({
                        isVisible: false,
                        onOkFunction: Function,
                        mode: ModeUserEnum.CREATE,
                        okButtonProps: { type: 'primary', danger: false },
                        dataUser: undefined,
                      })
                    },
                    isVisible: true,
                    okButtonProps: { type: 'primary', danger: false },
                    mode: ModeUserEnum.UPDATE,
                    dataUser,
                  })
              }}
            />
            <ButtonIcon
              data-testid={`user-permission-user-tab-user-list-table-delete-user-${index}`}
              src={IMAGE_URL.tableTrashIcon}
              onClick={() => {
                setConfirmModal({
                  onOkFunction: () => {
                    postBackofficeDeleteBackofficeTeamFromBackofficeUserMutation({
                      variables: {
                        input: {
                          backofficeUserId: backofficeUser.id,
                          backofficeTeamId: backofficeTeam.id,
                        },
                      },
                    })
                      .finally(() => {
                        refetchGetBackofficeUserTeamRolePaginatedQuery()
                        setConfirmModal({
                          isVisible: false,
                          onOkFunction: Function,
                          dataTestId: undefined,
                        })
                      })
                      .catch(() => {
                        message.error({
                          content: `ปัญหา: ${errorBackofficeDeleteBackofficeTeamFromBackofficeUser?.message}`,
                          duration: 10,
                        })
                      })
                  },
                  isVisible: true,
                  title: 'คุณต้องการลบผู้ใช้งาน',
                  bodyText: `คุณแน่ใจหรือไม่ที่จะทำการลบผู้ใช้งาน “${backofficeUser.firstname} ${backofficeUser.lastname}” ออกจากทีม "${backofficeTeam.name}"`,
                  okText: 'ลบ',
                  okButtonProps: { type: 'primary', danger: true },
                  dataTestId: 'delete-user-modal',
                })
              }}
            />
          </Space>
        )
      },
    },
  ]

  const handleSetSearch = useCallback(
    (word?: string) => {
      setSearchValue(word || '')

      if (word) {
        getBackofficeUserTeamRolePaginatedQuery({
          variables: {
            input: {
              page: 1,
              limit: 10,
              search: word,
            },
          },
          fetchPolicy: 'no-cache',
          onCompleted: (data) => {
            const { meta, data: dataUserTeamRole } =
              data.backofficeGetBackofficeUserTeamRolePaginated

            setBackofficeUserTeamRole(dataUserTeamRole)

            setPagination({
              page: meta.currentPage,
              limit: meta.itemsPerPage,
              totalItems: meta.totalItems,
            })
          },
        })
      } else {
        getBackofficeUserTeamRolePaginatedQuery({
          variables: {
            input: {
              page: pagination.page,
              limit: pagination.limit,
              search: word,
            },
          },
          fetchPolicy: 'no-cache',
          onCompleted: (data) => {
            const { meta, data: dataUserTeamRole } =
              data.backofficeGetBackofficeUserTeamRolePaginated

            setBackofficeUserTeamRole(dataUserTeamRole)

            setPagination({
              page: meta.currentPage,
              limit: meta.itemsPerPage,
              totalItems: meta.totalItems,
            })
          },
        })
      }
    },
    [getBackofficeUserTeamRolePaginatedQuery, pagination.limit, pagination.page],
  )

  const handleAddUserModal = () => {
    setUserModal({
      title: 'สร้างผู้ใช้งาน',
      onOkFunction: () => {
        setUserModal({
          isVisible: false,
          onOkFunction: Function,
          mode: ModeUserEnum.CREATE,
          okButtonProps: { type: 'primary', danger: false },
        })
        refetchGetBackofficeUserTeamRolePaginatedQuery()
      },
      isVisible: true,
      okButtonProps: { type: 'primary', danger: false },
      mode: ModeUserEnum.CREATE,
    })
  }

  const debouncedSearch = useRef(
    debounce((event?: ChangeEvent<HTMLInputElement>) => {
      const query = event?.target.value
      const value = query !== '' ? query : undefined

      return handleSetSearch(value)
    }, 400),
  ).current

  const onHandlePageChange = (page: number, pageSize: number) => {
    getBackofficeUserTeamRolePaginatedQuery({
      variables: {
        input: {
          search: searchValue,
          page: page,
          limit: pageSize,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const { meta, data: dataUserTeamRole } = data.backofficeGetBackofficeUserTeamRolePaginated

        setBackofficeUserTeamRole(dataUserTeamRole)

        setPagination({
          page: meta.currentPage,
          limit: meta.itemsPerPage,
          totalItems: meta.totalItems,
        })
      },
    })
  }

  useEffect(() => {
    getBackofficeUserTeamRolePaginatedQuery()
  }, [])

  return {
    backofficeUserTeamRole,
    columns,
    pagination,
    debouncedSearch,
    setUserModal,
    handleAddUserModal,
    onHandlePageChange,
    loadingGetBackofficeUserTeamRolePaginatedQuery,
  }
}

export default TabUserHook
