import DeviceImportCard from '@/components/feature/TviConnectPage/DeviceImportCard'
import DeviceImportFailureTable from '@/components/feature/TviConnectPage/DeviceImportFailureTable'
import { Container, Title } from './styles'

function TviConnect() {
  return (
    <Container>
      <Title>TVI-Connect</Title>
      <DeviceImportCard />
      <DeviceImportFailureTable />
    </Container>
  )
}

export default TviConnect
