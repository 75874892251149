import { ReactElement } from 'react'
import { ConfigProvider } from 'antd'
import { CacheProvider, css, Global, ThemeProvider } from '@emotion/react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { legacyLogicalPropertiesTransformer, StyleProvider } from '@ant-design/cssinjs'
import thTH from 'antd/es/locale/th_TH'
import 'dayjs/locale/th'
import 'antd/dist/reset.css'
import AdapterDateFns from '@tarzui/date-fns-be'
import { th } from 'date-fns/locale'

import { MuiTheme } from '@/theme'
import AntdTheme from '@/theme/AntdTheme'
import config from '@/config'
import ResetCSS from '@/styles/ResetCSS'
import createEmotionCache from '@/libs/createEmotionCache'
import { typographyCSS } from '@/styles/typography'

import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser'
import { msalInstance, MsalProvider } from './msal'
import CONSTANT from '@/constant'

import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from '@/libs/httpClient'
import { AxiosProvider } from './axios'

interface ProviderInterface {
  children: ReactElement
}

function Provider({ children }: ProviderInterface) {
  const httpLink = createHttpLink({
    uri: config.graphqlEndpoint,
  })

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    const unauthorized = graphQLErrors?.some(({ extensions }) => {
      const { code } = extensions
      return code === 'UNAUTHORIZED'
    })

    if (unauthorized) {
      return new Observable((observer) => {
        const account = msalInstance.getActiveAccount()
        if (!account) {
          return
        }

        const loginRequest = {
          scopes: ['openid', 'User.Read'],
          account,
        }

        const setAuthToken = (response: AuthenticationResult) => {
          const accessToken = response.idToken

          localStorage.setItem('accessToken', accessToken)
          operation.setContext({
            headers: {
              authorization: accessToken ? `Bearer ${accessToken}` : '',
            },
          })
        }

        const retryRequest = () => {
          // Retry last failed request
          forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        }

        const handleTokenAcquisition = async () => {
          try {
            const response = await msalInstance.acquireTokenSilent(loginRequest)
            setAuthToken(response)
            retryRequest()
          } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
              try {
                const response = await msalInstance.acquireTokenPopup(loginRequest)
                setAuthToken(response)
                retryRequest()
              } catch (err) {
                // No refresh or client token available, force the user to login
                observer.error(err)
              }
            } else {
              observer.error(error)
            }
          }
        }

        handleTokenAcquisition()
      })
    }

    const userNotFound = graphQLErrors?.some(({ extensions }) => {
      const { code } = extensions
      return code === 'BIZBOUAUTH1004'
    })

    if (userNotFound) {
      localStorage.clear()
      window.location.pathname = CONSTANT.ROUTES.USER_NOT_FOUND_PAGE
    }
  })

  const authLink = setContext((_, { headers }) => {
    const accessToken = localStorage.getItem('accessToken')
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(errorLink.concat(httpLink)),
    cache: new InMemoryCache(),
  })

  const clientSideEmotionCache = createEmotionCache()

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={clientSideEmotionCache}>
          <StyleProvider transformers={[legacyLogicalPropertiesTransformer]}>
            <ThemeProvider theme={MuiTheme}>
              <MuiThemeProvider theme={MuiTheme}>
                <ConfigProvider locale={thTH} theme={AntdTheme}>
                  {/* @ts-ignore */}
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th}>
                    <Global
                      styles={css`
                        ${ResetCSS}
                        ${typographyCSS}
                      `}
                    />
                    <MsalProvider>
                      <AxiosProvider>{children}</AxiosProvider>
                    </MsalProvider>
                  </LocalizationProvider>
                </ConfigProvider>
              </MuiThemeProvider>
            </ThemeProvider>
          </StyleProvider>
        </CacheProvider>
      </QueryClientProvider>
    </ApolloProvider>
  )
}

export default Provider
