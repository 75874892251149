export enum PolicySuperAppStatusEnum {
  A = 'A',
  C = 'C',
  E = 'E',
  F = 'F',
  P = 'P',
  S = 'S',
}

export const POLICY_SUPER_APP_STATUS = {
  [PolicySuperAppStatusEnum.A]: 'มีผลคุ้มครอง',
  [PolicySuperAppStatusEnum.C]: 'ยกเลิก',
  [PolicySuperAppStatusEnum.E]: 'หมดอายุ',
  [PolicySuperAppStatusEnum.F]: 'ยังไม่มีผลคุ้มครอง',
  [PolicySuperAppStatusEnum.P]: 'เปิด-ปิด',
  [PolicySuperAppStatusEnum.S]: 'ยกเลิกตั้งแต่วันเริ่มคุ้มครอง',
}
