import { useContext } from 'react'
import { PolicyManagementContext } from '@/contexts'

function ActiveHealthPolicyFilterTabHook() {
  const { policyStatus, setPolicyStatus } = useContext(PolicyManagementContext)

  return { policyStatus, setPolicyStatus }
}

export default ActiveHealthPolicyFilterTabHook
