import { SortByEnum } from '@/constant/SORT_BY'

export enum ActiveHealthBillingDiscountProblemSearchableEnum {
  INSURANCE_NAME = 'activeHealthPolicyBilling.activeHealthPolicy.insuredName',
}

export enum ActiveHealthBillingDiscountProblemStatusEnum {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
}

export enum ActiveHealthBillingDiscountProblemStatusFilterEnum {
  PENDING = 'pending',
  RESOLVED = 'resolved',
}

export interface ActiveHealthBillingDiscountProblemType {
  id: string
  status: string
  resolvedAt?: Date
  unpaidBillNumber?: number
  resolvedBy?: {
    firstname: string
    lastname: string
  }
  activeHealthPolicyBilling: {
    activeHealthPolicy: {
      insuredName: string
      policyNumber: string
      phoneNumber: string
      plan: string
    }
    startDate: Date
    billDate: Date
    billingAmount: number
    totalPendingBill: number
    billingDiscount: number
    totalManualDiscount: number
    actualDiscount: number
    payDate: Date
    premium: number
  }
}

export interface BackofficeGetActiveHealthBillingDiscountProblemPaginationDataInterface {
  backofficeGetActiveHealthBillingDiscountProblemPagination: {
    data: Array<ActiveHealthBillingDiscountProblemType>
    meta: {
      itemsPerPage: number
      totalItems: number
      totalPages: number
      currentPage: number
      previousPage: number | null
      nextPage: number
      sortBy: Array<['createdAt', SortByEnum]>
      searchBy: Array<ActiveHealthBillingDiscountProblemSearchableEnum>
      search: string
    }
  }
}

export interface BackofficeGetActiveHealthBillingDiscountProblemPaginationInputInterface {
  input: {
    page: number
    limit: number
    sortBy: Array<['createdAt', SortByEnum]>
    searchBy: Array<ActiveHealthBillingDiscountProblemSearchableEnum>
    search: string
    filter?: {
      status?: string
    }
  }
}

export interface BackofficeCountPendingActiveHealthBillingDiscountProblemRecordDataInterface {
  backofficerCountPendingActiveHealthBillingDiscountProblemRecord: number
}
