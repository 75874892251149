import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeChangePpuMotorPolicyModeDataInterface,
  BackofficeChangePpuMotorPolicyModeInputInterface,
} from './interfaces'

export const backofficeChangePpuMotorPolicyMode: TypedDocumentNode<
  BackofficeChangePpuMotorPolicyModeDataInterface,
  BackofficeChangePpuMotorPolicyModeInputInterface
> = gql`
  mutation BackofficeChangePpuMotorPolicyMode($input: BackofficeChangePpuMotorPolicyModeInput!) {
    backofficeChangePpuMotorPolicyMode(input: $input) {
      policyNumber
      currentMode
      currentStatus
    }
  }
`
export default backofficeChangePpuMotorPolicyMode
