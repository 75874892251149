import styled from '@emotion/styled'
import { Input } from 'antd'
import CommonButton from '@/components/common/Button'

export const Title = styled('div')`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
`

export const Container = styled('div')`
  display: grid;
`

export const SearchWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const SearchInput = styled(Input)`
  width: 239px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 24px;
`

export const Button = styled(CommonButton)`
  line-height: 20px;
  box-shadow: none;
  padding: 10px 16px;
`

export const ButtonIcon = styled('img')`
  cursor: pointer;
`

export const NoTeamMemberWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

export const TitleNoTeamMember = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`

export const DescriptionNoTeamMember = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

export const AddMemberIcon = styled('img')`
  height: 48px;
  width: 48px;
  margin-bottom: 12px;
`
