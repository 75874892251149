import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeListWelcomeCallPaymentMethodDataInterface,
  BackofficeListWelcomeCallPaymentMethodInputInterface,
} from './interfaces'

const backofficeListWelcomeCallPaymentMethod: TypedDocumentNode<
  BackofficeListWelcomeCallPaymentMethodDataInterface,
  BackofficeListWelcomeCallPaymentMethodInputInterface
> = gql`
  query BackofficeListWelcomeCallPaymentMethod($date: DateTime!) {
    backofficeListWelcomeCallPaymentMethod(date: $date)
  }
`

export default backofficeListWelcomeCallPaymentMethod
