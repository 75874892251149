export enum BackofficeActiveHealthExerciseStatPeriodEnum {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export interface BackofficeActiveHealthExerciseStatDataInterface {
  data: Array<{
    date: string
    step: number
    minute: number
    point: number
    wellBeing: number
  }>
  meta: {
    period: string
    next: string
    previous: string
  }
}

export interface BackofficeActiveHealthExerciseStatInterface {
  backofficeGetUserExerciseStat: BackofficeActiveHealthExerciseStatDataInterface
}

export interface BackofficeActiveHealthExerciseStatInputInterface {
  input: {
    userId?: string
    policyNumber: string
    period: BackofficeActiveHealthExerciseStatPeriodEnum
    date?: string
  }
}
