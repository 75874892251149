import { gql, TypedDocumentNode } from '@apollo/client'

export const backofficeResetActiveHealthWearable: TypedDocumentNode<{}, { id: string }> = gql`
  mutation BackofficeResetActiveHealthWearable($id: ID!) {
    backofficeResetActiveHealthWearable(id: $id) {
      code
      message
    }
  }
`

export default backofficeResetActiveHealthWearable
