import { gql, TypedDocumentNode } from '@apollo/client'
import {
  UpdateBackofficeFAQInfoDataInterface,
  UpdateBackofficeFAQInfoInputInterface,
} from './interfaces'

const updateBackofficeFAQInfo: TypedDocumentNode<
  UpdateBackofficeFAQInfoDataInterface,
  UpdateBackofficeFAQInfoInputInterface
> = gql`
  mutation UpdateBackofficeFAQInfo($input: UpdateBackofficeFAQInfoActiveHealthInput!) {
    updateBackofficeFAQInfo(input: $input) {
      id
      faqCategoryId
      title
      description
      status
      createBy {
        id
        lastname
        firstname
      }
      createAt
      order
    }
  }
`

export default updateBackofficeFAQInfo
