import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { ColumnsType } from 'antd/lib/table'
import deviceImportAtom from '@/recoil/deviceImport'
import { ReasonLabelProps } from './types'

const reasonLabel = {
  SERIAL_NUMBER_DUPLICATION: 'Serial no. ถูกใช้งานไปแล้ว',
  CHASSIS_NUMBER_NOT_FOUND: 'ไม่พบ Chassis no. ในระบบ',
  CHASSIS_NUMBER_IS_ALREADY_BOUND: 'Chassis no. ถูกผูกไปแล้ว',
} as ReasonLabelProps

function DeviceImportFailureTableHook() {
  const [failResults, setFailResults] = useState<any[]>([])
  const [deviceImport] = useRecoilState(deviceImportAtom)
  const columns: ColumnsType<{}> = [
    {
      title: 'Chassis no.',
      dataIndex: 'chassisNumber',
      key: 'chassisNumber',
    },
    {
      title: 'IMEI',
      dataIndex: 'imei',
      key: 'imei',
    },
    {
      title: 'Serial no.',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
    },
    {
      title: 'eSim no.',
      dataIndex: 'eSimNumber',
      key: 'eSimNumber',
    },
    {
      title: 'เหตุผล',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
    },
  ]

  useEffect(() => {
    if (deviceImport.devices.length > 0) {
      const result = [] as string[]

      for (const device of deviceImport.devices) {
        result.push({
          ...device,
          reason: reasonLabel[device.reason] ? reasonLabel[device.reason] : device.reason,
        })
      }

      setFailResults(result)
    }
  }, [deviceImport])

  return {
    deviceImport,
    columns,
    failResults,
  }
}

export default DeviceImportFailureTableHook
