import { Container, Title } from './styles'
import WelcomeCallTable from '@/components/feature/WelcomeCallPage/WelcomeCallTable'
import WelcomeCallImporter from '@/components/feature/WelcomeCallPage/WelcomeCallImporter'
import WelcomeCallHook from './WelcomeCall.hook'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import UnauthorizedPage from '@/pages/Unauthorized'

function WelcomeCall() {
  const { openWelcomeCallImporter, closeWelcomeCallImporter, isRenderImporter, user } =
    WelcomeCallHook()
  return (
    <>
      {validatePerm([BACKOFFICE_USER_PERMISSION.WEL010101], user.permissions) ? (
        <Container>
          {isRenderImporter ? (
            <WelcomeCallImporter closeWelcomeCallImporter={closeWelcomeCallImporter} />
          ) : (
            <>
              <Title>Welcome call</Title>
              <WelcomeCallTable openWelcomeCallImporter={openWelcomeCallImporter} />
            </>
          )}
        </Container>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default WelcomeCall
