import { gql, TypedDocumentNode } from '@apollo/client'
import {
  backofficeFAQCategoriesByModuleNameDataInterface,
  backofficeFAQCategoriesByModuleNameInputInterface,
} from './interfaces'

const backofficeFAQCategoriesByModuleName: TypedDocumentNode<
  backofficeFAQCategoriesByModuleNameDataInterface,
  backofficeFAQCategoriesByModuleNameInputInterface
> = gql`
  query BackofficeFAQCategoriesByModuleName($input: GetBackofficeFAQCategoryActiveHealthInput!) {
    backofficeFAQCategoriesByModuleName(input: $input) {
      id
      title
      order
    }
  }
`

export default backofficeFAQCategoriesByModuleName
