import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import dayjs from 'dayjs'
import sideBarInformationAtom from '@/recoil/sideBarInformation'
import backofficeActiveHealthExercise from '@/gql/backofficeActiveHealthExercise'
import { useLazyQuery } from '@apollo/client'
import policyAtom from '@/recoil/policy'
import { BackofficeActiveHealthSpecialPointHighlightPeriodEnum } from '@/gql/backofficeActiveHealthExercise/backofficeGetActiveHealthSpecialPointHighlight/interfaces'
import { ActiveHealthSpecialPointHighlight } from '@/components/feature/PolicyDetailPage/ActivityTab/types'
import { ActiveHealthPolicyBillingType } from '@/gql/activeHealthPolicy/backofficeListActiveHealthBillingByUserId/interfaces'
import { CreditCardTypeEnum } from '@/constant/ACTIVE_HEALTH_POLICY_BILLING'
import { BillingStatusEnum } from '@/constant/BILLING_STATUS'
import { isCurrentMonthlyBilling } from '@/libs/date'
import { PolicySuperAppStatusEnum } from '@/constant/POLICY_SUPER_APP_STATUS'

function BillingInformationHook() {
  const [sideBarInformation, setSideBarInformation] = useRecoilState(sideBarInformationAtom)
  const policy = useRecoilValue(policyAtom)
  const [specialPointHighlights, setSpecialPointHighlights] = useState<
    Array<ActiveHealthSpecialPointHighlight>
  >([])
  const [
    backofficeGetActiveHealthSpecialPointHighlight,
    { data: specialPointHighlightData, loading: specialPointHighlightLoading },
  ] = useLazyQuery(
    backofficeActiveHealthExercise.query.backofficeGetActiveHealthSpecialPointHighlight,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const openSideBarChildren = ({
    sideBarChildrenTitle,
    sideBarChildren,
    sideBarChildrenVisible,
  }: {
    sideBarChildrenVisible: boolean
    sideBarChildrenTitle: string
    sideBarChildren: React.ReactNode
  }) =>
    setSideBarInformation({
      visible: sideBarInformation.visible,
      children: sideBarInformation.children,
      title: sideBarInformation.title,
      sideBarChildrenVisible: sideBarChildrenVisible,
      sideBarChildrenTitle: sideBarChildrenTitle,
      sideBarChildren: sideBarChildren,
      dataTestId: 'billing-sidebar',
      childrenDataTestId: 'billing-children-sidebar',
    })

  const getSpecialPointHighlight = useCallback(
    (billingStartDate: Date) => {
      backofficeGetActiveHealthSpecialPointHighlight({
        variables: {
          input: {
            period: BackofficeActiveHealthSpecialPointHighlightPeriodEnum.MONTHLY,
            date: dayjs(billingStartDate).format('YYYY-MM'),
          },
        },
      })
    },
    [backofficeGetActiveHealthSpecialPointHighlight],
  )

  const displayLastDigits = (card: string) => (card ? `**** **** **** ${card}` : undefined)

  const getCreditCardType = (
    activeHealthPolicyBilling: ActiveHealthPolicyBillingType,
  ): CreditCardTypeEnum | null => {
    if (activeHealthPolicyBilling.paymentStatus === BillingStatusEnum.PAID) {
      return activeHealthPolicyBilling.creditCardType
    }

    return activeHealthPolicyBilling.activeHealthPolicy.creditCardType
  }

  const visibleTitleCurrentBilling = (activeHealthPolicyBilling: ActiveHealthPolicyBillingType) => {
    const isCurrentBilling = isCurrentMonthlyBilling(
      activeHealthPolicyBilling.startDate,
      activeHealthPolicyBilling.billDate,
    )
    const isActivePolicy =
      policy.policySuperAppStatus === PolicySuperAppStatusEnum.A ||
      policy.policySuperAppStatus === PolicySuperAppStatusEnum.F

    return isCurrentBilling && isActivePolicy
  }

  useEffect(() => {
    if (
      specialPointHighlightData &&
      specialPointHighlightData?.backofficeGetActiveHealthSpecialPointHighlight?.length > 0
    ) {
      const specialPoints =
        specialPointHighlightData?.backofficeGetActiveHealthSpecialPointHighlight
      const arr = []

      for (const specialPoint of specialPoints) {
        arr.push({ description: specialPoint.descriptionTh })
      }

      setSpecialPointHighlights(arr)
    }
  }, [specialPointHighlightData])

  return {
    openSideBarChildren,
    specialPointHighlights,
    getSpecialPointHighlight,
    displayLastDigits,
    getCreditCardType,
    visibleTitleCurrentBilling,
    specialPointHighlightLoading,
  }
}

export default BillingInformationHook
