import { Container } from './styles'
import ForgotPasswordForm from '@/components/feature/ForgotPasswordPage/ForgotPasswordForm'
import SuccessfullyForgotPasswordCard from '@/components/feature/ForgotPasswordPage/SuccessfullyForgotPasswordCard'

function ForgotPassword() {
  return (
    <Container>
      <ForgotPasswordForm />
      <SuccessfullyForgotPasswordCard />
    </Container>
  )
}

export default ForgotPassword
