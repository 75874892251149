import styled from '@emotion/styled'

export const Container = styled('div')`
  width: 100%;
  padding: 40px;
  overflow: hidden;
  position: relative;
`

export const Header = styled('div')``

export const Title = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
`
