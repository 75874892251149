import styled from '@emotion/styled'
import { Tabs as AntdTabs } from 'antd'

export const Title = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
`

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  width: 100%;
`

export const Tabs = styled(AntdTabs)`
  .ant-tabs-nav {
    margin: 0 0 24px 0;
  }
`
