import { atom } from 'recoil'

import { moveCategoryModalProps } from './types'

const initConfirmModal = {
  isVisible: false,
  onOkFunction: Function,
  title: '',
  okText: 'ตกลง',
  category: [],
  subCategoryId: [],
} as moveCategoryModalProps

const moveCategoryModalAtom = atom({
  key: 'moveCategoryModalAtom',
  default: initConfirmModal,
})

export default moveCategoryModalAtom
