import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeGetAllLocationChargingStationsInterface } from './interfaces'

const backofficeGetAllLocationChargingStations: TypedDocumentNode<BackofficeGetAllLocationChargingStationsInterface> = gql`
  query BackofficeGetAllLocationChargingStations {
    backofficeGetAllLocationChargingStations {
      id
      displayNameTh
      displayNameEn
      address1Th
      address1En
      address2Th
      address2En
      description
      phones
      faxes
      lat
      long
      subDistrictCode
      locationTypeId
      isActive
      province {
        displayNameTh
        displayNameEn
        districts {
          displayNameTh
          displayNameEn
          subDistricts {
            displayNameTh
            displayNameEn
            postCode
          }
        }
      }
      locationTag2Ids
      locationTags {
        id
        displayNameTh
        displayNameEn
        description
        priority
      }
      referenceCode
      locationType {
        id
        displayNameTh
        displayNameEn
        description
        priority
        locationSubType {
          id
          locationTypeId
          displayNameTh
          displayNameEn
          description
          priority
          refCode
        }
      }
      locationSubTypes {
        id
        locationTypeId
        displayNameTh
        displayNameEn
        description
        priority
        refCode
      }
      motorEvChargingProvider {
        id
        name
        chargeRates {
          displayTh
          displayEn
          currentType
        }
      }
      location2MotorEvChargingType {
        location2Id
        motorEvChargingTypeId
        quantity
        motorEvChargingType {
          id
          connectorType
          currentType
        }
      }
      location2OpenTime {
        location2Id
        dayOfWeek
        isOpen24Hours
        isClosed
        openTime
        closeTime
      }
    }
  }
`

export default backofficeGetAllLocationChargingStations
