import styled from '@emotion/styled'
import { Select } from 'antd'
import { DebounceSelect } from './AutoCompleteSearchUser'

export const SelectStyled = styled(Select)<any>`
  .ant-select-selector {
    height: 44px !important;
    border-radius: 0px !important;
  }

  .ant-select-selection-item {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
`

export const AntdDebounceSelect = styled(DebounceSelect)<any>`
  width: 100%;
`
