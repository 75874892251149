import styled from '@emotion/styled'
import { Switch as AntSwitch } from 'antd'

export const CardWrapper = styled('div')`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
`

export const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
`

export const Title = styled('div')`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #000000;
`

export const Switch = styled(AntSwitch)``

export const MenuWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e5e5e5;
  background: #fafafa;
  padding: 14px 24px;
  gap: 14px;

  :last-child {
    border-radius: 0px 0px 8px 8px;
  }
`

export const PageWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const SubTitle = styled('div')`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #000000;
`

export const PermissionWrapper = styled('div')``

export const SwitchPermissionWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 14px 16px;
  border-color: #e5e5e5;
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-style: solid;

  :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  :last-child {
    border-bottom-width: 1px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`
