import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeSearchBackOfficeUserDataInterface,
  SearchBackOfficeUserInputInterface,
} from './types'

export const backofficeSearchBackOfficeUser: TypedDocumentNode<
  BackofficeSearchBackOfficeUserDataInterface,
  SearchBackOfficeUserInputInterface
> = gql`
  query BackofficeSearchBackOfficeUser($input: SearchBackOfficeUserInput!) {
    backofficeSearchBackOfficeUser(input: $input) {
      id
      firstname
      lastname
    }
  }
`

export default backofficeSearchBackOfficeUser
