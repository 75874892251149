import backofficeGetPpuMotorAutoTopUpByPolicyNumber from './backofficeGetPpuMotorAutoTopUpByPolicyNumber/BackofficeGetPpuMotorAutoTopUpByPolicyNumber.gql'
import backofficeGetPpuMotorUserPolicyMotors from './backofficeGetPpuMotorUserPolicyMotors/BackofficeGetPpuMotorUserPolicyMotors.gql'
import backofficeGetTviConnectCurrentSimMainPackage from './backofficeGetTviConnectCurrentSimMainPackage/BackofficeGetTviConnectCurrentSimMainPackage.gql'
import getAllUserPoliciesByIdentityId from './getAllUserPoliciesByIdentityId/GetAllUserPoliciesByIdentityId.gql'
import backofficeChangePpuMotorPolicyMode from './backofficeChangePpuMotorPolicyMode/BackofficeChangePpuMotorPolicyMode.gql'
import backofficeChangeTviConnectSimMainPackage from './backofficeChangeTviConnectSimMainPackage/BackofficeChangeTviConnectSimMainPackage.gql'
import backofficeResetPpuMotorUserPolicyMotorDeviceId from './backofficeResetPpuMotorUserPolicyMotorDeviceId/BackofficeResetPpuMotorUserPolicyMotorDeviceId.gql'
import backofficeUnboundPpuMotorUserPolicyMotor from './backofficeUnboundPpuMotorUserPolicyMotor/BackofficeUnboundPpuMotorUserPolicyMotor.gql'

const ppuMotor = {
  query: {
    backofficeGetPpuMotorAutoTopUpByPolicyNumber,
    backofficeGetPpuMotorUserPolicyMotors,
    backofficeGetTviConnectCurrentSimMainPackage,
    getAllUserPoliciesByIdentityId,
  },
  mutation: {
    backofficeChangePpuMotorPolicyMode,
    backofficeChangeTviConnectSimMainPackage,
    backofficeResetPpuMotorUserPolicyMotorDeviceId,
    backofficeUnboundPpuMotorUserPolicyMotor,
  },
}

export default ppuMotor
