import { Image } from 'antd'
import {
  Container,
  Card,
  Bpm,
  BpmTitle,
  Information,
  Point,
  PointIcon,
  Title,
  Content,
  Block,
  InformationTitle,
  ActiveBonusIcon,
  EditTitle,
  EditBy,
  Thumbnail,
  TitleBlockStyle,
  ActivityContentStyle,
  Skeleton,
  PartnerLogo,
} from './styles'
import RecentActivityInformationHook from './RecentActivityInformation.hook'
import { toThaiLocaleDateString } from '@/libs/date'
import { PdfIcon } from '@/components/feature/PolicyDetailPage/ManualDiscountInformation/styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import { RecentActivityInformationProp } from './types'

function RecentActivityInformation({ exerciseHistory }: RecentActivityInformationProp) {
  const {
    editExerciseActivityLogData,
    bonusPointAndPromotionActivityData,
    fileExtension,
    wellBeingFileExtension,
    heartrateTargetData,
    partnerBranch,
    isComponentLoading,
  } = RecentActivityInformationHook({
    exerciseHistory,
  })

  return (
    <Container>
      {isComponentLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Card
            title={
              <Content>
                <Block className={TitleBlockStyle}>
                  <PointIcon src={IMAGE_URL.pointIcon} />
                  <Title>คะแนนออกกำลังกาย</Title>
                </Block>
                <Point>{exerciseHistory.exercisePoint} คะแนน</Point>
              </Content>
            }
          >
            <Content className={ActivityContentStyle}>
              <Block>
                <InformationTitle>จำนวนก้าว</InformationTitle>
                <Information>{exerciseHistory.step.toLocaleString() || 0} ก้าว</Information>
              </Block>
              <Block>
                <InformationTitle>เวลาออกกำลังกาย</InformationTitle>
                <Information>
                  {exerciseHistory.activityDuration.toLocaleString() || 0} นาที
                </Information>
              </Block>
            </Content>
            <Block>
              <BpmTitle>
                การคิดเวลาออกกำลังกาย จากอัตราการเต้นหัวใจ ของคุณที่ต้องเกิน{' '}
                <Bpm>
                  {`${
                    heartrateTargetData?.backOfficeGetActiveHealthHeartrateTargetByDate?.cardio ||
                    '-'
                  } bpm`}
                </Bpm>
              </BpmTitle>
            </Block>
          </Card>
          {editExerciseActivityLogData?.backOfficeGetEditExerciseDataDailySummaryActivityLog ? (
            <EditTitle>
              {`แก้ไขเมื่อ ${toThaiLocaleDateString({
                date: editExerciseActivityLogData
                  ?.backOfficeGetEditExerciseDataDailySummaryActivityLog?.actedAt,
              })} โดย `}
              <EditBy>
                {`${editExerciseActivityLogData?.backOfficeGetEditExerciseDataDailySummaryActivityLog?.actedBy?.firstname} ${editExerciseActivityLogData?.backOfficeGetEditExerciseDataDailySummaryActivityLog?.actedBy?.lastname}`}
              </EditBy>
              {fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg' ? (
                <Image.PreviewGroup>
                  <Thumbnail
                    src={
                      editExerciseActivityLogData
                        ?.backOfficeGetEditExerciseDataDailySummaryActivityLog?.actionData
                        ?.remarkImageUrl
                    }
                  />
                </Image.PreviewGroup>
              ) : fileExtension === 'pdf' ? (
                <a
                  href={
                    editExerciseActivityLogData
                      ?.backOfficeGetEditExerciseDataDailySummaryActivityLog?.actionData
                      ?.remarkImageUrl
                  }
                  target="_blank"
                  rel="noreferrer"
                  type="application/pdf"
                  download
                >
                  <PdfIcon src={IMAGE_URL.pdfIcon} />
                </a>
              ) : undefined}
            </EditTitle>
          ) : undefined}
          {exerciseHistory.bonusPoint != null ? (
            <>
              <Card
                title={
                  <Content>
                    <Block className={TitleBlockStyle}>
                      <ActiveBonusIcon src={IMAGE_URL.wellBeingIcon} />
                      <Title>คะแนน Well Point</Title>
                    </Block>
                    <Point>{exerciseHistory.bonusPoint} คะแนน</Point>
                  </Content>
                }
                bodyStyle={{ padding: exerciseHistory.bonusPoint > 0 ? undefined : 0 }}
              >
                {exerciseHistory.bonusPoint > 0 ? (
                  <Block className={TitleBlockStyle}>
                    <PartnerLogo src={partnerBranch?.activeHealthPartner?.logoImageUrl} />
                    <Title>{partnerBranch?.activeHealthPartner?.displayNameTh}</Title>
                  </Block>
                ) : undefined}
              </Card>
              {bonusPointAndPromotionActivityData?.backofficeGetAdjustBonusPointAndPromotionActivity ? (
                <>
                  <EditTitle>
                    {`แก้ไขเมื่อ ${toThaiLocaleDateString({
                      date: bonusPointAndPromotionActivityData
                        ?.backofficeGetAdjustBonusPointAndPromotionActivity?.[0]
                        ?.activeHealthPromotionActivity?.createdAt,
                    })} โดย `}
                    <EditBy>
                      {`${bonusPointAndPromotionActivityData?.backofficeGetAdjustBonusPointAndPromotionActivity?.[0]?.activeHealthPromotionActivity?.createdByText}`}
                    </EditBy>
                  </EditTitle>
                  {wellBeingFileExtension === 'png' ||
                  wellBeingFileExtension === 'jpg' ||
                  wellBeingFileExtension === 'jpeg' ? (
                    <Image.PreviewGroup>
                      <Thumbnail
                        src={
                          bonusPointAndPromotionActivityData
                            ?.backofficeGetAdjustBonusPointAndPromotionActivity?.[0]
                            ?.activeHealthPromotionActivity?.actionData?.remarkFileUrl
                        }
                      />
                    </Image.PreviewGroup>
                  ) : wellBeingFileExtension === 'pdf' ? (
                    <a
                      href={
                        bonusPointAndPromotionActivityData
                          ?.backofficeGetAdjustBonusPointAndPromotionActivity?.[0]
                          ?.activeHealthPromotionActivity?.actionData?.remarkFileUrl
                      }
                      target="_blank"
                      rel="noreferrer"
                      type="application/pdf"
                      download
                    >
                      <PdfIcon src={IMAGE_URL.pdfIcon} />
                    </a>
                  ) : undefined}
                </>
              ) : undefined}
            </>
          ) : undefined}
        </>
      )}
    </Container>
  )
}

export default RecentActivityInformation
