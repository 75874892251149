import { Outlet, useParams } from 'react-router-dom'
import { Container, Title } from './styles'
import { OrderManagementContext } from '@/contexts'
import TaTransactionHook from './TaTransaction.hook'
import TaTransactionFilterTab from '@/components/feature/TaTransactionPage/TaTransactionFilterTab'
import TaTransactionTable from '@/components/feature/TaTransactionPage/TaTransactionTable'

const TaTransaction = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const { orderTab, handleSetOrderTab } = TaTransactionHook()

  return (
    <OrderManagementContext.Provider value={{ orderTab, setOrderTab: handleSetOrderTab }}>
      {orderId ? (
        <Outlet />
      ) : (
        <Container>
          <Title>รายการสั่งซื้อประกันภัยการเดินทาง</Title>
          <TaTransactionFilterTab />
          <TaTransactionTable />
        </Container>
      )}
    </OrderManagementContext.Provider>
  )
}

export default TaTransaction
