import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ColumnsType } from 'antd/lib/table'
import { useSetRecoilState } from 'recoil'
import dayjs from 'dayjs'
import { debounceTime, distinctUntilChanged, fromEvent, map, of, switchMap, tap } from 'rxjs'
import { Badge } from 'antd'
import { saveAs } from 'file-saver'
import { SorterResult } from 'antd/es/table/interface'
import { ActionBlock, ButtonIcon, EnText } from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import sideBarInformationAtom from '@/recoil/sideBarInformation'
import WellBeingPartnerSidebarInfo from '@/components/feature/WellBeingPage/WellBeingPartnerSidebarInfo'
import { useLazyQuery } from '@apollo/client'
import { activeHealthPartnerGql, addressGql } from '@/gql'
import { SortByEnum } from '@/constant/SORT_BY'
import {
  ActiveHealthPartnerBranchWithScanCount,
  BackofficeGetActiveHealthPartnerBranchPaginationWithScanCountInputInterface,
} from '@/gql/activeHealthPartner/backofficeGetActiveHealthPartnerBranchPaginationWithScanCount/interface'
import message from '@/libs/message'
import { SORTER, SorterEnum } from '@/constant/USER'

function WellBeingBranchTableHook() {
  const { partnerId } = useParams<{ partnerId: string }>()
  const searchElement = document.getElementById('well-being-branch-search')
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [sort, setSort] = useState<[string, SortByEnum | '']>(['createdAt', SortByEnum.DESC])
  const [pagination, setPagination] = useState<{
    page: number
    limit: number
    totalItems?: number
  }>({
    page: 1,
    limit: 10,
    totalItems: 0,
  })
  const [
    backofficeGetActiveHealthPartnerBranchPaginationWithScanCount,
    { data: activeHealthPartnerBranchData, loading: activeHealthPartnerBranchLoading },
  ] = useLazyQuery(
    activeHealthPartnerGql.query.backofficeGetActiveHealthPartnerBranchPaginationWithScanCount,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.meta) {
          setPagination({
            page: data.backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.meta
              .currentPage,
            limit:
              data.backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.meta.itemsPerPage,
            totalItems:
              data.backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.meta.totalItems,
          })
        }
      },
    },
  )
  const [backofficeGetQrByActiveHealthPartnerBranchId] = useLazyQuery(
    activeHealthPartnerGql.query.backofficeGetQrByActiveHealthPartnerBranchId,
  )
  const [getAddress] = useLazyQuery(addressGql.query.backofficeGetAddresses, {
    fetchPolicy: 'no-cache',
  })
  const setSidebarInformation = useSetRecoilState(sideBarInformationAtom)
  const columns: ColumnsType<ActiveHealthPartnerBranchWithScanCount | {}> = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      align: 'center',
      render: (_, __, index) => {
        return <div>{index + 1}</div>
      },
    },
    {
      title: 'ชื่อสาขา',
      render: (_, data) => {
        const d = data as ActiveHealthPartnerBranchWithScanCount

        return (
          <div>
            <div>{d.activeHealthPartnerBranch.displayNameTh}</div>
            <EnText>{d.activeHealthPartnerBranch.displayNameEn}</EnText>
          </div>
        )
      },
    },
    {
      title: 'วันที่เริ่มต้น',
      dataIndex: ['activeHealthPartnerBranch', 'startDate'],
      key: 'startDate',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (startDate) => {
        return (startDate && dayjs(startDate).format('DD-MM-YYYY')) || '-'
      },
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: ['activeHealthPartnerBranch', 'endDate'],
      key: 'endDate',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (endDate) => {
        return (endDate && dayjs(endDate).format('DD-MM-YYYY')) || '-'
      },
    },
    {
      title: 'สถานะ QR CODE',
      dataIndex: ['activeHealthPartnerBranch', 'qr', 'isActive'],
      key: 'qr.isActive',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (isActive) => {
        return (
          <div>
            <Badge status={isActive ? 'success' : 'error'} />{' '}
            {isActive ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
          </div>
        )
      },
    },
    {
      title: 'สร้างโดย',
      render: (_, data) => {
        const d = data as ActiveHealthPartnerBranchWithScanCount

        return (
          (d.activeHealthPartnerBranch.createdBy.firstname &&
            d.activeHealthPartnerBranch.createdBy.lastname &&
            `${d.activeHealthPartnerBranch.createdBy.firstname} ${d.activeHealthPartnerBranch.createdBy.lastname}`) ||
          d.activeHealthPartnerBranch.createdByText
        )
      },
    },
    {
      title: 'แก้ไขโดย',
      render: (_, data) => {
        const d = data as ActiveHealthPartnerBranchWithScanCount

        return (
          (d.activeHealthPartnerBranch.updatedBy.firstname &&
            d.activeHealthPartnerBranch.updatedBy.lastname &&
            `${d.activeHealthPartnerBranch.updatedBy.firstname} ${d.activeHealthPartnerBranch.updatedBy.lastname}`) ||
          d.activeHealthPartnerBranch.updatedByText
        )
      },
    },
    {
      title: 'แก้ไขล่าสุด',
      dataIndex: ['activeHealthPartnerBranch', 'updatedAt'],
      render: (updatedAt) => {
        return (updatedAt && dayjs(updatedAt).format('DD-MM-YYYY')) || '-'
      },
    },
    {
      title: 'ยอดสแกน',
      dataIndex: ['qrScanCount'],
      render: (qrScanCount: number) => {
        return qrScanCount.toLocaleString()
      },
    },
    {
      title: '',
      render: (_, data) => {
        const d = data as ActiveHealthPartnerBranchWithScanCount
        return (
          <ActionBlock>
            <ButtonIcon
              src={IMAGE_URL.eyeIcon}
              style={{ marginRight: '8px' }}
              onClick={() => openSidebarInformation(d)}
            />
            <ButtonIcon src={IMAGE_URL.qrIcon} onClick={() => onSaveQrCode(d)} />
          </ActionBlock>
        )
      },
    },
  ]

  const onSaveQrCode = (data: ActiveHealthPartnerBranchWithScanCount) => {
    const filename = `qr-${
      data.activeHealthPartnerBranch.displayNameEn
    }-${new Date().toISOString()}.png`

    if (data.activeHealthPartnerBranch.qr.imageUrl) {
      saveAs(data.activeHealthPartnerBranch.qr.imageUrl, filename)
    } else {
      backofficeGetQrByActiveHealthPartnerBranchId({
        variables: {
          input: {
            partnerBranchId: data.activeHealthPartnerBranch.id,
          },
        },
      })
        .then((res) => {
          if (res.data?.backofficeGetQrByActiveHealthPartnerBranchId.imageUrl) {
            saveAs(res.data.backofficeGetQrByActiveHealthPartnerBranchId.imageUrl, filename)
          }
        })
        .catch((error) => {
          message.error({
            content: 'Error when downloading image!',
          })
        })
    }
  }

  const openSidebarInformation = (
    activeHealthPartnerBranchWithScanCount: ActiveHealthPartnerBranchWithScanCount,
  ) => {
    const subDistrictCode =
      activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.subDistrict.subDistrictCode

    if (subDistrictCode) {
      getAddress({
        variables: {
          input: {
            subDistrictCode,
          },
        },
      }).then((address) => {
        if (address.data?.backofficeGetAddresses) {
          setSidebarInformation({
            visible: true,
            title: 'ข้อมูลสาขา',
            children: (
              <WellBeingPartnerSidebarInfo
                activeHealthPartnerBranchWithScanCount={activeHealthPartnerBranchWithScanCount}
                address={address.data.backofficeGetAddresses[0]}
              />
            ),
            dataTestId: 'well-being-partner-branch-info',
          })
        }
      })
    }
  }

  const getActiveHealthPartnerBranchWithScanCount = useCallback(
    (input?: BackofficeGetActiveHealthPartnerBranchPaginationWithScanCountInputInterface) => {
      backofficeGetActiveHealthPartnerBranchPaginationWithScanCount({
        variables: {
          activeHealthPartnerId: input?.activeHealthPartnerId || '',
          input: {
            page: pagination.page || 1,
            limit: pagination.limit || 10,
            sortBy: input?.input.sortBy,
            searchBy: ['displayNameEn', 'displayNameTh'],
            search: input?.input?.search || '',
          },
        },
      })
    },
    [backofficeGetActiveHealthPartnerBranchPaginationWithScanCount, pagination],
  )

  const onTablePaginate = (page: number, pageSize: number) => {
    setPagination({
      page,
      limit: pageSize,
    })
  }

  const onTableChange = (sorter: SorterResult<{}> | Array<SorterResult<{}>>) => {
    if (sorter) {
      const sort = sorter as SorterResult<{}>
      const sortBy = sort.columnKey as string
      const sortOrder = sort.order as SorterEnum

      setSort([sortBy, SORTER[sortOrder] || ''])
    }
  }

  const handleSetSearch = (input: string) =>
    of(setSearch(input)).pipe(
      tap(() => {
        setPagination({
          page: 1,
          limit: 10,
          totalItems: pagination.totalItems,
        })
      }),
    )

  useEffect(() => {
    if (partnerId) {
      getActiveHealthPartnerBranchWithScanCount({
        activeHealthPartnerId: partnerId,
        input: {
          search,
          page: pagination.page,
          limit: pagination.limit,
          sortBy: sort[0] ? [sort] : undefined,
        },
      })
    }
  }, [partnerId, search, pagination.page, pagination.limit, sort])

  useEffect(() => {
    if (isTyping && searchElement) {
      fromEvent(searchElement, 'keyup')
        .pipe(
          debounceTime(1000),
          map((e: Event) => {
            const target = e.target as HTMLInputElement

            return target.value || ''
          }),
          distinctUntilChanged(),
          switchMap(handleSetSearch),
          tap(() => setIsTyping(false)),
        )
        .subscribe()
    }
  }, [isTyping, searchElement])

  return {
    columns,
    activeHealthPartnerBranchData,
    activeHealthPartnerBranchLoading,
    pagination,
    onTablePaginate,
    setIsTyping,
    onTableChange,
  }
}

export default WellBeingBranchTableHook
