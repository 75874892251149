import { useEffect } from 'react'
import { addressGql } from '@/gql'
import { useRecoilValue } from 'recoil'
import { useLazyQuery } from '@apollo/client'
import policyAtom from '@/recoil/policy/atom'

function ChargePointCardHook() {
  const policy = useRecoilValue(policyAtom)

  const [
    backofficeGetChargingLocationsByPolicyNumber,
    {
      data: chargingLocationsByPolicyNumber,
      loading: backofficeGetChargingLocationsByPolicyNumberLoading,
    },
  ] = useLazyQuery(addressGql.query.backofficeGetChargingLocationsByPolicyNumber, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (policy.policyNumber) {
      backofficeGetChargingLocationsByPolicyNumber({
        variables: {
          input: { policyNumber: policy.policyNumber },
        },
      })
    }
  }, [policy, policy.policyNumber, backofficeGetChargingLocationsByPolicyNumber])

  return {
    policy,
    chargingLocationsByPolicyNumber,
    backofficeGetChargingLocationsByPolicyNumberLoading,
  }
}

export default ChargePointCardHook
