import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeActiveHealthExerciseStatInputInterface,
  BackofficeActiveHealthExerciseStatInterface,
} from './interfaces'

const backofficeActiveHealthExerciseStat: TypedDocumentNode<
  BackofficeActiveHealthExerciseStatInterface,
  BackofficeActiveHealthExerciseStatInputInterface
> = gql`
  query BackofficeGetUserExerciseStat($input: BackofficeGetUserExerciseStatInput!) {
    backofficeGetUserExerciseStat(input: $input) {
      data {
        date
        step
        minute
        point
        wellBeing
      }
      meta {
        period
        next
        previous
      }
    }
  }
`

export default backofficeActiveHealthExerciseStat
