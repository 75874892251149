import styled from '@emotion/styled'

export const Container = styled('div')`
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
`
export const Header = styled('div')``
export const Content = styled('div')`
  flex: 1 1 auto;
  background: #fafafa;
  display: flex;
`
export const Footer = styled('div')`
  flex: 0 1 0px;
`
