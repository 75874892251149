import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetTviConnectCurrentSimMainPackageDataInterface,
  BackofficeGetTviConnectCurrentSimMainPackageInputInterface,
} from './interfaces'

const backofficeGetTviConnectCurrentSimMainPackage: TypedDocumentNode<
  BackofficeGetTviConnectCurrentSimMainPackageDataInterface,
  BackofficeGetTviConnectCurrentSimMainPackageInputInterface
> = gql`
  query BackofficeGetTviConnectCurrentSimMainPackage($id: String!) {
    backofficeGetTviConnectCurrentSimMainPackage(id: $id) {
      serialNumber
      checksumDigit
      status
      deviceType
      partner
      packageName
    }
  }
`

export default backofficeGetTviConnectCurrentSimMainPackage
