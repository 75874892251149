import { createTheme } from '@mui/material/styles'
import { colors } from '@/styles/colors'

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          fontSize: '1rem',
        },
        startIcon: {
          marginLeft: '0px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          height: '40px',
        },
        inputSizeSmall: {
          height: '40px',
          boxSizing: 'border-box',
          paddingTop: '4px',
        },
        root: {
          height: '48px',
          boxSizing: 'border-box',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '12px 14px',
        },
      },
      variants: [
        { props: { size: 'small' }, style: { height: '40px' } },
        { props: { size: 'medium' }, style: { height: '48px' } },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthMd: {
          maxWidth: '640px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '&.Mui-selected': {
            backgroundColor: 'var(--gray-200)',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          paddingRight: '8px',
        },
      },
    },
  },
})

export default theme
