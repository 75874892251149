import { useEffect } from 'react'
import _ from 'lodash'
import WellBeingPartnerInfoHook from './WellBeingPartnerInfo.hook'
import {
  Block,
  Container,
  InfoTitle,
  PartnerLogo,
  Title,
  Form,
  FormItem,
  Input,
  FormGridBlock,
  CreatedByText,
} from './styles'
import dayjs from 'dayjs'

function WellBeingPartnerInfo({
  setIsPartnerActive,
}: {
  setIsPartnerActive: (isActive: boolean) => void
}) {
  const { activeHealthPartnerData, activeHealthPartnerLoading } = WellBeingPartnerInfoHook()

  useEffect(() => {
    if (
      activeHealthPartnerData &&
      _.isBoolean(activeHealthPartnerData?.backofficeGetActiveHealthPartnerById.isActive)
    ) {
      setIsPartnerActive(activeHealthPartnerData.backofficeGetActiveHealthPartnerById.isActive)
    }
  }, [
    activeHealthPartnerData,
    activeHealthPartnerData?.backofficeGetActiveHealthPartnerById.isActive,
  ])

  return (
    <>
      {!activeHealthPartnerLoading ? (
        <>
          <CreatedByText>
            สร้างโดย{' '}
            <span style={{ color: '#000000' }}>
              {activeHealthPartnerData?.backofficeGetActiveHealthPartnerById.createdByText}
            </span>{' '}
            อัพเดตข้อมูลล่าสุด{' '}
            <span style={{ color: '#000000' }}>
              {activeHealthPartnerData?.backofficeGetActiveHealthPartnerById.updatedByText}{' '}
              {dayjs(
                activeHealthPartnerData?.backofficeGetActiveHealthPartnerById.updatedAt,
              ).format('DD-MM-YYYY')}
            </span>
          </CreatedByText>
          <Container>
            <Title>ข้อมูลพาร์ทเนอร์</Title>
            <Block>
              <div>
                <InfoTitle>โลโก้พาร์ทเนอร์*</InfoTitle>
                <PartnerLogo
                  src={activeHealthPartnerData?.backofficeGetActiveHealthPartnerById.logoImageUrl}
                />
              </div>
              <div>
                <Form
                  initialValues={{
                    displayNameTh:
                      activeHealthPartnerData?.backofficeGetActiveHealthPartnerById.displayNameTh,
                    displayNameEn:
                      activeHealthPartnerData?.backofficeGetActiveHealthPartnerById.displayNameEn,
                    categoryNameEn:
                      activeHealthPartnerData?.backofficeGetActiveHealthPartnerById
                        .activeHealthPartnerCategory.displayNameEn,
                  }}
                >
                  <FormGridBlock>
                    <div>
                      <InfoTitle>ชื่อพาร์ทเนอร์ (ไทย)*</InfoTitle>
                      <FormItem name="displayNameTh">
                        <Input disabled />
                      </FormItem>
                    </div>
                    <div>
                      <InfoTitle>ชื่อพาร์ทเนอร์ (อังกฤษ)</InfoTitle>
                      <FormItem name="displayNameEn">
                        <Input disabled />
                      </FormItem>
                    </div>
                  </FormGridBlock>
                  <div>
                    <InfoTitle>หมวดหมู่ (Category)*</InfoTitle>
                    <FormItem name="categoryNameEn">
                      <Input disabled />
                    </FormItem>
                  </div>
                </Form>
              </div>
            </Block>
          </Container>
        </>
      ) : undefined}
    </>
  )
}

export default WellBeingPartnerInfo
