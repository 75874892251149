import styled from '@emotion/styled'
import CommonButton from '@/components/common/Button'
import { ContainerProps } from './types'

export const Container = styled('div')<ContainerProps>`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 72px 223px;
  text-align: center;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  margin-bottom: 32px;
`

export const SuccessIcon = styled('img')`
  width: 148px;
  margin-bottom: 16px;
`

export const Button = styled(CommonButton)`
  width: 156px;
`
