import { ColumnsType } from 'antd/lib/table'
import _ from 'lodash'
import { IMAGE_URL } from '@/constant/IMAGE'
import { Space } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import message from '@/libs/message'
import backofficeTeam from '@/gql/backofficeTeam'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { PaginationTypes } from './types'
import selectedTeamPermissionAtom from '@/recoil/selectedTeamPermission/atom'
import {
  BackofficeGetBackofficeUserTeamRoleType,
  BackofficeUserTeamRolePaginatedSortableFieldEnum,
} from '@/gql/backofficeTeam/backofficeGetBackofficeUserTeamRolePaginated/interfaces'
import ModalAddUserHook from '../components/ModalAddUser/ModalAddUser.hook'
import { ModeUserEnum } from '@/recoil/userModal/types'
import debounce from 'lodash/debounce'

import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { ButtonIcon } from './styles'
import addTeamMemberModalAtom from '@/recoil/addTeamMemberModal/atom'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { SORTER, SorterEnum } from '@/constant/USER'
import { SortByEnum } from '@/constant/SORT_BY'
import { SorterResult } from 'antd/lib/table/interface'

const TabTeamMemberHook = () => {
  const user = useRecoilValue(userAtom)
  const isPowerUser = validatePerm([BACKOFFICE_USER_PERMISSION.USR010101], user.permissions)
  const [backofficeUserTeamRole, setBackofficeUserTeamRole] =
    useState<Array<BackofficeGetBackofficeUserTeamRoleType>>()
  const [searchValue, setSearchValue] = useState<string>('')
  const [pagination, setPagination] = useState<PaginationTypes>({
    page: 1,
    limit: 10,
    totalItems: 0,
  })
  const [sorter, setSorter] = useState<
    [BackofficeUserTeamRolePaginatedSortableFieldEnum | '', SortByEnum | '']
  >(['', ''])

  const { setConfirmModal } = ConfirmModalHook()
  const setAddTeamMemberModal = useSetRecoilState(addTeamMemberModalAtom)

  const selectedTeam = useRecoilValue(selectedTeamPermissionAtom)
  const { setUserModal } = ModalAddUserHook()
  const filterBackofficeUserTeamRolePaginatedQuery =
    !isPowerUser && user.backofficeTeams && user.backofficeTeams.length > 0
      ? `$in:${user.backofficeTeams.map((team) => team.id).join()}`
      : `$eq:${selectedTeam.id}`

  const [
    postBackofficeDeleteBackofficeTeamFromBackofficeUserMutation,
    { error: errorBackofficeDeleteBackofficeTeamFromBackofficeUser },
  ] = useMutation(backofficeTeam.mutation.backofficeDeleteBackofficeTeamFromBackofficeUser, {
    fetchPolicy: 'no-cache',
  })

  const [
    getBackofficeUserTeamRolePaginatedQuery,
    {
      loading: loadingGetBackofficeUserTeamRolePaginatedQuery,
      refetch: refetchGetBackofficeUserTeamRolePaginatedQuery,
    },
  ] = useLazyQuery(backofficeTeam.query.backofficeGetBackofficeUserTeamRolePaginated, {
    variables: {
      input: {
        page: 1,
        limit: 10,
        filter: {
          'backofficeTeam.id': filterBackofficeUserTeamRolePaginatedQuery,
        },
        sortBy: sorter[0] ? [sorter] : undefined,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { meta, data: dataUserTeamRole } = data.backofficeGetBackofficeUserTeamRolePaginated

      setBackofficeUserTeamRole(dataUserTeamRole)

      setPagination({
        page: meta.currentPage,
        limit: meta.itemsPerPage,
        totalItems: meta.totalItems,
      })
    },
  })

  const columns: ColumnsType<BackofficeGetBackofficeUserTeamRoleType | {}> = [
    {
      title: 'ลำดับ',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '5%',
      render: (_value, _record, number) => {
        return number + 1
      },
    },
    {
      title: 'ชื่อ-นามสกุล',
      align: 'left',
      width: '20%',
      render: (_data, record) => {
        const { backofficeUser } = record as BackofficeGetBackofficeUserTeamRoleType

        return `${backofficeUser?.firstname || ''} ${backofficeUser?.lastname || ''}`
      },
    },
    {
      title: 'เบอร์โทรศัพท์',
      key: 'phone',
      width: '10%',
      render: (_data, record) => {
        const { backofficeUser } = record as BackofficeGetBackofficeUserTeamRoleType

        return `${backofficeUser?.mobileNumber || ''}`
      },
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      render: (_data, record) => {
        const { backofficeUser } = record as BackofficeGetBackofficeUserTeamRoleType

        return `${backofficeUser.email || ''}`
      },
    },
    !isPowerUser
      ? {
          title: 'ทีม',
          width: '10%',
          key: 'backofficeTeam.name',
          sorter: true,
          sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
          defaultSortOrder: null,
          render: (_data, record) => {
            const { backofficeTeam } = record as BackofficeGetBackofficeUserTeamRoleType

            return `${backofficeTeam.name || ''}`
          },
        }
      : {
          width: 0,
        },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'position',
      key: 'backofficeRole.title',
      align: 'center',
      width: '10%',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (_data, record) => {
        const { backofficeRole } = record as BackofficeGetBackofficeUserTeamRoleType

        return `${backofficeRole.title || ''}`
      },
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: (_data, record) => {
        const { backofficeRole, backofficeTeam, backofficeUser } =
          record as BackofficeGetBackofficeUserTeamRoleType
        const isHeadRole = backofficeRole.title === 'หัวหน้าทีม'
        const isPowerUser = validatePerm([BACKOFFICE_USER_PERMISSION.USR010101], user.permissions)
        const isOwnUser = user.id === backofficeUser.id
        const headUserCanDelete = !isOwnUser && !isPowerUser && !isHeadRole // for head of user Team
        const powerUserCanDelete = isPowerUser && !isOwnUser // for everyone can be control permission

        return (
          <Space size="middle">
            <ButtonIcon
              src={IMAGE_URL.editGreyIcon}
              onClick={() => {
                const dataUser = {
                  userId: backofficeUser.id,
                  roleId: backofficeRole.id,
                  roleName: backofficeRole.title,
                  teamId: backofficeTeam.id,
                  teamName: backofficeTeam.name,
                  name: backofficeUser.firstname,
                  lastName: backofficeUser.lastname,
                  phone: String(backofficeUser.mobileNumber),
                  email: backofficeUser.email,
                }

                if (dataUser)
                  setUserModal({
                    title: 'แก้ไขข้อมูลผู้ใช้งาน',
                    onOkFunction: () => {
                      refetchGetBackofficeUserTeamRolePaginatedQuery()
                      setUserModal({
                        isVisible: false,
                        onOkFunction: Function,
                        mode: ModeUserEnum.CREATE,
                        okButtonProps: { type: 'primary', danger: false },
                        dataUser: undefined,
                      })
                    },
                    isVisible: true,
                    okButtonProps: { type: 'primary', danger: false },
                    mode: ModeUserEnum.UPDATE,
                    dataUser,
                  })
              }}
            />
            {headUserCanDelete || powerUserCanDelete ? (
              <ButtonIcon
                src={IMAGE_URL.tableTrashIcon}
                onClick={() => {
                  setConfirmModal({
                    onOkFunction: () => {
                      postBackofficeDeleteBackofficeTeamFromBackofficeUserMutation({
                        variables: {
                          input: {
                            backofficeUserId: backofficeUser.id,
                            backofficeTeamId: backofficeTeam.id,
                          },
                        },
                      })
                        .finally(() => {
                          refetchGetBackofficeUserTeamRolePaginatedQuery()
                          setConfirmModal({
                            isVisible: false,
                            onOkFunction: Function,
                            dataTestId: undefined,
                          })
                        })
                        .catch(() => {
                          message.error({
                            content: `ปัญหา: ${errorBackofficeDeleteBackofficeTeamFromBackofficeUser?.message}`,
                            duration: 10,
                          })
                        })
                    },
                    isVisible: true,
                    title: 'คุณต้องการลบผู้ใช้งาน',
                    bodyText: `คุณแน่ใจหรือไม่ที่จะทำการลบผู้ใช้งาน “${backofficeUser.firstname} ${backofficeUser.lastname}” ออกจากทีม "${backofficeTeam.name}"`,
                    okText: 'ลบ',
                    okButtonProps: { type: 'primary', danger: true },
                    dataTestId: 'delete-user-modal',
                  })
                }}
              />
            ) : (
              <div />
            )}
          </Space>
        )
      },
    },
  ]

  const handleAddUser = () => {
    setAddTeamMemberModal({
      isVisible: true,
      onOkFunction: () => {
        refetchGetBackofficeUserTeamRolePaginatedQuery()
      },
      onCancelFunction: () => {
        refetchGetBackofficeUserTeamRolePaginatedQuery()
      },
      okButtonProps: { type: 'primary', danger: false },
    })
  }

  const handleTableChange = (sorter: SorterResult<{}> | Array<SorterResult<{}>>) => {
    if (!_.isEmpty(sorter)) {
      const sortData = sorter as SorterResult<{}>
      const sortBy = sortData.columnKey as BackofficeUserTeamRolePaginatedSortableFieldEnum
      const sort = sortData.order as SorterEnum

      setSorter([sortBy, SORTER[sort] || ''])
    }
  }

  const onHandlePageChange = (page: number, pageSize: number) => {
    getBackofficeUserTeamRolePaginatedQuery({
      variables: {
        input: {
          search: searchValue,
          page: page,
          limit: pageSize,
          filter: {
            'backofficeTeam.id': filterBackofficeUserTeamRolePaginatedQuery,
          },
          sortBy: sorter[0] ? [sorter] : undefined,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const { meta, data: dataUserTeamRole } = data.backofficeGetBackofficeUserTeamRolePaginated

        setBackofficeUserTeamRole(dataUserTeamRole)

        setPagination({
          page: meta.currentPage,
          limit: meta.itemsPerPage,
          totalItems: meta.totalItems,
        })
      },
    })
  }

  const handleSetSearch = useCallback(
    (word?: string) => {
      setSearchValue(word || '')

      if (word && selectedTeam) {
        getBackofficeUserTeamRolePaginatedQuery({
          variables: {
            input: {
              page: 1,
              limit: 10,
              search: word,
              filter: {
                'backofficeTeam.id': filterBackofficeUserTeamRolePaginatedQuery,
              },
              sortBy: sorter[0] ? [sorter] : undefined,
            },
          },
          fetchPolicy: 'no-cache',
          onCompleted: (data) => {
            const { meta, data: dataUserTeamRole } =
              data.backofficeGetBackofficeUserTeamRolePaginated

            setBackofficeUserTeamRole(dataUserTeamRole)

            setPagination({
              page: meta.currentPage,
              limit: meta.itemsPerPage,
              totalItems: meta.totalItems,
            })
          },
        })
      } else {
        getBackofficeUserTeamRolePaginatedQuery({
          variables: {
            input: {
              page: pagination.page,
              limit: pagination.limit,
              filter: {
                'backofficeTeam.id': filterBackofficeUserTeamRolePaginatedQuery,
              },
              sortBy: sorter[0] ? [sorter] : undefined,
            },
          },
          fetchPolicy: 'no-cache',
          onCompleted: (data) => {
            const { meta, data: dataUserTeamRole } =
              data.backofficeGetBackofficeUserTeamRolePaginated

            setBackofficeUserTeamRole(dataUserTeamRole)

            setPagination({
              page: meta.currentPage,
              limit: meta.itemsPerPage,
              totalItems: meta.totalItems,
            })
          },
        })
      }
    },
    [
      getBackofficeUserTeamRolePaginatedQuery,
      pagination.limit,
      pagination.page,
      selectedTeam,
      sorter,
    ],
  )

  const handleSearchTextChange = debounce((event?: ChangeEvent<HTMLInputElement>) => {
    const query = event?.target.value
    const value = query !== '' ? query : undefined
    return handleSetSearch(value)
  }, 500)

  useEffect(() => {
    if (selectedTeam.id) getBackofficeUserTeamRolePaginatedQuery()
  }, [selectedTeam.id])

  return {
    handleSearchTextChange,
    backofficeUserTeamRole,
    pagination,
    columns,
    handleAddUser,
    onHandlePageChange,
    loadingGetBackofficeUserTeamRolePaginatedQuery,
    handleTableChange,
  }
}

export default TabTeamMemberHook
