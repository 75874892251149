export enum PolicyGroupEnum {
  MOTOR = 'Motor',
  ON_OFF_PACKAGE = 'Motor On Off (Package)',
  ON_OFF_TOP_UP = 'Motor On Off (Top-up)',
  HEALTH = 'Health',
  ACTIVE_HEALTH = 'Active Health',
  PA = 'Personal Accident',
  TRAVEL = 'Travel',
  TRAVEL_ON_OFF = 'Travel On Off',
  FIRE = 'Fire',
  MARINE = 'Marine',
  OTHERS = 'Others',
}
