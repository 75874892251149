import { LoginSso } from '@/components/feature/LoginPage'
import UserAuthProvider from '@/providers/userAuth'

function Login() {
  return (
    <UserAuthProvider>
      <LoginSso />
    </UserAuthProvider>
  )
}

export default Login
