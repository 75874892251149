import styled from '@emotion/styled'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  width: 100%;
`

export const HeaderCampaign = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled('span')`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
`

export const Button = styled(CommonButton)`
  line-height: 20px;
  padding: 10px 16px;
  width: 161px;
`

export const IconSvg = styled('img')`
  margin-right: 10px;
  height: 15px;
  width: 15px;
`

export const CampaignCard = styled('div')`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 32px;
`

export const Topic = styled('span')`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
`

export const ButtonIcon = styled('img')`
  cursor: pointer;
  width: 20px;
  height: 20px;
`

export const StatusWrapper = styled('div')`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`

export const Dot = styled('div')<{ background: string }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${(props) => props.background};
`

export const Span = styled('span')`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`
