import { Container, Modal, Text } from './styles'
import HardResetWatchDeviceModalHook from './HardResetWatchDeviceModal.hook'

export default function HardResetWatchDeviceModal() {
  const { hardResetWatchDeviceModal, handleSetHardResetWatchDeviceModal, onCancelModal } =
    HardResetWatchDeviceModalHook()

  return (
    <Modal
      title="คุณต้องการฮาร์ดรีเซ็ทการเชื่อมต่อนาฬิกาออกกำลังกายของลูกค้า?"
      cancelText="ยกเลิก"
      okText="ฮาร์ดรีเซ็ท"
      open={hardResetWatchDeviceModal.open}
      closable={false}
      onCancel={() => onCancelModal()}
      onOk={() => handleSetHardResetWatchDeviceModal()}
      width="400px"
      data-testid="hard-reset-smartwatches-device-modal"
    >
      <Container>
        <Text>
          โปรดแจ้งลูกค้าหลังคุณกด “ฮาร์ดรีเซ็ทอุปกรณ์” ให้ลูกค้า เปิดแอป “Thaivivat”
          อีกครั้งกดไปที่ส่วนการ์ดแสดง ข้อมูลการออกกำลังกายเพื่อทำการเลือกนาฬิกาที่เชื่อมต่ออีกครั้ง
        </Text>
      </Container>
    </Modal>
  )
}
