export enum MainPackageNameNbEnum {
  FREE = 'NB IOT MotorTracker MainOnetime free6Mths Corp',
  PAID = 'NB IOT Motor Tracker 12Mths Pay 50BCorp',
  SUSPEND = 'NB IOT Motor Tracker Bar Data Corp',
}

export enum MainPackageName4gEnum {
  FREE = 'IOT 4G Motor Tracker Main Onetime free7Mths Corp',
  PAID = 'IOT 4G Motor Tracker 12Mth Pay 50BCorp',
  SUSPEND = 'NB IOT Motor Tracker Bar Data Corp',
}
