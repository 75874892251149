import { activeHealthPolicyGql } from '@/gql'
import policyDetailAtom from '@/recoil/policyDetail/atom'
import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { PointWeeklyInformationHookProps } from './types'

const PointWeeklyInformationHook = ({ weeklySummary }: PointWeeklyInformationHookProps) => {
  const policyDetail = useRecoilValue(policyDetailAtom)

  const [
    getBackofficeGetActiveHealthDailySummaryByWeekIdInput,
    {
      data: dataBackofficeGetActiveHealthDailySummaryByWeekIdInput,
      loading: loadingBackofficeGetActiveHealthDailySummaryByWeekIdInputQuery,
    },
  ] = useLazyQuery(activeHealthPolicyGql.query.backofficeGetActiveHealthDailySummaryByWeekIdInput, {
    fetchPolicy: 'no-cache',
  })

  const [
    getBackofficeGetDiscountConditionByPolicyNumber,
    {
      data: dataBackofficeGetDiscountConditionByPolicyNumber,
      loading: loadingBackofficeGetDiscountConditionByPolicyNumberQuery,
    },
  ] = useLazyQuery(activeHealthPolicyGql.query.backofficeGetDiscountConditionByPolicyNumber, {
    fetchPolicy: 'no-cache',
  })
  const isComponentLoading =
    loadingBackofficeGetDiscountConditionByPolicyNumberQuery ||
    loadingBackofficeGetActiveHealthDailySummaryByWeekIdInputQuery

  useEffect(() => {
    if (policyDetail.id && weeklySummary.id && weeklySummary.policyNumber) {
      getBackofficeGetActiveHealthDailySummaryByWeekIdInput({
        variables: {
          input: {
            userId: policyDetail.id,
            weekId: weeklySummary.id,
          },
        },
      })

      getBackofficeGetDiscountConditionByPolicyNumber({
        variables: {
          input: weeklySummary.policyNumber,
        },
      })
    }
  }, [
    getBackofficeGetActiveHealthDailySummaryByWeekIdInput,
    getBackofficeGetDiscountConditionByPolicyNumber,
    policyDetail.id,
    weeklySummary.id,
    weeklySummary.policyNumber,
  ])

  return {
    dataBackofficeGetDiscountConditionByPolicyNumber,
    dataBackofficeGetActiveHealthDailySummaryByWeekIdInput,
    isComponentLoading,
  }
}

export default PointWeeklyInformationHook
