import styled from '@emotion/styled'

export const Container = styled('div')``

export const GroupTitle = styled('div')`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
`

export const InfoTitle = styled('div')`
  color: #666666;
  font-size: 12px;
  margin-bottom: 4px;
`

export const Info = styled('div')`
  font-size: 14px;
`

export const Block = styled('div')`
  margin: 24px 0;
  border-bottom: 1px solid #e5e5e5;
`

export const InfoBlock = styled('div')`
  margin-bottom: 16px;
`

export const MobileBlock = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

export const StatusText = styled('div')`
  font-size: 16px;
  color: #009400;
`

export const MobileSubBlock = styled('div')`
  display: flex;
`

export const MobileOrderText = styled('div')`
  font-size: 12px;
  margin-right: 8px;
`
