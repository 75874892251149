import config from '@/config/config'
import activeHealthSpecialPointCampaign from '@/gql/activeHealthSpecialPointCampaign'
import { CreateActiveHealthSpecialPointInputInterface } from '@/gql/activeHealthSpecialPointCampaign/backofficeCreateActiveHealthSpecialPoint/interfaces'
import { ActiveHealthSpecialPointEnum } from '@/gql/activeHealthSpecialPointCampaign/types'
import userAtom from '@/recoil/user/atom'
import { useMutation } from '@apollo/client'
import { faker } from '@faker-js/faker'
import { Form, UploadProps } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import message from '@/libs/message'
import {
  SpecialPointCampaignFormType,
  DataCampaignType,
  ModeCampaignEnum,
  StatusCampaignEnum,
} from './types'
import { funcStatusCampaign } from '@/pages/CampaignManagement/CampaignManagement.hook'
import { RangePickerProps } from 'antd/es/date-picker'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'

const CampaignModalHook = ({
  handleOk,
  dataCampaign,
}: {
  handleOk: () => void
  dataCampaign: DataCampaignType
}) => {
  const [form] = Form.useForm<SpecialPointCampaignFormType>()
  const user = useRecoilValue(userAtom)
  const [disabledSave, setDisabledSave] = useState<boolean>(true)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [files, setFiles] = useState<Array<UploadFile>>([])
  const [defaultFiles, setDefaultFiles] = useState<Array<UploadFile>>([])

  const descriptionThCampaign = Form.useWatch('descriptionTh', form)
  const imgCampaign = Form.useWatch('imageUrl', form)

  const handleUploadProps: UploadProps = {
    name: 'file',
    fileList: files,
    action: `${config.graphqlRestEndpoint}/backoffice-campaign/active-health-special-point/thumbnail`,
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
    data: {
      reference: faker.datatype.uuid(),
    },
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
        return false
      }

      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isLt1M) {
        message.error('Image must smaller than 1MB!')
        return false
      }

      // Check image dimensions
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          // Check width and height
          if (img.width === 500 && img.height === 500) {
            resolve(true)
          } else {
            message.error('Image dimensions must be within 500x500 pixels!').then(() => {
              resolve(false)
            })
          }
        }

        img.src = URL.createObjectURL(file)
      })
    },
    onChange(info: UploadChangeParam<UploadFile<any>>) {
      if (info.file.status === 'removed') {
        form.setFieldsValue({
          imageUrl: undefined,
          defaultImage: undefined,
        })
        setFiles([])
        setDefaultFiles([])
      }

      if (info.file.status === 'uploading') {
        setFiles(info.fileList)
        setDefaultFiles(info.fileList)
      }

      if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }

      if (info.file.status === 'done') {
        form.setFieldsValue({
          imageUrl: info.file.response.url,
          defaultImage: info.file.response.url,
        })

        setFiles(info.fileList)
        setDefaultFiles(info.fileList)

        message.success(`${info.file.name} file upload success.`)
      } else if (!info.file.status) {
        const defaultImage = form.getFieldValue('defaultImage')
        form.setFieldsValue({
          imageUrl: defaultImage,
          defaultImage: defaultImage,
        })

        if (defaultImage) {
          setFiles(defaultFiles)
        } else {
          setFiles([])
        }
      }
    },
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
    },
  }

  const [activeHealthSpecialPointCampaignMutation] = useMutation(
    activeHealthSpecialPointCampaign.mutation.backofficeCreateActiveHealthSpecialPoint,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const [updateActiveHealthSpecialPointCampaignMutation] = useMutation(
    activeHealthSpecialPointCampaign.mutation.backofficeUpdateActiveHealthSpecialPoint,
    {
      fetchPolicy: 'no-cache',
    },
  )

  function funcCheckActiveHealthSpecialPointType(type: ActiveHealthSpecialPointEnum) {
    if (type === ActiveHealthSpecialPointEnum.MULTIPLIER) return 2
    if (type === ActiveHealthSpecialPointEnum.TARGET) return 2

    return 0
  }

  const handleFormChange = () => {
    const { title, descriptionEn, descriptionTh, dateRang } = form.getFieldsValue()

    const hasErrors =
      !title?.trim() || !descriptionEn?.trim() || !descriptionTh?.trim() || !dateRang

    setDisabledSave(hasErrors)
  }

  const handleDisabledSave = () => {
    setDisabledSave(true)
  }

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true)
        if (values) {
          const dataInput: CreateActiveHealthSpecialPointInputInterface = {
            input: {
              title: values.title,
              descriptionTh: values.descriptionTh,
              descriptionEn: values.descriptionEn,
              imageUrl: values.imageUrl,
              startDate: dayjs(values.dateRang[0]).format('YYYY-MM-DD'),
              endDate: dayjs(values.dateRang[1]).format('YYYY-MM-DD'),
              type: values.type,
              value: funcCheckActiveHealthSpecialPointType(values.type),
            },
          }

          const { mode } = dataCampaign
          const dataId = dataCampaign?.dataEditSpecialPoint?.id

          if (mode === ModeCampaignEnum.UPDATE && dataId) {
            const data = await updateActiveHealthSpecialPointCampaignMutation({
              variables: {
                input: {
                  id: dataId,
                  title: values.title,
                  descriptionTh: values.descriptionTh,
                  descriptionEn: values.descriptionEn,
                  imageUrl: values.imageUrl || null,
                  startDate: dayjs(values.dateRang[0]).format('YYYY-MM-DD'),
                  endDate: dayjs(values.dateRang[1]).format('YYYY-MM-DD'),
                  type: values.type,
                  value: funcCheckActiveHealthSpecialPointType(values.type),
                },
              },
            })

            if (data) {
              message.success({
                content: 'success',
                duration: 10,
              })
            }
            handleDisabledSave()
            setLoading(false)
            setFiles([])
            setDefaultFiles([])
            form.resetFields()
            handleOk()
          }

          if (mode === ModeCampaignEnum.CREATE) {
            const { data } = await activeHealthSpecialPointCampaignMutation({
              variables: {
                ...dataInput,
              },
            })

            if (data) {
              message.success({
                content: 'success',
                duration: 10,
              })
            }
            handleDisabledSave()
            setLoading(false)
            setFiles([])
            setDefaultFiles([])
            form.resetFields()
            handleOk()
          }
        }
      })
      .catch((errorKey) => {
        const messageError: { [key: string]: string } = {
          'ApolloError: BIZAHSP1001':
            'ขออภัย ไม่สามารถสร้าง campaign ได้ เนื่องจากช่วงเวลาซ้ำกับ campaign ที่ถูกสร้างไว้ก่อน',
          'Error: BIZAHSP1001':
            'ขออภัย ไม่สามารถสร้าง campaign ได้ เนื่องจากช่วงเวลาซ้ำกับ campaign ที่ถูกสร้างไว้ก่อน',
        }

        if (messageError[errorKey])
          message.error({
            content: `ปัญหา: ${messageError[errorKey]}`,
            duration: 10,
          })
        setLoading(false)
      })
  }

  const checkStatusCampaign = funcStatusCampaign(
    dayjs(dataCampaign?.dataEditSpecialPoint?.startDate),
    dayjs(dataCampaign?.dataEditSpecialPoint?.endDate),
  ).statusCampaign

  const disabledStatusCampaign = (): boolean => {
    return (
      checkStatusCampaign === StatusCampaignEnum.ACTIVE &&
      dataCampaign.mode === ModeCampaignEnum.UPDATE
    )
  }

  const disabledDatePickerRangePicker: RangePickerProps['disabledDate'] = (current) => {
    if (
      dataCampaign.mode === ModeCampaignEnum.UPDATE &&
      checkStatusCampaign === StatusCampaignEnum.INACTIVE
    ) {
      return current && current < dayjs().startOf('day').add(1, 'day')
    }

    if (
      dataCampaign.mode === ModeCampaignEnum.UPDATE &&
      checkStatusCampaign === StatusCampaignEnum.ACTIVE
    ) {
      return false
    }

    return current && current < dayjs().startOf('day').add(1, 'day')
  }

  const isDisabledPickerRangePicker = (): [boolean, boolean] | boolean => {
    if (
      dataCampaign.mode === ModeCampaignEnum.UPDATE &&
      checkStatusCampaign === StatusCampaignEnum.INACTIVE
    ) {
      return [false, false]
    }

    if (
      dataCampaign.mode === ModeCampaignEnum.UPDATE &&
      checkStatusCampaign === StatusCampaignEnum.ACTIVE
    ) {
      return [true, false]
    }

    return false
  }

  useEffect(() => {
    if (dataCampaign.dataEditSpecialPoint) {
      form.setFieldsValue({
        title: dataCampaign.dataEditSpecialPoint.title,
        descriptionTh: dataCampaign.dataEditSpecialPoint.descriptionTh,
        descriptionEn: dataCampaign.dataEditSpecialPoint.descriptionEn,
        imageUrl: dataCampaign.dataEditSpecialPoint.imageUrl,
        defaultImage: dataCampaign.dataEditSpecialPoint.imageUrl,
        dateRang: [
          dayjs(dataCampaign.dataEditSpecialPoint.startDate),
          dayjs(dataCampaign.dataEditSpecialPoint.endDate),
        ],
        type: dataCampaign.dataEditSpecialPoint.type,
        value: dataCampaign.dataEditSpecialPoint.value,
      })

      if (dataCampaign.dataEditSpecialPoint.imageUrl) {
        const imageUrlString = dataCampaign.dataEditSpecialPoint.imageUrl
        const startIndex = imageUrlString.lastIndexOf('/') + 1
        const endIndex = Math.max(imageUrlString.indexOf('.jpeg'), imageUrlString.indexOf('.png'))

        const uuid = imageUrlString.slice(startIndex, endIndex)
        const data = {
          uid: uuid,
          name: uuid,
          status: 'success',
          type: 'image/png',
          response: {
            reference: dataCampaign.dataEditSpecialPoint.imageUrl,
            url: dataCampaign.dataEditSpecialPoint.imageUrl,
          },
        } as UploadFile<any>

        setFiles([data])
        setDefaultFiles([data])
      }
    }
  }, [dataCampaign, dataCampaign.dataEditSpecialPoint, form])

  return {
    form,
    funcCheckActiveHealthSpecialPointType,
    activeHealthSpecialPointCampaignMutation,
    imgCampaign,
    descriptionThCampaign,
    handleUploadProps,
    onSubmit,
    disabledSave,
    handleFormChange,
    handleDisabledSave,
    disabledStatusCampaign,
    isLoading,
    files,
    setFiles,
    setDefaultFiles,
    disabledDatePickerRangePicker,
    isDisabledPickerRangePicker,
  }
}

export default CampaignModalHook
