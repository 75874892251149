import { TabsProps } from 'antd'
import { BillingTabEnum } from '@/constant/BILLING_FILTER'
import BillingTable from '@/components/feature/PolicyDetailPage/BillingTable'
import ManualDiscountTable from '@/components/feature/PolicyDetailPage/ManualDiscountTable'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'
import { useEffect, useState } from 'react'

function PremiumTabHook() {
  const user = useRecoilValue(userAtom)
  const canViewBillingTab = validatePerm([BACKOFFICE_USER_PERMISSION.HLT010101], user.permissions)
  const [tabActiveKey, setTabActiveKey] = useState<BillingTabEnum | undefined>(undefined)
  const canViewManualDiscountTab = validatePerm(
    [BACKOFFICE_USER_PERMISSION.HLT010104],
    user.permissions,
  )
  const tabItems: TabsProps['items'] = [
    {
      key: BillingTabEnum.SUMMARY,
      label: 'สรุปรอบบิล',
      children: <BillingTable />,
      disabled: !canViewBillingTab,
    },
    {
      key: BillingTabEnum.MANUAL_DISCOUNT,
      label: 'รายการขออนุโลมส่วนลด',
      children: <ManualDiscountTable />,
      disabled: !canViewManualDiscountTab,
    },
  ]

  useEffect(() => {
    if (user.permissions.length > 0) {
      if (canViewBillingTab && canViewManualDiscountTab) {
        setTabActiveKey(undefined)
      } else if (canViewBillingTab) {
        setTabActiveKey(BillingTabEnum.SUMMARY)
      } else if (canViewManualDiscountTab) {
        setTabActiveKey(BillingTabEnum.MANUAL_DISCOUNT)
      }
    }
  }, [user.permissions])

  return { tabItems, tabActiveKey }
}

export default PremiumTabHook
