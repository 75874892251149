import backofficeFAQInfoes from './backofficeFAQInfoes/backofficeFAQInfoes.gql'
import backofficeFAQInfoesByFAQCategoryId from './backofficeFAQInfoesByFAQCategoryId/backofficeFAQInfoesByFAQCategoryId.gql'
import createBackofficeFAQInfo from './createBackofficeFAQInfo/createBackofficeFAQInfo.gql'
import removeBackofficeFAQInfo from './removeBackofficeFAQInfo/removeBackofficeFAQInfo.gql'
import updateBackofficeFAQInfo from './updateBackofficeFAQInfo/updateBackofficeFAQInfo.gql'
import updateFAQCategoryBackofficeFAQInfo from './updateFAQCategoryBackofficeFAQInfo/updateFAQCategoryBackofficeFAQInfo.gql'
import updateOrderBackofficeFAQInfo from './updateOrderBackofficeFAQInfo/updateOrderBackofficeFAQInfo.gql'

const backofficeFAQInfo = {
  query: {
    backofficeFAQInfoesByFAQCategoryId,
    backofficeFAQInfoes,
  },
  mutation: {
    createBackofficeFAQInfo,
    updateBackofficeFAQInfo,
    removeBackofficeFAQInfo,
    updateOrderBackofficeFAQInfo,
    updateFAQCategoryBackofficeFAQInfo,
  },
}

export default backofficeFAQInfo
