import styled from '@emotion/styled'

export const Container = styled('div')`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;
`
export const Title = styled('div')`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 11px;
`
export const InformationContainer = styled('div')<{ fourColumn?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.fourColumn ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr')};
  margin-top: 32px;
`

export const Information = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
`
