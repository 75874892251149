import { useRecoilState } from 'recoil'
import { useContext } from 'react'
import policyDetailAtom from '@/recoil/policyDetail'
import { PolicyGroupEnum } from '@/constant/POLICY_GROUP'
import { PolicyDetailContext } from '@/contexts'
import { IMAGE_URL } from '@/constant/IMAGE'

function PolicyCardHook() {
  const { getPolicyByPolicyNumber } = useContext(PolicyDetailContext)
  const [{ policies }] = useRecoilState(policyDetailAtom)
  const policyIcons: Array<{ src: string; policyGroup: PolicyGroupEnum }> = [
    {
      src: IMAGE_URL.motorIcon,
      policyGroup: PolicyGroupEnum.MOTOR,
    },
    {
      src: IMAGE_URL.motorIcon,
      policyGroup: PolicyGroupEnum.ON_OFF_PACKAGE,
    },
    {
      src: IMAGE_URL.motorIcon,
      policyGroup: PolicyGroupEnum.ON_OFF_TOP_UP,
    },
    {
      src: IMAGE_URL.activeHealthIcon,
      policyGroup: PolicyGroupEnum.HEALTH,
    },
    {
      src: IMAGE_URL.activeHealthIcon,
      policyGroup: PolicyGroupEnum.ACTIVE_HEALTH,
    },
    {
      src: IMAGE_URL.activeHealthIcon,
      policyGroup: PolicyGroupEnum.PA,
    },
    {
      src: IMAGE_URL.travelIcon,
      policyGroup: PolicyGroupEnum.TRAVEL,
    },
    {
      src: IMAGE_URL.travelIcon,
      policyGroup: PolicyGroupEnum.TRAVEL_ON_OFF,
    },
    {
      src: IMAGE_URL.fireIcon,
      policyGroup: PolicyGroupEnum.FIRE,
    },
    {
      src: IMAGE_URL.fireIcon,
      policyGroup: PolicyGroupEnum.MARINE,
    },
    {
      src: IMAGE_URL.fireIcon,
      policyGroup: PolicyGroupEnum.OTHERS,
    },
  ]

  const handleDisplayPolicy = (policyNumber: string) => {
    getPolicyByPolicyNumber(policyNumber)
  }
  return {
    policies,
    handleDisplayPolicy,
    policyIcons,
  }
}

export default PolicyCardHook
