import React from 'react'
import styled from '@emotion/styled'
import { Dropdown as AntdDropdown, DatePicker as AntdDatePicker } from 'antd'
import { css } from '@emotion/css'
import Button from '@/components/common/Button'

export const Container = styled('div')``

export const Block = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`

export const Title = styled('p')`
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-bottom: 0;
`

export const EyeIcon = styled('img')`
  cursor: pointer;
  font-size: 20px;
  color: #666666;
`

export const EditIcon = styled('img')`
  cursor: pointer;
  width: 20px;
  height: 20px;
`

export const Dropdown = styled(AntdDropdown)``

export const ChooseDateButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  padding: 8px 16px;
`

export const CalendarIcon = styled('img')`
  margin-right: 8px;
  width: 16px;
`

export const DatePickerStyles = css`
  visibility: hidden;
  position: absolute;
  top: 18px;
  right: 0;
`

export const ButtonContainer = styled('div')`
  position: relative;
`

export const DatePickerExtraFooter = styled('div')`
  margin: 16px;
`

export const FooterBlock = styled('div')`
  display: flex;
  align-items: center;
`

export const FooterContainer = styled('div')<{ isIconWellPoint: boolean }>`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(${(props) => (props.isIconWellPoint ? '3' : '2')}, 1fr);
`

export const Circle = styled('div')<{ backgroundColor?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor};
  margin-right: 8px;
`

export const FooterText = styled('p')`
  margin-bottom: 0;
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.004em;
  color: #666666;
`

export const ActiveBonusIcon = styled('img')`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const DatePickerCellInner = styled('div')<{
  isHighlight?: boolean
  backgroundColor?: string
}>`
  position: relative;
  background-color: ${(props) => (props.isHighlight ? props.backgroundColor : 'unset')};
  border-radius: ${(props) => (props.isHighlight ? '8px' : 'unset')};
`

export const highlightColor: {
  two: React.CSSProperties
  one: React.CSSProperties
} = {
  two: {
    backgroundColor: '#4DC370',
  },
  one: {
    backgroundColor: '#C3E9CC',
  },
}

export const ActiveBonusIconDateCellStyles = css`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 90%);
  width: 20px;
`

export const NotificationStyles = css`
  font-weight: 400;
  font-size: 11.5px;
  color: #276ef1;
  line-height: 1.5;
  text-align: left;
`

export const FlashIconStyles = css`
  margin-right: 8px;
`

export const NotificationContainerStyles = css`
  background: #e2f2ff;
  padding: 8px;
  border-radius: 8px;
  margin: 8px 0;

  display: flex;
  align-items: center;
`

export const DatePicker = styled(AntdDatePicker)``
