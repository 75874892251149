import config from '@/config'
import { httpClientPrivate } from '@/libs/httpClient'
import { useQuery } from '@tanstack/react-query'
import { ExportOrderInputType, OrderItemType } from './types'

const fetchExportOrder = async (input: ExportOrderInputType) => {
  const response = await httpClientPrivate.post(
    `${config.restUrl}/v1/backoffice-commerce/order/export`,
    input,
  )

  return response.data
}

const useExportOrder = ({ tab, from, to }: ExportOrderInputType) => {
  const enabled = !!tab && !!from && !!to
  const query = useQuery<Array<OrderItemType>>({
    queryKey: ['useExportOrder', tab, from, to],
    queryFn: async () => fetchExportOrder({ tab, from, to }),
    enabled,
  })

  return query
}

export { useExportOrder, fetchExportOrder }
