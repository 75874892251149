import React from 'react'

import {
  Container,
  InformationTitle,
  Information,
  Block,
  MoreInfoIcon,
  MoreInfoOutlineIcon,
  EvBadge,
} from './styles';
import { InformationGroupInterface } from './interfaces'
import { Tooltip } from 'antd'

function InformationGroup({
  information,
  fontColor,
  infoIcon,
  evBadge,
  infoIconFunction,
  marginBottomUnset,
  dataTestId,
  tooltip,
  tooltipMessage,
}: InformationGroupInterface) {
  return (
    <Container marginBottomUnset={marginBottomUnset}>
      <InformationTitle>
        {information.title}{' '}
        {tooltip ? (
          <Tooltip title={tooltipMessage}>
            <MoreInfoOutlineIcon />
          </Tooltip>
        ) : undefined}
      </InformationTitle>
      <Block>
        <Information fontColor={fontColor} data-testid={dataTestId}>
          {information.detail}
          {evBadge ? <EvBadge color="blue">EV</EvBadge> : undefined}
        </Information>
        {infoIcon ? <MoreInfoIcon onClick={infoIconFunction} /> : undefined}
      </Block>
    </Container>
  )
}

export default InformationGroup
