import { SearchOutlined, PlusOutlined } from '@ant-design/icons'
import { IMAGE_URL } from '@/constant/IMAGE'
import Table from '@/components/common/Table'
import {
  AddMemberIcon,
  Button,
  Container,
  DescriptionNoTeamMember,
  NoTeamMemberWrapper,
  SearchInput,
  SearchWrapper,
  Title,
  TitleNoTeamMember,
} from './styles'
import TabTeamMemberHook from './TabTeamMember.hook'
import ModalAddUser from '../components/ModalAddUser/ModalAddUser'
import ModalAddTeamMember from '../components/ModalAddTeamMember/ModalAddTeamMember'

const TabTeamMember = () => {
  const {
    handleSearchTextChange,
    backofficeUserTeamRole,
    pagination,
    columns,
    handleAddUser,
    onHandlePageChange,
    loadingGetBackofficeUserTeamRolePaginatedQuery,
    handleTableChange,
  } = TabTeamMemberHook()

  return (
    <Container>
      <Title>รายชื่อสมาชิกทีม</Title>
      <SearchWrapper>
        <SearchInput
          placeholder="ค้นหา..."
          prefix={<SearchOutlined style={{ color: '#ADADAD' }} />}
          onChange={handleSearchTextChange}
          data-testid="role-mange-tab-team-member-tab"
        />
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={handleAddUser}
          data-testid="role-manage-tab-add-user-button"
        >
          เพิ่มผู้ใช้งาน
        </Button>
      </SearchWrapper>
      {backofficeUserTeamRole && backofficeUserTeamRole.length > 0 ? (
        <Table
          loading={loadingGetBackofficeUserTeamRolePaginatedQuery}
          columns={columns.filter((column) => column.width !== 0)}
          dataSource={backofficeUserTeamRole}
          pagination={{
            pageSizeOptions: [10, 25, 50, 100],
            showSizeChanger: true,
            total: pagination?.totalItems,
            current: pagination.page,
            defaultCurrent: 1,
            pageSize: pagination.limit,
            onChange: (page, pageSize) => onHandlePageChange(page, pageSize),
          }}
          onChange={(_pagination, _filters, sorter) => handleTableChange(sorter)}
          totalRecord={pagination?.totalItems}
        />
      ) : (
        <NoTeamMemberWrapper>
          <AddMemberIcon src={IMAGE_URL.addTeamMemberIcon}></AddMemberIcon>
          <TitleNoTeamMember>ไม่มีสมาชิก</TitleNoTeamMember>
          <DescriptionNoTeamMember>กดปุ่ม “เพิ่มสมาชิก” ที่คุณต้องการ</DescriptionNoTeamMember>
        </NoTeamMemberWrapper>
      )}
      <ModalAddUser />
      <ModalAddTeamMember />
    </Container>
  )
}

export default TabTeamMember
