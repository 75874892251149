import styled from '@emotion/styled'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export const Container = styled('div')`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
`

export const SearchInput = styled(Input)`
  width: 690px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 24px;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`

export const ActionText = styled('span')`
  color: #009640;
  cursor: pointer;
`
