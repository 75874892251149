import { gql } from '@apollo/client'
import { TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetAddressesInputInterface,
  BackofficeGetAddressesDataInterface,
} from './interface'

const backofficeGetAddresses: TypedDocumentNode<
  BackofficeGetAddressesDataInterface,
  BackofficeGetAddressesInputInterface
> = gql`
  query BackofficeGetAddresses($input: BackofficeGetAddressInput!) {
    backofficeGetAddresses(input: $input) {
      displayNameTh
      displayNameEn
      provinceCode
      districts {
        displayNameTh
        displayNameEn
        districtCode
        subDistricts {
          displayNameTh
          displayNameEn
          subDistrictCode
          postCode
        }
      }
    }
  }
`

export default backofficeGetAddresses
