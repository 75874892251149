import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeTeamDeleteTeamDataInterface,
  BackofficeTeamDeleteTeamInputInterface,
} from './interfaces'

const backofficeTeamDeleteTeam: TypedDocumentNode<
  BackofficeTeamDeleteTeamDataInterface,
  BackofficeTeamDeleteTeamInputInterface
> = gql`
  mutation BackofficeTeamDeleteTeam($id: ID!) {
    backofficeTeamDeleteTeam(id: $id) {
      code
      message
      data
    }
  }
`

export default backofficeTeamDeleteTeam
