import styled from '@emotion/styled'
import CommonModal from '@/components/common/Modal'

export const Modal = styled(CommonModal)`
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: unset;
    }

    .ant-modal-footer {
      border-top: unset;
      background: #fafafa;
    }
  }
`

export const Container = styled('div')``

export const Text = styled('p')`
  font-size: 16px;
  color: #666666;
`
