import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeSearchActiveHealthSpecialPointDataInterface,
  BackofficeSearchActiveHealthSpecialPointInputInterface,
} from './interfaces'

const backofficeSearchActiveHealthSpecialPoint: TypedDocumentNode<
  BackofficeSearchActiveHealthSpecialPointDataInterface,
  BackofficeSearchActiveHealthSpecialPointInputInterface
> = gql`
  query BackofficeSearchActiveHealthSpecialPoint($input: RequestPaginationInput!) {
    backofficeSearchActiveHealthSpecialPoint(input: $input) {
      data {
        id
        title
        descriptionTh
        descriptionEn
        imageUrl
        startDate
        endDate
        type
        value
        createdAt
        updatedAt
      }
      meta {
        totalItems
        itemsPerPage
        currentPage
        nextPage
        previousPage
        totalPages
      }
    }
  }
`

export default backofficeSearchActiveHealthSpecialPoint
