import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { generatePath } from 'react-router'
import { useLazyQuery } from '@apollo/client'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'

import { userGql, ppuMotorGql } from '@/gql'
import { PolicyDetailProps, SearchInputProps, InputProps } from './types'
import policyDetailAtom from '@/recoil/policyDetail'
import policyAtom from '@/recoil/policy'
import deviceDetailAtom from '@/recoil/deviceDetail'
import tviConnectMainSimPackageAtom from '@/recoil/tviConnectMainSimPackage'
import CONSTANT from '@/constant'
import { toThaiLocaleDateString } from '@/libs/date'
import { UserPolicyInterface } from '@/gql/ppuMotor/getAllUserPoliciesByIdentityId/interfaces'

export enum SearchStateEnum {
  NONE = 'NONE',
  SEARCHING = 'SEARCHING',
  FINISH = 'FINISH',
}

function SearchPolicyBoxHook() {
  const [input, setInput] = useState<InputProps>({
    value: null,
    type: null,
  })
  const [searchState, setSearchState] = useState<SearchStateEnum>(SearchStateEnum.NONE)
  const { searchInput } = useParams<SearchInputProps>()
  const navigate = useNavigate()
  const setPolicyDetail = useSetRecoilState(policyDetailAtom)
  const policy = useRecoilValue(policyAtom)
  const [
    backofficeSearchUserQuery,
    {
      loading: backofficeSearchUserQueryLoading,
      data: backofficeSearchUserQueryData,
      error: backofficeSearchUserQueryError,
    },
  ] = useLazyQuery(userGql.query.backofficeSearchUser, {
    fetchPolicy: 'no-cache',
  })
  const [
    fetchGetAllUserPoliciesByIdentityId,
    {
      loading: fetchGetAllUserPoliciesByIdentityIdLoading,
      data: fetchGetAllUserPoliciesByIdentityIdData,
      error: fetchGetAllUserPoliciesByIdentityIdError,
    },
  ] = useLazyQuery(ppuMotorGql.query.getAllUserPoliciesByIdentityId, {
    fetchPolicy: 'no-cache',
  })
  const resetPolicy = useResetRecoilState(policyAtom)
  const resetPolicyDetail = useResetRecoilState(policyDetailAtom)
  const resetDeviceDetail = useResetRecoilState(deviceDetailAtom)
  const resetTviConnectMainPackage = useResetRecoilState(tviConnectMainSimPackageAtom)
  const isLoading = backofficeSearchUserQueryLoading || fetchGetAllUserPoliciesByIdentityIdLoading
  const isError = fetchGetAllUserPoliciesByIdentityIdError || backofficeSearchUserQueryError

  useEffect(() => {
    if (searchInput) {
      handleSearch(searchInput)
    }
  }, [searchInput])

  useEffect(() => {
    if (backofficeSearchUserQueryData && backofficeSearchUserQueryData.backofficeSearchUser) {
      const { foreignCitizen, thaiCitizen } = backofficeSearchUserQueryData.backofficeSearchUser
      const identityId = foreignCitizen?.passportId || thaiCitizen?.identityId

      if (identityId) {
        fetchGetAllUserPoliciesByIdentityId({
          variables: {
            identityId: identityId,
          },
        })
      }
    }
  }, [backofficeSearchUserQueryData])

  useEffect(() => {
    if (fetchGetAllUserPoliciesByIdentityIdData) {
      const { getAllUserPoliciesByIdentityId: policies } = fetchGetAllUserPoliciesByIdentityIdData

      if (backofficeSearchUserQueryData && backofficeSearchUserQueryData.backofficeSearchUser) {
        const {
          id,
          foreignCitizen,
          thaiCitizen,
          firstname,
          lastname,
          mobileNumber,
          email,
          createdAt,
          deleteRequestedAt,
        } = backofficeSearchUserQueryData.backofficeSearchUser

        const policyDetail: PolicyDetailProps = {
          id: id,
          insuredName: `${firstname} ${lastname}`,
          identityId: foreignCitizen?.passportId || thaiCitizen?.identityId,
          mobileNumber: mobileNumber,
          email: email,
          registeredDate: createdAt ? toThaiLocaleDateString({ date: createdAt }) : '-',
          policies:
            input.type === CONSTANT.SEARCH_POLICY_BOX.SEARCH_USER_INPUT_TYPE_POLICY_NUMBER
              ? policies.filter(
                  (policy: UserPolicyInterface) => policy.policyNumber === input.value,
                )
              : policies,
          deleteRequestedAt: deleteRequestedAt
            ? toThaiLocaleDateString({ date: deleteRequestedAt })
            : null,
        }

        setPolicyDetail(policyDetail)
        setSearchState(SearchStateEnum.FINISH)
      }
    }
  }, [fetchGetAllUserPoliciesByIdentityIdData])

  useEffect(() => {
    if (backofficeSearchUserQueryError || fetchGetAllUserPoliciesByIdentityIdError) {
      setSearchState(SearchStateEnum.NONE)
      resetPolicy()
      resetPolicyDetail()
      resetDeviceDetail()
      resetTviConnectMainPackage()
    }
  }, [backofficeSearchUserQueryError, fetchGetAllUserPoliciesByIdentityIdError])

  const handleSetSearchState = (state: SearchStateEnum) => setSearchState(state)

  const handleSearch = (value: any) => {
    if (value) {
      const input = {
        name: '',
        identityId: '',
        policyNumber: '',
        mobileNumber: '',
      }

      navigate(generatePath(CONSTANT.ROUTES.POLICY_DETAIL_PAGE, { searchInput: value }))

      if (isNaN(value)) {
        input.name = value
        setInput({
          value: value,
          type: CONSTANT.SEARCH_POLICY_BOX.SEARCH_USER_INPUT_TYPE_NAME,
        })
      } else {
        if (value.length === 16) {
          input.policyNumber = value
          setInput({
            value: value,
            type: CONSTANT.SEARCH_POLICY_BOX.SEARCH_USER_INPUT_TYPE_POLICY_NUMBER,
          })
        } else if (value.length === 13) {
          input.identityId = value
          setInput({
            value: value,
            type: CONSTANT.SEARCH_POLICY_BOX.SEARCH_USER_INPUT_TYPE_IDENTITY_ID,
          })
        } else if (value.length === 10) {
          input.mobileNumber = value
          setInput({
            value: value,
            type: CONSTANT.SEARCH_POLICY_BOX.SEARCH_USER_INPUT_TYPE_MOBILE_NUMBER,
          })
        }
      }

      backofficeSearchUserQuery({
        variables: {
          input,
        },
      })
    }
    setSearchState(SearchStateEnum.SEARCHING)
    resetPolicy()
    resetPolicyDetail()
    resetDeviceDetail()
    resetTviConnectMainPackage()
  }

  return {
    policy,
    handleSearch,
    isError,
    isLoading,
    searchState,
    handleSetSearchState,
  }
}

export default SearchPolicyBoxHook
