import styled from '@emotion/styled'
import { Breadcrumb as AntBreadcrumb } from 'antd'

export const Breadcrumb = styled(AntBreadcrumb)``
export const BreadcrumbItem = styled(AntBreadcrumb.Item)``

export const SelectItem = styled('span')`
  cursor: pointer;

  &:hover {
    color: #009640;
  }
`
