import { useEffect } from 'react'
import { Form } from 'antd'
import 'react-quill/dist/quill.snow.css'
import { QuestionFormType, OnSubmitProps, QuestionHookProps } from './types'
import { useMutation } from '@apollo/client'
import backofficeFAQInfo from '@/gql/backofficeFAQInfo'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user/atom'
import { statusQuestionEnum } from '../../types'
import message from '@/libs/message'

export const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
  ],
}

const QuestionFormHook = ({
  mode,
  subCategory,
  selectedFaqCategory,
  onSuccess,
}: QuestionHookProps) => {
  const user = useRecoilValue(userAtom)
  const [form] = Form.useForm<QuestionFormType>()

  const [createBackofficeFAQInfoMutation, { loading: loadingCreateBackofficeFAQInfoMutation }] =
    useMutation(
      backofficeFAQInfo.mutation.createBackofficeFAQInfo,

      {
        fetchPolicy: 'no-cache',
      },
    )

  const [updateBackofficeFAQInfoMutation, { loading: loadingUpdateBackofficeFAQInfoMutation }] =
    useMutation(
      backofficeFAQInfo.mutation.updateBackofficeFAQInfo,

      {
        fetchPolicy: 'no-cache',
      },
    )

  const onSubmit = async (value: OnSubmitProps) => {
    if (user.id) {
      if (mode === statusQuestionEnum.EDIT_QUESTION && subCategory?.id) {
        try {
          const { data } = await updateBackofficeFAQInfoMutation({
            variables: {
              input: {
                faqInfoId: subCategory.id,
                faqCategoryId: value.faqCategoryId,
                title: value.title,
                description: value.description,
                status: 'PUBLISH',
              },
            },
          })

          if (data && data.updateBackofficeFAQInfo) {
            message.success('แก้ไขคำถามสำเร็จ')
            onSuccess()
          }
        } catch (err) {
          console.error(err)
        }
      }

      if (mode === statusQuestionEnum.CREATE_QUESTION) {
        try {
          const { data } = await createBackofficeFAQInfoMutation({
            variables: {
              input: {
                title: value.title,
                description: value.description,
                faqCategoryId: value.faqCategoryId,
                status: 'PUBLISH',
              },
            },
          })
          if (data && data.createBackofficeFAQInfo) {
            message.success('เพิ่มคำถามสำเร็จ')
            onSuccess()
          }
        } catch (err) {
          console.error(err)
        }
      }
    }
  }

  useEffect(() => {
    if (mode === statusQuestionEnum.EDIT_QUESTION && subCategory) {
      form.setFieldsValue({
        title: subCategory.title,
        description: subCategory.description,
        faqCategoryId: selectedFaqCategory?.id,
      })
    }
  }, [form, mode, selectedFaqCategory?.id, subCategory])

  const isLoading = loadingCreateBackofficeFAQInfoMutation || loadingUpdateBackofficeFAQInfoMutation

  return {
    form,
    onSubmit,
    modules,
    loadingCreateBackofficeFAQInfoMutation,
    loadingUpdateBackofficeFAQInfoMutation,
    isLoading,
  }
}

export default QuestionFormHook
