import { ORDER_TAB, OrderTabEnum } from '@/constant/TA_TRANSACTION'
import { Breadcrumb, BreadcrumbItem, SelectItem } from './styles'
import TaTransactionOrderHeaderHook from './TaTransactionOrderHeader.hook'

const TaTransactionOrderHeader = ({
  orderTab,
  orderNumber,
}: {
  orderTab: OrderTabEnum
  orderNumber: string | undefined
}) => {
  const { goBack } = TaTransactionOrderHeaderHook()
  return (
    <Breadcrumb>
      <BreadcrumbItem>รายการสั่งซื้อประกันภัยการเดินทาง</BreadcrumbItem>
      <BreadcrumbItem onClick={goBack}>
        <SelectItem>{ORDER_TAB[orderTab]}</SelectItem>
      </BreadcrumbItem>
      <BreadcrumbItem>{orderNumber}</BreadcrumbItem>
    </Breadcrumb>
  )
}

export default TaTransactionOrderHeader
