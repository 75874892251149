import { gql, TypedDocumentNode } from '@apollo/client'

export const backofficeHardResetActiveHealthWearable: TypedDocumentNode<{}, { id: string }> = gql`
  mutation backofficeHardResetActiveHealthWearable($id: ID!) {
    backofficeHardResetActiveHealthWearable(id: $id) {
      code
      message
    }
  }
`

export default backofficeHardResetActiveHealthWearable
