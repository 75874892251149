import { ColumnsType } from 'antd/lib/table'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'
import dayjs from 'dayjs'

import { ContactPersonResponse, OrderResponse } from '@/services/order/type'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { OrderTabEnum, ProductTypeEnum, TITLE_TYPE, TitleTypeEnum } from '@/constant/TA_TRANSACTION'
import { formatLocaleDate } from '@/libs/utils/format-locale-date.util'
import { UserSelectStyle } from '@/components/common/Table/styles'
import { validatePerm } from '@/libs/validatePermission'
import { ActionBlock, ButtonIcon, InformationContainer } from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import { useState } from 'react'
import { isTimesEqual } from '@/libs/utils/is-times-equal'
import { diffDayRounded } from '@/libs/utils/day-diff-rounded.util'

const TaTransactionOrderCardPolicyHook = ({ data }: { data: OrderResponse | undefined }) => {
  const location = useLocation()
  const user = useRecoilValue(userAtom)
  const isContactCorporation = data?.contactPerson?.type === TitleTypeEnum.CORPORATION
  const isContactPerson = validatePerm([BACKOFFICE_USER_PERMISSION.TA010202], user.permissions)

  const formatThaiAndEnglishDate = (date: Date, timeFormat = false): string => {
    const thaiFormat = formatLocaleDate(date, 'th', 'D MMM BBBB')
    const englishFormat = formatLocaleDate(
      date,
      'en',
      timeFormat
        ? isTimesEqual(date, '23:59')
          ? '(D MMM YYYY) (24.00)'
          : '(D MMM YYYY) (HH.mm)'
        : '(D MMM YYYY)',
    )
    return `${thaiFormat} ${englishFormat}`
  }

  let travelDays = '-'
  if (data?.plan?.product?.type?.code === ProductTypeEnum.TRAVEL_TRIP) {
    const firstDate = dayjs(data?.effectiveDate).startOf('d').toDate()
    const secondDate = dayjs(data?.expiryDate).endOf('d').toDate()
    const diffDays = diffDayRounded(firstDate, secondDate)
    travelDays = `${diffDays} วัน (${diffDays} Days)`
  }

  if (data?.plan?.product?.type?.code === ProductTypeEnum.TRAVEL_ANNUAL) {
    travelDays = `${data?.departureDay?.titleTh} (${data?.departureDay?.titleEn})`
  }

  const policyDetails: { title: string; value: string }[] = [
    {
      title: 'แผนประกันภัย',
      value: `ประกันภัยการเดินทาง ${data?.plan?.product?.nameTh} ${data?.plan?.code} ${data?.plan?.nameTh}`,
    },
    {
      title: 'แบบความคุ้มครอง',
      value: `${data?.plan?.product?.type?.nameTh} (${data?.plan?.product?.type?.nameEn})`,
    },
    {
      title: 'จำนวนวันเดินทาง',
      value: travelDays,
    },
    {
      title: 'จำนวนผู้เดินทาง',
      value: `${data?.totalOrderItem} ท่าน (${data?.totalOrderItem} persons)`,
    },
    {
      title: 'วันที่เริ่มความคุ้มครอง',
      value: data?.effectiveDate ? formatThaiAndEnglishDate(data?.effectiveDate, true) : '-',
    },
    {
      title: 'วันที่สิ้นสุดความคุ้มครอง',
      value: data?.expiryDate ? formatThaiAndEnglishDate(data?.expiryDate, true) : '-',
    },
    {
      title: 'จุดหมายปลายทาง',
      value:
        data?.plan?.product?.type?.code === ProductTypeEnum.TRAVEL_TRIP
          ? data?.destination.map((country) => country.nameEn).join(', ')
          : data?.plan?.product?.type?.code === ProductTypeEnum.TRAVEL_ANNUAL
          ? `${data?.plan?.product?.nameTh} (${data?.plan?.product?.nameEn})`
          : '-',
    },
    {
      title: 'ช่องทางการชำระเงิน',
      value: data?.payment?.paymentType?.name ?? '-',
    },
    {
      title: 'ยอดค่าเบี้ยชำระรวม',
      value: data?.premiumAmount
        ? `${data?.premiumAmount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} บาท (THB)`
        : '-',
    },
    {
      title: 'ส่วนลด',
      value: data?.discountAmount
        ? `${data?.discountAmount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} บาท (THB)`
        : '-',
    },
    {
      title: 'รหัสส่วนลด',
      value: data?.promotionCode ?? '-',
    },
    {
      title: 'โปรโมชั่นหมดอายุ',
      value: data?.promotionExpire ? dayjs(data?.promotionExpire).format('DD-MM-YYYY') : '-',
    },
  ]

  const addressColumn = {
    title: 'ที่อยู่',
    className: UserSelectStyle,
    ellipsis: {
      showTitle: false,
    },
    render: (value: ContactPersonResponse) => {
      const address = `${value?.address} ${value?.subDistrict?.displayNameTh} ${value?.district?.displayNameTh} ${value?.province?.displayNameTh} ${value?.subDistrict?.postCode}`

      return (
        <InformationContainer placement="bottomLeft" title={address}>
          {address}
        </InformationContainer>
      )
    },
  }

  const corporationColumns: ColumnsType<object> = [
    {
      title: 'ชื่อบริษัท',
      className: UserSelectStyle,
      render: (contactPerson) => {
        if (contactPerson.type !== TitleTypeEnum.CORPORATION || !contactPerson.companyName) {
          return '-'
        }

        const { companyTitle, companyName } = contactPerson
        const title = companyTitle?.titleTh || ''

        return `${title} ${companyName}`
      },
    },
    {
      title: 'เลขนิติบุคคล',
      dataIndex: ['companyNumber'],
      className: UserSelectStyle,
      render: (companyNumber) => {
        return companyNumber ?? '-'
      },
    },
    {
      title: 'เลขที่สาขา',
      dataIndex: ['companyBranchNumber'],
      className: UserSelectStyle,
      render: (companyBranchNumber) => {
        return companyBranchNumber ?? '-'
      },
    },
    addressColumn,
  ]

  const commonColumns: ColumnsType<object> = [
    {
      title: 'ชื่อผู้ติดต่อ',
      className: UserSelectStyle,
      render: (contactPerson) => {
        return `${contactPerson?.title?.titleEn} ${contactPerson.firstname} ${contactPerson.lastname}`
      },
    },
    !isContactCorporation ? addressColumn : { width: 0 },
    {
      width: 120,
      title: 'เบอร์โทรศัพท์',
      dataIndex: ['mobileNumber'],
      className: UserSelectStyle,
    },
    {
      title: 'อีเมล',
      dataIndex: ['email'],
      className: UserSelectStyle,
    },
    {
      title: 'ประเภทผู้สั่งซื้อ',
      dataIndex: ['type'],
      className: UserSelectStyle,
      render: (type) => {
        return type ? TITLE_TYPE[type as TitleTypeEnum] : '-'
      },
    },
  ]

  const contactPersonColumns: ColumnsType<object> = [
    ...(isContactCorporation ? corporationColumns : []),
    ...commonColumns,
    // filter order status and orders tab (use order tab to make sure the status is expired)
    validatePerm([BACKOFFICE_USER_PERMISSION.TA010203], user.permissions) &&
    [OrderTabEnum.DRAFT, OrderTabEnum.WAITING_PAYMENT, OrderTabEnum.PAYMENT_FAILED].includes(
      location.state?.data?.tab,
    )
      ? {
          width: 60,
          render: (contactPerson) => {
            return (
              <ActionBlock>
                <ButtonIcon
                  src={IMAGE_URL.editIcon}
                  onClick={() => {
                    openModal(contactPerson)
                  }}
                />
              </ActionBlock>
            )
          },
        }
      : { width: 0 },
  ]

  const [selectedContactPerson, setSelectContactPerson] = useState<ContactPersonResponse | null>(
    null,
  )

  const openModal = (contactPerson: ContactPersonResponse) => {
    setSelectContactPerson(contactPerson)
  }

  const closeModal = () => {
    setSelectContactPerson(null)
  }

  return {
    policyDetails,
    isContactPerson,
    contactPersonColumns,
    openModal,
    closeModal,
    selectedContactPerson,
  }
}

export default TaTransactionOrderCardPolicyHook
