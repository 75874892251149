// TODO: move to constant
export enum ActiveHealthWeeklySummaryStatusEnum {
  GOTDISCOUNT = 'GOTDISCOUNT',
  NOTHING = 'NOTHING',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export type ActiveHealthPolicyWeeklySummaryType = {
  id: string
  policyNumber: string
  startDate: Date
  endDate: Date
  summaryStep: number
  summaryActivityDuration: number
  summaryPoint: number
  summaryExercisePoint: number
  summaryBonusPoint: number
  discountAmount: number
  isFirstWeekBilling: boolean
  status: ActiveHealthWeeklySummaryStatusEnum
  billingWeekNo: number
  createdAt: Date
  updatedAt: Date
}

export interface BackofficeListActiveHealthByBillIdDataInterface {
  backofficeListActiveHealthByBillId: Array<ActiveHealthPolicyWeeklySummaryType>
}

export interface BackofficeListActiveHealthByBillIdInputInterface {
  billId: string
}
