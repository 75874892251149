import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeResetPpuMotorUserPolicyMotorDeviceIdDataInterface,
  BackofficeResetPpuMotorUserPolicyMotorDeviceIdInputInterface,
} from './interfaces'

const backofficeResetPpuMotorUserPolicyMotorDeviceId: TypedDocumentNode<
  BackofficeResetPpuMotorUserPolicyMotorDeviceIdDataInterface,
  BackofficeResetPpuMotorUserPolicyMotorDeviceIdInputInterface
> = gql`
  mutation BackofficeResetPpuMotorUserPolicyMotorDeviceId(
    $input: BackofficeResetPpuMotorUserPolicyMotorDeviceIdInput!
  ) {
    backofficeResetPpuMotorUserPolicyMotorDeviceId(input: $input) {
      id
      deviceId
      message
    }
  }
`

export default backofficeResetPpuMotorUserPolicyMotorDeviceId
