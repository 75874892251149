import { gql } from '@apollo/client'

export const backofficeUnboundPpuMotorUserPolicyMotor = gql`
  mutation BackofficeUnboundPpuMotorUserPolicyMotor(
    $input: BackofficeUnboundPpuMotorUserPolicyMotorInput!
  ) {
    backofficeUnboundPpuMotorUserPolicyMotor(input: $input) {
      id
      chassisNumber
      unboundAt
    }
  }
`

export default backofficeUnboundPpuMotorUserPolicyMotor
