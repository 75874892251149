import { Container, Title } from './styles'
import CampaignPeriod from '../CampaignPeriod'
import WellBeingPartnerTable from '@/components/feature/WellBeingPage/WellBeingPartnerTable'
import WellBeingPartnerStatus from '@/components/feature/WellBeingPage/WellBeingPartnerStatus'
import WellBeingPartnerInfo from '@/components/feature/WellBeingPage/WellBeingPartnerInfo'
import WellBeingBranchTable from '@/components/feature/WellBeingPage/WellBeingBranchTable'
import WellBeingPageHook from './WellBeingPage.hook'

function WellBeingPage() {
  const { handleIsPartnerActive, isPartnerActive, partnerId } = WellBeingPageHook()

  return (
    <Container>
      <Title>{partnerId ? 'ข้อมูลพาร์ทเนอร์และสาขา' : 'จัดการพาร์ทเนอร์ Well-Being'}</Title>
      {!partnerId ? <CampaignPeriod /> : undefined}
      {!partnerId ? <WellBeingPartnerTable /> : undefined}
      {partnerId ? <WellBeingPartnerInfo setIsPartnerActive={handleIsPartnerActive} /> : undefined}
      {partnerId ? <WellBeingPartnerStatus isPartnerActive={isPartnerActive} /> : undefined}
      {partnerId ? <WellBeingBranchTable /> : undefined}
    </Container>
  )
}

export default WellBeingPage
