import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import {
  DataCampaignType,
  ModeCampaignEnum,
  StatusCampaignEnum,
} from '@/components/feature/CampaignManagementPage/CampaignModal/types'
import { IMAGE_URL } from '@/constant/IMAGE'
import activeHealthSpecialPointCampaign from '@/gql/activeHealthSpecialPointCampaign'
import {
  ActiveHealthSpecialPointEnum,
  ActiveHealthSpecialPointInterface,
} from '@/gql/activeHealthSpecialPointCampaign/types'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import message from '@/libs/message'
import { ButtonIcon, Dot, Span, StatusWrapper } from './styles'
import { paginationType } from './types'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'

export const funcStatusCampaign = (minDate: Dayjs, maxDate: Dayjs) => {
  const currentDate = dayjs()
  const startDate = minDate.startOf('day')
  const endDate = dayjs(maxDate).add(1, 'day').startOf('day')

  let statusCampaign = StatusCampaignEnum.INACTIVE
  let color = '#276EF1'

  if (currentDate >= startDate && currentDate <= endDate) {
    statusCampaign = StatusCampaignEnum.ACTIVE
    color = '#009640'
  }

  if (currentDate > endDate) {
    statusCampaign = StatusCampaignEnum.COMPLETED
    color = '#C2C2C2'
  }

  return {
    statusCampaign,
    color,
  }
}

const CampaignManagementHook = () => {
  const { setConfirmModal } = ConfirmModalHook()
  const [
    dataBackofficeSearchActiveHealthSpecialPoint,
    setBackofficeSearchActiveHealthSpecialPoint,
  ] = useState<Array<ActiveHealthSpecialPointInterface>>([])

  const [modalCampaign, setModalCampaign] = useState<DataCampaignType>({
    mode: ModeCampaignEnum.CREATE,
    openModal: false,
    dataEditSpecialPoint: undefined,
  })

  const [pagination, setPagination] = useState<paginationType>({
    page: 1,
    limit: 10,
    totalItems: 0,
  })
  const user = useRecoilValue(userAtom)
  const canEditCampaign = validatePerm([BACKOFFICE_USER_PERMISSION.CAM010103], user.permissions)
  const canRemoveCampaign = validatePerm([BACKOFFICE_USER_PERMISSION.CAM010104], user.permissions)

  const [getBackofficeSearchActiveHealthSpecialPointQuery, { loading, refetch }] = useLazyQuery(
    activeHealthSpecialPointCampaign.query.backofficeSearchActiveHealthSpecialPoint,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setBackofficeSearchActiveHealthSpecialPoint(
          data.backofficeSearchActiveHealthSpecialPoint.data,
        )
      },
    },
  )

  const [
    postBackofficeDeleteActiveHealthSpecialPointMutation,
    { error: errorBackofficeDeleteActiveHealthSpecialPoint },
  ] = useMutation(
    activeHealthSpecialPointCampaign.mutation.backofficeDeleteActiveHealthSpecialPoint,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const columns: ColumnsType<{} | ActiveHealthSpecialPointInterface> = [
    {
      title: 'ลำดับ',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '5%',
      render(_value, _record, index) {
        return index + 1
      },
    },
    {
      title: 'ชื่อ Campaign',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: '30%',
    },
    {
      title: 'เงื่อนไข',
      key: 'value',
      align: 'center',
      width: '20%',
      render: (data) => {
        if (data.type === ActiveHealthSpecialPointEnum.MULTIPLIER) {
          return `x ${data.value} คะแนน`
        }

        if (data.type === ActiveHealthSpecialPointEnum.TARGET) {
          return `${data.value}  คะแนนได้ส่วนลด`
        }

        return ''
      },
    },
    {
      title: 'วันที่เริ่มต้น',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '20%',
      render(value) {
        return dayjs(value).format('DD MMM BBBB')
      },
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'endDate',
      key: 'endDate',
      width: '20%',
      render(value) {
        return dayjs(value).format('DD MMM BBBB')
      },
    },

    {
      title: 'สถานะ',
      key: 'statusCampaign',
      width: '10%',
      render: (data) => {
        const minDate = dayjs(data.startDate)
        const maxDate = dayjs(data.endDate)

        return (
          <StatusWrapper>
            <Dot background={funcStatusCampaign(minDate, maxDate).color} />
            <Span>{funcStatusCampaign(minDate, maxDate).statusCampaign}</Span>
          </StatusWrapper>
        )
      },
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: (data) => {
        const minDate = dayjs(data.startDate)
        const maxDate = dayjs(data.endDate)

        const status = funcStatusCampaign(minDate, maxDate).statusCampaign

        const dataSpecialPoint: ActiveHealthSpecialPointInterface = {
          id: data.id,
          title: data.title,
          descriptionTh: data.descriptionTh,
          descriptionEn: data.descriptionEn,
          imageUrl: data.imageUrl,
          startDate: data.startDate,
          endDate: data.endDate,
          type: data.type,
          value: data.value,
        }

        if (status === StatusCampaignEnum.ACTIVE) {
          return (
            <Space size="middle">
              {canEditCampaign ? (
                <ButtonIcon
                  src={IMAGE_URL.editIcon}
                  onClick={() => {
                    setModalCampaign({
                      mode: ModeCampaignEnum.UPDATE,
                      openModal: true,
                      dataEditSpecialPoint: dataSpecialPoint,
                    })
                  }}
                />
              ) : undefined}
            </Space>
          )
        }

        if (status === StatusCampaignEnum.INACTIVE) {
          return (
            <Space size="middle">
              {canEditCampaign ? (
                <ButtonIcon
                  src={IMAGE_URL.editIcon}
                  onClick={() => {
                    setModalCampaign({
                      mode: ModeCampaignEnum.UPDATE,
                      openModal: true,
                      dataEditSpecialPoint: dataSpecialPoint,
                    })
                  }}
                />
              ) : undefined}

              {canRemoveCampaign ? (
                <ButtonIcon
                  src={IMAGE_URL.tableTrashIcon}
                  onClick={() => {
                    setConfirmModal({
                      onOkFunction: () => {
                        postBackofficeDeleteActiveHealthSpecialPointMutation({
                          variables: {
                            id: data.id,
                          },
                        })
                          .finally(() => {
                            refetch()
                            setConfirmModal({
                              isVisible: false,
                              onOkFunction: Function,
                              dataTestId: undefined,
                            })
                          })
                          .catch(() => {
                            message.error({
                              content: `ปัญหา: ${errorBackofficeDeleteActiveHealthSpecialPoint?.message}`,
                              duration: 10,
                            })
                          })
                      },
                      isVisible: true,
                      title: 'คุณแน่ใจที่จะลบ Campaign',
                      bodyText: `ลบ “${data.title}”`,
                      okText: 'ลบ',
                      okButtonProps: { type: 'primary', danger: true },
                      dataTestId: 'delete-campaign-modal',
                    })
                  }}
                />
              ) : undefined}
            </Space>
          )
        }
      },
    },
  ]

  const handleCampaignModal = (bool: boolean) => {
    setModalCampaign({
      mode: ModeCampaignEnum.CREATE,
      openModal: bool,
      dataEditSpecialPoint: undefined,
    })
  }

  const handleOkCampaign = () => {
    refetch()
    setModalCampaign({
      mode: ModeCampaignEnum.CREATE,
      openModal: false,
      dataEditSpecialPoint: undefined,
    })
  }

  const getFetchSearchActiveHealthSpecialPoint = (page: number, limit: number) => {
    if (page && limit) {
      getBackofficeSearchActiveHealthSpecialPointQuery({
        variables: {
          input: {
            page,
            limit,
          },
        },
      }).then((data) => {
        if (data.data?.backofficeSearchActiveHealthSpecialPoint.meta) {
          const { itemsPerPage, totalItems, currentPage } =
            data.data?.backofficeSearchActiveHealthSpecialPoint.meta

          setPagination({
            page: currentPage,
            limit: itemsPerPage,
            totalItems,
          })
        }
      })
    }
  }

  const onHandlePageChange = (page: number, pageSize: number) => {
    return setPagination({
      page,
      limit: pageSize,
    })
  }

  useEffect(() => {
    if (pagination.limit && pagination.page) {
      getFetchSearchActiveHealthSpecialPoint(pagination.page, pagination.limit)
    }
  }, [pagination.limit, pagination.page])

  return {
    columns,
    handleCampaignModal,
    loading,
    pagination,
    onHandlePageChange,
    modalCampaign,
    handleOkCampaign,
    dataBackofficeSearchActiveHealthSpecialPoint,
    user,
  }
}

export default CampaignManagementHook
