import { css } from '@emotion/react'

export const typographyCSS = css`
  body {
    --raw-h1-size: 48px;
    --raw-h2-size: 40px;
    --raw-h3-size: 32px;
    --raw-h4-size: 24px;
    --raw-h5-size: 20px;
    --raw-h6-size: 18px;
    --raw-s1-size: 16px;
    --raw-s2-size: 14px;
    --raw-s3-size: 12px;
    --raw-p1-size: 18px;
    --raw-p2-size: 16px;
    --raw-p3-size: 14px;
    --raw-bt1-size: 18px;
    --raw-bt2-size: 16px;
    --raw-bt3-size: 14px;
    --raw-c1-size: 12px;
    --raw-c2-size: 10px;
    --raw-o1-size: 10px;
    font-family: 'Prompt', 'Sarabun';

    h1 {
      font-size: var(--raw-h1-size);
      font-weight: 400;
      line-height: 64px;
    }
    h2 {
      font-size: var(--raw-h2-size);
      font-weight: 400;
      line-height: 56px;
    }
    h3 {
      font-size: var(--raw-h3-size);
      font-weight: 400;
      line-height: 44px;
    }
    h4 {
      font-size: var(--raw-h4-size);
      font-weight: 400;
      line-height: 32px;
    }
    h5 {
      font-size: var(--raw-h5-size);
      font-weight: 400;
      line-height: 28px;
    }
    h6 {
      font-size: var(--raw-h6-size);
      font-weight: 400;
      line-height: 24px;
    }
  }
`
