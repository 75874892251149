import { Input, Label, SmsText, TextBox, Subtitle } from './styles'
import ChangeCreditCardModalHook from './ChangeCreditCardModal.hook'
import Modal from '@/components/common/Modal'

function ChangeCreditCardModal() {
  const { handleCloseChangeCreditCardModal, changeCreditCardModal } = ChangeCreditCardModalHook()

  return (
    <Modal
      title="เปลี่ยนข้อมูลบัตรเครดิต"
      cancelText="ยกเลิก"
      okText="ส่ง SMS"
      open={changeCreditCardModal.open}
      onCancel={handleCloseChangeCreditCardModal}
      width={400}
    >
      <Subtitle>
        โปรดแจ้งลูกค้าให้ทำการเช็ก SMS
        <br />
        ตามเบอร์ที่ได้ระบุไว้
      </Subtitle>
      <Label>เบอร์โทรศัพท์</Label>
      <Input placeholder="0812223333" />
      <Subtitle>ตัวอย่าง SMS ส่งจาก Thaivivat Insurance</Subtitle>
      <TextBox>
        <SmsText>
          คุณได้แจ้งดำเนินการเปลี่ยนบัตรเครดิต หรือบัตรเดบิต กรุณาทำการเปลี่ยนข้อมูลบัตรได้ที่
          www.changecreditinfo.co.th
        </SmsText>
      </TextBox>
    </Modal>
  )
}

export default ChangeCreditCardModal
