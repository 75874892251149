import { gql, TypedDocumentNode } from '@apollo/client'
import { CreateBackofficeUserDataInterface, CreateBackofficeUserInputInterface } from './interfaces'

export const createBackofficeUser: TypedDocumentNode<
  CreateBackofficeUserDataInterface,
  CreateBackofficeUserInputInterface
> = gql`
  mutation CreateBackofficeUser($input: CreateBackofficeUserInput!) {
    createBackofficeUser(input: $input) {
      id
      createdAt
      firstname
      lastname
      email
      staffId
      updatedAt
    }
  }
`

export default createBackofficeUser
