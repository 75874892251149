import { Modal, Select, Option, FormItem, FormContainer } from './styles'
import MoveCategoryModalHook from './MoveCategoryModal.hook'

function MoveCategoryModal() {
  const {
    moveCategoryModal: { isVisible, title, okText, category },
    onModalInvisible,
    handleDisabledSave,
    form,
    disabledSave,
    handleFormChange,
    onSubmit,
  } = MoveCategoryModalHook()

  return (
    <Modal
      title={title}
      open={isVisible}
      cancelText="ยกเลิก"
      okText={okText}
      onOk={onSubmit}
      centered
      onCancel={() => {
        handleDisabledSave()
        form.resetFields()
        onModalInvisible()
      }}
      okButtonProps={{ disabled: disabledSave, type: 'primary', danger: false }}
    >
      <FormContainer layout="vertical" form={form} onFieldsChange={handleFormChange}>
        <FormItem name="selectFaqCategoryId" label="หมวดหมู่คำถาม">
          <Select defaultValue={'เลือก'}>
            <Option value="เลือก">เลือก</Option>
            {category?.map((category) => (
              <Option key={category.id} value={category.value}>
                {category.text}
              </Option>
            ))}
          </Select>
        </FormItem>
      </FormContainer>
    </Modal>
  )
}

export default MoveCategoryModal
