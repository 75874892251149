import { atom } from 'recoil'

import { PolicyDetailProps } from './types'

const initPolicyDetail = {
  id: null,
  insuredName: null,
  identityId: null,
  mobileNumber: null,
  email: null,
  registeredDate: null,
  policies: [],
  deleteRequestedAt: null,
} as PolicyDetailProps

const policyDetailAtom = atom({
  key: 'policyDetailAtom',
  default: initPolicyDetail,
})

export default policyDetailAtom
