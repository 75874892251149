import styled from '@emotion/styled'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)<any>`
  .ant-modal-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    height: 50%;
  }

  .ant-btn-default {
    box-shadow: none;
    height: auto;
    padding: 10px 16px;
    border-radius: 8px;
  }

  .ant-modal-header {
    padding: 24px 24px 0px 24px;
    margin-bottom: 4px;
  }

  .ant-modal-body {
    padding: 0px 24px 24px 24px;
  }

  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-footer {
    padding: 16px 24px;
    margin-top: 0px;
    background-color: #fafafa;
    border-radius: 0px 0px 11px 11px;
  }
`

export const BodySpan = styled('span')`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`
