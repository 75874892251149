import TaTransactionOrderCardTravelerHook from './TaTransactionOrderCardTraveler.hook'
import { OrderStatusEnum } from '@/constant/TA_TRANSACTION'
import Table from '@/components/common/Table'
import { Container, Header } from './styles'
import TaTravelerModal from '../TaTravelerModal'

const TaTransactionOrderCardTraveler = ({
  orderId,
  orderStatus,
}: {
  orderId: string
  orderStatus: OrderStatusEnum
}) => {
  const { applicationFormColumns, applicationForm, closeModal, selectedApplicationForm } =
    TaTransactionOrderCardTravelerHook({
      orderId,
      orderStatus,
    })

  return (
    <Container>
      <Header>รายชื่อผู้เดินทาง</Header>
      <Table
        data-testid="ta-transaction-address-form-table"
        rowKey="id"
        loading={applicationForm.isFetching}
        columns={applicationFormColumns.filter((column) => column.width !== 0)}
        dataSource={applicationForm?.data}
        pagination={false}
        scroll={{
          x: '77vw',
        }}
      />
      {selectedApplicationForm && (
        <TaTravelerModal
          open={!!selectedApplicationForm}
          orderId={orderId}
          applicationForm={selectedApplicationForm}
          closeModal={closeModal}
        />
      )}
    </Container>
  )
}

export default TaTransactionOrderCardTraveler
