import { useQuery } from '@tanstack/react-query'
import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'
import { OrderResponse } from '../type'

const fetchOrderDetail = async (orderId: string) => {
  const response = await httpClientPrivate.get<OrderResponse>(
    `${config.restUrl}/v1/backoffice-commerce/order/${orderId}`,
  )

  return response.data
}

const useOrderDetail = (orderId: string) => {
  const query = useQuery({
    queryKey: ['useOrderDetail', orderId],
    queryFn: async () => fetchOrderDetail(orderId),
  })

  return { ...query }
}

export { useOrderDetail, fetchOrderDetail }
