import { useQueryClient } from '@tanstack/react-query'
import { Form } from 'antd'
import { useEffect, useState } from 'react'
import { OTHER_NATIONALITY_ID, THAI_NATIONALITY_ID } from '@/constant/TA'
import { useDistrict } from '@/services/address/useDistrict'
import { useProvince } from '@/services/address/useProvince'
import { useSubDistrict } from '@/services/address/useSubDistrict'
import { SubDistrictType } from '@/services/address/useSubDistrict/types'
import { ApplicationFormResponse } from '@/services/order/useOrderApplicationForm/types'
import { useUpdateApplicationForm } from '@/services/order/useUpdateApplicationForm'

function TaTravelerModalHook({
  orderId,
  applicationForm,
}: {
  orderId: string
  applicationForm: ApplicationFormResponse
}) {
  const queryClient = useQueryClient()
  const [form] = Form.useForm<ApplicationFormResponse>()
  const [address, setAddress] = useState<string>('')
  const [provinceCode, setProvinceCode] = useState<string>('')
  const [districtCode, setDistrictCode] = useState<string>('')
  const [subDistrictCode, setSubDistrictCode] = useState<string>('')
  const [postCode, setPostCode] = useState<string>('')
  const [isBeneficiary, setIsBeneficiary] = useState<boolean>(false)

  const refetchUseOrderApplicationForm = () => {
    queryClient.refetchQueries({ queryKey: ['useOrderApplicationForm'] })
  }

  const useUpdateTraveler = () => {
    const mutation = useUpdateApplicationForm(orderId, applicationForm.id, {
      address,
      subDistrictCode,
    })

    return mutation
  }

  const useAddress = (provinceCode: string, districtCode: string) => {
    const provinces = useProvince().data
    const districts = useDistrict(provinceCode).data
    const subDistricts = useSubDistrict(districtCode).data
    const provinceOptions = provinces?.map((province) => ({
      value: province.provinceCode,
      label: province.displayNameTh,
    }))
    const districtOptions = districts?.map((district) => ({
      value: district.districtCode,
      label: district.displayNameTh,
    }))
    const subDistrictOptions = subDistricts?.map((subDistrict) => ({
      value: subDistrict.subDistrictCode,
      label: subDistrict.displayNameTh,
    }))

    return {
      provinces,
      districts,
      subDistricts,
      provinceOptions,
      districtOptions,
      subDistrictOptions,
    }
  }

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value)
  }

  const handleProvinceChange = (value: unknown) => {
    if (value) {
      setProvinceCode(value as string)
      setDistrictCode('')
      setSubDistrictCode('')
      setPostCode('')
    }
  }

  const handleDistrictChange = (value: unknown) => {
    if (value) {
      setDistrictCode(value as string)
      setSubDistrictCode('')
      setPostCode('')
    }
  }

  const handleSubDistrictChange = (value: unknown, subDistricts: SubDistrictType[]) => {
    if (value) {
      const code = value as string
      const subDistrict = subDistricts.find(
        (subDistrict: SubDistrictType) => subDistrict.subDistrictCode === code,
      )

      if (subDistrict) {
        setSubDistrictCode(code)
        setPostCode(subDistrict.postCode)
      }
    }
  }

  useEffect(() => {
    if (applicationForm) {
      setIsBeneficiary(applicationForm.beneficiaryType === 'other')
      setAddress(applicationForm.address)
      setProvinceCode(applicationForm.province.provinceCode)
      setDistrictCode(applicationForm.district.districtCode)
      setSubDistrictCode(applicationForm.subDistrict.subDistrictCode)
      setPostCode(applicationForm.subDistrict.postCode)
      form.setFieldsValue({
        ...applicationForm,
        nationality: {
          id:
            applicationForm.nationality.id === THAI_NATIONALITY_ID
              ? THAI_NATIONALITY_ID
              : OTHER_NATIONALITY_ID,
        },
      })
    }
  }, [applicationForm])

  return {
    useUpdateTraveler,
    form,
    useAddress,
    address,
    districtCode,
    subDistrictCode,
    provinceCode,
    postCode,
    handleAddressChange,
    handleProvinceChange,
    handleDistrictChange,
    handleSubDistrictChange,
    isBeneficiary,
    refetchUseOrderApplicationForm,
  }
}

export default TaTravelerModalHook
