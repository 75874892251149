import { Container, Title } from './styles'
import ActiveHealthPolicyFilterTab from '@/components/feature/PolicyManagementPage/ActiveHealthPolicyFilterTab'
import ActiveHealthPolicyManagementTable from '@/components/feature/PolicyManagementPage/ActiveHealthPolicyManagementTable'
import ActiveHealthPolicyExporter from '@/components/feature/PolicyManagementPage/ActiveHealthPolicyExporter'
import PolicyManagementHook from './PolicyManagement.hook'
import { PolicyManagementContext } from '@/contexts'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import UnauthorizedPage from '@/pages/Unauthorized'

function PolicyManagement() {
  const { policyStatus, handleSetPolicyStatus, user } = PolicyManagementHook()

  return (
    <>
      {validatePerm([BACKOFFICE_USER_PERMISSION.POL010101], user.permissions) ? (
        <PolicyManagementContext.Provider
          value={{ policyStatus, setPolicyStatus: handleSetPolicyStatus }}
        >
          <Container>
            <Title>จัดการกรมธรรม์</Title>
            {validatePerm([BACKOFFICE_USER_PERMISSION.POL010102], user.permissions) ? (
              <ActiveHealthPolicyExporter />
            ) : undefined}

            <ActiveHealthPolicyFilterTab />
            <ActiveHealthPolicyManagementTable />
          </Container>
        </PolicyManagementContext.Provider>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default PolicyManagement
