import styled from '@emotion/styled'

import {
  Input as AntdInput,
  Upload as AntdUpload,
  Select as AntdSelect,
  Space as AntSpace,
} from 'antd'
import Button from '@/components/common/Button'

export const Container = styled('div')``

export const Block = styled('div')`
  margin-bottom: 16px;
`

export const InputTextArea = styled(AntdInput.TextArea)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  min-height: 104px !important;
`

export const Select = styled(AntdSelect)`
  width: 100%;

  .ant-select-selector {
    height: 40px !important;
    padding: 10px 16px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-item, .ant-select-selection-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
`

export const Upload = styled(AntdUpload)``

export const Title = styled('p')`
  margin-bottom: 4px;
`

export const ChooseFileButton = styled(Button)`
  border: 1px solid #009640;
  color: #009640;
`

export const Desc = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
`

export const Input = styled(AntdInput)`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
`

export const Space = styled(AntSpace)``
