import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'

export default function WelcomeCallHook() {
  const [isRenderImporter, setIsRenderImporter] = useState<boolean>(false)
  const user = useRecoilValue(userAtom)

  const openWelcomeCallImporter = () => setIsRenderImporter(true)

  const closeWelcomeCallImporter = () => setIsRenderImporter(false)

  return { openWelcomeCallImporter, closeWelcomeCallImporter, isRenderImporter, user }
}
