import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetPpuMotorAutoTopUpByPolicyNumberDataInterface,
  BackofficeGetPpuMotorAutoTopUpByPolicyNumberInputInterface,
} from './interfaces'

const backofficeGetPpuMotorAutoTopUpByPolicyNumber: TypedDocumentNode<
  BackofficeGetPpuMotorAutoTopUpByPolicyNumberDataInterface,
  BackofficeGetPpuMotorAutoTopUpByPolicyNumberInputInterface
> = gql`
  query BackofficeGetPpuMotorAutoTopUpByPolicyNumber($policyNumber: String!) {
    backofficeGetPpuMotorAutoTopUpByPolicyNumber(policyNumber: $policyNumber) {
      policyNumber
      omiseCardId
    }
  }
`

export default backofficeGetPpuMotorAutoTopUpByPolicyNumber
