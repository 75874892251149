import { ReactElement, useMemo } from 'react'
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import { InteractionRequiredAuthError, PopupRequest } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'

import { httpClientPrivate } from '@/libs/httpClient'
import { msalInstance } from '@/providers/msal'

interface ProviderInterface {
  children: ReactElement
}

export const AxiosProvider = ({ children }: ProviderInterface) => {
  const { instance } = useMsal()
  const account = msalInstance.getActiveAccount()

  useMemo(() => {
    const acquireToken = async (loginRequest: PopupRequest) => {
      try {
        const tokenSilent = await instance.acquireTokenSilent(loginRequest)
        const idToken = tokenSilent.idToken
        localStorage.setItem('accessToken', idToken)
        return idToken
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          const response = await msalInstance.acquireTokenPopup(loginRequest)
          const idToken = response.idToken
          localStorage.setItem('accessToken', idToken)
          return idToken
        }
        throw error
      }
    }

    httpClientPrivate.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const errorStatus = error.response?.status
        const originalRequest = error.config

        if (errorStatus === 401 && account) {
          const loginRequest = {
            scopes: ['openid', 'User.Read'],
            account,
          }

          try {
            const idToken = await acquireToken(loginRequest)
            if (originalRequest?.headers) {
              originalRequest.headers.Authorization = `Bearer ${idToken}`
              return axios(originalRequest)
            }
          } catch (authError) {
            return Promise.reject(authError)
          }
        }

        return Promise.reject(error)
      },
    )

    httpClientPrivate.interceptors.request.use(async (config: InternalAxiosRequestConfig<any>) => {
      if (config.headers && !config.headers.Authorization && account) {
        let token = localStorage.getItem('accessToken')

        if (!token) {
          const loginRequest = {
            scopes: ['openid', 'User.Read'],
            account,
          }

          try {
            token = await acquireToken(loginRequest)
          } catch (authError) {
            return Promise.reject(authError)
          }
        }

        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    })

    return () => {
      httpClientPrivate.interceptors.response.clear()
      httpClientPrivate.interceptors.request.clear()
    }
  }, [account, instance])

  return children
}
