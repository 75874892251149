import styled from '@emotion/styled'
import {
  Form as AntForm,
  Select as AntSelect,
  DatePicker as AntDatePicker,
  Radio as AntRadio,
  Space as AntSpace,
  Button as AntButton,
} from 'antd'

export const Container = styled('div')`
  width: 100%;
  padding: 24px;
  // margin: auto;
  background: #ffffff;
  display: flex;
  // justify-content: center;
  // align-items: center;

  .ant-select-selector,
  .ant-picker {
    border-radius: 4px !important;
  }
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  margin-bottom: 24px;
`

export const Form = styled(AntForm)``

export const FormItem = styled(AntForm.Item)`
  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
`

export const Select = styled(AntSelect)`
  width: 100%;
`

export const Option = styled(AntSelect.Option)``

export const RangePicker = styled(AntDatePicker.RangePicker)``

export const Radio = styled(AntRadio)``

export const RadioGroup = styled(AntRadio.Group)``

export const Space = styled(AntSpace)``

export const Button = styled(AntButton)`
  border-radius: 4px;
`
