import styled from '@emotion/styled'
import { Button as AntdButton } from 'antd'

export const Container = styled('div')<{ display: boolean }>`
  display: ${(props) => (props.display ? 'block' : 'none')};
`

export const Button = styled(AntdButton)`
  border-radius: 100px;
  height: 32px;
  padding: 0 15px;
  margin-bottom: 8px;
`

export const FilterContainer = styled('div')`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  width: 400px;
  padding: 24px;
`

export const FilterBlock = styled('div')`
  margin-bottom: 16px;
  min-height: 100px;
`

export const FilterTitle = styled('div')`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 16px;
`

export const FilterButton = styled(AntdButton)`
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  margin-bottom: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`

export const FilterFooter = styled('div')`
  border-top: 1px solid #cccccc;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -24px;
  margin-right: -24px;
`

export const ConfirmButton = styled(AntdButton)``

export const ResetButton = styled('div')<{ active: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.active ? '#009640' : '#adadad')};
`
