export const BACKOFFICE_USER_PERMISSION = {
  CUS010101: 'CUS010101',
  CUS010102: 'CUS010102',
  CUS010103: 'CUS010103',
  MTP010101: 'MTP010101',
  MTP010102: 'MTP010102',
  MTP010103: 'MTP010103',
  MTP010104: 'MTP010104',
  MTD010101: 'MTD010101',
  MTT010101: 'MTT010101',
  WEL010101: 'WEL010101',
  WEL010102: 'WEL010102',
  WEL010203: 'WEL010203',
  WEL010204: 'WEL010204',
  POL010101: 'POL010101',
  POL010102: 'POL010102',
  HLT010101: 'HLT010101',
  HLT010102: 'HLT010102',
  HLT010104: 'HLT010104',
  HLT010105: 'HLT010105',
  HLT010106: 'HLT010106',
  HLT010108: 'HLT010108',
  HLT010107: 'HLT010107',
  HLT010109: 'HLT010109',
  HLT010110: 'HLT010110',
  DIS010101: 'DIS010101',
  DIS010102: 'DIS010102',
  DIS010103: 'DIS010103',
  WAT010101: 'WAT010101',
  CAM010101: 'CAM010101',
  CAM010102: 'CAM010102',
  CAM010103: 'CAM010103',
  CAM010104: 'CAM010104',
  HEL010101: 'HEL010101',
  HEL010102: 'HEL010102',
  FAQ010101: 'FAQ010101',
  FAQ010102: 'FAQ010102',
  FAQ010103: 'FAQ010103',
  FAQ010104: 'FAQ010104',
  FAQ010105: 'FAQ010105',
  FAQ010106: 'FAQ010106',
  USR010101: 'USR010101',
  USR010102: 'USR010102',
  WBE010101: 'WBE010101',
  WBE010102: 'WBE010102',
  WBE010103: 'WBE010103',
  WBE010104: 'WBE010104',
  WBE010105: 'WBE010105',
  WBE010106: 'WBE010106',
  WBE010107: 'WBE010107',
  TA010101: 'TA010101',
  TA010102: 'TA010102',
  TA010103: 'TA010103',
  TA010104: 'TA010104',
  TA010201: 'TA010201',
  TA010202: 'TA010202',
  TA010203: 'TA010203',
  TA010204: 'TA010204',
  TA010205: 'TA010205',
}
export const DEFAULT_BACKOFFICE_USER_PERMISSIONS = Object.values(BACKOFFICE_USER_PERMISSION)

export const BACKOFFICE_USER_MENU = {
  CUS01: 'CUS01',
  MTP01: 'MTP01',
  WEL01: 'WEL01',
  POL01: 'POL01',
  DIS01: 'DIS01',
  WAT01: 'WAT01',
  CAM01: 'CAM01',
  HEL01: 'HEL01',
  FAQ01: 'FAQ01',
  USR01: 'USR01',
  MTD01: 'MTD01',
  MTT01: 'MTT01',
  WBE01: 'WBE01',
  TA01: 'TA01',
}

export const DEFAULT_BACKOFFICE_USER_MENUS = Object.values(BACKOFFICE_USER_MENU)

export const BACKOFFICE_USER_PAGE = {
  MTP0101: 'MTP0101',
  MTD0101: 'MTD0101',
  MTT0101: 'MTT0101',
  WEL0101: 'WEL0101',
  POL0101: 'POL0101',
  HLT0101: 'HLT0101',
  DIS0101: 'DIS0101',
  WAT0101: 'WAT0101',
  CAM0101: 'CAM0101',
  HEL0101: 'HEL0101',
  FAQ0101: 'FAQ0101',
  USR0101: 'USR0101',
  CUS0101: 'CUS0101',
  WBE0101: 'WBE0101',
  TA0101: 'TA0101',
  TA0102: 'TA0102',
}

export const DEFAULT_BACKOFFICE_USER_PAGES = Object.values(BACKOFFICE_USER_PAGE)

export const BACKOFFICE_USER_MENU_TYPE = {
  ['ข้อมูลลูกค้าทั่วไป']: 'ข้อมูลลูกค้าทั่วไป',
  ['ประกันรถเปิดปิด']: 'ประกันรถเปิดปิด',
  ['ประกันสุขภาพ']: 'ประกันสุขภาพ',
  ['การจัดการระบบ']: 'การจัดการระบบ',
  ['ประกันเดินทาง']: 'ประกันเดินทาง',
}

export const DEFAULT_BACKOFFICE_USER_MENU_TYPES = Object.values(BACKOFFICE_USER_MENU_TYPE)
