import { IMAGE_URL } from '@/constant/IMAGE'
import { Button, CampaignCard, Container, HeaderCampaign, Title, Topic, IconSvg } from './styles'
import CampaignManagementHook from './CampaignManagement.hook'
import CampaignModal from '@/components/feature/CampaignManagementPage/CampaignModal/CampaignModal'
import { ModeCampaignEnum } from '@/components/feature/CampaignManagementPage/CampaignModal/types'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import UnauthorizedPage from '@/pages/Unauthorized'
import Table from '@/components/common/Table'

function CampaignManagement() {
  const {
    columns,
    handleCampaignModal,
    loading,
    pagination,
    onHandlePageChange,
    modalCampaign,
    handleOkCampaign,
    dataBackofficeSearchActiveHealthSpecialPoint,
    user,
  } = CampaignManagementHook()

  return (
    <>
      {validatePerm([BACKOFFICE_USER_PERMISSION.CAM010101], user.permissions) ? (
        <Container>
          <HeaderCampaign>
            <Title>จัดการ Campaign</Title>
            {validatePerm([BACKOFFICE_USER_PERMISSION.CAM010102], user.permissions) ? (
              <Button
                type="primary"
                icon={<IconSvg src={IMAGE_URL.plusIcon} />}
                onClick={() => handleCampaignModal(true)}
                data-testid="campaign-management-add-campaign-button"
              >
                เพิ่ม campaign
              </Button>
            ) : undefined}
          </HeaderCampaign>
          <CampaignCard>
            <Topic>รายการ Campaign</Topic>
            <Table
              data-testid="campaign-management-table"
              loading={loading}
              columns={columns}
              dataSource={dataBackofficeSearchActiveHealthSpecialPoint}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                showSizeChanger: true,
                total: pagination?.totalItems,
                current: pagination.page,
                defaultCurrent: 1,
                pageSize: pagination.limit,
                onChange: (page, pageSize) => onHandlePageChange(page, pageSize),
              }}
              totalRecord={pagination?.totalItems}
            />
          </CampaignCard>
          <CampaignModal
            isModalOpen={modalCampaign.openModal}
            title={
              modalCampaign.mode === ModeCampaignEnum.CREATE ? 'เพิ่ม Campaign' : 'แก้ไข Campaign'
            }
            handleOk={() => handleOkCampaign()}
            handleCancel={() => handleCampaignModal(false)}
            dataCampaign={modalCampaign}
          />
        </Container>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default CampaignManagement
