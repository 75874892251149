import { useRecoilState } from 'recoil'
import additionalCoverageAtom from '@/recoil/additionalCoverageModal'

function AdditionalCoverageModal() {
  const [additionalCoverageModal, setAdditionalCoverageModal] =
    useRecoilState(additionalCoverageAtom)

  const handleCloseModal = () =>
    setAdditionalCoverageModal({
      open: false,
      additionalCoverages: null,
    })

  return { additionalCoverageModal, handleCloseModal }
}

export default AdditionalCoverageModal
