import styled from '@emotion/styled'
import { Input as AntdInput, Skeleton } from 'antd'
import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons'
import { css } from '@emotion/css'
import Button from '@/components/common/Button'

export const Container = styled('div')`
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 32px;
  margin: auto;
  width: 100%;
  position: relative;
  user-select: none;
`

export const Header = styled('div')`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeaderBlock = styled('div')`
  position: relative;
`

export const Title = styled('span')`
  font-size: 20px;
`

export const Block = styled('div')`
  position: relative;
  width: 100%;
  padding: 0 52px;
  height: 44px;
`

export const FilterButtonBlock = styled('div')`
  white-space: nowrap;
  overflow-x: scroll;
`

export const DateFilterButton = styled('div')<{ select: boolean }>`
  background: #ffffff;
  cursor: pointer;
  border: 1px solid ${(props) => (props.select ? '#009640' : '#e5e5e5')};
  border-radius: 8px;
  padding: 8px;
  margin-right: 8px;
  width: 173px;
  display: inline-block;
  user-select: none;
`

export const DateFilterButtonBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

export const CountBlock = styled('div')`
  background: #f6f6f6;
  border-radius: 4px;
  padding: 2px 8px;
`

export const Success = styled('span')`
  color: #009640;
`

export const Total = styled('span')`
  color: #adadad;
`

export const LeftArrowButton = styled(LeftOutlined)<{ disabled: boolean }>`
  background: ${(props) => (props.disabled ? '#f6f6f6' : '#ffffff')};
  cursor: pointer;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RightArrowButton = styled(RightOutlined)<{ disabled: boolean }>`
  background: ${(props) => (props.disabled ? '#f6f6f6' : '#ffffff')};
  cursor: pointer;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  font-size: 16px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CallTypeFilterButtonBlock = styled('div')`
  margin: 24px 0;
`

export const AllTypeFilterButton = styled('button')<{ select: boolean }>`
  padding: 10px 16px;
  width: 96px;
  height: 40px;
  background: ${(props) => (props.select ? '#f6f6f6' : '#ffffff')};
  border: 1px solid #e5e5e5;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
`

export const NotCallFilterButton = styled('button')<{ select: boolean }>`
  padding: 10px 0;
  width: 106px;
  height: 40px;
  background: ${(props) => (props.select ? '#f6f6f6' : '#ffffff')};
  border-width: 1px;
  border-style: solid;
  border-color: #e5e5e5;
  cursor: pointer;
`

export const CalledFilterButton = styled('button')<{ select: boolean }>`
  padding: 10px 16px;
  width: 116px;
  height: 40px;
  background: ${(props) => (props.select ? '#f6f6f6' : '#ffffff')};
  border: 1px solid #e5e5e5;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
`

export const LastSyncText = styled('span')`
  font-size: 14px;
  color: #666666;
  position: absolute;
  left: -248px;
  top: 14px;
`

export const SearchInput = styled(AntdInput)`
  width: 260px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: -58px;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`

export const ImportButton = styled(Button)`
  background-color: #009400;
  color: #ffffff !important;
  border: 0;
  font-size: 16px;
`

export const ImportIcon = styled('img')`
  margin-right: 6px;
`

export const EyeIcon = styled('img')`
  cursor: pointer;
  font-size: 20px;
  color: #666666;
`

export const SelectedRow = css`
  background-color: #e6f6ea;
`

export const SkeletonInput = styled(Skeleton.Input)`
  margin-right: 8px;
`
