import backofficeTeam from '@/gql/backofficeTeam'
import userModalAtom from '@/recoil/userModal/atom'
import { ModeUserEnum } from '@/recoil/userModal/types'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import { Form } from 'antd'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import message from '@/libs/message'
import { TeamOptionsType, UserForm } from './types'
import {
  BackofficeListBackofficeRoleDataInterface,
  BackofficeListBackofficeRoleType,
} from '@/gql/backofficeTeam/backofficeListBackofficeRole/types'
import selectedTeamPermissionAtom from '@/recoil/selectedTeamPermission/atom'
import userAtom from '@/recoil/user/atom'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'

const ModalAddUserHook = () => {
  const [form] = Form.useForm<UserForm>()
  const user = useRecoilValue(userAtom)
  const selectedTeam = useRecoilValue(selectedTeamPermissionAtom)
  const [userModal, setUserModal] = useRecoilState(userModalAtom)
  const [teamOptions, setTeamOptions] = useState<Array<TeamOptionsType>>()
  const [roleList, setRoleList] = useState<Array<BackofficeListBackofficeRoleType>>()
  const [disabledSave, setDisabledSave] = useState<boolean>(true)
  const { isVisible, onOkFunction, title, mode, dataUser, okButtonProps } = userModal
  const [currentUser, setCurrentUser] = useState<{
    id: string
    currentRoleId: string
    currentTeamId: string
  }>()
  const isPowerUser = validatePerm([BACKOFFICE_USER_PERMISSION.USR010101], user.permissions)
  const userTeams = user.backofficeTeams || []

  const [getAllBackofficeTeamQuery, { loading: loadGetAllBackofficeTeamQuery }] = useLazyQuery(
    backofficeTeam.query.backofficeGetAllBackofficeTeam,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.backofficeGetAllBackofficeTeam.length > 0) {
          const team = data.backofficeGetAllBackofficeTeam.map((dup) => {
            return {
              value: dup.id,
              label: dup.name,
            }
          })
          setTeamOptions(team)
        }
      },
    },
  )

  const [getBackofficeListBackofficeRoleQuery] = useLazyQuery(
    backofficeTeam.query.backofficeListBackofficeRole,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: BackofficeListBackofficeRoleDataInterface) => {
        if (data.backofficeListBackofficeRole.length > 0) {
          const role = data.backofficeListBackofficeRole

          if (mode === ModeUserEnum.CREATE) {
            const roleList = role.filter((data) => data.isDefault)
            setRoleList(roleList)

            form.setFieldsValue({
              roleId: roleList[0].id,
              role: roleList[0].title,
            })
          } else {
            setRoleList(role)
          }
        }
      },
    },
  )

  const [createBackofficeUser] = useMutation(backofficeTeam.mutation.createBackofficeUser, {
    onError: (apolloError: ApolloError) => {
      const messageError: { [key: string]: string } = {
        BIZBOUSR1001: 'duplicate email',
        BIZBOUSR1002: 'duplicate mobile number',
        BIZBOUSR1004: 'backoffice user does not exist',
      }

      const error = messageError[apolloError.message]

      message.error({
        content: error,
        duration: 10,
      })
    },
  })

  const [updateBackofficeUser] = useMutation(backofficeTeam.mutation.updateBackofficeUser, {
    onCompleted() {
      message.success('แก้ไขข้อมูลสำเร็จ')
    },
    onError(apolloError: ApolloError) {
      const messageError: { [key: string]: string } = {
        BIZBOUSR1001: 'duplicate email',
        BIZBOUSR1002: 'duplicate mobile number',
        BIZBOUSR1004: 'backoffice user does not exist',
      }

      const error = messageError[apolloError.message]

      message.error({
        content: error,
        duration: 10,
      })
    },
  })

  const onCancelFunction = () => {
    form.resetFields()
    setDisabledSave(true)
    setUserModal({
      isVisible: false,
      onOkFunction: Function,
      mode: ModeUserEnum.CREATE,
      okButtonProps: { type: 'primary', danger: false },
    })
  }

  const handleOnSubmit = () => {
    form.validateFields().then(async (values) => {
      // Retrieve the validated values
      const { email, confirmEmail, name, lastName, phone, roleId, teamId } = values

      if (
        mode === ModeUserEnum.UPDATE &&
        currentUser &&
        currentUser.id &&
        currentUser.currentTeamId
      ) {
        await updateBackofficeUser({
          variables: {
            input: {
              id: currentUser.id,
              email,
              firstname: name,
              lastname: lastName,
              currentTeamId: currentUser.currentTeamId,
              newTeamId: teamId,
              newRoleId: roleId,
              mobileNumber: phone,
            },
          },
        })
        form.resetFields()
        setDisabledSave(true)
        onOkFunction(values)
      }

      if (mode === ModeUserEnum.CREATE) {
        if (email !== confirmEmail) {
          form.setFields([
            {
              name: 'confirmEmail',
              value: confirmEmail,
              errors: ['อีเมลไม่ตรงกัน กรุณาตรวจสอบอีกครั้ง'],
            },
          ])
          message.error('อีเมลไม่ตรงกัน กรุณาตรวจสอบอีกครั้ง')
          return
        }

        const dataUser = {
          email: `${email}@thaivivat.co.th`,
          firstname: name,
          lastname: lastName,
          mobileNumber: phone,
          roleId: roleId,
          teamId: teamId,
        }

        await createBackofficeUser({
          variables: {
            input: {
              ...dataUser,
            },
          },
          refetchQueries: [
            {
              query: backofficeTeam.query.backofficeGetBackofficeUserTeamRolePaginated,
              variables: {
                input: {
                  page: 1,
                  limit: 10,
                  filter: {
                    'backofficeTeam.id': `$eq:${selectedTeam.id}`,
                  },
                },
              },
            },
            'backofficeGetBackofficeUserTeamRolePaginated',
          ],
          onCompleted() {
            message.success(`เพิ่มผู้ใช้งาน email ${values.email}@thaivivat.co.th สำเร็จ`, 10)
          },
        })
        form.resetFields()
        setDisabledSave(true)
        onOkFunction(values)
      }
    })
  }

  const handleFormChange = () => {
    const { email, confirmEmail, name, lastName, phone, teamId } = form.getFieldsValue()
    let hasErrors = false

    if (mode === ModeUserEnum.UPDATE) {
      hasErrors = !name?.trim() || !lastName?.trim() || !phone?.trim()
    } else {
      hasErrors =
        !email?.trim() ||
        !confirmEmail?.trim() ||
        !name?.trim() ||
        !lastName?.trim() ||
        !phone?.trim() ||
        !teamId?.trim()
    }

    setDisabledSave(hasErrors)
  }

  const isDisabledTeamOptions =
    (mode === ModeUserEnum.UPDATE && !isPowerUser) || (userTeams.length === 1 && !isPowerUser)

  useEffect(() => {
    if (mode === ModeUserEnum.UPDATE && dataUser) {
      form.setFieldsValue({
        roleId: dataUser.roleId,
        role: dataUser.roleName,
        teamId: dataUser.teamId,
        team: dataUser.teamName,
        name: dataUser.name,
        lastName: dataUser.lastName,
        email: dataUser.email,
        phone: dataUser.phone,
      })
      setCurrentUser({
        id: dataUser.userId,
        currentRoleId: dataUser.roleId,
        currentTeamId: dataUser.teamId,
      })
    }
  }, [dataUser, form, mode])

  useEffect(() => {
    if (isVisible) {
      getBackofficeListBackofficeRoleQuery()

      if (isPowerUser) {
        // for power user
        getAllBackofficeTeamQuery()
      } else {
        // for head role user
        if (userTeams && userTeams.length > 0) {
          const userTeam = userTeams.map((t) => ({ value: t.id, label: t.name }))

          setTeamOptions(userTeam)

          // for head role only one team
          if (userTeam.length === 1) {
            form.setFieldValue('teamId', userTeam[0].value)
          }
        }
      }
    }
  }, [getAllBackofficeTeamQuery, getBackofficeListBackofficeRoleQuery, isVisible])

  return {
    userModal,
    form,
    isVisible,
    onOkFunction,
    onCancelFunction,
    setUserModal,
    title,
    teamOptions,
    loadGetAllBackofficeTeamQuery,
    mode,
    roleList,
    handleOnSubmit,
    disabledSave,
    handleFormChange,
    okButtonProps,
    isDisabledTeamOptions,
  }
}

export default ModalAddUserHook
