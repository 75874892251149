import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Checkbox, Input, Skeleton as AntdSkeleton } from 'antd'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')`
  display: grid;
  grid-template-columns: minmax(300px, 360px) 1fr;
  width: 100%;
  background-color: #ffff;
`

export const HeaderBlock = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 32px;
  border-right: 0.5px solid #e5e5e5;
`

export const Title = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
`

export const BodyBlock = styled('div')`
  display: grid;
  grid-template-rows: auto 1fr;
  caret-color: #009740;
  grid-gap: 16px;
`

export const SearchInput = styled(Input)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
`

export const Topic = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`

export const TopicWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const TitleWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 12px;
  padding: 40px 40px 0px 40px;
`

export const ButtonDefault = styled(CommonButton)`
  line-height: 20px;
  padding: 10px 16px;
`

export const IconSvg = styled('img')`
  margin-right: 10px;
  height: 15px;
  width: 15px;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`

export const PlusButton = styled(PlusOutlined)`
  cursor: pointer;
  color: #666666;
`

export const CreateCategoryInput = styled(Input)`
  height: 36px;
  border-radius: 8px;

  :focus {
    border: 1px solid #009640;
    box-shadow: 0px 0px 0px 2px #c3e9cc;
  }
`

export const CategoryWrapper = styled('div')`
  display: grid;
`

export const AllCheckbox = styled(Checkbox)`
  padding: 12px;
`

export const CategoryListWrapper = styled('div')`
  display: grid;
  grid-gap: 4px;
`

export const SkeletonContainer = styled('div')`
  padding: 16px 36px;
  display: grid;
`

export const Skeleton = styled(AntdSkeleton)`
  margin: 4px 0;
`

export const SkeletonButton = styled(AntdSkeleton.Button)`
  width: 165px !important;
`
