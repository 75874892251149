import { Drawer as AntdDrawer } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

import styled from '@emotion/styled'

export const Drawer = styled(AntdDrawer)<{ childrenVisible?: boolean }>`
  .ant-drawer-header-title {
    display: ${(props) => (props.childrenVisible ? 'flex' : 'block')};
    position: relative;

    .ant-drawer-close {
      position: ${(props) => (props.childrenVisible ? 'unset' : 'absolute')};
      right: 0;
      margin-right: 0;
    }
  }
`

export const CloseIcon = styled('img')`
  width: 15px;
`

export const BackIcon = styled(LeftOutlined)`
  color: #000000;
`
