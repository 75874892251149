import { IMAGE_URL } from '@/constant/IMAGE'
import { ImageIcon, ListWrapper, Span } from './styles'
import { ListButtonProps } from './types'

const ListButton = ({ text, onDeleteClose }: ListButtonProps) => {
  return (
    <ListWrapper>
      <Span>{text}</Span>
      <ImageIcon src={IMAGE_URL.closeIcon} onClick={onDeleteClose} />
    </ListWrapper>
  )
}

export default ListButton
