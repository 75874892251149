import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthHeartrateTargetByDateDataInterface,
  BackofficeGetActiveHealthHeartrateTargetByDateInputInterface,
} from './interfaces'

const backOfficeGetActiveHealthHeartrateTargetByDate: TypedDocumentNode<
  BackofficeGetActiveHealthHeartrateTargetByDateDataInterface,
  BackofficeGetActiveHealthHeartrateTargetByDateInputInterface
> = gql`
  query BackOfficeGetActiveHealthHeartrateTargetByDate(
    $userId: ID!
    $input: GetTargetHeartRateByDate!
  ) {
    backOfficeGetActiveHealthHeartrateTargetByDate(userId: $userId, input: $input) {
      cardio
    }
  }
`

export default backOfficeGetActiveHealthHeartrateTargetByDate
