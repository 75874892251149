import styled from '@emotion/styled'
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons'
import { ResultImportProps } from './types'

export const Container = styled('div')<ResultImportProps>`
  background: ${(props) => (props?.result === 'failed' ? '#fff8e0' : '#e6f6ea')};
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 20px;
`

export const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`

export const Title = styled('div')`
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`

export const Subtitle = styled('div')`
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-left: 20px;
`

export const SuccessIcon = styled(CheckCircleFilled)`
  margin-right: 5px;
  color: #009640;
`

export const FailIcon = styled(WarningFilled)`
  margin-right: 5px;
  color: #ffa100;
`
