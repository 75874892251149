import MobileDeviceTableHook from './MobileDeviceTable.hook'
import { TableStyles } from './styles'
import { MobileDeviceTableProps } from './types'
import Table from '@/components/common/Table'

export default function MobileDeviceTable({ dataSource, isLoading }: MobileDeviceTableProps) {
  const { columns } = MobileDeviceTableHook()

  return (
    <Table
      loading={isLoading}
      className={TableStyles}
      columns={columns}
      pagination={false}
      dataSource={dataSource}
      data-testid="policy-detail-device-tab-device-table"
    />
  )
}
