import { Form, Input } from 'antd'
import TeamModalHook from './TeamModal.hook'
import { FormItem } from './styles'
import Modal from '@/components/common/Modal'

const TeamModal = () => {
  const {
    onSubmit,
    disabledSave,
    handleFormChange,
    form,
    onCancel,
    teamModal: { title, isVisible, okButtonProps },
  } = TeamModalHook()

  return (
    <Modal
      title={title}
      open={isVisible}
      closable={false}
      cancelText="ยกเลิก"
      okText="บันทึก"
      onOk={onSubmit}
      onCancel={onCancel}
      okButtonProps={{ ...okButtonProps, disabled: disabledSave }}
      data-testid="team-modal"
    >
      <Form
        layout="vertical"
        form={form}
        onFieldsChange={handleFormChange}
        data-testid="team-modal-team-form"
      >
        <FormItem label="ชื่อทีม" name="name">
          <Input placeholder="ระบุชื่อทีม" data-testid="team-modal-team-name-input" />
        </FormItem>
      </Form>
    </Modal>
  )
}

export default TeamModal
