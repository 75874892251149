function UserDropdownMenuHook() {
  const handleLogout = () => {
    // TODO: improve this
    localStorage.clear()
    window.location.reload()
  }

  return {
    handleLogout,
  }
}

export default UserDropdownMenuHook
