import { SortByEnum } from '@/constant/SORT_BY'

export enum UserHelpSortableFieldEnum {
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  RECEIVED_AT = 'receivedAt',
  SUCCESSFUL_AT = 'successfulAt',
}

export enum SorterEnum {
  ASC = 'ascend',
  DESC = 'descend',
}

export const SORTER = {
  [SorterEnum.ASC]: SortByEnum.ASC,
  [SorterEnum.DESC]: SortByEnum.DESC,
}

export enum UserHelpSearchableFieldEnum {
  FIRSTNAME = 'user.firstname',
  LASTNAME = 'user.lastname',
  PHONE = 'phone',
  STATUS = 'status',
}

export enum UserHelpTypeEnum {
  EXERCISE_INFO = 'EXERCISE_INFO',
  BILLING_INFO = 'BILLING_INFO',
}

export enum UserHelpStatusEnum {
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESSFUL = 'SUCCESSFUL',
}

export const USER_HELP_TYPE = {
  [UserHelpTypeEnum.EXERCISE_INFO]: 'ข้อมูลออกกำลังกาย',
  [UserHelpTypeEnum.BILLING_INFO]: 'ค่าเบี้ย',
}

export const USER_HELP_STATUS = {
  [UserHelpStatusEnum.WAITING]: 'รอติดต่อกลับ',
  [UserHelpStatusEnum.IN_PROGRESS]: 'อยู่ระหว่างตรวจสอบ',
  [UserHelpStatusEnum.SUCCESSFUL]: 'แก้ไขสำเร็จ',
}
