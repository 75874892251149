import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthPartnerPaginationDataInterface,
  BackofficeGetActiveHealthPartnerPaginationInputInterface,
} from './interfaces'

const backofficeGetActiveHealthPartnerPagination: TypedDocumentNode<
  BackofficeGetActiveHealthPartnerPaginationDataInterface,
  BackofficeGetActiveHealthPartnerPaginationInputInterface
> = gql`
  query BackofficeGetActiveHealthPartnerPagination($input: RequestPaginationInput!) {
    backofficeGetActiveHealthPartnerPagination(input: $input) {
      data {
        id
        displayNameTh
        displayNameEn
        isActive
        logoImageUrl
        activeHealthPartnerCategory {
          displayNameEn
          displayNameTh
        }
        activeHealthPartnerBranch {
          id
          displayNameTh
          displayNameEn
        }
        createdByText
        updatedAt
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
        sortBy
        searchBy
        search
      }
    }
  }
`

export default backofficeGetActiveHealthPartnerPagination
