import { atom } from 'recoil'
import { UserProps } from './types'

const initUser: UserProps = {
  id: null,
  email: null,
  firstname: null,
  lastname: null,
  staffId: null,
  isAuth: false,
  accessToken: null,
  refreshToken: null,
  permissions: [],
  pages: [],
  menus: [],
  menuTypes: [],
  backofficeTeams: [],
}

const userAtom = atom({
  key: 'userAtom',
  default: initUser,
})

export default userAtom
