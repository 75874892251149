import {
  ActiveHealthSpecialPointEnum,
  ActiveHealthSpecialPointInterface,
} from '@/gql/activeHealthSpecialPointCampaign/types'
import dayjs from 'dayjs'

export enum ModeCampaignEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum StatusCampaignEnum {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

export type DataCampaignType = {
  mode: ModeCampaignEnum
  openModal: boolean
  dataEditSpecialPoint?: ActiveHealthSpecialPointInterface
}

export type CampaignModalProps = {
  title: string
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
  dataCampaign: DataCampaignType
}

export type SpecialPointCampaignFormType = {
  title: string
  descriptionTh: string
  descriptionEn: string
  imageUrl?: string
  defaultImage?: string
  dateRang: [dayjs.ConfigType, dayjs.ConfigType]
  type: ActiveHealthSpecialPointEnum
  value: number
}
