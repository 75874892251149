import {
  Button,
  Container,
  FilterButton,
  FilterContainer,
  FilterTitle,
  FilterBlock,
  FilterFooter,
  ConfirmButton,
  ResetButton,
} from './styles'
import { DownOutlined } from '@ant-design/icons'
import MapFilterHook from './MapFilter.hook'
import { useEffect } from 'react'

function MapFilter({
  handleFilterProviders,
  handleFilterTypes,
  display,
}: {
  handleFilterProviders: (providers: Array<string>) => void
  handleFilterTypes: (types: Array<string>) => void
  display: boolean
}) {
  const {
    isOpen,
    handleOpenFilter,
    motorEvChargingTypes,
    motorEvChargingProviders,
    selectFilterType,
    selectFilterProvider,
    selectedTypes,
    selectedProviders,
    resetAllFilters,
    isConfirm,
    submit,
    handlePreviousFilters,
  } = MapFilterHook()

  const isFiltered = selectedTypes.length > 0 || selectedProviders.length > 0

  useEffect(() => {
    if (isConfirm) {
      handleFilterProviders(selectedProviders)
      handleFilterTypes(selectedTypes)
      submit(false)
      handleOpenFilter(!isOpen)
      handlePreviousFilters(selectedProviders, selectedTypes)
    }
  }, [isConfirm])

  return (
    <Container display={display}>
      <Button type={isFiltered ? 'primary' : 'default'} onClick={() => handleOpenFilter(!isOpen)}>
        ประเภทหัวชาร์จ/ผู้ให้บริการ <DownOutlined />
      </Button>
      {isOpen ? (
        <FilterContainer>
          <FilterTitle>ประเภทหัวชาร์จ</FilterTitle>
          <FilterBlock>
            {motorEvChargingTypes?.backofficeGetAllMotorEvChargingTypes.map((type) => (
              <FilterButton
                type={selectedTypes.includes(type.id) ? 'primary' : 'default'}
                onClick={() => selectFilterType(type.id)}
              >
                {type.connectorType}
              </FilterButton>
            ))}
          </FilterBlock>
          <FilterTitle>ผู้ให้บริการ</FilterTitle>
          <FilterBlock>
            {motorEvChargingProviders?.backofficeGetAllMotorEvChargingProviders.map((provider) => (
              <FilterButton
                type={selectedProviders.includes(provider.id) ? 'primary' : 'default'}
                onClick={() => selectFilterProvider(provider.id)}
              >
                {provider.name}
              </FilterButton>
            ))}
          </FilterBlock>
          <FilterFooter>
            <ResetButton active={isFiltered} onClick={resetAllFilters}>
              ล้างทั้งหมด
            </ResetButton>
            <ConfirmButton onClick={() => submit(true)} type="primary">
              ค้นหา
            </ConfirmButton>
          </FilterFooter>
        </FilterContainer>
      ) : undefined}
    </Container>
  )
}

export default MapFilter
