import { Notification, FlashIcon, Text, TextBlock } from './styles'
import { ActivityBonusNotificationProps } from './types'
import { IMAGE_URL } from '@/constant/IMAGE'

// REMIND: From Backoffice UI requirement, Notification will display in 2 types
// 1. display only one badge and render multiple description.
// 2. display one or more badge (maximum 2 badges) and render only one description.
function ActivityBonusNotification({
  specialHighlights,
  className,
  displayInsideBadge,
}: ActivityBonusNotificationProps) {
  return (
    <>
      {specialHighlights &&
        specialHighlights?.length > 0 &&
        // type 1
        (displayInsideBadge ? (
          <Notification className={className}>
            <FlashIcon src={IMAGE_URL.flashIcon} />
            <TextBlock>
              {specialHighlights.map((item) => (
                <Text>{item ? item.description : ''}</Text>
              ))}
            </TextBlock>
          </Notification>
        ) : (
          // type 2
          specialHighlights?.map((item) => {
            return (
              <Notification key={item.description}>
                <FlashIcon src={IMAGE_URL.flashIcon} />
                <Text>{item ? item.description : ''}</Text>
              </Notification>
            )
          })
        ))}
    </>
  )
}

export default ActivityBonusNotification
