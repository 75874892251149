import React from 'react'
import {
  Container,
  DeleteIcon,
  DropzoneContainer,
  UploadFileIcon,
  UploadFileSubtitle,
  BackTitle,
  ImportContainer,
  ImportTitle,
  Alert,
  HeaderContainer,
  UploadFileClickTitle,
  UploadFileDragTitle,
  UploadFileTitleContainer,
  ImportButton,
  ImportButtonContainer,
  FileInfoContainer,
  ImportResultContainer,
  ImportResultAlert,
  ImportResultTitle,
  ImportResultDetails,
  LeftArrow,
} from './styles'
import WelcomeCallImporterHook from './WelcomeCallImporter.hook'
import { FileName } from '@/components/feature/TviConnectPage/DeviceImportCard/styles'
import WelcomeCallUploadFailedTable from '@/components/feature/WelcomeCallPage/WelcomeCallUploadFailedTable/WelcomeCallUploadFailedTable'
import { WelcomeCallImporterProps } from '@/components/feature/WelcomeCallPage/WelcomeCallImporter/types'
import { IMAGE_URL } from '@/constant/IMAGE'

function WelcomeCallImporter({ closeWelcomeCallImporter }: WelcomeCallImporterProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    onModalVisible,
    handleRemoveFile,
    file,
    result,
    isLoading,
  } = WelcomeCallImporterHook()

  return (
    <Container>
      <HeaderContainer>
        <BackTitle onClick={closeWelcomeCallImporter} data-testid="welcome-call-back-button">
          <LeftArrow /> Import ข้อมูลการจัดส่ง
        </BackTitle>
      </HeaderContainer>
      <ImportContainer>
        <ImportTitle>เลือกไฟล์ที่จะ Import</ImportTitle>
        <Alert
          message="หัวข้อคอลัมน์ใน CSV จะต้องตรงตามที่กำหนดเท่านั้น"
          type="info"
          showIcon={true}
        />
        <DropzoneContainer
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          data-testid="welcome-call-import-zone"
        >
          <input {...getInputProps()} />
          <UploadFileIcon src={IMAGE_URL.uploadGreyIcon} />
          <UploadFileTitleContainer>
            <UploadFileClickTitle>คลิกเพื่อเลือกไฟล์</UploadFileClickTitle>{' '}
            <UploadFileDragTitle>หรือลากวาง</UploadFileDragTitle>
          </UploadFileTitleContainer>
          <UploadFileSubtitle>ไฟล์ .CSV เท่านั้น</UploadFileSubtitle>
        </DropzoneContainer>
        <FileInfoContainer>
          {file.name ? <FileName>{file.name}</FileName> : undefined}
          {file.name ? (
            <DeleteIcon src={IMAGE_URL.trashIcon} onClick={handleRemoveFile} />
          ) : undefined}
        </FileInfoContainer>
        <ImportButtonContainer>
          <ImportButton
            type="primary"
            disabled={!file.name}
            onClick={onModalVisible}
            loading={isLoading}
            data-testid="welcome-call-confirm-import-button"
          >
            Import
          </ImportButton>
        </ImportButtonContainer>
        {result ? (
          <ImportResultContainer>
            <ImportResultAlert
              showIcon
              type={result && result.failed > 0 ? 'warning' : 'success'}
              message={<ImportResultTitle>ผลการ Import</ImportResultTitle>}
              description={
                <>
                  <ImportResultDetails>สำเร็จ: {result ? result.success : 0}</ImportResultDetails>
                  <ImportResultDetails>ไม่สำเร็จ: {result ? result.failed : 0}</ImportResultDetails>
                </>
              }
            />
          </ImportResultContainer>
        ) : undefined}
        {result && result.failedRecord && result.failedRecord.length > 0 ? (
          <WelcomeCallUploadFailedTable failedRecord={result.failedRecord} />
        ) : undefined}
      </ImportContainer>
    </Container>
  )
}

export default WelcomeCallImporter
