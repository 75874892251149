import backofficeFAQInfo from '@/gql/backofficeFAQInfo'
import backofficeFAQCategory from '@/gql/backofficeFAQCategory'
import {
  BackofficeFAQCategoriesByModuleNameInterface,
  BackOfficeFAQModuleNameEnum,
} from '@/gql/backofficeFAQCategory/backofficeFAQCategoriesByModuleName/interfaces'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState, KeyboardEvent, useRef, ChangeEvent, useCallback } from 'react'
import {
  CategoryActionEnum,
  FAQCategoryType,
  FaqInfoesByFAQCategoryIdType,
  statusQuestionEnum,
  SubCategoryMoveEnum,
} from './types'
import message from '@/libs/message'
import { useRecoilValue } from 'recoil'
import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import { BaseOptionType, DefaultOptionType } from 'antd/es/select'
import MoveCategoryModalHook from './MoveCategoryModal/MoveCategoryModal.hook'
import debounce from 'lodash/debounce'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'

const FrequencyQuestionsHook = () => {
  const { setConfirmModal } = ConfirmModalHook()
  const { setMoveCategoryModal } = MoveCategoryModalHook()
  const user = useRecoilValue(userAtom)
  const [isCreateCategory, setCreateCategory] = useState<boolean>(false)
  const [initialFaqCategory, setInitialFaqCategory] = useState<Array<FAQCategoryType> | []>([])
  const [faqCategory, setFaqCategory] = useState<Array<FAQCategoryType> | []>([])
  const [selectedFaqCategory, setSelectedFaqCategory] = useState<FAQCategoryType>()
  const [categoryOptions, setCategoryOptions] = useState<
    Array<BaseOptionType | DefaultOptionType> | []
  >([])

  const [statusQuestion, setStatusQuestion] = useState<statusQuestionEnum>(
    statusQuestionEnum.QUESTION,
  )

  const [faqInfoesByFAQCategoryId, setFaqInfoesByFAQCategoryId] = useState<
    Array<FaqInfoesByFAQCategoryIdType>
  >([])

  const [subCategory, setSubCategory] = useState<FaqInfoesByFAQCategoryIdType>()
  const [isMoveCategory, setMoveCategory] = useState<boolean>(false)
  const canSearch = validatePerm([BACKOFFICE_USER_PERMISSION.FAQ010102], user.permissions)
  const canCreate = validatePerm([BACKOFFICE_USER_PERMISSION.FAQ010103], user.permissions)
  const canDelete = validatePerm([BACKOFFICE_USER_PERMISSION.FAQ010104], user.permissions)
  const canEdit = validatePerm([BACKOFFICE_USER_PERMISSION.FAQ010105], user.permissions)
  const canMove = validatePerm([BACKOFFICE_USER_PERMISSION.FAQ010106], user.permissions)

  const getTitle = (): string => {
    if (statusQuestion === statusQuestionEnum.CREATE_QUESTION) {
      return 'เพิ่มคำถาม'
    }

    if (statusQuestion === statusQuestionEnum.EDIT_QUESTION) {
      return 'แก้ไขคำถาม'
    }

    if (statusQuestion === statusQuestionEnum.SEARCH_FAQ) {
      return 'ผลการค้นหา'
    }

    return selectedFaqCategory?.title || ''
  }

  const funcFormatFaqCategoriesByModuleName = (
    faqCategoriesByModuleName: Array<BackofficeFAQCategoriesByModuleNameInterface>,
  ) => {
    let category: Array<FAQCategoryType> = []

    for (const faqCategory of faqCategoriesByModuleName) {
      const dataCategory = {
        ...faqCategory,
        isSelect: false,
        action: CategoryActionEnum.READ,
      }

      category.push(dataCategory)
    }

    return category
  }

  const [
    getBackofficeFAQCategoriesByModuleNameQuery,
    {
      loading: loadingBackofficeFAQCategoryByModuleNameQuery,
      refetch: refetchBackofficeFAQCategoryByModuleNameQuery,
    },
  ] = useLazyQuery(backofficeFAQCategory.query.backofficeFAQCategoriesByModuleName, {
    variables: {
      input: {
        moduleName: BackOfficeFAQModuleNameEnum.PPU_MOTOR,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.backofficeFAQCategoriesByModuleName) {
        const arrayCategory = funcFormatFaqCategoriesByModuleName(
          data?.backofficeFAQCategoriesByModuleName,
        )

        let categoryOptions = []

        for (const category of arrayCategory) {
          categoryOptions.push({
            value: category.id,
            label: category.title,
          })
        }

        setInitialFaqCategory(arrayCategory)
        setFaqCategory(arrayCategory)
        setSelectedFaqCategory(arrayCategory[0])
        setCategoryOptions(categoryOptions)

        if (categoryOptions.length > 0) {
          const categoryId = categoryOptions[0].value

          getBackofficeFAQInfoesByFAQCategoryIdQuery({
            variables: {
              input: { faqCategoryId: categoryId },
            },
          })
        }
      }
    },
  })

  const [
    createBackofficeFAQCategoryMutation,
    { loading: loadingCreateBackofficeFAQCategoryMutation },
  ] = useMutation(backofficeFAQCategory.mutation.createBackofficeFAQCategory, {
    fetchPolicy: 'no-cache',
  })

  const [
    updateBackofficeFAQCategoryMutation,
    { error: errorUpdateBackofficeFAQCategory, loading: loadingUpdateBackofficeFaqCateMutation },
  ] = useMutation(backofficeFAQCategory.mutation.updateBackofficeFAQCategory, {
    fetchPolicy: 'no-cache',
  })

  const [
    removeBackofficeFAQCategoryMutation,
    { error: errorBackofficeFAQCategory, loading: loadingRemoveBackofficeFaqCateMutation },
  ] = useMutation(backofficeFAQCategory.mutation.removeBackofficeFAQCategory, {
    fetchPolicy: 'no-cache',
  })

  const [
    updateOrderBackofficeFAQCategoryMutation,
    { error: errorUpdateOrderBackofficeFAQCategory },
  ] = useMutation(backofficeFAQCategory.mutation.updateOrderBackofficeFAQCategory, {
    fetchPolicy: 'no-cache',
  })

  const [updateOrderBackofficeFAQInfoMutation, { error: errorUpdateOrderBackofficeFAQInfo }] =
    useMutation(backofficeFAQInfo.mutation.updateOrderBackofficeFAQInfo, {
      fetchPolicy: 'no-cache',
    })

  const [
    getBackofficeFAQInfoesByFAQCategoryIdQuery,
    {
      loading: loadingBackofficeFAQInfoesByFAQCategoryIdQuery,
      refetch: refetchBackofficeFAQInfoesByFAQCategoryIdQuery,
    },
  ] = useLazyQuery(backofficeFAQInfo.query.backofficeFAQInfoesByFAQCategoryId, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      let arrayFaq = []

      for (const faqInfo of data.backofficeFAQInfoesByFAQCategoryId) {
        arrayFaq.push({
          ...faqInfo,
          selectedSubCategory: false,
        })
      }

      setFaqInfoesByFAQCategoryId(arrayFaq)
      setStatusQuestion(statusQuestionEnum.QUESTION)
    },
  })

  const [getBackofficeFAQInfoesQuery, { loading: loadingGetBackofficeFAQInforesQuery }] =
    useLazyQuery(backofficeFAQInfo.query.backofficeFAQInfoes, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        let arrayFaq = []

        for (const faqInfo of data.backofficeFAQInfoes) {
          arrayFaq.push({
            ...faqInfo,
            selectedSubCategory: false,
          })
        }

        setFaqInfoesByFAQCategoryId(arrayFaq)
        setStatusQuestion(statusQuestionEnum.SEARCH_FAQ)
      },
    })

  const [removeBackofficeFAQInfoMutation, { error: errorRemoveBackofficeFAQInfo }] = useMutation(
    backofficeFAQInfo.mutation.removeBackofficeFAQInfo,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const handleSelectedFaqCategory = async (category: FAQCategoryType) => {
    if (category) {
      setSelectedFaqCategory(category)

      await getBackofficeFAQInfoesByFAQCategoryIdQuery({
        variables: {
          input: { faqCategoryId: category.id },
        },
      })
    }
  }

  const handleCreateBackofficeFAQCategory = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement
      if (target && target.value.trim() !== '') {
        await createBackofficeFAQCategoryMutation({
          variables: {
            input: {
              title: target.value,
              moduleName: BackOfficeFAQModuleNameEnum.PPU_MOTOR,
            },
          },
        })
          .then(() => {
            refetchBackofficeFAQCategoryByModuleNameQuery()
          })
          .finally(() => {
            setCreateCategory((boolean) => !boolean)
            return message.success({
              content: 'สร้างหมวดหมู่คำถามสำเร็จ',
              duration: 10,
            })
          })
          .catch(() => {
            setCreateCategory((boolean) => !boolean)
            return message.error({
              content: 'สร้างหมวดหมู่คำถามไม่สำเร็จ',
              duration: 10,
            })
          })
      }
    }
  }

  const handleEditCategory = (cate: FAQCategoryType, index: number) => {
    let newArray = [...faqCategory]

    newArray[index] = {
      ...cate,
      action: CategoryActionEnum.EDIT,
    }

    setFaqCategory(newArray)
  }

  const handleChangeCategory = (cate: FAQCategoryType, index: number, text: string) => {
    let newArray = [...faqCategory]

    newArray[index] = {
      ...cate,
      title: text,
    }

    setFaqCategory(newArray)
  }

  const handleUpdateBackofficeFAQCategory = async (
    event: KeyboardEvent<HTMLInputElement>,
    cate: FAQCategoryType,
  ) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement
      if (target && target.value !== '') {
        updateBackofficeFAQCategoryMutation({
          variables: {
            input: {
              faqCategoryId: cate.id,
              title: cate.title,
            },
          },
        })
          .finally(() => {
            refetchBackofficeFAQCategoryByModuleNameQuery()
            return message.success({
              content: 'แก้ไขหมวดหมู่คำถามสำเร็จ',
              duration: 10,
            })
          })
          .catch(() => {
            return message.error({
              content: `ปัญหา: ${errorUpdateBackofficeFAQCategory?.message}`,
              duration: 10,
            })
          })
      }
    }
  }

  const handleRemoveBackofficeFAQCategory = async (category: FAQCategoryType) => {
    setConfirmModal({
      onOkFunction: () => {
        removeBackofficeFAQCategoryMutation({
          variables: {
            input: {
              faqCategoryId: category.id,
            },
          },
        })
          .finally(() => {
            refetchBackofficeFAQCategoryByModuleNameQuery()
            message.success({
              content: 'ลบหมวดหมู่คำถามสำเร็จ',
              duration: 10,
            })
          })
          .catch(() => {
            message.error({
              content: `ปัญหา: ${errorBackofficeFAQCategory?.message}`,
              duration: 10,
            })
          })
        setConfirmModal({
          isVisible: false,
          onOkFunction: Function,
          dataTestId: undefined,
        })
      },
      isVisible: true,
      title: 'คุณแน่ใจที่จะลบห้วข้อคำถาม',
      bodyText: 'ถ้าหากคุณลบรายการคำถามที่อยู่ในห้วข้อนี้จะถูกลบทั้งหมด',
      okText: 'ลบ',
      okButtonProps: { type: 'primary', danger: true },
      dataTestId: 'delete-faq-list-modal',
    })
  }

  const handleCategoryMove = async (move: SubCategoryMoveEnum, id: string, order: number) => {
    let orderMove: number = order - 1

    if (move === SubCategoryMoveEnum.MOVE_DOWN) {
      orderMove = order - 1
    }

    if (move === SubCategoryMoveEnum.MOVE_UP) {
      orderMove = order + 1
    }

    await updateOrderBackofficeFAQCategoryMutation({
      variables: {
        input: {
          faqCategoryId: id,
          order: orderMove,
        },
      },
    })
      .then(() => {
        refetchBackofficeFAQCategoryByModuleNameQuery()
        message.success({
          content: 'เปลี่ยนแปลงลำดับหมวดหมู่คำถามสำเร็จ',
          duration: 10,
        })
      })
      .catch(() => {
        message.error({
          content: `ปัญหา: ${errorUpdateOrderBackofficeFAQCategory?.message}`,
          duration: 10,
        })
      })
  }

  const handleSubCategoryMove = async (move: SubCategoryMoveEnum, id: string, order: number) => {
    let orderMove: number = order

    if (move === SubCategoryMoveEnum.MOVE_DOWN) {
      orderMove = order - 1
    }

    if (move === SubCategoryMoveEnum.MOVE_UP) {
      orderMove = order + 1
    }

    await updateOrderBackofficeFAQInfoMutation({
      variables: {
        input: {
          faqInfoId: id,
          order: orderMove,
        },
      },
    })
      .then(() => {
        refetchBackofficeFAQInfoesByFAQCategoryIdQuery()
        message.success({
          content: 'เปลี่ยนแปลงลำดับคำถามสำเร็จ',
          duration: 10,
        })
      })
      .catch(() => {
        message.error({
          content: `ปัญหา: ${errorUpdateOrderBackofficeFAQInfo?.message}`,
          duration: 10,
        })
      })
  }

  const handleRemoveBackofficeFAQInfo = (subCategory?: FaqInfoesByFAQCategoryIdType) => {
    if (subCategory) {
      setConfirmModal({
        onOkFunction: () => {
          removeBackofficeFAQInfoMutation({
            variables: {
              input: { faqInfoId: subCategory.id },
            },
          })
            .finally(() => {
              refetchBackofficeFAQInfoesByFAQCategoryIdQuery()
              message.success({
                content: 'success',
                duration: 10,
              })
            })
            .catch(() => {
              message.error({
                content: `ปัญหา: ${errorRemoveBackofficeFAQInfo?.message}`,
                duration: 10,
              })
            })
          setConfirmModal({
            isVisible: false,
            onOkFunction: Function,
            dataTestId: undefined,
          })
          setStatusQuestion(statusQuestionEnum.QUESTION)
        },

        isVisible: true,
        title: 'คุณแน่ใจที่จะลบคำถาม',
        bodyText: `ลบคำถาม “${subCategory?.title || ''}”`,
        okText: 'ลบ',
        okButtonProps: { type: 'primary', danger: true },
        dataTestId: 'delete-question-modal',
      })
    }
  }

  const handleQuestion = () => {
    refetchBackofficeFAQInfoesByFAQCategoryIdQuery()
    setStatusQuestion(statusQuestionEnum.QUESTION)
  }

  const isSomeSubCategorySelected = faqInfoesByFAQCategoryId.some(
    (data) => data.selectedSubCategory,
  )

  const isEverySubCategorySelected =
    faqInfoesByFAQCategoryId.length > 0
      ? faqInfoesByFAQCategoryId.every((data) => data.selectedSubCategory)
      : false

  const category = categoryOptions.map((category) => {
    return {
      id: category.value,
      text: category.label,
      value: category.value,
    }
  })

  const handleMoveCategoryMultiple = () => {
    const filterFaqInfoId = faqInfoesByFAQCategoryId
      .filter((data) => data.selectedSubCategory)
      .map((faqInfoId) => faqInfoId.id)

    if (filterFaqInfoId.length > 0) {
      setMoveCategoryModal({
        onOkFunction: () => {
          setMoveCategoryModal({
            isVisible: false,
            onOkFunction: Function,
          })
          refetchBackofficeFAQInfoesByFAQCategoryIdQuery()
          setMoveCategory((bool) => !bool)
          setStatusQuestion(statusQuestionEnum.QUESTION)
        },
        isVisible: true,
        title: 'ย้ายคำถามไปยัง',
        category,
        subCategoryId: filterFaqInfoId,
        okText: 'ย้าย',
      })
    }
  }

  const handleMoveCategoryQuestion = (subCategoryId: string) => {
    const arraySubCategoryId = [subCategoryId]

    setMoveCategoryModal({
      onOkFunction: () => {
        setMoveCategoryModal({
          isVisible: false,
          onOkFunction: Function,
        })
        refetchBackofficeFAQInfoesByFAQCategoryIdQuery()
        setStatusQuestion(statusQuestionEnum.QUESTION)
      },
      isVisible: true,
      title: 'ย้ายคำถามไปยัง',
      category,
      okText: 'ย้าย',
      subCategoryId: arraySubCategoryId,
      okButtonProps: { type: 'primary', danger: true },
    })
  }

  const handleSetSearch = useCallback(
    (word?: string) => {
      if (word) {
        getBackofficeFAQInfoesQuery({
          variables: {
            input: {
              keyword: word,
            },
          },
        })
      } else {
        setSelectedFaqCategory((preState) => {
          if (preState)
            getBackofficeFAQInfoesByFAQCategoryIdQuery({
              variables: {
                input: { faqCategoryId: preState.id },
              },
            })

          return preState
        })
      }
    },
    [getBackofficeFAQInfoesByFAQCategoryIdQuery, getBackofficeFAQInfoesQuery],
  )

  const debouncedSearch = useRef(
    debounce((event?: ChangeEvent<HTMLInputElement>) => {
      const query = event?.target.value
      const value = query !== '' ? query : undefined

      return handleSetSearch(value)
    }, 400),
  ).current

  const isCateLoading =
    loadingBackofficeFAQCategoryByModuleNameQuery ||
    loadingCreateBackofficeFAQCategoryMutation ||
    loadingUpdateBackofficeFaqCateMutation ||
    loadingRemoveBackofficeFaqCateMutation
  const isFaqInfoLoading =
    loadingGetBackofficeFAQInforesQuery || loadingBackofficeFAQInfoesByFAQCategoryIdQuery

  useEffect(() => {
    getBackofficeFAQCategoriesByModuleNameQuery()
  }, [])

  return {
    initialFaqCategory,
    faqCategory,
    setFaqCategory,
    isCateLoading,
    isFaqInfoLoading,
    handleSelectedFaqCategory,
    handleCreateBackofficeFAQCategory,
    isCreateCategory,
    setCreateCategory,
    handleUpdateBackofficeFAQCategory,
    handleRemoveBackofficeFAQCategory,
    handleEditCategory,
    handleChangeCategory,
    categoryOptions,
    statusQuestion,
    setStatusQuestion,
    faqInfoesByFAQCategoryId,
    setFaqInfoesByFAQCategoryId,
    selectedFaqCategory,
    handleRemoveBackofficeFAQInfo,
    subCategory,
    setSubCategory,
    handleQuestion,
    handleMoveCategoryMultiple,
    setMoveCategoryModal,
    isSomeSubCategorySelected,
    isEverySubCategorySelected,
    handleMoveCategoryQuestion,
    isMoveCategory,
    setMoveCategory,
    handleCategoryMove,
    handleSubCategoryMove,
    debouncedSearch,
    getTitle,
    canCreate,
    canEdit,
    canMove,
    canSearch,
    canDelete,
  }
}

export default FrequencyQuestionsHook
