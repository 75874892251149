import { Link } from 'react-router-dom'

import { Container, Title, SuccessIcon, Button } from './styles'
import CONSTANT from '@/constant'
import SuccessfullyResetPasswordCardHook from './SuccessfullyResetPasswordCard.hook'
import { IMAGE_URL } from '@/constant/IMAGE'

function SuccessfullyResetPasswordCard() {
  const { resetBackofficeUserPassword } = SuccessfullyResetPasswordCardHook()

  return (
    <Container visible={resetBackofficeUserPassword.isSuccessful}>
      <SuccessIcon src={IMAGE_URL.successResetPasswordIcon} />
      <Title>เปลี่ยนรหัสผ่านสำเร็จ</Title>
      <Link to={CONSTANT.ROUTES.LOGIN_PAGE}>
        <Button type="primary">เข้าสู่ระบบ</Button>
      </Link>
    </Container>
  )
}

export default SuccessfullyResetPasswordCard
