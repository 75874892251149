import { gql, TypedDocumentNode } from '@apollo/client'
import {
  CreateBackofficeFAQInfoDataInterface,
  CreateBackofficeFAQInfoInputInterface,
} from './interfaces'

const createBackofficeFAQInfo: TypedDocumentNode<
  CreateBackofficeFAQInfoDataInterface,
  CreateBackofficeFAQInfoInputInterface
> = gql`
  mutation CreateBackofficeFAQInfo($input: CreateBackofficeFAQInfoActiveHealthInput!) {
    createBackofficeFAQInfo(input: $input) {
      faqCategoryId
      title
      description
      status
    }
  }
`

export default createBackofficeFAQInfo
