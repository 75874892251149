import { toThaiLocaleDateAndMonth, toThaiLocaleDateAndMonthRangeWithDash } from '@/libs/date'
import PointWeeklyInformationHook from './PointWeeklyInformation.hook'
import {
  ActivityDate,
  ActivityInformation,
  ActivityRow,
  ActivityTextBlock,
  ActivityTitle,
  Block,
  BlueFlagIcon,
  ConditionBlock,
  ConditionText,
  Container,
  DiscountBadge,
  PointBlock,
  PointIcon,
  PointText,
  ScoreText,
  Title,
  WeeklyDetailBlock,
  WeeklyTitle,
  Skeleton,
  ActiveHealthScoreBlock,
  WellPointSubBlock,
  ExercisePointSubBlock,
  WellPointSubIcon,
} from './styles'
import { PointWeeklyInformationProps } from './types'
import { IMAGE_URL } from '@/constant/IMAGE'

const PointWeeklyInformation = ({ weeklySummary }: PointWeeklyInformationProps) => {
  const {
    dataBackofficeGetDiscountConditionByPolicyNumber,
    dataBackofficeGetActiveHealthDailySummaryByWeekIdInput,
    isComponentLoading,
  } = PointWeeklyInformationHook({
    weeklySummary,
  })

  return (
    <Container>
      {isComponentLoading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <>
          <WeeklyDetailBlock>
            <WeeklyTitle>สัปดาห์ที่ {weeklySummary.billingWeekNo}</WeeklyTitle>
            <Title>
              {toThaiLocaleDateAndMonthRangeWithDash(
                weeklySummary.startDate,
                weeklySummary.endDate,
              )}
            </Title>
            <DiscountBadge discount={weeklySummary.discountAmount > 0}>
              {weeklySummary.discountAmount > 0 ? 'ได้รับส่วนลด' : 'ไม่ได้รับส่วนลด'}
            </DiscountBadge>
            <ConditionBlock>
              <BlueFlagIcon src={IMAGE_URL.blueFlagIcon} />
              <ConditionText>
                {
                  dataBackofficeGetDiscountConditionByPolicyNumber?.backOfficeGetDiscountConditionByPolicyNumber
                }
              </ConditionText>
            </ConditionBlock>
          </WeeklyDetailBlock>

          <ActiveHealthScoreBlock>
              <ExercisePointSubBlock>
                  <PointBlock>
                    <PointIcon src={IMAGE_URL.pointIcon} />
                    <PointText>คะแนนออกกำลังกาย</PointText>
                  </PointBlock>
                  <ScoreText>{weeklySummary.summaryExercisePoint} คะแนน</ScoreText>
              </ExercisePointSubBlock>
              {
                weeklySummary.summaryBonusPoint !== null ?
                <WellPointSubBlock>
                  <>
                  <PointBlock>
                    <PointIcon src={IMAGE_URL.wellBeingIcon} />
                    <PointText>คะแนน Well Point</PointText>
                  </PointBlock>
                  <ScoreText>{weeklySummary.summaryBonusPoint} คะแนน</ScoreText></>
                </WellPointSubBlock>
                : undefined
              }
            </ActiveHealthScoreBlock>

          <ActivityTitle>คะแนนที่มาจากการออกกำลังกาย</ActivityTitle>
          {dataBackofficeGetActiveHealthDailySummaryByWeekIdInput?.backofficeGetActiveHealthDailySummaryByWeekId.map(
            (dailySummaryByWeekId) => (
              <ActivityRow key={dailySummaryByWeekId.id}>
                <ActivityTextBlock>
                  <ActivityDate>
                    วัน{toThaiLocaleDateAndMonth(dailySummaryByWeekId.date)}
                  </ActivityDate>
                  <ActivityInformation>
                    {dailySummaryByWeekId.step} ก้าว, {dailySummaryByWeekId.activityDuration}
                    นาที
                  </ActivityInformation>
                </ActivityTextBlock>
                <WellPointSubIcon>
                {
                dailySummaryByWeekId.bonusPoint > 0 ?
                  <PointIcon src={IMAGE_URL.wellBeingIcon} /> : 
                  undefined
                }
                </WellPointSubIcon>
                <ScoreText>{dailySummaryByWeekId.exercisePoint}</ScoreText>
              </ActivityRow>
            ),
          )}
        </>
      )}
    </Container>
  )
}

export default PointWeeklyInformation
