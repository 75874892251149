import { Container, Title } from './styles'
import WatchManagementTable from '@/components/feature/WatchManagementPage/WatchManagementTable'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import UnauthorizedPage from '@/pages/Unauthorized'

function WatchManagement() {
  const user = useRecoilValue(userAtom)

  return (
    <>
      {validatePerm([BACKOFFICE_USER_PERMISSION.WAT010101], user.permissions) ? (
        <Container>
          <Title>จัดการนาฬิกา</Title>
          <WatchManagementTable />
        </Container>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default WatchManagement
