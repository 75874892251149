import { useParams } from 'react-router-dom'
import { Container, Title, Subtitle, Input, Button, Form, FormItem, Alert } from './styles'
import ForgotPasswordFormHook from './ForgotPasswordForm.hook'

function ForgotPasswordForm() {
  // TODO: workaround cause have not activate user flow
  const { token } = useParams<{ token: string }>()
  const { onSubmit, forgotBackofficeUserPassword, forgotBackofficeUserPasswordError } =
    ForgotPasswordFormHook()

  return (
    <Container visible={!forgotBackofficeUserPassword.isSuccessful}>
      <Title>{token ? 'ยืนยันบัญชีผู้ใช้งาน' : 'ลืมรหัสผ่าน'}</Title>
      <Subtitle>กรุณาระบุอีเมลของคุณ</Subtitle>
      <Form onFinish={onSubmit}>
        <FormItem name="email">
          <Input placeholder="Email" />
        </FormItem>
        <Button type="primary" htmlType="submit">
          ยืนยัน
        </Button>
        {forgotBackofficeUserPasswordError?.message && (
          <Alert message="ไม่พบผู้ใช้งานนี้ในระบบ" type="error" showIcon />
        )}
      </Form>
    </Container>
  )
}

export default ForgotPasswordForm
