import TaTransactionOrderPage from '@/components/feature/TaTransactionOrderPage/TaTransactionOrderPage'
import { Container } from './styles'

const TaTransactionOrder = () => {
  return (
    <Container>
      <TaTransactionOrderPage />
    </Container>
  )
}

export default TaTransactionOrder
