import { atom } from 'recoil'

import { LoadingProps } from './types'

const initLoading = {
  isLoading: false,
} as LoadingProps

const loadingAtom = atom({
  key: 'loadingAtom',
  default: initLoading,
})

export default loadingAtom
