import { useEffect, useState } from 'react'
import InsuredCard from '@/components/feature/PolicyDetailPage/InsuredCard'
import CoverageCard from '@/components/feature/PolicyDetailPage/CoverageCard'
import TviConnectCard from '@/components/feature/PolicyDetailPage/TviConnectCard'
import ChargePointCard from '@/components/feature/PolicyDetailPage/ChargePointCard'
import DiseaseModal from '@/components/feature/PolicyDetailPage/DiseaseModal'
import AdditionalCoverageModal from '@/components/feature/PolicyDetailPage/AdditionalCoverageModal'
import policyAtom from '@/recoil/policy/atom'
import { RowAntd, ColAntd } from './styles'
import { useRecoilValue } from 'recoil'
import { isEv as isEvType } from '@/libs/ev'

function UserInfoTab() {
  const [isEv, setIsEv] = useState(false)
  const policy = useRecoilValue(policyAtom)

  useEffect(() => {
    if (policy && policy.pvVehicleType) {
      const isEv = isEvType(policy.pvVehicleType)
      setIsEv(isEv)
    }
  }, [policy, setIsEv])

  const cards = [
    { component: <InsuredCard />, span: 24 },
    { component: <CoverageCard />, span: 24 },
    { component: <TviConnectCard isEv={isEv} />, span: isEv ? 12 : 24 },
    { component: isEv ? <ChargePointCard /> : undefined, span: 12 },
  ]

  return (
    <>
      <RowAntd gutter={[24, 24]}>
        {cards.map(
          (card, index) =>
            card.component && (
              <ColAntd key={index} md={card.span}>
                {card.component}
              </ColAntd>
            ),
        )}
      </RowAntd>
      <DiseaseModal />
      <AdditionalCoverageModal />
    </>
  )
}

export default UserInfoTab
