import React from 'react'

import { InformationContainer } from './styles'
import DetailCard from '../DetailCard'
import InformationGroup from '../InformationGroup'
import InsuredCardHook from './InsuredCard.hook'

function InsuredCard() {
  const { policy, isAutoTopUp, isMotorOnOffTopUp } = InsuredCardHook()

  return (
    <DetailCard
      title="ผู้เอาประกันภัย"
      visible={!!policy.insuredName}
      rightItem={null}
      dataTestId="policy-detail-insured-card"
    >
      <InformationContainer fourColumn={isMotorOnOffTopUp}>
        <InformationGroup
          information={{
            title: 'ผู้เอาประกันภัย',
            detail: policy.insuredName,
          }}
          marginBottomUnset={true}
          dataTestId="policy-detail-insured-card-insuredName"
        />
        <InformationGroup
          information={{
            title: 'เลขประจำตัวประชาชน',
            detail: policy.identityId,
          }}
          marginBottomUnset={true}
          dataTestId="policy-detail-insured-card-identityId"
        />
        {policy.insuredDateOfBirth ? (
          <InformationGroup
            information={{
              title: 'ว/ด/ป เกิด',
              detail: policy.insuredDateOfBirth,
            }}
            marginBottomUnset={true}
            dataTestId="policy-detail-insured-card-insuredDateOfBirth"
          />
        ) : (
          <></>
        )}
      </InformationContainer>
      <InformationContainer fourColumn={isMotorOnOffTopUp}>
        <InformationGroup
          information={{
            title: 'ที่อยู่ตามตารางกรมธรรม์',
            detail: `${policy.insuredAddress} ${policy.insuredDistrict.displayNameTh} ${policy.insuredProvince.displayNameTh} ${policy.insuredPostCode}`,
          }}
          marginBottomUnset={true}
          dataTestId="policy-detail-insured-card-insuredAddress"
        />
        <InformationGroup
          information={{
            title: 'ที่อยู่จัดส่งกรมธรรม์',
            detail: policy.deliveryAddress,
          }}
          marginBottomUnset={true}
          dataTestId="policy-detail-insured-card-deliveryAddress"
        />
        <InformationGroup
          information={{
            title: 'ผู้รับผลประโยชน์',
            detail: policy.beneficiary,
          }}
          marginBottomUnset={true}
          dataTestId="policy-detail-insured-card-beneficiary"
        />
        {isMotorOnOffTopUp && (
          <InformationGroup
            information={{
              title: 'สถานะ Auto Top-up',
              detail: isAutoTopUp ? 'ใช้งาน' : 'ไม่ได้ใช้งาน',
            }}
            marginBottomUnset={true}
            dataTestId="policy-detail-insured-card-autoTopUpStatus"
          />
        )}
      </InformationContainer>
    </DetailCard>
  )
}

export default InsuredCard
