import Modal from '@/components/common/Modal'
import { CloseOutlined } from '@ant-design/icons'
import { Container } from './styles'
import { Form, Radio, Select, Space } from 'antd'
import {
  FormItem,
  Input,
  Title,
} from '@/components/feature/TaTransactionOrderPage/TaTravelerModal/styles'
import TaContactPersonModalHook from '@/components/feature/TaTransactionOrderPage/TaContactPersonModal/TaContactPersonModal.hook'
import { ContactPersonResponse } from '@/services/order/type'
import { useEffect } from 'react'
import message from '@/libs/message'
import { TitleTypeEnum } from '@/constant/TA_TRANSACTION'

function TaContactPersonModal({
  open,
  orderId,
  contactPerson,
  closeModal,
}: {
  open: boolean
  orderId: string
  contactPerson: ContactPersonResponse
  closeModal: () => void
}) {
  const {
    form,
    isPerson,
    personTitleId,
    companyTitleId,
    isFormValid,
    type,
    validateForm,
    useTitle,
    useUpdateContact,
    handleChangeInput,
    handleChangeRadio,
    refetchOrderDetail,
  } = TaContactPersonModalHook({
    orderId,
    contactPerson,
  })
  const { nameTitlePersonOptions, nameTitleCorporationOptions } = useTitle()
  const mutation = useUpdateContact()

  useEffect(() => {
    if (mutation.isSuccess) {
      message.success('แก้ไขข้อมูลสำเร็จ').then(null)
      closeModal()
      refetchOrderDetail()
    }
    if (mutation.isError) {
      message.error('แก้ไขข้อมูลไม่สำเร็จ').then(null)
    }
  }, [closeModal, mutation.isSuccess, mutation.isError, refetchOrderDetail, isPerson])

  return (
    <Modal
      open={open}
      closeIcon={<CloseOutlined />}
      data-testid="ta-contact-person-modal"
      title="แก้ไขข้อมูลผู้ติดต่อ"
      width={640}
      onOk={() => mutation.mutate()}
      okText="บันทึก"
      okButtonProps={{ disabled: !isFormValid }}
      onCancel={closeModal}
    >
      <Container>
        <Form layout="vertical" form={form} onChange={validateForm}>
          {/* Nationality */}
          <FormItem label="ประเภทผู้สั่งซื้อ">
            <Radio.Group value={type} onChange={(e) => handleChangeRadio('type', e)}>
              <Radio value={TitleTypeEnum.PERSON}>บุคคลธรรมดา</Radio>
              <Radio value={TitleTypeEnum.CORPORATION}>นิติบุคคล</Radio>
            </Radio.Group>
          </FormItem>
          {/* Prefix */}
          <FormItem label="คำนำหน้า*" name={['title', 'id']} hidden={!isPerson}>
            <Select
              options={nameTitlePersonOptions}
              value={personTitleId}
              onChange={(e) => handleChangeInput('title', e)}
            />
          </FormItem>
          <FormItem label="คำนำหน้า*" name={['companyTitle', 'id']} hidden={isPerson}>
            <Select
              options={nameTitleCorporationOptions}
              value={companyTitleId}
              onChange={(e) => handleChangeInput('companyTitle', e)}
            />
          </FormItem>
          {/* Name */}
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Firstname */}
            <FormItem label="ชื่อ*" name="firstname">
              <Input
                onChange={(e) => handleChangeInput('firstname', e.target.value)}
                maxLength={100}
              />
            </FormItem>
            {/* Lastname */}
            <FormItem label="นามสกุล*" name="lastname">
              <Input
                onChange={(e) => handleChangeInput('lastname', e.target.value)}
                maxLength={100}
              />
            </FormItem>
          </Space>
          {/* Corporation Name */}
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Corporation Name */}
            <FormItem label="ชื่อบริษัท*" name="companyName" hidden={isPerson}>
              <Input
                onChange={(e) => handleChangeInput('companyName', e.target.value)}
                maxLength={200}
              />
            </FormItem>
            {/* Corporation ID */}
            <FormItem label="เลขที่นิติบุคคล*" name="companyNumber" hidden={isPerson}>
              <Input
                onChange={(e) => handleChangeInput('companyNumber', e.target.value)}
                maxLength={13}
              />
            </FormItem>
          </Space>
          {/* Corporation Branch */}
          <Space style={{ display: 'flex', justifyContent: 'space-between' }} hidden={isPerson}>
            {/* Firstname */}
            <FormItem label="เลขที่สาขา*" name="companyBranchNumber">
              <Input
                onChange={(e) => handleChangeInput('companyBranchNumber', e.target.value)}
                maxLength={5}
              />
            </FormItem>
          </Space>
          {/* Contact */}
          <Title>ช่องทางการติดต่อ</Title>
          <Space style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
            <FormItem label="เบอร์โทรศัพท์*" name="mobileNumber">
              <Input
                onChange={(e) => handleChangeInput('mobileNumber', e.target.value)}
                maxLength={10}
              />
            </FormItem>
            <FormItem label="อีเมล*" name="email">
              <Input onChange={(e) => handleChangeInput('email', e.target.value)} maxLength={100} />
            </FormItem>
          </Space>
        </Form>
      </Container>
    </Modal>
  )
}

export default TaContactPersonModal
