import styled from '@emotion/styled'
import { Input as AntdInput, Upload as AntdUpload } from 'antd'
import Button from '@/components/common/Button'

export const Container = styled('div')``

export const Block = styled('div')``

export const Input = styled(AntdInput)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
`

export const Upload = styled(AntdUpload)``

export const Title = styled('p')`
  margin-bottom: 4px;
`

export const Desc = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const Desc2 = styled('p')`
  font-weight: 400;
  font-size: 12px;
  color: #666666;
`

export const ChooseFileButton = styled(Button)`
  border: 1px solid #009640;
  color: #009640;
`

export const ImgWrapper = styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export const Image = styled('img')`
  width: 200px;
  height: 433.07px;
`

export const PreviewImage = styled('img')`
  position: absolute;
  top: 150px;
  left: 25px;
  width: 150px;
  height: 150px;
`

export const BoostPointIcon = styled('img')`
  position: absolute;
  top: 226px;
  left: 228px;
  width: 8px;
  height: 8px;
`

export const PreviewText = styled('p')`
  position: absolute;
  top: 224px;
  left: 238px;
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 12px;
  color: white;
`
