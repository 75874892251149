import { Container, Title } from './styles'
import Report from '@/components/feature/ReportPage/Report'

function User() {
  return (
    <Container>
      <Title>รายงาน</Title>
      <Report />
    </Container>
  )
}

export default User
