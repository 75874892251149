import { CloseOutlined } from '@ant-design/icons'
import AdditionalCoverageModalHook from './AdditionalCoverageModal.hook'
import Modal from '@/components/common/Modal'

function AdditionalCoverageModal() {
  const { additionalCoverageModal, handleCloseModal } = AdditionalCoverageModalHook()
  return (
    <Modal
      open={additionalCoverageModal.open}
      closeIcon={<CloseOutlined />}
      footer={null}
      title="สิทธิพิเศษ"
      onCancel={handleCloseModal}
      data-testid="policy-detail-additional-coverage-modal"
    >
      <ul style={{ fontWeight: '500' }}>
        {additionalCoverageModal?.additionalCoverages?.map(({ additionalCampaignCoverage }) => (
          <li>{additionalCampaignCoverage}</li>
        ))}
      </ul>
    </Modal>
  )
}

export default AdditionalCoverageModal
