import { ChangeEvent, useEffect, useState } from 'react'
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select'
import { activeHealthPartnerGql, activeHealthBonusPointGql } from '@/gql'
import editActiveBonusModalAtom from '@/recoil/editActiveBonusModal'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useRecoilState, useRecoilValue } from 'recoil'
import axios, { AxiosRequestConfig } from 'axios'
import { UploadFile, UploadProps } from 'antd'
import { RcFile } from 'antd/es/upload'
import message from '@/libs/message'
import userAtom from '@/recoil/user'
import config from '@/config'

export default function EditActiveBonusModalHook() {
  const [getActiveHealthPartnerAll, { data: activeHealthPartners }] = useLazyQuery(
    activeHealthPartnerGql.query.backofficeGetActiveHealthPartnerAll,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const [adjustBonusPointMutation, { loading: loadingAdjustBonusPointMutation }] = useMutation(
    activeHealthBonusPointGql.mutation.backofficeAdjustBonusPoint,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const user = useRecoilValue(userAtom)
  const [partnerId, setPartnerId] = useState<string>()
  const [branchId, setBranchId] = useState<string>()
  const [remark, setRemark] = useState<string>()
  const [remarkFile, setRemarkFile] = useState<UploadFile>()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isFormValidate, setIsFormValidate] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeBonusModal, setActiveBonusModal] = useRecoilState(editActiveBonusModalAtom)
  const onCloseModal = ({ isEditSuccess }: { isEditSuccess?: boolean }) => {
    setPartnerId(undefined)
    setBranchId(undefined)
    setRemark(undefined)
    setRemarkFile(undefined)
    setFileList([])
    setIsFormValidate(false)
    setIsLoading(false)
    setActiveBonusModal({ open: false, isEditSuccess })
  }
  const selectFilterOption = (input: string, option: any) => (option?.label ?? '').includes(input)

  const selectPartnerProps = {
    value: partnerId,
    options: activeHealthPartners?.backofficeGetActiveHealthPartnerAll?.map((partner) => {
      return { value: partner.id, label: partner.displayNameTh }
    }) as Array<BaseOptionType | DefaultOptionType>,
    filterOption: selectFilterOption,
    onChange: (value: any) => {
      setPartnerId(value)
      setBranchId(undefined)
    },
  }

  const selectBranchProps = {
    value: branchId,
    options: activeHealthPartners?.backofficeGetActiveHealthPartnerAll
      ?.find((partner) => partner.id === partnerId)
      ?.activeHealthPartnerBranch.map((branch) => {
        return { value: branch.id, label: branch.displayNameTh }
      }),
    filterOption: selectFilterOption,
    onChange: (value: any) => {
      setBranchId(value)
    },
  }

  const textAreaRemarkProps = {
    value: remark,
    showCount: true,
    maxLength: 2000,
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => setRemark(event.target.value),
  }

  const uploadProps: UploadProps = {
    fileList,
    maxCount: 1,
    accept: '.png,.jpg,.jpeg,.pdf',
    beforeUpload: () => false,
    onChange(info) {
      const { file, fileList } = info
      if (fileList.length < 1) {
        return
      }

      const isLt10M = (file?.size || 11) / 1024 / 1024 > 10

      let files: UploadFile[] = fileList
      if (isLt10M) {
        message.error('File must smaller than 10MB!')
        files = fileList.map((upload) => {
          return { ...upload, status: 'error' }
        })
        setRemarkFile(undefined)
      } else {
        setRemarkFile(file)
      }

      setFileList(files)
    },
    onRemove() {
      setFileList([])
      setRemarkFile(undefined)
    },
    async onPreview(file: UploadFile) {
      let src = file.url as string
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(file.originFileObj as RcFile)
          reader.onload = () => resolve(reader.result as string)
        })
      }
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow?.document.write(image.outerHTML)
    },
  }

  const validateEditActivityDurationModal = () => {
    if (!activeBonusModal) {
      return false
    }

    if (!activeBonusModal.id) {
      message.error('ไม่พบวันที่จะแก้ไข')
      return false
    }

    if (!branchId) {
      message.error('กรุณาระบุสาขา')
      return false
    }

    if (!remark) {
      message.error('กรุณาระบุรายละเอียด')
      return false
    }

    if (!remarkFile) {
      message.error('กรุณาใส่รูปรายละเอียด')
      return false
    }

    return true
  }

  const onSaveActivityDuration = async () => {
    setIsLoading(true)
    const isValidate = validateEditActivityDurationModal()
    if (!isValidate) return

    const dailySummaryId = activeBonusModal.id || ''
    const formData = new FormData()
    formData.append('file', remarkFile as any)
    formData.append('reference', dailySummaryId)

    try {
      setFileList(
        fileList.map((upload) => {
          return { ...upload, status: 'uploading' }
        }),
      )

      const options: AxiosRequestConfig = {
        method: 'POST',
        url: `${config.graphqlRestEndpoint}/active-health-bonus-point/daily-summary/bonus-point/remark`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
      const result = await axios(options).catch(() => {
        message.error('ไม่สามารถอัพโหลดไฟล์ได้')
      })
      const remarkFileUrl = result?.data?.url

      setFileList(
        fileList.map((upload) => {
          return { ...upload, status: 'done' }
        }),
      )

      const { data } = await adjustBonusPointMutation({
        variables: {
          input: {
            dailySummaryId,
            partnerBranchId: branchId || '',
            remark: remark || '',
            remarkFileUrl,
          },
        },
      })
      if (data) {
        message.success('แก้ไขสำเร็จ')
        onCloseModal({ isEditSuccess: true })
      }
    } catch (err) {
      message.error('ไม่สามารถแก้ไขได้')

      setFileList(
        fileList.map((upload) => {
          return { ...upload }
        }),
      )

      console.error(err)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getActiveHealthPartnerAll()
  }, [])

  useEffect(() => {
    const isValidate = !!branchId && !!remark && !!remarkFile
    setIsFormValidate(isValidate)
  }, [branchId, remark, remarkFile])

  return {
    activeBonusModal,
    onCloseModal,
    selectPartnerProps,
    selectBranchProps,
    textAreaRemarkProps,
    uploadProps,
    isFormValidate,
    onSaveActivityDuration,
    loadingAdjustBonusPointMutation,
    isLoading,
  }
}
