import { useCallback, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useRecoilValue } from 'recoil'
import dayjs from 'dayjs'
import { activeHealthBonusPointGql, backofficeActiveHealthExerciseGql } from '@/gql'
import policyDetailAtom from '@/recoil/policyDetail'
import {
  ActiveHealthPartnerBranch,
  ActiveHealthPromotionActivityType,
} from '@/gql/activeHealthBonusPoint/backofficeGetBonusPointPartnerBranch/interface'
import { BackofficeGetUserExerciseHistoryData } from '@/gql/backofficeActiveHealthExercise/backofficeGetUserExerciseHistoryPagination/interfaces'

function RecentActivityInformationHook({
  exerciseHistory,
}: {
  exerciseHistory: BackofficeGetUserExerciseHistoryData
}) {
  const policyDetail = useRecoilValue(policyDetailAtom)
  const [fileExtension, setFileExtension] = useState('')
  const [wellBeingFileExtension, setWellBeingFileExtension] = useState('')
  const [partnerBranch, setPartnerBranch] = useState<ActiveHealthPartnerBranch>()

  const [
    backofficeGetEditExerciseDataDailySummaryActivityLog,
    { data: editExerciseActivityLogData, loading: exerciseActivityLogLoading },
  ] = useLazyQuery(
    backofficeActiveHealthExerciseGql.query.backOfficeGetEditExerciseDataDailySummaryActivityLog,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const [
    backOfficeGetActiveHealthHeartrateTargetByDate,
    { loading: getHeartrateTargetLoading, data: heartrateTargetData },
  ] = useLazyQuery(
    backofficeActiveHealthExerciseGql.query.backOfficeGetActiveHealthHeartrateTargetByDate,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const getExerciseDataDailySummaryActivityLog = useCallback(
    (activeHealthDailySummaryId: string) => {
      backofficeGetEditExerciseDataDailySummaryActivityLog({
        variables: {
          activeHealthDailySummaryId,
        },
      })
    },
    [backofficeGetEditExerciseDataDailySummaryActivityLog],
  )

  const [
    backofficeGetAdjustBonusPointAndPromotionActivity,
    { data: bonusPointAndPromotionActivityData, loading: bonusPointAndPromotionActivityLoading },
  ] = useLazyQuery(
    activeHealthBonusPointGql.query.backofficeGetAdjustBonusPointAndPromotionActivity,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const getAdjustBonusPointAndPromotionActivity = useCallback(
    (activeHealthDailySummaryId: string) => {
      backofficeGetAdjustBonusPointAndPromotionActivity({
        variables: {
          input: {
            dailySummaryId: activeHealthDailySummaryId,
          },
        },
      })
    },
    [backofficeGetAdjustBonusPointAndPromotionActivity],
  )

  const getHeartrateByDate = useCallback(
    (date: Date) => {
      if (policyDetail.id && date) {
        backOfficeGetActiveHealthHeartrateTargetByDate({
          variables: {
            userId: policyDetail.id,
            input: {
              date: dayjs(date).format('YYYY-MM-DD'),
            },
          },
        })
      }
    },
    [policyDetail.id, backOfficeGetActiveHealthHeartrateTargetByDate],
  )

  const [
    backofficeGetBonusPointPartnerBranch,
    { data: bonusPointPartnerBranchData, loading: bonusPointPartnerBranchLoading },
  ] = useLazyQuery(activeHealthBonusPointGql.query.backofficeGetBonusPointPartnerBranch, {
    fetchPolicy: 'no-cache',
  })

  const getBonusPointPartnerBranch = useCallback(
    (id: string) => {
      backofficeGetBonusPointPartnerBranch({
        variables: {
          input: {
            dailySummaryId: id,
          },
        },
      })
    },
    [backofficeGetBonusPointPartnerBranch],
  )

  const isComponentLoading =
    exerciseActivityLogLoading ||
    bonusPointAndPromotionActivityLoading ||
    getHeartrateTargetLoading ||
    bonusPointPartnerBranchLoading

  // example image input: https://hello.world/img/image.jpg , expected output is jpg
  // example pdf input: https://hello.world/img/document.pdf, expected output is pdf
  const getFileExtension = (fileUrl: string) => {
    const fragments = fileUrl.split('.')

    return fragments[fragments.length - 1]
  }

  useEffect(() => {
    getExerciseDataDailySummaryActivityLog(exerciseHistory.id)
    getHeartrateByDate(exerciseHistory.date)

    if (exerciseHistory.bonusPoint > 0) {
      getBonusPointPartnerBranch(exerciseHistory.id)
    }
  }, [
    exerciseHistory.id,
    exerciseHistory.date,
    exerciseHistory.bonusPoint,
    getExerciseDataDailySummaryActivityLog,
    getBonusPointPartnerBranch,
    getHeartrateByDate,
  ])

  useEffect(() => {
    const processBonusPointData = () => {
      const bonusPointData = bonusPointPartnerBranchData?.backofficeGetBonusPointPartnerBranch
      const bonusPoint = bonusPointData?.bonusPoint || 0

      if (!bonusPointData || bonusPoint <= 0) {
        return
      }

      const activityBonusPoint = bonusPointData.activityBonusPoint || []

      if (activityBonusPoint.length <= 0) {
        return
      }

      const activityPoint = activityBonusPoint[0]
      const activityType = activityPoint?.activityType

      setPartnerBranch(activityPoint?.activeHealthPartnerBranch)

      if (activityType === ActiveHealthPromotionActivityType.CS_ADJUST) {
        getAdjustBonusPointAndPromotionActivity(exerciseHistory.id)
      }
    }

    processBonusPointData()
  }, [exerciseHistory.id, bonusPointPartnerBranchData, getAdjustBonusPointAndPromotionActivity])

  useEffect(() => {
    if (
      editExerciseActivityLogData?.backOfficeGetEditExerciseDataDailySummaryActivityLog?.actionData
        ?.remarkImageUrl
    ) {
      const extension = getFileExtension(
        editExerciseActivityLogData?.backOfficeGetEditExerciseDataDailySummaryActivityLog
          ?.actionData?.remarkImageUrl,
      )

      setFileExtension(extension)
    }
  }, [editExerciseActivityLogData])

  useEffect(() => {
    const remarkFileUrl =
      bonusPointAndPromotionActivityData?.backofficeGetAdjustBonusPointAndPromotionActivity?.[0]
        ?.activeHealthPromotionActivity?.actionData?.remarkFileUrl
    if (remarkFileUrl) {
      setWellBeingFileExtension(getFileExtension(remarkFileUrl))
    }
  }, [bonusPointAndPromotionActivityData])

  return {
    editExerciseActivityLogData,
    bonusPointAndPromotionActivityData,
    fileExtension,
    wellBeingFileExtension,
    heartrateTargetData,
    partnerBranch,
    isComponentLoading,
  }
}

export default RecentActivityInformationHook
