import axios from 'axios'
import JsFileDownload from 'js-file-download'
import { useRecoilValue } from 'recoil'

import userAtom from '@/recoil/user'
import config from '@/config'

function ReportHook() {
  const user = useRecoilValue(userAtom)

  const onDownloadReport = async (values: any) => {
    try {
      const rangeDate = values.rangeDate
      const startDate = rangeDate[0].startOf('day').toISOString()
      const endDate = rangeDate[1].endOf('day').toISOString()
      const isMapped = values.isMapped

      const downloadUrl = `${config.restUrl}/v1/report/ppu-motor-tvi-connect-devices?startDate=${startDate}&endDate=${endDate}&isMapped=${isMapped}`
      const params = {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
      const { data } = await axios.get(downloadUrl, params)

      JsFileDownload(data, 'report.csv')
    } catch (err) {
      console.log(err)
    }
  }

  return {
    onDownloadReport,
  }
}

export default ReportHook
