import React, { useContext, useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useLazyQuery } from '@apollo/client'
import { DateRange } from '@/libs/x-date-pickers-pro'
import { activeHealthPolicyGql } from '@/gql'
import { PolicyManagementContext } from '@/contexts'
import { ActiveHealthPolicyInputFilterInterface } from '@/gql/activeHealthPolicy/backofficeSearchActiveHealthPolicy/interfaces'
import { ActiveHealthPolicyStatusEnum } from '@/constant/ACTIVE_HEALTH_POLICY'

function ActiveHealthPolicyExporterHook() {
  const [dateValue, setDateValue] = useState<DateRange<Dayjs>>([null, null])
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [policies, setPolicies] = useState<Array<Array<string>>>([])
  const [
    exportActiveHealthPolicy,
    { data: activeHealthPolicyData, loading: activeHealthPolicyLoading },
  ] = useLazyQuery(activeHealthPolicyGql.query.backofficeExportSearchActiveHealthPolicy, {
    fetchPolicy: 'no-cache',
  })
  const { policyStatus } = useContext(PolicyManagementContext)

  const handleCloseModal = () => {
    setDateValue([null, null])
    setPolicies([])
    setIsModalVisible(false)
  }

  const onClickCsvLink = (
    event: React.MouseEventHandler<HTMLAnchorElement>,
    done: (proceed?: boolean) => void,
  ) => {
    // API always return header of csv at 0 index (length === 1), we allow to downloading csv file when found length of data more than 1
    if (policies.length <= 1) return done(false)

    handleCloseModal()
  }

  useEffect(() => {
    if (activeHealthPolicyData && activeHealthPolicyData.backofficeExportSearchActiveHealthPolicy) {
      setPolicies(activeHealthPolicyData.backofficeExportSearchActiveHealthPolicy)
    }
  }, [activeHealthPolicyData])

  useEffect(() => {
    if (dateValue[0] && dateValue[1]) {
      const from = dayjs(dateValue[0]).format('YYYY-MM-DD')
      const to = dayjs(dateValue[1]).format('YYYY-MM-DD')
      const filter: ActiveHealthPolicyInputFilterInterface = {
        status: undefined,
        createdAt: `$btw:${from},${to}`,
      }

      if (policyStatus) {
        const query =
          policyStatus === ActiveHealthPolicyStatusEnum.CANCELLED_WITH_REASON ? '$in' : '$eq'

        filter.status = `${query}:${policyStatus}`
      }

      exportActiveHealthPolicy({
        variables: {
          input: {
            filter,
          },
        },
      })
    }
  }, [dateValue])

  return {
    isModalVisible,
    setIsModalVisible,
    dateValue,
    setDateValue,
    policies,
    handleCloseModal,
    activeHealthPolicyLoading,
    onClickCsvLink,
  }
}

export default ActiveHealthPolicyExporterHook
