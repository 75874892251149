import { Table } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const UserSelectStyle = css`
  user-select: text;
`

export const AntdTable = styled(Table)`
  .ant-table {
    border-radius: 8px;
    border: 1px solid #e5e5e5;
  }

  .ant-table-thead > tr > th {
    color: #666666;
    font-size: 12px;
    padding: 10px 16px;
  }

  .ant-table-tbody > tr > td {
    font-size: 14px;
  }

  // disable default background-color of ant design when sorting enabled.
  .ant-table-column-sort {
    background-color: inherit !important;
  }

  // force assign background color when hover.
  .ant-table-cell.ant-table-cell-row-hover {
    background-color: #fafafa !important;
  }
`

export const TotalRecordContainer = styled('div')`
  position: relative;
`

export const TotalRecord = styled('div')`
  position: absolute;
  top: -40px;
  font-size: 14px;
`
