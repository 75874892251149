import styled from '@emotion/styled'
import { Form as AntForm, Input as AntdInput } from 'antd'

export const Container = styled('div')`
  padding: 24px 32px 24px 32px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
  margin: 24px 0;
`

export const Title = styled('div')`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
`

export const Block = styled('div')`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
`

export const InfoTitle = styled('p')`
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
`

export const PartnerLogo = styled('img')`
  width: 140px;
  height: 140px;
`

export const Input = styled(AntdInput)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
`

export const Form = styled(AntForm)``

export const FormItem = styled(AntForm.Item)``

export const FormGridBlock = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export const CreatedByText = styled('div')`
  color: #666666;
  font-size: 14px;
`
