import backofficeSearchUser from './backofficeSearchUser/BackofficeSearchUser.gql'
import backofficeUpdateUser from './backofficeUpdateUser/BackofficeUpdateUser.gql'
import backofficeGetUserHelpPagination from './backofficeGetUserHelpPagination/BackofficeGetUserHelpPagination.gql'
import backofficeUpdateUserHelp from './backofficeUpdateUserHelp/BackofficeUpdateUserHelp.gql'
import backofficeGetUserPolicyByPolicyNumber from './backofficeGetUserPolicyByPolicyNumber/BackofficeGetUserPolicyByPolicyNumber.gql'

const user = {
  query: {
    backofficeSearchUser,
    backofficeGetUserHelpPagination,
    backofficeGetUserPolicyByPolicyNumber,
  },
  mutation: {
    backofficeUpdateUser,
    backofficeUpdateUserHelp,
  },
}

export default user
