import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthManualDiscountPaginationDataInterface,
  BackofficeGetActiveHealthManualDiscountPaginationInputInterface,
} from './interfaces'

const backofficeGetActiveHealthManualDiscountPagination: TypedDocumentNode<
  BackofficeGetActiveHealthManualDiscountPaginationDataInterface,
  BackofficeGetActiveHealthManualDiscountPaginationInputInterface
> = gql`
  query BackofficeGetActiveHealthManualDiscountPagination($input: RequestPaginationInput!) {
    backofficeGetActiveHealthManualDiscountPagination(input: $input) {
      data {
        id
        policyNumber
        approveStatus
        user {
          firstname
          lastname
        }
        refActiveHealthPolicyBilling {
          id
          billDate
          billingDiscount
          startDate
          payDate
          isPvDiscountSuccess
        }
        activeHealthPolicyBilling {
          id
          billDate
          billingDiscount
          isPvDiscountSuccess
        }
        activeHealthManualDiscountApprover {
          name
          email
        }
        activeHealthPolicy {
          plan
          insuredName
        }
        discount
        reason
        fileKey
        fileId
        createdAt
        updatedAt
        createdBy {
          firstname
          lastname
        }
      }
      meta {
        totalItems
      }
    }
  }
`

export default backofficeGetActiveHealthManualDiscountPagination
