import styled from '@emotion/styled'
import { Tabs as AntdTabs, Skeleton as AntdSkeleton } from 'antd'
import CommonButton from '@/components/common/Button'

export const CardWrapper = styled('div')<{ displayUnset?: boolean }>`
  display: ${(props) => (props.displayUnset ? 'unset' : 'grid')};
  grid-template-columns: 320px auto 1fr;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

export const Line = styled('div')`
  height: 100%;
  width: 1px;
  background: #efefef;
`

export const Wrapper = styled('div')`
  padding: 32px 24px;
`

export const Title = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`

export const Content = styled('div')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.004em;
  color: #666666;
  margin-bottom: 16px;
`

export const Button = styled(CommonButton)`
  font-size: 14px;
  line-height: 20px;
  box-shadow: none;
  padding: 10px 16px;
  margin-bottom: 24px;
`

export const ListWrapper = styled('div')`
  display: grid;
  justify-items: left;
`

export type ButtonLinkType = {
  selected: boolean
}

export const ButtonLink = styled('div')<ButtonLinkType>`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: ${(props) => (props.selected ? '#009640' : '#000000')};
  margin-bottom: 20px;

  :hover {
    color: #1aa351;
  }

  :active {
    color: #007034;
  }
`

export const IconSvg = styled('img')`
  margin-right: 10px;
  width: 20px;
  height: 20px;
`

export const TopicWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 12px;
  margin-bottom: 32px;
`

export const Tabs = styled(AntdTabs)`
  .ant-tabs-tab {
    padding: 0px 0px 14px 0px;
  }

  .ant-tabs-nav {
    margin: 0 0 24px 0;
  }
`

export const Skeleton = styled(AntdSkeleton)`
  margin: 4px 0;
`
