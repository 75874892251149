import backofficeGetAllLocationChargingStations from '@/gql/motorEv/backofficeGetAllLocationChargingStations/backofficeGetAllLocationChargingStations.gql'
import backofficeGetAllMotorEvChargingProviders from '@/gql/motorEv/backofficeGetAllMotorEvChargingProviders/backofficeGetAllMotorEvChargingProviders.gql'
import backofficeGetAllMotorEvChargingTypes from '@/gql/motorEv/backofficeGetAllMotorEvChargingTypes/backofficeGetAllMotorEvChargingTypes'

const motorEv = {
  query: {
    backofficeGetAllLocationChargingStations,
    backofficeGetAllMotorEvChargingProviders,
    backofficeGetAllMotorEvChargingTypes,
  },
  mutation: {},
}

export default motorEv
