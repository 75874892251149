import { IMAGE_URL } from '@/constant/IMAGE'
import ListButton from '../ListButton/ListButton'
import {
  AddUserButton,
  BodyWrapper,
  FormWrapper,
  HeaderList,
  ImageIcon,
  ListWrapper,
  Modal,
  RemoveAllButton,
  SearchWrapper,
  TextButton,
  TitleList,
} from './styles'
import ModalAddTeamMemberHook from './ModalAddTeamMember.hook'

import AutoCompleteSearchUser from './components/AutoCompleteSearchUser/AutoCompleteSearchUser'

const ModalAddTeamMember = () => {
  const {
    isVisible,
    buttonProps,
    onSubmit,
    onCancelFunction,
    userValue,
    setUserValue,
    onOpenAddUserTeamMember,
  } = ModalAddTeamMemberHook()

  return (
    <Modal
      title="เพิ่มผู้ใช้งานเข้าทีม"
      open={isVisible}
      closable={false}
      cancelText="ยกเลิก"
      okText="เพิ่ม"
      okButtonProps={buttonProps}
      onOk={onSubmit}
      onCancel={onCancelFunction}
      width={400}
      data-testid="add-team-member-modal"
    >
      <SearchWrapper>
        <AutoCompleteSearchUser
          userValue={userValue}
          onChangeUser={(e) => setUserValue(e)}
          data-testid="add-team-member-search-input"
        />
        <AddUserButton onClick={onOpenAddUserTeamMember} data-testid="add-team-member-button">
          <ImageIcon src={IMAGE_URL.addUserIcon} />
          <TextButton>สร้างผู้ใช้งาน</TextButton>
        </AddUserButton>
      </SearchWrapper>
      <FormWrapper>
        <HeaderList>
          <TitleList>จำนวนทั้งหมด {userValue.length} คน</TitleList>
          <RemoveAllButton
            onClick={() => setUserValue([])}
            data-testid="add-team-member-remove-all-button"
          >
            ลบทั้งหมด
          </RemoveAllButton>
        </HeaderList>
        <BodyWrapper>
          {userValue.length > 0
            ? userValue.map((user) => {
                return (
                  <ListWrapper data-testid={`add-team-member-user-${user.value}`}>
                    <ListButton
                      text={user.label}
                      onDeleteClose={() => {
                        setUserValue((prevUserValue) =>
                          prevUserValue.filter((u) => u.value !== user.value),
                        )
                      }}
                    />
                  </ListWrapper>
                )
              })
            : null}
        </BodyWrapper>
      </FormWrapper>
    </Modal>
  )
}

export default ModalAddTeamMember
