import { useLocation, useNavigate } from 'react-router-dom'
import CONSTANT from '@/constant'

const TaTransactionOrderHeaderHook = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goBack = () => {
    navigate(CONSTANT.ROUTES.TA_TRANSACTION_PAGE, {
      state: {
        data: location.state?.data,
      },
    })
  }

  return { goBack }
}

export default TaTransactionOrderHeaderHook
