import { Container, Modal, Text } from './styles'
import ResetWatchDeviceModalHook from './ResetWatchDeviceModal.hook'

export default function ResetWatchDeviceModal() {
  const { resetWatchDeviceModal, handleSetResetWatchDeviceModal, onCancelModal } =
    ResetWatchDeviceModalHook()

  return (
    <Modal
      title="คุณต้องการรีเซ็ทการเชื่อมต่อนาฬิกาออกกำลังกายของลูกค้า?"
      cancelText="ยกเลิก"
      okText="รีเซ็ท"
      open={resetWatchDeviceModal.open}
      closable={false}
      onCancel={() => onCancelModal()}
      onOk={() => handleSetResetWatchDeviceModal()}
      width="400px"
      data-testid="reset-smartwatches-device-modal"
    >
      <Container>
        <Text>
          โปรดแจ้งลูกค้าหลังคุณกด “รีเซ็ทอุปกรณ์” ให้ลูกค้า เปิดแอป “Thaivivat”
          อีกครั้งกดไปที่ส่วนการ์ดแสดง ข้อมูลการออกกำลังกายเพื่อทำการเลือกนาฬิกาที่เชื่อมต่ออีกครั้ง
        </Text>
      </Container>
    </Modal>
  )
}
