import styled from '@emotion/styled'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { ContainerProps } from './types'

export const Container = styled('div')<ContainerProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export const SearchLogo = styled('img')`
  width: 80px;
  height: 80px;
`

export const Title = styled('div')`
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  margin: 20px 0px;
`

export const Search = styled(Input.Search)`
  max-width: 696px;
  margin-bottom: 32px;

  .ant-input {
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 0 4px 4px 0 !important;
  }

  .ant-input-group-addon {
    .ant-input-search-button {
      display: none;
    }
  }
`

export const NotFoundContainer = styled('div')`
  margin-top: 72px;
  text-align: center;
`

export const NotFoundIcon = styled('img')``

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`
