import BuddhistDatePicker from '@/components/common/BuddhistDatePicker/BuddhistDatePicker'
import styled from '@emotion/styled'
import { Form, Input as FormInput, DatePicker as AntDatePicker, Button } from 'antd'

export const Container = styled('div')`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
`

export const Input = styled(FormInput)`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
`

export const FormItem = styled(Form.Item)`
  margin-bottom: 16px;

  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0 0 4px;
    white-space: initial;
    text-align: start;
  }
`

export const AntdTextArea = styled(FormInput.TextArea)`
  min-height: 104px !important;
`

export const Span = styled('span')`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

export const RangePicker = styled(AntDatePicker.RangePicker)``

export const PopUpFormItem = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
`

export const ImgWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-content: center;
`

export const DviWrapper = styled('div')`
  position: relative;
`

export const Image = styled('img')`
  width: 200px;
  height: 433.07px;
`

export const PreviewImage = styled('img')`
  position: absolute;
  top: 150px;
  left: 25px;
  width: 150px;
  height: 150px;
`

export const BoostPointIcon = styled('img')`
  position: absolute;
  /* top: 360px;
  left: 228px; */
  top: 226px;
  left: 16px;
  width: 8px;
  height: 8px;
`

export const PreviewText = styled('p')`
  position: absolute;
  top: 222px;
  left: 28px;
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 12px;
  color: white;
`

export const ButtonPopUp = styled(Button)`
  color: #009640;
  border-color: #009640;
`

export const BuddhistDatePickerStyled = styled(BuddhistDatePicker.RangePicker)`
  width: 100%;
`
