import styled from '@emotion/styled'
import { HandleDisplayPolicyStatusProps } from './types'
import { PolicySuperAppStatusEnum } from '@/constant/POLICY_SUPER_APP_STATUS'

const handleDisplayPolicyStatusColor = ({
  policySuperAppStatus,
  property,
}: HandleDisplayPolicyStatusProps) => {
  const titlePolicyStatuses = [
    {
      label: 'A',
      background: '#E5F4EB',
      color: '#009640',
    },
    {
      label: 'C',
      background: '#FEF4ED',
      color: '#FCB785',
    },
    {
      label: 'E',
      background: '#FCEDED',
      color: '#EB8181',
    },
    {
      label: 'F',
      background: '#EEF5FD',
      color: '#8DB9F1',
    },
    {
      label: 'P',
      background: '#E5F4EB',
      color: '#83D4A0',
    },
    {
      label: 'S',
      background: '#FEF4ED',
      color: '#FCB785',
    },
  ]

  // if not found `policySuperAppStatus` will return default color
  if (!policySuperAppStatus && property === 'background') return '#FCEDED'

  if (!policySuperAppStatus && property === 'color') return '#EB8181'

  for (const titlePolicyStatus of titlePolicyStatuses) {
    if (titlePolicyStatus.label === policySuperAppStatus && property === 'background') {
      return titlePolicyStatus.background
    }

    if (titlePolicyStatus.label === policySuperAppStatus && property === 'color') {
      return titlePolicyStatus.color
    }
  }
}

export const PolicyStatus = styled('div')<{
  policySuperAppStatus: PolicySuperAppStatusEnum | null
}>`
  background: ${(props) =>
    handleDisplayPolicyStatusColor({
      policySuperAppStatus: props.policySuperAppStatus,
      property: 'background',
    })};
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) =>
    handleDisplayPolicyStatusColor({
      policySuperAppStatus: props.policySuperAppStatus,
      property: 'color',
    })};
  display: flex;
  justify-content: center;
  padding: 6px 12px;
`
