import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeFAQInfoesByFAQCategoryIdDataInterface,
  BackofficeFAQInfoesByFAQCategoryIdInputInterface,
} from './interfaces'

const backofficeFAQInfoesByFAQCategoryId: TypedDocumentNode<
  BackofficeFAQInfoesByFAQCategoryIdDataInterface,
  BackofficeFAQInfoesByFAQCategoryIdInputInterface
> = gql`
  query BackofficeFAQInfoesByFAQCategoryId($input: GetBackofficeFAQInfoActiveHealthInput!) {
    backofficeFAQInfoesByFAQCategoryId(input: $input) {
      id
      faqCategoryId
      title
      description
      status
      createAt
      order
      createBy {
        id
        lastname
        firstname
      }
    }
  }
`

export default backofficeFAQInfoesByFAQCategoryId
