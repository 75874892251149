import styled from '@emotion/styled'

export const Container = styled('div')`
  width: 234px;
`

export const Block = styled('div')`
  margin-bottom: 24px;
`

export const Title = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-bottom: 4px;
`

export const Information = styled('p')`
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  margin-bottom: 4px;
`

export const Icon = styled('img')`
  width: 13px;
`
