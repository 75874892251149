import styled from '@emotion/styled'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
`

export const SubContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

export const Title = styled('div')`
  font-size: 32px;
  color: #666666;
  margin-bottom: 16px;
`

export const Button = styled(CommonButton)`
  background-color: #009640;
  color: #ffffff;
`
