import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeUpdateUserHelpDataInterface,
  BackofficeUpdateUserHelpInputInterface,
} from './interfaces'

const backofficeUpdateUserHelp: TypedDocumentNode<
  BackofficeUpdateUserHelpDataInterface,
  BackofficeUpdateUserHelpInputInterface
> = gql`
  mutation BackofficeUpdateUserHelp($input: BackofficeUpdateUserHelpInput!) {
    backofficeUpdateUserHelp(input: $input) {
      id
      createdAt
      type
      phone
      remark
      user {
        firstname
        lastname
      }
      status
      receivedBy {
        firstname
        lastname
      }
      receivedAt
      successfulBy {
        firstname
        lastname
      }
      successfulAt
    }
  }
`

export default backofficeUpdateUserHelp
