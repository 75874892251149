import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeSearchUserDataInterface, BackofficeSearchUserInputInterface } from './interfaces'

export const backofficeSearchUser: TypedDocumentNode<
  BackofficeSearchUserDataInterface,
  BackofficeSearchUserInputInterface
> = gql`
  query BackofficeSearchUser($input: BackofficeSearchUserInput!) {
    backofficeSearchUser(input: $input) {
      id
      thaiCitizen {
        identityId
      }
      foreignCitizen {
        passportId
      }
      firstname
      lastname
      email
      isConfirmEmail
      mobileNumber
      createdAt
      deleteRequestedAt
    }
  }
`

export default backofficeSearchUser
