import styled from '@emotion/styled'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export const Container = styled('div')`
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 32px;
  margin: auto;
  width: 100%;
  position: relative;
  user-select: none;
`

export const SearchInput = styled(Input)`
  width: 239px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 24px;
`

export const Title = styled('p')`
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  margin-bottom: 24px;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`
