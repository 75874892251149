import React from 'react'

import {
  Container,
  Title,
  DropzoneContainer,
  UploadFileIcon,
  UploadFileTitle,
  UploadFileSubtitle,
  ProgressContainer,
  ProgressInfoBlock,
  FileName,
  DeleteIcon,
  ImportButton,
  ImportButtonContainer,
  FailIcon,
  UploadFailContainer,
} from './styles'
import DeviceImportCardHook from './DeviceImportCard.hook'
import ResultImportCard from '../ResultImportCard'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { IMAGE_URL } from '@/constant/IMAGE'

function DeviceImportCard() {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    file,
    handleRemoveFile,
    onModalVisible,
    deviceImport,
    isUploadFail,
    user,
    isLoading,
  } = DeviceImportCardHook()

  return (
    <Container>
      <Title>ผูกอุปกรณ์ TVI-Connect</Title>
      <DropzoneContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <UploadFileIcon src={IMAGE_URL.uploadIcon} />
        <UploadFileTitle>เลือกหรือลากวางไฟล์ที่ต้องการ</UploadFileTitle>
        <UploadFileSubtitle>.CSV เท่านั้น</UploadFileSubtitle>
      </DropzoneContainer>
      {!deviceImport.isUpload && file.name && (
        <ProgressContainer>
          <ProgressInfoBlock>
            {file.name && <FileName>{file.name}</FileName>}
            {file.name && <DeleteIcon src={IMAGE_URL.trashIcon} onClick={handleRemoveFile} />}
          </ProgressInfoBlock>
        </ProgressContainer>
      )}
      {isUploadFail ? (
        <ProgressContainer>
          <ProgressInfoBlock>
            <UploadFailContainer>
              <FailIcon src={IMAGE_URL.failIcon} />
              <div>Failed to upload</div>
            </UploadFailContainer>
          </ProgressInfoBlock>
        </ProgressContainer>
      ) : undefined}
      {deviceImport.isUpload && <ResultImportCard />}
      {validatePerm([BACKOFFICE_USER_PERMISSION.MTD010101], user.permissions) ? (
        <ImportButtonContainer>
          <ImportButton
            disabled={!(!deviceImport.isUpload && file.name)}
            type="primary"
            onClick={onModalVisible}
            loading={isLoading}
          >
            Import
          </ImportButton>
        </ImportButtonContainer>
      ) : undefined}
    </Container>
  )
}

export default DeviceImportCard
