import { useContext } from 'react'
import { useRecoilState } from 'recoil'

import userAtom from '@/recoil/user'
import { UserAuthContext } from '@/contexts'

function LoginSsoHook() {
  const [user] = useRecoilState(userAtom)
  const { login } = useContext(UserAuthContext)

  return {
    user,
    login,
  }
}

export default LoginSsoHook
