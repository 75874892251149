import { atom } from 'recoil'
import { WelcomeCallInformationType } from './types'

const initWelcomeCalInformation: WelcomeCallInformationType = {
  welcomeCall: {
    index: null,
    policyNumber: null,
    referenceNumber: null,
    insuredName: null,
    phoneNumber: null,
    planCode: null,
    address: null,
    remarkPolicy: null,
    effectiveDate: null,
    purchaseChannel: null,
    paymentMethod: null,
    watchDeliveredDate: null,
    watchDeliveredBy: null,
    watchDeliveredTrackingNo: null,
    watchSerialNumber: null,
    callStatus: null,
    calledBy: undefined,
    remark: null,
    createdAt: null,
    updatedAt: null,
  },
  isCallStatusChange: false,
}

const welcomeCallInformationAtom = atom({
  key: 'welcomeCallInformationAtom',
  default: initWelcomeCalInformation,
})

export default welcomeCallInformationAtom
