import { LoadingOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Tabs as AntdTabs, Spin } from 'antd'

export const Container = styled('div')`
  width: 100%;
`

export const TitleWrapper = styled('div')`
  margin: 24px 0px;
  align-items: center;
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 24px;
`

export const SubTitleWrapper = styled('div')`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  margin: 15px 0px;
`

export const TitleText = styled('div')`
  display: flex;
  color: #666666;
`

export const TitleValue = styled('div')`
  color: #000000;
  margin: 0px 5px;
`

export const Tabs = styled(AntdTabs)`
  .ant-tabs-tab {
    padding: 0px 0px 14px 0px;
  }

  .ant-tabs-nav {
    margin: 0 0 24px 0;
  }
`

export const LoadingContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
`

export const SpinAntd = styled(Spin)``

export const IconLoading = styled(LoadingOutlined)``
