import { Skeleton } from 'antd'
import { toThaiLocaleSlashDate } from '@/libs/date'
import CampaignPeriodHook from './CampaignPeriod.hook'
import {
  Container,
  Title,
  Block,
  PeriodTitle,
  Period,
  PeriodDate,
  Dash,
  PeriodBlock,
} from './styles'

function CampaignPeriod() {
  const { recentBonustCriteriaData, recentBonusCriteriaLoading } = CampaignPeriodHook()
  return (
    <Container>
      <Block>
        <Title>ระยะเวลาแคมเปญ</Title>
        <PeriodBlock>
          <Period>
            <PeriodTitle>เริ่มมีผลตั้งแต่</PeriodTitle>
            {recentBonusCriteriaLoading ? (
              <Skeleton.Button active={true} style={{ width: '100px' }} />
            ) : (
              <PeriodDate>
                {recentBonustCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria.startDate
                  ? toThaiLocaleSlashDate(
                      recentBonustCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria
                        .startDate as Date,
                    )
                  : '00/00/0000'}
              </PeriodDate>
            )}
          </Period>
          <Dash>-</Dash>
          <Period>
            <PeriodTitle>สิ้นสุด</PeriodTitle>
            {recentBonusCriteriaLoading ? (
              <Skeleton.Button active={true} style={{ width: '100px' }} />
            ) : (
              <PeriodDate>
                {recentBonustCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria.endDate
                  ? toThaiLocaleSlashDate(
                      recentBonustCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria
                        .endDate as Date,
                    )
                  : '00/00/0000'}
              </PeriodDate>
            )}
          </Period>
        </PeriodBlock>
      </Block>
      <Block>
        <div />
      </Block>
    </Container>
  )
}

export default CampaignPeriod
