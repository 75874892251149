import backofficeAdjustBonusPoint from './backofficeAdjustBonusPoint/backofficeAdjustBonusPoint.gql'
import backofficeGetBonusPointPartnerBranch from './backofficeGetBonusPointPartnerBranch/backofficeGetBonusPointPartnerBranch.gql'
import backofficeGetAdjustBonusPointAndPromotionActivity from './backofficeGetAdjustBonusPointAndPromotionActivity/backofficeGetBonusPointAndPromotionActivity.gql'

const activeHealthBonusPoint = {
  query: {
    backofficeGetBonusPointPartnerBranch,
    backofficeGetAdjustBonusPointAndPromotionActivity,
  },
  mutation: {
    backofficeAdjustBonusPoint,
  },
}

export default activeHealthBonusPoint
