import { gql, TypedDocumentNode } from '@apollo/client'
import {
  GetAllUserPoliciesByIdentityIdDataInterface,
  GetAllUserPoliciesByIdentityIdInputInterface,
} from './interfaces'

const getAllUserPoliciesByIdentityId: TypedDocumentNode<
  GetAllUserPoliciesByIdentityIdDataInterface,
  GetAllUserPoliciesByIdentityIdInputInterface
> = gql`
  query GetAllUserPoliciesByIdentityId($identityId: String!) {
    getAllUserPoliciesByIdentityId(input: $identityId) {
      policyNumber
      policyGroup
      planName
      campaignName
      vehicleNumber
      policySuperAppStatus
    }
  }
`

export default getAllUserPoliciesByIdentityId
