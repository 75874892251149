import styled from '@emotion/styled'

import { ContainerProps, HeaderContainerProps } from './types'

export const Container = styled('div')<ContainerProps>`
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 32px;
  height: 100%;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`
export const HeaderContainer = styled('div')<HeaderContainerProps>`
  height: 65px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 24px;
  justify-content: space-between;
  align-items: center;
  display: ${(props) => (props.isFlex ? 'flex' : 'block')};
`

export const Title = styled('div')`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`
export const LeftItem = styled('div')``

export const RightItem = styled('div')``
