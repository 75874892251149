import { useRecoilState } from 'recoil'

import deviceImportAtom from '@/recoil/deviceImport'

function ResultImportCardHook() {
  const [deviceImport] = useRecoilState(deviceImportAtom)

  return {
    deviceImport,
  }
}

export default ResultImportCardHook
