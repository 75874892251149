import PolicyDetailPage from '@/components/feature/PolicyDetailPage/PolicyDetailPage'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import UnauthorizedPage from '@/pages/Unauthorized'

function PolicyDetail() {
  const user = useRecoilValue(userAtom)

  return (
    <>
      {validatePerm(
        [BACKOFFICE_USER_PERMISSION.CUS010101, BACKOFFICE_USER_PERMISSION.MTT010101],
        user.permissions,
      ) ? (
        <PolicyDetailPage />
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default PolicyDetail
