import { Container } from './styles'
import ResetPasswordForm from '@/components/feature/ResetPasswordPage/ResetPasswordForm'
import SuccessfullyResetPasswordCard from '@/components/feature/ResetPasswordPage/SuccessfullyResetPasswordCard'

function ResetPassword() {
  return (
    <Container>
      <ResetPasswordForm />
      <SuccessfullyResetPasswordCard />
    </Container>
  )
}

export default ResetPassword
