import { Navigate, useLocation } from 'react-router-dom'

import {
  Container,
  Title,
  Form,
  FormItem,
  Button,
  FormRight,
  FormRightLoginLogo,
  AppVersion,
  AppVersionContainer,
  KeyIcon,
} from './styles'
import config from '@/config/config'
import LoginSsoHook from './LoginSso.hook'
import { IMAGE_URL } from '@/constant/IMAGE'

function LoginSso() {
  const { user, login } = LoginSsoHook()
  const location = useLocation()
  const state = location.state as { referrer?: string }
  const referrer = state?.referrer || '/'

  if (user.isAuth) {
    return <Navigate to={{ pathname: referrer }} state={{ from: location }} />
  }

  return (
    <Container>
      <Form onFinish={login} data-testid="login-form">
        <Title>Thaivivat backoffice</Title>
        <FormItem>
          <Button
            icon={<KeyIcon src={IMAGE_URL.keyIcon} />}
            type="primary"
            htmlType="submit"
            data-testid="login-submit-button"
          >
            เข้าสู่ระบบ SSO
          </Button>
        </FormItem>
      </Form>
      <FormRight backgroundImage={IMAGE_URL.backgroundSsoPicture}>
        <FormRightLoginLogo src={IMAGE_URL.loginLogoIcon} />
        <AppVersionContainer>
          <AppVersion>v.{config.appVersion}</AppVersion>
        </AppVersionContainer>
      </FormRight>
    </Container>
  )
}

export default LoginSso
