import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ScatterDataPoint,
  BubbleDataPoint,
  ChartOptions,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
// import { faker } from '@faker-js/faker'

// library from https://react-chartjs-2.js.org/

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// example options
// const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: true,
//       position: 'top' as const,
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Bar Chart',
//     },
//   },
// }

// example labels
// const labels = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
// ]

// example data
// const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 20000 })),
//       backgroundColor: '#009640',
//     },
//   ],
// }

export default function VerticalBarChart({
  data,
  options,
}: {
  data: ChartData<
    'bar',
    (number | [number, number] | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  >
  options: ChartOptions<'bar'>
}) {
  return <Bar data={data} options={options} />
}
