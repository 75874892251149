import styled from '@emotion/styled'
import { Form, Input as FormInput, Select as AntSelect } from 'antd'

export const FormItem = styled(Form.Item)`
  margin-bottom: 16px;

  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0 0 4px;
    white-space: initial;
    text-align: start;
  }

  .ant-form-item-explain {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.004em;
    color: #666666;
    margin-top: 4px;
    margin-bottom: 16px;
  }
`

export const Input = styled(FormInput)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
`

export const InputEmail = styled(FormInput)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
`

export const FormItemWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 16px;
`

export const Remark = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

export const Select = styled(AntSelect)`
  .ant-select-selector {
    height: 40px !important;
    padding: 10px 16px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
`
