import { TypedDocumentNode, gql } from '@apollo/client'
import {
  BackofficeGetQrByActiveHealthPartnerBranchIdDataInterface,
  BackofficeGetQrByActiveHealthPartnerBranchIdInputInterface,
} from './interface'

const backofficeGetQrByActiveHealthPartnerBranchId: TypedDocumentNode<
  BackofficeGetQrByActiveHealthPartnerBranchIdDataInterface,
  BackofficeGetQrByActiveHealthPartnerBranchIdInputInterface
> = gql`
  query BackofficeGetQrByActiveHealthPartnerBranchId($input: GetQrByActiveHealthPartnerInput!) {
    backofficeGetQrByActiveHealthPartnerBranchId(input: $input) {
      id
      isActive
      imageUrl
      module
    }
  }
`

export default backofficeGetQrByActiveHealthPartnerBranchId
