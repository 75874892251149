import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeChangeTviConnectSimMainPackageDataInterface,
  BackofficeChangeTviConnectSimMainPackageInputInterface,
} from './interfaces'

const backofficeChangeTviConnectSimMainPackage: TypedDocumentNode<
  BackofficeChangeTviConnectSimMainPackageDataInterface,
  BackofficeChangeTviConnectSimMainPackageInputInterface
> = gql`
  mutation BackofficeChangeTviConnectSimMainPackage($id: String!) {
    backofficeChangeTviConnectSimMainPackage(id: $id) {
      isSuccessful
      message
      reason
    }
  }
`

export default backofficeChangeTviConnectSimMainPackage
