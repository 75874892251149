import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useMutation } from '@apollo/client'
import type { MenuProps } from 'antd'
import message from '@/libs/message'
import welcomeCallInformationAtom from '@/recoil/welcomeCallInformation'
import userAtom from '@/recoil/user'
import { welcomeCallGql } from '@/gql'
import { CALL_TYPE, CallTypeEnum } from '@/constant/CALL_TYPE'

function WelcomeCallInformationHeaderHook() {
  const [{ welcomeCall }, setWelcomeCallInformation] = useRecoilState(welcomeCallInformationAtom)
  const user = useRecoilValue(userAtom)
  const [
    updateWelcomeCall,
    {
      error: updateWelcomeCallError,
      data: updateWelcomeCallData,
      loading: updateWelcomeCallLoading,
    },
  ] = useMutation(welcomeCallGql.mutation.backofficeUpdateWelcomeCall)
  const items: MenuProps['items'] = [
    {
      label: CALL_TYPE[CallTypeEnum.PENDING],
      key: CallTypeEnum.PENDING,
    },
    {
      label: CALL_TYPE[CallTypeEnum.NOT_RECEIVE],
      key: CallTypeEnum.NOT_RECEIVE,
    },
    {
      label: CALL_TYPE[CallTypeEnum.BUSY],
      key: CallTypeEnum.BUSY,
    },
    {
      label: CALL_TYPE[CallTypeEnum.CALLED],
      key: CallTypeEnum.CALLED,
    },
    {
      label: CALL_TYPE[CallTypeEnum.NOT_AVAILABLE],
      key: CallTypeEnum.NOT_AVAILABLE,
    },
  ]
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key && welcomeCall.policyNumber) {
      updateWelcomeCall({
        variables: {
          input: {
            policyNumber: welcomeCall.policyNumber,
            callStatus: e.key as CallTypeEnum,
            calledBy: user.id,
          },
        },
      })
    }
  }

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick,
  }

  useEffect(() => {
    if (updateWelcomeCallError) {
      message.error({ content: 'เปลี่ยนแปลงข้อมูล Welcome Call ไม่สำเร็จ' })
    }
  }, [updateWelcomeCallError])

  useEffect(() => {
    if (updateWelcomeCallData?.backofficeUpdateWelcomeCall) {
      setWelcomeCallInformation({
        welcomeCall: updateWelcomeCallData.backofficeUpdateWelcomeCall,
        isCallStatusChange: true,
      })

      message.success({ content: 'เปลี่ยนแปลงข้อมูล Welcome Call สำเร็จ' })
    }
  }, [updateWelcomeCallData])

  return { welcomeCall, menuProps, user, updateWelcomeCallLoading }
}

export default WelcomeCallInformationHeaderHook
