import { KeyboardEvent } from 'react'

export const CLASS_NAME_EDIT = 'edit'
export const CLASS_NAME_MOVE = 'move'

export type CategoryType = {
  id: string
  text: string
  isSelect: boolean
  action?: CategoryActionEnum
}

export enum CategoryActionEnum {
  READ = 'read',
  EDIT = 'edit',
}

export type CategoryProps = {
  value?: string
  onChange?: Function
  isSelect?: boolean
  action?: CategoryActionEnum
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
  onEditCategory?: () => void
  onSelected?: () => void
  onDelete?: () => void
  onMouseLeave?: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  canDelete?: boolean
  canEdit?: boolean
  index?: number
}
