import styled from '@emotion/styled'
import { Dropdown as AntdDropdown } from 'antd'
import { CaretDownOutlined as AntdCaretDownOutlined } from '@ant-design/icons'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')``

export const Title = styled('div')`
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin-bottom: 4px;
`

export const Subtitle = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-bottom: 4px;
`

export const Dropdown = styled(AntdDropdown)``

export const Button = styled(CommonButton)<{ success: boolean }>`
  padding: 6px 12px;
  background: ${(props) => (props.success ? '#009640' : '#e1e2e7')};
  color: ${(props) => (props.success ? '#ffffff' : '#000000')};
  border-radius: 4px;
  border: unset;
  height: 32px;
  font-weight: 500;
`

export const DownArrow = styled(AntdCaretDownOutlined)``

export const FooterContainer = styled('span')`
  font-size: 10px;
  height: 32px;
  display: inline-flex;
  align-items: center;
`

export const CalledByContainer = styled('div')`
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
`

export const CalledByTitle = styled('span')`
  color: #666666;
`

export const CalledByText = styled('span')`
  color: #000000;
`
