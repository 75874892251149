import { useEffect, useState } from 'react'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { SorterResult, SortOrder } from 'antd/lib/table/interface'
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs'
import { formatThaiLocaleDateRange } from '@/libs/date'
import { useReturnTimePagination } from '@/services/returnTime/useReturnTimePagination'
import { ActionText } from './styles'
import { ReturnTimeTopicEnum } from '@/constant/RETURN_TIME'
import { SorterEnum } from '@/constant/USER'

const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()

const ReturnTimeTableHook = () => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [selectPpuMotorReturnTimeId, setSelectPpuMotorReturnTimeId] = useState(null)
  const returnTime = useReturnTimePagination({
    page,
    limit,
    search,
    sortBy,
  })
  const columns: ColumnsType<{}> = [
    {
      title: 'ลำดับ',
      width: 75,
      align: 'center',
      render: (_value, _record, index) => index + 1,
    },
    {
      title: 'เลขที่กรมธรรม์',
      dataIndex: 'policyNumber',
    },
    {
      title: 'ทะเบียนรถ',
      dataIndex: 'carLicenseNumber',
    },
    {
      title: 'ชื่อผู้เอาประกัน',
      dataIndex: 'fullname',
    },
    {
      title: 'วันที่ลูกค้าใช้งานรถที่มีการคืนชั่วโมงให้',
      render: (value) => {
        const isDateRange = !!(value.minDateOfReturn && value.maxDateOfReturn)

        return isDateRange
          ? formatThaiLocaleDateRange(
              new Date(value.minDateOfReturn),
              new Date(value.maxDateOfReturn),
            )
          : formatThaiLocaleDateRange(new Date(value.returnedDate))
      },
    },
    {
      title: 'วันที่ได้รับการคืนชั่วโมง',
      dataIndex: 'returnedDate',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      render: (value) => {
        return formatThaiLocaleDateRange(new Date(value))
      },
    },
    {
      title: 'ประเภทการคืนชั่วโมง',
      dataIndex: 'topic',
      render: (value) => {
        return value === ReturnTimeTopicEnum.TRAFFIC_NO_PREMIUM ? 'รถติดไม่คิดเบี้ย' : 'คืนตามปกติ'
      },
    },
    {
      title: 'วิธีการคืน',
      dataIndex: 'type',
      render: (value) => {
        return value.toLocaleLowerCase()
      },
    },
    {
      title: 'จำนวนชั่วโมงที่ได้คืน',
      dataIndex: 'minutesReturned',
      render: (value) => {
        return `${value} นาที`
      },
    },
    {
      title: '',
      width: 150,
      align: 'center',
      dataIndex: ['id'],
      render: (id) => {
        return (
          <ActionText onClick={() => setSelectPpuMotorReturnTimeId(id)}>ดูชั่วโมงที่คืน</ActionText>
        )
      },
    },
  ]

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    inputChange.next(value)
  }

  const handleSortBy = (field?: React.Key | readonly React.Key[], order?: SortOrder) => {
    switch (order) {
      case SorterEnum.ASC:
        return `${field}:ASC`
      case SorterEnum.DESC:
        return `${field}:DESC`
      default:
        return ''
    }
  }

  const onTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<{}> | SorterResult<{}>[],
  ) => {
    const { current, pageSize } = pagination
    const sort = Array.isArray(sorter) ? sorter[0] : sorter
    const { field, order } = sort || { field: '', order: '' }
    const sortBy = handleSortBy(field, order)

    setPage(current || 1)
    setLimit(pageSize || 10)
    setSortBy(sortBy)
  }

  const closeModal = () => {
    setSelectPpuMotorReturnTimeId(null)
  }

  useEffect(() => {
    const subscription = inputChange$
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        setSearch(value)
        setPage(1)
      })

    return () => {
      inputChange.next('')
      return subscription.unsubscribe()
    }
  }, [])

  return {
    columns,
    returnTime,
    onTableChange,
    onSearch,
    closeModal,
    search,
    selectPpuMotorReturnTimeId,
  }
}

export default ReturnTimeTableHook
