import { gql, TypedDocumentNode } from '@apollo/client'
import {
  CreateActiveHealthSpecialPointDataInterface,
  CreateActiveHealthSpecialPointInputInterface,
} from './interfaces'

export const backofficeCreateActiveHealthSpecialPoint: TypedDocumentNode<
  CreateActiveHealthSpecialPointDataInterface,
  CreateActiveHealthSpecialPointInputInterface
> = gql`
  mutation BackofficeCreateActiveHealthSpecialPoint($input: CreateActiveHealthSpecialPointInput!) {
    backofficeCreateActiveHealthSpecialPoint(input: $input) {
      code
      message
      data
    }
  }
`

export default backofficeCreateActiveHealthSpecialPoint
