import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeListActiveHealthPolicyIncludeWatchBranchDataInterface,
  BackofficeListActiveHealthPolicyIncludeWatchBranchInputInterface,
} from './type'

const backofficeListActiveHealthPolicyIncludeWatchBranch: TypedDocumentNode<
  BackofficeListActiveHealthPolicyIncludeWatchBranchDataInterface,
  BackofficeListActiveHealthPolicyIncludeWatchBranchInputInterface
> = gql`
  query BackofficeListActiveHealthPolicyIncludeWatchBranch($userId: ID!, $policyNumber: String!) {
    backofficeListActiveHealthPolicyIncludeWatchBranch(
      userId: $userId
      policyNumber: $policyNumber
    ) {
      id
      watchBrand {
        name
      }
      watchModel
      watchSerialNumber
      watchLastSyncedAt
    }
  }
`

export default backofficeListActiveHealthPolicyIncludeWatchBranch
