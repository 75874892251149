import { atom } from 'recoil'

import { TviConnectSimMainPackageInterface } from '@/gql/ppuMotor/backofficeGetTviConnectCurrentSimMainPackage/interfaces'

const initTviConnectMainSimPackage: TviConnectSimMainPackageInterface = {
  status: null,
  deviceType: null,
  serialNumber: null,
  checksumDigit: null,
  partner: null,
  packageName: null,
}

const tviConnectMainSimPackageAtom = atom({
  key: 'tviConnectMainSimPackageAtom',
  default: initTviConnectMainSimPackage,
})

export default tviConnectMainSimPackageAtom
