import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import userAtom from './recoil/user'
import { useLazyQuery } from '@apollo/client'
import { backofficeUserGql } from '@/gql'
import { useNavigate } from 'react-router-dom'
import CONSTANT from '@/constant'
import { UserAuthHook } from './hooks'
import { InteractionStatus } from '@azure/msal-browser'

function AppHook() {
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userAtom)
  const [isBootstrapping, setIsBootstrapping] = useState(true)
  const [
    backofficeGetMe,
    { data: backofficeGetMeData, loading: backofficeLoading, error: backofficeGetMeError },
  ] = useLazyQuery(backofficeUserGql.query.backofficeGetMe, { fetchPolicy: 'no-cache' })

  const { inProgress, isAuthenticated } = UserAuthHook()

  useEffect(() => {
    const shouldBootstrap = inProgress === InteractionStatus.None

    if (shouldBootstrap) {
      if (!backofficeLoading) {
        bootstrapApp()
      } else {
        loadingBootstrapApp()
      }
    }
  }, [inProgress, backofficeLoading])

  useEffect(() => {
    if (!user.isAuth && isAuthenticated) {
      backofficeGetMe()
    }
  }, [user.isAuth, isAuthenticated, backofficeGetMe])

  useEffect(() => {
    if (backofficeGetMeData && backofficeGetMeData.backofficeGetMe) {
      const {
        email,
        firstname,
        lastname,
        id,
        menus,
        menuTypes,
        pages,
        permissions,
        backofficeTeams,
      } = backofficeGetMeData.backofficeGetMe
      setUser({
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        email,
        firstname,
        id,
        lastname,
        menuTypes,
        menus,
        pages,
        permissions,
        staffId: id,
        isAuth: true,
        backofficeTeams,
      })
    }
  }, [backofficeGetMeData, setUser])

  useEffect(() => {
    if (backofficeGetMeError) {
      navigate(CONSTANT.PATHNAME.LOGIN)
      bootstrapApp()
    }
  }, [backofficeGetMeError, navigate])

  const bootstrapApp = () => {
    setIsBootstrapping(false)
  }

  const loadingBootstrapApp = () => {
    setIsBootstrapping(true)
  }

  return {
    isBootstrapping,
  }
}

export default AppHook
