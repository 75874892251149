import styled from '@emotion/styled'
import { Skeleton as AntdSkeleton } from 'antd'

export const Container = styled('div')``

export const Title = styled('p')`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.004em;
  color: #000000;
  margin-bottom: 4px;
`

export const DiscountBadge = styled('div')<{ discount: boolean }>`
  padding: 2px 4px;
  background: ${(props) => (props.discount ? '#009640' : '#C2C2C2')};
  border-radius: 4px;
  color: white;
  font-weight: 400;
  font-size: 12px;
  max-width: 83px;
  margin-bottom: 20px;
`

export const PointBlock = styled('div')`
  display: flex;
  align-items: center;
`

export const PointIcon = styled('img')`
  margin-right: 12px;
`

export const PointText = styled('span')`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
`

export const ScoreText = styled('p')`
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
`

export const Block = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
`

export const WeeklyTitle = styled('p')`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.004em;
  color: #666666;
  margin-bottom: 4px;
`

export const WeeklyDetailBlock = styled('div')`
  background-color: #fafafa;
  margin: -24px -24px 0 -24px;
  padding: 24px 24px 4px 24px;
`

export const ConditionBlock = styled('div')`
  margin-bottom: 16px;
`

export const BlueFlagIcon = styled('img')`
  margin-right: 5.5px;
`

export const ConditionText = styled('span')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const ActivityRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 14px 24px 14px 24px;
  margin: 0 -24px 0 -12px;
`

export const ActivityTitle = styled('p')`
  margin-top: 24px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #000000;
`

export const ActivityTextBlock = styled('div')``

export const ActivityDate = styled('p')`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
`

export const ActivityInformation = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: #adadad;
  margin-top: 4px;
`

export const Skeleton = styled(AntdSkeleton)`
  margin: 4px 0;
`

export const ActiveHealthScoreBlock = styled('div')`
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
`

export const ExercisePointSubBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: "12px, 16px, 12px, 16px"
`

export const WellPointSubBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  margin: 17px -16px 0px -16px;
  padding: 12px 12px 0px 12px;
`

export const WellPointSubIcon = styled('div')`
  margin-left: auto;
  margin-right: 30px;
`
