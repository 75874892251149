import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const TableStyles = css`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
`

export const ActionText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #009640;
  cursor: pointer;
`
