const PATHNAME = {
  LOGIN: 'login',
  USER: 'user',
  CREATE: 'create',
  UPDATE: 'update',
  PERMISSION: 'permission',
  TVI_CONNECT: 'tvi-connect',
  POLICY_DETAIL: 'policy-detail',
  PASSWORD: 'password',
  FORGOT: 'forgot',
  RESET: 'reset',
  REPORT: 'report',
  STAR: '*',
  WELCOME_CALL: 'welcome-call',
  POLICY_MANAGEMENT: 'policy-management',
  DISCOUNT_ISSUE: 'discount-issue',
  WATCH_MANAGEMENT: 'watch-management',
  CAMPAIGN_MANAGEMENT: 'campaign-management',
  HELP: 'help',
  FREQUENCY_QUESTIONS: 'frequency-questions',
  EMAIL: 'email',
  CONFIRM_EMAIL: 'confirmemail',
  UNAUTHORIZED: 'unauthorized',
  WELL_BEING: 'well-being',
  EV_CHARGING_STATION: 'ev-charging-station',
  NOT_FOUND: 'notfound',
  TA_TRANSACTION: 'ta-transaction',
  RETURN_TIME: 'return-time',
}

const PARAMS = {
  SEARCH_INPUT: ':searchInput',
  USER_ID: ':userId',
  TOKEN: ':token',
  PARTNER_ID: ':partnerId',
  ORDER_ID: ':orderId',
}

const ROUTES = {
  LOGIN_PAGE: `/${PATHNAME.LOGIN}`,
  USER_NOT_FOUND_PAGE: `/${PATHNAME.USER}/${PATHNAME.NOT_FOUND}`,
  USER_PERMISSION_PAGE: `/${PATHNAME.USER}/${PATHNAME.PERMISSION}`,
  TVI_CONNECT_PAGE: `/${PATHNAME.TVI_CONNECT}`,
  POLICY_DETAIL_PAGE: `/${PATHNAME.POLICY_DETAIL}/${PARAMS.SEARCH_INPUT}`,
  PASSWORD_FORGOT_PAGE: `/${PATHNAME.PASSWORD}/${PATHNAME.FORGOT}`,
  PASSWORD_RESET_PAGE: `/${PATHNAME.PASSWORD}/${PATHNAME.RESET}/${PARAMS.TOKEN}`,
  REPORT_PAGE: `/${PATHNAME.REPORT}`,
  NOT_FOUND_PAGE: PATHNAME.STAR,
  WELCOME_CALL_PAGE: `/${PATHNAME.WELCOME_CALL}`,
  POLICY_MANAGEMENT_PAGE: `/${PATHNAME.POLICY_MANAGEMENT}`,
  DISCOUNT_ISSUE_PAGE: `/${PATHNAME.DISCOUNT_ISSUE}`,
  WATCH_MANAGEMENT_PAGE: `/${PATHNAME.WATCH_MANAGEMENT}`,
  CAMPAIGN_MANAGEMENT_PAGE: `/${PATHNAME.CAMPAIGN_MANAGEMENT}`,
  HELP_PAGE: `/${PATHNAME.HELP}`,
  FREQUENCY_QUESTIONS_PAGE: `/${PATHNAME.FREQUENCY_QUESTIONS}`,
  CONFIRM_EMAIL_PAGE: `/${PATHNAME.EMAIL}/${PATHNAME.CONFIRM_EMAIL}/${PARAMS.TOKEN}`,
  UNAUTHORIZED_PAGE: `/${PATHNAME.UNAUTHORIZED}`,
  WELL_BEING_PAGE: `/${PATHNAME.WELL_BEING}`,
  WELL_BEING_PARTNER_ID_PAGE: `/${PATHNAME.WELL_BEING}/${PARAMS.PARTNER_ID}`,
  EV_CHARGING_STATION_PAGE: `/${PATHNAME.EV_CHARGING_STATION}`,
  TA_TRANSACTION_PAGE: `/${PATHNAME.TA_TRANSACTION}`,
  TA_TRANSACTION_ORDER_ID_PAGE: `/${PATHNAME.TA_TRANSACTION}/${PARAMS.ORDER_ID}`,
  RETURN_TIME_PAGE: `/${PATHNAME.RETURN_TIME}`,
}

export { ROUTES, PATHNAME, PARAMS }
