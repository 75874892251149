import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeFAQInfoesDataInterface, BackofficeFAQInfoesInputInterface } from './interfaces'

const backofficeFAQInfoes: TypedDocumentNode<
  BackofficeFAQInfoesDataInterface,
  BackofficeFAQInfoesInputInterface
> = gql`
  query BackofficeFAQInfoes($input: SearchBackofficeFAQInfoActiveHealthInput!) {
    backofficeFAQInfoes(input: $input) {
      id
      faqCategoryId
      title
      description
      status
      createAt
      order
      createBy {
        id
        lastname
        firstname
      }
    }
  }
`

export default backofficeFAQInfoes
