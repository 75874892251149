import { ROUTES, PATHNAME, PARAMS } from './ROUTES'
import * as SEARCH_POLICY_BOX from './SEARCH_POLICY_BOX'
import * as SIDE_BAR from './SIDE_BAR'
import { SimStatusEnum } from './SIM_STATUS'
import { FontColorEnum } from './FONT_COLOR'
import { TviConnectDeviceTypeEnum } from './TVI_CONNECT_DEVICE_TYPE'
import { ActiveHealthPolicyMenuEnum } from './ACTIVE_HEALTH_POLICY_MENU'
import { PolicyGroupEnum } from './POLICY_GROUP'
import { PolicySuperAppStatusEnum, POLICY_SUPER_APP_STATUS } from './POLICY_SUPER_APP_STATUS'
import { BillingFilterEnum, BillingTabEnum } from './BILLING_FILTER'
import { BillingStatusEnum, BILLING_STATUS } from './BILLING_STATUS'
import { ActivityFilterEnum } from './ACTIVITY_FILTER'
import { ActivityPeriodDateFilterEnum } from './ACTIVITY_PERIOD_DATE_FILTER'
import { IMAGE_URL } from './IMAGE'
import { SortByEnum } from './SORT_BY'
import { WelcomeCallSortableFieldEnum } from './WELCOME_CALL'
import {
  ActiveHealthPolicySearchableFieldEnum,
  ActiveHealthPolicySortableFieldEnum,
  ActiveHealthPolicyStatusEnum,
  ACTIVE_HEALTH_POLICY_STATUS,
} from './ACTIVE_HEALTH_POLICY'

const CONSTANT = {
  ROUTES,
  PATHNAME,
  PARAMS,
  SEARCH_POLICY_BOX,
  SIDE_BAR,
  SimStatusEnum,
  FontColorEnum,
  TviConnectDeviceTypeEnum,
  ActiveHealthPolicyMenuEnum,
  PolicyGroupEnum,
  PolicySuperAppStatusEnum,
  POLICY_SUPER_APP_STATUS,
  BillingFilterEnum,
  BillingTabEnum,
  BillingStatusEnum,
  BILLING_STATUS,
  ActivityFilterEnum,
  ActivityPeriodDateFilterEnum,
  IMAGE_URL,
  SortByEnum,
  WelcomeCallSortableFieldEnum,
  ActiveHealthPolicySearchableFieldEnum,
  ActiveHealthPolicySortableFieldEnum,
  ActiveHealthPolicyStatusEnum,
  ACTIVE_HEALTH_POLICY_STATUS,
}

export default CONSTANT
