import type { TabsProps } from 'antd'
import { Container, Tabs, Title } from './styles'
import TabPermissionManagement from './TabPermissionManagement/TabPermissionManagement'
import TabUser from './TabUser/TabUser'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import UnauthorizedPage from '@/pages/Unauthorized'

const UserPermissionPage = () => {
  const user = useRecoilValue(userAtom)

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `ผู้ใช้งาน`,
      children: <TabUser />,
    },
    {
      key: '2',
      label: `การจัดการสิทธิ์`,
      children: <TabPermissionManagement />,
    },
  ]

  return (
    <>
      {validatePerm(
        [BACKOFFICE_USER_PERMISSION.USR010101, BACKOFFICE_USER_PERMISSION.USR010102],
        user.permissions,
      ) ? (
        <Container>
          <Title>การจัดการสิทธิ์และบทบาทผู้ใช้งาน</Title>
          {validatePerm([BACKOFFICE_USER_PERMISSION.USR010101], user.permissions) ? (
            <Tabs defaultActiveKey="1" items={items} data-testid="user-permission-tab" />
          ) : (
            <TabPermissionManagement />
          )}
        </Container>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default UserPermissionPage
