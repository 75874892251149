import { Container } from './styles'
import ReturnTimeTable from './ReturnTimeTable/ReturnTimeTable'

const ReturnTimePage = () => {
  return (
    <Container>
      <ReturnTimeTable />
    </Container>
  )
}

export default ReturnTimePage
