import { gql, TypedDocumentNode } from '@apollo/client'
import { GetBackofficeUsersDataInterface } from './interfaces'

export const getBackofficeUsers: TypedDocumentNode<GetBackofficeUsersDataInterface> = gql`
  query GetBackofficeUsers {
    getBackofficeUsers {
      id
      email
      firstname
      lastname
      staffId
      updatedAt
      createdAt
    }
  }
`

export default getBackofficeUsers
