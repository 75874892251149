import { ReactElement, useCallback, useMemo } from 'react'
import UserAuthContext from '@/contexts/userAuth/context'
import { ContextTypes } from '@/contexts/userAuth/types'
import { msalInstance } from '../msal'

interface ProviderInterface {
  children: ReactElement
}

const UserAuthProvider = ({ children }: ProviderInterface) => {
  const login = useCallback(async () => {
    msalInstance.loginRedirect({
      scopes: ['openid', 'User.Read'],
      prompt: 'select_account',
      redirectStartPage: '/',
    })
  }, [])

  const logout = useCallback((navigateTo?: string) => {
    // TODO: improve this
    localStorage.clear()
    window.location.reload()
  }, [])

  const contextValue: ContextTypes = useMemo(
    () => ({
      login,
      logout,
    }),
    [login, logout],
  )

  return <UserAuthContext.Provider value={contextValue}>{children}</UserAuthContext.Provider>
}

export default UserAuthProvider
