import { atom } from 'recoil'

import { SideBarInformationProps } from './types'

const initSideBarInformation: SideBarInformationProps = {
  visible: false,
  title: null,
  children: null,
  dataTestId: undefined,
  sideBarChildrenVisible: false,
  sideBarChildrenTitle: null,
  sideBarChildren: null,
  childrenDataTestId: undefined,
}

const sideBarInformationAtom = atom({
  key: 'sideBarInformationAtom',
  default: initSideBarInformation,
})

export default sideBarInformationAtom
