export enum ActiveHealthPolicySortableFieldEnum {
  REFERENCE_NUMBER = 'referenceNumber',
  CREATED_AT = 'createdAt',
}

export enum ActiveHealthPolicySearchableFieldEnum {
  REFERENCE_NUMBER = 'referenceNumber',
  IDENTITY_ID = 'identityId',
  POLICY_NUMBER = 'policyNumber',
  INSURED_NAME = 'insuredName',
  PHONE_NUMBER = 'phoneNumber',
  PLAN = 'plan',
}

export enum ActiveHealthPolicyStatusEnum {
  ACTIVE = 'Active',
  NOT_YET = 'Not yet active',
  EXPIRED = 'Expired',
  CANCELLED_WITH_REASON = 'Cancelled still effective,Cancelled since effective',
  CANCELLED = 'Cancelled',
  ALL = '',
}

export const ACTIVE_HEALTH_POLICY_STATUS = {
  [ActiveHealthPolicyStatusEnum.ACTIVE]: 'มีผลคุ้มครองอยู่',
  [ActiveHealthPolicyStatusEnum.NOT_YET]: 'ยังไม่มีผลคุ้มครอง',
  [ActiveHealthPolicyStatusEnum.EXPIRED]: 'กธ. ที่ยังไม่ได้รับการต่ออายุ',
  [ActiveHealthPolicyStatusEnum.CANCELLED_WITH_REASON]:
    'ยกเลิกแต่ยังมีผลคุ้มครอง,ยกเลิกตั้งแต่วันที่คุ้มครอง',
  [ActiveHealthPolicyStatusEnum.CANCELLED]: 'ยกเลิก',
  [ActiveHealthPolicyStatusEnum.ALL]: 'ทั้งหมด',
}
