import { css } from '@emotion/css'
import styled from '@emotion/styled'

export const SelectedDateContainer = styled('div')`
  display: flex;
`

export const SelectedDateBlock = styled('div')`
  width: 50%;
`

export const SelectedDateSubtitle = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #666666;
`

export const SelectedDateValue = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
`

export const DateRangePickerStyled = css`
  .css-10wpov9-MuiTypography-root {
    font-family: 'Prompt';
  }

  .css-1fiwjne-MuiDateRangePickerDay-root {
    background-color: #e6f6ea;
  }

  .css-1fiwjne-MuiDateRangePickerDay-root:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .css-1fiwjne-MuiDateRangePickerDay-root:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .css-w6gjxx-MuiDateRangePickerDay-root {
    border-radius: 4px !important;
    background-color: #e6f6ea;
  }

  .css-l9ibgo-MuiDateRangePickerDay-root {
    border-radius: 4px !important;
    background-color: #e6f6ea;
  }

  .css-13ul2f4-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day {
    font-family: 'Prompt';
  }

  .css-13ul2f4-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:hover {
    border-radius: 4px;
  }

  .css-13ul2f4-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:focus {
    border-radius: 4px;
  }

  .css-57rt36-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:hover {
    border-radius: 4px;
  }

  .css-s2oo14-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected) {
    border-radius: 4px;
  }

  .Mui-selected {
    border-radius: 4px !important;
  }

  .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel {
    font-family: 'Prompt';
  }
`
