import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeGetMeDataInterface } from './interfaces'

export const backofficeGetMe: TypedDocumentNode<BackofficeGetMeDataInterface> = gql`
  query BackofficeGetMe {
    backofficeGetMe {
      id
      email
      firstname
      lastname
      staffId
      isConfirmEmail
      updatedBy
      createdBy
      permissions
      pages
      menus
      menuTypes
      backofficeTeams {
        id
        name
      }
    }
  }
`

export default backofficeGetMe
