import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeListUserSessionDataInterface,
  BackofficeListUserSessionInputInterface,
} from './types'

const backofficeListUserSession: TypedDocumentNode<
  BackofficeListUserSessionDataInterface,
  BackofficeListUserSessionInputInterface
> = gql`
  query BackofficeListUserSessionByUserId($id: ID!) {
    backofficeListUserSessionByUserId(id: $id) {
      id
      deviceVendor
      osName
      osVersion
      createdAt
    }
  }
`

export default backofficeListUserSession
