import styled from '@emotion/styled'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')`
  position: absolute;
  right: 42px;
  top: 42px;
`

export const Button = styled(CommonButton)`
  background: #ffffff;
  border: 1px solid #e5e5e5;
`

export const ExportIcon = styled('img')`
  margin-right: 9px;
`

export const ButtonContainer = styled('div')`
  text-align: right;
  margin: 24px -24px 0 -24px;
  padding: 24px 24px 0 24px;
  border-top: 1px solid #e5e5e5;
`

export const OkButton = styled(CommonButton)`
  background: #009400;
  border: 1px solid #e5e5e5;
  color: #ffffff;
  margin-left: 8px;

  a:hover {
    color: #ffffff;
  }
`
