import { useCallback, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Badge, CheckboxOptionType } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import sideBarInformationAtom from '@/recoil/sideBarInformation'
import deferredDiscountModalAtom from '@/recoil/deferredDiscountModal'
import { BillingFilterEnum } from '@/constant/BILLING_FILTER'
import { BILLING_STATUS, BillingStatusEnum } from '@/constant/BILLING_STATUS'
import { ActionText, EyeIcon, UserSelectStyle } from './styles'
import policyDetailAtom from '@/recoil/policyDetail'
import policyAtom from '@/recoil/policy'
import { activeHealthPolicyGql } from '@/gql'
import { useLazyQuery } from '@apollo/client'
import { ActiveHealthPolicyBillingSearchableFieldEnum } from '@/constant/ACTIVE_HEALTH_POLICY_BILLING'
import { isCurrentMonthlyBilling, toThaiLocaleDateAndMonthRangeWithDash } from '@/libs/date'
import { ActiveHealthPolicyBillingType } from '@/gql/activeHealthPolicy/backofficeListActiveHealthBillingByUserId/interfaces'
import BillingInformation from '../BillingInformation'
import { BadgeStatusEnum } from '@/constant/BADGE_STATUS'
import { validatePerm } from '@/libs/validatePermission'
import userAtom from '@/recoil/user'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { PolicySuperAppStatusEnum } from '@/constant/POLICY_SUPER_APP_STATUS'
import { IMAGE_URL } from '@/constant/IMAGE'

dayjs.extend(isSameOrAfter)

function BillingTableHook() {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([])
  const setOpenDeferredDiscountModal = useSetRecoilState(deferredDiscountModalAtom)
  const [sideBarInformation, setSideBarInformation] = useRecoilState(sideBarInformationAtom)
  const [billings, setBillings] = useState<Array<ActiveHealthPolicyBillingType>>([])
  const [selectBillingIndex, setSelectBillingIndex] = useState<number>(0)
  const policy = useRecoilValue(policyAtom)
  const user = useRecoilValue(userAtom)
  const policyDetail = useRecoilValue(policyDetailAtom)
  // REMIND: Visible "ขออนุโลมส่วนลด" when policy status are "มีผลคุ้มครอง" and "ยังไม่มีผลคุ้มครอง" cause billing can generate forward 4 months.
  // billing will appear in renew policy and policy status will be "ยังไม่มีผลคุ้มครอง"
  const visibleManualDiscount =
    policy.policySuperAppStatus === PolicySuperAppStatusEnum.A ||
    policy.policySuperAppStatus === PolicySuperAppStatusEnum.F
  const radioOptions: Array<CheckboxOptionType> = [
    {
      label: 'ทั้งหมด',
      value: BillingFilterEnum.ALL,
    },
    {
      label: 'รอชำระ',
      value: BillingFilterEnum.PENDING,
    },
    {
      label: 'สำเร็จ',
      value: BillingFilterEnum.PAID,
    },
  ]
  const [
    backofficeListActiveHealthBillingByUserId,
    { data: activeHealthBillingData, loading: activeHealthBillingLoading },
  ] = useLazyQuery(activeHealthPolicyGql.query.backofficeListActiveHealthBillingByUserId, {
    fetchPolicy: 'no-cache',
  })

  const [
    backofficeListActiveHealthByBillId,
    { data: activeHealthWeeklySummaryData, loading: activeHealthWeeklySummaryLoading },
  ] = useLazyQuery(activeHealthPolicyGql.query.backofficeListActiveHealthByBillId, {
    fetchPolicy: 'no-cache',
  })

  const getActiveHealthBilling = useCallback(
    (paymentStatus?: BillingFilterEnum) => {
      if (policyDetail.id && policy.policyNumber) {
        backofficeListActiveHealthBillingByUserId({
          variables: {
            userId: policyDetail.id,
            input: {
              searchBy: [ActiveHealthPolicyBillingSearchableFieldEnum.POLICY_NUMBER],
              search: policy.policyNumber,
              filter: {
                paymentStatus: paymentStatus ? paymentStatus : undefined,
              },
              limit: 12, // maximum is 12 records
            },
          },
        })
      }
    },
    [backofficeListActiveHealthBillingByUserId],
  )

  const getActiveHealthWeeklySummary = useCallback(
    (billId: string) => {
      backofficeListActiveHealthByBillId({
        variables: {
          billId,
        },
      })
    },
    [backofficeListActiveHealthByBillId],
  )

  const columns: ColumnsType<ActiveHealthPolicyBillingType | {}> = [
    {
      title: 'ลำดับ',
      key: 'index',
      align: 'center',
      render: (_value, _record, index) => {
        return <div>{index + 1}</div>
      },
    },
    {
      title: 'แผนประกัน',
      className: UserSelectStyle,
      dataIndex: ['activeHealthPolicy', 'plan'],
    },
    {
      title: 'รอบบิลวันที่',
      className: UserSelectStyle,
      render: (data) => {
        return toThaiLocaleDateAndMonthRangeWithDash(data?.startDate, data?.billDate)
      },
    },
    {
      title: 'ค่าเบี้ย/รอบบิล',
      className: UserSelectStyle,
      dataIndex: 'billingAmount',
      render: (billingAmount) => {
        return billingAmount.toLocaleString() || 0
      },
    },
    {
      title: 'ส่วนลดส่งไป PV',
      className: UserSelectStyle,
      dataIndex: 'billingDiscount',
      render: (billingDiscount) => {
        return billingDiscount.toLocaleString() || 0
      },
    },
    {
      title: 'ส่วนลดการขออนุโลม',
      className: UserSelectStyle,
      dataIndex: 'totalManualDiscount',
      render: (totalManualDiscount) => {
        return totalManualDiscount.toLocaleString() || 0
      },
    },
    {
      title: 'รวมส่วนลดค่าเบี้ย',
      className: UserSelectStyle,
      dataIndex: '',
      render: (_value, record) => {
        const { actualDiscount, totalManualDiscount } = record as ActiveHealthPolicyBillingType

        return (actualDiscount + totalManualDiscount).toLocaleString() || 0
      },
    },
    {
      title: 'ยอดค่าเบี้ยที่ชำระแล้ว',
      className: UserSelectStyle,
      dataIndex: 'premium',
      render: (premium) => {
        return premium.toLocaleString() || 0
      },
    },
    {
      title: 'วันที่ชำระ',
      className: UserSelectStyle,
      dataIndex: 'payDate',
      render: (payDate) => {
        return payDate ? dayjs(payDate).format('DD-MM-YYYY HH:mm:ss') : '-'
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'paymentStatus',
      render: (paymentStatus: BillingStatusEnum) => {
        return (
          <>
            <Badge
              status={
                paymentStatus === BillingStatusEnum.PAID
                  ? BadgeStatusEnum.SUCCESS
                  : BadgeStatusEnum.PROCESSING
              }
            />{' '}
            <span>{BILLING_STATUS[paymentStatus]}</span>
          </>
        )
      },
    },
    validatePerm([BACKOFFICE_USER_PERMISSION.HLT010102], user.permissions)
      ? {
          title: '',
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 140,
          render: (_value, record, index) => {
            const activeHealthPolicyBilling = record as ActiveHealthPolicyBillingType

            return isCurrentMonthlyBilling(
              activeHealthPolicyBilling.startDate,
              activeHealthPolicyBilling.billDate,
            ) && visibleManualDiscount ? (
              <ActionText
                onClick={() => handleOpenDeferredDiscountModal(activeHealthPolicyBilling)}
                data-testid={`policy-detail-billing-tab-summary-table-discount-button-${index}`}
              >
                ขออนุโลมส่วนลด
              </ActionText>
            ) : undefined
          },
        }
      : {
          width: 0,
        },
    {
      title: '',
      align: 'center',
      fixed: 'right',
      width: 50,
      render: (_value, record: ActiveHealthPolicyBillingType | {}, index) => (
        <EyeIcon
          src={IMAGE_URL.eyeIcon}
          onClick={() => {
            const activeHealthPolicyBilling = record as ActiveHealthPolicyBillingType

            if (index !== undefined && index >= 0) {
              handleSelectKeyRows([index])
              handleSetBillingInformation(activeHealthPolicyBilling, index)
            }
          }}
          data-testid={`policy-detail-billing-tab-summary-table-view-button-${index}`}
        />
      ),
    },
  ]

  const handleSetBillingInformation = (
    activeHealthPolicyBilling: ActiveHealthPolicyBillingType,
    billingIndex: number,
  ) => {
    setSelectBillingIndex(billingIndex)
    getActiveHealthWeeklySummary(activeHealthPolicyBilling.id)
  }

  const handleOpenDeferredDiscountModal = (
    activeHealthPolicyBilling: ActiveHealthPolicyBillingType,
  ) => {
    setOpenDeferredDiscountModal({ open: true, activeHealthPolicyBilling })
  }

  const handleSelectKeyRows = (data: Array<number>) => setSelectedRowKeys(data)

  useEffect(() => {
    if (
      activeHealthWeeklySummaryData &&
      activeHealthWeeklySummaryData?.backofficeListActiveHealthByBillId?.length > 0
    ) {
      const activeHealthPolicyBilling = billings[selectBillingIndex]
      const activeHealthPolicyWeeklySummaries =
        activeHealthWeeklySummaryData.backofficeListActiveHealthByBillId

      setSideBarInformation({
        title: 'บิลค่าเบี้ย',
        children: (
          <BillingInformation
            activeHealthPolicyBilling={activeHealthPolicyBilling}
            activeHealthPolicyWeeklySummaries={activeHealthPolicyWeeklySummaries}
            loading={activeHealthWeeklySummaryLoading}
          />
        ),
        visible: true,
        dataTestId: 'billing-sidebar',
      })
    }
  }, [activeHealthWeeklySummaryData])

  useEffect(() => {
    getActiveHealthBilling()
  }, [])

  useEffect(() => {
    if (
      activeHealthBillingData &&
      activeHealthBillingData.backofficeListActiveHealthBillingByUserId
    ) {
      setBillings(activeHealthBillingData.backofficeListActiveHealthBillingByUserId.data)
    }
  }, [activeHealthBillingData])

  useEffect(() => {
    if (!sideBarInformation.visible) {
      handleSelectKeyRows([])
    }
  }, [sideBarInformation])

  return {
    billings,
    columns,
    selectedRowKeys,
    activeHealthBillingLoading,
    radioOptions,
    activeHealthBillingData,
    getActiveHealthBilling,
  }
}

export default BillingTableHook
