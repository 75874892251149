import { ActiveHealthPartnerBranchWithScanCount } from '@/gql/activeHealthPartner/backofficeGetActiveHealthPartnerBranchPaginationWithScanCount/interface'
import {
  Container,
  InfoTitle,
  Info,
  GroupTitle,
  Block,
  InfoBlock,
  MobileBlock,
  StatusText,
  MobileSubBlock,
  MobileOrderText,
} from './styles'
import { Badge } from 'antd'
import { toThaiLocaleSlashDate } from '@/libs/date'
import { Addresses } from '@/gql/address/backofficeGetAddresses/interface'

function WellBeingPartnerSidebarInfo({
  activeHealthPartnerBranchWithScanCount,
  address,
}: {
  activeHealthPartnerBranchWithScanCount: ActiveHealthPartnerBranchWithScanCount
  address: Addresses
}) {
  return (
    <Container>
      <Block style={{ marginTop: 'unset' }}>
        <GroupTitle>ชื่อสาขา</GroupTitle>
        <InfoBlock>
          <InfoTitle>ชื่อสาขา (ไทย)</InfoTitle>
          <Info>
            {activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.displayNameTh || '-'}
          </Info>
        </InfoBlock>
        <InfoBlock>
          <InfoTitle>ชื่อสาขา (Eng)</InfoTitle>
          <Info>
            {activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.displayNameEn || '-'}
          </Info>
        </InfoBlock>
      </Block>
      <Block>
        <GroupTitle>ข้อมูลที่อยู่</GroupTitle>
        <InfoBlock>
          <InfoTitle>ที่อยู่ (ไทย)</InfoTitle>
          <Info>
            {activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.addressTh || '-'}
          </Info>
        </InfoBlock>
        <InfoBlock>
          <InfoTitle>ที่อยู่ (Eng)</InfoTitle>
          <Info>
            {activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.addressEn || '-'}
          </Info>
        </InfoBlock>
        <InfoBlock>
          <InfoTitle>จังหวัด</InfoTitle>
          <Info>{address?.displayNameTh || '-'}</Info>
        </InfoBlock>
        <InfoBlock>
          <InfoTitle>เขต/อำเภอ</InfoTitle>
          {address.districts[0]?.displayNameTh || '-'}
        </InfoBlock>
        <InfoBlock>
          <InfoTitle>แขวง/ตำบล</InfoTitle>
          <Info>
            {address.districts[0]?.subDistricts[0]?.displayNameTh ||
              activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch?.subDistrict
                ?.displayNameTh ||
              '-'}
          </Info>
        </InfoBlock>
        <InfoBlock>
          <InfoTitle>รหัสไปรษณีย์</InfoTitle>
          <Info>
            {activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch?.subDistrict
              ?.postCode || '-'}
          </Info>
        </InfoBlock>
        <InfoBlock>
          <InfoTitle>Lat</InfoTitle>
          <Info>{activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.lat}</Info>
        </InfoBlock>
        <InfoBlock>
          <InfoTitle>Long</InfoTitle>
          <Info>{activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.long}</Info>
        </InfoBlock>
      </Block>
      {activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch
        ?.activeHealthPartnerBranchContacts?.length ? (
        <Block>
          <GroupTitle>ข้อมูลติดต่อ</GroupTitle>
          {activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.activeHealthPartnerBranchContacts.map(
            (contact, index) => (
              <MobileBlock>
                <InfoBlock>
                  <MobileSubBlock>
                    <MobileOrderText>{index + 1}</MobileOrderText>
                    <div>
                      <InfoTitle>เบอร์โทร</InfoTitle>
                      <Info>{contact.phoneNumber || '-'}</Info>
                    </div>
                  </MobileSubBlock>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>ต่อ</InfoTitle>
                  <Info>{contact.extensionNumber || '-'}</Info>
                </InfoBlock>
              </MobileBlock>
            ),
          )}
        </Block>
      ) : undefined}
      <Block style={{ borderBottom: 'unset' }}>
        <GroupTitle>การใช้งาน QR Code</GroupTitle>
        <InfoBlock>
          <InfoTitle>วันที่เริ่มต้น - วันที่สิ้นสุด</InfoTitle>
          <Info>{`${toThaiLocaleSlashDate(
            activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.startDate,
          )} - ${toThaiLocaleSlashDate(
            activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.endDate,
          )}`}</Info>
        </InfoBlock>
        <InfoBlock>
          <InfoTitle>สถานะการใช้งาน QR Code</InfoTitle>
          <StatusText>
            <Badge
              status={
                activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.qr.isActive
                  ? 'success'
                  : 'error'
              }
            />{' '}
            {activeHealthPartnerBranchWithScanCount.activeHealthPartnerBranch.qr.isActive
              ? 'เปิดการใช้งาน'
              : 'ปิดการใช้งาน'}
          </StatusText>
        </InfoBlock>
      </Block>
    </Container>
  )
}

export default WellBeingPartnerSidebarInfo
