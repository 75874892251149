import { backofficeUpdateBackofficeTeam } from './backofficeUpdateBackofficeTeam/backofficeUpdateBackofficeTeam.gql'
import backofficeGetAllBackofficeTeam from './backofficeGetAllBackofficeTeam/backofficeGetAllBackofficeTeam.gql'
import backofficeTeamDeleteTeam from './backofficeTeamDeleteTeam/backofficeTeamDeleteTeam.gql'
import createBackofficeTeam from './createBackofficeTeam/CreateBackofficeTeam.gql'
import backofficeGetBackofficeUserTeamRolePaginated from './backofficeGetBackofficeUserTeamRolePaginated/backofficeGetBackofficeUserTeamRolePaginated.gql'
import backofficeListBackofficeRole from './backofficeListBackofficeRole/backofficeListBackofficeRole.gql'
import createBackofficeUser from './createBackofficeUser/createBackofficeUser.gql'
import backofficeSearchBackOfficeUser from './backofficeSearchBackOfficeUser/backofficeSearchBackOfficeUser.gql'
import backofficeDeleteBackofficeTeamFromBackofficeUser from './backofficeDeleteBackofficeTeamFromBackofficeUser/backofficeDeleteBackofficeTeamFromBackofficeUser.gql'
import backofficeBulkAssignUsersToTeam from './backofficeBulkAssignUsersToTeam/backofficeBulkAssignUsersToTeam.gql'
import updateBackofficeUser from './updateBackofficeUser/updateBackofficeUser.gql'
import backofficeUpdateBackofficeTeamMenuPermission from './backofficeUpdateBackofficeTeamMenuPermission/backofficeUpdateBackofficeTeamMenuPermission.gql'

const backofficeTeam = {
  query: {
    backofficeGetAllBackofficeTeam,
    backofficeGetBackofficeUserTeamRolePaginated,
    backofficeListBackofficeRole,
    backofficeSearchBackOfficeUser,
  },
  mutation: {
    createBackofficeTeam,
    backofficeUpdateBackofficeTeam,
    backofficeTeamDeleteTeam,
    createBackofficeUser,
    backofficeDeleteBackofficeTeamFromBackofficeUser,
    backofficeBulkAssignUsersToTeam,
    updateBackofficeUser,
    backofficeUpdateBackofficeTeamMenuPermission,
  },
}

export default backofficeTeam
