import config from '@/config'
import { httpClientPrivate } from '@/libs/httpClient'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { SubDistrictType } from './types'

const fetchSubDistrict = async (districtCode: string) => {
  const response = await httpClientPrivate.get(
    `${config.restUrl}/v1/backoffice-address/sub-district/${districtCode}`,
  )

  return response.data
}

const useSubDistrict = (districtCode: string) => {
  const query = useQuery<Array<SubDistrictType>>({
    queryKey: ['useSubDistrict', districtCode],
    queryFn: async () => fetchSubDistrict(districtCode),
    placeholderData: keepPreviousData,
    enabled: !!districtCode,
  })

  return query
}

export { useSubDistrict, fetchSubDistrict }
