import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeAdjustDailyExerciseDataInputInterface,
  BackofficeAdjustDailyExerciseDataResponseInterface,
} from './interfaces'

const backofficeAdjustDailyExerciseData: TypedDocumentNode<
  BackofficeAdjustDailyExerciseDataResponseInterface,
  BackofficeAdjustDailyExerciseDataInputInterface
> = gql`
  mutation BackofficeAdjustDailyExerciseData($input: UpdateDailyExerciseInput!) {
    backofficeAdjustDailyExerciseData(input: $input) {
      step
      activityDuration
    }
  }
`

export default backofficeAdjustDailyExerciseData
