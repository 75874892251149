import { useCallback, useEffect } from 'react'
import { msalInstance } from '@/providers/msal'
import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

const UserAuthHook = () => {
  const { inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const handleRefreshTokenAzureAD = useCallback(async () => {
    const account = msalInstance.getActiveAccount()

    if (!account) {
      return
    }

    const loginRequest = {
      scopes: ['openid', 'User.Read'],
      account,
    }

    const response = await msalInstance.acquireTokenSilent(loginRequest)
    const { idToken } = response

    const msalTokenKeys = JSON.parse(
      localStorage.getItem(`msal.token.keys.${account?.idTokenClaims?.aud}`) || '{}',
    )

    const { secret: refreshToken } = JSON.parse(
      localStorage.getItem(msalTokenKeys.refreshToken[0]) || '{}',
    )

    localStorage.setItem('accessToken', idToken)
    localStorage.setItem('refreshToken', refreshToken)
  }, [])

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      handleRefreshTokenAzureAD()
    }
  }, [inProgress, handleRefreshTokenAzureAD])

  return {
    inProgress,
    isAuthenticated,
  }
}

export default UserAuthHook
