import styled from '@emotion/styled'

export const Container = styled('div')`
  width: 100%;
  padding: 40px;
`
export const Title = styled('div')`
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  margin: 33px;
`
