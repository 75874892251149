export enum BackOfficeFAQModuleNameEnum {
  ACTIVE_HEALTH = 'ACTIVE_HEALTH',
  PPU_MOTOR = 'PPU_MOTOR',
}

export interface BackofficeFAQCategoriesByModuleNameInterface {
  id: string
  title: string
  order: number
  faqModuleId: string
}

export interface backofficeFAQCategoriesByModuleNameDataInterface {
  backofficeFAQCategoriesByModuleName: Array<BackofficeFAQCategoriesByModuleNameInterface>
}

export interface backofficeFAQCategoriesByModuleNameInputInterface {
  input: {
    moduleName: BackOfficeFAQModuleNameEnum
  }
}
