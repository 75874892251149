import { CloseOutlined } from '@ant-design/icons'
import DiseaseModalHook from './DiseaseModal.hook'
import Modal from '@/components/common/Modal'

function DiseaseModal() {
  const { diseaseModal, handleCloseModal } = DiseaseModalHook()
  return (
    <Modal
      open={diseaseModal.open}
      closeIcon={<CloseOutlined />}
      footer={null}
      title="โรคที่ไม่คุ้มครอง"
      onCancel={handleCloseModal}
      data-testid="policy-detail-disease-modal"
    >
      {diseaseModal?.diseases?.map((disease) => (
        <div>{disease}</div>
      ))}
    </Modal>
  )
}

export default DiseaseModal
