import styled from '@emotion/styled'
import { Radio as AntdRadio } from 'antd'
import { DisplayModeEnum } from '@/components/feature/WellBeingPage/WellBeingPartnerStatus/types'

export const Container = styled('div')`
  padding: 24px 32px 24px 32px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
  margin-bottom: 24px;
`

export const Title = styled('div')`
  font-size: 20px;
  font-weight: 400;
  margin-right: 8px;
`

export const Subtitle = styled('div')`
  font-size: 14px;
  color: #666666;
`

export const TitleBlock = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const TopBlock = styled('div')<{ displayMode: DisplayModeEnum }>`
  margin-bottom: ${(props) => (props.displayMode === DisplayModeEnum.EDIT ? '32px' : 'unset')};
`

export const Radio = styled(AntdRadio)``

export const RadioGroup = styled(AntdRadio.Group)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`

export const RadioBox = styled('div')<{ select?: boolean }>`
  border-radius: 8px;
  border: 1px solid ${(props) => (props.select ? '#009640' : '#E5E5E5')};
  padding: 16px;
  display: flex;
  align-items: flex-start;
`

export const RadioTitle = styled('div')`
  font-size: 14px;
  font-weight: 400;
`

export const OnText = styled('span')`
  font-weight: 500;
  color: #009400;
`

export const OffText = styled('span')`
  font-weight: 500;
  color: #ef2605;
`

export const StatusBadge = styled('span')<{ isActive: boolean }>`
  padding: 2px 8px 2px 6px;
  border-radius: 16px;
  background-color: ${(props) => (props.isActive ? '#e6f6ea' : '#FCEDED')};
  color: ${(props) => (props.isActive ? '#009400' : '#EB8181')};
`
