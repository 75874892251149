import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'
import { UpdateContactPersonInputType } from '@/services/order/useUpdateContactPerson/types'
import { useMutation } from '@tanstack/react-query'

const updateContactPerson = async (
  orderId: string,
  contactId: string,
  data: UpdateContactPersonInputType,
) => {
  return httpClientPrivate.put(
    `${config.restUrl}/v1/backoffice-commerce/order/${orderId}/contact/${contactId}`,
    data,
  )
}

const useUpdateContactPerson = (
  orderId: string,
  contactId: string,
  data: UpdateContactPersonInputType,
) => {
  return useMutation({
    mutationKey: ['useUpdateContactPerson', 'orderId', 'contactId'],
    mutationFn: async () => updateContactPerson(orderId, contactId, data),
  })
}

export { updateContactPerson, useUpdateContactPerson }
