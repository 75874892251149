import styled from '@emotion/styled'
import {
  Input as AntdInput,
  Upload as AntdUpload,
  InputNumber as AntdInputNumber,
  Form,
} from 'antd'
import { css } from '@emotion/css'
import Button from '@/components/common/Button'

export const Container = styled('div')`
  display: grid;
  grid-gap: 16px;
`

export const Block = styled('div')``

export const Input = styled(AntdInput)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
`

export const InputNumber = styled(AntdInputNumber)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
`

export const FormItem = styled(Form.Item)`
  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
`

export const TextArea = styled(AntdInput.TextArea)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  min-height: 104px !important;
`

export const ChooseFileButton = styled(Button)`
  border: 1px solid #009640;
  color: #009640;
`

export const Upload = styled(AntdUpload)``

export const Title = styled('p')`
  margin-bottom: 4px;
`

export const Desc = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const TopContainerStyle = css`
  grid-template-columns: 1fr 1fr;
`

export const MiddleContainerStyle = css`
  padding-top: 16px;
`
