import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeGetRecentActiveHealthBonusCriteriaDataInterface } from './interfaces'

const backofficeGetRecentActiveHealthBonusCriteria: TypedDocumentNode<BackofficeGetRecentActiveHealthBonusCriteriaDataInterface> = gql`
  query BackofficeGetRecentActiveHealthBonusCriteria {
    backofficeGetRecentActiveHealthBonusCriteria {
      startDate
      endDate
    }
  }
`

export default backofficeGetRecentActiveHealthBonusCriteria
