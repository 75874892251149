import styled from '@emotion/styled'

export const Container = styled('div')`
  padding: 24px 32px 24px 32px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 24px;
  background-color: white;
`

export const Block = styled('div')``

export const Title = styled('div')`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
`

export const PeriodBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  max-width: 275px;
  align-items: center;
`

export const Period = styled('div')``

export const PeriodTitle = styled('div')`
  font-size: 12px;
  color: #666666;
  margin-bottom: 4px;
`

export const PeriodDate = styled('div')`
  font-size: 19px;
`

export const Dash = styled('div')`
  color: #adadad;
`
