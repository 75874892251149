import backofficeActiveHealthExerciseStat from './backofficeActiveHealthExerciseStat/backofficeActiveHealthExerciseStat.gql'
import backofficeGetActiveHealthPolicy from './backofficeGetActiveHealthPolicy/backofficeGetActiveHealthPolicy.gql'
import backofficeGetActiveHealthSpecialPointHighlight from './backofficeGetActiveHealthSpecialPointHighlight/backofficeGetActiveHealthSpecialPointHighlight.gql'
import backofficeGetUserExerciseHistoryPagination from './backofficeGetUserExerciseHistoryPagination/backofficeGetUserExerciseHistoryPagination.gql'
import backOfficeGetEditExerciseDataDailySummaryActivityLog from './backOfficeGetEditExerciseDataDailySummaryActivityLog/backOfficeGetEditExerciseDataDailySummaryActivityLog.gql'
import backOfficeGetEditWellBeingDataDailySummaryActivityLog from './backOfficeGetEditWellBeingDataDailySummaryActivityLog/backOfficeGetEditWellBeingDataDailySummaryActivityLog.gql'
import backOfficeGetActiveHealthHeartrateTargetByDate from './backOfficeGetActiveHealthHeartrateTargetByDate/backOfficeGetActiveHealthHeartrateTargetByDate.gql'

const backofficeActiveHealthExercise = {
  query: {
    backofficeActiveHealthExerciseStat,
    backofficeGetActiveHealthPolicy,
    backofficeGetActiveHealthSpecialPointHighlight,
    backofficeGetUserExerciseHistoryPagination,
    backOfficeGetEditExerciseDataDailySummaryActivityLog,
    backOfficeGetEditWellBeingDataDailySummaryActivityLog,
    backOfficeGetActiveHealthHeartrateTargetByDate,
  },
  mutation: {},
}

export default backofficeActiveHealthExercise
