export enum ActiveHealthSpecialPointEnum {
  MULTIPLIER = 'MULTIPLIER',
  TARGET = 'TARGET',
}

export interface ActiveHealthSpecialPointInterface {
  id?: string
  title: string
  descriptionTh: string
  descriptionEn: string
  imageUrl?: string
  startDate: string
  endDate: string
  type: ActiveHealthSpecialPointEnum
  value: number
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
}
