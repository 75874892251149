import { atom } from 'recoil'

import { AdditionalCoverageModalProps } from './types'

const additionalCoverageModal: AdditionalCoverageModalProps = {
  open: false,
  additionalCoverages: null,
}

const additionalCoverageModalAtom = atom({
  key: 'additionalCoverageModalAtom',
  default: additionalCoverageModal,
})

export default additionalCoverageModalAtom
