import _ from 'lodash'
import {
  Button,
  CalledByContainer,
  CalledByText,
  CalledByTitle,
  Container,
  DownArrow,
  Dropdown,
  FooterContainer,
  Subtitle,
  Title,
} from './styles'
import WelcomeCallInformationHeaderHook from './WelcomeCallInformationHeader.hook'
import { CALL_TYPE, CallTypeEnum } from '@/constant/CALL_TYPE'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'

function WelcomeCallInformationHeader() {
  const { welcomeCall, menuProps, user, updateWelcomeCallLoading } =
    WelcomeCallInformationHeaderHook()

  return (
    <Container data-testid="welcome-call-sidebar-header">
      <Subtitle>เลขที่รายการ {welcomeCall.policyNumber}</Subtitle>
      <Title>รายละเอียดการซื้อประกันสุขภาพ</Title>
      <FooterContainer data-testid="welcome-call-update-footer-container">
        <Dropdown
          menu={menuProps}
          disabled={!validatePerm([BACKOFFICE_USER_PERMISSION.WEL010102], user.permissions)}
          data-testid="welcome-call-update-status-dropdown"
        >
          <Button
            success={welcomeCall.callStatus === CallTypeEnum.CALLED}
            loading={updateWelcomeCallLoading}
            data-testid="welcome-call-update-status-button"
          >
            {welcomeCall.callStatus && CALL_TYPE[welcomeCall.callStatus]} <DownArrow />
          </Button>
        </Dropdown>
        {!_.isEmpty(welcomeCall.calledBy) ? (
          <CalledByContainer>
            <CalledByTitle>แจ้งลูกค้าโดย</CalledByTitle>{' '}
            <CalledByText>
              {welcomeCall.calledBy.firstname} {welcomeCall.calledBy.lastname}
            </CalledByText>
          </CalledByContainer>
        ) : undefined}
      </FooterContainer>
    </Container>
  )
}

export default WelcomeCallInformationHeader
