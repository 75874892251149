import { useState } from 'react'

function MainAppHook() {
  const [isSideBarCollapse, setIsSideBarCollapse] = useState<boolean>(false)

  function setSideBar(isCollapse: boolean): void {
    setIsSideBarCollapse(isCollapse)
  }

  return {
    isSideBarCollapse,
    setSideBar,
  }
}

export default MainAppHook
