export enum ActiveHealthPromotionActivityType {
  QR_SCAN = 'QR_SCAN',
  CS_ADJUST = 'CS_ADJUST',
}

interface ActiveHealthPartner {
  id: string
  displayNameTh: string
  displayNameEn: string
  logoImageUrl: string
}

export interface ActiveHealthPartnerBranch {
  id: string
  displayNameTh: string
  displayNameEn: string
  activeHealthPartner: ActiveHealthPartner
}

interface ActivityBonusPointPartnerBranch {
  point: number
  activityType?: ActiveHealthPromotionActivityType
  activeHealthPartnerBranch?: ActiveHealthPartnerBranch
}

interface ActiveHealthBonusPointResponse {
  bonusPoint?: number
  activityBonusPoint?: ActivityBonusPointPartnerBranch[]
}

export interface BackofficeGetBonusPointPartnerBranchDataInterface {
  backofficeGetBonusPointPartnerBranch: ActiveHealthBonusPointResponse
}

export interface BackofficeGetBonusPointPartnerBranchInputInterface {
  input: { dailySummaryId: string }
}
