import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { NameTitleType } from '@/services/order/useNameTitle/types'

const fetchNameTitle = async () => {
  const response = await httpClientPrivate.get(`${config.restUrl}/v1/backoffice/common/name-title`)
  return response.data
}

const useNameTitle = () => {
  return useQuery<NameTitleType[]>({
    queryKey: ['useNameTitle'],
    queryFn: async () => fetchNameTitle(),
    placeholderData: keepPreviousData,
  })
}

export { useNameTitle, fetchNameTitle }
