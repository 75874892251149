import { atom } from 'recoil'

import { ChangeCreditCardModalProps } from './types'

const initChangeCreditCardModal: ChangeCreditCardModalProps = {
  open: false,
}

const changeCreditCardModalAtom = atom({
  key: 'changeCreditCardModalAtom',
  default: initChangeCreditCardModal,
})

export default changeCreditCardModalAtom
