import { Tabs } from 'antd'
import SearchPolicyBox from './SearchPolicyBox'
import PolicyDetailPageHook from './PolicyDetailPage.hook'
import { Container } from './styles'
import { PolicyGroupEnum } from '@/constant/POLICY_GROUP'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PAGE } from '@/constant/BACKOFFICE_PERMISSION'
import { PolicyDetailContext } from '@/contexts'
import UserInfoTab from '@/components/feature/PolicyDetailPage/UserInfoTab'

function PolicyDetailPage() {
  const {
    tabItems,
    user,
    policy,
    tabActiveKey,
    handleSetTabActiveKey,
    getPolicyByPolicyNumber,
    isComponentLoading,
  } = PolicyDetailPageHook()

  // TODO: add skeleton component and handle in single component
  return (
    <PolicyDetailContext.Provider
      value={{
        handleSetTabActiveKey,
        getPolicyByPolicyNumber,
        isComponentLoading,
      }}
    >
      <Container>
        <SearchPolicyBox />
        {policy.policyGroup === PolicyGroupEnum.ACTIVE_HEALTH &&
        validatePerm([BACKOFFICE_USER_PAGE.HLT0101], user.pages) ? (
          <Tabs items={tabItems} activeKey={tabActiveKey} />
        ) : (
          <UserInfoTab />
        )}
      </Container>
    </PolicyDetailContext.Provider>
  )
}

export default PolicyDetailPage
