import styled from '@emotion/styled'
import { Button } from 'antd'

export const Container = styled('div')``

export const Title = styled('div')`
  font-weight: 500;
  font-size: 18px;
  color: #000000;
`

export const Header = styled('div')``

export const ExportButton = styled(Button)`
  font-weight: 500;

  a:hover {
    color: #000000;
  }
`

export const ExportIcon = styled('img')``

export const TableContainer = styled('div')`
  position: relative;
`

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`
