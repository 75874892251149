import { useLazyQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ActivityFilterEnum } from '@/constant/ACTIVITY_FILTER'
import backofficeActiveHealthExercise from '@/gql/backofficeActiveHealthExercise'
import {
  BackofficeActiveHealthExerciseStatInputInterface,
  BackofficeActiveHealthExerciseStatInterface,
  BackofficeActiveHealthExerciseStatPeriodEnum,
} from '@/gql/backofficeActiveHealthExercise/backofficeActiveHealthExerciseStat/interfaces'
import {
  BackofficeActiveHealthSpecialPointHighlightInputInterface,
  BackofficeActiveHealthSpecialPointHighlightInterface,
  BackofficeActiveHealthSpecialPointHighlightPeriodEnum,
} from '@/gql/backofficeActiveHealthExercise/backofficeGetActiveHealthSpecialPointHighlight/interfaces'
import policyAtom from '@/recoil/policy'
import policyDetailAtom from '@/recoil/policyDetail/atom'
import { ActiveHealthSpecialPointHighlight, ExerciseStat, ExerciseStatBarChart } from './types'
import { ActivityPeriodDateFilterEnum } from '@/constant/ACTIVITY_PERIOD_DATE_FILTER'
import { toThaiLocaleDayAndDateWithDash, toThaiLocaleMonthAndYearWithDash } from '@/libs/date'
import { BackofficeGetActiveHealthPolicyInputInterface } from '@/gql/backofficeActiveHealthExercise/backofficeGetActiveHealthPolicy/interfaces'

function ActivityTabHook() {
  const policyDetail = useRecoilValue(policyDetailAtom)
  const policy = useRecoilValue(policyAtom)
  const [exerciseStat, setExerciseStat] = useState<Array<ExerciseStat> | []>([])
  const [exerciseBarChart, setExerciseBarChart] = useState<Array<ExerciseStatBarChart> | []>([])
  const [startDateStat, setStartDateStat] = useState<Date>(new Date())
  const [endDateStat, setEndDateStat] = useState<Date>(new Date())
  const [totalStep, setTotalStep] = useState<number>(0)
  const [totalMinute, setTotalMinute] = useState<number>(0)
  const [totalPoint, setTotalPoint] = useState<number>(0)
  const [totalBonusPoint, setTotalBonusPoint] = useState<number>()

  const [activityFilter, setActivityFilter] = useState<ActivityFilterEnum>(ActivityFilterEnum.STEPS)
  const [rangeDateCurrent, setRangeDateCurrent] = useState<string>('')
  const [rangeDateNext, setRangeDateNext] = useState<string>('')
  const [rangeDatePrevious, setRangeDatePrevious] = useState<string>('')

  const [specialHighlights, setSpecialHighlights] = useState<
    Array<ActiveHealthSpecialPointHighlight> | []
  >([])
  const [lastSync, setLastSync] = useState<string>('')

  const [filterPeriod, setFilterPeriod] = useState<ActivityPeriodDateFilterEnum>(
    ActivityPeriodDateFilterEnum.WEEK,
  )

  const funcFormatBackofficeActiveHealthExerciseStat = (
    backofficeActiveHealthExerciseStats: BackofficeActiveHealthExerciseStatInterface,
  ): Array<ExerciseStat> => {
    const stats: Array<ExerciseStat> = []
    if (backofficeActiveHealthExerciseStats?.backofficeGetUserExerciseStat?.data) {
      for (const stat of backofficeActiveHealthExerciseStats.backofficeGetUserExerciseStat.data) {
        const dataStat = {
          date: stat.date,
          step: stat.step,
          minute: stat.minute,
          point: stat.point,
          wellPoint: stat.wellBeing,
        }

        stats.push(dataStat)
      }
    }

    return stats
  }

  const funcFormatBackofficeActiveHealthSpecialHighlight = (
    backofficeActiveHealthSpecialPointHighlightInterface: BackofficeActiveHealthSpecialPointHighlightInterface,
  ) => {
    const specialPointHighlight: Array<ActiveHealthSpecialPointHighlight> = []
    if (
      backofficeActiveHealthSpecialPointHighlightInterface?.backofficeGetActiveHealthSpecialPointHighlight
    ) {
      for (const desc of backofficeActiveHealthSpecialPointHighlightInterface.backofficeGetActiveHealthSpecialPointHighlight) {
        const dataSpecialPoint = {
          description: desc.descriptionTh,
        } as ActiveHealthSpecialPointHighlight

        specialPointHighlight.push(dataSpecialPoint)
      }
    }

    return specialPointHighlight
  }

  const funcFormatBackofficeActiveHealthExerciseStatBarChart = useCallback(
    (exerciseStat: Array<ExerciseStat>) => {
      const stats: Array<ExerciseStatBarChart> = []
      for (const stat of exerciseStat) {
        const dataStat = {
          date:
            filterPeriod === ActivityPeriodDateFilterEnum.YEAR
              ? toThaiLocaleMonthAndYearWithDash(new Date(stat.date))
              : toThaiLocaleDayAndDateWithDash(new Date(stat.date)),
          value: activityFilter === ActivityFilterEnum.STEPS ? stat.step : stat.minute,
        } as ExerciseStatBarChart

        stats.push(dataStat)
      }

      return stats
    },
    [activityFilter, filterPeriod],
  )

  const funcCalculateTotalStep = (stats: Array<ExerciseStat>) => {
    let total = 0
    for (const stat of stats) {
      total += stat.step
    }
    return total
  }

  const funcCalculateTotalMinute = (stats: Array<ExerciseStat>) => {
    let total = 0
    for (const stat of stats) {
      total += stat.minute
    }
    return total
  }

  const funcCalculateTotalPoint = (stats: Array<ExerciseStat>) => {
    let total = 0
    for (const stat of stats) {
      total += stat.point
    }
    return total
  }

  const funcCalculateTotalBonusPoint = (stats: Array<ExerciseStat>) => {
    const allNull = stats.every((stat) => stat.wellPoint == null)
    const result = allNull
      ? undefined
      : stats.reduce((accumulator, stat) => {
          if (stat.wellPoint != null) {
            return accumulator + stat.wellPoint
          }
          return accumulator
        }, 0)

    return result
  }

  const handleNextRangeDateChart = () => {
    setRangeDateCurrent(rangeDateNext)
  }

  const handlePrevRangeDateChart = () => {
    setRangeDateCurrent(rangeDatePrevious)
  }

  const handleSelectedActivityFilter = (filter: ActivityFilterEnum) => {
    setActivityFilter(filter)
  }

  const handlePeriodChange = (filter: ActivityPeriodDateFilterEnum) => {
    setFilterPeriod(filter)
    if (filter === ActivityPeriodDateFilterEnum.WEEK) {
      setRangeDateCurrent(dayjs().format('YYYY-MM-DD'))
    }

    if (filter === ActivityPeriodDateFilterEnum.MONTH) {
      setRangeDateCurrent(dayjs().format('YYYY-MM'))
    }

    if (filter === ActivityPeriodDateFilterEnum.YEAR) {
      setRangeDateCurrent(dayjs().format('YYYY'))
    }
  }

  const getBackofficeActiveHealthExerciseStatPeriod = (filter: ActivityPeriodDateFilterEnum) => {
    if (filter === ActivityPeriodDateFilterEnum.WEEK) {
      return BackofficeActiveHealthExerciseStatPeriodEnum.WEEKLY
    }

    if (filter === ActivityPeriodDateFilterEnum.MONTH) {
      return BackofficeActiveHealthExerciseStatPeriodEnum.MONTHLY
    }

    if (filter === ActivityPeriodDateFilterEnum.YEAR) {
      return BackofficeActiveHealthExerciseStatPeriodEnum.YEARLY
    }

    return BackofficeActiveHealthExerciseStatPeriodEnum.WEEKLY
  }

  const getBackofficeActiveHealthSpecialPointHighlightPeriod = (
    filter: ActivityPeriodDateFilterEnum,
  ) => {
    if (filter === ActivityPeriodDateFilterEnum.WEEK) {
      return BackofficeActiveHealthSpecialPointHighlightPeriodEnum.WEEKLY
    }

    if (filter === ActivityPeriodDateFilterEnum.MONTH) {
      return BackofficeActiveHealthSpecialPointHighlightPeriodEnum.MONTHLY
    }

    if (filter === ActivityPeriodDateFilterEnum.YEAR) {
      return BackofficeActiveHealthSpecialPointHighlightPeriodEnum.YEARLY
    }

    return BackofficeActiveHealthSpecialPointHighlightPeriodEnum.WEEKLY
  }

  const [
    getBackofficeGetUserExerciseStat,
    {
      loading: loadingGetBackofficeGetUserExerciseStatQuery,
      refetch: refetchBackofficeGetUserExerciseStat,
    },
  ] = useLazyQuery(backofficeActiveHealthExercise.query.backofficeActiveHealthExerciseStat, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        if (data && data?.backofficeGetUserExerciseStat) {
          const stats = funcFormatBackofficeActiveHealthExerciseStat(data)
          setExerciseStat(stats)

          const statBarCharts = funcFormatBackofficeActiveHealthExerciseStatBarChart(stats)
          setExerciseBarChart(statBarCharts)

          setTotalStep(funcCalculateTotalStep(stats))
          setTotalMinute(funcCalculateTotalMinute(stats))
          setTotalPoint(funcCalculateTotalPoint(stats))
          setTotalBonusPoint(funcCalculateTotalBonusPoint(stats))

          if (stats.length > 0) {
            setStartDateStat(new Date(stats[0].date))
            setEndDateStat(new Date(stats[stats.length - 1].date))
          }

          setRangeDateNext(data?.backofficeGetUserExerciseStat.meta.next)
          setRangeDatePrevious(data?.backofficeGetUserExerciseStat.meta.previous)
        }
      }
    },
  })

  const handleRefetchBackofficeGetUserExerciseStat = () => refetchBackofficeGetUserExerciseStat()

  const fetchBackofficeGetUserExerciseStat = (
    userId: string,
    period: BackofficeActiveHealthExerciseStatPeriodEnum,
    date: string,
    policyNumber: string,
  ) => {
    let input = {
      input: { userId, period, policyNumber },
    } as BackofficeActiveHealthExerciseStatInputInterface

    if (date && date !== '') {
      input.input.date = date
    }

    getBackofficeGetUserExerciseStat({
      variables: input,
    })
  }

  const [getBackofficeActiveHealthSpecialPointHighlight] = useLazyQuery(
    backofficeActiveHealthExercise.query.backofficeGetActiveHealthSpecialPointHighlight,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const fetchBackofficeActiveHealthSpecialPointHighlight = (
    period: BackofficeActiveHealthSpecialPointHighlightPeriodEnum,
    date: string,
  ) => {
    let input = {
      input: {
        period: period,
      },
    } as BackofficeActiveHealthSpecialPointHighlightInputInterface

    if (date && date !== '') {
      input.input.date = date
    }

    getBackofficeActiveHealthSpecialPointHighlight({
      variables: input,
    }).then(({ data }) => {
      if (data) {
        const specialHighlights = funcFormatBackofficeActiveHealthSpecialHighlight(data)
        setSpecialHighlights(specialHighlights)
      }
    })
  }

  const [
    getBackofficeActiveHealthLastSync,
    { loading: loadingBackofficeActiveHealthLastSyncQuery },
  ] = useLazyQuery(backofficeActiveHealthExercise.query.backofficeGetActiveHealthPolicy, {
    fetchPolicy: 'no-cache',
  })

  const fetchBackofficeActiveHealthLastSync = (userId: string, policyNumber: string) => {
    let input = {
      userId,
      policyNumber,
    } as BackofficeGetActiveHealthPolicyInputInterface

    getBackofficeActiveHealthLastSync({
      variables: input,
    }).then(({ data }) => {
      if (data && data.backofficeGetActiveHealthPolicy.watchLastSyncedAt) {
        return setLastSync(data.backofficeGetActiveHealthPolicy.watchLastSyncedAt)
      } else {
        return setLastSync('')
      }
    })
  }

  const isComponentLoading =
    loadingBackofficeActiveHealthLastSyncQuery || loadingGetBackofficeGetUserExerciseStatQuery

  useEffect(() => {
    const policyDetailId = policyDetail.id
    const policyNumber = policy.policyNumber

    if (policyDetailId && policyNumber) {
      fetchBackofficeGetUserExerciseStat(
        policyDetailId,
        getBackofficeActiveHealthExerciseStatPeriod(filterPeriod),
        rangeDateCurrent,
        policyNumber,
      )
      fetchBackofficeActiveHealthSpecialPointHighlight(
        getBackofficeActiveHealthSpecialPointHighlightPeriod(filterPeriod),
        rangeDateCurrent,
      )
      fetchBackofficeActiveHealthLastSync(policyDetailId, policyNumber)
    }
  }, [policyDetail.id, rangeDateCurrent])

  useEffect(() => {
    const statBarCharts = funcFormatBackofficeActiveHealthExerciseStatBarChart(exerciseStat)
    setExerciseBarChart(statBarCharts)
  }, [activityFilter, exerciseStat, funcFormatBackofficeActiveHealthExerciseStatBarChart])

  return {
    policyDetail,
    exerciseStat,
    exerciseBarChart,
    startDateStat,
    endDateStat,
    totalStep,
    totalMinute,
    totalPoint,
    totalBonusPoint,
    specialHighlights,
    lastSync,
    handleNextRangeDateChart,
    handlePrevRangeDateChart,
    handleSelectedActivityFilter,
    handlePeriodChange,
    handleRefetchBackofficeGetUserExerciseStat,
    isComponentLoading,
  }
}

export default ActivityTabHook
