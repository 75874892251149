import styled from '@emotion/styled'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

export const Container = styled('div')`
  .leaflet-control-container {
    position: absolute;
    right: 56px;
  }
`

export const LoadingContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
`

export const SpinAntd = styled(Spin)``

export const IconLoading = styled(LoadingOutlined)``
