/**
 * For detecting user operating system platforms is Windows OS.
 */
export const isWindowsOs = () => {
  // @ts-ignore
  if (window?.navigator?.userAgentData) {
    // This scope is an experimental feature.
    // In some browser may have not supported it.
    // See reference at https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/platform
    // @ts-ignore
    const userAgentData = window.navigator.userAgentData

    return userAgentData.platform === 'Windows'
  } else if (window?.navigator?.platform) {
    // WARNING: This scope to support older browser.
    // This feature is no longer recommended (Deprecated)
    // But in some browser may have already been removed from the relevant web standards.
    // See browser compatability at https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform

    return window.navigator.platform.startsWith('Win')
  }

  return false
}
