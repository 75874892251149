import { OrderTabEnum } from '@/constant/TA_TRANSACTION'
import { useState } from 'react'

export default function TaTransactionHook() {
  const [orderTab, setOrderTab] = useState(OrderTabEnum.DRAFT)

  const handleSetOrderTab = (status: OrderTabEnum) => setOrderTab(status)

  return { orderTab, handleSetOrderTab }
}
