import { keepPreviousData, useQuery } from '@tanstack/react-query'
import {
  OrderItemPaginationResponse,
  OrderPaginationParamsType,
  OrderTabQuery,
  PaginateQuery,
} from './types'
import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'

const fetchOrderPagination = async (query: OrderTabQuery, pagination?: PaginateQuery) => {
  const response = await httpClientPrivate.get<OrderItemPaginationResponse>(
    `${config.restUrl}/v1/backoffice-commerce/order`,
    {
      params: {
        ...query,
        ...pagination,
      },
      paramsSerializer: {
        indexes: null,
      },
    },
  )

  return response.data
}

const useOrderPagination = (param: OrderPaginationParamsType) => {
  const query = useQuery({
    queryKey: ['useOrderPagination', param.query, param?.pagination],
    queryFn: async () => fetchOrderPagination(param.query, param?.pagination),
    placeholderData: keepPreviousData,
  })

  return { ...query }
}

export { useOrderPagination, fetchOrderPagination }
