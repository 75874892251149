import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import backofficeTeam from '@/gql/backofficeTeam'
import { GetAllBackofficeTeam } from '@/gql/backofficeTeam/backofficeGetAllBackofficeTeam/interfaces'
import { ModeTeamEnum } from '@/recoil/teamModal/types'
import { useLazyQuery, useMutation } from '@apollo/client'
import { TabsProps } from 'antd'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import TabTeamMember from '../TabTeamMember/TabTeamMember'
import TabTeamMemberPermission from '../TabTeamMemberPermission/TabTeamMemberPermission'
import TeamModalHook from './TeamModal/TeamModal.hook'
import selectedTeamPermissionAtom from '@/recoil/selectedTeamPermission/atom'
import userAtom from '@/recoil/user'
import { validatePerm } from '@/libs/validatePermission'
import message from '@/libs/message'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'

const TabPermissionManagementHook = () => {
  const [nameTeamList, setNameTeams] = useState<Array<GetAllBackofficeTeam>>()
  const [selectedTeamPermission, setSelectedTeamPermission] = useRecoilState(
    selectedTeamPermissionAtom,
  )
  const user = useRecoilValue(userAtom)
  const isPowerUser = validatePerm([BACKOFFICE_USER_PERMISSION.USR010101], user.permissions)
  const { setConfirmModal } = ConfirmModalHook()
  const { setTeamModal } = TeamModalHook()

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `สมาชิกทีม`,
      children: <TabTeamMember />,
    },
    {
      key: '2',
      label: `สิทธิ์ในการใช้งาน`,
      children: <TabTeamMemberPermission />,
    },
  ]

  const handleSelectTeam = (list: GetAllBackofficeTeam) => setSelectedTeamPermission(list)

  const [
    getAllBackofficeTeamQuery,
    { loading: loadGetAllBackofficeTeamQuery, refetch: refetchGetAllBackofficeTeamQuery },
  ] = useLazyQuery(backofficeTeam.query.backofficeGetAllBackofficeTeam, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.backofficeGetAllBackofficeTeam.length > 0) {
        setNameTeams(data.backofficeGetAllBackofficeTeam)
        setSelectedTeamPermission(data.backofficeGetAllBackofficeTeam[0])
      }
    },
  })

  const [postBackofficeTeamDeleteTeamMutation, { error: errorBackofficeTeamDeleteTeam }] =
    useMutation(backofficeTeam.mutation.backofficeTeamDeleteTeam, {
      fetchPolicy: 'no-cache',
    })

  const handleCreateTeamModal = () => {
    setTeamModal({
      title: 'สร้างชื่อทีม',
      onOkFunction: () => {
        refetchGetAllBackofficeTeamQuery()
        setTeamModal({
          isVisible: false,
          onOkFunction: Function,
          mode: ModeTeamEnum.CREATE,
          okButtonProps: { type: 'primary', danger: false },
        })
      },
      isVisible: true,
      okButtonProps: { type: 'primary', danger: false },
      mode: ModeTeamEnum.CREATE,
    })
  }

  const handleEditTeamModal = () => {
    if (selectedTeamPermission?.name) {
      setTeamModal({
        title: 'เปลี่ยนชื่อทีม',
        dataTeam: selectedTeamPermission,
        onOkFunction: () => {
          refetchGetAllBackofficeTeamQuery()
          setTeamModal({
            isVisible: false,
            onOkFunction: Function,
            mode: ModeTeamEnum.CREATE,
            okButtonProps: { type: 'primary', danger: false },
            dataTeam: undefined,
          })
        },
        isVisible: true,
        okButtonProps: { type: 'primary', danger: false },
        mode: ModeTeamEnum.UPDATE,
      })
    }
  }

  useEffect(() => {
    const isPowerUser = validatePerm([BACKOFFICE_USER_PERMISSION.USR010101], user.permissions)

    // for power user
    // TODO: refactor, workaround
    if (isPowerUser) {
      getAllBackofficeTeamQuery()
    } else if (user.backofficeTeams && user.backofficeTeams.length > 0) {
      // for other head role user
      setNameTeams(user.backofficeTeams as Array<GetAllBackofficeTeam>)
      setSelectedTeamPermission(user.backofficeTeams[0])
    }
  }, [])

  const handleRemoveTeam = () => {
    if (selectedTeamPermission?.id)
      postBackofficeTeamDeleteTeamMutation({
        variables: {
          id: selectedTeamPermission.id,
        },
      })
        .finally(() => {
          refetchGetAllBackofficeTeamQuery()
          setConfirmModal({
            isVisible: false,
            onOkFunction: Function,
            dataTestId: undefined,
          })
        })
        .catch(() => {
          message.error({
            content: `ปัญหา: ${errorBackofficeTeamDeleteTeam?.message}`,
            duration: 10,
          })
        })
  }

  return {
    setConfirmModal,
    nameTeamList,
    items,
    setTeamModal,
    handleCreateTeamModal,
    handleSelectTeam,
    selectedTeamPermission,
    handleRemoveTeam,
    handleEditTeamModal,
    user,
    isPowerUser,
    loadGetAllBackofficeTeamQuery,
  }
}

export default TabPermissionManagementHook
