import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeUpdateWelcomeCallDataInterface,
  BackofficeUpdateWelcomeCallInputInterface,
} from './interfaces'

export const backofficeUpdateWelcomeCall: TypedDocumentNode<
  BackofficeUpdateWelcomeCallDataInterface,
  BackofficeUpdateWelcomeCallInputInterface
> = gql`
  mutation BackofficeUpdateWelcomeCall($input: UpdateWelcomeCallInput!) {
    backofficeUpdateWelcomeCall(input: $input) {
      policyNumber
      referenceNumber
      insuredName
      phoneNumber
      planCode
      address
      remarkPolicy
      effectiveDate
      purchaseChannel
      paymentMethod
      watchDeliveredDate
      watchDeliveredBy
      watchDeliveredTrackingNo
      watchSerialNumber
      callStatus
      calledBy {
        firstname
        lastname
      }
      remark
      createdAt
      updatedAt
    }
  }
`

export default backofficeUpdateWelcomeCall
