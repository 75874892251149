export enum DayOfWeekEnum {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export const DAY_OF_WEEK = {
  [DayOfWeekEnum.MONDAY]: 'วันจันทร์',
  [DayOfWeekEnum.TUESDAY]: 'วันอังคาร',
  [DayOfWeekEnum.WEDNESDAY]: 'วันพุธ',
  [DayOfWeekEnum.THURSDAY]: 'วันพฤหัสบดี',
  [DayOfWeekEnum.FRIDAY]: 'วันศุกร์',
  [DayOfWeekEnum.SATURDAY]: 'วันเสาร์',
  [DayOfWeekEnum.SUNDAY]: 'วันอาทิตย์',
}

export interface LocationChargingStationInterface {
  id: string
  displayNameTh: string
  displayNameEn: string
  address1Th: string
  address1En: string
  address2Th: string
  address2En: string
  description: string
  phones: Array<string>
  faxes: Array<string>
  lat: number
  long: number
  subDistrictCode: string
  locationTypeId: number
  isActive: boolean
  province: {
    displayNameTh: string
    displayNameEn: string
    districts: Array<{
      displayNameTh: string
      displayNameEn: string
      subDistricts: Array<{
        displayNameTh: string
        displayNameEn: string
        postCode: string
      }>
    }>
  }
  locationTag2Ids: Array<string>
  locationTags: Array<{
    id: string
    displayNameTh: string
    displayNameEn: string
    description: string
    priority: number
  }>
  referenceCode: Array<string>
  locationType: {
    id: number
    displayNameTh: string
    displayNameEn: string
    description: string
    priority: number
    locationSubType: {
      id: number
      locationTypeId: number
      displayNameTh: string
      displayNameEn: string
      description: string
      priority: number
      refCode: string
    }
  }
  locationSubTypes: Array<{
    id: number
    locationTypeId: number
    displayNameTh: string
    displayNameEn: string
    description: string
    priority: number
    refCode: string
  }>
  motorEvChargingProvider: {
    id: string
    name: string
    chargeRates: Array<{
      displayTh: string
      displayEn: string
      currentType: string
    }>
  }
  location2MotorEvChargingType: Array<{
    location2Id: string
    motorEvChargingTypeId: string
    quantity: number
    motorEvChargingType: {
      id: string
      connectorType: string
      currentType: string
    }
  }>
  location2OpenTime: Array<{
    location2Id: string
    dayOfWeek: DayOfWeekEnum
    isOpen24Hours: boolean
    isClosed: boolean
    openTime: string
    closeTime: string
  }>
}

export interface BackofficeGetAllLocationChargingStationsInterface {
  backofficeGetAllLocationChargingStations: Array<LocationChargingStationInterface>
}
