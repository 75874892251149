import { SelectedTeamPermissionType } from '../selectedTeamPermission/types'

export enum ModeTeamEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

export type TeamModalProps = {
  title?: string
  dataTeam?: SelectedTeamPermissionType
  isVisible: boolean
  onOkFunction: Function
  okText?: string
  okButtonProps?: {
    type: 'default' | 'link' | 'text' | 'ghost' | 'primary' | 'dashed'
    danger: boolean
  }
  mode?: ModeTeamEnum
}
