import { atom } from 'recoil'

import { EditActiveBonusModalProps } from './types'

const initEditActiveBonusModal: EditActiveBonusModalProps = {
  open: false,
  isEditSuccess: false,
  // onOkFunction: Function,
}

const editActiveBonusModalAtom = atom({
  key: 'editActiveBonusModalAtom',
  default: initEditActiveBonusModal,
})

export default editActiveBonusModalAtom
