import { useRecoilState } from 'recoil'
import diseaseModalAtom from '@/recoil/diseaseModal'

function DiseaseModalHook() {
  const [diseaseModal, setDiseaseModal] = useRecoilState(diseaseModalAtom)

  const handleCloseModal = () =>
    setDiseaseModal({
      open: false,
      diseases: null,
    })

  return { diseaseModal, handleCloseModal }
}

export default DiseaseModalHook
