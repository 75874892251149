import FormItem from 'antd/lib/form/FormItem'
import {
  Container,
  Form,
  Select,
  Option,
  RangePicker,
  RadioGroup,
  Radio,
  Space,
  Button,
} from './styles'
import ReportHook from './Report.hook'

function Report() {
  const { onDownloadReport } = ReportHook()

  return (
    <Container>
      <Form layout="vertical" onFinish={onDownloadReport}>
        <FormItem name="report" label="รายงาน" rules={[{ required: true, message: 'Required!' }]}>
          <Select>
            <Option value="motor">Motor</Option>
          </Select>
        </FormItem>

        <FormItem name="section" label="หัวข้อ" rules={[{ required: true, message: 'Required!' }]}>
          <Select>
            <Option value="motorTviConnect">การผูก TVI Connect</Option>
          </Select>
        </FormItem>
        <FormItem
          name="rangeDate"
          label="ช่วงเวลา"
          rules={[{ required: true, message: 'Required!' }]}
        >
          {/* <RangePicker format="YYYY/MM/DD" showTime  /> */}
        </FormItem>
        <FormItem name="isMapped" rules={[{ required: true, message: 'Required!' }]}>
          <RadioGroup>
            <Space direction="vertical">
              <Radio value={true}>เลือกจากวันที่ผูก</Radio>
              <Radio value={false}>เลือกจากวันที่อัพเดท</Radio>
            </Space>
          </RadioGroup>
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">
            ดาวน์โหลดรายงาน
          </Button>
        </FormItem>
      </Form>
    </Container>
  )
}

export default Report
