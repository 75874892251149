import { Form, RadioChangeEvent } from 'antd'
import { ContactPersonResponse } from '@/services/order/type'
import { useEffect, useState } from 'react'
import { useUpdateContactPerson } from '@/services/order/useUpdateContactPerson'
import { TitleTypeEnum } from '@/constant/TA_TRANSACTION'
import { useQueryClient } from '@tanstack/react-query'
import { useNameTitle } from '@/services/order/useNameTitle'
import _ from 'lodash'

type T =
  | 'email'
  | 'firstname'
  | 'lastname'
  | 'mobileNumber'
  | 'title'
  | 'companyNumber'
  | 'companyName'
  | 'companyBranchNumber'
  | 'companyTitle'

const TaContactPersonModalHook = ({
  orderId,
  contactPerson,
}: {
  orderId: string
  contactPerson: ContactPersonResponse
}) => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm<ContactPersonResponse>()
  const [email, setEmail] = useState<string>(contactPerson.email)
  const [firstname, setFirstname] = useState<string>(contactPerson.firstname)
  const [lastname, setLastname] = useState<string>(contactPerson.lastname)
  const [mobileNumber, setMobileNumber] = useState<string>(contactPerson.mobileNumber)
  const [titleId, setTitleId] = useState<string>(contactPerson.title.id)
  const [type, setType] = useState<string>(contactPerson.type)
  const [isPerson, setIsPerson] = useState<boolean>()
  const [companyNumber, setCompanyNumber] = useState<string>(contactPerson.companyNumber || '')
  const [companyName, setCompanyName] = useState<string>(contactPerson.companyName || '')
  const [companyBranchNumber, setCompanyBranchNumber] = useState<string>(
    contactPerson.companyBranchNumber || '',
  )
  const [companyTitleId, setCompanyTitleId] = useState<string>(
    contactPerson?.companyTitle?.id || '',
  )
  const [personTitleId] = useState<string>(contactPerson.title.id)
  const [isFormValid, setIsFormValid] = useState<boolean>(true)

  const useTitle = () => {
    const titles = useNameTitle().data
    const nameTitlePersonOptions = titles
      ?.filter((i) => i.type === TitleTypeEnum.PERSON)
      .map((i) => ({ value: i.id, label: i.titleTh }))
    const nameTitleCorporationOptions = titles
      ?.filter((i) => i.type === TitleTypeEnum.CORPORATION)
      .map((i) => ({ value: i.id, label: i.titleTh }))
    return { nameTitlePersonOptions, nameTitleCorporationOptions }
  }

  const useUpdateContact = () => {
    const contactPersonId = contactPerson?.id
    const data = {
      address: contactPerson?.address,
      email,
      firstname,
      lastname,
      mobileNumber,
      subDistrictCode: contactPerson.subDistrict.subDistrictCode,
      titleId,
      type,
      companyNumber,
      companyBranchNumber,
      companyName,
      companyTitleId,
    }
    return useUpdateContactPerson(orderId, contactPersonId, data)
  }

  const handleChangeInput = (name: T, value: string) => {
    switch (name) {
      case 'email':
        setEmail(value)
        break
      case 'firstname':
        setFirstname(value)
        break
      case 'lastname':
        setLastname(value)
        break
      case 'mobileNumber':
        setMobileNumber(value)
        break
      case 'title':
        setTitleId(value)
        break
      case 'companyNumber':
        setCompanyNumber(value)
        break
      case 'companyName':
        setCompanyName(value)
        break
      case 'companyBranchNumber':
        setCompanyBranchNumber(value)
        break
      case 'companyTitle':
        setCompanyTitleId(value)
        break
    }
  }

  const handleChangeRadio = (name: 'type', value: RadioChangeEvent) => {
    switch (name) {
      case 'type':
        setType(value.target.value)
        if (value.target.value === TitleTypeEnum.PERSON) setIsPerson(true)
        if (value.target.value === TitleTypeEnum.CORPORATION) setIsPerson(false)
        break
    }
  }

  const refetchOrderDetail = () => {
    queryClient.refetchQueries({ queryKey: ['useOrderDetail'] }).then(null)
  }

  const validateForm = () => {
    const n = form.getFieldsValue()
    const isEmptyValue = [
      n.firstname === '',
      n.lastname === '',
      n.mobileNumber === '',
      n.email === '',
    ]
    if (type === TitleTypeEnum.CORPORATION) {
      isEmptyValue.push(
        _.isEmpty(n.companyName),
        _.isEmpty(n.companyNumber),
        _.isEmpty(n.companyBranchNumber),
        _.isEmpty(companyTitleId),
      )
    }
    if (_.some(isEmptyValue)) {
      setIsFormValid(false)
    } else {
      setIsFormValid(true)
    }
  }

  useEffect(() => {
    if (contactPerson) {
      if (
        isPerson === false &&
        (companyName === '' || companyNumber === '' || companyBranchNumber === '')
      ) {
        setIsFormValid(false)
      }
      if (type !== TitleTypeEnum.PERSON) {
        setIsPerson(false)
      } else {
        setIsPerson(true)
      }
      form.setFieldsValue({
        ...contactPerson,
      })
    }
  }, [contactPerson, form, isPerson, type])

  return {
    form,
    isPerson,
    companyTitleId,
    personTitleId,
    isFormValid,
    type,
    validateForm,
    useTitle,
    useUpdateContact,
    handleChangeInput,
    handleChangeRadio,
    refetchOrderDetail,
  }
}

export default TaContactPersonModalHook
