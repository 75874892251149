import { useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'

import { useOrderApplicationForm } from '@/services/order/useOrderApplicationForm'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { UserSelectStyle } from '@/components/common/Table/styles'
import {
  BENEFICIARY_TYPE_LAW,
  BeneficiaryTypeEnum,
  OrderStatusEnum,
  OrderTabEnum,
} from '@/constant/TA_TRANSACTION'
import { validatePerm } from '@/libs/validatePermission'
import { ActionBlock, ButtonIcon, InformationContainer } from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import { ApplicationFormResponse } from '@/services/order/useOrderApplicationForm/types'

const TaTransactionOrderCardTravelerHook = ({
  orderId,
  orderStatus,
}: {
  orderId: string
  orderStatus: OrderStatusEnum
}) => {
  const location = useLocation()
  const user = useRecoilValue(userAtom)
  const applicationForm = useOrderApplicationForm(orderId as string)
  const applicationFormColumns: ColumnsType<object> = [
    {
      width: 75,
      title: 'ลำดับ',
      align: 'center',
      render: (_value, _record, index) => index + 1,
    },
    {
      title: 'สัญชาติ',
      className: UserSelectStyle,
      dataIndex: ['nationality', 'thaiLanguage'],
    },
    {
      title: 'ชื่อผู้เดินทาง',
      className: UserSelectStyle,
      render: (applicationForm) => {
        return `${applicationForm.title.titleEn} ${applicationForm.firstname} ${applicationForm.lastname}`
      },
    },
    {
      title: 'วันเดือนปีเกิด',
      className: UserSelectStyle,
      dataIndex: ['dateOfBirth'],
    },
    {
      width: 75,
      title: 'อายุ',
      className: UserSelectStyle,
      dataIndex: ['age'],
    },
    {
      title: 'เลขบัตรประชาชน',
      className: UserSelectStyle,
      dataIndex: ['identityId'],
      render: (identityId) => {
        return identityId || '-'
      },
    },
    {
      title: 'หนังสือเดินทาง',
      className: UserSelectStyle,
      dataIndex: ['passportId'],
      render: (passportId) => {
        return passportId || '-'
      },
    },
    {
      title: 'เบอร์โทรศัพท์',
      className: UserSelectStyle,
      dataIndex: ['mobileNumber'],
    },
    {
      title: 'อีเมล',
      className: UserSelectStyle,
      dataIndex: ['email'],
    },
    {
      title: 'ที่อยู่',
      className: UserSelectStyle,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => {
        const address = `${value?.address} ${value?.subDistrict?.displayNameTh} ${value?.district?.displayNameTh} ${value?.province?.displayNameTh} ${value?.subDistrict?.postCode}`

        return (
          <InformationContainer placement="bottomLeft" title={address}>
            {address}
          </InformationContainer>
        )
      },
    },
    {
      title: 'ผู้รับประโยชน์ ',
      className: UserSelectStyle,
      render: (applicationForm) => {
        const isTypeLaw = applicationForm?.beneficiaryType === BeneficiaryTypeEnum.LAW
        return isTypeLaw ? BENEFICIARY_TYPE_LAW : applicationForm?.beneficiary?.name || '-'
      },
    },
    {
      title: 'ความสัมพันธ์ ',
      className: UserSelectStyle,
      render: (applicationForm) => {
        const isTypeLaw = applicationForm?.beneficiaryType === BeneficiaryTypeEnum.LAW
        return isTypeLaw
          ? BENEFICIARY_TYPE_LAW
          : applicationForm?.beneficiary?.beneficiaryRelation?.nameTh || '-'
      },
    },
    // filter order status and orders tab (use order tab to make sure the status is expired)
    validatePerm([BACKOFFICE_USER_PERMISSION.TA010205], user.permissions) &&
    ![OrderStatusEnum.PAID, OrderStatusEnum.PAYMENT_CONFIRMED, OrderStatusEnum.CANCELLED].includes(
      orderStatus,
    ) &&
    [OrderTabEnum.DRAFT, OrderTabEnum.WAITING_PAYMENT, OrderTabEnum.PAYMENT_FAILED].includes(
      location.state?.data?.tab,
    )
      ? {
          width: 60,
          render: (applicationForm) => {
            return (
              <ActionBlock>
                <ButtonIcon
                  src={IMAGE_URL.editIcon}
                  onClick={() => {
                    openModal(applicationForm)
                  }}
                />
              </ActionBlock>
            )
          },
        }
      : { width: 0 },
  ]

  const [selectedApplicationForm, setSelectedApplicationForm] =
    useState<ApplicationFormResponse | null>(null)

  const openModal = (applicationForm: ApplicationFormResponse) => {
    setSelectedApplicationForm(applicationForm)
  }

  const closeModal = () => {
    setSelectedApplicationForm(null)
  }

  return {
    applicationFormColumns,
    closeModal,
    openModal,
    selectedApplicationForm,
    applicationForm,
  }
}

export default TaTransactionOrderCardTravelerHook
