import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeUpdateBackofficeTeamDataInterface,
  BackofficeUpdateBackofficeTeamInputInterface,
} from './interfaces'

export const backofficeUpdateBackofficeTeam: TypedDocumentNode<
  BackofficeUpdateBackofficeTeamDataInterface,
  BackofficeUpdateBackofficeTeamInputInterface
> = gql`
  mutation BackofficeUpdateBackofficeTeam($input: BackofficeUpdateBackofficeTeamInput!) {
    backofficeUpdateBackofficeTeam(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`

export default backofficeUpdateBackofficeTeam
