import styled from '@emotion/styled'

import { LoadingProps } from './types'

export const Container = styled('div')<LoadingProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  opacity: 0.7;
  z-index: 1200;
  display: ${(props) => (props.isLoading ? 'flex' : 'none')};
`
export const Lottie = styled('div')`
  width: 500px;
  margin: 0 auto;
`
