import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetUserPolicyByPolicyNumberInputInterface,
  BackofficeGetUserPolicyByPolicyNumberDataInterface,
} from './interfaces'

const backofficeGetUserPolicyByPolicyNumber: TypedDocumentNode<
  BackofficeGetUserPolicyByPolicyNumberDataInterface,
  BackofficeGetUserPolicyByPolicyNumberInputInterface
> = gql`
  query BackofficeGetUserPolicyByPolicyNumber($policyNumber: String!) {
    backofficeGetUserPolicyByPolicyNumber(policyNumber: $policyNumber) {
      identityId
      insuredName
      policyNumber
      effectiveDate
      expiryDate
      vehicleNumber
      planName
      policySuperAppStatus
      insuredAddress
      insuredDistrict {
        displayNameTh
      }
      insuredProvince {
        displayNameTh
      }
      insuredPostCode
      deliveryAddress
      beneficiary
      insuredDateOfBirth
      ppuPlanType
      ppuTimeUsed
      ppuTimeLeft
      mtPolicyType
      campaignName
      garageGrade
      sumInsured
      compulsoryMotorInsurancePolicyNumber
      policyGroup
      ipdSumInsured
      opdSumInsured
      paMedicalExpenseLimit
      opdTotal
      opdLeft
      roomLimit
      paAccidentalDeath
      taOnOffCoverDaysTotal
      taOnOffCoverDaysLeft
      diseases {
        exceptedDiseaseDescription
      }
      carModelName
      carYear
      pvVehicleType
      additionalCoverages {
        additionalCampaignCoverage
      }
    }
  }
`

export default backofficeGetUserPolicyByPolicyNumber
