import { PolicyStatus } from './styles'
import { PolicyStatusTagInterface } from './interfaces'
import { POLICY_SUPER_APP_STATUS } from '@/constant/POLICY_SUPER_APP_STATUS'

function PolicyStatusTag({ policySuperAppStatus }: PolicyStatusTagInterface) {
  return (
    <PolicyStatus policySuperAppStatus={policySuperAppStatus}>
      {policySuperAppStatus ? POLICY_SUPER_APP_STATUS[policySuperAppStatus] : 'ไม่พบสถานะ'}
    </PolicyStatus>
  )
}

export default PolicyStatusTag
