import axios, { AxiosRequestConfig } from 'axios'
import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useRecoilState, useRecoilValue } from 'recoil'

import config from '@/config'
import deviceImportAtom from '@/recoil/deviceImport'
import { FileProps, DeviceImportProps } from './types'
import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import userAtom from '@/recoil/user'

function DeviceImportCardHook() {
  const user = useRecoilValue(userAtom)
  const [isUploadFail, setIsUploadFail] = useState<boolean>(false)
  const [deviceImport, setDeviceImport] = useRecoilState(deviceImportAtom)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [file, setFile] = useState<FileProps>({
    name: null,
    source: null,
  })
  const { setConfirmModal } = ConfirmModalHook()
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
    },
  })

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      // Set device import state to default, to prevent re-upload file.
      setIsUploadFail(false)
      setDeviceImport({
        successful: 0,
        failed: 0,
        devices: [],
        isUpload: false,
      } as DeviceImportProps)
      setFile({
        name: acceptedFiles[0].name,
        source: acceptedFiles[0],
      })
    }
  }, [acceptedFiles, setDeviceImport])

  useEffect(() => {
    if (fileRejections.length > 0) {
      setIsUploadFail(true)
      setDeviceImport({
        successful: 0,
        failed: 0,
        devices: [],
        isUpload: false,
      } as DeviceImportProps)
      setFile({
        name: null,
        source: null,
      })
    }
  }, [fileRejections, setDeviceImport])

  const handleRemoveFile = () => setFile({ name: null, source: null } as FileProps)

  const handleSubmit = async () => {
    try {
      const formData = new FormData()
      formData.append('file', acceptedFiles[0])
      setIsLoading(true)
      setConfirmModal({
        isVisible: false,
        onOkFunction: Function,
      })

      const options = {
        method: 'POST',
        url: `${config.graphqlRestEndpoint}/backoffice-ppu-motor/import-tvi-connect-devices`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.accessToken}`,
        },
      } as AxiosRequestConfig
      const result = await axios(options)

      // Set state of device import for display import result.
      if (result?.data?.data) {
        setIsLoading(false)
        setDeviceImport({ ...result.data.data, isUpload: true } as DeviceImportProps)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onModalVisible = () => {
    setConfirmModal({
      isVisible: true,
      onOkFunction: handleSubmit,
      title: 'คุณต้องการ Import ไฟล์นี้?',
    })
  }

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    file,
    handleRemoveFile,
    onModalVisible,
    deviceImport,
    isUploadFail,
    user,
    isLoading,
  }
}

export default DeviceImportCardHook
