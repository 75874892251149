import styled from '@emotion/styled'
import { Form, Input as FormInput } from 'antd'

export const FormItem = styled(Form.Item)`
  margin-bottom: 16px;

  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #000000;
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0 0 4px;
    white-space: initial;
    text-align: start;
  }
`

export const Input = styled(FormInput)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
`
