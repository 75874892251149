import { useRecoilState } from 'recoil'

import ResetBackofficeUserPasswordAtom from '@/recoil/resetBackofficeUserPassword'

function SuccessfullyResetPasswordCardHook() {
  const [resetBackofficeUserPassword] = useRecoilState(ResetBackofficeUserPasswordAtom)

  return {
    resetBackofficeUserPassword,
  }
}

export default SuccessfullyResetPasswordCardHook
