import { OnOffSwitchContainer, OnOffSwitch, InformationContainer, Information } from './styles'
import DetailCard from '../DetailCard'
import InformationGroup from '../InformationGroup'
import CoverageCardHook from './CoverageCard.hook'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'

function CoverageCard() {
  const {
    policy,
    filterField,
    user,
    onModalVisible,
    isPpuMotorPolicyActive,
    backofficeChangePpuMotorPolicyModeLoading,
    isOnOffPackage,
  } = CoverageCardHook()

  return (
    <DetailCard
      title="ความคุ้มครอง"
      visible={!!policy.effectiveDate}
      rightItem={
        isOnOffPackage ? (
          <OnOffSwitchContainer>
            <Information>เปิด-ปิดประกัน</Information>
            <OnOffSwitch
              onChange={onModalVisible}
              checked={isPpuMotorPolicyActive}
              disabled={!validatePerm([BACKOFFICE_USER_PERMISSION.MTP010101], user.permissions)}
              loading={backofficeChangePpuMotorPolicyModeLoading}
            />
          </OnOffSwitchContainer>
        ) : null
      }
      dataTestId="policy-detail-coverage-card"
    >
      <InformationContainer>
        {filterField.length > 0 &&
          filterField.map((field, index) => (
            <InformationGroup
              key={index}
              information={{
                title: field.title,
                detail: field.detail,
              }}
              infoIcon={field?.options?.info}
              evBadge={field?.options?.isEv}
              infoIconFunction={field?.options?.onChangeFunction}
              dataTestId={`policy-detail-coverage-card-${field.dataTestId}`}
            />
          ))}
      </InformationContainer>
    </DetailCard>
  )
}

export default CoverageCard
