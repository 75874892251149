import { atom } from 'recoil'

export interface AddTeamMemberModalType {
  isVisible: boolean
  onOkFunction?: Function
  onCancelFunction?: Function
  okButtonProps?: {
    type: 'default' | 'link' | 'text' | 'ghost' | 'primary' | 'dashed'
    danger: boolean
  }
}

const initAddTeamMemberModal: AddTeamMemberModalType = {
  isVisible: false,
  onOkFunction: Function,
  onCancelFunction: Function,
  okButtonProps: { type: 'primary', danger: false },
}

const addTeamMemberModalAtom = atom({
  key: 'addTeamMemberModalAtom',
  default: initAddTeamMemberModal,
})

export default addTeamMemberModalAtom
