import styled from '@emotion/styled'
import { Form as AntForm, Input as AntInput } from 'antd'
import CommonButton from '@/components/common/Button'
import { CustomerProfileProps } from './types'

export const Container = styled('div')<CustomerProfileProps>`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 100%;
  padding: 24px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  margin-bottom: 72px;
`
export const Name = styled('div')`
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: #323232;
  margin-bottom: 16px;
`

export const InformationContainer = styled('div')<{ isSixColumn: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.isSixColumn ? 'repeat(6, 1fr)' : 'repeat(5, 1fr)')};
`

export const InformationTitle = styled('div')`
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  margin-bottom: 4px;
`

export const Information = styled('div')<{ isOrangeText?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => (props.isOrangeText ? '#FFA100' : '#000000')};
`

export const InformationEditButton = styled('div')`
  display: flex;
  justify-content: flex-end;
`

export const Button = styled(CommonButton)`
  padding: 0 37px;
`

export const UpdateUserButton = styled(CommonButton)`
  padding: 0 37px;
  float: right;
  margin-left: 14px;
`

export const ModalTitle = styled('div')`
  font-weight: 600;
  font-size: 20px;
`
export const ModalSubtitle = styled('div')`
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #666666;
`
export const Form = styled(AntForm)``
export const FormItem = styled(AntForm.Item)``
export const Input = styled(AntInput)`
  border-radius: 4px;
`

export const EditIcon = styled('img')`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 8px;
`
