import React, { useEffect, useRef } from 'react'
import { useRecoilState } from 'recoil'
import lottie from 'lottie-web'

import loadingAtom from '@/recoil/loading'
import { Container, Lottie } from './styles'

function Loading() {
  const lottieElement = useRef<HTMLDivElement | null>(null)
  const [loading] = useRecoilState(loadingAtom)

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieElement.current as HTMLDivElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/lottie/loading.json',
    })
  }, [])

  return (
    <Container isLoading={loading.isLoading}>
      <Lottie ref={lottieElement} />
    </Container>
  )
}

export default Loading
