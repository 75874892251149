import { IMAGE_URL } from '@/constant/IMAGE'
import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  ButtonLink,
  CardWrapper,
  Content,
  IconSvg,
  Line,
  ListWrapper,
  Tabs,
  Title,
  TopicWrapper,
  Wrapper,
  Skeleton,
} from './styles'
import TabPermissionManagementHook from './TabPermissionManagement.hook'
import TeamModal from './TeamModal/TeamModal'
import TabTeamMember from '@/components/feature/UserPermissionPage/TabTeamMember/TabTeamMember'

const TabPermissionManagement = () => {
  const {
    setConfirmModal,
    nameTeamList,
    items,
    handleCreateTeamModal,
    handleSelectTeam,
    selectedTeamPermission,
    handleRemoveTeam,
    handleEditTeamModal,
    isPowerUser,
    loadGetAllBackofficeTeamQuery,
  } = TabPermissionManagementHook()

  return (
    <CardWrapper displayUnset={!isPowerUser}>
      {isPowerUser ? (
        <>
          <Wrapper>
            <Title>ทีม</Title>
            <Content>
              กรณีเพิ่มผู้ใช้งานใหม่ ต้องสร้างและระบุทีมของผู้ใช้งาน หากยังไม่มีทีมให้สร้างทีมเพิ่ม
            </Content>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateTeamModal}
              data-testid="role-manage-tab-create-team-button"
            >
              สร้างทีม
            </Button>
            <ListWrapper>
              {loadGetAllBackofficeTeamQuery ? (
                <>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </>
              ) : nameTeamList ? (
                nameTeamList.map((list) => {
                  return (
                    <ButtonLink
                      selected={list.id === selectedTeamPermission?.id}
                      onClick={() => handleSelectTeam(list)}
                      data-testid={`role-manage-tab-team-${list.id}`}
                    >
                      {list.name}
                    </ButtonLink>
                  )
                })
              ) : null}
            </ListWrapper>
          </Wrapper>
          <Line />
        </>
      ) : undefined}
      <Wrapper>
        {isPowerUser ? (
          <TopicWrapper>
            <Title>{selectedTeamPermission?.name || ''}</Title>
            <Button
              icon={<IconSvg src={IMAGE_URL.editIcon} />}
              onClick={handleEditTeamModal}
              data-testid="role-manage-tab-edit-team-button"
            >
              แก้ไข
            </Button>
            <Button
              icon={<IconSvg src={IMAGE_URL.deleteIcon} />}
              onClick={() => {
                setConfirmModal({
                  onOkFunction: () => handleRemoveTeam(),
                  isVisible: true,
                  title: 'คุณต้องการลบทีม',
                  bodyText: `คุณแน่ใจหรือไม่ที่จะทำการลบทีม "${selectedTeamPermission?.name}" `,
                  okText: 'ลบ',
                  okButtonProps: { type: 'primary', danger: true },
                  dataTestId: 'delete-team-modal',
                })
              }}
              data-testid="role-manage-tab-delete-team-button"
            >
              ลบทีม
            </Button>
          </TopicWrapper>
        ) : undefined}
        {isPowerUser ? (
          <Tabs defaultActiveKey="1" items={items} data-testid="role-manage-tab-member-role-tab" />
        ) : (
          <TabTeamMember />
        )}
      </Wrapper>
      <TeamModal />
    </CardWrapper>
  )
}

export default TabPermissionManagement
