import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { motorEvGql } from '@/gql'

function MapFilterHook() {
  const [previousSelectedTypes, setPreviousSelectedTypes] = useState<Array<string>>([])
  const [previousSelectedProviders, setPreviousSelectedProviders] = useState<Array<string>>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedTypes, setSelectedTypes] = useState<Array<string>>([])
  const [selectedProviders, setSelectedProviders] = useState<Array<string>>([])
  const [isConfirm, setIsConfirm] = useState<boolean>(false)
  const [backofficeGetAllMotorEvChargingTypes, { data: motorEvChargingTypes }] = useLazyQuery(
    motorEvGql.query.backofficeGetAllMotorEvChargingTypes,
  )
  const [backofficeGetAllMotorEvChargingProviders, { data: motorEvChargingProviders }] =
    useLazyQuery(motorEvGql.query.backofficeGetAllMotorEvChargingProviders)

  const handleOpenFilter = (isOpen: boolean) => {
    if (!isConfirm) {
      if (previousSelectedTypes.length > 0) {
        setSelectedTypes(previousSelectedTypes)
      }

      if (previousSelectedProviders.length > 0) {
        setSelectedProviders(previousSelectedProviders)
      }
    }

    setIsOpen(isOpen)
  }

  const selectFilterType = (filter: string) => {
    if (selectedTypes.indexOf(filter) < 0) {
      setSelectedTypes([...selectedTypes, filter])
    } else {
      const index = selectedTypes.indexOf(filter)
      const tempArray = [...selectedTypes]

      tempArray.splice(index, 1)
      setSelectedTypes(tempArray)
    }
  }

  const selectFilterProvider = (filter: string) => {
    if (selectedProviders.indexOf(filter) < 0) {
      setSelectedProviders([...selectedProviders, filter])
    } else {
      const index = selectedProviders.indexOf(filter)
      const tempArray = [...selectedProviders]

      tempArray.splice(index, 1)
      setSelectedProviders(tempArray)
    }
  }

  const resetAllFilters = () => {
    if (selectedProviders.length > 0) {
      setPreviousSelectedProviders(selectedProviders)
    }

    if (selectedTypes.length > 0) {
      setPreviousSelectedTypes(selectedTypes)
    }

    setSelectedProviders([])
    setSelectedTypes([])
  }

  const submit = (confirm: boolean) => {
    setIsConfirm(confirm)
  }

  const handlePreviousFilters = (providers: Array<string>, types: Array<string>) => {
    setPreviousSelectedTypes(types)
    setPreviousSelectedProviders(providers)
  }

  useEffect(() => {
    backofficeGetAllMotorEvChargingTypes()
    backofficeGetAllMotorEvChargingProviders()
  }, [])

  return {
    isOpen,
    handleOpenFilter,
    motorEvChargingTypes,
    motorEvChargingProviders,
    selectFilterType,
    selectFilterProvider,
    selectedTypes,
    selectedProviders,
    resetAllFilters,
    isConfirm,
    submit,
    handlePreviousFilters,
  }
}

export default MapFilterHook
