import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const Container = styled('div')`
  display: flex;
`

export const Text = styled('p')<{ select: boolean }>`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.select ? '#009640' : '#666666')};
  cursor: pointer;
`

export const StepTextCustomStyle = css`
  border-right: 1px solid #e5e5e5;
  padding-right: 8px;
`

export const ActivityTextCustomStyle = css`
  padding-left: 8px;
`
