import { ColumnsType } from 'antd/lib/table'
import { useReturnTimeReason } from '@/services/returnTimeReason/useReturnTimeReason'
import { toThaiLocaleShortDate } from '@/libs/date'
import dayjs from 'dayjs'
import { Badge } from 'antd'

type ReturnTimeReasonModalProps = { ppuMotorReturnTimeId: string }
type ReasonOption = { [key: string]: { text: string; color: string } }

function ReturnTimeReasonModalHook({ ppuMotorReturnTimeId }: ReturnTimeReasonModalProps) {
  const returnTimeReason = useReturnTimeReason({ ppuMotorReturnTimeId })
  const columns: ColumnsType<{}> = [
    {
      title: 'วันที่เริ่ม',
      dataIndex: 'start',
      render: (value) => {
        return toThaiLocaleShortDate(new Date(value))
      },
    },
    {
      title: 'เวลาเริ่ม',
      dataIndex: 'start',
      render: (value) => {
        return dayjs(value).format('HH:mm')
      },
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'end',
      render: (value) => {
        return toThaiLocaleShortDate(new Date(value))
      },
    },
    {
      title: 'เวลาสิ้นสุด',
      dataIndex: 'end',
      render: (value) => {
        return dayjs(value).format('HH:mm')
      },
    },
    { title: 'นาที', dataIndex: 'minutes' },
    {
      title: 'สถานะ',
      dataIndex: 'reason',
      render: (value) => {
        const option: ReasonOption = {
          STANDBY: { text: 'Standby', color: '#0290FF' },
          IDLE: { text: 'Idle', color: '#FF8F00' },
          TRAFFIC: { text: 'Traffic', color: '#EF2605' },
          NO_SIGNAL: { text: 'No-Signal', color: '#666666' },
        }
        const selected = option[value] || { text: 'N/A', color: '#0290FF' }
        return (
          <>
            <Badge color={selected.color} text={selected.text} />
          </>
        )
      },
    },
  ]
  return { columns, returnTimeReason }
}

export default ReturnTimeReasonModalHook
