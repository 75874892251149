import styled from '@emotion/styled'

export const Container = styled('div')`
  width: 100%;
  box-shadow: 0 0.5px 0 #c6c6c8;
  padding: 0.5px;
  display: flex;
`

export const Tab = styled('div')`
  cursor: pointer;
  margin-right: 24px;
  text-align: center;
`

export const Title = styled('p')<{ select?: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.select ? '#009640' : '#000000')};
  box-shadow: ${(props) => (props.select ? '0px 2px 0px #009640' : 'unset')};
  margin-bottom: 0;
  padding: 14px 0;
`
