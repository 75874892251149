import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import {
  Container,
  Title,
  Form,
  FormItem,
  Input,
  InputPassword,
  Checkbox,
  Button,
  ForgotPasswordLink,
  FormRight,
  FormRightLoginLogo,
  AppVersion,
  AppVersionContainer,
} from './styles'
import LoginFormHook from './LoginForm.hook'
import CONSTANT from '@/constant'
import config from '@/config/config'

function LoginForm() {
  const { user, handleLogin, modalContextHolder } = LoginFormHook()
  const location = useLocation()
  const loginLogoSrc = 'https://thaivivat.co.th/th/images/logo_trans_tvi.svg'
  const state = location.state as { referrer?: string }
  const referrer = state?.referrer || '/'

  if (user.isAuth) {
    return <Navigate to={{ pathname: referrer }} state={{ from: location }} />
  }

  return (
    <Container>
      {modalContextHolder}
      <Form onFinish={handleLogin} data-testid="login-form">
        <Title>Thaivivat backoffice</Title>
        <FormItem name="email">
          <Input placeholder="Email" data-testid="login-email-input" />
        </FormItem>
        <FormItem name="password">
          <InputPassword placeholder="Password" data-testid="login-password-input" />
        </FormItem>
        <FormItem>
          {/*<Checkbox>จำผู้ใช้งานนี้</Checkbox>*/}
          <ForgotPasswordLink to={CONSTANT.ROUTES.PASSWORD_FORGOT_PAGE}>
            ลืมรหัสผ่าน
          </ForgotPasswordLink>
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" data-testid="login-submit-button">
            เข้าสู่ระบบ
          </Button>
        </FormItem>
      </Form>
      <FormRight>
        <FormRightLoginLogo src={loginLogoSrc} />
        <AppVersionContainer>
          <AppVersion>v.{config.appVersion}</AppVersion>
        </AppVersionContainer>
      </FormRight>
    </Container>
  )
}

export default LoginForm
