import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useMutation } from '@apollo/client'
import message from '@/libs/message'

import policyAtom from '@/recoil/policy'
import policyDetailAtom from '@/recoil/policyDetail'
import deviceDetailAtom from '@/recoil/deviceDetail'
import diseaseModalAtom from '@/recoil/diseaseModal'
import { ppuMotorGql } from '@/gql'
import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import { PolicyGroupEnum } from '@/constant/POLICY_GROUP'
import { POLICY_SUPER_APP_STATUS } from '@/constant/POLICY_SUPER_APP_STATUS'
import { FieldTypes, InformationGroupTypes } from './types'
import userAtom from '@/recoil/user'
import { UserPolicyInterface } from '@/gql/ppuMotor/getAllUserPoliciesByIdentityId/interfaces'
import additionalCoverageModalAtom from '@/recoil/additionalCoverageModal/atom'
import { isEv } from '@/libs/ev'

function CoverageCardHook() {
  const { setConfirmModal } = ConfirmModalHook()
  const [policyGroup, setPolicyGroup] = useState<PolicyGroupEnum | null>(null)
  const [filterField, setFilterField] = useState<Array<FieldTypes> | []>([])
  const [informationGroups, setInformationGroups] = useState<InformationGroupTypes>([])
  const setDiseaseModal = useSetRecoilState(diseaseModalAtom)
  const setAdditionalCoverageModal = useSetRecoilState(additionalCoverageModalAtom)
  const user = useRecoilValue(userAtom)
  const policy = useRecoilValue(policyAtom)
  const policyDetail = useRecoilValue(policyDetailAtom)
  const [deviceDetail, setDeviceDetail] = useRecoilState(deviceDetailAtom)
  const [
    backofficeChangePpuMotorPolicyModeMutation,
    {
      error: backofficeChangePpuMotorPolicyModeError,
      loading: backofficeChangePpuMotorPolicyModeLoading,
    },
  ] = useMutation(ppuMotorGql.mutation.backofficeChangePpuMotorPolicyMode, {
    fetchPolicy: 'no-cache',
  })
  const isPpuMotorPolicyActive = deviceDetail.currentStatus === 'START'
  const isOnOffPackage =
    policyGroup === PolicyGroupEnum.ON_OFF_PACKAGE || policyGroup === PolicyGroupEnum.ON_OFF_TOP_UP

  const handleSetInformationGroup = (policy: UserPolicyInterface) => {
    const baseFields: Array<FieldTypes> = [
      {
        title: 'สถานะกรมธรรม์',
        detail: policy.policySuperAppStatus
          ? POLICY_SUPER_APP_STATUS[policy.policySuperAppStatus]
          : '',
        dataTestId: 'policySuperAppStatus',
      },
      {
        title: 'วันที่เริ่มต้นความคุ้มครอง',
        detail: policy.effectiveDate,
        dataTestId: 'effectiveDate',
      },
      {
        title: 'วันที่สิ้นสุดความคุ้มครอง',
        detail: policy.expiryDate,
        dataTestId: 'expiryDate',
      },
    ]
    const lastBaseFields: Array<FieldTypes> = [
      {
        title: 'สิทธิพิเศษ',
        detail: policy.additionalCoverages && policy.additionalCoverages.length ? 'มี' : 'ไม่มี',
        dataTestId: 'additionalCoverages',
        options: {
          info: policy.additionalCoverages && policy.additionalCoverages?.length > 0,
          onChangeFunction: () => onAdditionalCoverageModalOpen(),
        },
      },
    ]
    const informationGroups: InformationGroupTypes = [
      {
        policyGroups: [
          PolicyGroupEnum.MOTOR,
          PolicyGroupEnum.ON_OFF_TOP_UP,
          PolicyGroupEnum.ON_OFF_PACKAGE,
        ],
        fields: [
          ...baseFields,
          {
            title: 'แบบประกัน',
            detail: policy.mtPolicyType,
            dataTestId: 'mtPolicyType',
          },
          {
            title: 'ทุนประกัน',
            detail: policy.sumInsured,
            dataTestId: 'sumInsured',
          },
          {
            title: 'ทะเบียนรถยนต์',
            detail: policy.vehicleNumber,
            dataTestId: 'vehicleNumber',
          },
          {
            title: 'ยี่ห้อ รุ่นรถ',
            detail: `${policy.carModelName} ${policy.carYear}`,
            options: {
              isEv: isEv(policy.pvVehicleType || ''),
            },
            dataTestId: 'carModelName-carYear',
          },
          {
            title: 'เลขที่ กธ. ภาคบังคับ (พรบ.)',
            detail: policy.compulsoryMotorInsurancePolicyNumber,
            dataTestId: 'compulsoryMotorInsurancePolicyNumber',
          },
          {
            title: 'เข้าซ่อมโดยไม่เสียส่วนต่าง',
            detail: policy.garageGrade,
            dataTestId: 'garageGrade',
          },
          {
            title: 'ชั่วโมงคุ้มครองที่ใช้ไป',
            detail: policy.ppuTimeUsed,
            dataTestId: 'ppuTimeUsed',
          },
          {
            title: 'ชั่วโมงคุ้มครองคงเหลือ',
            detail: policy.ppuTimeLeft,
            dataTestId: 'ppuTimeLeft',
          },
          {
            title: 'ประเภทการเปิด/ปิด',
            detail: deviceDetail.currentMode,
            dataTestId: 'currentMode',
          },
          ...lastBaseFields,
        ],
      },
      {
        policyGroups: [PolicyGroupEnum.PA, PolicyGroupEnum.ACTIVE_HEALTH, PolicyGroupEnum.HEALTH],
        fields: [
          ...baseFields,
          {
            title: 'ความคุ้มครองสูงสุดต่อปี',
            detail:
              policyGroup === PolicyGroupEnum.PA ? policy.paAccidentalDeath : policy.ipdSumInsured,
            dataTestId: policyGroup === PolicyGroupEnum.PA ? 'paAccidentalDeath' : 'ipdSumInsured',
          },
          {
            title: 'วงเงินผู้ป่วยนอก',
            detail: policy.opdSumInsured,
            dataTestId: 'opdSumInsured',
          },
          {
            title: policyGroup === PolicyGroupEnum.PA ? 'วงเงินค่ารักษาอุบัติเหตุ' : 'ค่าห้อง',
            detail:
              policyGroup === PolicyGroupEnum.PA ? policy.paMedicalExpenseLimit : policy.roomLimit,
            dataTestId: policyGroup === PolicyGroupEnum.PA ? 'paMedicalExpenseLimit' : 'roomLimit',
          },
          {
            title: 'โรคที่ไม่คุ้มครอง',
            detail: policy.diseases.length ? 'มี' : 'ไม่มี',
            options: {
              info: policy.diseases.length > 0,
              onChangeFunction: () => onDiseaseModalOpen(),
            },
          },
          {
            title: 'จำนวนครั้งการรักษา OPD',
            detail: policy.opdTotal,
            dataTestId: 'opdTotal',
          },
          {
            title: 'จำนวนครั้งคงเหลือในการรักษา OPD',
            detail: policy.opdLeft,
            dataTestId: 'opdLeft',
          },
          ...lastBaseFields,
        ],
      },
      {
        policyGroups: [PolicyGroupEnum.TRAVEL, PolicyGroupEnum.TRAVEL_ON_OFF],
        fields: [
          ...baseFields,
          {
            title: 'จำนวนวันที่ซื้อ',
            detail: policy.taOnOffCoverDaysTotal,
            dataTestId: 'taOnOffCoverDaysTotal',
          },
          {
            title: 'จำนวนวันคงเหลือ',
            detail: policy.taOnOffCoverDaysLeft,
            dataTestId: 'taOnOffCoverDaysLeft',
          },
          ...lastBaseFields,
        ],
      },
      {
        policyGroups: [PolicyGroupEnum.FIRE, PolicyGroupEnum.MARINE, PolicyGroupEnum.OTHERS],
        fields: [
          ...baseFields,
          {
            title: 'ทุนประกัน',
            detail: policy.sumInsured,
            dataTestId: 'sumInsured',
          },
          ...lastBaseFields,
        ],
      },
    ]

    setInformationGroups(informationGroups)
  }

  useEffect(() => {
    if (backofficeChangePpuMotorPolicyModeError) {
      message.error({
        content: isPpuMotorPolicyActive ? 'ปิดความคุ้มครองไม่สำเร็จ' : 'เปิดความคุ้มครองไม่สำเร็จ',
      })
    }
  }, [backofficeChangePpuMotorPolicyModeError])

  useEffect(() => {
    if (policy && policy.policyGroup) {
      handleSetInformationGroup(policy)
      setPolicyGroup(policy.policyGroup)
    }
  }, [policy, deviceDetail])

  useEffect(() => {
    if (policyGroup && informationGroups.length > 0) {
      for (const group of informationGroups) {
        for (const info of group.policyGroups) {
          if (info.includes(policyGroup)) {
            setFilterField(group.fields)
            break
          }
        }
      }
    }
  }, [policyGroup, informationGroups])

  const onModalVisible = () => {
    setConfirmModal({
      isVisible: true,
      onOkFunction: handleFetchBackofficeChangePpuMotorPolicyMode,
      title: isPpuMotorPolicyActive ? 'คุณต้องการปิดความคุ้มครอง?' : 'คุณต้องการเปิดความคุ้มครอง?',
      dataTestId: 'active-ppu-modal',
    })
  }

  const onDiseaseModalOpen = () => {
    let diseases = policy.diseases.map((disease) => disease.exceptedDiseaseDescription)

    if (!diseases.length) {
      diseases = ['-']
    }

    setDiseaseModal({
      open: true,
      diseases: diseases,
    })
  }

  const onAdditionalCoverageModalOpen = () => {
    if (policy.additionalCoverages && policy.additionalCoverages.length > 0) {
      setAdditionalCoverageModal({
        open: true,
        additionalCoverages: policy.additionalCoverages,
      })
    }
  }

  const handleFetchBackofficeChangePpuMotorPolicyMode = async () => {
    if (policy.policyNumber && policyDetail.id) {
      const { data } = await backofficeChangePpuMotorPolicyModeMutation({
        variables: {
          input: {
            userId: policyDetail.id,
            policyNumber: policy.policyNumber,
            action: isPpuMotorPolicyActive ? 'INACTIVE' : 'MANUAL_START',
          },
        },
      })

      if (data) {
        const { currentMode, currentStatus } = data.backofficeChangePpuMotorPolicyMode

        setDeviceDetail({
          ...deviceDetail,
          currentMode: currentMode,
          currentStatus: currentStatus,
        })
      }

      setConfirmModal({
        isVisible: false,
        onOkFunction: Function,
        dataTestId: undefined,
      })

      message.success({
        content: isPpuMotorPolicyActive ? 'ปิดความคุ้มครองสำเร็จ' : 'เปิดความคุ้มครองสำเร็จ',
      })
    }
  }

  return {
    policy,
    filterField,
    user,
    onModalVisible,
    isPpuMotorPolicyActive,
    backofficeChangePpuMotorPolicyModeLoading,
    isOnOffPackage,
  }
}

export default CoverageCardHook
