import backofficeCreateActiveHealthSpecialPoint from './backofficeCreateActiveHealthSpecialPoint/BackofficeCreateActiveHealthSpecialPoint.gql'
import backofficeDeleteActiveHealthSpecialPoint from './backofficeDeleteActiveHealthSpecialPoint/backofficeDeleteActiveHealthSpecialPoing.gql'
import backofficeSearchActiveHealthSpecialPoint from './backofficeSearchActiveHealthSpecialPoint/backofficeSearchActiveHealthSpecialPoint.gql'
import backofficeUpdateActiveHealthSpecialPoint from './backofficeUpdateActiveHealthSpecialPoint/backofficeUpdateActiveHealthSpecialPoint.gql'

const activeHealthSpecialPointCampaign = {
  query: {
    backofficeSearchActiveHealthSpecialPoint,
  },
  mutation: {
    backofficeCreateActiveHealthSpecialPoint,
    backofficeUpdateActiveHealthSpecialPoint,
    backofficeDeleteActiveHealthSpecialPoint,
  },
}

export default activeHealthSpecialPointCampaign
