import { Form } from 'antd'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { ResetPasswordFormProps, ResetPasswordPageParamProps } from './types'
import { isMatchPassword } from '@/libs/validator-schema'
import { backofficeUserGql } from '@/gql'
import ResetBackofficeUserPasswordAtom from '@/recoil/resetBackofficeUserPassword'
import { ResetBackofficeUserPasswordProps } from '@/recoil/resetBackofficeUserPassword/types'

function ResetPasswordFormHook() {
  const { token } = useParams<ResetPasswordPageParamProps>()
  const [form] = Form.useForm()
  const [resetBackofficeUserPasswordMutation] = useMutation(
    backofficeUserGql.mutation.resetBackofficeUserPassword,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const [resetBackofficeUserPassword, setResetBackofficeUserPassword] = useRecoilState(
    ResetBackofficeUserPasswordAtom,
  )

  const onFormError = (content: string) => {
    form.setFields([
      {
        name: 'password',
        errors: [''],
      },
      {
        name: 'confirmPassword',
        errors: [content],
      },
    ])
  }

  const onValidatePassword = async (values: unknown | ResetPasswordFormProps) => {
    if (values) {
      const { password, confirmPassword } = values as ResetPasswordFormProps

      if (isMatchPassword(password)) {
        if (password === confirmPassword) {
          if (token) {
            const { data } = await resetBackofficeUserPasswordMutation({
              variables: {
                input: {
                  password,
                  token,
                },
              },
            })

            if (data) {
              setResetBackofficeUserPassword({
                ...data.resetBackofficeUserPassword,
                isSuccessful: true,
              } as ResetBackofficeUserPasswordProps)
            }
          }
        } else {
          onFormError('รหัสผ่านไม่ตรงกัน')
        }
      } else {
        onFormError('รูปแบบรหัสผ่านไม่ถูกต้อง')
      }
    }
  }

  return {
    onValidatePassword,
    form,
    resetBackofficeUserPassword,
  }
}

export default ResetPasswordFormHook
