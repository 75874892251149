import { useState } from 'react'
import { ActivityFilterEnum } from '@/constant/ACTIVITY_FILTER'

function ActivityFilterHook(handleSelectedActivityFilter: (filter: ActivityFilterEnum) => void) {
  const [filterSelected, setFilterSelected] = useState<ActivityFilterEnum>(ActivityFilterEnum.STEPS)

  const handleSetFilter = (filter: ActivityFilterEnum) => {
    handleSelectedActivityFilter(filter)
    setFilterSelected(filter)
  }

  return { handleSetFilter, filterSelected }
}

export default ActivityFilterHook
