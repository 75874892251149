import { gql, TypedDocumentNode } from '@apollo/client'
import {
  ForgotBackofficeUserPasswordDataInterface,
  ForgotBackofficeUserPasswordInputInterface,
} from './interfaces'

export const forgotBackofficeUserPassword: TypedDocumentNode<
  ForgotBackofficeUserPasswordDataInterface,
  ForgotBackofficeUserPasswordInputInterface
> = gql`
  mutation ForgotBackofficeUserPassword($input: ForgotBackofficeUserPasswordInput!) {
    forgotBackofficeUserPassword(input: $input) {
      id
      createdAt
      firstname
      lastname
      email
      staffId
      updatedAt
    }
  }
`

export default forgotBackofficeUserPassword
