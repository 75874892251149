import {
  ORDER_STATUS,
  OrderTabEnum,
  OrderStatusEnum,
  ApplicationFormStatusEnum,
} from '@/constant/TA_TRANSACTION'
import dayjs from 'dayjs'

export const findOrderTab = (
  orderStatus: OrderStatusEnum,
  appFormStatus: ApplicationFormStatusEnum,
  effectiveDate: string,
): OrderTabEnum | null => {
  const current = dayjs().add(2, 'hours')

  for (const [orderTab, { orderStatus: statusList, appFormStatus: formStatus }] of Object.entries(
    ORDER_STATUS,
  )) {
    const isConditionExpiry = [
      OrderStatusEnum.DRAFTED,
      OrderStatusEnum.SUBMITTED,
      OrderStatusEnum.PAYMENT_FAILED,
    ].includes(orderStatus)

    if (isConditionExpiry && current.isAfter(effectiveDate)) {
      return OrderTabEnum.CANCELLED
    }

    if (!statusList && formStatus?.includes(appFormStatus!)) {
      return orderTab as OrderTabEnum
    }

    if (!formStatus && statusList?.includes(orderStatus!)) {
      return orderTab as OrderTabEnum
    }

    if (statusList?.includes(orderStatus!) && formStatus?.includes(appFormStatus!)) {
      return orderTab as OrderTabEnum
    }
  }
  return null // Return null if no matching order tab is found
}
