import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { ColumnsType } from 'antd/lib/table'
import { fromEvent, of, tap } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { Badge, BadgeProps, TablePaginationConfig } from 'antd'
import dayjs from 'dayjs'
import { generatePath } from 'react-router'
import { activeHealthPolicyGql } from '@/gql'
import {
  ActiveHealthPolicyInputFilterInterface,
  ActiveHealthPolicyInterface,
  ActiveHealthPolicyMetaInterface,
} from '@/gql/activeHealthPolicy/backofficeSearchActiveHealthPolicy/interfaces'
import {
  ACTIVE_HEALTH_POLICY_STATUS,
  ActiveHealthPolicySearchableFieldEnum,
  ActiveHealthPolicyStatusEnum,
} from '@/constant/ACTIVE_HEALTH_POLICY'
import { EyeIcon } from './styles'
import CONSTANT from '@/constant'
import { PolicyManagementContext } from '@/contexts'
import { BadgeStatusEnum } from '@/constant/BADGE_STATUS'
import { IMAGE_URL } from '@/constant/IMAGE'
import { UserSelectStyle } from '@/components/common/Table/styles'

function ActiveHealthPolicyManagementTableHook() {
  const searchElement = document.getElementById('active-health-policy-search')
  const { policyStatus } = useContext(PolicyManagementContext)
  const [policies, setPolicies] = useState<Array<ActiveHealthPolicyInterface>>([])
  const [pagination, setPagination] = useState<ActiveHealthPolicyMetaInterface | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentPageSize, setCurrentPageSize] = useState<number>(10)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [
    searchActiveHealthPolicy,
    { data: activeHealthPolicyData, loading: activeHealthPolicyLoading },
  ] = useLazyQuery(activeHealthPolicyGql.query.backofficeSearchActiveHealthPolicy, {
    fetchPolicy: 'no-cache',
  })
  const navigate = useNavigate()

  const getColumns = (): ColumnsType<object> => [
    {
      title: 'ลำดับ',
      align: 'center',
      width: 90,
      render: (_value, _record, index) => index + 1,
    },
    {
      title: 'เลขอ้างอิง',
      dataIndex: 'referenceNumber',
      key: 'referenceNumber',
      className: UserSelectStyle,
      width: 140,
    },
    {
      title: 'เลขบัตรประชาชน',
      dataIndex: 'identityId',
      key: 'identityId',
      className: UserSelectStyle,
      width: 140,
    },
    {
      title: 'เลขที่กรมธรรม์',
      dataIndex: 'policyNumber',
      key: 'policyNumber',
      className: UserSelectStyle,
      width: 160,
    },
    {
      title: 'ชื่อผู้เอาประกัน',
      dataIndex: 'insuredName',
      key: 'insuredName',
      className: UserSelectStyle,
      width: 250,
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      className: UserSelectStyle,
      width: 140,
    },
    {
      title: 'แผนประกัน',
      dataIndex: 'plan',
      key: 'plan',
      className: UserSelectStyle,
      width: 110,
    },
    {
      title: 'วันเริ่มคุ้มครอง',
      dataIndex: 'startAt',
      width: 140,
      render: (startAt) => (startAt ? dayjs(startAt).format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'วันที่สิ้นสุดความคุ้มครอง',
      dataIndex: 'endAt',
      width: 200,
      render: (endAt) => (endAt ? dayjs(endAt).format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'วันที่แจ้งยกเลิก',
      dataIndex: 'cancelledAt',
      width: 140,
      render: (cancelledAt) => (cancelledAt ? dayjs(cancelledAt).format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: 300,
    },
    policyStatus === ActiveHealthPolicyStatusEnum.ALL
      ? {
          title: 'สถานะ',
          dataIndex: 'status',
          key: 'status',
          width: 200,
          render: (status: ActiveHealthPolicyStatusEnum) => {
            const badgeStatus = handleBadgeStatus(status)

            return (
              <div>
                <Badge status={badgeStatus} /> {ACTIVE_HEALTH_POLICY_STATUS[status]}
              </div>
            )
          },
        }
      : {
          width: 0,
        },
    {
      title: '',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (_, record, index) => {
        const data = record as ActiveHealthPolicyInterface
        const isClickable = !!data?.user?.id

        return isClickable ? (
          <EyeIcon
            src={IMAGE_URL.eyeIcon}
            onClick={() =>
              navigate(
                generatePath(CONSTANT.ROUTES.POLICY_DETAIL_PAGE, {
                  searchInput: data.policyNumber,
                }),
                {
                  state: {
                    referrer: CONSTANT.ROUTES.POLICY_MANAGEMENT_PAGE,
                    policyNumber: data.policyNumber,
                  },
                },
              )
            }
            data-testid={`policy-management-table-view-button-${index}`}
          />
        ) : undefined
      },
    },
  ]

  const handleBadgeStatus = (status: ActiveHealthPolicyStatusEnum): BadgeProps['status'] => {
    switch (status) {
      case ActiveHealthPolicyStatusEnum.ACTIVE:
      case ActiveHealthPolicyStatusEnum.EXPIRED:
        return BadgeStatusEnum.SUCCESS
      case ActiveHealthPolicyStatusEnum.NOT_YET:
        return BadgeStatusEnum.WARNING
      case ActiveHealthPolicyStatusEnum.CANCELLED:
        return BadgeStatusEnum.ERROR
      default:
        return BadgeStatusEnum.DEFAULT
    }
  }

  const handleOnChangeTable = ({ current, pageSize }: TablePaginationConfig) => {
    if (current && pageSize) {
      setCurrentPage(current)
      setCurrentPageSize(pageSize)
    }
  }

  const handleSetSearch = (key: string) =>
    of(setSearch(key)).pipe(
      tap(() => {
        setCurrentPage(1)
        setCurrentPageSize(10)
      }),
    )

  const handleOnSearchInputChange = (isTyping: boolean) => {
    setIsTyping(isTyping)
  }

  // fetch first time render
  useEffect(() => {
    searchActiveHealthPolicy({
      variables: {
        input: {
          page: currentPage,
          limit: currentPageSize,
        },
      },
    })
  }, [])

  useEffect(() => {
    if (activeHealthPolicyData && activeHealthPolicyData.backofficeSearchActiveHealthPolicy) {
      const { data, meta } = activeHealthPolicyData.backofficeSearchActiveHealthPolicy

      const policies = data.map((policy, index) => ({
        ...policy,
        index: index + 1,
      }))

      setPolicies(policies)
      setPagination(meta)
    }
  }, [activeHealthPolicyData])

  useEffect(() => {
    const filter: ActiveHealthPolicyInputFilterInterface = {
      status: undefined, // We instantiate as an undefined key cause if filter.status is not assigned by any values, then the filter is unnecessary for query data.
    }

    if (policyStatus) {
      const query =
        policyStatus === ActiveHealthPolicyStatusEnum.CANCELLED_WITH_REASON ? '$in' : '$eq'

      filter.status = `${query}:${policyStatus}`
    }

    searchActiveHealthPolicy({
      variables: {
        input: {
          page: currentPage,
          limit: currentPageSize,
          filter,
          searchBy: [
            ActiveHealthPolicySearchableFieldEnum.POLICY_NUMBER,
            ActiveHealthPolicySearchableFieldEnum.INSURED_NAME,
            ActiveHealthPolicySearchableFieldEnum.PHONE_NUMBER,
            ActiveHealthPolicySearchableFieldEnum.IDENTITY_ID,
            ActiveHealthPolicySearchableFieldEnum.REFERENCE_NUMBER,
          ],
          search,
        },
      },
    })
  }, [policyStatus, currentPage, currentPageSize, search])

  useEffect(() => {
    if (isTyping && searchElement) {
      fromEvent(searchElement, 'keyup')
        .pipe(
          debounceTime(1000),
          map((e: Event) => {
            const target = e.target as HTMLInputElement

            return target.value || ''
          }),
          distinctUntilChanged(),
          switchMap(handleSetSearch),
          tap(() => setIsTyping(false)),
        )
        .subscribe()
    }
  }, [isTyping, searchElement])

  return {
    policies,
    pagination,
    currentPage,
    currentPageSize,
    handleOnChangeTable,
    handleOnSearchInputChange,
    getColumns,
    activeHealthPolicyLoading,
  }
}

export default ActiveHealthPolicyManagementTableHook
