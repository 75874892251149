import Table from '@/components/common/Table'
import ManualDiscountTableHook from './ManualDiscountTable.hook'
import { SelectedRow, RadioGroup, TotalRecordText } from './styles'
import { ActiveHealthManualDiscountFilterApproveStatusEnum } from '@/gql/activeHealthPolicy/backofficeGetActiveHealthManualDiscountPagination/interfaces'

function ManualDiscountTable() {
  const {
    dataBackofficeGetActiveHealthManualDiscountPagination,
    loadingBackofficeGetActiveHealthManualDiscountPagination,
    columns,
    selectedRowKeys,
    radioOptions,
    getActiveHealthManualDiscount,
  } = ManualDiscountTableHook()

  return (
    <>
      <RadioGroup
        options={radioOptions}
        optionType="button"
        buttonStyle="solid"
        size="large"
        defaultValue=""
        onChange={({ target: { value } }) =>
          getActiveHealthManualDiscount(value as ActiveHealthManualDiscountFilterApproveStatusEnum)
        }
        data-testid="policy-detail-billing-tab-manual-discount-radio-group"
      />{' '}
      <TotalRecordText>{`ทั้งหมด ${
        dataBackofficeGetActiveHealthManualDiscountPagination
          ?.backofficeGetActiveHealthManualDiscountPagination?.data?.length || 0
      } รายการ`}</TotalRecordText>
      <Table
        rowClassName={(_value, index) => (index === selectedRowKeys[0] ? SelectedRow : '')}
        columns={columns}
        scroll={{
          x: '100vw',
        }}
        pagination={false}
        loading={loadingBackofficeGetActiveHealthManualDiscountPagination}
        dataSource={
          dataBackofficeGetActiveHealthManualDiscountPagination
            ?.backofficeGetActiveHealthManualDiscountPagination.data
        }
        data-testid="policy-detail-billing-tab-manual-discount-table"
      />
    </>
  )
}

export default ManualDiscountTable
