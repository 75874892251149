import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetPpuMotorUserPolicyMotorsDataInterface,
  BackofficeGetPpuMotorUserPolicyMotorsInputInterface,
} from './interfaces'

export const backofficeGetPpuMotorUserPolicyMotors: TypedDocumentNode<
  BackofficeGetPpuMotorUserPolicyMotorsDataInterface,
  BackofficeGetPpuMotorUserPolicyMotorsInputInterface
> = gql`
  query BackofficeGetPpuMotorUserPolicyMotors($input: BackofficeGetPpuMotorUserPolicyMotorsInput!) {
    backofficeGetPpuMotorUserPolicyMotors(input: $input) {
      id
      ppuMotorUserPolicy {
        currentMode
        currentStatus
        policyNumber
      }
      chassisNumber
      deviceId
      imei
      createdAt
      updatedAt
      unboundAt
      vendor
      eSimNumber
      serialNumber
      broker
    }
  }
`

export default backofficeGetPpuMotorUserPolicyMotors
