export enum SimStatusEnum {
  SIM_READY = 'SIM Ready',
  TEST = 'Test',
  COMMERCIAL = 'Commercial',
  SUSPEND = 'Suspend',
  TERMINATE = 'Terminate',
  NOT_FOUND = 'Not found',
}

export enum TrueJasperSimStatusEnum {
  ACTIVATED = 'ACTIVATED',
  ACTIVATION_READY = 'ACTIVATION_READY',
  DEACTIVATED = 'DEACTIVATED',
  INVENTORY = 'INVENTORY',
  PURGED = 'PURGED',
  REPLACED = 'REPLACED',
  RETIRED = 'RETIRED',
  TEST_READY = 'TEST_READY',
}

export enum TviConnectPartnerEnum {
  AIS = 'AIS',
  TRUE = 'TRUE',
}
