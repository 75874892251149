import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthBillingDiscountProblemPaginationDataInterface,
  BackofficeGetActiveHealthBillingDiscountProblemPaginationInputInterface,
} from './interfaces'

const backofficeGetActiveHealthBillingDiscountProblemPagination: TypedDocumentNode<
  BackofficeGetActiveHealthBillingDiscountProblemPaginationDataInterface,
  BackofficeGetActiveHealthBillingDiscountProblemPaginationInputInterface
> = gql`
  query BackofficeGetActiveHealthBillingDiscountProblemPagination($input: RequestPaginationInput!) {
    backofficeGetActiveHealthBillingDiscountProblemPagination(input: $input) {
      data {
        id
        status
        resolvedAt
        unpaidBillNumber
        resolvedBy {
          firstname
          lastname
        }
        activeHealthPolicyBilling {
          activeHealthPolicy {
            insuredName
            policyNumber
            phoneNumber
            plan
          }
          startDate
          billDate
          billingAmount
          totalPendingBill
          billingDiscount
          totalManualDiscount
          actualDiscount
          payDate
          premium
        }
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
        sortBy
        searchBy
        search
      }
    }
  }
`

export default backofficeGetActiveHealthBillingDiscountProblemPagination
