import backofficeGetAddresses from './backofficeGetAddresses/backofficeGetAddresses.gql'
import backofficeGetChargingLocationsByPolicyNumber from './backofficeGetChargingLocationsByPolicyNumber/backofficeGetChargingLocationsByPolicyNumber.gql'

const address = {
  query: {
    backofficeGetAddresses,
    backofficeGetChargingLocationsByPolicyNumber,
  },
  mutation: {},
}

export default address
