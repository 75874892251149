import { createContext } from 'react'

import { OrderTabEnum } from '@/constant/TA_TRANSACTION'
import { ContextTypes } from './types'

const defaultValue: ContextTypes = {
  orderTab: OrderTabEnum.DRAFT,
  setOrderTab: Function,
}

const OrderManagementContext = createContext(defaultValue)

export default OrderManagementContext
