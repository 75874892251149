import styled from '@emotion/styled'

export const Container = styled('div')``

export const Title = styled('div')`
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-bottom: 4px;
`

export const WatchTitle = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`
