import { Container, Title, SearchInput, SearchIcon } from './styles'
import Table from '@/components/common/Table'
import WellBeingBranchTableHook from './WellBeingBranchTable.hook'

function WellBeingBranchTable() {
  const {
    columns,
    activeHealthPartnerBranchData,
    activeHealthPartnerBranchLoading,
    pagination,
    onTablePaginate,
    setIsTyping,
    onTableChange,
  } = WellBeingBranchTableHook()
  return (
    <Container>
      <Title>
        จำนวน{' '}
        {activeHealthPartnerBranchData
          ?.backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.data.length || 0}{' '}
        สาขา
      </Title>
      <SearchInput
        id="well-being-branch-search"
        data-testid="well-being-branch-search-input"
        prefix={<SearchIcon />}
        placeholder="ค้นหาชื่อสาขา..."
        onChange={() => setIsTyping(true)}
      />
      <Table
        data-testid="well-being-branch-table"
        columns={columns}
        dataSource={
          activeHealthPartnerBranchData
            ?.backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.data
        }
        loading={activeHealthPartnerBranchLoading}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: pagination.totalItems,
          current: pagination.page,
          defaultCurrent: 1,
          pageSize: pagination.limit,
          onChange: (page, pageSize) => onTablePaginate(page, pageSize),
        }}
        onChange={(_pagination, _filters, sorter) => onTableChange(sorter)}
      />
    </Container>
  )
}

export default WellBeingBranchTable
