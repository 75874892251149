import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeBulkAssignUsersToTeamDataInterface,
  BackofficeBulkAssignUsersToTeamInputInterface,
} from './interfaces'

const backofficeBulkAssignUsersToTeam: TypedDocumentNode<
  BackofficeBulkAssignUsersToTeamDataInterface,
  BackofficeBulkAssignUsersToTeamInputInterface
> = gql`
  mutation backofficeBulkAssignUsersToTeam($input: BulkAssignBackOfficeUsersToTeamInput!) {
    backofficeBulkAssignUsersToTeam(input: $input) {
      code
      message
      data
    }
  }
`

export default backofficeBulkAssignUsersToTeam
