import dayjs from 'dayjs'
import {
  Container,
  TitleWrapper,
  Title,
  SubTitleWrapper,
  TitleText,
  TitleValue,
  Tabs,
  LoadingContainer,
  SpinAntd,
  IconLoading,
} from './styles'
import TaTransactionOrderPageHook from './TaTransactionOrderPage.hook'
import TaTransactionOrderHeader from './TaTransactionOrderHeader'

const TaTransactionOrderPage = () => {
  const { fetchOrderPagination, orderTab, tabs } = TaTransactionOrderPageHook()

  return (
    <Container>
      {fetchOrderPagination.isFetching && (
        <LoadingContainer>
          <SpinAntd indicator={<IconLoading style={{ fontSize: 55 }} spin />} />
        </LoadingContainer>
      )}

      <TaTransactionOrderHeader
        orderTab={orderTab}
        orderNumber={fetchOrderPagination.data?.orderNumber}
      />
      <TitleWrapper>
        <Title>รายละเอียดการสั่งซื้อ</Title>
        <SubTitleWrapper>
          <TitleText>
            เลขที่ใบสั่งซื้อ <TitleValue>{fetchOrderPagination.data?.orderNumber}</TitleValue>
          </TitleText>
          <TitleText>
            วันที่สั่งซื้อ
            <TitleValue>
              {fetchOrderPagination.data?.orderDate
                ? dayjs(fetchOrderPagination.data?.orderDate).format('DD/MM/YYYY HH:mm:ss')
                : '-'}
            </TitleValue>
          </TitleText>
        </SubTitleWrapper>
      </TitleWrapper>
      <Tabs defaultActiveKey="policy" items={tabs.filter((tab) => tab !== undefined)} />
    </Container>
  )
}

export default TaTransactionOrderPage
