import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetBackofficeUserTeamRolePaginatedDataInterface,
  BackofficeGetBackofficeUserTeamRolePaginatedInputInterface,
} from './interfaces'

const backofficeGetBackofficeUserTeamRolePaginated: TypedDocumentNode<
  BackofficeGetBackofficeUserTeamRolePaginatedDataInterface,
  BackofficeGetBackofficeUserTeamRolePaginatedInputInterface
> = gql`
  query BackofficeGetBackofficeUserTeamRolePaginated($input: RequestPaginationInput!) {
    backofficeGetBackofficeUserTeamRolePaginated(input: $input) {
      data {
        backofficeUser {
          id
          email
          mobileNumber
          firstname
          lastname
          staffId
          isConfirmEmail
          role
          team
          createdBy
          updatedBy
          createdAt
        }
        backofficeTeam {
          id
          name
          createdAt
          updatedAt
        }
        backofficeRole {
          id
          title
        }
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
      }
    }
  }
`

export default backofficeGetBackofficeUserTeamRolePaginated
