import config from '@/config'
import { httpClientPrivate } from '@/libs/httpClient'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { ProvinceType } from './types'

const fetchProvince = async () => {
  const response = await httpClientPrivate.get(`${config.restUrl}/v1/backoffice-address/province`)

  return response.data
}

const useProvince = () => {
  const query = useQuery<Array<ProvinceType>>({
    queryKey: ['useProvince'],
    queryFn: async () => fetchProvince(),
    placeholderData: keepPreviousData,
  })

  return query
}

export { useProvince, fetchProvince }
