import { useQuery } from '@tanstack/react-query'
import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'
import { ReturnTimePaginationResponse } from './types'

const fetchReturnTimePagination = async (
  page: number,
  limit: number,
  search: string,
  sortBy: string,
) => {
  const response = await httpClientPrivate.get<ReturnTimePaginationResponse>(
    `${config.restUrl}/v1/backoffice-ppu-motor/returns-time?page=${page}&limit=${limit}&sortBy=${sortBy}&search=${search}`,
  )

  return response.data
}

const useReturnTimePagination = (query: {
  page: number
  limit: number
  search: string
  sortBy: string
}) => {
  const queryKey = ['useReturnTimePagination', query]
  const queryFn = async () =>
    fetchReturnTimePagination(query.page, query.limit, query.search, query.sortBy)
  const queryResult = useQuery({
    enabled: query.page && query.limit ? true : false,
    queryKey,
    queryFn,
  })

  return { ...queryResult }
}

export { useReturnTimePagination, fetchReturnTimePagination }
