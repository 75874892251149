import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeListActiveHealthByBillIdDataInterface,
  BackofficeListActiveHealthByBillIdInputInterface,
} from './interfaces'

const backofficeListActiveHealthByBillId: TypedDocumentNode<
  BackofficeListActiveHealthByBillIdDataInterface,
  BackofficeListActiveHealthByBillIdInputInterface
> = gql`
  query BackofficeListActiveHealthByBillId($billId: ID!) {
    backofficeListActiveHealthByBillId(billId: $billId) {
      id
      policyNumber
      startDate
      endDate
      summaryStep
      summaryActivityDuration
      summaryPoint
      summaryExercisePoint
      summaryBonusPoint
      discountAmount
      isFirstWeekBilling
      status
      billingWeekNo
      createdAt
      updatedAt
    }
  }
`

export default backofficeListActiveHealthByBillId
