import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeExportSearchActiveHealthPolicyDataInterface,
  BackofficeExportSearchActiveHealthPolicyInputInterface,
} from './interfaces'

const backofficeExportSearchActiveHealthPolicy: TypedDocumentNode<
  BackofficeExportSearchActiveHealthPolicyDataInterface,
  BackofficeExportSearchActiveHealthPolicyInputInterface
> = gql`
  query BackofficeExportSearchActiveHealthPolicy($input: RequestPaginationInput!) {
    backofficeExportSearchActiveHealthPolicy(input: $input)
  }
`

export default backofficeExportSearchActiveHealthPolicy
