import { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useMutation } from '@apollo/client'
import message from '@/libs/message'
import policyDetailAtom from '@/recoil/policyDetail'
import { userGql } from '@/gql'
import userAtom from '@/recoil/user'
import { Form } from 'antd'

export enum EditModeEnum {
  EMAIL = 'EMAIL',
  MOBILE_NUMBER = 'MOBILE_NUMBER',
}

function CustomerProfileHook() {
  const [form] = Form.useForm<{
    currentEmail: string
    email: string
    currentMobileNumber: string
    mobileNumber: string
  }>()
  const user = useRecoilValue(userAtom)
  const [policyDetail, setPolicyDetail] = useRecoilState(policyDetailAtom)
  const [backofficeUpdateUser, { error: backofficeUpdateUserError }] = useMutation(
    userGql.mutation.backofficeUpdateUser,
  )
  const [editMode, setEditMode] = useState<EditModeEnum | undefined>(undefined)
  const [disableSave, setDisableSave] = useState(true)

  const modalProperty = {
    [EditModeEnum.EMAIL]: {
      title: 'คุณกำลังแก้ไขอีเมลลูกค้า',
      subtitle: 'กรุณาตรวจสอบ email ว่าสะกดถูกต้อง',
      dataTestId: 'policy-detail-edit-email-modal',
      form: {
        currentValueInput: {
          label: 'อีเมลปัจจุบัน',
          name: 'currentEmail',
          dataTestId: 'policy-detail-current-email-input',
        },
        newValueInput: {
          label: 'อีเมลใหม่',
          name: 'email',
          rule: [{ type: 'email', required: true, message: 'ไม่ได้อยู่ในรูปแบบอีเมล' }],
          placeholder: 'ระบุอีเมลใหม่',
          dataTestId: 'policy-detail-edit-email-input',
          type: 'email',
        },
      },
    },
    [EditModeEnum.MOBILE_NUMBER]: {
      title: 'คุณกำลังแก้ไขเบอร์โทรศัพท์ลูกค้า',
      subtitle: 'กรุณาทวนตัวเลขเบอร์โทรศัพท์กับลูกค้า หรือตรวจสอบเลขที่คุณกรอก',
      dataTestId: 'policy-detail-edit-mobileNumber-modal',
      form: {
        currentValueInput: {
          label: 'เบอร์โทรศัพท์เดิม',
          name: 'currentMobileNumber',
          dataTestId: 'policy-detail-current-mobileNumber-input',
        },
        newValueInput: {
          label: 'เบอร์โทรศัพท์',
          name: 'mobileNumber',
          rule: [
            {
              required: true,
              pattern: /^[0]\d{9}$/g,
              message: 'ข้อมูลไม่ถูกต้อง',
            },
          ],
          placeholder: 'ระบุเบอร์โทรศัพท์ใหม่',
          dataTestId: 'policy-detail-edit-mobileNumber-input',
          type: 'tel',
        },
      },
    },
  }

  useEffect(() => {
    if (backofficeUpdateUserError) {
      const errorCode = backofficeUpdateUserError.message

      switch (errorCode) {
        case 'BIZUSR1001':
          message.error('ไม่พบข้อมูลลูกค้า')
          break
        case 'BIZUSR1002':
          message.error('อีเมลนี้ถูกใช้ไปแล้ว')
          break
        case 'BIZUSR1003':
          message.error('เบอร์โทรศัพท์นี้ถูกใช้ไปแล้ว')
          break
        default:
          message.error('แก้ไขข้อมูลลูกค้าผิดพลาด')
      }
    }
  }, [backofficeUpdateUserError])

  const updateUser = async () => {
    const updateUser = form.getFieldsValue()

    if (editMode === EditModeEnum.EMAIL && updateUser.currentEmail === updateUser.email) {
      return message.error('อีเมลนี้ถูกใช้ไปแล้ว')
    }

    if (
      editMode === EditModeEnum.MOBILE_NUMBER &&
      updateUser.currentMobileNumber === updateUser.mobileNumber
    ) {
      return message.error('เบอร์โทรศัพท์นี้ถูกใช้ไปแล้ว')
    }

    try {
      if (policyDetail.id) {
        const { data } = await backofficeUpdateUser({
          variables: {
            input: {
              userId: policyDetail.id,
              email: updateUser.email || undefined,
              mobileNumber: updateUser.mobileNumber || undefined,
            },
          },
        })

        if (data && data.backofficeUpdateUser) {
          const backofficeUpdateUserData = data.backofficeUpdateUser

          if (editMode === EditModeEnum.MOBILE_NUMBER) {
            setPolicyDetail({
              ...policyDetail,
              mobileNumber: backofficeUpdateUserData.mobileNumber,
            })

            message.success('แก้ไขเบอร์โทรศัพท์ลูกค้าสำเร็จ')
            form.resetFields()
          }

          if (editMode === EditModeEnum.EMAIL) {
            setPolicyDetail({
              ...policyDetail,
              email: backofficeUpdateUserData.email,
            })

            message.success('แก้ไขอีเมลลูกค้าสำเร็จ')
            form.resetFields()
          }

          setEditMode(undefined)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length)
    setDisableSave(hasErrors)
  }

  const openModal = (editMode: EditModeEnum) => {
    setEditMode(editMode)
    form.resetFields()
  }

  const closeModal = () => {
    setEditMode(undefined)
    form.resetFields()
  }

  return {
    policyDetail,
    updateUser,
    user,
    editMode,
    modalProperty,
    openModal,
    closeModal,
    form,
    onFormChange,
    disableSave,
  }
}

export default CustomerProfileHook
