export enum CategoryActionEnum {
  READ = 'read',
  EDIT = 'edit',
}

export enum SubCategoryMoveEnum {
  MOVE_UP = 'moveUp',
  MOVE_DOWN = 'moveDown',
}

export type FaqInfoesByFAQCategoryIdType = {
  id: string
  title: string
  description: string
  order: number
  selectedSubCategory: boolean
  faqCategoryId: string
  status: string
  createAt: string
  createBy: {
    id: string
    firstname: string
    lastname: string
  }
}

export enum statusQuestionEnum {
  QUESTION = 'question',
  CREATE_QUESTION = 'createQuestion',
  EDIT_QUESTION = 'editQuestion',
  READ_QUESTION = 'readQuestion',
  SEARCH_FAQ = 'searchFaq',
}

export type FAQCategoryType = {
  id: string
  title: string
  order: number
  isSelect: boolean
  action: CategoryActionEnum
}
