import React from 'react'

import UserDropdownMenuHook from './UserDropdownMenu.hook'
import { Menu, MenuItem } from './styles'

function UserDropdownMenu() {
  const { handleLogout } = UserDropdownMenuHook()

  return (
    <Menu>
      <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
    </Menu>
  )
}

export default UserDropdownMenu
