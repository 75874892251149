import { gql, TypedDocumentNode } from '@apollo/client'
import {
  RemoveBackofficeFAQInfoDataInterface,
  RemoveBackofficeFAQInfoInputInterface,
} from './interfaces'

const removeBackofficeFAQInfo: TypedDocumentNode<
  RemoveBackofficeFAQInfoDataInterface,
  RemoveBackofficeFAQInfoInputInterface
> = gql`
  mutation RemoveBackofficeFAQInfo($input: RemoveBackofficeFAQInfoActiveHealthInput!) {
    removeBackofficeFAQInfo(input: $input) {
      code
      message
      data
    }
  }
`

export default removeBackofficeFAQInfo
