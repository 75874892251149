import { gql, TypedDocumentNode } from '@apollo/client'
import { UpdateBackofficeUserDataInterface, UpdateBackofficeUserInputInterface } from './interfaces'

export const updateBackofficeUser: TypedDocumentNode<
  UpdateBackofficeUserDataInterface,
  UpdateBackofficeUserInputInterface
> = gql`
  mutation UpdateBackofficeUser($input: UpdateBackofficeUserInput!) {
    updateBackofficeUser(input: $input) {
      id
      firstname
      lastname
      email
      staffId
      updatedAt
      createdAt
    }
  }
`

export default updateBackofficeUser
