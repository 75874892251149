import { atom } from 'recoil'
import { NotificationProps } from './types'

const initNotification: NotificationProps = {
  discountIssuePage: {
    count: 0,
  },
  helpPage: {
    count: 0,
  },
}

const notificationAtom = atom({
  key: 'notificationAtom',
  default: initNotification,
})

export default notificationAtom
