import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeDeleteBackofficeTeamFromBackofficeUserDataInterface,
  BackofficeDeleteBackofficeTeamFromBackofficeUserInputInterface,
} from './interfaces'

const backofficeDeleteBackofficeTeamFromBackofficeUser: TypedDocumentNode<
  BackofficeDeleteBackofficeTeamFromBackofficeUserDataInterface,
  BackofficeDeleteBackofficeTeamFromBackofficeUserInputInterface
> = gql`
  mutation backofficeDeleteBackofficeTeamFromBackofficeUser(
    $input: DeleteBackofficeTeamFromBackofficeUserInput!
  ) {
    backofficeDeleteBackofficeTeamFromBackofficeUser(input: $input) {
      code
      message
      data
    }
  }
`

export default backofficeDeleteBackofficeTeamFromBackofficeUser
