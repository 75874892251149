import React, { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import Map from '@/components/common/Map'
import {
  Container,
  Search,
  FilterContainer,
  SearchIcon,
  SearchResultContainer,
  SearchResultBlock,
  PlugIcon,
  StationDetailContainer,
  StationDetailTitle,
  StationDetailAddress,
  StationDetailSubtitle,
  StationDetailInfo,
  StationDetailBlock,
  TimedPeriodBlock,
  TimePeriodInfo,
  ChargeRateBlock,
  ChargeTypeBlock,
  CloseContainer,
  SearchResultSubContainer,
} from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import { MapFilter } from '@/components/feature/EvChargingStationPage'
import EvChargingStationMapHook from './EvChargingStationMap.hook'
import {
  DAY_OF_WEEK,
  LocationChargingStationInterface,
} from '@/gql/motorEv/backofficeGetAllLocationChargingStations/interfaces'
import { isCurrentDay } from '@/libs/date'
import { CloseOutlined } from '@ant-design/icons'
import { LatLngExpression } from 'leaflet'

export function EvChargingStationDetail({
  location,
}: {
  location: LocationChargingStationInterface
}) {
  const province = location.province?.displayNameTh || '-'
  const district = location.province?.districts[0]?.displayNameTh || '-'
  const subDistrict = location.province?.districts[0]?.subDistricts[0]?.displayNameTh || '-'
  const postCode = location.province?.districts[0]?.subDistricts[0]?.postCode || '-'

  return (
    <StationDetailContainer>
      <StationDetailTitle>{location.displayNameTh || location.displayNameEn}</StationDetailTitle>
      <StationDetailAddress>
        {location.address1Th} {location.address2Th} {subDistrict} {district} {province} {postCode}
      </StationDetailAddress>
      {location.phones.length > 0 ? (
        <>
          <StationDetailSubtitle>โทร</StationDetailSubtitle>
          <StationDetailInfo>
            {location.phones.length === 1
              ? location.phones[0]
              : location.phones.map((phone) => `${phone},`)}
          </StationDetailInfo>
        </>
      ) : undefined}
      {location.location2OpenTime.length > 0 && (
        <>
          <StationDetailSubtitle>เวลา</StationDetailSubtitle>
          <TimedPeriodBlock>
            {location.location2OpenTime.map((day) => (
              <>
                <TimePeriodInfo currentDay={isCurrentDay(day.dayOfWeek)}>
                  {DAY_OF_WEEK[day.dayOfWeek]} :
                </TimePeriodInfo>
                <TimePeriodInfo currentDay={isCurrentDay(day.dayOfWeek)}>
                  {day.isClosed
                    ? 'ไม่เปิดให้บริการ'
                    : day.isOpen24Hours
                    ? 'เปิด 24 ชั่วโมง'
                    : day.openTime && day.closeTime
                    ? `${day.openTime}-${day.closeTime}`
                    : 'ไม่มีข้อมูลเวลาเปิดปิด'}
                </TimePeriodInfo>
              </>
            ))}
          </TimedPeriodBlock>
        </>
      )}

      <StationDetailSubtitle>ประเภทหัวชาร์จ</StationDetailSubtitle>
      <StationDetailBlock>
        {location.location2MotorEvChargingType.length > 0
          ? location.location2MotorEvChargingType.map((type) => (
              <ChargeTypeBlock>
                <div>
                  <PlugIcon src={IMAGE_URL.evPlugIcon} /> {type.motorEvChargingType.currentType} :{' '}
                  {type.motorEvChargingType.connectorType}
                </div>
                <div>{type.quantity}</div>
              </ChargeTypeBlock>
            ))
          : '-'}
      </StationDetailBlock>

      <StationDetailSubtitle>อัตราค่าใช้จ่าย</StationDetailSubtitle>
      <ChargeRateBlock>
        <StationDetailInfo>AC:</StationDetailInfo>

        {location.motorEvChargingProvider.chargeRates.length > 0
          ? location.motorEvChargingProvider.chargeRates
              .filter((cr) => cr.currentType === 'AC')
              .map((chargeRate) => (
                <StationDetailInfo>
                  {chargeRate.displayTh || chargeRate.displayEn}
                </StationDetailInfo>
              ))
          : '-'}
      </ChargeRateBlock>
      <ChargeRateBlock>
        <StationDetailInfo>DC:</StationDetailInfo>
        {location.motorEvChargingProvider.chargeRates.length > 0
          ? location.motorEvChargingProvider.chargeRates
              .filter((cr) => cr.currentType === 'DC')
              .map((chargeRate) => (
                <StationDetailInfo>
                  {chargeRate.displayTh || chargeRate.displayEn}
                </StationDetailInfo>
              ))
          : '-'}
      </ChargeRateBlock>
      <div>*ข้อมูลอัตราค่าใช้จ่าย update ณ เดือนสิงหาคม 2566</div>
    </StationDetailContainer>
  )
}

function MapSetCenter({
  center,
  location,
}: {
  center?: LatLngExpression
  location?: LocationChargingStationInterface
}) {
  const map = useMap()

  useEffect(() => {
    if (center && location) {
      // go to the direction.
      map.setView(center, 16)
    }
  }, [center, location])

  return <div></div>
}

function EvChargingStationMap() {
  const {
    mapLocations,
    mapCenter,
    handleSetMapCenter,
    selectedLocation,
    searchResults,
    onSearch,
    handleFilterTypes,
    handleFilterProviders,
    onCloseSearch,
    loadingChargingStations,
  } = EvChargingStationMapHook()

  return (
    <Container>
      <FilterContainer>
        <Search
          addonBefore={<SearchIcon />}
          placeholder="ค้นหาสถานีชาร์จรถไฟฟ้า"
          onSearch={(value) => onSearch(value)}
        />

        {searchResults.length > 0 ? (
          <SearchResultContainer>
            <CloseContainer>
              <></>
              <CloseOutlined
                style={{ cursor: 'pointer', color: '#666666', fontSize: '12px' }}
                onClick={onCloseSearch}
              />
            </CloseContainer>
            <SearchResultSubContainer>
              {searchResults.map((result) => {
                const province = result.province?.displayNameTh || '-'
                const district = result.province?.districts[0]?.displayNameTh || '-'
                const subDistrict =
                  result.province?.districts[0]?.subDistricts[0]?.displayNameTh || '-'
                const postCode = result.province?.districts[0]?.subDistricts[0]?.postCode || '-'

                return (
                  <SearchResultBlock>
                    <StationDetailTitle
                      onClick={() =>
                        handleSetMapCenter({ lat: result.lat, lng: result.long }, result)
                      }
                    >
                      {result.displayNameTh || result.displayNameEn}
                    </StationDetailTitle>
                    <StationDetailAddress>
                      {result.address1Th} {result.address2Th} {subDistrict} {district} {province}{' '}
                      {postCode}
                    </StationDetailAddress>
                    {result.location2OpenTime.length > 0 && (
                      <TimedPeriodBlock>
                        {result.location2OpenTime.map((day) => (
                          <>
                            <TimePeriodInfo currentDay={isCurrentDay(day.dayOfWeek)}>
                              {DAY_OF_WEEK[day.dayOfWeek]} :
                            </TimePeriodInfo>
                            <TimePeriodInfo currentDay={isCurrentDay(day.dayOfWeek)}>
                              {day.isClosed
                                ? 'ไม่เปิดให้บริการ'
                                : day.isOpen24Hours
                                ? 'เปิด 24 ชั่วโมง'
                                : day.openTime && day.closeTime
                                ? `${day.openTime}-${day.closeTime}`
                                : 'ไม่มีข้อมูลเวลาเปิดปิด'}
                            </TimePeriodInfo>
                          </>
                        ))}
                      </TimedPeriodBlock>
                    )}

                    <StationDetailSubtitle>ประเภทหัวชาร์จ</StationDetailSubtitle>
                    <StationDetailBlock disableBorderBottom={true} disableMarginBottom={true}>
                      {result.location2MotorEvChargingType.length > 0
                        ? result.location2MotorEvChargingType.map((type) => (
                            <ChargeTypeBlock>
                              <div>
                                <PlugIcon src={IMAGE_URL.evPlugIcon} />{' '}
                                {type.motorEvChargingType.currentType} :{' '}
                                {type.motorEvChargingType.connectorType}
                              </div>
                              <div>{type.quantity}</div>
                            </ChargeTypeBlock>
                          ))
                        : '-'}
                    </StationDetailBlock>
                  </SearchResultBlock>
                )
              })}
            </SearchResultSubContainer>
          </SearchResultContainer>
        ) : undefined}

        <MapFilter
          handleFilterTypes={handleFilterTypes}
          handleFilterProviders={handleFilterProviders}
          display={!searchResults.length}
        />
      </FilterContainer>
      <Map
        locations={mapLocations}
        currentPosition={
          selectedLocation ? [selectedLocation?.lat, selectedLocation?.long] : undefined
        }
        loading={loadingChargingStations}
      >
        <MapSetCenter center={mapCenter} location={selectedLocation} />
      </Map>
    </Container>
  )
}

export default EvChargingStationMap
