import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import _ from 'lodash'
import { FieldData } from 'rc-field-form/lib/interface'
import welcomeCallInformationAtom from '@/recoil/welcomeCallInformation'
import { useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import { welcomeCallGql } from '@/gql'
import message from '@/libs/message'
import { UpdateWelcomeCallTypes } from './types'
import confirmModalAtom from '@/recoil/confirmModal'
import SideBarInformationHook from '@/components/common/SideBarInformation/SideBarInformation.hook'
import userAtom from '@/recoil/user'

function WelcomeCallInformationHook() {
  const { onCloseSideBar } = SideBarInformationHook()
  const [{ welcomeCall }, setWelcomeCallInformation] = useRecoilState(welcomeCallInformationAtom)
  const setConfirmModal = useSetRecoilState(confirmModalAtom)
  const user = useRecoilValue(userAtom)
  const [
    updateWelcomeCall,
    {
      error: updateWelcomeCallError,
      data: updateWelcomeCallData,
      loading: updateWelcomeCallLoading,
    },
  ] = useMutation(welcomeCallGql.mutation.backofficeUpdateWelcomeCall)
  const [disableEditButton, setDisableEditButton] = useState(true)

  const onFieldsChange = (allFields: Array<FieldData>) => {
    let errorCount: number = 0
    const currentValues: { [key: string]: string | null } = {}
    const initialValues: UpdateWelcomeCallTypes = {
      phoneNumber: welcomeCall.phoneNumber,
      address: welcomeCall.address,
      watchDeliveredBy: welcomeCall.watchDeliveredBy,
      watchDeliveredTrackingNo: welcomeCall.watchDeliveredTrackingNo,
      watchSerialNumber: welcomeCall.watchSerialNumber,
      watchDeliveredDate: welcomeCall.watchDeliveredDate,
      remark: welcomeCall.remark,
    }

    for (const field of allFields) {
      if (field.errors && field.errors.length > 0) {
        errorCount++
      }

      currentValues[field.name as string] = field.value
    }

    if (errorCount > 0 || _.isEqual(currentValues, initialValues)) {
      setDisableEditButton(true)
    } else {
      setDisableEditButton(false)
    }
  }

  const onFinish = (values: UpdateWelcomeCallTypes) => {
    setConfirmModal({
      onOkFunction: () => onUpdateWelcomeCall(values),
      isVisible: true,
      title: 'ยืนยันบันทึกการแก้ไขหรือไม่?',
      bodyText: 'กรุณาตรวจสอบความถูกต้องก่อนกดบันทึก',
      okText: 'บันทึก',
      dataTestId: 'update-welcome-call-information-modal',
    })
  }

  const onUpdateWelcomeCall = (values: UpdateWelcomeCallTypes) => {
    const initialValues: UpdateWelcomeCallTypes = {
      phoneNumber: welcomeCall.phoneNumber,
      address: welcomeCall.address,
      watchDeliveredBy: welcomeCall.watchDeliveredBy,
      watchDeliveredTrackingNo: welcomeCall.watchDeliveredTrackingNo,
      watchSerialNumber: welcomeCall.watchSerialNumber,
      watchDeliveredDate: welcomeCall.watchDeliveredDate,
      remark: welcomeCall.remark,
    }
    if (!_.isEqual(values, initialValues)) {
      const {
        phoneNumber,
        address,
        watchDeliveredDate,
        watchDeliveredTrackingNo,
        watchSerialNumber,
        watchDeliveredBy,
        remark,
      } = values

      updateWelcomeCall({
        variables: {
          input: {
            policyNumber: welcomeCall.policyNumber,
            phoneNumber: phoneNumber || undefined,
            address: address || undefined,
            watchDeliveredBy: watchDeliveredBy || undefined,
            watchDeliveredTrackingNo: watchDeliveredTrackingNo || undefined,
            watchDeliveredDate: watchDeliveredDate
              ? dayjs(watchDeliveredDate).toString()
              : undefined,
            watchSerialNumber: watchSerialNumber || '',
            remark: remark || '',
          },
        },
      })
    }
  }

  useEffect(() => {
    if (updateWelcomeCallError) {
      message.error({ content: 'บันทึกการแก้ไขไม่สำเร็จ' })
    }
  }, [updateWelcomeCallError])

  useEffect(() => {
    if (updateWelcomeCallData?.backofficeUpdateWelcomeCall) {
      setWelcomeCallInformation({ welcomeCall: updateWelcomeCallData.backofficeUpdateWelcomeCall })
      onCloseSideBar()
      setConfirmModal({
        isVisible: false,
        onOkFunction: Function,
        dataTestId: undefined,
      })
      message.success({ content: 'บันทึกการแก้ไขสำเร็จ' })
    }
  }, [updateWelcomeCallData])

  return {
    welcomeCall,
    disableEditButton,
    onFinish,
    onFieldsChange,
    user,
    updateWelcomeCallLoading,
  }
}

export default WelcomeCallInformationHook
