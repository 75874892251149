import { Skeleton as AntdSkeleton } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { ActiveHealthWeeklySummaryStatusEnum } from '@/gql/activeHealthPolicy/backofficeListActiveHealthByBillId/interfaces'

export const Container = styled('div')``

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  margin: -24px;
  padding: 24px;
`

export const HeaderBlock = styled('div')``

export const Title = styled('p')`
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-bottom: 0;
`

export const PaymentStatus = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #009640;
  margin-top: 8px;
`

export const BillingIcon = styled('img')``

export const Card = styled('div')<{ special?: boolean }>`
  position: relative;
  margin-bottom: 16px;
  margin-top: ${(props) => (props.special ? '40px' : 'unset')};
  cursor: pointer;
`

export const BillingCard = styled('div')`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0 10px 0 #dfdfdf;
  position: relative;
`

export const CardTitle = styled('p')`
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin-bottom: 0;
`

export const CardSubtitle = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const DiscountBadge = styled('div')<{ status: ActiveHealthWeeklySummaryStatusEnum }>`
  padding: 2px 4px;
  background: ${(props) =>
    props.status === ActiveHealthWeeklySummaryStatusEnum.ACTIVE
      ? '#fea100'
      : props.status === ActiveHealthWeeklySummaryStatusEnum.GOTDISCOUNT
      ? '#009640'
      : '#C2C2C2'};
  border-radius: 4px;
  color: white;
  height: 22px;
  font-weight: 400;
  font-size: 10px;
  max-width: 100px;
  line-height: 1.5;
`

export const PointBlock = styled('div')`
  display: flex;
  align-items: center;
`

export const PointIcon = styled('img')`
  margin-right: 12px;
`

export const PointText = styled('span')`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
`

export const ScoreText = styled('p')`
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
`

export const Block = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ActiveHealthScoreBlock = styled('div')`
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
`

export const ExercisePointSubBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: "12px, 16px, 12px, 16px"
`

export const WellPointSubBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  margin: 17px -16px 0px -16px;
  padding: 12px 12px 0px 12px;
`

export const WeeklyBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const DiscountBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 0px -16px 0 -16px;
  padding: 16px 16px 0 16px;
`

export const DiscountText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
`
export const DiscountPrice = styled('p')`
  font-weight: 500;
  font-size: 16px;
  color: #009640;
  margin-bottom: 0;
`

export const SubBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const BlockSeparate = styled('div')`
  background-color: #fafafa;
  padding: 5px;
  margin: 0 -24px;
`

export const FooterBlock = styled('div')``

export const FooterTitle = styled('div')`
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 16px;
`

export const FooterText = styled('div')`
  font-weight: 400;
  font-size: 16px;
  color: #adadad;
`

export const FooterInformation = styled('div')`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`

export const FooterNotation = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #666666;
`

export const FooterPaymentText = styled('p')`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`

export const FooterPaymentInformation = styled('p')`
  font-weight: 500;
  font-size: 18px;
  color: #009640;
`

export const CreditCardTypeIcon = styled('img')`
  margin-right: 8px;
`

export const ActivityBonusStyle = css`
  position: absolute;
  top: -48px;
  left: 0;
  width: 100%;
  height: 60px;
  margin-left: 0 !important;
`

export const Body = styled('div')`
  position: relative;
`

export const Skeleton = styled(AntdSkeleton)`
  margin: 4px 0;
`
