import { atom } from 'recoil'

import { EditActivityDurationModalProps } from './types'

const initEditActivityDurationModal: EditActivityDurationModalProps = {
  open: false,
  isEditSuccess: false,
}

const editActivityDurationModalAtom = atom({
  key: 'editActivityDurationModalAtom',
  default: initEditActivityDurationModal,
})

export default editActivityDurationModalAtom
