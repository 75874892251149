import {
  Container,
  Title,
  Subtitle,
  RadioGroup,
  Radio,
  RadioBox,
  RadioTitle,
  OnText,
  OffText,
  TopBlock,
  StatusBadge,
  TitleBlock,
} from './styles'
import WellBeingPartnerStatusHook from './WellBeingPartnerStatus.hook'
import { Badge } from 'antd'
import { DisplayModeEnum } from '@/components/feature/WellBeingPage/WellBeingPartnerStatus/types'

function WellBeingPartnerStatus({ isPartnerActive }: { isPartnerActive: boolean }) {
  const { displayMode } = WellBeingPartnerStatusHook()

  return (
    <Container>
      <TopBlock displayMode={displayMode}>
        <TitleBlock>
          <Title>สถานะพาร์ทเนอร์</Title>
          {displayMode === DisplayModeEnum.VIEW ? (
            <StatusBadge isActive={isPartnerActive}>
              <Badge status={isPartnerActive ? 'success' : 'error'} />{' '}
              {isPartnerActive ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
            </StatusBadge>
          ) : undefined}
        </TitleBlock>
        {displayMode === DisplayModeEnum.EDIT ? (
          <Subtitle>
            คุณต้องการเปิดใช้งานพาร์ทเนอร์นี้ ให้แสดงบน Thaivivat App และสามารถแก้ไขข้อมูลในระบบ
            Backoffice
          </Subtitle>
        ) : undefined}
      </TopBlock>

      {displayMode === DisplayModeEnum.EDIT ? (
        <RadioGroup>
          <RadioBox select={true}>
            <Radio />
            <div>
              <RadioTitle>
                <OnText>เปิด</OnText> การใช้งานงานพาร์ทเนอร์
              </RadioTitle>
              <Subtitle>
                แสดงพาร์ทเนอร์นี้บน Thaivivat App และลูกค้าสแกนรับคะแนนได้ ตามช่วงเวลาที่กำหนด
              </Subtitle>
            </div>
          </RadioBox>
          <RadioBox>
            <Radio />
            <div>
              <RadioTitle>
                <OffText>ปิด</OffText> การใช้งานงานพาร์ทเนอร์
              </RadioTitle>
              <Subtitle>ไม่แสดงพาร์ทเนอร์นี้บน Thaivivat App และลูกค้าไม่สามารถสแกนได้</Subtitle>
            </div>
          </RadioBox>
        </RadioGroup>
      ) : undefined}
    </Container>
  )
}

export default WellBeingPartnerStatus
