import { useRecoilState } from 'recoil'
import { Form } from 'antd'
import { useState } from 'react'
import message from '@/libs/message'
import backofficeFAQInfo from '@/gql/backofficeFAQInfo'
import { useMutation } from '@apollo/client'
import { UpdateFAQCategoryBackofficeFAQInfoInputInterface } from '@/gql/backofficeFAQInfo/updateFAQCategoryBackofficeFAQInfo/interfaces'
import { MoveCategoryModalFormType } from './types'

import moveCategoryModalAtom from '@/recoil/moveCategoryModalAtom'

function MoveCategoryModalHook() {
  const [moveCategoryModal, setMoveCategoryModal] = useRecoilState(moveCategoryModalAtom)

  const { subCategoryId, onOkFunction } = moveCategoryModal

  const onModalInvisible = () => {
    setMoveCategoryModal({
      isVisible: false,
      onOkFunction: Function,
      title: '',
      category: [],
      subCategoryId: [],
    })
  }

  const [form] = Form.useForm<MoveCategoryModalFormType>()
  const [disabledSave, setDisabledSave] = useState(true)

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length)
    setDisabledSave(hasErrors)
  }

  const handleDisabledSave = () => setDisabledSave(true)

  const [updateFAQCategoryBackofficeFAQInfoMutation] = useMutation(
    backofficeFAQInfo.mutation.updateFAQCategoryBackofficeFAQInfo,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (values && subCategoryId) {
          const dataInput: UpdateFAQCategoryBackofficeFAQInfoInputInterface = {
            input: {
              faqCategoryId: values.selectFaqCategoryId,
              faqInfoIds: subCategoryId,
            },
          }

          const { data } = await updateFAQCategoryBackofficeFAQInfoMutation({
            variables: {
              ...dataInput,
            },
          })
          if (data) {
            message.success({
              content: 'success',
              duration: 10,
            })
          } else {
            message.error({
              content: 'error',
              duration: 10,
            })
          }
          handleDisabledSave()
          form.resetFields()
          onOkFunction()
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  return {
    moveCategoryModal,
    onModalInvisible,
    handleDisabledSave,
    setMoveCategoryModal,
    form,
    disabledSave,
    handleFormChange,
    onSubmit,
  }
}

export default MoveCategoryModalHook
