import config from '@/config'
import { httpClientPrivate } from '@/libs/httpClient'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { DistrictType } from './types'

const fetchDistrict = async (provinceCode: string) => {
  const response = await httpClientPrivate.get(
    `${config.restUrl}/v1/backoffice-address/district/${provinceCode}`,
  )

  return response.data
}

const useDistrict = (provinceCode: string) => {
  const query = useQuery<Array<DistrictType>>({
    queryKey: ['useDistrict', provinceCode],
    queryFn: async () => fetchDistrict(provinceCode),
    placeholderData: keepPreviousData,
    enabled: !!provinceCode,
  })

  return query
}

export { useDistrict, fetchDistrict }
