import { gql, TypedDocumentNode } from '@apollo/client'
import { UpdateBackofficeFAQCategoryDataInterface, UpdateBackofficeFAQCategoryInput } from './types'

export const updateBackofficeFAQCategory: TypedDocumentNode<
  UpdateBackofficeFAQCategoryDataInterface,
  UpdateBackofficeFAQCategoryInput
> = gql`
  mutation UpdateBackofficeFAQCategory($input: UpdateBackofficeFAQCategoryActiveHealthInput!) {
    updateBackofficeFAQCategory(input: $input) {
      id
      title
      order
    }
  }
`

export default updateBackofficeFAQCategory
