import styled from '@emotion/styled'

export const ListWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  border-bottom: 0.5px solid #e5e5e5;
`

export const Span = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #000000;
`

export const ImageIcon = styled('img')`
  cursor: pointer;
  height: 12px;
  width: 12px;
`
