import { atom } from 'recoil'
import { ModeUserEnum, UserModalProps } from './types'

const initUserModal = {
  title: undefined,
  dataUser: undefined,
  isVisible: false,
  onOkFunction: Function,
  onCancelFunction: Function,
  okText: 'ตกลง',
  okButtonProps: { type: 'primary', danger: false },
  mode: ModeUserEnum.CREATE,
} as UserModalProps

const userModalAtom = atom({
  key: 'userModalAtom',
  default: initUserModal,
})

export default userModalAtom
