import CardMemberPermission from './CardMemberPermission/CardMemberPermission'
import {
  Block,
  BlockWrapper,
  BodyWrapper,
  Button,
  ContainerWrapper,
  Description,
  Header,
  HeaderWrapper,
  Label,
  MemberRoleWrapper,
  RoleWrapper,
  SearchIcon,
  SearchInput,
  Select,
  SwitchWrapper,
  Skeleton,
  SkeletonContainer,
} from './styles'
import TabTeamMemberPermissionHook from './TabTeamMemberPermission.hook'

const TabTeamMemberPermission = () => {
  const {
    roleOptions,
    roleId,
    isLoading,
    setRoleId,
    menu,
    menuPermissionByMenuTypeId,
    handleMenu,
    handleSearchTextChange,
    onHandleHeaderSwitch,
    onHandlePageSwitch,
    onHandlePermissionSwitch,
    teamMenuPermission,
    loadingBackofficeUpdateBackofficeTeamMenuPermission,
    handleSubmitPermission,
    disabledSaveButton,
    loadBackofficeGetAllBackofficeMenuTypeQuery,
    loadingGetMenuPermissionByMenuTypeId,
  } = TabTeamMemberPermissionHook()

  return (
    <ContainerWrapper>
      <HeaderWrapper>
        <Header>ตั้งค่าสิทธิ์ในการใช้งานของทีม</Header>
        <Description>สมาชิกทุกคนในทีมจะมีสิทธิ์การใช้งานตามที่มีการตั้งค่าไว้</Description>
      </HeaderWrapper>
      <BodyWrapper>
        <Block>
          <MemberRoleWrapper>
            <div>
              <Header>บทบาทสมาชิก</Header>
              <Description>บทบาทในการเข้าถึงประกันภัยประเภทต่างๆ</Description>
            </div>
            <Select
              options={roleOptions}
              value={roleId}
              loading={isLoading}
              disabled={isLoading}
              onChange={(value: unknown) => {
                if (value) setRoleId(value as string)
              }}
              data-testid="user-role-manage-tab-select-role"
            />
          </MemberRoleWrapper>
          {loadBackofficeGetAllBackofficeMenuTypeQuery ? (
            <SkeletonContainer>
              <Skeleton active />
            </SkeletonContainer>
          ) : (
            menu?.map((dataMenu) => {
              return (
                <SwitchWrapper key={dataMenu.id}>
                  <Label
                    selected={dataMenu.id === menuPermissionByMenuTypeId?.id}
                    onClick={() => handleMenu(dataMenu)}
                    data-testid={`user-role-manage-tab-select-menu-${dataMenu.id}`}
                  >
                    {dataMenu.name}
                  </Label>
                  {/* <Switch checked={false} onChange={() => {}} /> */}
                </SwitchWrapper>
              )
            })
          )}
        </Block>
        <RoleWrapper>
          <BlockWrapper>
            <div>
              <Header>{menuPermissionByMenuTypeId?.name}</Header>
              <Description>สิทธิ์ในการเข้าถึงการจัดการประกันภัย</Description>
            </div>
            <Button
              type="primary"
              loading={loadingBackofficeUpdateBackofficeTeamMenuPermission}
              disabled={disabledSaveButton}
              onClick={handleSubmitPermission}
              data-testid="user-role-manage-tab-submit-button"
            >
              บันทึก
            </Button>
          </BlockWrapper>
          <SearchInput
            placeholder="ค้นหา..."
            prefix={<SearchIcon />}
            onChange={handleSearchTextChange}
            data-testid="user-role-manage-tab-search-permission-input"
          />
          {loadingGetMenuPermissionByMenuTypeId ? (
            <SkeletonContainer>
              <Skeleton active />
            </SkeletonContainer>
          ) : menuPermissionByMenuTypeId ? (
            menuPermissionByMenuTypeId.menu
              .sort((a, b) => a.order - b.order)
              .map((menu) => {
                return (
                  <CardMemberPermission
                    onChangeHeaderSwitch={onHandleHeaderSwitch}
                    onChangePageSwitch={onHandlePageSwitch}
                    onChangePermissionSwitch={onHandlePermissionSwitch}
                    menu={menu}
                    menuPermission={teamMenuPermission}
                  />
                )
              })
          ) : null}
        </RoleWrapper>
      </BodyWrapper>
    </ContainerWrapper>
  )
}

export default TabTeamMemberPermission
