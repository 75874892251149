// CallTypeFilterEnum used for filter when query data from API.
export enum CallTypeFilterEnum {
  ALL = '',
  NOT_RECEIVE = 'not-receive',
  CALLED = 'called',
  NOT_AVAILABLE = 'not-available',
  PENDING = 'pending',
  BUSY = 'busy',
}

// CallTypeEnum used for mapping data from response.
export enum CallTypeEnum {
  ALL = 'ALL',
  NOT_RECEIVE = 'NOT_RECEIVE',
  CALLED = 'CALLED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  PENDING = 'PENDING',
  BUSY = 'BUSY',
}

// CALL_TYPE used for display data
export const CALL_TYPE = {
  [CallTypeEnum.ALL]: 'ทั้งหมด',
  [CallTypeEnum.NOT_RECEIVE]: 'ไม่รับสาย',
  [CallTypeEnum.CALLED]: 'โทรแล้ว',
  [CallTypeEnum.NOT_AVAILABLE]: 'ติดต่อไม่ได้',
  [CallTypeEnum.PENDING]: 'ยังไม่โทร',
  [CallTypeEnum.BUSY]: 'ไม่สะดวกคุย',
}
