import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'

import { OrderManagementContext } from '@/contexts'
import { OrderStatusEnum } from '@/constant/TA_TRANSACTION'
import { useOrderDetail } from '@/services/order/useOrderDetail'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { validatePerm } from '@/libs/validatePermission'

import TaTransactionOrderCardPolicy from './TaTransactionOrderCardPolicy'
import TaTransactionOrderCardTraveler from './TaTransactionOrderCardTraveler'

const TaTransactionOrderPageHook = () => {
  const user = useRecoilValue(userAtom)
  const { orderId } = useParams<{ orderId: string }>()
  const { orderTab } = useContext(OrderManagementContext)
  const fetchOrderPagination = useOrderDetail(orderId as string)

  const isPolicy = validatePerm([BACKOFFICE_USER_PERMISSION.TA010201], user.permissions)
  const isTraveler = validatePerm([BACKOFFICE_USER_PERMISSION.TA010201], user.permissions)
  const tabs = []

  if (isPolicy) {
    tabs.push({
      key: 'policy',
      label: 'ข้อมูลการขอประกันภัย',
      children: <TaTransactionOrderCardPolicy data={fetchOrderPagination.data} />,
    })
  }

  if (isTraveler) {
    tabs.push({
      key: 'traveler',
      label: 'ผู้เดินทาง',
      children: (
        <TaTransactionOrderCardTraveler
          orderId={orderId as string}
          orderStatus={fetchOrderPagination.data?.status as OrderStatusEnum}
        />
      ),
    })
  }

  return { fetchOrderPagination, orderTab, tabs }
}

export default TaTransactionOrderPageHook
