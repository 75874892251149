import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { motorEvGql } from '@/gql'
import { MapLocationProps } from '@/components/common/Map/Map'
import { IMAGE_URL } from '@/constant/IMAGE'
import { EvChargingStationDetail } from '@/components/feature/EvChargingStationPage/EvChargingStationMap/EvChargingStationMap'
import { LocationChargingStationInterface } from '@/gql/motorEv/backofficeGetAllLocationChargingStations/interfaces'
import { LatLngExpression } from 'leaflet'

function EvChargingStationMapHook() {
  const [selectedLocation, setSelectedLocation] = useState<
    LocationChargingStationInterface | undefined
  >(undefined)
  const [mapCenter, setMapCenter] = useState<LatLngExpression | undefined>(undefined)
  const [filterTypes, setFilterTypes] = useState<Array<string>>([])
  const [filterProviders, setFilterProviders] = useState<Array<string>>([])
  const [searchResults, setSearchResults] = useState<Array<LocationChargingStationInterface>>([])
  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const [mapLocations, setMapLocations] = useState<Array<MapLocationProps>>([])
  const [locations, setLocations] = useState<Array<LocationChargingStationInterface>>([])
  const [
    backofficeGetAllLocationChargingStations,
    { data: chargingStations, loading: loadingChargingStations },
  ] = useLazyQuery(motorEvGql.query.backofficeGetAllLocationChargingStations)

  const handleFilterTypes = (types: Array<string>) => setFilterTypes(types)

  const handleFilterProviders = (providers: Array<string>) => setFilterProviders(providers)

  const handleSetMapCenter = (
    center: LatLngExpression,
    location: LocationChargingStationInterface,
  ) => {
    setMapCenter(center)
    setSelectedLocation(location)
  }

  const onSearch = (value: string) => {
    if (value && locations && locations.length > 0) {
      const results = locations.filter((station) => {
        const subDistrict = station.province.districts[0].subDistricts[0].displayNameTh
        const distrcict = station.province.districts[0].displayNameTh
        const province = station.province.displayNameTh

        return (
          station.motorEvChargingProvider.name.toLowerCase().includes(value.toLowerCase()) ||
          station.displayNameTh.toLowerCase().includes(value.toLowerCase()) ||
          station.displayNameEn.toLowerCase().includes(value.toLowerCase()) ||
          station.displayNameEn.toLowerCase().includes(value.toLowerCase()) ||
          subDistrict.toLowerCase().includes(value.toLowerCase()) ||
          distrcict.toLowerCase().includes(value.toLowerCase()) ||
          province.toLowerCase().includes(value.toLowerCase()) ||
          station.address1Th.toLowerCase().includes(value.toLowerCase()) ||
          station.address2Th.toLowerCase().includes(value.toLowerCase())
        )
      })

      setSearchResults(results)
    } else {
      setSearchResults([])
    }
  }

  const onCloseSearch = () => setSearchResults([])

  useEffect(() => {
    backofficeGetAllLocationChargingStations()
  }, [])

  // First time rendering.
  useEffect(() => {
    if (chargingStations) {
      const data: Array<MapLocationProps> =
        chargingStations.backofficeGetAllLocationChargingStations.map((station) => ({
          iconUrl: IMAGE_URL.evChargingStationMarker,
          position: [station.lat, station.long],
          popupChildren: <EvChargingStationDetail location={station} />,
        }))

      if (data) {
        setMapLocations(data)
        setLocations(chargingStations.backofficeGetAllLocationChargingStations)
      }
    }
  }, [chargingStations])

  // Filter and update.
  // TODO: found bugs when filter more than 2 filter (provider).
  useEffect(() => {
    let filterLocations: Array<LocationChargingStationInterface> = []

    if (chargingStations && chargingStations.backofficeGetAllLocationChargingStations.length > 0) {
      const data = chargingStations.backofficeGetAllLocationChargingStations

      if (filterProviders.length > 0 || filterTypes.length > 0) {
        const newFilterProviders: Array<LocationChargingStationInterface> = []
        const newFilterTypes: Array<LocationChargingStationInterface> = []

        if (filterProviders.length > 0) {
          for (const provider of filterProviders) {
            const filtered = data.filter((loc) => loc.motorEvChargingProvider.id === provider)

            newFilterProviders.push(...newFilterProviders, ...filtered)
          }
        }

        if (filterTypes.length > 0) {
          const stations = newFilterProviders.length > 0 ? newFilterProviders : data

          for (const type of filterTypes) {
            for (const loc of stations) {
              const filtered = loc.location2MotorEvChargingType.filter(
                (chargingType) => chargingType.motorEvChargingType.id === type,
              )

              if (filtered.length > 0) {
                newFilterTypes.push(loc)
              }
            }
          }
        }

        filterLocations = newFilterTypes.length > 0 ? newFilterTypes : newFilterProviders
      } else {
        filterLocations = data
      }
    }

    const data: Array<MapLocationProps> = filterLocations.map((station) => ({
      iconUrl: IMAGE_URL.evChargingStationMarker,
      position: [station.lat, station.long],
      popupChildren: <EvChargingStationDetail location={station} />,
    }))

    if (data) {
      setMapLocations(data)
      setLocations(filterLocations)
    }
  }, [filterTypes, filterProviders])

  return {
    chargingStations,
    mapLocations,
    handleFilterProviders,
    handleFilterTypes,
    onSearch,
    searchResults,
    onCloseSearch,
    handleSetMapCenter,
    mapCenter,
    selectedLocation,
    setSearchInputValue,
    loadingChargingStations,
  }
}

export default EvChargingStationMapHook
