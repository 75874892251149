import styled from '@emotion/styled'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
`

export const SubContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

export const NotFoundUserIcon = styled('img')`
  height: 48px;
  width: 48px;
`

export const Wrapper = styled('div')`
  margin: 13px 0px;
`

export const Title = styled('div')`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
`

export const Subtitle = styled('div')`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #666666;
`

export const Button = styled(CommonButton)`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background-color: #009640;
  color: #ffffff;
  padding: 10px 16px;
`
