import styled from '@emotion/styled'

import { Modal as AntModal, Select as AntSelect, Form } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    height: 50%;
  }

  .ant-btn-default {
    box-shadow: none;
    height: auto;
    padding: 10px 16px;
    border-radius: 8px;
  }

  .ant-modal-header {
    padding: 20px 24px;
    margin-bottom: 4px;
  }

  .ant-modal-body {
    border-top: 1px solid #c6c6c8;
    padding: 24px;
  }

  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-footer {
    padding: 16px 24px;
    margin-top: 0px;
    background-color: #fafafa;
    border-radius: 0px 0px 11px 11px;
  }

  .ant-btn-primary {
    padding: 10px 16px;
    border-radius: 8px;
    border-width: 1px;
    box-shadow: none;
    height: 40px;

    :where(.css-dev-only-do-not-override-1kiccxv).ant-btn-primary:disabled {
      cursor: not-allowed;
      box-shadow: none !important;
      color: #ffff !important;
      border-color: #ededf0 !important;
      background: #ededf0 !important;
    }
  }

  .ant-btn-default {
    border-radius: 8px;
    box-shadow: none;
    height: 40px;
  }
`

export const BodySpan = styled('span')`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`

export const Select = styled(AntSelect)`
  width: 100%;

  .ant-select-selector {
    width: 100%;
    height: auto !important;
    padding: 10px 16px !important;
  }
`

export const Option = styled(AntSelect.Option)``

export const FormContainer = styled(Form)`
  .ant-form-item {
    margin: 0px;
  }
`

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0 0 4px;
  }

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.004em;
    color: #000000;
  }
`
