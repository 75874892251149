import { useEffect } from 'react'
import { Form, Radio, Space } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Modal from '@/components/common/Modal'
import { Container, FormItem, Input, OrderNumber, Select, Title } from './styles'
import { ApplicationFormResponse } from '@/services/order/useOrderApplicationForm/types'
import TaTravelerModalHook from './TaTravelerModal.hook'
import { OTHER_NATIONALITY_ID, THAI_NATIONALITY_ID } from '@/constant/TA'
import message from '@/libs/message'

function TaTravelerModal({
  open,
  orderId,
  applicationForm,
  closeModal,
}: {
  open: boolean
  orderId: string
  applicationForm: ApplicationFormResponse
  closeModal: () => void
}) {
  const {
    form,
    useAddress,
    address,
    provinceCode,
    districtCode,
    subDistrictCode,
    postCode,
    handleAddressChange,
    handleProvinceChange,
    handleDistrictChange,
    handleSubDistrictChange,
    isBeneficiary,
    useUpdateTraveler,
    refetchUseOrderApplicationForm,
  } = TaTravelerModalHook({
    orderId,
    applicationForm,
  })
  const { subDistricts, districtOptions, provinceOptions, subDistrictOptions } = useAddress(
    provinceCode,
    districtCode,
  )
  const mutation = useUpdateTraveler()

  useEffect(() => {
    if (mutation.isSuccess) {
      message.success('แก้ไขข้อมูลสำเร็จ')
      closeModal()
      refetchUseOrderApplicationForm()
    }
  }, [mutation.isSuccess])

  return (
    <Modal
      open={open}
      closeIcon={<CloseOutlined />}
      data-testid="ta-traveler-modal-form"
      title={`ผู้เดินทางลำดับที่ ${Number(applicationForm.insuredNo) + 1}`}
      width={640}
      onCancel={closeModal}
      okButtonProps={{ disabled: !address || !provinceCode || !districtCode || !subDistrictCode }}
      onOk={() => mutation.mutate()}
      okText="บันทึก"
    >
      <Container>
        <Form layout="vertical" form={form}>
          <FormItem label="สัญชาติ" name={['nationality', 'id']}>
            <Radio.Group disabled>
              <Radio value={THAI_NATIONALITY_ID}>ไทย</Radio>
              <Radio value={OTHER_NATIONALITY_ID}>ต่างชาติ</Radio>
            </Radio.Group>
          </FormItem>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormItem label="เลขที่บัตรประชาชน*" name="identityId">
              <Input disabled />
            </FormItem>
            <FormItem label="เลขที่หนังสือเดินทาง*" name="passportId">
              <Input disabled />
            </FormItem>
          </Space>

          <FormItem label="วันเดือนปีเกิด*" name="dateOfBirth">
            <Input disabled />
          </FormItem>

          <FormItem label="คำนำหน้า*" name={['title', 'titleEn']}>
            <Input disabled />
          </FormItem>

          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormItem label="ชื่อ (Eng)*" name="firstname">
              <Input disabled />
            </FormItem>
            <FormItem label="นามสกุล (Eng)*" name="lastname">
              <Input disabled />
            </FormItem>
          </Space>

          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormItem label="ที่อยู่*" name="address">
              <Input value={address} onChange={(e) => handleAddressChange(e)} />
            </FormItem>
            <FormItem label="จังหวัด*">
              <Select
                options={provinceOptions}
                value={provinceCode}
                onChange={handleProvinceChange}
              />
            </FormItem>
          </Space>

          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormItem label="เขต/อำเภอ*">
              <Select
                options={districtOptions}
                value={districtCode}
                disabled={!districtCode && !provinceCode}
                onChange={handleDistrictChange}
              />
            </FormItem>
            <FormItem label="แขวง/ตำบล*">
              <Select
                options={subDistrictOptions}
                value={subDistrictCode}
                disabled={!districtCode && !subDistrictCode}
                onChange={(value) => handleSubDistrictChange(value, subDistricts || [])}
              />
            </FormItem>
          </Space>

          <Space style={{ marginBottom: '24px' }}>
            <FormItem label="รหัสไปรษณีย์*">
              <Input disabled value={postCode} />
            </FormItem>
          </Space>

          <Title>ช่องทางการติดต่อ</Title>
          <Space style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
            <FormItem label="เบอร์โทรศัพท์*" name="mobileNumber">
              <Input disabled />
            </FormItem>
            <FormItem label="อีเมล*" name="email">
              <Input disabled />
            </FormItem>
          </Space>

          <Space style={{ marginBottom: '8px' }}>
            <FormItem label="ผู้รับผลประโยชน์" name="beneficiaryType" style={{ width: 'unset' }}>
              <Radio.Group disabled>
                <Radio value="law">ทายาทตามกฎหมาย</Radio>
                <Radio value="other">ระบุผู้รับผลประโยชน์</Radio>
              </Radio.Group>
            </FormItem>
          </Space>

          {isBeneficiary && (
            <>
              <Title>รายชื่อผู้รับผลประโยชน์</Title>
              <Space
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  border: '1px solid #E5E5E5',
                  borderRadius: '8px',
                  padding: '16px',
                  position: 'relative',
                }}
              >
                <OrderNumber>1</OrderNumber>
                <FormItem
                  label="ชื่อ-นามสกุล"
                  name={['beneficiary', 'name']}
                  style={{ width: '236px' }}
                >
                  <Input disabled />
                </FormItem>
                <FormItem
                  label="เลือกความสัมพันธ์"
                  name={['beneficiary', 'beneficiaryRelation', 'nameTh']}
                  style={{ width: '236px' }}
                >
                  <Input disabled />
                </FormItem>
              </Space>
            </>
          )}
        </Form>
      </Container>
    </Modal>
  )
}

export default TaTravelerModal
