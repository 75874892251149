import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th' // Import Thai locale

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(buddhistEra)
dayjs.extend(localizedFormat)

export const formatLocaleDate = (date: Date, locale: string, format: string): string => {
  return dayjs(date).locale(locale).format(format)
}
