import { gql, TypedDocumentNode } from '@apollo/client'
import { BackofficeGetAllBackofficeTeamDataInterface } from './interfaces'

export const backofficeGetAllBackofficeTeam: TypedDocumentNode<BackofficeGetAllBackofficeTeamDataInterface> = gql`
  query backofficeGetAllBackofficeTeam {
    backofficeGetAllBackofficeTeam {
      id
      name
      createdAt
      updatedAt
    }
  }
`

export default backofficeGetAllBackofficeTeam
