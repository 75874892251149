import { Container, Title } from './styles'
import MobileDeviceTable from '@/components/feature/PolicyDetailPage/MobileDeviceTable'
import WatchDeviceTable from '@/components/feature/PolicyDetailPage/WatchDeviceTable'

import DeviceTabHook from './DeviceTab.hook'

export default function DeviceTab() {
  const {
    dataBackOfficeListUserSessionQuery,
    loadingBackOfficeListUserSessionQuery,
    dataBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery,
    loadBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery,
  } = DeviceTabHook()

  return (
    <>
      <Container data-testid="policy-detail-device-tab-device-card">
        <Title>มือถือเข้าใช้งาน</Title>
        <MobileDeviceTable
          dataSource={dataBackOfficeListUserSessionQuery?.backofficeListUserSessionByUserId}
          isLoading={loadingBackOfficeListUserSessionQuery}
        />
      </Container>
      <Container data-testid="policy-detail-device-tab-smartwatches-card">
        <Title>นาฬิกาออกกำลังกาย</Title>
        <WatchDeviceTable
          dataSource={
            dataBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery?.backofficeListActiveHealthPolicyIncludeWatchBranch
          }
          isLoading={loadBackOfficeListActiveHealthPolicyIncludeWatchBranchQuery}
        />
      </Container>
    </>
  )
}
